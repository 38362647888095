//FR  il afficher une liste d'activités organisées par l'utilisateur actuel (FrameFigma22B3)
//GB  To display a list of activities organized by the current user (FrameFigma22B3)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { View, Text, Platform, Animated } from 'react-native';
import MyActivityCard from '../../../../components/CardSection/MyActivityCard/Code';
import { useFocusEffect } from '@react-navigation/native';
import { formatDate, compareActivities, ActivitiesWithDate } from '../../../../utils/Functions/functions';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
const OrganizedScreen = ({ route }) => {
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const activitiesStore = useSelector((state) => state.activities);
    const { user } = useSelector((state) => state.user);
    const [activities, setActivities] = useState([]);
    const [userId, setUserId] = useState(null); // Ajouter l'état pour userId
    const { setIsDisplayBannerVisible } = route.params;
    const scrollY = useRef(new Animated.Value(0)).current;
    useFocusEffect(useCallback(() => {
        setIsDisplayBannerVisible(true);
        return () => {
            setActivities([]);
            setIsDisplayBannerVisible(false);
        };
    }, []));
    useEffect(() => {
        const listener = scrollY.addListener(({ value }) => {
            if (value > 0) {
                setIsDisplayBannerVisible(false);
            }
            else {
                setIsDisplayBannerVisible(true);
            }
        });
        return () => {
            scrollY.removeListener(listener);
        };
    }, [scrollY]);
    const fetchActivities = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const userId = user._id;
            setUserId(userId);
            // const response = await ActivityServices.getActivities();
            const myUpcomingActivities = activitiesStore.activities.filter((activity) => {
                return activity.author.includes(userId) && new Date(activity.date) >= new Date();
            });
            setActivities(myUpcomingActivities);
        }
        catch (error) {
            if (error instanceof TypeError && error.message === 'Network request failed') {
                console.error("Network request failed:", error);
            }
            else {
                console.error("Error fetching activities:", error);
            }
        }
    });
    useFocusEffect(useCallback(() => {
        fetchActivities();
    }, []));
    const groupedActivities = useMemo(() => {
        const grouped = new Map();
        activities.forEach((activity) => ActivitiesWithDate(activity, grouped));
        // Convertir le Map en array et trier par date
        const sortedGroupedActivities = Array.from(grouped.entries()).sort(([date1], [date2]) => {
            return new Date(date1).valueOf() - new Date(date2).valueOf();
        });
        return new Map(sortedGroupedActivities);
    }, [activities]);
    // Trier les activités pour chaque date
    groupedActivities.forEach(activities => {
        activities.sort(compareActivities);
    });
    return (<View style={styles.colorBackroundWhite}>
      <Animated.ScrollView onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], { useNativeDriver: false })} showsVerticalScrollIndicator={false}>
        {groupedActivities.size > 0 ? (Array.from(groupedActivities).map(([date, activities]) => (<View key={date}>
              <Text style={styles.DisplayDate}>{formatDate(date)}</Text>
              {activities.map((activity) => (<View key={activity._id ? activity._id.toString() : activity.date.toString()}>
                  <MyActivityCard activity={activity} userId={userId}/>
                </View>))}
            </View>))) : (<View style={styles.text}>
            <Text>{translations.JsonActivityPages.JsonMyActivitiesScreen.JsonOrganizedActivityEmpty}</Text>
          </View>)}
      </Animated.ScrollView>
    </View>);
};
export default OrganizedScreen;
