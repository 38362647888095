import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import MyButtonPress from '../../../ButtonSection/PressButton/Code';
const ActivityDeleteModal = ({ setModalVisible, deleteAct }) => {
    return (<>
            <Text style={styles.modalText}>Are you sure you want to delete this activity?</Text>
            <View style={styles.modalButtons}>
                <MyButtonPress text="Confirm" txtCol="#59C09B" txtS={20} txtFW={'700'} w={113} h={35} bgC="white" br={11} b={1} bC="#59C09B" cb={deleteAct}/>
                <MyButtonPress text="Cancel" txtCol="white" txtS={20} txtFW={'700'} w={113} h={35} bgC="#59C09B" br={11} cb={() => setModalVisible(false)}/>
            </View>
        </>);
};
export default ActivityDeleteModal;
const styles = StyleSheet.create({
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
    },
    modalText: {
        fontFamily: 'Poppins',
        fontSize: 20,
        color: '#59C09B',
        fontWeight: '700',
        textAlign: 'center',
        margin: 20,
    },
});
