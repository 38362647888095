// Fr Cette page affiche l'étape 1 du formulaire pour modifier une activité de type VIP(FrameFigma038A,FrameFigma038B)
// En This page displays the first step of the form to edit an activity of type VIP (FrameFigma038A,FrameFigma038B)
import React, { useState } from 'react';
import { Platform, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import SwitchBtn from '../../../../components/ButtonSection/SwitchButton/Code';
import SliderComponent from '../../../../components/GeneralSection/SliderComponent/SliderComponent';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import Modal from '../../../../components/ModalPopUpModule/ModalSection/ActivityDetailsModal/Code';
import countriesListSquareFlags from '../../../../components/GeneralSection/LanguagesFlagsComponent/LanguagesFlagsSquare';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const EditActivityHelpForOrganizersScreen = ({ onNextStep, onPrevStep, setHeaderTitle, rolePremium }) => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { user } = useSelector((state) => state.user);
    // var pour afficher le pop up ComingSoon
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [chooseCommunity, setChooseCommunity] = useState(false);
    const [peopleMet, setPeopleMet] = useState(false);
    const [peopleMyLanguage, setPeopleMyLanguage] = useState(false);
    const [peopleMetOrga, setPeopleMetOrga] = useState(false);
    const [peopleLoveTopics, setPeopleLoveTopics] = useState(false);
    const [aroundMeSliderValue, setAroundMeSliderValue] = useState(4);
    const [aroundPointSliderValue, setAroundPointSliderValue] = useState(6);
    const [peopleChoiceLanguages, setPeopleChoiceLanguages] = useState([]);
    const flagsDetails = countriesListSquareFlags.filter(flag => {
        var _a;
        const languageSpokenFlag = (_a = user === null || user === void 0 ? void 0 : user.spokenLanguage) === null || _a === void 0 ? void 0 : _a.includes(flag.bigram);
        return languageSpokenFlag;
    });
    const handleSpokenLanguageChange = (bigram) => {
        setPeopleChoiceLanguages(peopleChoiceLanguages.includes(bigram) ? peopleChoiceLanguages.filter(b => b !== bigram) : [...peopleChoiceLanguages, bigram]);
    };
    const chooseCommunityFunction = () => {
        if (rolePremium === 'noStarUser') {
            setModalVisible(true);
            setModalMessage('This feature will be available for VIP gold star members or more!');
        }
        else {
            //setChooseCommunity(prevState => !prevState);
            setModalVisible(true);
            setModalMessage('🚧 We are working on this feature');
        }
    };
    const peopleMetFunction = () => {
        if (rolePremium != 'diamondStarUser') {
            setModalVisible(true);
            setModalMessage('This feature will be available for VIP diamond star members!');
        }
        else {
            setModalVisible(true);
            setModalMessage('🚧 We are working on this feature');
        }
    };
    const peopleMyLanguageFunction = () => {
        if (rolePremium !== 'emeraldStarUser' && rolePremium !== 'diamondStarUser') {
            setModalVisible(true);
            setModalMessage('This feature will be available for VIP emerald star members or more!');
        }
        else {
            setModalVisible(true);
            setModalMessage('🚧 We are working on this feature');
        }
    };
    const peopleMetOrgaFunction = () => {
        if (rolePremium !== 'rubyStarUser' && rolePremium !== 'emeraldStarUser' && rolePremium !== 'diamondStarUser') {
            setModalVisible(true);
            setModalMessage('This feature will be available for VIP ruby star members or more!');
        }
        else {
            setModalVisible(true);
            setModalMessage('🚧 We are working on this feature');
        }
    };
    const peopleLoveTopicsFunction = () => {
        if (rolePremium != 'diamondStarUser') {
            setModalVisible(true);
            setModalMessage('This feature will be available for VIP diamond star members!');
        }
        else {
            setModalVisible(true);
            setModalMessage('🚧 We are working on this feature');
        }
    };
    const handlePrevStep = () => {
        setHeaderTitle('Manage Participation');
        onPrevStep();
    };
    const handleNextStep = () => {
        setHeaderTitle('Activity topic');
        onNextStep();
    };
    return (<ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
            <Text style={styles.textbtn}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonAppWillHelpToAttractPeopleMessage}
            </Text>
            <View>
                <View style={styles.switchBtn}>
                    <Text style={styles.textbtn}>
                        {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonChooseYourCommunity}
                    </Text>
                    <SwitchBtn state={chooseCommunity} setState={chooseCommunityFunction}/>
                </View>
            </View>
            {chooseCommunity === true && (<View style={styles.twoSliders}>
                    <View>
                        <Text style={styles.sliderText}>
                            {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleAroundMe}
                        </Text>
                        <SliderComponent min={2} max={20} sliderValue={aroundMeSliderValue} setSliderValue={setAroundMeSliderValue}/>
                    </View>
                    <View>
                        <Text style={styles.sliderText}>
                            {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleAroundMeetingPoint}
                        </Text>
                        <SliderComponent min={2} max={20} sliderValue={aroundPointSliderValue} setSliderValue={setAroundPointSliderValue}/>
                    </View>
                </View>)}
            <Modal modalVisible={modalVisible} onClose={() => setModalVisible(false)} children={<Text>{modalMessage}</Text>}/>
            <View style={styles.switchBtn}>
                <Text style={styles.textbtn}>
                    {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleIMet}
                </Text>
                <SwitchBtn state={peopleMet} setState={peopleMetFunction}/>
            </View>
            <View style={styles.switchBtn}>
                <Text style={styles.textbtn}>
                    {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleWithMyLanguage}
                </Text>
                <SwitchBtn state={peopleMyLanguage} setState={peopleMyLanguageFunction}/>
            </View>
            {peopleMyLanguage === true && (user === null || user === void 0 ? void 0 : user.spokenLanguage) && (<View style={styles.flagsContainer}>
                    {flagsDetails.map(({ FlagComponent, bigram }) => (<TouchableOpacity key={bigram} onPress={() => handleSpokenLanguageChange(bigram)} style={[styles.flagWrapper, peopleChoiceLanguages.includes(bigram) ? styles.flagWrapperSelected : {}]}>
                            <FlagComponent width={30} height={30}/>
                        </TouchableOpacity>))}
                </View>)}
            <View style={styles.switchBtn}>
                {/* text a corriger dans json, pas pareil que figma */}
                <Text style={styles.textbtn}>
                    {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleIMetAsOrganizer}
                </Text>
                <SwitchBtn state={peopleMetOrga} setState={peopleMetOrgaFunction}/>
            </View>
            <View style={styles.switchBtn}>
                <Text style={styles.textbtn}>
                    {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountInvitationFeatures.JsonPeopleWhoLoveMyTopic}
                </Text>
                <SwitchBtn state={peopleLoveTopics} setState={peopleLoveTopicsFunction}/>
            </View>
            <View style={styles.btnContainer}>
                <ButtonPrevious onPrevStep={handlePrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
                <ButtonNext conditionNeed={peopleMyLanguage === true ? peopleChoiceLanguages.length > 0 : true} handleNextStep={handleNextStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}/>
            </View>
        </ScrollView>);
};
export default EditActivityHelpForOrganizersScreen;
