var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { View, Text, StyleSheet, Pressable, ImageBackground } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import Vectors from '../../../constants/Vectors';
import { addUserLike, deleteUserLike } from '../../../services/user/UserLikesServices';
import ProfileMedal from '../../ProfileSection/ProfileMedal/Code';
import { useSelector } from 'react-redux';
import { userServices } from '../../../services/user/UserServices';
const UserCard = ({ userCard }) => {
    var _a, _b;
    const navigation = useNavigation();
    // const { getCurrentUserLikeStatus, user } = useUser();
    const { user } = useSelector((state) => state.user);
    const { userToken } = useSelector((state) => state.auth);
    const { getCurrentUserLikeStatus } = userServices;
    const isOnline = userCard._id === user._id ? 'online' : userCard.status === 'online';
    const [userIsLiked, setUserIsLiked] = useState(false);
    useEffect(() => {
        getCurrentUserLikeStatus(userCard._id, user._id)
            .then((data) => setUserIsLiked(data))
            .catch((error) => console.error('Error fetching user like:', error));
        return () => {
            setUserIsLiked(false);
        };
    }, [userCard._id]);
    const toggleLike = () => __awaiter(void 0, void 0, void 0, function* () {
        setUserIsLiked(!userIsLiked);
        if (userIsLiked) {
            yield deleteUserLike(user._id, userCard._id);
        }
        else {
            yield addUserLike(user._id, userCard._id);
        }
    });
    if (!userCard) {
        return <Text>Utilisateur introuvable</Text>;
    }
    const imageUrl = userCard.profileImage;
    const handleMemberPress = () => {
        navigation.navigate('ProfileScreen', { userId: userCard._id, status: isOnline });
    };
    const cityArray = (_a = userCard.city) === null || _a === void 0 ? void 0 : _a.split(',');
    const city = cityArray && cityArray.length >= 2 ? cityArray[cityArray.length - 2].trim() : '';
    return (<ImageBackground source={{ uri: imageUrl }} style={styles.profileImageContainer}>
      <Pressable style={styles.card} onPress={handleMemberPress} key={isOnline ? 'online' : 'offline'}>
        <View style={styles.rowIcon}>
          <View style={styles.iconAchive}>
            <ProfileMedal points={userCard.appPoints} noOfSponsorships={((_b = userCard.sponsoredUsers) === null || _b === void 0 ? void 0 : _b.length) || 0} isAmbassador={false} size={24}/>
          </View>
          {isOnline && <View style={styles.statusOnline}/>}
        </View>
        <View style={styles.rowInfo}>
          <View>
            <Text style={styles.textWhite}>{userCard.userName}</Text>
            {userCard.useMyBirthday && userCard.age !== undefined && (<Text style={styles.textWhiteSmall}>{userCard.age} years </Text>)}
            <Text style={styles.textWhiteSmall}>{city.length > 16 ? city.slice(0, 16) + '...' : city}</Text>
          </View>
          {!userIsLiked ? (<Pressable onPress={toggleLike} style={styles.likeButton}>
              <Vectors.UsersInteractionBlackAndWhiteHeartGrey width={45} height={37}/>
            </Pressable>) : (<Pressable onPress={toggleLike} style={styles.likeButton}>
              <Vectors.UsersInteractionColorsHeartRed width={45} height={37}/>
            </Pressable>)}
        </View>
      </Pressable>
    </ImageBackground>);
};
const styles = StyleSheet.create({
    profileImageContainer: {
        flex: 1,
        aspectRatio: 1 / 1,
    },
    gradient: {
        flex: 1,
        justifyContent: 'center',
    },
    card: {
        margin: '5%',
        maxWidth: '100%',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
    },
    iconAchive: {
        top: 0,
        left: 0,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderRadius: 150,
        padding: 3,
        aspectRatio: 1.2 / 1,
        width: '20%',
    },
    statusOnline: {
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: '#2AE09F',
        position: 'absolute',
        right: 0,
        top: 0,
        borderWidth: 0.2,
        borderColor: '#000000',
    },
    textWhite: {
        color: 'white',
        textAlign: 'left',
        fontSize: 18,
        fontWeight: '400',
    },
    textWhiteSmall: {
        color: 'white',
        textAlign: 'left',
        fontSize: 15,
        fontWeight: '400',
    },
    rowInfo: {
        bottom: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    rowIcon: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    likeButton: {
        padding: 10,
    },
});
export default UserCard;
