// FR  rend l'écran des activités pour un profil utilisateur.
// EN  renders the activities screen for a user profile
import React, { useMemo } from 'react';
import { View, Text, Platform } from 'react-native';
import NextActivitiesBoard from './NextActivitiesBoard/Code';
import LastActionBoard from './LastActionBoard/Code';
import { activitiesIcon } from '../../../../constants/VectorsOnOff/ActivityListIcon';
import ProfilActivitiesBanner from '../../../../components/BannerSection/ProfilActivitiesBanner/Code';
import { useSelector } from 'react-redux';
const MyProfileActivitiesScreen = ({ profileOwner, allUsers, userActivities }) => {
    const styles = Platform.OS === 'web'
        ? require('./StylesWeb').default
        : require('./StylesMobile').default;
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const memoizedData = useMemo(() => {
        const now = new Date();
        const upcomingActivities = userActivities.filter((activity) => new Date(activity.date) > now);
        const pastActivities = userActivities.filter((activity) => new Date(activity.date) <= now);
        const userActivityVectors = activitiesIcon.filter((icon) => userActivities.some((activity) => activity.topic === icon.id - 1));
        const iconCounts = userActivities.reduce((acc, activity) => {
            acc[activity.topic + 1] = (acc[activity.topic + 1] || 0) + 1;
            return acc;
        }, {});
        return { upcomingActivities, pastActivities, userActivityVectors, iconCounts };
    }, [userActivities]);
    const { upcomingActivities, pastActivities, userActivityVectors, iconCounts } = memoizedData;
    const userName = (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.userName) || 'unknown';
    return (<View style={styles.container}>
            <View style={styles.tabsContainer}>
                {upcomingActivities.length > 0 ? (<View>
                        <ProfilActivitiesBanner Json={translations.JsonProfilePages.JsonMyProfileScreen.JsonNextJsonNoUpcomingActivities + Math.min(5, upcomingActivities.length) + translations.JsonProfilePages.JsonMyProfileScreen.JsonActivitiesParticipation + userName}/>
                        <NextActivitiesBoard users={allUsers} userActivities={upcomingActivities}/>
                    </View>) : (<Text>{userName + translations.JsonProfilePages.JsonMyProfileScreen.JsonNoUpcomingActivities}</Text>)}

                {pastActivities.length > 0 ? (<View>
                        <ProfilActivitiesBanner Json={translations.JsonProfilePages.JsonMyProfileScreen.JsonLastAction + userName}/>
                        <LastActionBoard users={allUsers} userActivities={pastActivities}/>
                    </View>) : (<Text>{userName + translations.JsonProfilePages.JsonMyProfileScreen.JsonDoesntParticipate}</Text>)}
            </View>
            <View style={styles.greyBar}>
                <Text style={styles.greyBarText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonActivitiesDone} :</Text>
            </View>
            <View style={styles.bottomVectors}>
                {userActivityVectors.map((icon) => (<View key={icon.id}>
                        {icon.activityIconOn}
                        <View style={styles.notifCount}>{iconCounts[icon.id]}</View>
                    </View>))}
            </View>
        </View>);
};
export default React.memo(MyProfileActivitiesScreen);
