import React, { useState, useEffect } from 'react';
import { View, Text, Modal, TouchableOpacity, Platform, FlatList } from 'react-native';
import TimePicker from '../TimePicker/Code';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const DatePickerModal = ({ onDateChange }) => {
    const { translations } = useSelector((state) => state.language);
    const currentLanguage = useSelector((state) => state.language.bigram);
    // Initialisation de selectedDate à la date du jour, mois, année
    const [selectedTime, setSelectedTime] = useState({ hour: 12, minute: 30 }); // Etat pour stocker l'heure et minute sélectionné
    const [selectedDate, setSelectedDate] = useState({
        day: new Date().getDate() + 1,
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear(),
    });
    const [modalVisible, setModalVisible] = useState(false); // État pour gérer la modal de date
    const [yearModalVisible, setYearModalVisible] = useState(false); // État pour gérer la modal de l'année
    const [timeModalVisible, setTimeModalVisible] = useState(false); // État pour gérer la modal de l'heure
    const [daysInMonth, setDaysInMonth] = useState(31); // Stocke le nombre de jours dynamiquement
    const [currentMonth, setCurrentMonth] = useState(selectedDate.month);
    const currentYear = new Date().getFullYear();
    const currentDay = new Date().getDate();
    const iscurrentMonth = new Date().getMonth() + 1;
    const years = Array.from({ length: 21 }, (_, i) => currentYear + i); // Liste des années
    const handleYearSelect = (year) => {
        setSelectedDate((prevDate) => (Object.assign(Object.assign({}, prevDate), { year: year })));
        setYearModalVisible(false); // Ferme le modal après la sélection
    };
    //Gestion du calendrier pour le premier jour du mois
    const getFirstDayOfMonth = (year, month) => {
        return new Date(year, month - 1, 1).getDay();
    };
    // Fonction pour calculer le nombre de jours d'un mois donné
    const getDaysInMonth = (year, month) => {
        return new Date(year, month, 0).getDate();
    };
    const createCalendarDays = (year, month, daysInMonth) => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = today.getMonth() + 1; // Mois courant (de 1 à 12)
        // Bloquer l'accès aux mois avant le mois actuel
        if (year < currentYear || (year === currentYear && month < currentMonth)) {
            return []; // Retourner un tableau vide si l'utilisateur essaie de naviguer vers un mois précédent
        }
        const firstDay = getFirstDayOfMonth(year, month);
        const maxDays = Math.min(daysInMonth, 31);
        const days = Array.from({ length: maxDays }, (_, i) => i + 1);
        const emptyDaysStart = Array.from({ length: (firstDay + 6) % 7 }, () => '');
        const totalDays = emptyDaysStart.length + days.length;
        const emptyDaysEnd = Array.from({ length: (totalDays % 7 === 0) ? 0 : 7 - (totalDays % 7) }, () => '');
        return [...emptyDaysStart, ...days, ...emptyDaysEnd];
    };
    //Création du calendrier
    const daysToDisplay = createCalendarDays(selectedDate.year, currentMonth, daysInMonth);
    // Utiliser useEffect pour recalculer les jours lorsqu'on change le mois ou l'année
    useEffect(() => {
        const newDaysInMonth = getDaysInMonth(selectedDate.year, currentMonth);
        setDaysInMonth(newDaysInMonth);
        // S'assurer que le jour sélectionné ne dépasse pas le nombre de jours dans le mois sélectionné
        if (selectedDate.day > newDaysInMonth) {
            setSelectedDate((prevDate) => (Object.assign(Object.assign({}, prevDate), { day: newDaysInMonth })));
        }
    }, [currentMonth, selectedDate.year]);
    // Gérer la sélection des valeurs de jour, mois et année dans un seul état
    const handleDateChange = (field, value) => {
        setSelectedDate((prevDate) => (Object.assign(Object.assign({}, prevDate), { [field]: parseInt(value, 10) })));
        setSelectedDate((prevDate) => (Object.assign(Object.assign({}, prevDate), { month: currentMonth })));
    };
    const closeTimePicker = () => {
        setTimeModalVisible(false); // Ferme la modal
    };
    const handleTimeSelected = (time) => {
        setSelectedTime(time);
    };
    useEffect(() => {
        if (selectedTime) {
            const selectedDateObject = new Date(selectedDate.year, selectedDate.month - 1, selectedDate.day, selectedTime.hour, selectedTime.minute);
            //Ajout de 2 heures pour UTC+2 (être raccord avec le temps selectionné)
            selectedDateObject.setUTCHours(selectedDateObject.getUTCHours() + 2);
            onDateChange(selectedDateObject);
        }
    }, [selectedTime]);
    // Gérer la confirmation de la date et ouvrir la modal de l'heure
    const handleConfirmDate = () => {
        setModalVisible(false);
        setTimeModalVisible(true);
    };
    // Fonction pour ajouter un 0 devant un nombre < 10
    const formatWithLeadingZero = (value) => {
        return value < 10 ? `0${value}` : value;
    };
    return (<View style={styles.container}>
      {/* Bouton pour ouvrir la modal */}
      <TouchableOpacity onPress={() => setModalVisible(true)} style={styles.selectButton}>
        <Text style={styles.headerText}>{formatWithLeadingZero(selectedDate.day)}/{formatWithLeadingZero(selectedDate.month)}/{selectedDate.year} {selectedTime ? `${formatWithLeadingZero(selectedTime.hour)}:${formatWithLeadingZero(selectedTime.minute)}` : ''}</Text>
      </TouchableOpacity>
      
      {/* Modal pour la sélection de la date */}
      <Modal animationType="slide" transparent={true} visible={modalVisible} onRequestClose={() => setModalVisible(false)}>
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <View style={styles.yearContainer}>
              {/* Bouton pour sélectionner l'année */}
              <TouchableOpacity onPress={() => setYearModalVisible(true)} style={styles.selectButton}>
                <Text style={styles.headerText}>{selectedDate.year}</Text>
              </TouchableOpacity>

              {/* Modal pour la sélection de l'année */}
              <Modal transparent={true} visible={yearModalVisible} animationType="slide">
                <View style={styles.modalContainer}>
                  <View style={styles.modalYearContent}>
                    <FlatList data={years} keyExtractor={(item) => String(item)} renderItem={({ item }) => (<TouchableOpacity style={[styles.yearButton, { backgroundColor: selectedDate.year === item ? '#59C09B' : 'white' }]} onPress={() => handleYearSelect(item)}>
                          <Text style={[styles.yearText, { color: selectedDate.year === item ? 'white' : 'black' }]}>{item}</Text>
                        </TouchableOpacity>)} numColumns={3}/>
                  </View>
                </View>
              </Modal>
            </View>
             {/* Affichage du mois avec boutons pour naviguer */}
            <View style={styles.monthContainer}>
              <TouchableOpacity onPress={() => setCurrentMonth((prev) => (prev === 1 ? 12 : prev - 1))} disabled={selectedDate.year === currentYear && currentMonth === iscurrentMonth}>                
                <Text style={selectedDate.year === currentYear && currentMonth === iscurrentMonth ? styles.headerTextDisable : styles.headerText}>≺</Text>
              </TouchableOpacity>
              <Text style={styles.headerText}>
                {new Date(selectedDate.year, currentMonth - 1).toLocaleString(currentLanguage, { month: 'long' })}
              </Text>
              <TouchableOpacity onPress={() => setCurrentMonth((prev) => (prev === 12 ? 1 : prev + 1))} disabled={currentMonth === 12 && iscurrentMonth != 1}>
                <Text style={currentMonth === 12 && iscurrentMonth != 1 ? styles.headerTextDisable : styles.headerText}>≻</Text>
              </TouchableOpacity>
            </View>

            {/* Affichage des jours de la semaine */}
            <View style={styles.weekContainer}>
              <Text style={{ color: '#A8A8A8', flex: 1, textAlign: 'center', fontWeight: 'bold' }}>{translations.JsonPickerDate.JsonMon}</Text>
              <Text style={{ color: '#A8A8A8', flex: 1, textAlign: 'center', fontWeight: 'bold' }}>{translations.JsonPickerDate.JsonTue}</Text>
              <Text style={{ color: '#A8A8A8', flex: 1, textAlign: 'center', fontWeight: 'bold' }}>{translations.JsonPickerDate.JsonWed}</Text>
              <Text style={{ color: '#A8A8A8', flex: 1, textAlign: 'center', fontWeight: 'bold' }}>{translations.JsonPickerDate.JsonThu}</Text>
              <Text style={{ color: '#A8A8A8', flex: 1, textAlign: 'center', fontWeight: 'bold' }}>{translations.JsonPickerDate.JsonFri}</Text>
              <Text style={{ color: '#A8A8A8', flex: 1, textAlign: 'center', fontWeight: 'bold' }}>{translations.JsonPickerDate.JsonSat}</Text>
              <Text style={{ color: '#A8A8A8', flex: 1, textAlign: 'center', fontWeight: 'bold' }}>{translations.JsonPickerDate.JsonSun}</Text>
            </View>

            {/* Affichage des jours du mois */}
            <View style={{ flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
              {daysToDisplay.map((day, index) => (<TouchableOpacity key={index} onPress={() => handleDateChange('day', day)} disabled={Number(day) < currentDay + 1 && currentMonth === iscurrentMonth && selectedDate.year === currentYear} // Empêcher le clic pour les jours passés
         style={[day === currentDay && currentMonth === iscurrentMonth && selectedDate.year === currentYear ? styles.dayContainerToday : styles.dayContainer, { backgroundColor: selectedDate.day === day && currentMonth === selectedDate.month ? '#59C09B' : 'white' }]}>
                  <Text style={{ color: selectedDate.day === Number(day) && currentMonth === selectedDate.month ? 'white' : (Number(day) < currentDay + 1 && currentMonth === iscurrentMonth && selectedDate.year === currentYear ? 'lightgrey' : 'black') }}>{day !== '' ? day : ''}</Text>
                </TouchableOpacity>))}
            </View>
            <View style={styles.separator}/>
            {/* Boutons dans la modal */}    
            <View style={styles.buttonBottomContainer}>
              <TouchableOpacity style={styles.buttonBottomContent} onPress={() => setModalVisible(false)}>
                  <Text style={{ color: 'black' }}>{translations.JsonPickerDate.JsonCancel}</Text>
                </TouchableOpacity>
              <TouchableOpacity style={styles.buttonBottomContent} onPress={handleConfirmDate}>
                <Text style={{ color: 'black' }}>{translations.JsonPickerDate.JsonConfirm}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
      {timeModalVisible && (<TimePicker isVisible={timeModalVisible} onTimeSelected={handleTimeSelected} onClose={closeTimePicker}/>)}
    </View>);
};
export default DatePickerModal;
