import React from 'react';
import { Modal, TouchableWithoutFeedback, View, Text, Platform, TouchableOpacity } from "react-native";
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
export default function ChatMenuModal({ onClose, isVisible, activityId }) {
    return (<Modal animationType="fade" transparent={true} visible={isVisible} onRequestClose={onClose}>
        <TouchableWithoutFeedback onPress={onClose}>
          <View style={styles.modalContainer}>
            <TouchableWithoutFeedback>
              <View style={styles.modalContent}>
                <TouchableOpacity style={styles.modalButton} onPress={() => { }}>
                  <Text style={styles.modalText}>Rechercher</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.modalButton} onPress={() => { }}>
                  <Text style={styles.modalText}>Signaler</Text>
                </TouchableOpacity>
                <TouchableOpacity style={styles.modalButton} onPress={() => { }}>
                  <Text style={styles.modalText}>Notification en mode silencieux</Text>
                </TouchableOpacity>
              </View>
            </TouchableWithoutFeedback>
          </View>
        </TouchableWithoutFeedback>
      </Modal>);
}
