//FR permet à un utilisateur de récupérer les utilisateurs qu'il a aimés, d'ajouter un utilisateur à sa liste d'utilisateurs aimés et de supprimer un utilisateur de sa liste d'utilisateurs aimés.
//EN allows a user to retrieve the users they have liked, add a user to their list of liked users, and remove a user from their list of liked users.
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Import Modules
// import AsyncStorage from '@react-native-async-storage/async-storage';
import Axios from "../Axios/AxiosService";
export const getUserLikes = (userId) => __awaiter(void 0, void 0, void 0, function* () {
    // const token = await AsyncStorage.getItem('userToken');
    // const userId = await AsyncStorage.getItem('userId');
    // const response = await fetch(`${hostname}/userLikes`, {
    //   method: 'GET',
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     'Content-Type': 'application/json',
    //   },
    // });
    try {
        const response = yield Axios.get('/userLikes');
        const data = response.data;
        const userLikes = data.filter((like) => like.likedUser === userId);
        return userLikes;
    }
    catch (error) {
        console.error('Error fetching user likes:', error);
        return false;
    }
});
export const addUserLike = (userId, likedUserId) => __awaiter(void 0, void 0, void 0, function* () {
    // const token = await AsyncStorage.getItem('userToken');
    // const userId = await AsyncStorage.getItem('userId');
    // Update Logged In User
    // const response = await fetch(`${hostname}/userLikes`, {
    //   method: 'POST',
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({
    //     likedBy: userId,
    //     likedUser: likedUserId,
    //     type: "like"
    //   }),
    // });
    try {
        const options = {
            method: 'POST',
            url: `/users`,
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify({
                likedBy: userId,
                likedUser: likedUserId,
                type: "like"
            }),
        };
        const response = yield Axios.request(options).then(response => response.data)
            .catch(error => false);
        if (response) {
            console.info("You Liked this User");
            return true;
        }
        else
            return false;
    }
    catch (error) {
        console.error('Error while adding a user', error);
        return null;
    }
});
export const deleteUserLike = (userId, unlikedUserId) => __awaiter(void 0, void 0, void 0, function* () {
    // const token = await AsyncStorage.getItem('userToken');
    // const userId = await AsyncStorage.getItem('userId');
    // let likeToDelete = {};
    // const getUserLikes = async () => {
    //   const response = await fetch(`${hostname}/userLikes`, {
    //     method: 'GET',
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //       'Content-Type': 'application/json',
    //     },
    //   });
    // }
    try {
        let likeToDelete = null;
        const data = yield getUserLikes(userId);
        // On utilise .some() method pour avoir un booleen
        likeToDelete = data.find((like) => like.likedBy === userId && like.likedUser === unlikedUserId);
        if (!likeToDelete)
            return false;
        // const response = await fetch(`${hostname}/userLikes/${likeToDelete._id}`, {
        //   method: 'DELETE',
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //     'Content-Type': 'application/json',
        //   },
        // });
        const response = yield Axios.delete(`/userLikes/${likeToDelete._id}`).then(response => response.data)
            .catch(error => false);
        if (response) {
            console.info("You UnLiked this User");
            return true;
        }
        else
            return false;
    }
    catch (error) {
        console.error('Error deleting a like', error);
        return null;
    }
});
