// 🇫🇷 cette page affiche les informations sur différentes offres de badges premium pour une adhésion (FrameFigma050B)
// 🇬🇧 This page displays information about various premium badge offers for a membership (FrameFigma050B)
import React from "react";
import { Text, View, Pressable, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
import ProfileMedal from '../../../../components/ProfileSection/ProfileMedal/Code';
// import des Vectors
import { Vectors } from "../../../../constants/Vectors";
import { useSelector } from "react-redux";
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const Test1 = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const navigation = useNavigation();
    // 🇫🇷 Trait permettant d'afficher la separation entre les étoiles(Golden, Emurald, Ruby ,Diamond) et les parrainages
    // 🇬🇧 Feature for displaying the separation between the stars (Golden, Emerald, Ruby, Diamond) and sponsorships
    const SeparatorG = () => {
        return <View style={styles.separatorG}/>;
    };
    const SeparatorR = () => {
        return <View style={styles.separatorR}/>;
    };
    const SeparatorE = () => {
        return <View style={styles.separatorE}/>;
    };
    const SeparatorD = () => {
        return <View style={styles.separatorD}/>;
    };
    return (<View style={styles.container}>
       <View style={styles.titleContainer}>
          <Text style={styles.title}>
            {translations.JsonAchievementsPages.JsonVipBadgesScreen.JsonBecomeADonator}
          </Text>
        </View>

        {/*GoldButton*/}
        <Pressable style={styles.button} onPress={() => navigation.navigate('VipBadgeGold')}>
          <View style={styles.buttonContainer}>
            <Vectors.GoldSmallBorder style={styles.imgBord}/>
            <View style={styles.textContainer}>
              <Text style={styles.goldText}>
                {translations.JsonAchievementsPages.JsonGoldStar}
              </Text>
              <SeparatorG />
              <Text style={styles.goldText}>
                {translations.JsonAchievementsPages.JsonSponsorshipScreen.Json10Sponsorship}
              </Text>
            </View>
            <View style={{ marginRight: '5%' }}>
              <ProfileMedal points={5000} noOfSponsorships={10} isAmbassador={false} size={65}/>
            </View>        
          </View>  
        </Pressable>
        
        {/* RubyButton */}
        <Pressable style={styles.button} onPress={() => navigation.navigate('VipBadgeRuby')}>
          <View style={styles.buttonContainer}>
            <Vectors.RubySmallBorder style={styles.imgBord}/>
            <View style={styles.textContainer}>
              <Text style={styles.rubyText}>
                {translations.JsonAchievementsPages.JsonRubyStar}
              </Text>
              <SeparatorR />
              <Text style={styles.rubyText}>
                {translations.JsonAchievementsPages.JsonSponsorshipScreen.Json20Sponsorship}
              </Text>
            </View>
            <View style={{ marginRight: '5%' }}>
              <ProfileMedal points={5000} noOfSponsorships={25} isAmbassador={false} size={65}/>
            </View>        
          </View>  
        </Pressable>        

        {/*EmeraldButton*/}
        <Pressable style={styles.button} onPress={() => navigation.navigate('VipBadgeEmerald')}>
          <View style={styles.buttonContainer}>
            <Vectors.EmeraldSmallBorder style={styles.imgBord}/>
            <View style={styles.textContainer}>
              <Text style={styles.emeraldText}>
                {translations.JsonAchievementsPages.JsonEmeraldStar}
              </Text>
              <SeparatorE />
              <Text style={styles.emeraldText}>
                {translations.JsonAchievementsPages.JsonSponsorshipScreen.Json50Sponsorship}
              </Text>
            </View>
            <View style={{ marginRight: '5%' }}>
              <ProfileMedal points={5000} noOfSponsorships={50} isAmbassador={false} size={65}/>
            </View>        
          </View>  
        </Pressable>  

        {/*DiamondButton*/}
        <Pressable style={styles.button} onPress={() => navigation.navigate('VipBadgeDiamond')}>
          <View style={styles.buttonContainer}>
            <Vectors.DiamandSmallBorder style={styles.imgBord}/>
            <View style={styles.textContainer}>
              <Text style={styles.diamondText}>
                {translations.JsonAchievementsPages.JsonDiamondStar}
              </Text>
              <SeparatorD />
              <Text style={styles.diamondText}>
                {translations.JsonAchievementsPages.JsonSponsorshipScreen.Json100Sponsorship}
              </Text>
            </View>
            <View style={{ marginRight: '5%' }}>
              <ProfileMedal points={5000} noOfSponsorships={100} isAmbassador={false} size={65}/>
            </View>        
          </View>  
        </Pressable> 
      </View>);
};
export default Test1;
