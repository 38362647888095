var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Axios from "../Axios/AxiosService";
const getAuthorByFilter = (Aid, allUsers) => {
    return allUsers.find((user) => user._id === Aid);
}; // Returns the author of the activity as an object
const getAuthor = (Aid) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield Axios.get("/users/" + Aid);
        return response.data;
    }
    catch (error) {
        try {
            const response = yield Axios.get("/users/66928dd94d84ce1f98b910bb");
            return response.data;
        }
        catch (error) {
            console.error(error);
            return null;
        }
    }
});
const getAuthorStatus = (Aid, setStatus) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield Axios.get(`/users/${Aid}/status`);
        if (setStatus) {
            setStatus(response.data.status);
        }
        return response.data.status;
    }
    catch (error) {
        console.error(error);
        return null;
    }
});
const isAuthor = (authorId, userId) => authorId === userId;
export const authorServices = {
    getAuthor,
    getAuthorByFilter,
    getAuthorStatus,
    isAuthor
};
