// Import Modules
import React from "react";
import { Modal, Pressable, StyleSheet, View } from "react-native";
// Import Vectors (icons)
import { Vectors } from "../../../../constants/Vectors";
const MyModal = ({ modalVisible, onClose, children, w, h = 'auto', jC = 'center', animation = 'fade', bgC = 'white', crossView = true, bR = 0, pad = 50, padBot = 50, top = 0, right = 0, bottom = 0, left = 0, }) => {
    const styles = StyleSheet.create({
        container: {
            flex: 1,
            justifyContent: jC,
            alignItems: 'center',
            padding: pad,
            elevation: 10,
            top: top,
            right: right,
            bottom: bottom,
            left: left,
        },
        modal: {
            width: w,
            height: h,
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: bgC,
            gap: 20,
            padding: 10,
            borderRadius: bR,
            boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
            paddingBottom: padBot,
        },
        close: {
            alignSelf: 'flex-end',
        },
    });
    return (<Modal animationType={animation} transparent={true} visible={modalVisible} onRequestClose={onClose}>
            <View style={styles.container}>
                <View style={styles.modal}>
                    {crossView && (<Pressable onPress={onClose} style={styles.close}>
                            <Vectors.CloseSignGreen width={30} height={30}/>
                        </Pressable>)}
                    {children}
                </View>
            </View>
        </Modal>);
};
export default MyModal;
