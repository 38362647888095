//FR  permet d'inviter des amis via TikTok
//EN allows users to invite friends via TikTok
import React from 'react';
import { View, Text, Pressable, Linking } from 'react-native';
import { useSelector } from 'react-redux';
const InviteByTikTok = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const inviteByTikTok = () => {
        // Note: Direct app linking to TikTok for sharing is limited.
        // This example just attempts to open the TikTok app.
        const tiktokLink = 'tiktok://';
        Linking.openURL(tiktokLink);
    };
    return (<View>
      <Pressable onPress={inviteByTikTok}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteTiktok}</Text>
      </Pressable>
    </View>);
};
export default InviteByTikTok;
