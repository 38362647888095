//FR affiche les prochaines activités dans le profil utilisateur
// displays the upcoming activities in the user profile
// Import Modules
import { View, Text, Platform } from 'react-native';
import React from 'react';
// Import Contexts
// Import Vectors
import { Vectors } from '../../../../../constants/Vectors';
// Import Styles
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
import { useSelector } from 'react-redux';
const ActivitiesProchaines = ({ users, userActivities }) => {
    // Contexts
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    console.log(userActivities);
    // Map Activities to Organisers
    //filtre activity pas encore passé
    // activity.filter(function(item){return new Date(activity.date)>new Date()})
    const activitiesWithOrganizers = userActivities
        .filter((activity) => new Date(activity.date) > new Date())
        .map(activity => {
        const organizer = users.find(user => user._id === activity.author);
        const organizerName = organizer ? organizer.firstName : "Unknown";
        return Object.assign(Object.assign({}, activity), { organizerName });
    });
    function convertFormatDate(activityDate) {
        // Séparer le jour, le mois et l'année en utilisant "."
        const inputDate = new Date(activityDate).toLocaleDateString();
        const parts = inputDate.split(".");
        const day = parts[0];
        const month = parts[1];
        const year = parts[2];
        // Construire la nouvelle date au format "jour.mois.année"
        const newDate = `${day}.${month}.${year.slice(-2)}`;
        return newDate;
    }
    return (<View style={{ flex: 1, paddingTop: 10 }}>
      <View style={{
            flexDirection: "column",
            borderColor: "#797B7A",
            marginBottom: 10,
        }}>
        <View style={Object.assign(Object.assign({}, styles.boxShadow), { flexDirection: "row", borderBottomWidth: 1, borderColor: "#797B7A", background: "#8D9FA9", paddingHorizontal: "2%" })}>
          {/* Case avec l'icone calendrier qui correspond à la partie 'Date' */}
          <View style={Object.assign(Object.assign({}, styles.column), { flexDirection: "row", alignItems: "center", justifyContent: "center", background: "#556774", borderColor: "white" })}>
            <Text style={Object.assign(Object.assign({}, styles.column), { width: 100, paddingTop: 5 })}>
              <Vectors.Calendar width={20} height={20}/>
            </Text>
          </View>
          {/* Case avec les icones activité + calendrier qui correspond à la partie 'Titre Activité' */}
          <View style={Object.assign(Object.assign({}, styles.column), { flex: 2, flexDirection: "row", alignItems: "center", justifyContent: "center", background: "#8D9FA9", borderColor: "white" })}>
            <Text style={{ marginRight: 5, fontWeight: "bold", color: "#fff" }}>
              {translations.JsonProfilePages.JsonMyProfileScreen.JsonActivities}
            </Text>
            <Vectors.CalendarOn width={20} height={20}/>
          </View>
          {/* Case avec l'icone des participants qui correspond à la partie 'Nombre Participants' */}
          <View style={Object.assign(Object.assign({}, styles.column), { flexDirection: "row", alignItems: "center", justifyContent: "center", background: "#556774", borderColor: "white" })}>
            <Text style={Object.assign(Object.assign({}, styles.column), { width: 100 })}>
              <Vectors.NavbarMembersBottom width={20} height={20}/>
            </Text>
          </View>
          {/* Case avec le text Organisateur qui correspond à la partie 'Auteur Activité' */}
          <View style={Object.assign(Object.assign({}, styles.column), { width: 100, alignItems: "center", justifyContent: "center", paddingLeft: 5, paddingRight: 5, borderColor: "white", borderRightWidth: 0, color: "#fff" })}>
            <Text style={{ fontWeight: "bold", color: "#fff" }}>
              {translations.JsonContactScreen.JsonOrganizer}
            </Text>
          </View>
        </View>
        {/* afficher max les 5 premiers activities, dont activities les plus recents */}
        {activitiesWithOrganizers.slice(0, 5).map((activity, index) => (<View key={index} style={{
                flexDirection: "row",
                borderBottomWidth: 1,
                borderColor: "#797B7A",
                backgroundColor: index % 2 === 0 ? "#fff" : "#f2f2f2",
                marginHorizontal: "2%",
            }}>
            <Text style={Object.assign(Object.assign({}, styles.column), { width: 100 })}>
              {convertFormatDate(activity.date)}
            </Text>
            <View style={Object.assign(Object.assign({}, styles.column), { flex: 2, flexDirection: "row", alignItems: "flex-end", color: "#59C19C", justifyContent: "space-between" })}>
              <Text style={styles.greenBold}>
                {/* afficher max 17 lettres  */}
                {activity.title.length > 17
                ? activity.title.substring(0, 17) + '...'
                : activity.title}
              </Text>
              <Text style={styles.fleche}>{'>'}</Text>
            </View>
            <Text style={Object.assign(Object.assign({}, styles.column), { width: 100 })}>
              {activity.attendees.length}/{activity.attendeeLimit}
            </Text>
            <Text style={Object.assign(Object.assign({}, styles.column), { width: 100, overflow: "hidden", whiteSpace: "nowrap", paddingLeft: 5, paddingRight: 5, borderColor: 'white', textAlign: 'left' })}>
              {activity.organizerName}

            </Text>
          </View>))}
      </View>
    </View>);
};
export default ActivitiesProchaines;
