var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ActivityServices } from "./ActivityServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
// Get the array of interested user IDs from an activity
const getInterestedArray = (activity) => {
    return activity.interested || []; // Return an empty array if 'interested' is undefined
};
// Add the user ID to the array of interested users for an activity
const addUserIdToInterestedArray = (activity) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userId = yield AsyncStorage.getItem('userId');
        // Check if userId or activity._id is null
        if (!userId || !activity._id) {
            throw new Error('User ID or Activity ID is null');
        }
        if (!activity.interested) {
            activity.interested = []; // Ensure 'interested' is defined
        }
        if (activity.interested.includes(userId)) {
            return; // User already in the interested list
        }
        const updatedActivity = Object.assign(Object.assign({}, activity), { interested: [...activity.interested, userId] });
        const response = yield ActivityServices.updateActivity(activity._id, updatedActivity);
        return response.data.comments;
    }
    catch (error) {
        console.error('Error adding interested:', error);
    }
});
// Export InterestedServices
export const InterestedServices = {
    getInterestedArray,
    addUserIdToInterestedArray
};
