// Fr  afficher les drapeaux (FrameFigma002,FrameFigma002A,FrameFigma002B,FrameFigma002C)
// En  show The flags (FrameFigma002,FrameFigma002A,FrameFigma002B,FrameFigma002C)
import FrenchFlag from '../../../assets/Vectors/Flags/Round/French/Fr.svg';
import EnglishFlag from '../../../assets/Vectors/Flags/Round/English/En.svg';
import SpanishFlag from '../../../assets/Vectors/Flags/Round/Spanish/Es.svg';
import GermanFlag from '../../../assets/Vectors/Flags/Round/German/De.svg';
import ItalianFlag from '../../../assets/Vectors/Flags/Round/Italian/It.svg';
import PortugueseFlag from '../../../assets/Vectors/Flags/Round/Portuguese/Pt.svg';
import RussianFlag from '../../../assets/Vectors/Flags/Round/Russian/Ru.svg';
import HindiFlag from '../../../assets/Vectors/Flags/Round/Hindi/Hi.svg';
import ChineseFlag from '../../../assets/Vectors/Flags/Round/Chinese/Zh.svg';
import ArabicFlag from '../../../assets/Vectors/Flags/Round/Arabic/Ar.svg';
import HebrewFlag from '../../../assets/Vectors/Flags/Round/Hebrew/He.svg';
import JapaneseFlag from '../../../assets/Vectors/Flags/Round/Japanese/Ja.svg';
import BosnianFlag from '../../../assets/Vectors/Flags/Round/Bosnian/Bs.svg';
import BulgarianFlag from '../../../assets/Vectors/Flags/Round/Bulgarian/Bg.svg';
import CroatianFlag from '../../../assets/Vectors/Flags/Round/Croatian/Hr.svg';
import CzechFlag from '../../../assets/Vectors/Flags/Round/Czech/Cs.svg';
import DanishFlag from '../../../assets/Vectors/Flags/Round/Danish/Da.svg';
import EstonianFlag from '../../../assets/Vectors/Flags/Round/Estonian/Et.svg';
import FinnishFlag from '../../../assets/Vectors/Flags/Round/Finnish/Fi.svg';
import GreekFlag from '../../../assets/Vectors/Flags/Round/Greek/El.svg';
import HungarianFlag from '../../../assets/Vectors/Flags/Round/Hungarian/Hu.svg';
import IcelandicFlag from '../../../assets/Vectors/Flags/Round/Icelandic/Is.svg';
import IrishFlag from '../../../assets/Vectors/Flags/Round/Irish/Ga.svg';
import LatvianFlag from '../../../assets/Vectors/Flags/Round/Latvian/Lv.svg';
import LithuanianFlag from '../../../assets/Vectors/Flags/Round/Lithuanian/Lt.svg';
import MadagascarFlag from '../../../assets/Vectors/Flags/Round/Malagasy/Mg.svg';
import DutchFlag from '../../../assets/Vectors/Flags/Round/Dutch/Nl.svg';
import NorwegianFlag from '../../../assets/Vectors/Flags/Round/Norwegian/No.svg';
import PolishFlag from '../../../assets/Vectors/Flags/Round/Polish/Pl.svg';
import RomanianFlag from '../../../assets/Vectors/Flags/Round/Romanian/Ro.svg';
import ScottishFlag from '../../../assets/Vectors/Flags/Round/ScottishGaelic/Gd.svg';
import SerbianFlag from '../../../assets/Vectors/Flags/Round/Serbian/Sr.svg';
import SlovakianFlag from '../../../assets/Vectors/Flags/Round/Slovak/Sk.svg';
import SlovenianFlag from '../../../assets/Vectors/Flags/Round/Slovenian/Sl.svg';
import SwedishFlag from '../../../assets/Vectors/Flags/Round/Swedish/Sv.svg';
import UkrainianFlag from '../../../assets/Vectors/Flags/Round/Ukrainian/Uk.svg';
const countriesListCircleFlags = [
    { language: 'French', FlagComponent: FrenchFlag, bigram: 'Fr' },
    { language: 'English', FlagComponent: EnglishFlag, bigram: 'En' },
    { language: 'Spanish', FlagComponent: SpanishFlag, bigram: 'Es' },
    { language: 'German', FlagComponent: GermanFlag, bigram: 'De' },
    { language: 'Italian', FlagComponent: ItalianFlag, bigram: 'It' },
    { language: 'Portuguese', FlagComponent: PortugueseFlag, bigram: 'Pt' },
    { language: 'Russian', FlagComponent: RussianFlag, bigram: 'Ru' },
    { language: 'Hindi', FlagComponent: HindiFlag, bigram: 'Hi' },
    { language: 'Chinese', FlagComponent: ChineseFlag, bigram: 'Zh' },
    { language: 'Arabic', FlagComponent: ArabicFlag, bigram: 'Ar' },
    { language: 'Hebrew', FlagComponent: HebrewFlag, bigram: 'He' },
    { language: 'Japanese', FlagComponent: JapaneseFlag, bigram: 'Ja' },
    { language: 'Bosnian', FlagComponent: BosnianFlag, bigram: 'Bs' },
    { language: 'Bulgarian', FlagComponent: BulgarianFlag, bigram: 'Bg' },
    { language: 'Croatian', FlagComponent: CroatianFlag, bigram: 'Hr' },
    { language: 'Czech', FlagComponent: CzechFlag, bigram: 'Cs' },
    { language: 'Danish', FlagComponent: DanishFlag, bigram: 'Da' },
    { language: 'Estonian', FlagComponent: EstonianFlag, bigram: 'Et' },
    { language: 'Finnish', FlagComponent: FinnishFlag, bigram: 'Fi' },
    { language: 'Greek', FlagComponent: GreekFlag, bigram: 'El' },
    { language: 'Hungarian', FlagComponent: HungarianFlag, bigram: 'Hu' },
    { language: 'Icelandic', FlagComponent: IcelandicFlag, bigram: 'Is' },
    { language: 'Irish', FlagComponent: IrishFlag, bigram: 'Ga' },
    { language: 'Latvian', FlagComponent: LatvianFlag, bigram: 'Lv' },
    { language: 'Lithuanian', FlagComponent: LithuanianFlag, bigram: 'Lt' },
    { language: 'Malagasy', FlagComponent: MadagascarFlag, bigram: 'Mg' },
    { language: 'Dutch', FlagComponent: DutchFlag, bigram: 'Nl' },
    { language: 'Norwegian', FlagComponent: NorwegianFlag, bigram: 'No' },
    { language: 'Polish', FlagComponent: PolishFlag, bigram: 'Pl' },
    { language: 'Romanian', FlagComponent: RomanianFlag, bigram: 'Ro' },
    { language: 'Scottish Gaelic', FlagComponent: ScottishFlag, bigram: 'Gd' },
    { language: 'Serbian', FlagComponent: SerbianFlag, bigram: 'Sr' },
    { language: 'Slovak', FlagComponent: SlovakianFlag, bigram: 'Sk' },
    { language: 'Slovenian', FlagComponent: SlovenianFlag, bigram: 'Sl' },
    { language: 'Swedish', FlagComponent: SwedishFlag, bigram: 'Sv' },
    { language: 'Ukrainian', FlagComponent: UkrainianFlag, bigram: 'Uk' },
];
export default countriesListCircleFlags;
