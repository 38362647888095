var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// 🇫🇷 Ce fichier gère la page où on rentre notre mot de passe lors du login/register si l'email rentré est un email qui existe dans la base de donnée (FrameFigma006a)
// 🇬🇧 This file manages the page where we enter our password during login/register if the email entered is an email that exists in the database (FrameFigma006a)
import React, { useState, useEffect } from 'react';
import { View, Text, Linking, Platform, TouchableOpacity, Pressable } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Yup from 'yup';
import Vectors from '../../../../constants/Vectors';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import { hostname } from '../../../../../hostName/hostName';
import { useError } from '../../../../contexts/ErrorContext';
import { useDispatch, useSelector } from 'react-redux';
import { nanoid } from '@reduxjs/toolkit';
import UserSlice from '../../../../redux/Features/User/UserSlice';
import AuthSlice from '../../../../redux/Features/Auth/AuthSlice';
import { userServices } from '../../../../services/user/UserServices';
import { setToken } from '../../../../services/Axios/AxiosService';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const ResetExistingUserPasswordScreen = ({ navigation }) => {
    const { translations, language } = useSelector((state) => state.language);
    // const { updateUserState } = useUser();
    const { error, setError } = useError();
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const updateUserState = (loginData) => {
        dispatch(UserSlice.actions.setUser({ id: nanoid(), value: loginData }));
    };
    const login = (token, userData) => {
        dispatch(AuthSlice.actions.login({ id: nanoid(), value: { token, userData } }));
        setToken(token);
    };
    useEffect(() => {
        getEmailFromStorage();
    }, []);
    /**
     * 🇬🇧 Retrieve email from storage and set it in the state.
     * 🇫🇷 Récupérer l'e-mail du stockage et le définir dans l'état.
     */
    const getEmailFromStorage = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // Retrieve email from AsyncStorage that we put from the emailscreen page
            const storedEmail = yield AsyncStorage.getItem('email');
            if (storedEmail) {
                //console.log('Email retrieved from storage:', storedEmail);
                setEmail(storedEmail);
            }
        }
        catch (error) {
            console.error("Erreur lors de la récupération de l'email depuis AsyncStorage :", error);
        }
    });
    /**
     * 🇬🇧 Validation schema using Yup to ensure password format and requirement.
     * 🇫🇷 Schéma de validation utilisant Yup pour garantir le format et l'exigence du mot de passe.
     */
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordErrorMessageTooShort)
            .required(translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordErrorMessageEmpty),
    });
    /**
     * 🇬🇧 Handle next button press, validate password, and process login or send verification email.
     * 🇫🇷 Gérer l'appui sur le bouton suivant, valider le mot de passe et traiter la connexion ou envoyer un e-mail de vérification.
     */
    const handleNext = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // Validate the password against the schema
            yield validationSchema.validate({ password });
            //console.log('Email for login:', email);
            // Attempt to log in with the provided email and password
            const loginResponse = yield userServices.logUser(email, password);
            if (!loginResponse.request) {
                setError(loginResponse.error);
                return;
            }
            else {
                if (loginResponse.loginData) {
                    // supprimer les deux lignes userToken
                    // console.log("stokage du token et de l'id ", loginResponse.loginData.authToken, loginResponse.loginData.user._id)
                    yield AsyncStorage.setItem('authToken', loginResponse.loginData.authToken);
                    yield AsyncStorage.setItem('userToken', loginResponse.loginData.authToken);
                    yield AsyncStorage.setItem('userId', loginResponse.loginData.user._id);
                    // console.log("verification ok the login data ", loginResponse.loginData.user);
                    login(loginResponse.loginData.authToken, loginResponse.loginData.user);
                    updateUserState(loginResponse.loginData.user);
                    // navigation.navigate('Activities', { userId: loginData.user._id });
                    setError('');
                    return;
                }
                else if (loginResponse.action === 'email') {
                    console.log("email to send");
                    yield sendVerificationEmail(email);
                    navigation.navigate('VerifyEmailExistingUserScreen', { newPassword: password });
                    return;
                }
                else if (loginResponse.loginData == null) {
                    console.log("login sans data");
                    setError(translations.JsonHomeScreen.JsonConnectionIssues);
                    return;
                }
                else if (loginResponse.action === 'connectionIssue') {
                    console.log("login error");
                    setError(translations.JsonHomeScreen.JsonConnectionIssues);
                    return;
                }
            }
            // // Handle the response from the login attempt
            // if (loginResponse.ok) {
            //   const loginData = await loginResponse.json();
            //   //console.log('Login response data:', loginData);
            //   // If the login is successful, store the user data and navigate to the Activities screen
            //   if (loginData.authToken && loginData.user) {
            //     await AsyncStorage.setItem('authToken', loginData.authToken);
            //     await AsyncStorage.setItem('userData', JSON.stringify(loginData.user));
            //     await AsyncStorage.setItem('userId', loginData.user._id);
            //     await AsyncStorage.setItem('userEmail', loginData.user.email);
            //     await AsyncStorage.setItem('profileImage', loginData.user.profileImage);
            //     await AsyncStorage.setItem('userName', loginData.user.userName);
            //     login(loginData.authToken, loginData.user);
            //     updateUserState(loginData.user);
            //     // navigation.navigate('Activities', { userId: loginData.user._id });
            //     setError('');
            //   } else {
            //     setError(translations.JsonHomeScreen.JsonConnectionIssues);
            //   }
            // } else if (loginResponse.status === 401) {
            //   // If the password is incorrect, send a verification email
            //   //console.log('Incorrect password, sending verification email');
            //   await sendVerificationEmail(email);
            //   navigation.navigate('VerifyEmailExistingUserScreen', { newPassword: password });
            // } else {
            //   setError(translations.JsonHomeScreen.JsonConnectionIssues);
            // }
        }
        catch (validationError) {
            console.error('Validation error:', validationError);
            setError(validationError.message);
        }
    });
    /**
     * 🇬🇧 Send verification email to the provided email address.
     * 🇫🇷 Envoyer un e-mail de vérification à l'adresse e-mail fournie.
     */
    const sendVerificationEmail = (email) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            //console.log('Sending verification email to:', email);
            yield fetch(`${hostname}/sendVerificationExistingUser`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
        }
        catch (error) {
            console.error("Erreur lors de l'envoi de la demande de vérification par e-mail", error);
        }
    });
    /**
     * 🇬🇧 Handle password text change and reset error if any.
     * 🇫🇷 Gérer le changement de texte du mot de passe et réinitialiser le message d'erreur s'il y en a.
     */
    const handleTextChange = (text) => {
        setPassword(text);
        if (error) {
            setError('');
        }
    };
    return (<View key={language} style={styles.container}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <View style={styles.passwordContent}>
        <TextInputCustomComponent Icon={Vectors.ProfilesPasswordPadLock} placeholderText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordPlaceholder} value={password} handleTextChange={handleTextChange} isPlaceHolderTextAlignCenter={false} isPassword={!isPasswordVisible}/>
      </View>
      <View style={styles.buttonContainer}>
        <ButtonPrevious onPrevStep={() => navigation.navigate('Email')} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
        <ButtonNext handleNextStep={handleNext} btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton} conditionNeed={password.length >= 8}/>
      </View>
      <View style={styles.forgotPassword}>
        <Text style={styles.forgotTxt}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonForgotPassword}</Text>
        <View style={styles.btnForgotContainer}>
          <TouchableOpacity style={styles.btnForgot} onPress={() => navigation.navigate('ResetPassword')}>
            <Text style={styles.btnTextForgot}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonResetPasswordButton}</Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={styles.condition}>
        <Text style={styles.terms}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonDisclaimer}</Text>
        <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
          <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonTermsAndConditions}</Text>
        </Pressable>
        <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
          <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPrivacyPolicy}</Text>
        </Pressable>
      </View>
    </View>);
};
export default ResetExistingUserPasswordScreen;
