// Fr Cette page affiche des details sur une activité pouvoir participer ou partager (FrameFigma041)
// En This page displays details about an activity able to participate or share it(FrameFigma041)
import React, { useState } from 'react';
import { View, Text, Platform, Pressable, ScrollView } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
// Import Contexts
// Import Components
import ActivityDetailsAuthorComponent from '../../../../../components/ActivitySection/ActivityDetailsAuthorOrganiserComponent/Code';
// import ActivityDetailsButtonTopComponent from '../../../../components/ActivityDetails/buttons/top/ActivityDetailsButtonsTopComponent';
// import ActivityDetailsButtonsButtonComponent from '../../../../components/ActivityDetails/buttons/bottom/ActivityDetailsButtonsBottomComponent';
import ActivityDetailsHeaderComponent from '../../../../../components/ActivitySection/ActivityDetailsHeaderComponent/Code';
// import ParticipantCard from '../../../../../components/Card/ParticipantCard';
import MyButtonPress from '../../../../../components/ButtonSection/PressButton/Code';
import ActivityDetailsParticipantsDisplay from '../../../../../components/ActivitySection/ActivityDetailsParticipantsDisplayComponent/Code';
// Import services
import { ParticipantsServices } from '../../../../../services/activities/ParticipantsServices';
import { authorServices } from '../../../../../services/activities/auhorServices';
import { useSelector } from 'react-redux';
import { userServices } from '../../../../../services/user/UserServices';
// Import Vectors
// import Vectors from '../../../../../constants/Vectors';
// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const ActivityDetailsParticipantsScreen = () => {
    const navigation = useNavigation();
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    // const { activityView } = useActivity();
    const { activityView } = useSelector((state) => state.activities);
    // const { user, allUsers, getAllUsers } = useUser();
    const { user, allUsers } = useSelector((state) => state.user);
    const { getAllUsers } = userServices;
    const [showAttendees, setShowAttendees] = useState(true);
    const [showWaitingList, setShowWaitingList] = useState(false);
    const [showCoOrganizers, setShowCoOrganizers] = useState(false);
    const [showCoOrganizersWaitingList, setShowCoOrganizersWaitingList] = useState(false);
    const [showUnApprovedParticipants, setShowUnApprovedParticipants] = useState(false);
    useFocusEffect(React.useCallback(() => {
        // getAllUsers();
    }, []));
    const groupItems = (data, itemsPerRow) => {
        const groupedItems = [];
        for (let i = 0; i < data.length; i += itemsPerRow) {
            groupedItems.push(data.slice(i, i + itemsPerRow));
        }
        return groupedItems;
    };
    const isAutor = authorServices.isAuthor(activityView.author, user._id);
    console.log('isAutor', isAutor);
    const participants = ParticipantsServices.getParticipants(activityView, allUsers);
    const groupedParticipants = groupItems(participants, 3);
    const waitingList = ParticipantsServices.getParticipantwaitingList(activityView, allUsers);
    const groupedWaitingList = groupItems(waitingList, 3);
    const CoOrganizers = ParticipantsServices.getCoOrganizers(activityView, allUsers);
    const groupedCoOrganizers = groupItems(CoOrganizers, 3);
    const CoOrganizersWaitingList = ParticipantsServices.getCoOrganizersWaitingList(activityView, allUsers);
    const groupedCoOrganizersWaitingList = groupItems(CoOrganizersWaitingList, 3);
    const unApprovedParticipants = ParticipantsServices.getUnApprovedParticipants(activityView, allUsers);
    const groupedUnApprovedParticipants = groupItems(unApprovedParticipants, 3);
    return (<>
            {!activityView ? (<View style={styles.container}>
                    <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityDeleted}</Text>
                    <Pressable onPress={() => navigation.navigate('Activities')}>
                        <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonViewAllActivities}</Text>
                    </Pressable>
                </View>) : (<ScrollView>
                    <View style={styles.container}>
                        <View style={styles.top}>
                            <View style={styles.header}>
                                <ActivityDetailsHeaderComponent />
                            </View>
                            <ActivityDetailsAuthorComponent />
                            {/* <ActivityDetailsButtonTopComponent id={user._id} act={activityView} /> */}
                        </View>
                        <View style={{ flexDirection: 'column', alignItems: 'center', paddingHorizontal: 20, paddingTop: 20 }}>
                            <Text style={{ flexWrap: 'wrap', paddingHorizontal: 20, marginBottom: 20 }}>
                                This app will help organizers, so if you want to start or test yourself, feel welcome to assist with this organizer.
                            </Text>
                            <MyButtonPress text='Co Organize' txtCol={'white'} txtS={20} txtFW={'bold'} w={190} h={47} bgC='#FFA113' br={10} cb={() => alert('Invite')}/>
                        </View>
                        <View style={styles.middle}>
                            <ActivityDetailsParticipantsDisplay title='Attendees' activity={activityView} data={participants} groupeData={groupedParticipants} setter={setShowAttendees} getter={showAttendees} isUserAuthor={isAutor}/>
                            <ActivityDetailsParticipantsDisplay title='Wating list' activity={activityView} data={waitingList} groupeData={groupedWaitingList} setter={setShowWaitingList} getter={showWaitingList} isUserAuthor={isAutor}/>
                            <ActivityDetailsParticipantsDisplay title='Co-organizers' activity={activityView} data={CoOrganizers} groupeData={groupedCoOrganizers} setter={setShowCoOrganizers} getter={showCoOrganizers} isUserAuthor={isAutor}/>
                            <ActivityDetailsParticipantsDisplay title='Co-organizers waiting list' activity={activityView} data={CoOrganizersWaitingList} groupeData={groupedCoOrganizersWaitingList} setter={setShowCoOrganizersWaitingList} getter={showCoOrganizersWaitingList} isUserAuthor={isAutor}/>
                            {isAutor && (<ActivityDetailsParticipantsDisplay title='UnApproved list' activity={activityView} data={unApprovedParticipants} groupeData={groupedUnApprovedParticipants} setter={setShowUnApprovedParticipants} getter={showUnApprovedParticipants} isUserAuthor={isAutor}/>)}
                        </View>
                        {/* <View style={styles.bottom}>
            <ActivityDetailsButtonsButtonComponent style={styles.containerButtonBottom}/>
        </View> */}
                    </View>
                </ScrollView>)}
        </>);
};
export default ActivityDetailsParticipantsScreen;
