//Fr Styles de Code (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma037A) (FrameFigma031) (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma035A,FrameFigma035B)
//En Styles of Code (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma037A) (FrameFigma031) (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma035A,FrameFigma035B)
import { StyleSheet } from 'react-native';
const Styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '99%',
        zIndex: -1,
        // position: 'absolute',
    },
    input: {
        borderColor: 'rgba(0, 0, 0, 1)',
        borderWidth: 1,
        marginVertical: 13,
        width: '100%',
        height: '80%',
        borderRadius: 20,
        paddingVertical: 10,
        marginHorizontal: '1%', // Cette propriété pourrait ne pas se comporter comme prévu si la largeur de l'écran est petite
        marginLeft: 1,
        zIndex: 0,
        justifyContent: 'center',
    },
    focusedInput2: {
        backgroundColor: 'white',
        //not a valid ViewStyle property
        //outlineColor: 'rgba(0, 0, 0, 0)',
    },
    defaultInput: {
        backgroundColor: '#F0EEE9'
    },
    focusedInput: {
        backgroundColor: 'white',
        borderColor: 'rgba(0, 0, 0, 0)', //marche pas qd onFocused
    },
    placeholder: {
        overflow: 'hidden',
        fontSize: 16,
        fontWeight: '400',
    },
    input2: {
        overflow: 'hidden',
        fontSize: 16,
        fontWeight: '400',
        textAlign: 'left',
    },
    iconLeft: {
        position: 'absolute',
        marginLeft: '3%',
        zIndex: 2,
    },
    iconRight: {
        position: 'absolute',
        width: 'auto',
        right: '3%',
        borderRadius: 50,
        zIndex: 2,
    },
    inconPressIN: {
        backgroundColor: 'grey',
        opacity: 0.6,
    },
    iconEye: {
        position: 'absolute',
        right: 10,
        zIndex: 1,
        marginTop: -2,
    },
});
export default Styles;
