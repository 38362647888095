import React from 'react';
import { Pressable, StyleSheet, Text, View } from 'react-native';
const MyButtonPress = ({ cb, text, txtCol, txtS, txtFW, w, h, bgC, br, pad = 0, b = 0, disabled = false, bC = '', Icon = null, wIcon = 30, hIcon = 30, }) => {
    const styles = StyleSheet.create({
        button: {
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: bgC,
            borderWidth: b,
            borderColor: bC,
            borderRadius: br,
            width: w,
            height: h,
            padding: pad,
        },
        content: {
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
        },
        text: {
            fontSize: txtS,
            color: txtCol,
            fontWeight: txtFW,
            marginRight: Icon ? 10 : 0,
        },
    });
    return (<Pressable style={styles.button} onPress={cb} disabled={disabled}>
            <View style={styles.content}>
                <Text style={styles.text}>{text}</Text>
                {Icon ? <Icon width={wIcon} height={hIcon}/> : null}
            </View>
        </Pressable>);
};
export default MyButtonPress;
