var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// 🇫🇷 Ce fichier gère la deuxième étape pour les modifications profile (Nom, Numéro de téléphone, Date de naissance, Email, Gestion des options d'ajout d'amis). (FrameFigma016A FrameFigma016C)
// 🇬🇧 This file handles the second step for profile modifications (Last Name, Phone Number, Date of Birth, Email, Manage Friend Options). (FrameFigma016A FrameFigma016C)
import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, ScrollView, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import SwitchBtn from '../../../../components/ButtonSection/SwitchButton/Code';
import { Vectors } from '../../../../constants/Vectors';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import { useError } from '../../../../contexts/ErrorContext';
import CustomDatePicker from '../../../../components/PickerSection/DateWheelPicker/Code';
import PhonePickerWeb from '../../../../components/PickerSection/PhoneWebPicker/Code';
import PhonePickerMobile from '../../../../components/PickerSection/PhoneMobilePicker/Code';
import { useDispatch, useSelector } from 'react-redux';
import { userServices } from '../../../../services/user/UserServices';
import UserSlice from '../../../../redux/Features/User/UserSlice';
import { nanoid } from '@reduxjs/toolkit';
import BottomWhiteNav from '../../../../components/NavBarSection/BottomNav/BottomWhiteNav/Code';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const EditProfileStep2Screen = ({ onNextStep }) => {
    var _a;
    // const { user, updateUser, fetchUser } = useUser();
    const { user, allUsers } = useSelector((state) => state.user);
    const { updateUser, getUserByFilter } = userServices;
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { error, setError } = useError();
    const navigation = useNavigation();
    const [isLoading, setIsLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [formDataBefore, setFormDataBefore] = useState(null);
    const dispatch = useDispatch();
    /**
     * 🇬🇧 State for form variables
     * 🇫🇷 State pour les variable du formulaire
     */
    const fetchUser = () => {
        getUserByFilter(allUsers, user.id);
    };
    const [formData, setFormData] = useState({
        lastName: '',
        phone: '',
        dateOfBirth: '',
        email: '',
        useMyPhone: false,
        useMyLastName: false,
    });
    /**
     * 🇬🇧 State for the “Choose how members can add you as a friend:” button.
     * 🇫🇷 State pour les bouton du "Choisis comment les membres peuvent t'ajouter en tant qu'ami:"
     */
    const [isSelected, setIsSelected] = useState({
        email: true,
        phone: false,
        lastName: false,
        birthday: false,
        memberNumber: true,
    });
    const [addAsFriend, setAddAsFriend] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [country, setCountry] = useState('');
    const emailRegex = /^[\w.!#$%&'*+/=?^`{|}~-]+@\w+(\.\w+)+$/;
    /**
     * 🇬🇧 Loads user information and profile data from temporary AsyncStorage if available, otherwise from DB and initial values, (sponsorship and email cannot be unchecked).
     * 🇫🇷 Charge les information de l'utilisateur et les données de profil depuis AsyncStorage temporaire s'il existe, sinon depuis la DB et les valeur initiale, (le sponsorship et email ne peuvent etre decocher).
     */
    useEffect(() => {
        const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
            const storedData = yield AsyncStorage.getItem('profileData2');
            const initialFormData = {
                lastName: user.lastName || '',
                phone: user.phone || '',
                dateOfBirth: user.dateOfBirth ? new Date(user.dateOfBirth).toISOString().split('T')[0] : '',
                email: user.email || '',
                useMyPhone: user.useMyPhone || false,
                useMyLastName: user.useMyLastName || false,
                useMyBirthday: user.useMyBirthday || false,
                useMyAge: user.useMyAge || false,
            };
            if (storedData) {
                const parsedData = JSON.parse(storedData);
                setFormData(parsedData);
                setFormDataBefore(initialFormData);
                setIsSelected({
                    phone: parsedData.useMyPhone || false,
                    lastName: parsedData.useMyLastName || false,
                    birthday: parsedData.useMyBirthday || false,
                    email: true,
                    memberNumber: true,
                });
                // Save the integrity of the save/cancel button state
                setIsChanged(JSON.stringify(parsedData) !== JSON.stringify(formDataBefore));
            }
            else if (user) {
                setFormData(initialFormData);
                setFormDataBefore(initialFormData);
                setIsSelected({
                    phone: user.useMyPhone || false,
                    lastName: user.useMyLastName || false,
                    birthday: user.useMyBirthday || false,
                    email: true,
                    memberNumber: true,
                });
            }
        });
        loadData();
    }, [user]);
    /**
     * 🇬🇧 Handles input changes by updating form data and saving it to AsyncStorage.
     * 🇫🇷 Gère les changements de saisie en mettant à jour les données du formulaire et en les sauvegardant dans AsyncStorage.
     */
    const handleInputChange = (name, value) => __awaiter(void 0, void 0, void 0, function* () {
        setFormData(prevState => {
            let updatedFormData = Object.assign(Object.assign({}, prevState), { [name]: value });
            if (name === 'phone' && value.length === 0) {
                updatedFormData = Object.assign(Object.assign({}, updatedFormData), { useMyPhone: false });
                setIsSelected(updatedFormData => (Object.assign(Object.assign({}, updatedFormData), { phone: false })));
            }
            setIsChanged(JSON.stringify(updatedFormData) !== JSON.stringify(formDataBefore));
            AsyncStorage.setItem('profileData2', JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    });
    /**
     * 🇬🇧 Filter character input for names to match given limitation
     * 🇫🇷 Filtre l'entrée des caractère pour les nom pour quelle soit conforme au limitation donnée
     */
    const handleNameChange = (key, value) => {
        value = value.replace(/[^a-zA-ZÀ-ÿ'\-\s]/g, '') // Filtrer les caractères non autorisés (autres que lettres, accents, espaces, apostrophes et tirets)
            .replace(/^[\s'-]+/, '') // Filtrer les espaces, tirets et apostrophes en début de chaîne
            .replace(/[\s'-]{2,}/g, match => match[0]) // Remplacer les répétitions de caractères spéciaux par un seul
            .replace(/([^\s'-][A-ZÀ-Ÿ])/g, match => match.toLowerCase()) // Convertir les majuscules qui ne sont pas au début d'un bloc en minuscules
            .replace(/(?:^|[\s'-])([a-zà-ÿ])/g, match => match.toUpperCase()); // Assurer que chaque bloc commence par une majuscule, si nécessaire
        handleInputChange(key, value);
        setError('');
    };
    /**
     * 🇬🇧 Handles phone number modifications. If you continue to write characters when the maximum size has been reached, an error message is displayed.
     * 🇫🇷 Gère les modifications du numéro de téléphone, si l'on continue d'écrire des caracètere alors que la taille maximale a été atteinte alors affiche un message d'erreur.
     */
    const handlePhoneNumberChange = (phone) => {
        if (country === 'fr' && phone.length === 11) {
            setError(translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPhoneNumberCharLimit);
        }
        else {
            setError('');
        }
        handleInputChange('phone', phone);
    };
    /**
     * 🇬🇧 Handles changes in the selected country.
     * 🇫🇷 Gère les changements dans le pays sélectionné.
     */
    const handleCountryChange = (country) => {
        setCountry(country);
    };
    /**
     * 🇬🇧 Handles saving date of birth.
     * 🇫🇷 Gère la sauvegarde de la date de naissance.
     */
    const handleSave = (date) => {
        handleInputChange('dateOfBirth', date);
    };
    /**
     * 🇬🇧 Toggles the selection state of a specific key and updates the button data accordingly.
     * 🇫🇷 Bascule l'état de sélection d'une clé spécifique et met à jour les données des boutons en conséquence.
     */
    const toggleSelection = (key) => {
        setIsSelected(prevState => (Object.assign(Object.assign({}, prevState), { [key]: !prevState[key] })));
        switch (key) {
            case 'phone':
                handleInputChange('useMyPhone', !formData.useMyPhone);
                break;
            case 'lastName':
                handleInputChange('useMyLastName', !formData.useMyLastName);
                break;
            case 'birthday':
                handleInputChange('useMyBirthday', !formData.useMyBirthday);
                break;
            /*case 'age':
              handleInputChange('useMyAge', !formData.useMyAge);
              break;*/
            default:
                throw new Error(`Invalid key: ${key}`);
        }
    };
    /**
     * 🇬🇧 Handles form submission.
     * 🇫🇷 Gère la soumission du formulaire.
     */
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        // If the email is invalid then return an error message
        if (!emailRegex.test(formData.email)) {
            setError(translations.JsonLoginRegisterPages.JsonEmailScreen.JsonEmailErrorMessage);
            return;
        }
        // If the name doesn't respect the appropriate length then return an error message
        if (formData.lastName.length < 2) {
            setError(translations.JsonCreateProfilePages.JsonLastNamePlaceholder + ' ' + translations.JsonCreateProfilePages.JsonErrorNameMinLength);
            return;
        }
        // If there is no change, then delete the temporary storage and return to the profile screen
        if (!isChanged) {
            console.log('Nothing changed');
            try {
                yield AsyncStorage.removeItem('profileData1');
                yield AsyncStorage.removeItem('profileData2');
                yield AsyncStorage.removeItem('profileData3');
                //console.log('Temp data removed');
            }
            catch (error) {
                console.error('Failed to remove form data from AsyncStorage', error);
            }
            navigation.navigate('MyProfileScreen', { userData: user, userId: user._id });
            return;
        }
        // Apply the change, delete the temporary storage and return to the profile screen
        setIsLoading(true);
        const updatedUserData = Object.assign(Object.assign({}, user), formData);
        try {
            let updated = yield updateUser(user._id, updatedUserData);
            if (updated)
                dispatch(UserSlice.actions.updateUserProperties({ id: nanoid(), value: updatedUserData }));
            //console.log('User updated successfully');
            yield AsyncStorage.removeItem('profileData1');
            yield AsyncStorage.removeItem('profileData2');
            yield AsyncStorage.removeItem('profileData3');
            fetchUser();
            navigation.navigate('MyProfileScreen', { userId: user._id });
        }
        catch (error) {
            console.error('Error updating user:', error);
        }
        finally {
            setIsLoading(false);
            setIsChanged(false);
        }
    });
    /**
     * 🇬🇧 Handles the continuation to the next step.
     * 🇫🇷 Gère la poursuite vers l'étape suivante.
     */
    const handleContinue = () => __awaiter(void 0, void 0, void 0, function* () {
        setError('');
        if (!isChanged) {
            try {
                yield AsyncStorage.removeItem('profileData2');
            }
            catch (error) {
                console.error('Failed to remove form data from AsyncStorage', error);
            }
        }
        onNextStep();
    });
    /**
     * 🇬🇧 Toggles the state of adding a friend.
     * 🇫🇷 Bascule l'état de l'ajout d'un ami.
     */
    const isFormComplete = formData.lastName.length >= 2 && emailRegex.test(formData.email);
    const PhonePicker = Platform.OS === 'web' ? PhonePickerWeb : PhonePickerMobile;
    return (<View style={styles.container}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <ScrollView style={styles.scrollViewContainer} contentContainerStyle={{ flexGrow: 1 }}>
        <View style={styles.logoConfidentiality}>
          <Vectors.ProfilesConfidential style={styles.NotificationsVectors}/>
          <Text style={styles.textConfidential}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonWarning}</Text>
        </View>
        <View style={styles.label}>
          <Text style={styles.labelText}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonYourMembershipIs}</Text>
          <Text style={styles.memberId}> {user === null || user === void 0 ? void 0 : user.memberId}</Text>
        </View>
        <View style={styles.elementContainer}>
          <View style={styles.input}>
            <TextInputCustomComponent multiline={true} Icon={Vectors.ProfilesLastName} placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonLastnamePlaceholder} value={formData.lastName} handleTextChange={text => handleNameChange('lastName', text)} isPlaceHolderTextAlignCenter={false}/>
          </View> 
          <View style={styles.numberContainer}>
            <PhonePicker value={formData.phone} onPhoneNumberChange={handlePhoneNumberChange} onCountryChange={handleCountryChange} country={country} 
    //TODO: a vérifier
    onPhoneVerification={handlePhoneNumberChange}/>
          </View>
          <Text style={styles.inputDesc}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPhoneNumberDescription}</Text>
          <View style={styles.input}>
            <CustomDatePicker handleSave={handleSave} initialDate={formData.dateOfBirth}/>
          </View>
          <View style={styles.input}>
            <TextInputCustomComponent Icon={Vectors.ProfilesArobase} placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonEmailPlaceholder} value={formData.email} handleTextChange={text => handleInputChange('email', text)} isPlaceHolderTextAlignCenter={false}/>
          </View>
          <View style={styles.Confidentiality}>
            <Text style={[styles.labelText, { color: (!(formData === null || formData === void 0 ? void 0 : formData.dateOfBirth)) ? 'grey' : 'black' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonAgeDisplayed}</Text>
            <SwitchBtn state={(_a = formData === null || formData === void 0 ? void 0 : formData.useMyAge) !== null && _a !== void 0 ? _a : false} setState={(value) => handleInputChange('useMyAge', value)} disabled={!(formData === null || formData === void 0 ? void 0 : formData.dateOfBirth)}/>
          </View>
          <View style={styles.Confidentiality}>
            <Text style={[styles.labelText, { maxWidth: '70%' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonChooseMember}</Text>
            <SwitchBtn state={addAsFriend} setState={setAddAsFriend} disabled={false}/>
          </View>
        </View>
        {addAsFriend ? (<View style={styles.containerBottom}>
            <View style={styles.buttonSponsorsContainer}>
              <TouchableOpacity style={[styles.buttonSponsors, styles.buttonSelected]}>
                <View style={styles.VectorsContainer}>
                  <Vectors.ProfilesMemberNumber style={styles.Vectors}/>
                </View>
                <Text style={[styles.buttonTextTitle, isSelected.memberNumber ? { color: 'white' } : { color: 'black' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonMemberShip}</Text>
                <Text style={[styles.buttonText, isSelected.memberNumber ? { color: 'white' } : { color: 'black' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPrivateForEveryone}</Text>
              </TouchableOpacity>
            </View>
            <View style={styles.buttonRowContainer}>
              <TouchableOpacity style={[styles.button, styles.buttonSelected]}>
                <View style={styles.VectorsContainer}>
                  <Vectors.ProfilesEmail style={styles.Vectors}/>
                </View>
                <Text style={[styles.buttonTextTitle, isSelected.email ? { color: 'white' } : { color: 'black' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonEmail}</Text>
                <Text style={[styles.buttonText, isSelected.email ? { color: 'white' } : { color: 'black' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPrivateForEveryone}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => toggleSelection('phone')} disabled={!formData.phone} style={[
                styles.button,
                isSelected.phone ? styles.buttonSelected : styles.buttonNotSelected,
                (!formData.phone) && styles.buttonNotSelected,
            ]}>
                <View style={styles.VectorsContainer}>
                  <Vectors.ProfilesPhone style={styles.Vectors}/>
                </View>
                <Text style={[styles.buttonTextTitle, isSelected.phone ? { color: 'white' } : { color: 'black' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPhoneNumber}</Text>
                <Text style={[styles.buttonText, isSelected.phone ? { color: 'white' } : { color: 'black' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPrivateForEveryone}</Text>
              </TouchableOpacity>
            </View>
            <View style={styles.buttonRowContainer}>
              <TouchableOpacity onPress={() => toggleSelection('lastName')} style={[styles.button,
                isSelected.lastName ? styles.buttonSelected : styles.buttonNotSelected,
                (!formData.lastName) && styles.buttonNotSelected,
            ]}>
                <View style={styles.VectorsContainer}>
                  <Vectors.ProfilesLastName style={styles.Vectors}/>
                </View>
                <Text style={[styles.buttonTextTitle, isSelected.lastName ? { color: 'white' } : { color: 'black' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonLastname}</Text>
                <Text style={[styles.buttonText, isSelected.lastName ? { color: 'white' } : { color: 'black' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPrivateForEveryone}</Text>
              </TouchableOpacity>
              <TouchableOpacity onPress={() => toggleSelection('birthday')} disabled={!formData.dateOfBirth} style={[styles.button,
                isSelected.birthday ? styles.buttonSelected : styles.buttonNotSelected,
                (!formData.dateOfBirth) && styles.buttonNotSelected,
            ]}>
                <View style={styles.VectorsContainer}>
                  <Vectors.ProfilesBirthday style={styles.Vectors}/>
                </View>
                <Text style={[styles.buttonTextTitle, isSelected.birthday ? { color: 'white' } : { color: 'black' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonBirthday}</Text>
                <Text style={[styles.buttonText, isSelected.birthday ? { color: 'white' } : { color: 'black' }]}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonPrivateForEveryone}</Text>
              </TouchableOpacity>
            </View>
          </View>) : null}
      </ScrollView>
      <BottomWhiteNav items={[
            { label: isChanged || !isFormComplete ? translations.JsonProfilePages.JsonEditProfileScreen.JsonSaveButton : translations.JsonProfilePages.JsonEditProfileScreen.JsonCancelButton, onPress: () => handleSubmit() },
            { label: translations.JsonProfilePages.JsonEditProfileScreen.JsonContinueButton, onPress: () => handleContinue() },
        ]}/>
    </View>);
};
export default EditProfileStep2Screen;
