// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* global.css */

* {
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

html, body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
  height: 100vh;
  width: 100%;
  overflow: auto;
}

#root {
  width: 450px;
  min-height: 100%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;

}

`, "",{"version":3,"sources":["webpack://./public/global.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;EACE,yCAAyC;EACzC,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,yBAAyB;EACzB,aAAa;EACb,WAAW;EACX,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,wCAAwC;EACxC,aAAa;EACb,sBAAsB;;AAExB","sourcesContent":["/* global.css */\n\n* {\n  font-family: Arial, Helvetica, sans-serif;\n  box-sizing: border-box;\n}\n\nhtml, body {\n  margin: 0;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #f5f5f5;\n  height: 100vh;\n  width: 100%;\n  overflow: auto;\n}\n\n#root {\n  width: 450px;\n  min-height: 100%;\n  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);\n  display: flex;\n  flex-direction: column;\n\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
