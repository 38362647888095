import React from 'react';
import { View, Text, Pressable, ScrollView, Image, TouchableWithoutFeedback, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { useWebDrawer } from './WebDrawerContext';
import LogoutButton from '../../ButtonSection/LogoutButton/Code';
import styles from './StyleWeb';
import ProfileMedal from '../../ProfileSection/ProfileMedal/Code';
import { CalculateUserPointsDrawer } from '../../../utils/Functions/CalculateUserPointsDrawer';
import Vectors from '../../../constants/Vectors';
import { useSelector } from 'react-redux';
import ProgressBar from '../../ProfileSection/ProgressBar/Code';
const DrawerWeb = () => {
    var _a, _b;
    // const {user} = useUser();
    const { user } = useSelector((state) => state.user);
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { isOpen, toggleDrawer } = useWebDrawer();
    const navigation = useNavigation();
    const t = translations.JsonDrawerNavMenu;
    // useEffect(() => {
    //   if (!user) {
    //     fetchUser();
    //   }
    // }, [user]);
    const navigateToScreen = (screenName) => {
        if (screenName === 'Donation') {
            Linking.openURL('https://socializus.org');
        }
        else {
            toggleDrawer( /*false*/);
            navigation.navigate(screenName, {
                userId: user === null || user === void 0 ? void 0 : user._id,
            });
        }
        ;
    };
    const userPoints = CalculateUserPointsDrawer((_a = user === null || user === void 0 ? void 0 : user._id) !== null && _a !== void 0 ? _a : '');
    if (!user || !isOpen) {
        return null;
    }
    const screens = [
        { name: 'ConceptSlides', title: t.JsonConcept, icon: <Vectors.NavbarColorsConcept /> },
        { name: 'VipBadges', title: t.JsonBecomeVip, icon: <Vectors.GeneralColorsGoldStar /> },
        { name: 'InviteFriends', title: t.JsonInviteFriends, icon: <Vectors.GeneralColorsInviteFriends /> },
        { name: 'CreateActivity', title: t.JsonCreateEvent, icon: <Vectors.GeneralColorsCreateAnActivity /> },
        { name: 'Donation', title: 'Donation', icon: <Vectors.DonationIcon /> },
        { name: 'Settings', title: t.JsonSettings, icon: <Vectors.GeneralColorsSettings /> },
        { name: 'InProcess', title: 'En développement', icon: <Text style={{ fontSize: 24 }}>🚀</Text> },
        { name: 'Update', title: t.JsonUpdate, icon: <Vectors.GeneralColorsUpdate /> },
        { name: 'News', title: t.JsonNews, icon: <Vectors.SettingsNews /> },
    ];
    return (<View style={styles.overlay}>
      //toggleDrawer ne prend pas de paramètre
      <TouchableWithoutFeedback onPress={() => toggleDrawer( /*false*/)}>
        <View style={styles.background}/>
      </TouchableWithoutFeedback>
      <ScrollView style={styles.drawerContainer}> 
        <View style={styles.drawerHeader}>
          <Pressable onPress={() => navigateToScreen('MyProfileScreen')}>
            {user.profileImage ? <Image source={{ uri: user.profileImage }} style={styles.profileImage}/> : <Vectors.ProfilesNoGender />}
          </Pressable>
          <View style={styles.userInfosContainer}>
            <View style={styles.userNameContainer}>
              <Text style={user.sexe === 'Male' ? styles.userNameMale : styles.userNameFemale}>{user.userName}</Text>
            </View>
            <View style={styles.trophyContainer}>
              <ProfileMedal noOfSponsorships={((_b = user.sponsoredUsers) === null || _b === void 0 ? void 0 : _b.length) || 0} points={userPoints} isAmbassador={false} size={24}/>
              <Text style={styles.textPoint}>
                {userPoints} {translations.JsonAchievementsPages.JsonMedalsScreen.JsonPoints}
              </Text>
              {/* TODO: Remplacer par la vrai valeur de la note de l'organisateur lors de l'implementation dans le back-end */}
              <Text>⭐⭐⭐⭐⭐</Text>
              <ProgressBar progress={0.8}/>
            </View>
          </View>
        </View>
        {screens.map((screen, index) => (<React.Fragment key={index}>
            <Pressable key={index} style={styles.drawerItem} onPress={() => navigateToScreen(screen.name)}>
              <View style={{ width: 32, height: 32 }}>{screen.icon}</View>
              <Text style={styles.drawerItemText}>{screen.title}</Text>
            </Pressable>
            {(screen.name === 'ConceptSlides' || screen.name === 'Donation' || screen.name === 'News') && (<View style={styles.separator}/>)}
          </React.Fragment>))}
        <View style={styles.drawerItem}>
          <LogoutButton style={styles.drawerItemText}/>
        </View>
      </ScrollView>
    </View>);
};
export default DrawerWeb;
