var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Image, Platform, Pressable, ScrollView, Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
// Import Contexts
// Import functions utils
import activityParticpate from '../../../../../services/activities/ActivityParticpate';
// import services
import { ParticipantsServices } from '../../../../../services/activities/ParticipantsServices';
import { ActivityLikeServices } from '../../../../../services/activities/ActivityLikeServices';
// Import Components
import ActivityDetailsHeaderComponent from '../../../../../components/ActivitySection/ActivityDetailsHeaderComponent/Code';
import ActivityDetailsAuthorComponent from '../../../../../components/ActivitySection/ActivityDetailsAuthorOrganiserComponent/Code';
import ActivityDetailsButtonTopComponent from '../../../../../components/ButtonSection/ActivityDetailsTopButton/Code';
import ActivityDetailsScrollBarParticipantsComponent from '../../../../../components/ActivitySection/ActivityDetailsScrollBarParticipantsComponent/Code';
import ActivityDetailsDescriptionComponent from '../../../../../components/ActivitySection/ActivityDetailsDescriptionComponent/Code';
import ActivityDetailsButtonsBottomComponent from '../../../../../components/ButtonSection/ActivityDetailsBottomButton/Code';
import MetroOrPostalDisplayComponent from '../../../../../components/ActivitySection/ActivityDetailsMetroOrPostalDisplayComponent/Code';
// Import Modals
import MyModal from '../../../../../components/ModalPopUpModule/ModalSection/ActivityDetailsModal/MyModal';
import ActivityWaitingListModal from '../../../../../components/ModalPopUpModule/ModalSection/ActivityJoinWaitingListModal/Code';
// import Vectors
import Vectors from '../../../../../constants/Vectors';
import { userServices } from '../../../../../services/user/UserServices';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const ActivityDetailsDescriptionScreen = () => {
    var _a, _b, _c;
    const navigation = useNavigation();
    const { translations } = useSelector((state) => state.language);
    // const { activityView, setActivityView, activitiesLoading } = useActivity();
    const { activityView } = useSelector((state) => state.activities);
    const { user } = useSelector((state) => state.user);
    const [isLiked, setIsLiked] = useState((_a = activityView === null || activityView === void 0 ? void 0 : activityView.isLiked) !== null && _a !== void 0 ? _a : false);
    const dispatch = useDispatch();
    const setActivityView = (data) => {
        dispatch(ActivitiesSlice.actions.setActivityView({ id: nanoid(), value: data }));
    };
    const [modalWaitingListVisible, setModalWaitingListVisible] = useState(false);
    const handleParticipate = (activityView, setActivityView, navigation, modalWaitingListVisible, setModalWaitingListVisible) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const userFetch = yield userServices.getUserById(user._id);
            yield activityParticpate(activityView, setActivityView, navigation, userFetch, setModalWaitingListVisible);
        }
        catch (error) {
            console.error('Error participating in activity:', error);
        }
    });
    const action = (activityView, setActivityView, user, setModalWaitingListVisible) => __awaiter(void 0, void 0, void 0, function* () {
        if (!ParticipantsServices.isUserInWaitingList(activityView, user._id)) {
            yield ParticipantsServices.joinWaitingList(activityView, user._id, setActivityView, setModalWaitingListVisible);
            setModalWaitingListVisible(false);
            return;
        }
        else {
            yield ParticipantsServices.leaveWaitingList(activityView, user._id, setActivityView, setModalWaitingListVisible);
            setModalWaitingListVisible(false);
            return;
        }
    });
    const actionLike = (activityView) => __awaiter(void 0, void 0, void 0, function* () {
        if (isLiked) {
            setIsLiked(false);
            yield ActivityLikeServices.removeUserLikeActivity(activityView._id);
            const response = yield ActivityLikeServices.removeActivityLike(activityView);
            setActivityView(Object.assign(Object.assign({}, activityView), { likes: response || [] }));
        }
        else {
            setIsLiked(true);
            yield ActivityLikeServices.addUserLikeActivity(activityView._id);
            const response = yield ActivityLikeServices.addActivityLike(activityView);
            setActivityView(Object.assign(Object.assign({}, activityView), { likes: response || [] }));
        }
    });
    return (<>
            {!activityView ?
            <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
                    <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityDeleted}</Text>
                    <Pressable onPress={() => navigation.navigate('Activities')}>
                        <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonViewAllActivities}</Text>
                    </Pressable>
                </ScrollView>
            :
                <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
                    <MyModal modalVisible={modalWaitingListVisible} onClose={() => setModalWaitingListVisible(false)} w={400}>
                        <ActivityWaitingListModal action={() => action(activityView, setActivityView, user, setModalWaitingListVisible)} setModalVisible={setModalWaitingListVisible} activity={activityView} user={user}/>
                    </MyModal>
                    {/* <ScrollView style={styles.container} showsVerticalScrollIndicator={false}> */}
                    <View style={styles.top}>
                        <ActivityDetailsHeaderComponent />
                        {activityView.activityImage &&
                        <Image source={{ uri: activityView.activityImage }} style={styles.image}/>}

                        <Pressable style={styles.heart} onPress={() => actionLike(activityView)} disabled={activityView.disabledLike}>
                            {isLiked ? <Vectors.UsersInteractionColorsHeartRed width={32} height={32}/> :
                        <Vectors.UsersInteractionBlackAndWhiteHeartGrey width={32} height={32}/>}
                        </Pressable>

                        <ActivityDetailsAuthorComponent />
                        <ActivityDetailsScrollBarParticipantsComponent />
                        {!!activityView.metroStation || !!((_b = activityView.location) === null || _b === void 0 ? void 0 : _b.postalCode) ?
                        <MetroOrPostalDisplayComponent postal={(_c = activityView.location) === null || _c === void 0 ? void 0 : _c.postalCode} metroStation={activityView.metroStation}/>
                        : null}
                        <ActivityDetailsButtonTopComponent cbParticipate={() => handleParticipate(activityView, setActivityView, navigation, modalWaitingListVisible, setModalWaitingListVisible)} cbLeave={() => setModalWaitingListVisible(true)} cbInvite={() => alert('Invite')} disabled={activityView.isPassed} id={user._id} act={activityView}/>
                    </View>
                    <View style={styles.middle}>
                        <ActivityDetailsDescriptionComponent aboutTitle={'About the event :'} content={activityView.description || ''}/>
                    </View>
                    {/* </ScrollView> */}
                    <View style={styles.bottom}>
                        <ActivityDetailsButtonsBottomComponent id={user._id} disabled={activityView.isPassed}/>
                    </View>
                </ScrollView>}
        </>);
};
export default ActivityDetailsDescriptionScreen;
