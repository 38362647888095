import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    choosePhoto: {
        padding: 10,
        backgroundColor: '#ddd',
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
    btnChoose: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    field: {
        marginRight: 10,
    },
    btnChooseText: {
        fontSize: 16,
        color: '#333',
    },
    imagePreview: {
        width: 300,
        height: 300,
        borderRadius: 150,
        marginTop: 20,
    },
    saveButton: {
        marginTop: 20,
        padding: 10,
        backgroundColor: '#4CAF50',
        borderRadius: 5,
    },
});
export default styles;
