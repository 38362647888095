//🇫🇷 Ce fichier gère le style web du composant VerifyEmailExistingUserScreen.js (FrameFigma007A FrameFigma007B FrameFigma007C)
//🇬🇧 This file manages the web style of the VerifyEmailExistingUserScreen.js component (FrameFigma007A FrameFigma007B FrameFigma007C)
import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    verification: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'white',
    },
    container: {
        width: '80%',
        flex: 1,
        marginTop: height * 0.15, // Adjusted to 15% of screen height
        alignItems: 'center', // Center align the container
    },
    verificationText: {
        textAlign: 'center',
        fontSize: width * 0.035, // Adjusted to 3.5% of screen width
        fontWeight: '400',
        lineHeight: height * 0.023, // Adjusted to 2.3% of screen height
        marginBottom: height * 0.025, // Adjusted to 2.5% of screen height
    },
    inputs: {
        flexDirection: 'row',
        justifyContent: 'center', // Center align the inputs
        marginVertical: height * 0.025, // Adjusted to 2.5% of screen height
    },
    opt: {
        width: width * 0.11, // Adjusted to 11% of screen width
        height: height * 0.05, // Adjusted to 5% of screen height
        backgroundColor: 'white',
        borderColor: '#000000',
        borderWidth: 1,
        borderRadius: 10,
        textAlign: 'center',
        marginHorizontal: width * 0.0125, // Adjusted to 1.25% of screen width
        fontSize: width * 0.045, // Adjusted to 4.5% of screen width
    },
    optError: {
        borderColor: 'red', // Changement de la bordure en rouge en cas d'erreur
    },
    resend: {
        alignItems: 'center',
        marginVertical: height * 0.025, // Adjusted to 2.5% of screen height
    },
    resendText: {
        textAlign: 'center',
        fontSize: width * 0.04, // Adjusted to 4% of screen width
        marginBottom: height * 0.0125, // Adjusted to 1.25% of screen height
    },
    btnResendContainer: {
        width: '40%',
        marginTop: height * 0.0125, // Adjusted to 1.25% of screen height
        borderWidth: 1,
        borderColor: '#59C09B',
        paddingVertical: height * 0.0075, // Adjusted to 0.75% of screen height
        borderRadius: 15,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnResend: {
        alignContent: 'center',
        justifyContent: 'center',
    },
    btnTextResend: {
        color: '#59C09B',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    btnContainer: {
        flexDirection: 'row',
        marginVertical: height * 0.1, // Adjusted to 10% of screen height
        justifyContent: 'space-between',
        width: '110%',
        alignItems: 'center',
    },
    btnText: {
        color: 'white',
        fontSize: width * 0.055, // Adjusted to 5.5% of screen width
        fontWeight: 'bold',
    },
    headerError: {
        backgroundColor: 'red',
        padding: width * 0.05, // Adjusted to 5% of screen width
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: width * 0.045, // Adjusted to 4.5% of screen width
        fontWeight: 'bold',
    },
    paste: {
        width: width * 0.05, // Adjusted to 5% of screen width
        height: height * 0.025, // Adjusted to 2.5% of screen height
        alignSelf: 'flex-end',
        marginRight: width * 0.0025, // Adjusted to 0.25% of screen width
    },
    pasteContainer: {
        alignSelf: 'flex-end',
        marginRight: width * 0.05, // Adjusted to 5% of screen width
    },
});
