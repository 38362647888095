import { StyleSheet } from "react-native";
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: "#fff",
        paddingTop: 30,
    },
    friendship: {
        alignItems: "center",
        marginBottom: 20,
        marginTop: 30,
    },
    social: {
        width: '30%',
        marginBottom: 16,
        alignItems: 'center',
    },
    txt: {
        fontSize: 16,
        fontWeight: "600",
        marginTop: 10,
        textAlign: "center", // Center text horizontally
    },
    countText: {
        display: 'flex',
        alignItems: 'center',
        fontFamily: 'Poppins-Medium',
        marginTop: 5,
        paddingHorizontal: 15,
        paddingVertical: 5,
        borderRadius: 5,
        fontSize: 20,
        textAlign: "center",
        width: '70%',
        marginLeft: 'auto',
        marginRight: 'auto',
        color: 'orange',
    },
    inviteStyle: {
        backgroundColor: "white",
        width: "100%",
        height: "100%",
    },
    inviteFriendsStyle: {
        width: '100%',
        backgroundColor: '#D9D9D9',
        alignItems: 'center',
        fontFamily: 'Poppins-Medium',
        fontWeight: "bold",
        marginTop: 20,
        marginBottom: 10,
        height: '40%',
        flex: 1,
    },
    inviteTextFriendsStyle: {
        paddingBottom: 20,
        paddingTop: 20,
        textAlign: 'center',
        textAlignVertical: 'center',
        fontSize: 15,
    },
    inviteFriendsView: {
        marginTop: 20,
    },
    Vectorstyles: {
        borderWidth: 1,
        borderColor: 'black',
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 60,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 20,
        marginRight: 20,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    inviteText: {
        marginTop: 5,
        textAlign: "center",
        color: "red",
    },
    dispositionInvitedIcon: {
        marginTop: 30,
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between",
        alignItems: "center",
    },
    numberInviteFriendsView: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
    },
    numberInvitedFriendView: {
        height: 30,
        width: 30,
        backgroundColor: 'orange',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    numberInvitedFriendText: {
        color: 'white',
        fontFamily: 'poppins-bold',
        fontSize: 15,
    },
    numberInviteFriendsText: {
        fontSize: 15,
        marginLeft: 5,
    },
    specialText: {
        color: '#59C09B',
        fontWeight: 'bold',
    },
});
