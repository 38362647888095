//FR un système de navigation par onglets(FrameFigma024A,024B,024C,024D)
//EN that creates a tab navigation system (FrameFigma024A,024B,024C,024D)
import React from 'react';
import { Text, TouchableOpacity, View, Platform } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import InfoScreen from './NotificationsInfoScreen/NotificationsInfoScreen';
import ManageScreen from './NotificationsManageScreen/NotificationsManageScreen';
import InteractionsScreen from './NotificationsInteractionScreen/NotificationsInteractionScreen';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
const Tab = createMaterialTopTabNavigator();
const CustomTabBar = ({ state, descriptors, navigation }) => {
    return (<View style={{ flexDirection: 'row', backgroundColor: '#000' }}>
      {state.routes.map((route, index) => {
            const { options } = descriptors[route.key];
            const label = options.tabBarLabel !== undefined ? options.tabBarLabel : options.title !== undefined ? options.title : route.name;
            const isFocused = state.index === index;
            const onPress = () => {
                const event = navigation.emit({
                    type: 'tabPress',
                    target: route.key,
                    canPreventDefault: true,
                });
                if (!isFocused && !event.defaultPrevented) {
                    navigation.navigate(route.name);
                }
            };
            return (<TouchableOpacity key={index} onPress={onPress} style={{
                    flex: 1,
                    alignItems: 'center',
                    padding: 16,
                    backgroundColor: isFocused ? '#59C09B' : 'gray', // Couleur de fond basée sur l'état
                }}>
            <Text style={{ color: isFocused ? 'gray' : 'white' }}>{label}</Text>
          </TouchableOpacity>);
        })}
    </View>);
};
const TabNavigator = () => {
    // Utilisation du contexte User
    // const { user, allUsers } = useUser();
    const { user, allUsers } = useSelector((state) => state.user);
    // const { translations } = useLanguage(); // Importez les traductions
    const { translations } = useSelector((state) => state.language);
    return (<Tab.Navigator tabBar={props => <CustomTabBar {...props}/>}>
      {/* Utilisez une fonction de rendu pour passer le contexte comme prop */}
      <Tab.Screen name="Info" options={{ tabBarLabel: translations.JsonTopNav.JsonNotificationsSecondLineInfo }}>
        {props => <InfoScreen />}
      </Tab.Screen>
      <Tab.Screen name="Manage" options={{ tabBarLabel: translations.JsonTopNav.JsonNotificationsSecondLineManage }}>
        {props => <ManageScreen {...props} user={user}/>}
      </Tab.Screen>
      <Tab.Screen name="Interactions" options={{ tabBarLabel: translations.JsonTopNav.JsonNotificationsSecondLineInteraction }}>
        {props => <InteractionsScreen />}
      </Tab.Screen>
    </Tab.Navigator>);
};
export default TabNavigator;
