import React from 'react';
import { View, Text, Platform, ScrollView, Pressable } from 'react-native';
//import styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const ActivityDetailsDescriptionComponent = ({ aboutTitle, content }) => {
    return (<View style={styles.aboutContainer}>
            <View style={styles.aboutTitleContainer}>
                <Text style={styles.textAbout}>{aboutTitle}</Text>
                <Pressable style={styles.buttonTranslate} onPress={() => alert('selectionner langue')}>
                    <Text>Translate</Text>
                </Pressable>
            </View>
            <ScrollView style={styles.contentContainer}>
                <Text style={styles.textContent}>{content}</Text>
            </ScrollView>
        </View>);
};
export default ActivityDetailsDescriptionComponent;
