// FR  affiche une interface utilisateur avec une barre de navigation à onglets qui permet à l'utilisateur de visualiser ses activités à venir (FrameFigma021A)
// GB Display a user interface with a tab navigation bar that allows the user to view their upcoming activities. (FrameFigma021A)
import React, { useState } from 'react';
import { View, Text, TouchableOpacity, Platform, ActivityIndicator } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
// import contexts
// import components
// import DisplayBanner from '../../../components/MyActivityDisplayBanner/DisplayBanner';
import MyActivitiesDisplayBanner from '../../../components/BannerSection/MyActivitiesDisplayBanner/Code';
import LikedScreen from "./MyActivitiesLikedScreen/Code";
import OrganizedScreen from "./MyActivitiesOrganizedScreen/Code";
import PastScreen from "./MyActivitiesPastScreen/Code";
import UpcomingScreen from "./MyActivitiesUpcomingScreen/Code";
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
const Tab = createMaterialTopTabNavigator();
const CustomTabBar = ({ label, isSelected, onPress }) => {
    return (<TouchableOpacity onPress={onPress} style={[
            styles.button,
            isSelected ? styles.buttonSelected : styles.buttonUnselected,
        ]}>
      <Text style={isSelected ? styles.activeLabel : styles.inactiveLabel}>
        {label}
      </Text>
    </TouchableOpacity>);
};
const MyActivitiesScreen = () => {
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    // const { user } = useUser();
    const { user } = useSelector((state) => state.user);
    const [activeTab, setActiveTab] = useState();
    const [isDisplayBannerVisible, setIsDisplayBannerVisible] = useState(true);
    const handleDataChange = (newData) => {
        setIsDisplayBannerVisible(newData);
    };
    const getBannerJson = () => {
        switch (activeTab) {
            case translations.JsonTopNav.JsonMyActivitiesThirdLineUpcoming:
                return translations.JsonTopNav.JsonMyActivitiesExplanationUpcoming;
            case translations.JsonTopNav.JsonMyActivitiesThirdLinePast:
                return translations.JsonTopNav.JsonMyActivitiesExplanationPast;
            case translations.JsonTopNav.JsonMyActivitiesThirdLineLiked:
                return translations.JsonTopNav.JsonMyActivitiesExplanationLiked;
            case translations.JsonTopNav.JsonMyActivitiesThirdLineOrganized:
                return translations.JsonTopNav.JsonMyActivitiesExplanationOrganized;
            default:
                return translations.JsonTopNav.JsonMyActivitiesExplanationUpcoming;
        }
    };
    return (<View style={styles.colorBackroundWhite}>
      {!(user === null || user === void 0 ? void 0 : user._id) ? <ActivityIndicator size="large" color="#0000ff"/> :
            <>
          {isDisplayBannerVisible && <MyActivitiesDisplayBanner Json={getBannerJson()}/>}
          <Tab.Navigator tabBar={(props) => (<View style={styles.tabBar}>
                {props.state.routes.map((route, index) => (<View key={route.key} style={styles.tabBarButtonContainer}>
                    {index > 0 && <View style={styles.separator}/>}
                    <CustomTabBar label={route.name} isSelected={props.state.routes[props.state.index].key === route.key} onPress={() => {
                            props.navigation.navigate(route.name);
                            setActiveTab(route.name);
                        }}/>
                  </View>))}
              </View>)}>
            <Tab.Screen name={translations.JsonTopNav.JsonMyActivitiesThirdLineUpcoming} component={UpcomingScreen} initialParams={{ setIsDisplayBannerVisible }}/>
            <Tab.Screen name={translations.JsonTopNav.JsonMyActivitiesThirdLinePast} component={PastScreen} initialParams={{ setIsDisplayBannerVisible }}/>
            <Tab.Screen name={translations.JsonTopNav.JsonMyActivitiesThirdLineLiked} component={LikedScreen} initialParams={{ setIsDisplayBannerVisible }}/>
            <Tab.Screen name={translations.JsonTopNav.JsonMyActivitiesThirdLineOrganized} component={OrganizedScreen} initialParams={{ setIsDisplayBannerVisible }}/>
          </Tab.Navigator>
        </>}
    </View>);
};
export default MyActivitiesScreen;
