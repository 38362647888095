// Fr Cette page affiche l'étape 4 du formulaire pour modifier une activité (FrameFigma036)
// En This page displays the fourth step of the form to edit an activity (FrameFigma036)
import React, { useState } from 'react';
import { View, ScrollView, Text, Pressable, Platform } from 'react-native';
import { activitiesIcon } from '../../../../constants/VectorsOnOff/ActivityListIcon';
import { useError } from '../../../../contexts/ErrorContext';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const EditActivityTopicScreen = ({ onNextStep, onPrevStep, setHeaderTitle }) => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { error, setError } = useError(); //On appele le context erreur
    // const {currentActivity, setCurrentActivity} = useActivity(); //On appele le context activité
    const { currentActivity } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setCurrentActivity = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivity({ id: nanoid(), value: data }));
    };
    const [topic, setTopic] = useState(currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.topic);
    //Fonction pour sauvegarder le topic choisi
    const handleTopicBtn = (index) => {
        setError('');
        setTopic(index);
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { topic: index, activityTopicTitle: activitiesIcon[index].activityTitle }));
    };
    const handlePrevStep = () => {
        setHeaderTitle('Help for organizers');
        setError('');
        onPrevStep();
    };
    const handleNextStep = () => {
        if (!!topic || topic === 0) {
            setHeaderTitle('Activity description');
            onNextStep(); // Appeler la fonction pour passer à l'étape suivante
        }
        else {
            setError(translations.JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen.JsonTopicErrorMessage);
            return;
        }
    };
    return (<ScrollView showsVerticalScrollIndicator={false}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <View style={styles.container}>
        <View>
          <Text style={styles.title}>{translations.JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen.JsonChooseTopic}</Text>
          <Text style={styles.info}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen.JsonIncreaseYourActivityInterestMessage}{' '}
          </Text>
        </View>
        <View style={styles.activityIconContainer}>
          {activitiesIcon.map((activity, index) => (<View style={styles.activityView} key={index}>
              <Pressable style={[styles.activityCard, { backgroundColor: (currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.topic) === index ? '#59c09b' : 'white' }]} onPress={() => handleTopicBtn(index)}>
                <View style={styles.flexView}>{(currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.topic) === index ? activity.activityIconOn : activity.activityIconOff}</View>
                <Text style={[
                styles.activityTitle,
                {
                    fontWeight: topic === index ? 'bold' : '400',
                    color: topic === index ? 'white' : 'black',
                },
            ]}>
                  {activity.activityTitle}
                </Text>
              </Pressable>
            </View>))}
        </View>
        <View>
          <View style={styles.btnContainer}>
            <ButtonPrevious onPrevStep={handlePrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
            <ButtonNext conditionNeed={(currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.topic) || (currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.topic) === 0} handleNextStep={handleNextStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}/>
          </View>
        </View>
      </View>
    </ScrollView>);
};
export default EditActivityTopicScreen;
