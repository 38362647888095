import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
    },
    text: {
        marginLeft: 5,
        marginRight: 5,
        marginTop: 5,
        color: 'white',
        fontFamily: 'poppins',
        fontWeight: '600',
        fontSize: 16
    },
    ribbon: {
        paddingHorizontal: 3,
        height: 28,
        backgroundColor: '#484F54',
    },
    borderTriangle: {
        position: 'absolute',
        top: '107%',
        marginTop: -30,
        borderTopWidth: 15,
        borderTopColor: '#484F54',
        borderBottomWidth: 15,
        borderBottomColor: '#484F54',
    },
    leftBorderTriangle: {
        left: -14,
        borderLeftWidth: 15,
        borderLeftColor: 'transparent',
    },
    rightBorderTriangle: {
        right: -14,
        borderRightWidth: 15,
        borderRightColor: 'transparent',
    },
});
export default styles;
