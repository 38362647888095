// // 🇫🇷 Ce fichier gère la page Home au lancement de l'application. (FrameFigma001 FrameFigma003)
// // 🇬🇧 This file manage the Home page when the application is launched. (FrameFigma001 FrameFigma003)
import React, { useEffect, useCallback, useState } from 'react';
import { View, Text, TouchableOpacity, ImageBackground, Platform, Dimensions, Pressable, Linking, SafeAreaView } from 'react-native';
import HomePageBR from '../../../assets/Images/Home/HomePageBR.png';
import HomePageBR2 from '../../../assets/Images/Home/HomePageBR2.png';
import Vectors from '../../../constants/Vectors';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const HomeScreen = ({ navigation }) => {
    const [backgroundImage, setBackgroundImage] = useState(HomePageBR2); // (FR) État pour l'image de fond - (EN) Status for background image
    const resetRootStyles = () => {
        if (Platform.OS !== 'web')
            return;
        const rootElement = document.getElementById('root');
        if (rootElement)
            rootElement.style.width = '450px';
    };
    const applyConditionalStyles = () => {
        if (Platform.OS !== 'web')
            return;
        const rootElement = document.getElementById('root');
        if (!rootElement)
            return;
        if (Dimensions.get('window').width <= 450) {
            rootElement.style.width = '100%';
        }
        else {
            rootElement.style.width = '1900px';
        }
    };
    // (FR) Fonction pour changer l'image de fond en fonction de la taille de l'écran - (EN) Function to change the background image according to screen size
    const updateBackgroundImage = () => {
        const width = Dimensions.get('window').width;
        setBackgroundImage(width > 1024 ? HomePageBR2 : HomePageBR);
    };
    useEffect(useCallback(() => {
        // Apply styles when the screen is focused
        applyConditionalStyles();
        updateBackgroundImage(); // (FR) Met à jour l'image de fond à l'initialisation - (EN) Updates the background image on initialisation
        // Handle window resize
        const handleResize = () => {
            applyConditionalStyles();
            updateBackgroundImage(); // (FR) Met à jour l'image de fond lors du redimensionnement - (EN) Updates the background image when resizing
        };
        Platform.OS === 'web' && window.addEventListener('resize', handleResize);
        // Cleanup when the screen is unfocused
        return () => {
            Platform.OS === 'web' && window.removeEventListener('resize', handleResize);
            resetRootStyles();
        };
    }, []));
    useEffect(() => {
        // (FR) Écouteur d'événements pour détecter les changements de taille d'écran - (EN) Event listener to detect changes in screen size
        const subscription = Dimensions.addEventListener('change', updateBackgroundImage);
        updateBackgroundImage(); // (FR) Appel initial pour définir l'image de fond
        return () => {
            subscription === null || subscription === void 0 ? void 0 : subscription.remove();
        };
    }, []);
    return (<SafeAreaView style={{ flex: 1 }}>
      <ImageBackground source={backgroundImage} style={[styles.container, { width: '100%', height: '100%' }]} resizeMode="cover">
      <LogoSection />
      <ContentSection navigation={navigation} resetRootStyles={resetRootStyles}/>
      </ImageBackground>
    </SafeAreaView>);
};
const LogoSection = () => {
    return (<View style={styles.logo}>
      <Vectors.GeneralColorsLogoSocializusBlack />
    </View>);
};
const ContentSection = ({ navigation, resetRootStyles }) => {
    return (<View style={styles.content}>
      <ButtonSection navigation={navigation} resetRootStyles={resetRootStyles}/>
    </View>);
};
const ButtonSection = ({ navigation, resetRootStyles }) => {
    resetRootStyles();
    return (<TouchableOpacity style={styles.btnContainer} onPress={() => navigation.navigate('Languages')}>
      <Vectors.ButtonWorldStart style={styles.btnStart}/>
    </TouchableOpacity>);
};
const Condition = () => {
    return (<View style={styles.condition}>
      <Text style={styles.terms}>By connecting, you agree to the</Text>
      <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
        <Text style={styles.termsBold}>Policy of confidentiality</Text>
      </Pressable>
      <Text style={styles.terms}>and to the</Text>
      <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
        <Text style={styles.termsBold}>terms of use.</Text>
      </Pressable>
    </View>);
};
export default HomeScreen;
