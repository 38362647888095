import { StyleSheet } from "react-native";
export default StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    modalView: {
        width: 450,
        height: '70%',
        backgroundColor: 'white',
        borderRadius: 30,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.3,
        shadowRadius: 4,
    },
    header: {
        width: '100%',
        padding: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        tintColor: 'white',
        fontWeight: 'bold',
        fontSize: 20,
        alignItems: 'center',
        flexWrap: 'nowrap',
        marginLeft: '40%',
    },
    headerSettings: {
        marginLeft: '25%',
    },
    dragIndicator: {
        width: 70,
        height: 5,
        backgroundColor: 'lightgray',
        borderRadius: 3,
        marginBottom: '10%',
    },
    messagesContainer: {
        width: '100%',
        flex: 1,
        paddingBottom: 10,
    },
    footer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        padding: 15,
        backgroundColor: 'white',
        justifyContent: 'center',
    },
    footerSend: {
        marginLeft: 10,
    },
    footerInput: {
        padding: 10,
        width: '80%',
        borderWidth: 1,
        borderColor: '#ccc',
        borderRadius: 50,
        backgroundColor: '#EDEDEE',
    },
    button: {
        width: '100%',
        padding: 10,
        backgroundColor: '#007bff',
        borderRadius: 5,
        alignItems: 'center',
    },
    buttonText: {
        color: 'white',
    },
    image: { width: 50, height: 50, borderRadius: 25 },
});
