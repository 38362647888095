//🇫🇷 Ce fichier gère le style mobile du composant EmailScreen.js (FrameFigma004A)
//🇬🇧 This file manages the mobile style of the EmailScreen.js component (FrameFigma004A)
import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    subtitle: {
        fontSize: width * 0.045,
        fontWeight: 'bold',
        marginTop: height * 0.02,
        marginHorizontal: width * 0.075,
    },
    content: {
        alignItems: 'center',
        width: '100%',
        height: '100%',
        marginHorizontal: 'auto',
    },
    email: {
        marginTop: height * 0.0125,
        marginHorizontal: width * 0.075,
        width: '80%',
    },
    sponsorship: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '10%',
        padding: width * 0.0625,
        marginBottom: '5%',
        width: '100%',
    },
    buttonSponsorshipAccount: {
        borderWidth: 1,
        padding: width * 0.025,
        borderTopLeftRadius: width * 0.0375,
        borderBottomLeftRadius: width * 0.0375,
        width: '45%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textButtonSponsorshipAccount: {
        fontSize: width * 0.03,
        fontWeight: 'bold',
    },
    buttonNoSponsorshipAccount: {
        borderWidth: 1,
        padding: width * 0.025,
        borderTopRightRadius: width * 0.0375,
        borderBottomRightRadius: width * 0.0375,
        width: '45%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnContainer: {
        width: '100%',
        alignItems: 'center',
        marginVertical: '10%',
    },
    terms: {
        fontSize: width * 0.03,
        textAlign: 'center',
    },
    forgotPassword: {
        marginTop: '5%',
        fontSize: width * 0.035,
        textAlign: 'center',
        marginHorizontal: width * 0.075,
    },
    underline: {
        fontSize: width * 0.035,
        textDecorationLine: 'underline',
        marginLeft: 4,
    },
    condition: {
        marginTop: '15%',
        width: '65%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    headerError: {
        backgroundColor: 'red',
        padding: width * 0.05,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: width * 0.045,
        fontWeight: 'bold',
    },
});
