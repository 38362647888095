var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Import Services
import { ParticipantsServices } from './ParticipantsServices';
import { ActivityServices } from './ActivityServices';
import { userServices } from '../user/UserServices';
const joinActivity = (activity, user, navigation, setActivityView) => __awaiter(void 0, void 0, void 0, function* () {
    const userId = user._id;
    const activityJoin = Object.assign(Object.assign({}, activity), { attendees: [...activity.attendees, userId] });
    const userJoin = Object.assign(Object.assign({}, user), { activities: [...user.activities, activity._id], status: 'online' });
    try {
        yield ActivityServices.updateActivity(activity._id, activityJoin);
        yield userServices.updateUser(user._id, userJoin);
        setActivityView(Object.assign(Object.assign({}, activity), { attendees: [...activity.attendees, user._id] })); // mets à jour le contexte
        {
            activity.infoLine === '' ? navigation.navigate('Address') : navigation.navigate('Online');
        }
    }
    catch (error) {
        console.log('Error joining activity', error);
    }
});
const leaveActivity = (activity, user, navigation) => __awaiter(void 0, void 0, void 0, function* () {
    const userId = user._id;
    const ActivityLeave = Object.assign(Object.assign({}, activity), { attendees: activity.attendees.filter((id) => id !== userId) });
    const userLeave = Object.assign(Object.assign({}, user), { status: 'online', activities: user.activities.filter((id) => id !== activity._id) });
    try {
        yield ActivityServices.updateActivity(activity._id, ActivityLeave);
        yield userServices.updateUser(user._id, userLeave);
        navigation.navigate('Activities');
    }
    catch (error) {
        console.log('Error leaving activity', error);
    }
});
const activityParticpate = (activityView, setActivityView, navigation, user, setModalWaitingListVisible) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield ActivityServices.getActivity(activityView._id);
    const activity = response.data;
    if (!!ParticipantsServices.isUserParticipate(activity, user._id)) {
        leaveActivity(activity, user, navigation);
        return;
    }
    if (activity.attendees.length >= activity.attendeeLimit || !!activity.attendeesValidation) {
        setModalWaitingListVisible(true);
    }
    else {
        joinActivity(activity, user, navigation, setActivityView);
    }
});
export default activityParticpate;
