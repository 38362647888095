// une modal permettant de rechercher et de choisir des images à partir de l'API Unsplash
//"A modal allowing to search and choose images from the Unsplash API."
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// UnsplashPicker.tsx
import React, { useState, useEffect, useCallback } from 'react';
import { View, Modal, Text, Pressable, FlatList, Image, TextInput, ActivityIndicator, Platform } from 'react-native';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const unsplashKey = 'V5naPaTjx6P1z10fh5gW_9hRvp1hxrlodqeBz0u1V-Q';
const UnsplashPicker = ({ modalVisible, setModalVisible, onImageSelected, searchTermInitial }) => {
    const { translations } = useSelector((state) => state.language);
    const [searchTerm, setSearchTerm] = useState(searchTermInitial || '');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const searchUnsplash = useCallback((searchQuery) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setError(null);
        try {
            const response = yield fetch(`https://api.unsplash.com/search/photos?query=${searchQuery}&per_page=30&client_id=${unsplashKey}`);
            const data = yield response.json();
            setImages(data.results);
        }
        catch (err) {
            setError('Failed to fetch images');
        }
        finally {
            setLoading(false);
        }
    }), []);
    useEffect(() => {
        if (searchTermInitial && searchTerm === '') {
            setSearchTerm(searchTermInitial);
            searchUnsplash(searchTermInitial);
        }
        else if (searchTerm) {
            const timeoutId = setTimeout(() => {
                searchUnsplash(searchTerm);
            }, 500); // Debounce input by 500ms
            return () => clearTimeout(timeoutId);
        }
    }, [searchTerm, searchTermInitial, searchUnsplash]);
    const pickImage = (image) => {
        onImageSelected(image.urls.small);
        setModalVisible(false);
    };
    return (<Modal animationType="slide" transparent={true} visible={modalVisible} onRequestClose={() => setModalVisible(false)}>
      <View style={styles.modalContainer}>
        <View style={styles.modalView}>
          <View style={styles.close}>
            <Pressable style={styles.closeButton} onPress={() => setModalVisible(false)}>
              <Text>X</Text>
            </Pressable>
          </View>
          <View style={styles.informations}>
            <View>
              <Text>{translations.JsonActivityPages.JsonCreateActivityImageModal.JsonFindImageChoice}</Text>
            </View>
            <View>
              <Text>{translations.JsonActivityPages.JsonCreateActivityImageModal.JsonUnsplashFindThePerfectImageMessage}</Text>
            </View>
            <View>
              <Text>{translations.JsonActivityPages.JsonCreateActivityImageModal.JsonUnsplashGoBackMessage}</Text>
            </View>
          </View>
          <TextInput style={styles.searchInput} placeholder={translations.JsonActivityPages.JsonCreateActivityImageModal.JsonResearchImagePlaceholder} value={searchTerm} onChangeText={setSearchTerm}/>
          {loading && <ActivityIndicator size="large" color="#0000ff"/>}
          {error && <Text style={styles.errorText}>{error}</Text>}
          <FlatList data={images} keyExtractor={item => item.id} numColumns={3} renderItem={({ item }) => (<Pressable onPress={() => pickImage(item)}>
                <Image source={{ uri: item.urls.small }} style={styles.image}/>
              </Pressable>)}/>
        </View>
      </View>
    </Modal>);
};
export default UnsplashPicker;
