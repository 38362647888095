import enTranslations from '../../../json/Translation/En.json';
import frTranslations from '../../../json/Translation/Fr.json';
import esTranslations from '../../../json/Translation/Es.json';
import deTranslations from '../../../json/Translation/De.json';
import itTranslations from '../../../json/Translation/It.json';
import ptTranslations from '../../../json/Translation/Pt.json';
import ruTranslations from '../../../json/Translation/Ru.json';
import hiTranslations from '../../../json/Translation/Hi.json';
import zhTranslations from '../../../json/Translation/Zh.json';
import arTranslations from '../../../json/Translation/Ar.json';
import heTranslations from '../../../json/Translation/He.json';
import jaTranslations from '../../../json/Translation/Ja.json';
import bsTranslations from '../../../json/Translation/Bs.json';
import bgTranslations from '../../../json/Translation/Bg.json';
import hrTranslations from '../../../json/Translation/Hr.json';
import csTranslations from '../../../json/Translation/Cs.json';
import daTranslations from '../../../json/Translation/Da.json';
import etTranslations from '../../../json/Translation/Et.json';
import fiTranslations from '../../../json/Translation/Fi.json';
import elTranslations from '../../../json/Translation/El.json';
import huTranslations from '../../../json/Translation/Hu.json';
import isTranslations from '../../../json/Translation/Is.json';
import gaTranslations from '../../../json/Translation/Ga.json';
import lvTranslations from '../../../json/Translation/Lv.json';
import ltTranslations from '../../../json/Translation/Lt.json';
import mgTranslations from '../../../json/Translation/Mg.json';
import nlTranslations from '../../../json/Translation/Nl.json';
import noTranslations from '../../../json/Translation/No.json';
import roTranslations from '../../../json/Translation/Ro.json';
import gdTranslations from '../../../json/Translation/Gd.json';
import srTranslations from '../../../json/Translation/Sr.json';
import skTranslations from '../../../json/Translation/Sk.json';
import slTranslations from '../../../json/Translation/Sl.json';
import svTranslations from '../../../json/Translation/Sv.json';
import ukTranslations from '../../../json/Translation/Uk.json';
export const translationsMap = {
    En: enTranslations,
    Fr: frTranslations,
    Es: esTranslations,
    De: deTranslations,
    It: itTranslations,
    Pt: ptTranslations,
    Ru: ruTranslations,
    Hi: hiTranslations,
    Zh: zhTranslations,
    Ar: arTranslations,
    He: heTranslations,
    Ja: jaTranslations,
    Bs: bsTranslations,
    Bg: bgTranslations,
    Hr: hrTranslations,
    Cs: csTranslations,
    Da: daTranslations,
    Et: etTranslations,
    Fi: fiTranslations,
    El: elTranslations,
    Hu: huTranslations,
    Is: isTranslations,
    Ga: gaTranslations,
    Lv: lvTranslations,
    Lt: ltTranslations,
    Mg: mgTranslations,
    Nl: nlTranslations,
    No: noTranslations,
    Ro: roTranslations,
    Gd: gdTranslations,
    Sr: srTranslations,
    Sk: skTranslations,
    Sl: slTranslations,
    Sv: svTranslations,
    Uk: ukTranslations,
};
