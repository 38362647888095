import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    containerMetroStation: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        alignItems: 'center',
        paddingVertical: '3%'
    },
    textMetroStation: {
        fontFamily: "Poppins",
        fontSize: 18,
        fontWeight: '700',
        lineHeight: 24,
    },
    containerPostal: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '25%',
        alignItems: 'center',
        marginLeft: '35%',
        paddingVertical: '3%'
    },
    textPostal: {
        fontFamily: "Poppins",
        fontSize: 18,
        fontWeight: '700',
        lineHeight: 24,
    },
});
export default styles;
