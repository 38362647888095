// 🇫🇷 Ce fichier gère la page non bloquante où on l'on peut rentrer un code de parrainage (FrameFigma005)
// 🇬🇧 This file manages the non-blocking page where one can enter a referral code (FrameFigma008).
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Text, View, Platform, Alert } from 'react-native';
import { hostname } from '../../../../hostName/hostName';
import AsyncStorage from '@react-native-async-storage/async-storage';
import TextInputCustomComponent from '../../../components/GeneralSection/TextInputCustomComponent/Code';
import ButtonNext from '../../../components/ButtonSection/NextButton/Code';
import ButtonPrevious from '../../../components/ButtonSection/PreviousButton/Code';
import { useError } from '../../../contexts/ErrorContext';
import Vectors from '../../../constants/Vectors';
import { useSelector } from 'react-redux';
// Import styles conditionally based on Platform
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const Sponsorship = ({ navigation }) => {
    const [displaySponsorshipNumber, setDisplaySponsorshipNumber] = useState('');
    const [sponsorshipNumber, setSponsorshipNumber] = useState('');
    const [isButtonActive, setIsButtonActive] = useState(true);
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { error, setError } = useError();
    // 🇬🇧 Function to format the sponsorship number (ex :111-XX)
    // 🇫🇷 Fonction pour formater le numéro de parrainage (ex :111-XX)
    const formatSponsorshipNumber = (text) => {
        // Réinitialiser l'erreur dès que l'utilisateur commence à taper
        if (error) {
            setError('');
        }
        // Convertir en majuscules et nettoyer le texte
        let upperText = text.toUpperCase().replace(/[^0-9A-Z]/g, '');
        // Extraire les 3 premiers chiffres
        let digits = upperText.slice(0, 3).replace(/[^0-9]/g, '');
        // Extraire les 2 lettres suivantes en majuscule, s'assurant qu'elles sont des lettres
        let letters = upperText.slice(digits.length).replace(/[^A-Z]/g, '').slice(0, 2);
        let formattedDisplayNumber = digits + (digits.length === 3 && letters ? ' ' : '') + letters;
        // Mise à jour pour l'affichage avec espace
        setDisplaySponsorshipNumber(formattedDisplayNumber);
        // Garder une version sans espace pour l'envoi
        setSponsorshipNumber(digits + letters);
        // Activer le bouton si le format est correct ou si le champ est vide
        setIsButtonActive((digits.length === 3 && letters.length === 2) || upperText.length === 0);
    };
    /**
     * 🇬🇧 Verify the sponsorship code, store validation state and sponsor ID if valid, and navigate to the password screen.
     * 🇫🇷 Vérifier le code de parrainage, stocker l'état de validation et l'ID du parrain si valide, et naviguer vers l'écran de mot de passe.
     */
    const verifySponsorshipCode = () => __awaiter(void 0, void 0, void 0, function* () {
        // Si le code de parrainage est vide, naviguer vers l'écran de création de mot de passe
        if (!sponsorshipNumber.trim()) {
            navigation.navigate('NewUserPassword');
            return;
        }
        try {
            // Faire une requête POST pour vérifier le code de parrainage
            const response = yield fetch(`${hostname}/verifySponsorshipCode`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ memberId: sponsorshipNumber }),
            });
            const data = yield response.json();
            if (data.isValid) {
                // Stocker l'état de validité et l'ID du parrain dans AsyncStorage si le code est valide
                yield AsyncStorage.setItem('isSponsorshipCodeValid', 'true');
                if (data.sponsorId) {
                    yield AsyncStorage.setItem('sponsorId', data.sponsorId);
                }
                // Naviguer vers l'écran de création de mot de passe avec l'état de validité du code de parrainage
                navigation.navigate('NewUserPassword', { sponsorshipNumberValid: true });
            }
            else {
                // Stocker l'état invalide dans AsyncStorage et définir le message d'erreur si le code est invalide
                yield AsyncStorage.setItem('isSponsorshipCodeValid', 'false');
                setError(translations.JsonLoginRegisterPages.JsonSponsorshipCode.JsonSponsorshipInvalid);
            }
        }
        catch (error) {
            console.error(error);
            Alert.alert('Error', translations.JsonLoginRegisterPages.JsonSponsorshipCode.JsonSponsorshipError);
        }
    });
    return (<View style={styles.container}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <Text style={styles.title}>{translations.JsonLoginRegisterPages.JsonSponsorshipCode.JsonSponsorshipInformation}</Text>
      <View style={styles.containerInput}>
        <TextInputCustomComponent Icon={() => <Vectors.WireGreen width={25} height={25}/>} placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonMemberShip} value={displaySponsorshipNumber} handleTextChange={formatSponsorshipNumber} isPlaceHolderTextAlignCenter={false}/>
      </View>
      <View style={styles.buttonContainer}>
        <ButtonPrevious onPrevStep={() => navigation.navigate('Email')} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
        <ButtonNext handleNextStep={verifySponsorshipCode} btnText={translations.JsonLoginRegisterPages.JsonEmailScreen.JsonNextButton} conditionNeed={isButtonActive}/>
      </View>
      <Text style={styles.footerText}>{translations.JsonLoginRegisterPages.JsonSponsorshipCode.JsonSponsorshipFlavorText}</Text>
    </View>);
};
export default Sponsorship;
