// Fr Cette page affiche l'étape 4 du formulaire pour modifier une activité (FrameFigma036)
// En This page displays the fourth step of the form to Create an activity (FrameFigma036)
import React, { useState } from 'react';
import { View, Text, Platform, TouchableOpacity, TouchableWithoutFeedback } from 'react-native';
import { activitiesIcon } from '../../../../constants/VectorsOnOff/ActivityListIcon';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
import BottomWhiteNav from '../../../../components/NavBarSection/BottomNav/BottomWhiteNav/Code';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const CreateActivityTopicScreen = ({ onNextStep, onPrevStep, setHeaderTitle }) => {
    const { translations } = useSelector((state) => state.language);
    const { currentActivity } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setCurrentActivity = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivity({ id: nanoid(), value: data }));
    };
    const [topic, setTopic] = useState(currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.topic);
    //Fonction pour sauvegarder le topic choisi
    const handleTopicBtn = (index) => {
        setTopic(index);
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { topic: index, activityTopicTitle: activitiesIcon[index].activityTitle }));
        resetHeader();
    };
    const navigation = useNavigation();
    //Permet de gérer l'affichage des erreurs
    const [isError, setIsError] = useState(false);
    const resetHeader = () => {
        setHeaderTitle('');
        setTimeout(() => {
            setHeaderTitle('Activity topic');
            setIsError(false);
        }, 10);
    };
    const showErrorInHeader = (errorMessage) => {
        navigation.setOptions({
            headerTitle: () => (<Text style={{
                    fontSize: 20,
                    color: 'white',
                    fontWeight: 'bold',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                }}>
                {errorMessage}
              </Text>),
            headerStyle: { backgroundColor: 'red' },
            headerLeft: false,
        });
    };
    const handlePrevStep = () => {
        setHeaderTitle('Help for organizers');
        setIsError(false);
        onPrevStep();
    };
    const handleNextStep = () => {
        if (topic) {
            setIsError(false);
            setHeaderTitle('Activity description');
            onNextStep(); // Appeler la fonction pour passer à l'étape suivante
        }
        else {
            setIsError(true);
            showErrorInHeader(translations.JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen.JsonTopicErrorMessage);
            return;
        }
    };
    return (<TouchableWithoutFeedback onPress={resetHeader} disabled={!isError}>
      <View style={styles.container}> 
        <View>
          <Text style={styles.title}>{translations.JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen.JsonChooseTopic}</Text>
          <Text style={styles.info}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonChooseTopicScreen.JsonIncreaseYourActivityInterestMessage}{' '}
          </Text>
          </View>
          <View style={styles.activityIconContainer}>
            {activitiesIcon.map((activity, index) => (<TouchableOpacity style={[styles.activityCard, { backgroundColor: (currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.topic) === index ? '#59c09b' : 'white' }]} onPress={() => handleTopicBtn(index)}>
                <View style={styles.flexView}>{(currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.topic) === index ? activity.activityIconOn : activity.activityIconOff}</View>
                <Text style={[
                styles.activityTitle,
                {
                    fontWeight: topic === index ? 'bold' : '400',
                    color: topic === index ? 'white' : 'black',
                },
            ]}>
                  {activity.activityTitle}
                </Text>
              </TouchableOpacity>))}
          </View>
          <BottomWhiteNav items={[
            { label: translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton, onPress: () => handlePrevStep() },
            { label: translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton, onPress: () => handleNextStep(), disabled: !topic },
        ]}/>
      </View>
    </TouchableWithoutFeedback>);
};
export default CreateActivityTopicScreen;
