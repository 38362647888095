import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    titleAttendees: {
        flexDirection: 'row',
        paddingHorizontal: 20,
        width: '100%',
        height: 50,
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 10,
        backgroundColor: '#59C09B',
        // backgroundColor: '#FFFFFF',
    },
    arrowDown: {
        width: 30,
        height: 30,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
});
export default styles;
