import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    addressContent: {
        marginTop: height * 0.05, // Adjusted to 5% of screen height
        width: '80%',
    },
    btnContainer: {
        width: '80%',
        alignItems: 'center',
        marginTop: height * 0.33, // Adjusted to 10% of screen height
        zIndex: -2,
    },
    headerError: {
        backgroundColor: 'red',
        padding: height * 0.025, // Adjusted to 2.5% of screen height
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: width * 0.045, // Adjusted to 4.5% of screen width
        fontWeight: 'bold',
    },
});
