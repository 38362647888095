import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    column: {
        flex: 1,
        borderRightWidth: 1,
        borderColor: "#000",
        textAlign: "center",
    },
    greenBold: {
        color: '#59C19C',
        fontWeight: 'bold',
        paddingLeft: '5%',
    },
    boxShadow: {
        zIndex: 2,
        shadowColor: '#000000', // Couleur de l'ombre (noir)
        shadowOffset: { width: 0, height: 4 }, // Décalages horizontal et vertical
        shadowOpacity: 0.25, // Opacité de l'ombre
        shadowRadius: 4, // Rayon de flou
        elevation: 5, // Valeur approximative pour Android
    },
    fleche: {
        color: '#59C19C',
        fontWeight: 'bold',
        right: 3,
    }
});
export default styles;
