import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    user: null,
    userIdStorage: null,
    isUserIdAvailable: false,
    isLoading: false,
    allUsers: [],
    profileVisits: [],
    friendNotifications: [],
    likeNotifications: [],
    activityLikeNotifications: []
};
const UserSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload.value;
            // state.userIdStorage = action.payload.value.userIdStorage;
            // state.isLoading = action.payload.value.isLoading;
            // state.allUsers = action.payload.value.allUsers;
            state.profileVisits = action.payload.value.profileVisits;
            state.friendNotifications = action.payload.value.friendNotifications;
            state.likeNotifications = action.payload.value.likeNotifications;
            state.activityLikeNotifications = action.payload.value.activityLikeNotifications;
        },
        setAllUsers: (state, action) => {
            state.allUsers = action.payload.value;
        },
        updateUser: (state, action) => {
            state.user = action.payload.value;
        },
        updateUserProperties: (state, action) => {
            state.user = Object.assign(Object.assign({}, state.user), action.payload.value);
        },
        updateUserProfileImage: (state, action) => {
            state.user.profileImage = action.payload.value;
        },
        getUserbyId: (state, action) => {
        },
        logout: (state) => {
            state.user = null;
            state.userIdStorage = null;
            state.isLoading = false;
            state.allUsers = [];
            state.profileVisits = [];
            state.friendNotifications = [];
            state.likeNotifications = [];
            state.activityLikeNotifications = [];
        },
        setProfileVisits: (state, action) => {
            const { visitId, isRead } = action.payload.value;
            let index = null;
            state.profileVisits.map((notification, id) => notification._id === visitId ? index = id : null);
            if (index)
                state.profileVisits[index].isRead = isRead;
        },
        setFriendNotifications: (state, action) => {
            const { notificationId, isRead } = action.payload.value;
            let index = null;
            state.friendNotifications.map((notification, id) => notification._id === notificationId ? index = id : null);
            if (index)
                state.friendNotifications[index].isRead = isRead;
        },
        setLikeNotifications: (state, action) => {
            const { notificationId, isRead } = action.payload.value;
            let index = null;
            state.likeNotifications.map((notification, id) => notification._id === notificationId ? index = id : null);
            if (index)
                state.likeNotifications[index].isRead = isRead;
        },
        setActivityLikeNotifications: (state, action) => {
            const { notificationId, isRead } = action.payload.value;
            let index = null;
            state.activityLikeNotifications.map((notification, id) => notification._id === notificationId ? index = id : null);
            if (index)
                state.activityLikeNotifications[index].isRead = isRead;
        },
        updateProfileVisits: (state, action) => {
            const { visitId, isRead } = action.payload.value;
            let index = null;
            state.profileVisits.map((visit, i) => (visit._id === visitId ? index = i : null));
            if (index)
                state.profileVisits[index].isRead = isRead;
        },
        updateFriendNotifications: (state, action) => {
            const { notificationId, isRead } = action.payload.value;
            let index = null;
            state.friendNotifications.map((notification, i) => (notification._id === notificationId ? index = i : null));
            if (index)
                state.friendNotifications[index].isRead = isRead;
        },
        updateLikeNotifications: (state, action) => {
            const { notificationId, isRead } = action.payload.value;
            let index = null;
            state.likeNotifications.map((notification, i) => (notification._id === notificationId ? index = i : null));
            if (index)
                state.likeNotifications[index].isRead = isRead;
        },
        updateActivityLikeNotifications: (state, action) => {
            const { notificationId, isRead } = action.payload.value;
            let index = null;
            state.activityLikeNotifications.map((notification, i) => (notification._id === notificationId ? index = i : null));
            if (index)
                state.activityLikeNotifications[index].isRead = isRead;
        },
        getAllUsers: () => {
        },
        fetchUserFromAllUsers: (allUsers) => {
        },
    }
});
export default UserSlice;
