import React, { useState } from 'react';
import { View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
//import components
import MyButtonPress from '../../../../../components/ButtonSection/PressButton/Code';
//import Modals
import MyModal from '../../../../../components/ModalPopUpModule/ModalSection/ActivityDetailsModal/MyModal';
import ActivityWaitingListModal from '../../../../../components/ModalPopUpModule/ModalSection/ActivityJoinWaitingListModal/Code';
//import utils functions
import activityParticpate from '../../../../../services/activities/ActivityParticpate';
//import services
import { ParticipantsServices } from '../../../../../services/activities/ParticipantsServices';
// import contexts
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
const ActivityDetailsAddressSecretScreen = () => {
    const navigation = useNavigation();
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    // const {activityView, setActivityView} = useActivity();
    const { activityView } = useSelector((state) => state.activities);
    // const {user} = useUser();
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const setActivityView = (data) => {
        dispatch(ActivitiesSlice.actions.setActivityView({ id: nanoid(), value: data }));
    };
    const [modalWaitingListVisible, setModalWaitingListVisible] = useState(false);
    const handleParticipate = (activityView, setActivityView, navigation, user, modalWaitingListVisible, setModalWaitingListVisible) => {
        activityParticpate(activityView, setActivityView, navigation, user, setModalWaitingListVisible);
    };
    const action = (activityView, user, setActivityView, setModalWaitingListVisible) => {
        if (!ParticipantsServices.isUserInWaitingList(activityView, user._id)) {
            ParticipantsServices.joinWaitingList(activityView, user._id, setActivityView, setModalWaitingListVisible);
            setModalWaitingListVisible(false);
            return;
        }
        else {
            ParticipantsServices.leaveWaitingList(activityView, user._id, setActivityView, setModalWaitingListVisible);
            setModalWaitingListVisible(false);
            return;
        }
    };
    return (<>
            <MyModal modalVisible={modalWaitingListVisible} onClose={() => setModalWaitingListVisible(false)} w={400}>
                <ActivityWaitingListModal action={() => action(activityView, user, setActivityView, setModalWaitingListVisible)} setModalVisible={setModalWaitingListVisible} activity={activityView} user={user}/>
            </MyModal>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                {ParticipantsServices.isUserInWaitingList(activityView, user._id) ?
            <MyButtonPress disabled={activityView.isPassed} text='Leave WaitingList' txtCol={'white'} txtS={20} txtFW={'bold'} w={190} h={47} bgC={activityView.isPassed ? 'grey' : 'orange'} br={10} cb={() => setModalWaitingListVisible(true)}/>
            :
                <MyButtonPress disabled={activityView.isPassed} text='Participate' txtCol={'white'} txtS={20} txtFW={'bold'} w={190} h={47} bgC={activityView.isPassed ? "grey" : "#59c09b"} br={10} cb={() => handleParticipate(activityView, setActivityView, navigation, user, modalWaitingListVisible, setModalWaitingListVisible)}/>}
            </View>
        </>);
};
export default ActivityDetailsAddressSecretScreen;
