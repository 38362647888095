import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isLoggedIn: false,
    user: null,
    userId: '',
    profileImage: '',
    userName: '',
    userToken: '',
    loginTimestamp: ''
};
const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action) => {
            state.loginTimestamp = new Date().getTime().toString();
            state.userToken = action.payload.value.token;
            state.isLoggedIn = true;
            state.user = action.payload.value.user;
            state.userId = action.payload.value.userId;
            state.profileImage = action.payload.value.profileImage;
            state.userName = action.payload.value.userName;
        },
        logout: (state) => {
            state.isLoggedIn = false;
            state.user = null;
            state.userId = '';
            state.profileImage = '';
            state.userName = '';
            state.userToken = '';
        }
    }
});
export default AuthSlice;
