// Fr  Styles de DuplicateActivityTopicScreen(FrameFigma036)
// En  Styles of DuplicateActivityTopicScreen(FrameFigma036)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        paddingLeft: 25,
        paddingRight: 25,
    },
    title: {
        fontWeight: 'bold',
        marginLeft: 20,
        fontSize: 18,
        marginTop: 25,
        marginBottom: 15,
    },
    info: {
        marginBottom: 5,
        paddingHorizontal: 20,
    },
    activityIconContainer: {
        flexWrap: 'wrap',
        borderRadius: 30,
        marginTop: 15,
        // marginHorizontal: 0,
        flexDirection: 'row',
        justifyContent: 'center',
        paddingVertical: 20,
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.25, // équivalent de 40% en opacité
        shadowRadius: 4,
        elevation: 4, // Android utilise `elevation` pour les ombres
        // marginHorizontal: -2.5
        // marginHorizontal:-20,
        color: 'black',
    },
    activityCard: {
        marginHorizontal: 4,
        marginVertical: 8,
        borderRadius: 10,
        width: 80,
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.25, // équivalent de 40% en opacité
        shadowRadius: 4,
        elevation: 4, // Android utilise `elevation` pour les ombres
    },
    activityTitle: {
        marginBottom: 5,
        fontSize: 12.5,
    },
    activityView: {
        position: 'relative',
    },
    flexView: {
        flex: 1,
        justifyContent: 'center',
    },
    btnContainer: {
        width: '100%',
        alignContent: 'center',
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    btnNext: {
        backgroundColor: '#59C09B',
        width: '55%',
        padding: 8,
        borderRadius: 15,
        marginHorizontal: 50,
        alignItems: 'center',
        elevation: 5,
        margin: 30,
    },
    btnText: {
        color: 'white',
        fontSize: 22,
        fontWeight: 'bold',
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
