var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// 🇬🇧 This file handles the first step for profile modification (Image, First Name, Nickname, Native Language, Application Language). (FrameFigma014A FrameFigma014B)
// 🇫🇷 Ce fichier gère la première étape pour les modifications profile (Image, Prenom, Surnom,Langue Maternelle,Langue d'Application). (FrameFigma014A FrameFigma014B)
import React, { useState, useEffect } from 'react';
import { View, Image, TouchableOpacity, Text, Pressable, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Picker } from '@react-native-picker/picker';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useError } from '../../../../contexts/ErrorContext';
import { verifyExistingUserName, generateUsernameSuggestions } from '../../../../components/ProfileSection/UniqueUserName/Code';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import countriesListSquareFlags from '../../../../components/GeneralSection/LanguagesFlagsComponent/LanguagesFlagsSquare';
import ProgressBar from '../../../../components/ProfileSection/ProgressBar/Code';
import ImageCropperWeb from '../../../../components/ImageCropperSection/ImageProfileCrop/Code';
import ImageCropperMobile from '../../../../components/ImageCropperSection/ImageProfileCrop/CodeMobile';
import Modal from '../../../../components/ModalPopUpModule/ModalSection/ActivityDetailsModal/Code';
import AddressAutoPicker from '../../../../components/PickerSection/AdressAutoProfilePicker/Code';
//import MultipleProfilePictures from '../../../../components/MultipleProfilePictures/MultipleProfilePictures'; Le composant permettant a l'utilisateur d'ajouter plusieur image de profile, non fonctionnelle pour le moment
import Vectors from '../../../../constants/Vectors';
import { useDispatch, useSelector } from 'react-redux';
import LanguageSlice from '../../../../redux/Features/Language/LanguagesSlice';
import { nanoid } from '@reduxjs/toolkit';
import { userServices } from '../../../../services/user/UserServices';
import UserSlice from '../../../../redux/Features/User/UserSlice';
import BottomWhiteNav from '../../../../components/NavBarSection/BottomNav/BottomWhiteNav/Code';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const EditProfileStep1Screen = ({ onNextStep }) => {
    var _a, _b;
    const [modalVisible, setModalVisible] = useState(false); //Utiliser pour l'affichage du cropper lorsque l'on clique sur l'image de profile
    // const { user, updateUser, updateUserProfileImage, getUserById } = useUser();
    const { user } = useSelector((state) => state.user);
    const { updateUser, updateUserProfileImage, getUserById } = userServices;
    const dispatch = useDispatch();
    // const { changeLanguage, translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const changeLanguage = LanguageSlice.actions.changeLanguage;
    const { error, setError } = useError();
    const [isLoading, setIsLoading] = useState(false);
    const [profileImageFile, setProfileImageFile] = useState(null);
    const navigation = useNavigation();
    const [progress, setProgress] = useState(0);
    const [displayLanguage, setDisplayLanguage] = useState('');
    const [selectedFlag, setSelectedFlag] = useState(null);
    const [isChanged, setIsChanged] = useState(false);
    const [formDataBefore, setFormDataBefore] = useState({});
    const [isImageSelected, setIsImageSelected] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [cropperModalVisible, setCropperModalVisible] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [usernameSuggestions, setUsernameSuggestions] = useState([]);
    const [isUsernameTaken, setIsUsernameTaken] = useState(false);
    const [isUsernameRepeated, setIsUsernameRepeated] = useState(true);
    //const [showMultiplePictures, setShowMultiplePictures] = useState(false); setter modal d'image
    //const [profilePictureGallery, setProfilePictureGallery] = useState<string[]>([]); setter modal d'image
    /**
     * 🇬🇧 State for form variables
     * 🇫🇷 State pour les variable du formulaire
     */
    const [formData, setFormData] = useState({
        //profilePictureGallery: user?.profilePictureGallery || [], Tableau d'image non encore implementer
        profileImage: '',
        accountType: '',
        firstName: '',
        userName: '',
        city: '',
        nativeLanguage: '',
        appDisplayLanguages: '',
    });
    /**
     * 🇬🇧 Loads user language preferences and profile data from AsyncStorage if available, otherwise from DB.
     * 🇫🇷 Charge les préférences linguistiques de l'utilisateur et les données de profil depuis AsyncStorage temporaire s'il existe, sinon depuis la DB.
     */
    useEffect(() => {
        // Load languages and data when user changes
        loadLanguagesAndData();
    }, [user]);
    const loadLanguagesAndData = () => __awaiter(void 0, void 0, void 0, function* () {
        // Retrieve user's selected display language from AsyncStorage
        const storedDisplayLanguage = yield AsyncStorage.getItem('selectedLanguage');
        // Get the default language if no language is stored
        const defaultLanguage = countriesListSquareFlags[0];
        // Update form data with stored or default display language
        setFormData(prevFormData => (Object.assign(Object.assign({}, prevFormData), { appDisplayLanguages: storedDisplayLanguage || defaultLanguage.bigram })));
        // Set selected flag based on default language
        setSelectedFlag(defaultLanguage.FlagComponent);
        // Set display language based on stored or default language
        setDisplayLanguage(storedDisplayLanguage || defaultLanguage.language);
        // Retrieve user's profile data from AsyncStorage
        const storedData = yield AsyncStorage.getItem('profileData1');
        if (storedData) {
            // Parse stored profile data
            const parsedData = JSON.parse(storedData);
            // Update form data with stored profile data
            setFormData(parsedData);
            setFormDataBefore(parsedData);
            setIsChanged(JSON.stringify(parsedData) !== JSON.stringify(formDataBefore));
        }
        else if (user) {
            // If no stored data, use user's data from context
            const initialFormData = {
                profileImage: user.profileImage || '',
                //profilePictureGallery: user.profilePictureGallery || [],
                accountType: user.accountType || '',
                firstName: user.firstName || '',
                userName: user.userName || '',
                city: user.city || '',
                nativeLanguage: user.nativeLanguage || '',
                appDisplayLanguages: user.appDisplayLanguages || user.nativeLanguage || '',
            };
            // Set initial form data
            setFormData(initialFormData);
            // Set form data before changes
            setFormDataBefore(initialFormData);
            //setProfilePictureGallery(user.profilePictureGallery || []);
        }
    });
    /*useEffect(() => { //Utiliser par le profilePictureGallery
      if (user) {
        setProfilePictureGallery(user.profilePictureGallery || []);
      }
    }, [user]);*/
    /**
     * 🇬🇧 Checks if the form data has been modified and updates the IsChanged setter.
     * The IsChanged setter is used to determine if the left button should be a save or cancel.
     * 🇫🇷 Vérifie si les données du formulaire ont été modifiées et met a jour le setter IsChanged,
     * Le setter IsChanged qui permet de détérminer si le bouton de gauche sera un sauvegarder ou un cancel.
     */
    const checkIsChanged = (updatedFormData) => {
        const isFormChanged = Object.keys(updatedFormData).some(key => updatedFormData[key] !== formDataBefore[key]);
        setIsChanged(isFormChanged);
    };
    /**
     * 🇬🇧 When you click on the profile picture it open the modal that allow you to change profile picture
     * 🇫🇷 Lorsque vous cliquez sur la photo de profil, la fenêtre modale s'ouvre et vous permet de changer la photo de profil.
     */
    const openImagePickerModal = () => {
        setModalVisible(true);
        //setShowMultiplePictures(true); Mettre en commentaire l'autre code si on veut utiliser celui-ci
    };
    /**
     * 🇬🇧 When a picture is selected the cropper is called
     * 🇫🇷 Lorsqu'une image est sélectionnée, le recadrage est appelé
     */
    const onImageSelectedHandler = (data) => {
        setSelectedImage(data);
        setIsImageSelected(true);
        setCropperModalVisible(true);
    };
    /**
      * 🇬🇧 When the picture has been cropped, this new cropped image gets a URL and replace the former image, a simulated progress bar is shown but doesn't represent anything
      * 🇫🇷 Lorsque l'image a été recadrée, cette nouvelle image recadrée reçoit une URL et remplace l'ancienne image, une barre de progression simulée est affichée mais ne représente rien.
      */
    const handleCroppedImage = (croppedImage) => {
        setError('');
        setSelectedImage(croppedImage);
        setCropperModalVisible(false);
        if (Platform.OS === 'web') {
            const imageUrl = URL.createObjectURL(croppedImage.file);
            setFormData(prevFormData => (Object.assign(Object.assign({}, prevFormData), { profileImage: imageUrl })));
        }
        else {
            setFormData(prevFormData => (Object.assign(Object.assign({}, prevFormData), { profileImage: croppedImage.imageUrl })));
        }
        setProfileImageFile(croppedImage.file);
        setIsChanged(true);
        simulateProgress();
    };
    /**
     * 🇬🇧 A simulated progress bar that doesn't represent any calculation
     * 🇫🇷 Une barre de progression simulée qui ne représente aucun calcul
     */
    const simulateProgress = () => {
        setProgress(0);
        setIsLoading(true);
        const interval = setInterval(() => {
            setProgress(currentProgress => {
                const newProgress = currentProgress + 0.1;
                if (newProgress >= 1) {
                    clearInterval(interval);
                    setIsLoading(false);
                    return 1;
                }
                return newProgress;
            });
        }, 100);
    };
    /**
     * 🇬🇧 Saves updated form data to temporary AsyncStorage storage.
     * 🇫🇷 Enregistre les données du formulaire mises à jour dans le stockage temporaire AsyncStorage.
     */
    const saveTemporaryData = (updatedFormData) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield AsyncStorage.setItem('profileData1', JSON.stringify(updatedFormData));
        }
        catch (error) {
            setError(error.message);
            console.error('Failed to save profileData1', error);
        }
    });
    /**
     * 🇬🇧 Handles input changes by updating form data and saving it to AsyncStorage.
     * 🇫🇷 Gère les changements de saisie en mettant à jour les données du formulaire et en les sauvegardant dans AsyncStorage.
     */
    const handleInputChange = (key, value) => __awaiter(void 0, void 0, void 0, function* () {
        const updatedFormData = Object.assign(Object.assign({}, formData), { [key]: value });
        setFormData(updatedFormData);
        setError(''); // Réinitialiser les erreurs
        checkIsChanged(updatedFormData);
        // Save updated formData to AsyncStorage
        yield saveTemporaryData(updatedFormData);
    });
    /**
     * 🇬🇧 Filter character input for names to match given limitation
     * 🇫🇷 Filtre l'entrée des caractère pour les nom pour quelle soit conforme au limitation donnée
     */
    const handleTextChange = (key, value) => {
        value = value.replace(/[^a-zA-ZÀ-ÿ'\-\s]/g, '') // Filtrer les caractères non autorisés (autres que lettres, accents, espaces, apostrophes et tirets)
            .replace(/^[\s'-]+/, '') // Filtrer les espaces, tirets et apostrophes en début de chaîne
            .replace(/[\s'-]{2,}/g, match => match[0]) // Remplacer les répétitions de caractères spéciaux par un seul
            .replace(/([^\s'-][A-ZÀ-Ÿ])/g, match => match.toLowerCase()) // Convertir les majuscules qui ne sont pas au début d'un bloc en minuscules
            .replace(/(?:^|[\s'-])([a-zà-ÿ])/g, match => match.toUpperCase()); // Assurer que chaque bloc commence par une majuscule, si nécessaire
        handleInputChange(key, value);
        setError('');
    };
    const handleNicknameChange = (text) => __awaiter(void 0, void 0, void 0, function* () {
        // Filter out unauthorized characters
        text = text.replace(/[^\p{L}0-9.-]/gu, '')
            .replace(/^[.-]+/, '') // Remove leading dots and dashes
            .replace(/[.-]{2,}/g, match => match[0]); // Replace consecutive dots and dashes with a single one
        // Check if username has changed
        setIsUsernameTaken(text === formDataBefore.userName); // Update isUsernameTaken state before verification
        setIsUsernameRepeated(true);
        setValidationErrors({});
        setError('');
        // Update form data immediately
        handleInputChange('userName', text);
        // Check if the new username is already taken
        if (text !== formDataBefore.userName) {
            setIsUsernameRepeated(false);
            const isTaken = yield verifyExistingUserName(text);
            if (isTaken) {
                setValidationErrors({ nickname: translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonUserNameTaken });
                const suggestions = yield generateUsernameSuggestions(text);
                setUsernameSuggestions(suggestions);
            }
            else {
                setValidationErrors({});
                setUsernameSuggestions([]);
            }
        }
    });
    /**
     * 🇬🇧 Handle the change of user's native language.
     * 🇫🇷 Gérer le changement de langue maternelle de l'utilisateur.
     */
    const handleNativeLanguageChange = (language) => __awaiter(void 0, void 0, void 0, function* () {
        const updatedFormData = Object.assign(Object.assign({}, formData), { nativeLanguage: language });
        setFormData(updatedFormData);
        checkIsChanged(updatedFormData);
        try {
            //await AsyncStorage.setItem('nativeLanguage', language);
            yield AsyncStorage.setItem('profileData1', JSON.stringify(updatedFormData));
        }
        catch (error) {
            setError(error.message);
            console.error('Failed to save native language', error);
        }
    });
    /**
     * 🇬🇧 Handle the change of app display language.
     * 🇫🇷 Gérer le changement de langue d'affichage de l'application.
     */
    const handleDisplayLanguageChange = (language) => __awaiter(void 0, void 0, void 0, function* () {
        const updatedFormData = Object.assign(Object.assign({}, formData), { appDisplayLanguages: language });
        setFormData(updatedFormData);
        checkIsChanged(updatedFormData);
        try {
            yield AsyncStorage.setItem('selectedLanguage', language);
            yield AsyncStorage.setItem('profileData1', JSON.stringify(updatedFormData));
            setDisplayLanguage(language);
            simulateProgress();
        }
        catch (error) {
            setError(error.message);
            console.error('Échec de la récupération des détails du lieu', error);
        }
    });
    /**
     * 🇬🇧 Get the flag component for the selected native language.
     * 🇫🇷 Obtenir le composant de drapeau pour la langue maternelle sélectionnée.
     */
    const selectedNativeLanguageFlag = (_a = countriesListSquareFlags.find(lang => lang.bigram === formData.nativeLanguage)) === null || _a === void 0 ? void 0 : _a.FlagComponent;
    /**
     * 🇬🇧 Get the flag component for the selected display language.
     * 🇫🇷 Obtenir le composant de drapeau pour la langue d'affichage sélectionnée.
     */
    const selectedDisplayLanguageFlag = (_b = countriesListSquareFlags.find(lang => lang.bigram === displayLanguage)) === null || _b === void 0 ? void 0 : _b.FlagComponent;
    /**
     * 🇬🇧 Handle form submission.
     * 🇫🇷 Gérer la soumission du formulaire.
     */
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        // Check if required fields are empty
        if (!formData.firstName || !formData.userName || !formData.city) {
            setError(translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonMissingFields);
            return;
        }
        // Check if the first name is of minimum length
        if (formData.firstName.length < 2) {
            setError(translations.JsonCreateProfilePages.JsonFirstNamePlaceholder + ' ' + translations.JsonCreateProfilePages.JsonErrorNameMinLength);
            return;
        }
        if (validationErrors.nickname) {
            setError(translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonUserNameTaken);
            return;
        }
        // If no changes, navigate back to profile screen and delete the temp storage
        if (!isChanged) {
            try {
                yield AsyncStorage.removeItem('profileData1');
                yield AsyncStorage.removeItem('profileData2');
                yield AsyncStorage.removeItem('profileData3');
                //console.log('Temp data removed)')
            }
            catch (error) {
                setError(error.message);
                console.error('Failed to remove form data from AsyncStorage', error);
            }
            navigation.navigate('MyProfileScreen', { userData: user, userId: user._id });
            return;
        }
        setIsLoading(true);
        try {
            // Update user information without profile image
            let updated = yield updateUser(user._id, formData);
            if (updated)
                dispatch(UserSlice.actions.updateUserProperties({ id: nanoid(), value: formData }));
            // Update profile image separately if an image is selected
            if (profileImageFile) {
                let image = null;
                if (Platform.OS === "web")
                    image = profileImageFile;
                else
                    image = {
                        // Create a file object-like structure
                        uri: 'file://' + formData.profileImage,
                        name: profileImageFile.name,
                        type: profileImageFile.type,
                    };
                yield updateUserProfileImage(user._id, image);
            }
            //console.log('User updated successfully');
            // Refresh user information
            getUserById(user._id);
            // Change app language
            changeLanguage({ id: nanoid(), value: formData.appDisplayLanguages });
            try {
                // Remove temporary form data from AsyncStorage
                yield AsyncStorage.removeItem('profileData1');
                yield AsyncStorage.removeItem('profileData2');
                yield AsyncStorage.removeItem('profileData3');
                //console.log('Temp data removed)')
            }
            catch (error) {
                setError(error.message);
                console.error('Failed to remove form data from AsyncStorage', error);
            }
            // Navigate back to profile screen
            navigation.navigate('MyProfileScreen', { userData: user, userId: user._id });
        }
        catch (error) {
            setError(error.message);
            console.error('Error updating user:', error);
        }
        finally {
            setIsLoading(false);
            setIsChanged(false);
        }
    });
    /**
     * 🇬🇧 Handle the change of account type. (There is no difference between the accounts yet)
     * 🇫🇷 Gérer le changement du type de compte. (Il n'y a pas encore de difference entre les comptes)
     */
    const handleAccountTypeChange = (accountType) => __awaiter(void 0, void 0, void 0, function* () {
        setFormData(prevFormData => (Object.assign(Object.assign({}, prevFormData), { accountType: accountType })));
        setIsChanged(accountType !== formDataBefore.accountType);
        yield saveTemporaryData(Object.assign(Object.assign({}, formData), { accountType: accountType }));
    });
    /**
     * 🇬🇧 Handle the continue button press.
     * 🇫🇷 Gérer l'appui sur le bouton de continuer.
     */
    const handleContinue = () => __awaiter(void 0, void 0, void 0, function* () {
        // Reset error state
        setError('');
        // If no changes, remove temporary form data and proceed to next step
        if (!isChanged) {
            try {
                yield AsyncStorage.removeItem('profileData1');
            }
            catch (error) {
                setError(error.message);
            }
        }
        onNextStep(); // Proceed to next step
    });
    /**
     * 🇬🇧 Select appropriate image cropper component based on platform.
     * 🇫🇷 Sélectionner le composant de recadrage d'image approprié en fonction de la plateforme.
     */
    const ImageCropper = Platform.OS === 'web' ? ImageCropperWeb : ImageCropperMobile;
    /**
     * 🇬🇧 Check if the form is complete.
     * 🇫🇷 Vérifier si le formulaire est complet.
     */
    const isFormComplete = formData.firstName.length >= 2 &&
        formData.userName &&
        formData.city &&
        (!isUsernameTaken || isUsernameRepeated);
    return (<View style={styles.editProfileContainer}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      {/*showMultiplePictures ? ( //L'affichage du modal qui permet d'ajouter plusieur image de profile doit remplacer celui qui ne fait que remplacer l'image de profile
          <MultipleProfilePictures
            onBack={() => setShowMultiplePictures(false)}
            defaultProfileImage={formData.profileImage}
            profilePictureGallery={profilePictureGallery}
          />
        ) : (
        <View style={styles.profileImageContainer}>
        <View style={styles.profileImageContainer}>
          <View>
          <View style={styles.profileImageContainer}>
          <View>
            <Pressable onPress={openImagePickerModal}>
              {formData.profileImage ? (
                 <Image key={formData.profileImage} source={{ uri: formData.profileImage }} style={styles.image} />
              ) : (
                <Vectors.ProfilesNoGender />
              )*/}
      <View style={styles.profileImageContainer}>
        <View>
          <Pressable onPress={openImagePickerModal}>
            {formData.profileImage ? (<Image key={formData.profileImage} source={{ uri: formData.profileImage }} style={styles.image}/>) : (<Vectors.ProfilesNoGender />)}
          </Pressable>
        </View>
        {isLoading && <ProgressBar progress={progress}/>}
        <View style={styles.camera}>
          <Pressable onPress={openImagePickerModal}>
            <Vectors.ProfilesCamera />
          </Pressable>
        </View>
        {isImageSelected && (<Modal modalVisible={cropperModalVisible} onClose={() => setCropperModalVisible(false)}>
            {Platform.OS === 'web' ? (<ImageCropperWeb file={selectedImage === null || selectedImage === void 0 ? void 0 : selectedImage.file} handleCroppedImage={handleCroppedImage}/>)
                : (<ImageCropperMobile uri={selectedImage === null || selectedImage === void 0 ? void 0 : selectedImage.imageUrl} handleCroppedImage={handleCroppedImage}/>)}
          </Modal>)}
      </View>
      {/* <ProfileImagePickerModal onImageSelected={onImageSelectedHandler} modalVisible={modalVisible} setModalVisible={setModalVisible} /> */}
      <View style={styles.compte}>
        <TouchableOpacity onPress={() => handleAccountTypeChange('personal')} style={[styles.ButtonPerso, formData.accountType === 'personal' && { backgroundColor: '#59C09B' }]}>
          <Text style={styles.textButtonPersoPro}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonPersonal}</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => handleAccountTypeChange('professional')} style={[styles.ButtonPro, formData.accountType === 'professional' && { backgroundColor: '#59C09B' }]}>
          <Text style={styles.textButtonPersoPro}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonPro}</Text>
        </TouchableOpacity>
      </View>

      <View style={styles.userForm}>
        <View style={styles.inputContainer}>
          <Text style={styles.label}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonFirstName}</Text>
          <TextInputCustomComponent Icon={Vectors.ProfilesLastName} placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonFirstName} value={formData.firstName} handleTextChange={text => handleTextChange('firstName', text)} isPlaceHolderTextAlignCenter={false}/>
        </View>

        <View style={styles.inputContainer}>
          <Text style={styles.label}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonNickname}</Text>
          <TextInputCustomComponent Icon={Vectors.ProfilesLastName} placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonNickname} value={formData.userName} handleTextChange={text => handleNicknameChange(text)} isPlaceHolderTextAlignCenter={false}/>
        </View>
        {validationErrors.nickname && (<View>
            <Text style={styles.errorText}>{validationErrors.nickname}</Text>
            <View style={styles.suggestionsContainer}>
              {usernameSuggestions.map((suggestion, index) => (<TouchableOpacity key={index} style={styles.suggestionButton} onPress={() => handleNicknameChange(suggestion)}>
                  <Text style={styles.buttonBottomText}>{suggestion}</Text>
                </TouchableOpacity>))}
            </View>
          </View>)}
        <View style={styles.inputContainer}>
          <Text style={styles.label}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonActivityAroundYourCity}</Text>
          <AddressAutoPicker 
    //onAddressSelect
    selectedAddress={user === null || user === void 0 ? void 0 : user.city} setSelectedAddress={(text) => handleInputChange('city', text)} placeholder={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonActivityAroundYourCity}/>
        </View>
        <View style={styles.inputContainer}>
          <Text style={styles.label}>
            {translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonNativeLanguagePlaceholder + ' ' + translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonLanguageVisibleOnYourProfile.charAt(0).toLowerCase() + translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonLanguageVisibleOnYourProfile.slice(1)}
          </Text>
          <View style={styles.containerWithIcon}>
            <Vectors.ProfilesLanguage style={styles.vectorLangage}/>
            <Picker selectedValue={formData.nativeLanguage} onValueChange={handleNativeLanguageChange} style={styles.pickerStyle} mode="dropdown">
              {countriesListSquareFlags.map(({ language, bigram }) => (<Picker.Item key={bigram} label={language} value={bigram}/>))}
            </Picker>
            {selectedNativeLanguageFlag && React.createElement(selectedNativeLanguageFlag, { style: styles.flagStyle })}
          </View>
        </View>
        <View style={styles.separator}/>
        <View style={styles.inputContainer}>
          <Text style={styles.label}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonAppLanguageDisplay}</Text>
          <View style={styles.containerWithIcon}>
            <Vectors.ProfilesLanguage style={styles.vectorLangage}/>
            <Picker selectedValue={displayLanguage} onValueChange={handleDisplayLanguageChange} style={styles.pickerStyle} mode="dropdown">
              {countriesListSquareFlags.map(({ language, bigram }) => (<Picker.Item key={bigram} label={language} value={bigram}/>))}
            </Picker>
            {selectedDisplayLanguageFlag && React.createElement(selectedDisplayLanguageFlag, { style: styles.flagStyle })}
          </View>
        </View>
      </View>
      <BottomWhiteNav items={[
            { label: ((isChanged || !isFormComplete)) ? translations.JsonProfilePages.JsonEditProfileScreen.JsonSaveButton : translations.JsonProfilePages.JsonEditProfileScreen.JsonCancelButton, onPress: () => handleSubmit() },
            { label: translations.JsonProfilePages.JsonEditProfileScreen.JsonContinueButton, onPress: () => handleContinue() },
        ]}/>
    </View>);
};
export default EditProfileStep1Screen;
