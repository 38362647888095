var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { TouchableOpacity, Text, StyleSheet, View } from 'react-native';
import Vectors from '../../../constants/Vectors';
import { format } from 'date-fns';
const ActivitiesLikeNotificationCard = ({ notificationId, likerUserName, activityTitle, likedAt, isRead, onRead, onUnread, isMute, }) => {
    // Cette fonction est appelée lorsque l'utilisateur appuie sur la notification
    const handlePress = () => __awaiter(void 0, void 0, void 0, function* () {
        // Si la notification est déjà lue, on appelle onUnread pour la marquer comme non lue
        // Sinon, on appelle onRead pour la marquer comme lue
        if (isRead) {
            yield onUnread(notificationId);
        }
        else {
            yield onRead(notificationId);
        }
    });
    return (<TouchableOpacity style={[styles.card, isRead ? styles.pressedCard : styles.unpressedCard]} onPress={handlePress}>
        {isMute ? (<Vectors.NotificationsBellMute style={styles.NotificationsVectors}/>) : isRead ? (<Vectors.NotificationsBellOn style={styles.NotificationsVectors}/>) : (<Vectors.NotificationsBellRinging style={styles.NotificationsVectors}/>)}
        <View style={styles.textContainer}>
          <Text style={styles.text}>
            {likerUserName} liked your activity "{activityTitle}".
          </Text>
          <View style={styles.clockContainer}>
            <Vectors.NotificationsClock style={styles.NotificationsVectors}/>
            <Text style={styles.textClock}>{format(new Date(likedAt), ' dd/MM/yyyy  p')}</Text>
          </View>
        </View>
      </TouchableOpacity>);
};
const styles = StyleSheet.create({
    card: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: '5%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minHeight: 87,
        maxHeight: 'auto',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 0,
        },
        borderColor: 'transparent',
        shadowOpacity: 0.25,
        shadowRadius: 8,
    },
    textContainer: {
        flex: 0.7,
        justifyContent: 'flex-start',
    },
    clockContainer: {
        flexDirection: 'row',
        alignItems: 'baseline',
        justifyContent: 'flex-start',
    },
    unpressedCard: {
        backgroundColor: '#B6DFD0', // Une teinte différente pour les notifications non lues
    },
    pressedCard: {
        backgroundColor: '#F8F8F8', // Une teinte plus claire pour les notifications lues
    },
    text: {
        fontSize: 14,
    },
    NotificationsVectors: {
        width: 30,
        height: 30,
    },
    textClock: {
        fontSize: 14,
    },
});
export default ActivitiesLikeNotificationCard;
