import { StyleSheet, Dimensions } from 'react-native';
const { width } = Dimensions.get('window');
// Define styles that can be handled by StyleSheet.create
const baseStyles = StyleSheet.create({
    tabBarStyle: {
        height: '10%', // Adjusted height for mobile devices.
        backgroundColor: '#59C09B', // Background color for the tab bar.
        marginBottom: '-2%',
    },
    tabBarItemStyle: {
        flexDirection: "column", // Ensures Vectors and texts are one above the other.
        alignItems: 'center', // Centers items horizontally.
        justifyContent: 'center', // Centers items vertically.
        width: width / 5, // Ensures each tab has equal width, adapted to the screen width.
    },
    tabBarIcon: {
        width: 44, // Fixed width for all Vectors for uniformity.
        height: 44, // Fixed height for all Vectors.
    },
    tabBarLabel: {
        fontSize: 12, // Font size adapted for mobile devices.
        margin: 0, // Removes margins around the text.
        padding: 0, // Removes padding around the text.
    },
});
// Define custom styles object with additional properties
const customStyles = {
    screenOptionsStyles: {
        tabBarStyle: baseStyles.tabBarStyle,
        tabBarItemStyle: baseStyles.tabBarItemStyle,
        tabBarActiveTintColor: "white", // Text color for the active tab.
        tabBarInactiveTintColor: "white", // Text color for the inactive tabs.
        tabBarActiveBackgroundColor: "#F48225",
        tabBarInactiveBackgroundColor: "#59C09B",
    },
    tabScreenOptions: {
        tabBarIcon: baseStyles.tabBarIcon,
        tabBarLabel: baseStyles.tabBarLabel,
    },
};
export default customStyles;
