var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, Image, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
// import AsyncStorage from '@react-native-async-storage/async-storage';
// import { hostname } from '../../../../hostName/hostName';
import { Vectors } from '../../../constants/Vectors';
import { activitiesIcon } from '../../../constants/VectorsOnOff/ActivityListIcon';
//import contexts
//import services
import { authorServices } from '../../../services/activities/auhorServices';
import { ActivityServices } from '../../../services/activities/ActivityServices';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
//import styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const MyActivityCard = ({ activity, userId }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const navigation = useNavigation();
    const [liked, setLiked] = useState(false);
    // const [likeId, setLikeId] = useState(null);
    const [authorDetails, setAuthorDetails] = useState(null);
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    // const { setAuthor, setActivityView } = useActivity();
    const dispatch = useDispatch();
    const setAuthor = (data) => {
        dispatch(ActivitiesSlice.actions.setAuthor({ id: nanoid(), value: data }));
    };
    const setActivityView = (data) => {
        dispatch(ActivitiesSlice.actions.setActivityView({ id: nanoid(), value: data }));
    };
    // const checkLikedStatus = async () => {
    //   try {
    //     const userId = await AsyncStorage.getItem('userId');
    //     const userToken = await AsyncStorage.getItem('userToken');
    //     const response = await fetch(`${hostname}/activityLikes`, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${userToken}`,
    //       },
    //     });
    //     if (response.ok) {
    //       const likedActivities = await response.json();
    //       const likedActivity:any = likedActivities.find((likedActivity: { activity: any; likedBy: string | null; }) => likedActivity.activity === activity._id && likedActivity.likedBy === userId);
    //       if (likedActivity) {
    //         setLiked(true);
    //         setLikeId(likedActivity._id);
    //       } else {
    //         setLiked(false);
    //         setLikeId(null);
    //       }
    //     } else {
    //       console.error('Erreur lors de la récupération des activités likées:', response.status, response.statusText);
    //     }
    //   } catch (error) {
    //     console.error('Erreur lors de la récupération des activités likées:', error);
    //   }
    // };
    // interface AuthorImage{
    //   profileImage:string | null
    // }
    const getActAuthor = (authorID) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const author = yield authorServices.getAuthor(authorID);
            setAuthorDetails(author);
        }
        catch (error) {
            console.error('Erreur lors de la récupération de l\'auteur de l\'activité:', error);
        }
    });
    useEffect(() => {
        getActAuthor(activity.author);
        return () => {
            setAuthorDetails(null);
        };
    }, [activity.author]);
    // useEffect(() => {
    //   checkLikedStatus();
    // }, [activity._id]);
    // useFocusEffect(
    //   useCallback(() => {
    //     checkLikedStatus();
    //   }, [activity._id]),
    // )
    // const handleUnlikeAction = async () => {
    //   if (likeId) {
    //     await handleUnlike(likeId);
    //     checkLikedStatus();
    //   } else {
    //     console.error("L'ID du like n'est pas disponible.");
    //   }
    // };
    const userStatus = {
        participant: (activity === null || activity === void 0 ? void 0 : activity.attendees.length) > 0 ? (_a = activity.attendees) === null || _a === void 0 ? void 0 : _a.includes(userId) : false,
        inWaitingList: (activity === null || activity === void 0 ? void 0 : activity.waitingList.length) > 0 ? activity.waitingList.includes(userId) : false,
    };
    const displayActivityDetails = () => __awaiter(void 0, void 0, void 0, function* () {
        setAuthor(authorDetails);
        const response = yield ActivityServices.getActivity(activity._id);
        const act = response.data;
        {
            new Date(activity.date).getTime() > new Date().getTime() ? setActivityView(activity) : setActivityView(Object.assign(Object.assign({}, act), { isPassed: true, disabledLike: true }));
        }
        navigation.navigate('Activity', { activityId: activity._id });
    });
    const formatString = (inputString, maxCharactersPerLine = 64) => {
        // Éliminer les retours à la ligne et les espaces en début et fin de chaîne
        const trimmedString = inputString.trim().replace(/\n/g, '');
        // Vérifier si la chaîne est plus longue que la limite par ligne
        if (trimmedString.length > maxCharactersPerLine) {
            // Tronquer la chaîne après 62 caractères et ajouter ".."
            return trimmedString.slice(0, maxCharactersPerLine - 2) + '..';
        }
        else {
            return trimmedString;
        }
    };
    return (<Pressable style={[
            styles.activityCard,
            liked && styles.activityCardLiked,
            userStatus.participant && styles.activityCardParticipant,
            userStatus.inWaitingList && styles.activityCardWaitingList,
        ]} onPress={displayActivityDetails}>
      <View style={styles.rightSide}>
        <View style={styles.iconContainer}>
          <View style={styles.circle}>
            <Text style={[styles.circleText, { fontSize: activity.price > 0 ? 10 : 4 }]}>{activity.price > 0 ? '$' : 'GRATUIT'}</Text>
          </View>
        </View>
        <View style={styles.centerItemUp}>
          <View style={styles.activityTitleContainer}>
            <Text style={styles.activityTitle}>★ {formatString(activity.title)} ★</Text>
          </View>
          <View style={styles.authorImageView}>
            {authorDetails ? <Image source={{ uri: authorDetails.profileImage }} style={styles.authorImage}/> : null}
          </View>
        </View>
        <View style={styles.centerItem}>
          <View style={styles.userStatusTagContainer}>
            <View style={[
            styles.userStatusTag,
            userStatus.participant
                ? styles.userStatusTagParticipant
                : userStatus.inWaitingList
                    ? styles.userStatusTagWaitingList
                    : styles.userStatusTagTopic,
        ]}>
              <Text style={styles.userStatusTagText}>
                {userStatus.participant
            ? translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonAttendeeCard
            : userStatus.inWaitingList
                ? translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonWaitingList
                : ((_b = activitiesIcon[activity.topic]) === null || _b === void 0 ? void 0 : _b.activityTitle) || translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonTitleActivity}
              </Text>
            </View>
          </View>
          <View style={styles.bottomItem}>
            <View style={[styles.bottomItemCenter, { minWidth: 70 }]}>
              <View>
                <Vectors.NavbarMembersBottom style={styles.NotificationsVectors}/>
              </View>
              <Text style={styles.textParticipants}>
                {activity.attendeeLimit ? activity.attendeeLimit === 1000000
            ? activity.attendees.length : activity.attendees.length + '/' + activity.attendeeLimit
            : activity.attendees.length}
                {/* {activity.attendeeLimit === 1000000 ? activity.attendees.length : `${activity.attendees.length}/${activity.attendeeLimit}`} */}
              </Text>
            </View>
            <View style={[styles.bottomItemCenter, { marginLeft: 20 }]}>
              <View style={{ marginRight: 5, marginBottom: 5 }}>
                {!!activity.infoLine ?
            <Vectors.GeneralColorsLinkChain style={styles.NotificationsVectors}/>
            : !!activity.metroStation ?
                <Vectors.Metro style={styles.NotificationsVectors}/>
                : <Vectors.PinGoogleMaps style={styles.NotificationsVectors}/>}
              </View>
              <Text>{(_c = activity.location) === null || _c === void 0 ? void 0 : _c.postalCode}</Text>
            </View>
          </View>
          <View style={styles.timeContainer}>
            <Text style={styles.activityTime}>
              {(_d = activity.startTime) === null || _d === void 0 ? void 0 : _d.hour}:{activity.startTime.minute > 9 ? (_e = activity.startTime) === null || _e === void 0 ? void 0 : _e.minute : `0${(_f = activity.startTime) === null || _f === void 0 ? void 0 : _f.minute}`}
            </Text>
            {activity.endTime && (<>
                <Text style={styles.activityTime}> - </Text>
                <Text style={styles.activityTime}>
                  {(_g = activity.endTime) === null || _g === void 0 ? void 0 : _g.hour}:{activity.endTime.minute > 9 ? (_h = activity.endTime) === null || _h === void 0 ? void 0 : _h.minute : `0${(_j = activity.endTime) === null || _j === void 0 ? void 0 : _j.minute}`}
                </Text>
              </>)}
          </View>
        </View>
      </View>
    </Pressable>);
};
export default MyActivityCard;
