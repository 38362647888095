// 🇫🇷 Cette page vous explique la différence entre tous les packs (FrameFigma052)
// 🇬🇧 This page explains the difference between all packs (FrameFigma052)
import React from "react";
import { Text, View, Pressable, ScrollView } from "react-native";
import { useNavigation } from "@react-navigation/native";
// Components
import styles from "./StylesWeb";
// import des Vectors
import { Vectors } from "../../../constants/Vectors";
import { useSelector } from "react-redux";
const VipBoardExplanation = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const Separator = () => {
        return <View style={styles.separator}/>;
    };
    const SeparatorPlat = () => {
        return <View style={styles.separatorPlat}/>;
    };
    const SeparatorNoir = () => {
        return <View style={styles.SeparatorNoir}/>;
    };
    const navigation = useNavigation();
    return (<ScrollView>
     <View style={styles.container}>
       <View style={styles.rowText}>
          <Text style={styles.text}>

            {translations.JsonAchievementsPages.JsonVipBadgesScreen.JsonBecomeADonator}
        </Text>
        </View>

        </View>
        {/**notre tableau  */}
        <View style={styles.table}>
          <View style={styles.rowTab}>
            <View style={styles.cell}>
              <Text style={styles.cellTextTitle}>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonPrivileges}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                <Vectors.StarsGold style={styles.imgsmall}/>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonGold}
              </Text>
            </View>

            <View style={styles.cell}>
              <Text style={styles.cellText}>
                <Vectors.StarsRuby style={styles.imgsmall}/>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonRuby}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                <Vectors.StarsEmerald style={styles.imgsmall}/>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonEmerald}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                <Vectors.StarsDiamond style={styles.imgsmall}/>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonDiamond}
              </Text>
            </View>
          </View>

          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonStarLevel}
              </Text>
            </View>
            {/** Ligne 1 */}
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextNumber}>1</Text>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
              <Text style={styles.cellTextNumber}>2</Text>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
              <Text style={styles.cellTextNumber}>3</Text>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
              <Text style={styles.cellTextNumber}>4</Text>
            </View>
          </View>

          <Separator />
            {/** Ligne 2 */}
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                 Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>

            <SeparatorNoir />
            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>
          </View>

          <Separator />
          {/** Ligne 3 */}
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
               <Vectors.SignRed style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>
          </View>

           {/** Ligne 4 */}
          <Separator />

          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
               <Vectors.SignRed style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
               <Vectors.SignRed style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
               <Vectors.CheckSign style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>
          </View>

          <Separator />
          {/** Ligne 5 */}
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                "Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
               <Vectors.CheckSign style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
               <Vectors.SignRed style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>
          </View>

          <Separator />
          {/** Ligne 6 */}
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
               <Vectors.SignRed style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
               <Vectors.SignRed style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
               <Vectors.SignRed style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>
          </View>

          <Separator />
          {/** Ligne 7 */}
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Vectors.SignRed style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
               <Vectors.SignRed style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
               <Vectors.SignRed style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>
          </View>

          <Separator />

           {/** Ligne 8 */}

          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                Lorem ipsum lorem ips Lorem ipsum.
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>

            <SeparatorNoir />

            <View style={styles.cell}>
            <Vectors.CheckSign style={styles.img}/>
            </View>

            <SeparatorNoir />

          <View style={styles.cell}>
          <Vectors.CheckSign style={styles.img}/>
            </View>
          </View>
          <Separator />

          {/* Les autres lignes du tableau vont ici */}
        </View>

    {/* gérer les boutons dans un tableau */}
    {/* gérer le bouton golden star*/}
        <View style={styles.table}>
          <View style={styles.row}>
            <View style={styles.cellButton}>
            <Pressable style={styles.buttonGrey} onPress={() => navigation.navigate('Rewards')}>
                <Vectors.StarsGold style={styles.buttonimg}/>
                <Text style={styles.buttonText}>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonGoldLevel}
                </Text>
            </Pressable>
            </View>

            {/* gérer le bouton ruby star*/}
            <View style={styles.cellButton}>
            <Pressable style={styles.boxRuby} onPress={() => navigation.navigate('Rewards')}>
                       <Vectors.StarsRuby style={styles.buttonimg}/>
                       <Text style={styles.buttonText}>
                            {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonRubyLevel}
                       </Text>
                  </Pressable>
            </View>
          </View>


         {/* gérer le bouton emerald star*/}
          <View style={styles.row}>
            <View style={styles.cellButton}>
            <Pressable style={styles.buttonGrey} onPress={() => navigation.navigate('Rewards')}>
                <Vectors.StarsEmerald style={styles.buttonimg}/>
                <Text style={styles.buttonText}>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonEmeraldLevel}
                </Text>
            </Pressable>
            </View>

             {/* gérer le bouton diamond star*/}
            <View style={styles.cellButton}>
            <Pressable style={styles.boxDiamond} onPress={() => navigation.navigate('Rewards')}>
                <Vectors.StarsDiamond style={styles.buttonimgdiamond}/>
                <Text style={styles.buttontextdiamond}>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonDiamondLevel}
                </Text>
            </Pressable>
            </View>


          </View>






        </View>

    </ScrollView>);
};
export default VipBoardExplanation;
