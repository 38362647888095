// FR  gère les erreurs dans  application(FrameFigma004,007C,013,)
// EN  manages errors in THIS application
import React, { createContext, useState, useContext } from 'react';
const ErrorContext = createContext(undefined);
export const useError = () => {
    const context = useContext(ErrorContext);
    if (!context) {
        throw new Error('useError must be used within a ErrorProvider');
    }
    return context;
};
export const ErrorProvider = ({ children }) => {
    const [error, setError] = useState('');
    const clearError = () => setError('');
    return (<ErrorContext.Provider value={{ error, setError, clearError }}>{children}</ErrorContext.Provider>);
};
// <ErrorContext.Provider value= { { error, setError, clearError }
// }> { children } </ErrorContext.Provider>
