var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useRef, useEffect } from 'react';
import { View, Pressable } from 'react-native';
import ReactCrop, { centerCrop, makeAspectCrop } from 'react-image-crop';
// import 'react-image-crop/dist/ReactCrop.css';
//Import Vectors
import { Vectors } from '../../../constants/Vectors';
//Import Styles
const styles = require('./StylesWeb.tsx').default;
// Helper function to center and aspect crop
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(makeAspectCrop({
        unit: '%',
        width: 100,
    }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
}
const ImageProfileCrop = ({ file, handleCroppedImage }) => {
    const imgRef = useRef(null);
    const [imgSrc, setImgSrc] = useState('');
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const aspect = 1; // Square aspect ratio
    const handleFile = () => {
        if (file instanceof File) {
            setCrop(undefined); // Makes crop preview update between images
            const reader = new FileReader();
            reader.addEventListener('load', () => {
                var _a;
                setImgSrc(((_a = reader.result) === null || _a === void 0 ? void 0 : _a.toString()) || '');
            });
            reader.readAsDataURL(file);
        }
    };
    useEffect(() => {
        handleFile();
    }, [file]);
    function onImageLoad(e) {
        const { width, height } = e.currentTarget;
        setCrop(centerAspectCrop(width, height, aspect));
    }
    const saveCropClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const image = imgRef.current;
        if (!image || !(completedCrop === null || completedCrop === void 0 ? void 0 : completedCrop.width) || !(completedCrop === null || completedCrop === void 0 ? void 0 : completedCrop.height)) {
            throw new Error('Crop canvas does not exist or crop is not completed');
        }
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);
        const ctx = offscreen.getContext('2d');
        if (!ctx) {
            throw new Error('No 2d context');
        }
        // Drawing the cropped area
        ctx.drawImage(image, completedCrop.x * scaleX, completedCrop.y * scaleY, completedCrop.width * scaleX, completedCrop.height * scaleY, 0, 0, completedCrop.width * scaleX, completedCrop.height * scaleY);
        // Convert the final canvas to a blob
        const blob = yield offscreen.convertToBlob({ type: 'image/jpeg' });
        const imageUrl = URL.createObjectURL(blob);
        console.info('Image URL:', imageUrl);
        const fileNameWithoutExtension = file === null || file === void 0 ? void 0 : file.name.split('.').slice(0, -1).join('.');
        const croppedFileName = `${fileNameWithoutExtension}_cropped.jpeg`;
        const croppedImageFile = new File([blob], croppedFileName, { type: 'image/jpeg', lastModified: Date.now(), });
        handleCroppedImage({ file: croppedImageFile, imageUrl });
    });
    return (<View style={styles.container}>
      {!!imgSrc && (<ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={c => setCompletedCrop(c)} aspect={aspect}>
          <img ref={imgRef} alt="Crop me" src={imgSrc} style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                maxWidth: '100%', // Constrain image width
                maxHeight: '80vh', // Constrain image height to 80% of viewport height
            }} onLoad={onImageLoad}/>
        </ReactCrop>)}
      <Pressable onPress={() => __awaiter(void 0, void 0, void 0, function* () {
            yield saveCropClick();
        })} style={styles.saveButton}>
        <Vectors.CheckSignGeneralColor width={40} height={40}/>
      </Pressable>
    </View>);
};
export default ImageProfileCrop;
