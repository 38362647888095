// Fr Cette page affiche l'étape 5 du formulaire pour modifier une activité (FrameFigma037A)
// En This page displays the FIFTH step of the form to edit an activity (FrameFigma037A)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { ScrollView, View, Text, Pressable, Platform, Image } from 'react-native';
import Vectors from '../../../../constants/Vectors';
import ActivityImagePicker from '../../../../components/PickerSection/ActivityImagePicker/Code';
import { useNavigation } from '@react-navigation/native';
import { useError } from '../../../../contexts/ErrorContext';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import Modal from '../../../../components/ModalPopUpModule/ModalSection/ActivityDetailsModal/Code';
import ImageCropperWeb from '../../../../components/ImageCropperSection/ImageActivityCrop/ImageCropperWeb';
import ImageCropperMobile from '../../../../components/ImageCropperSection/ImageActivityCrop/ImageCropperMobile';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
// Importer les styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
//import utils
import { ActivityCRUD } from '../../../../services/activities/ActivityCRUD';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
import { ActivityServices } from '../../../../services/activities/ActivityServices';
//Definir le type des props
const EditActivityDescriptionScreen = ({ onPrevStep, onNextStep, setHeaderTitle, resetSteps }) => {
    const navigation = useNavigation();
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { user } = useSelector((state) => state.user);
    const { error, setError } = useError();
    // const {currentActivity, setCurrentActivity, setCurrentActivityDate, currentActivitySwitch, setCurrentActivitySwitch,  getActivities} = useActivity();
    const { currentActivity, currentActivitySwitch } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setCurrentActivity = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivity({ id: nanoid(), value: data }));
    };
    const setCurrentActivityDate = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivityDate({ id: nanoid(), value: data }));
    };
    const setCurrentActivitySwitch = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivitySwitch({ id: nanoid(), value: data }));
    };
    const [modalVisible, setModalVisible] = useState(false);
    const [cropperModalVisible, setCropperModalVisible] = useState(false);
    const [activityImage, setActivityImage] = useState(currentActivity.activityImage); // Ajouté pour gérer l'image sélectionnée
    const [newActivityImage, setNewActivityImage] = useState(''); // Ajouté pour gérer l'image sélectionnée
    const [activityImageView, setActivityImageView] = useState(''); // Ajouté pour gérer l'image sélectionnée
    const [description, setDescription] = useState(currentActivity.description);
    const [howToFind, setHowToFind] = useState(currentActivity.howToFind);
    // const {selectedActivityTitle, switchBtnUnlimitted = false} = currentActivity; //Avec la destructuration je recupère ces deux valeurs
    const openIllustrationModal = () => {
        setModalVisible(true);
    };
    // Handle Cropped Image
    const handleCroppedImage = (croppedImage) => {
        setActivityImage(croppedImage);
        setCropperModalVisible(false);
        setError('');
    };
    //Fonction pour ajouter dans le context le lien de l'image
    const handleActivityImagePicker = (image) => {
        console.log("image has been selected ", image);
        if (image.imageUrl) {
            if (Platform.OS === 'web') {
                setActivityImage(image);
                setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { activityImage: image.imageUrl }));
            }
            else {
                setActivityImage(image);
                setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { activityImage: image.imageUrl }));
            }
            if (image.source == 'file') // image selectionnée depuis la bibliothèque de l'utilisateur
                setCropperModalVisible(true);
            setError('');
        }
    };
    const fetchActivitiesData = () => __awaiter(void 0, void 0, void 0, function* () {
        const activitiesData = yield ActivityServices.getActivities(user._id);
        if (activitiesData) {
            dispatch(ActivitiesSlice.actions.setActivities({ id: nanoid(), value: activitiesData.activities }));
            dispatch(ActivitiesSlice.actions.setUserOrganisedActivities({ id: nanoid(), value: activitiesData.userOrganisedActivities }));
            dispatch(ActivitiesSlice.actions.setUserCoOrganisedActivities({ id: nanoid(), value: activitiesData.userCoOrganisedActivities }));
            dispatch(ActivitiesSlice.actions.setNetworkUsers({ id: nanoid(), value: activitiesData.networkUsers }));
        }
        // dispatch(ActivitiesSlice.actions.setStateValue({ id: nanoid(), value: { activities: activitiesData.activities } }));
        else
            console.log("failed to fetch activities data");
    });
    const saveActivity = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const newActivity = yield ActivityCRUD.EditActivity(currentActivity, activityImage);
            if (!!newActivity) {
                fetchActivitiesData();
                Platform.OS === 'web' ? navigation.navigate('Activities') : navigation.navigate('Home'); //
                //Vider le context
                setCurrentActivity({});
                setCurrentActivityDate({});
                setCurrentActivitySwitch({});
            }
            else {
                console.error('Échec de la sauvegarde de l activité sur le serveur:');
                console.error("Détails de l'erreur du serveur:");
            }
        }
        catch (error) {
            console.error('Erreur lors de la sauvegarde de l activité:', error);
        }
    });
    const handlePrevState = () => {
        setHeaderTitle('Activity Topic');
        onPrevStep();
    };
    const handleNextStep = () => __awaiter(void 0, void 0, void 0, function* () {
        // Vérifiez si les champs obligatoires sont remplis
        console.log(!howToFind && !(currentActivity.infoLine !== ''));
        console.log(currentActivity.infoLine);
        if (!activityImage) {
            setError(translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonImageErrorMessage);
        }
        else if (!description) {
            setError(translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonDescriptionErrorMessage);
        }
        else if (!howToFind && currentActivity.infoLine === '') {
            setError(translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonHowToFindMeErrorMessage);
        }
        else {
            yield saveActivity(); // Enregistrez d'abord les données de l'activité
            onNextStep(); // Passez à l'étape suivante une fois que les données sont enregistrées
        }
    });
    const CropperComponent = Platform.select({
        web: () => <ImageCropperWeb file={activityImage === null || activityImage === void 0 ? void 0 : activityImage.file} handleCroppedImage={handleCroppedImage}/>,
        default: () => <ImageCropperMobile uri={activityImage === null || activityImage === void 0 ? void 0 : activityImage.file} handleCroppedImage={handleCroppedImage}/>,
    });
    //Fonction pour recuper la description
    const handleDescriptionText = (text) => {
        setDescription(text.slice(0, 7500));
        setError('');
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { description }));
    };
    //Fonction pour recuperer le text howToFind
    const handleHowToFindText = (text) => {
        setHowToFind(text.slice(0, 7500));
        setError('');
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { howToFind }));
    };
    return (<ScrollView showsVerticalScrollIndicator={false}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <View style={styles.container}>
        <View style={styles.title}>
          <Text style={styles.title}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonExplainYourActivityMessage}
          </Text>
        </View>
        {activityImage || newActivityImage ? (
        // Appeler l'Image Cropper si Image Uploaded
        <>
            <Modal modalVisible={cropperModalVisible} onClose={() => setCropperModalVisible(false)}>
              <CropperComponent />
            </Modal>
            <Pressable onPress={openIllustrationModal}>
              <Pressable onPress={() => setCropperModalVisible(true)} style={styles.cropBtn}>
                <Text style={styles.cropBtnText}>
                  {translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonEditButton}
                </Text>
                <Vectors.ProfilesEdit />
              </Pressable>
              {/* activityImage.imageUrl pour image choisis depuis le bibliotheque, activityImage pour unsplash/cropped image */}
              <Image source={{ uri: activityImage.imageUrl ? activityImage.imageUrl : activityImage }} style={styles.imageStyle}/>
            </Pressable>
          </>) : (<Pressable style={styles.choosePhoto} onPress={openIllustrationModal}>
            <View style={styles.btnChoose}>
              <View style={styles.field}>
                <Vectors.GeneralColorsUpload />
              </View>
              <Text style={styles.btnChooseText}>{translations.JsonActivityPages.JsonCreateActivityImageModal.JsonAddImageMessage}</Text>
            </View>
          </Pressable>)}
        <ActivityImagePicker modalVisible={modalVisible} setModalVisible={setModalVisible} 
    // ouvrir crop image automatiquement pour image local library
    onImageSelected={handleActivityImagePicker} selectedActivityTitle={currentActivity.activityTopicTitle}/>

        <TextInputCustomComponent placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonDescriptionPlaceholder} handleTextChange={handleDescriptionText} value={description} multiline={true} numberOfLines={12} isPlaceHolderTextAlignCenter={false}/>
        {currentActivity.infoLine === '' ?
            <TextInputCustomComponent placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonHowToFindMePlaceholder} handleTextChange={handleHowToFindText} value={howToFind} multiline={true} numberOfLines={12} isPlaceHolderTextAlignCenter={false}/> : null}
        <View style={styles.btnContainer}>
          <ButtonPrevious onPrevStep={handlePrevState} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
          <ButtonNext conditionNeed={activityImage !== null && description.trim().length > 0 && (currentActivity.infoLine !== '' ? true : howToFind.trim().length > 0)} handleNextStep={handleNextStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPublishButton}/>
        </View>
      </View>
    </ScrollView>);
};
export default EditActivityDescriptionScreen;
