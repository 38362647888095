var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { Vectors } from '../../../constants/Vectors';
import AsyncStorage from '@react-native-async-storage/async-storage';
import EditMainInformation from './EditMainInformation/Code';
import EditSecretInformation from './EditSecretInformation/Code';
import EditPublicInformation from './EditPublicInformation/Code';
import { useSelector } from 'react-redux';
const EditProfileNavigator = ({ route = undefined }) => {
    var _a;
    const navigation = useNavigation();
    const { translations } = useSelector((state) => state.language);
    const currentStep = ((_a = route === null || route === void 0 ? void 0 : route.params) === null || _a === void 0 ? void 0 : _a.currentStep) || 1;
    const [step, setStep] = useState(currentStep);
    /**
     * 🇬🇧 Handles the cancel action by clearing form data from AsyncStorage and navigating back.
     * 🇫🇷 Gère l'action d'annulation en effaçant les données du formulaire d'AsyncStorage et en revenant en arrière.
     */
    const handleCancel = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield AsyncStorage.removeItem('profileData1');
            yield AsyncStorage.removeItem('profileData2');
            yield AsyncStorage.removeItem('profileData3');
        }
        catch (error) {
            console.error('Failed to remove form data from AsyncStorage', error);
        }
        navigation.dispatch(CommonActions.goBack());
    });
    /**
     * 🇬🇧 Advances to the next step in the profile editing process.
     * 🇫🇷 Passe à l'étape suivante du processus de modification du profil.
     */
    const onNextStep = () => {
        if (step < 3) {
            setStep(step + 1);
        }
    };
    /**
     * 🇬🇧 Returns the header left component based on the current step.
     * 🇫🇷 Retourne le composant de gauche de l'en-tête en fonction de l'étape actuelle.
     */
    const headerLeft = () => {
        if (step === 1) {
            return (<TouchableOpacity onPress={handleCancel} style={{ marginLeft: 15 }}>
          <Vectors.Exit />
        </TouchableOpacity>);
        }
        else {
            return (<TouchableOpacity onPress={() => setStep(step - 1)} style={{ marginLeft: 15 }}>
          <Vectors.LeftArrow />
        </TouchableOpacity>);
        }
    };
    // 🇬🇧 Set the title based on the current step.
    // 🇫🇷 Définir le titre en fonction de l'étape actuelle.
    const title = step !== 2
        ? translations.JsonProfilePages.JsonEditProfileScreen.JsonTitleEditProfile
        : translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonSecret +
            ' ' +
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonInfo;
    // Options du header
    const headerOptions = {
        headerLeft: headerLeft, // Fix the type issue
        title,
        headerStyle: { backgroundColor: '#59C09B' },
        headerTintColor: 'white',
        headerTitleStyle: { fontWeight: 'bold', fontSize: 20 },
    };
    /**
     * 🇬🇧 Updates the navigation options when the component mounts or the header options change.
     * 🇫🇷 Met à jour les options de navigation lorsque le composant est monté ou lorsque les options de l'en-tête changent.
     */
    useEffect(() => {
        navigation.setOptions(headerOptions);
    }, [navigation, headerOptions]);
    // Composants pour chaque étape
    const stepComponents = {
        1: <EditMainInformation onNextStep={onNextStep}/>,
        2: <EditSecretInformation onNextStep={onNextStep}/>,
        3: <EditPublicInformation />,
    };
    return <View style={styles.container}>{stepComponents[step]}</View>;
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
export default EditProfileNavigator;
