var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState } from 'react';
import { FlatList, Image, Platform, Text, View } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
// import AchievementsCalcul from '../../../utils/ActivityDetails/Achievements/***';
// import components
import OnlineStatus from '../../GeneralSection/NotificationBulles/OnlineStatus';
import ProfileMedal from '../../ProfileSection/ProfileMedal/Code';
//import services
import { CoOrganizerServices } from '../../../services/activities/CoOrganizerServices';
import { authorServices } from '../../../services/activities/auhorServices';
import { calculateUserPoints } from '../../../utils/Functions/UserPointsCalculator';
// import contexts
import { useSelector } from 'react-redux';
// import styles
const styles = Platform.OS === 'web' ? require('./StylesWeb') : require('./StylesMobile');
const ActivityDetailsAuthorComponent = () => {
    // const { author, activityView } = useActivity();
    const { activityView, author } = useSelector((state) => state.activities);
    const { allUsers } = useSelector((state) => state.user);
    const [status, setStatus] = useState();
    const [coOrganizers, setCoOrganizers] = useState([]);
    const authPoints = calculateUserPoints(author._id);
    const fetchCoOrganizers = (activityView, allUsers, setCoOrganizers) => __awaiter(void 0, void 0, void 0, function* () {
        yield CoOrganizerServices.getCoOrganizers(activityView, allUsers, setCoOrganizers);
    });
    const authorStatus = (Aid, setStatus) => __awaiter(void 0, void 0, void 0, function* () {
        yield authorServices.getAuthorStatus(Aid, setStatus);
    });
    useFocusEffect(useCallback(() => {
        authorStatus(author._id, setStatus);
        fetchCoOrganizers(activityView, allUsers, setCoOrganizers);
    }, [author._id, activityView, allUsers]));
    return (<View style={styles.container}>
      <View style={styles.containerRow}>
        <Image source={{ uri: author.profileImage }} style={styles.imageProfile}/>
        {status === 'online' && <OnlineStatus w={15} h={15} br={7.5} bw={2} bottom={-32}/>}
        <View style={styles.descriptionAuthor}>
          <View style={styles.containerCol}>
            <Text style={styles.textName}>XX ans - {authPoints} points</Text>
            <Text style={styles.textName}>{author.evaluations > 0 ? author.evaluations : `Evaluations en cours ⭐`}</Text>
          </View>
          <ProfileMedal role={author.role} points={authPoints} isAmbassador={false} size={24}/>
        </View>
      </View>
      <View style={styles.separator}/>
      <View style={styles.coOganizersContainer}>
        <Text style={styles.textcoOganizers}>Co-organized by:</Text>
        {Array.isArray(coOrganizers) && coOrganizers.length > 0 ? (<FlatList data={coOrganizers} horizontal={true} renderItem={({ item }) => (<Text style={styles.textcoOganizers}>{item.firstName}</Text>)} keyExtractor={(item) => item._id.toString()}/>) : (<Text style={styles.textcoOganizers}>No co-organizers found</Text>)}
      </View>
      <View style={styles.separator}/>
    </View>);
};
export default ActivityDetailsAuthorComponent;
