//FR  permet d'inviter des amis via Snapchat
//EN allows users to invite friends via Snapchat
import React from 'react';
import { View, Text, Pressable, Linking } from 'react-native';
import { useSelector } from 'react-redux';
const InviteBySnapchat = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const inviteBySnapchat = () => {
        // Note: Direct app linking to Snapchat for sharing is limited.
        // This example just attempts to open the Snapchat app.
        const snapchatLink = 'snapchat://';
        Linking.openURL(snapchatLink);
    };
    return (<View>
      <Pressable onPress={inviteBySnapchat}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteSnapchat}</Text>
      </Pressable>
    </View>);
};
export default InviteBySnapchat;
