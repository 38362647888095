/*Fr ce fichier represente une diapositive de concept.
Il inclut une image, un titre ("Never Alone"), une description, des icônes de navigation, et des boutons "Participate"
et "Share". Les styles s'adaptent à la plateforme (web ou mobile), et la diapositive est défilable verticalement.(Frame 080AFR-080Ffr)*/
/*GB this file represents a concept slide. It includes an image,
a title ("Never Alone"), a description, navigation Vectors, and "Participate" and "Share" buttons. Styles adapt based
on the platform (web or mobile), and the slide is vertically scrollable.(Frame 080AFR-080Ffr)*/
import React, { useState } from 'react';
import { View, Text, Image, Platform, TouchableOpacity, } from 'react-native';
import Vectors from '../../constants/Vectors';
import { CreateSlidesData } from '../../data/Slides'; // Importez la fonction pour créer les slides
import { useNavigation } from "@react-navigation/native";
import { useSelector } from 'react-redux';
import { generateRandomIntInRange } from '../../utils/Functions/functions';
import BottomWhiteNav from '../../components/NavBarSection/BottomNav/BottomWhiteNav/Code';
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
const ConceptSlidesScreen = () => {
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const navigation = useNavigation();
    const Slides = CreateSlidesData(translations.JsonConceptScreen);
    const [currentSlide, setCurrentSlide] = useState(generateRandomIntInRange(0, Slides.length - 1));
    const prevSlide = () => {
        setCurrentSlide(prev => (prev === 0 ? Slides.length - 1 : prev - 1)); // Utilisez Slides comme une fonction pour obtenir les données
    };
    const nextSlide = () => {
        setCurrentSlide(prev => (prev === Slides.length - 1 ? 0 : prev + 1)); // Utilisez Slides comme une fonction pour obtenir les données
    };
    return (<View style={styles.container}>
      <Image style={styles.img} source={Slides[currentSlide].image}/>
      <View style={styles.content}>
        <TouchableOpacity onPress={prevSlide}>
          <Vectors.GeneralBlackAndWhitePreviousWhite />
        </TouchableOpacity>
        <View style={styles.titleContainer}>
          <Text style={styles.titleTxt}>{Slides[currentSlide].title}</Text>
        </View>
        <TouchableOpacity onPress={nextSlide}>
          <Vectors.GeneralBlackAndWhiteNextWhite />
        </TouchableOpacity>
      </View>
      <View style={styles.descriptionContent}>
        <Text style={styles.descriptionTxt}>
          {Slides[currentSlide].description}
        </Text>
      </View>
      <BottomWhiteNav items={[
            { label: translations.JsonConceptScreen.JsonParticipateButton, onPress: () => navigation.navigate('Activities') },
            { label: translations.JsonConceptScreen.JsonShareButton, onPress: () => navigation.navigate('InviteFriends') },
        ]}/>
    </View>);
};
export default ConceptSlidesScreen;
