var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useLayoutEffect, useState } from 'react';
import { View, Text, Platform, TextInput, Pressable, ScrollView } from 'react-native';
import SwitchBtn from '../../../../components/ButtonSection/SwitchButton/Code';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useSelector } from 'react-redux';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const DuplicateActivityAddressOfStepsScreen = ({ sliderValue = 1, // Default value to avoid undefined
onNextStep, onPrevStep, setHeaderTitle, }) => {
    const { translations } = useSelector((state) => state.language);
    const [addressValues, setAddressValues] = useState(Array(sliderValue).fill(''));
    const [switchBtnStates, setSwitchBtnStates] = useState(Array(sliderValue).fill(false));
    useLayoutEffect(() => {
        setHeaderTitle('Create your Activity');
    }, [setHeaderTitle]);
    const handleAddressChange = (index, address) => {
        const updatedValues = [...addressValues];
        updatedValues[index] = address;
        setAddressValues(updatedValues);
    };
    const handleSwitchBtnChange = (index, value) => {
        const updatedStates = [...switchBtnStates];
        updatedStates[index] = value;
        setSwitchBtnStates(updatedStates);
    };
    const saveAddressToAsyncStorage = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield AsyncStorage.setItem('addressValues', JSON.stringify(addressValues));
            console.log('Address values saved to AsyncStorage:', addressValues);
            onNextStep();
        }
        catch (error) {
            console.error('Error saving address values to AsyncStorage:', error);
        }
    });
    const DisplayStep = ({ index }) => {
        const [isFocused, setIsFocused] = useState(false);
        return (<View style={{ marginTop: 20, marginHorizontal: 20, paddingLeft: 25, paddingRight: 25 }}>
                <View style={styles.inputContainer}>
                    <Text style={styles.textTitle}>
                        {translations.JsonActivityPages.JsonActivityManagement.JsonActivitiesWithStepScreen.JsonAddress} {index + 1}
                    </Text>
                    <TextInput style={isFocused ? styles.focusedInput : undefined} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} onChangeText={(text) => handleAddressChange(index, text)}/>
                </View>
                <View style={styles.switchBtn}>
                    <Text style={styles.textbtn}>
                        {translations.JsonActivityPages.JsonActivityManagement.JsonActivitiesWithStepScreen.JsonFreeShot}
                    </Text>
                    <SwitchBtn state={switchBtnStates[index]} setState={(value) => handleSwitchBtnChange(index, value)}/>
                </View>
            </View>);
    };
    return (<ScrollView style={{ backgroundColor: 'white' }}>
            {Array.from({ length: sliderValue }, (_, index) => (<DisplayStep key={index} index={index}/>))}
            <View style={{ flexDirection: 'row' }}>
                <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
                <View style={styles.btnContainer}>
                    <Pressable style={styles.btnNext} onPress={saveAddressToAsyncStorage}>
                        <Text style={styles.btnText}>{translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}</Text>
                    </Pressable>
                </View>
            </View>
        </ScrollView>);
};
export default DuplicateActivityAddressOfStepsScreen;
