//it's composant for bannerDisplay 
import React from 'react';
import { Text, View, Platform } from 'react-native';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const MyActivitiesDisplayBanner = ({ Json }) => {
    return (<View style={styles.container}>
      <View style={styles.ribbon}>
        <Text style={styles.text}>{Json}</Text>
        <View style={[styles.borderTriangle, styles.leftBorderTriangle]}/>
        <View style={[styles.borderTriangle, styles.rightBorderTriangle]}/>
      </View>
    </View>);
};
export default MyActivitiesDisplayBanner;
