import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        minHeight: 150,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 50,
        // elevation: 5,
    },
    NotificationsVectors: {
        width: 30,
        height: 30,
    },
    modal: {
        width: 400,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        gap: 20,
        padding: 10,
        borderRadius: 10,
        // boxShadow is not supported in React Native, use elevation for Android and shadow properties for iOS
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.30,
        shadowRadius: 4.65,
        elevation: 8,
    },
    close: {
        alignSelf: 'flex-end',
    },
    closeText: {
        color: '#59C09B',
        fontSize: 20,
        fontWeight: 'bold',
    },
    btnContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        flexWrap: 'wrap',
    },
    buttonItem: {
        height: 110,
        // flexBasis: '30%'
    },
    confirmButton: {
        // Button CSS
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
        backgroundColor: '#6f6f6f',
        borderRadius: 10,
    },
    buttonText: {
        // Button Text CSS
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 'bold',
        marginRight: 5,
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: 20,
        fontWeight: 'bold',
    },
    innerContainer: {
        alignItems: 'center',
        gap: 10,
    }
});
export default styles;
