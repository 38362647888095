//FR  permet d'inviter des amis via Messenger
//EN allows users to invite friends via Messenger
import React from 'react';
import { View, Text, Pressable, Linking } from 'react-native';
import { useSelector } from 'react-redux';
const InviteByMessenger = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const inviteByMessenger = () => {
        const messengerLink = `fb-messenger://user/?message=${translations.JsonInviteFriendsScreen.JsonInvitationMessage}`;
        Linking.openURL(messengerLink);
    };
    return (<View>
      <Pressable onPress={inviteByMessenger}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteFacebook}</Text>
      </Pressable>
    </View>);
};
export default InviteByMessenger;
