//🇫🇷 Ce fichier gère le style web du composant NewUserPasswordScreen.js (FrameFigma005)
//🇬🇧 This file manages the web style of the NewUserPasswordScreen.js component (FrameFigma005)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    verification: {
        marginHorizontal: '5%',
        width: '80%',
    },
    inputContainer: {
        marginTop: "1%",
        width: '100%',
    },
    boldText: {
        marginVertical: '10%',
        fontWeight: 'bold',
        fontSize: 16,
        textAlign: 'center'
    },
    underText: {
        fontSize: 14,
        textAlign: 'center',
        marginVertical: '5%',
    },
    buttonContainer: {
        //alignItems: 'flex-start',
        flexDirection: 'row',
        marginVertical: '10%',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: 25,
        paddingRight: 25,
        alignItems: 'center',
        zIndex: -10,
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
