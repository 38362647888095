import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    profile: {
        flex: 1,
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'white',
    },
    mainView: {
        paddingTop: height * 0.1, // Adjusted to 10% of screen height
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'white',
    },
    picture: {
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        width: width * 0.3, // Adjusted to 30% of screen width
        height: width * 0.3, // Adjusted to 30% of screen width
        borderRadius: width * 0.15, // Adjusted to 15% of screen width
        overflow: 'hidden',
    },
    camera: {
        marginTop: height * 0.13, // Adjusted to 13% of screen height
        position: 'absolute',
        top: height * 0.07, // Adjusted to 7% of screen height
        right: width * 0.37, // Adjusted to 37% of screen width
    },
    image: {
        width: width * 0.3, // Adjusted to 30% of screen width
        height: width * 0.3, // Adjusted to 30% of screen width
    },
    btnContainer: {
        width: '40%',
        alignItems: 'center',
        marginTop: height * 0.05, // Adjusted to 5% of screen height
    },
    headerError: {
        backgroundColor: 'red',
        padding: height * 0.025, // Adjusted to 2.5% of screen height
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: width * 0.045, // Adjusted to 4.5% of screen width
        fontWeight: 'bold',
    },
});
