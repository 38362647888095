// Fr  Styles de CreateActivityVipStep1Screen(FrameFigma038A,FrameFigma038B)
// En  Styles of CreateActivityVipStep1Screen(FrameFigma038A,FrameFigma038B)
import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        marginTop: height * 0.02,
    },
    contentContainer: {
        marginVertical: height * 0.03,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: width * 0.9,
    },
    textbtn: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    twoSliders: {
        gap: 5,
        marginVertical: 10,
    },
    sliderText: {
        fontSize: 18,
        fontWeight: '500',
        marginVertical: 10,
    },
    btnContainer: {
        width: width,
        alignContent: 'center',
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    flagsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        marginVertical: 10,
        gap: 2,
    },
    flagWrapper: {
        width: '21%',
        height: 40,
        backgroundColor: '#FFFFFF', // Default background color
        justifyContent: 'center',
        alignItems: 'center',
        margin: 5,
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#59C09B',
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    flagWrapperSelected: {
        backgroundColor: '#59C09B', // Background color when selected
    },
});
