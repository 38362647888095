// Fr  Styles de DuplicateActivityDescriptionScreen(FrameFigma037A)
// En  Styles of DuplicateActivityDescriptionScreen(FrameFigma037A)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        paddingLeft: 25,
        paddingRight: 25,
    },
    addPhoto: {
        marginLeft: 10,
    },
    choosePhoto: {
        backgroundColor: '#DCF1EA',
        padding: 25,
        borderRadius: 15,
        marginVertical: 10,
        marginLeft: 5,
        marginRight: 10,
    },
    btnChoose: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    cropBtn: {
        backgroundColor: 'black',
        width: '20%',
        height: 27,
        top: 1,
        left: 1,
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        position: 'absolute',
        zIndex: 10,
        marginHorizontal: '6%',
        paddingLeft: '2%',
    },
    cropBtnText: {
        color: 'white',
    },
    imageStyle: {
        width: '100%', // Utilisez '100%' pour que l'image prenne toute la largeur du conteneur parent
        height: 200, // Hauteur fixe ou ajustable selon vos besoins
        resizeMode: 'contain', // Utilisez 'cover' pour que l'image couvre toute la zone disponible
        alignSelf: 'center',
        backgroundColor: 'white',
    },
    field: {
        margin: 5,
        backgroundColor: 'white',
        borderColor: '#9A9A9A',
        borderWidth: 9,
        borderRadius: 50,
        paddingHorizontal: 10,
        paddingTop: 9,
        paddingBottom: 11,
    },
    btnChooseText: {
        fontWeight: '700',
    },
    title: {
        fontWeight: 'bold',
        alignItems: 'center',
        fontSize: 16,
        marginVertical: 10,
    },
    inputContainer: {
        marginVertical: 10,
    },
    textContainer: {
        position: 'absolute',
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        left: 25,
        top: -11,
    },
    multiline: {
        borderWidth: 1,
        borderRadius: 15,
        verticalAlign: 'top',
        padding: 15,
    },
    btnsRow: {
        width: '100%',
        alignItems: 'center',
        marginTop: 30,
        marginBottom: 30,
    },
    btnContainer: {
        width: '100%',
        alignContent: 'center',
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 30
    },
    btnNext: {
        backgroundColor: '#59C09B',
        width: '55%',
        padding: 8,
        borderRadius: 15,
        marginHorizontal: 50,
        alignItems: 'center',
        elevation: 5,
        margin: 30,
    },
    btnText: {
        color: 'white',
        fontSize: 22,
        fontWeight: 'bold',
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
