import React, { useState, useRef, useEffect } from 'react';
import { View, Text, Modal, TouchableOpacity, Platform, ScrollView } from 'react-native';
import { Vectors } from '../../../constants/Vectors';
import { useSelector } from 'react-redux';
// Importation des styles en fonction de la plateforme
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const CustomDatePicker = ({ onDateChange, initialDate, handleSave }) => {
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const months = [
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonJanuary,
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonFebruary,
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonMarch,
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonApril,
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonMay,
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonJune,
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonJuly,
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonAugust,
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonSeptember,
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonOctober,
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonNovember,
        translations.JsonProfilePages.JsonEditProfileScreen.JsonMonth.JsonDecember,
    ];
    const getDefaultDate = () => {
        const currentYear = new Date().getFullYear();
        return new Date(Date.UTC(currentYear - 24, 0, 1));
    };
    const parseDate = (dateString) => {
        const date = new Date(dateString);
        return {
            day: date.getUTCDate().toString(),
            month: months[date.getUTCMonth()],
            year: date.getUTCFullYear().toString(),
        };
    };
    const initialDateObject = initialDate ? parseDate(initialDate) : parseDate(getDefaultDate().toISOString());
    const [selectedDate, setSelectedDate] = useState(initialDateObject);
    const [modalVisible, setModalVisible] = useState(false);
    const years = [...Array(100).keys()].map(year => String(new Date().getFullYear() - year));
    const dayScrollRef = useRef(null);
    const monthScrollRef = useRef(null);
    const yearScrollRef = useRef(null);
    const scrollToItem = (ref, index) => {
        if (ref.current) {
            ref.current.scrollTo({ y: index * 60 - 90, animated: true });
        }
    };
    const isDateValid = (day, month, year) => {
        const monthIndex = months.indexOf(month);
        const maxDay = new Date(parseInt(year), monthIndex + 1, 0).getDate();
        return day <= maxDay;
    };
    const handleSaveBirthDate = () => {
        setModalVisible(false);
        const { day, month, year } = selectedDate;
        const monthIndex = months.indexOf(month);
        const selectedDateObject = new Date(Date.UTC(parseInt(year), monthIndex, parseInt(day)));
        handleSave(selectedDateObject.toISOString().split('T')[0]);
    };
    const handlePress = (value, type) => {
        if (type === 'day') {
            const newDay = parseInt(value);
            const monthIndex = months.indexOf(selectedDate.month);
            const maxDay = new Date(parseInt(selectedDate.year), monthIndex + 1, 0).getDate();
            if (newDay <= maxDay) {
                setSelectedDate(prevState => (Object.assign(Object.assign({}, prevState), { day: String(newDay) })));
                scrollToItem(dayScrollRef, newDay - 1);
            }
        }
        else if (type === 'month') {
            const newMonthIndex = months.indexOf(value);
            const maxDay = new Date(parseInt(selectedDate.year), newMonthIndex + 1, 0).getDate();
            if (parseInt(selectedDate.day) <= maxDay) {
                setSelectedDate(prevState => (Object.assign(Object.assign({}, prevState), { month: value })));
                scrollToItem(monthScrollRef, newMonthIndex);
            }
            else {
                setSelectedDate(prevState => (Object.assign(Object.assign({}, prevState), { month: value, day: String(maxDay) })));
                scrollToItem(monthScrollRef, newMonthIndex);
            }
        }
        else if (type === 'year') {
            const newYear = parseInt(value);
            const monthIndex = months.indexOf(selectedDate.month);
            const maxDay = new Date(newYear, monthIndex + 1, 0).getDate();
            if (parseInt(selectedDate.day) <= maxDay) {
                setSelectedDate(prevState => (Object.assign(Object.assign({}, prevState), { year: value })));
                scrollToItem(yearScrollRef, years.indexOf(value));
            }
            else {
                setSelectedDate(prevState => (Object.assign(Object.assign({}, prevState), { year: value, day: String(maxDay) })));
                scrollToItem(yearScrollRef, years.indexOf(value));
            }
        }
    };
    useEffect(() => {
        if (initialDate) {
            setSelectedDate(parseDate(initialDate));
        }
    }, [initialDate]);
    useEffect(() => {
        if (modalVisible) {
            const dayIndex = parseInt(selectedDate.day, 10) - 1;
            const monthIndex = months.indexOf(selectedDate.month);
            const yearIndex = years.indexOf(selectedDate.year);
            if (dayScrollRef.current) {
                scrollToItem(dayScrollRef, dayIndex);
            }
            if (monthScrollRef.current) {
                scrollToItem(monthScrollRef, monthIndex);
            }
            if (yearScrollRef.current) {
                scrollToItem(yearScrollRef, yearIndex);
            }
        }
    }, [modalVisible, selectedDate]);
    return (<View style={styles.container}>
      <TouchableOpacity onPress={() => setModalVisible(true)} style={styles.birthdate}>
        <Vectors.ProfilesBirthday style={styles.logoBirthday}/>
        <Text style={styles.selectedDate}>
          {selectedDate.day} {selectedDate.month} {selectedDate.year}
        </Text>
      </TouchableOpacity>

      <Modal animationType="fade" transparent={true} visible={modalVisible} onRequestClose={() => setModalVisible(false)}>
        <TouchableOpacity style={styles.modalContainer} onPress={() => setModalVisible(false)} activeOpacity={1}>
          <TouchableOpacity style={styles.modalContent} activeOpacity={1} onPress={e => e.stopPropagation()}>
            <View style={styles.date}>
              <ScrollView ref={dayScrollRef} onMomentumScrollEnd={event => handlePress(String(Math.round(event.nativeEvent.contentOffset.y / 60) + 1), 'day')} snapToInterval={60} showsVerticalScrollIndicator={false} style={styles.scrollPicker}>
                {[...Array(31).keys()].map(day => (<TouchableOpacity key={day} style={[styles.item, !isDateValid(day + 1, selectedDate.month, selectedDate.year) && styles.disabledItem]} onPress={() => handlePress(String(day + 1), 'day')}>
                    <Text style={[styles.dateText, selectedDate.day === String(day + 1) && styles.selectedText, !isDateValid(day + 1, selectedDate.month, selectedDate.year) && styles.disabledText]}>
                      {String(day + 1)}
                    </Text>
                  </TouchableOpacity>))}
              </ScrollView>

              <ScrollView ref={monthScrollRef} onMomentumScrollEnd={event => handlePress(months[Math.round(event.nativeEvent.contentOffset.y / 60)], 'month')} snapToInterval={60} showsVerticalScrollIndicator={false} style={styles.scrollPicker}>
                {months.map((month, index) => (<TouchableOpacity key={index} style={styles.item} onPress={() => handlePress(month, 'month')}>
                    <Text style={[styles.dateText, selectedDate.month === month && styles.selectedText]}>{month}</Text>
                  </TouchableOpacity>))}
              </ScrollView>

              <ScrollView ref={yearScrollRef} onMomentumScrollEnd={event => handlePress(years[Math.round(event.nativeEvent.contentOffset.y / 60)], 'year')} snapToInterval={60} showsVerticalScrollIndicator={false} style={styles.scrollPicker}>
                {years.map(year => (<TouchableOpacity key={year} style={styles.item} onPress={() => handlePress(year, 'year')}>
                    <Text style={[styles.dateText, selectedDate.year === year && styles.selectedText]}>{year}</Text>
                  </TouchableOpacity>))}
              </ScrollView>
            </View>

            <TouchableOpacity onPress={handleSaveBirthDate} style={styles.saveButton}>
              <Text style={styles.saveButtonText}>{translations.JsonCreateProfilePages.JsonSaveButton}</Text>
            </TouchableOpacity>
          </TouchableOpacity>
        </TouchableOpacity>
      </Modal>
    </View>);
};
export default CustomDatePicker;
