import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    location: null,
    locationType: 'exact',
    showModal: true,
};
const LocationSlice = createSlice({
    name: 'location',
    initialState,
    reducers: {
        setLocation: (state, action) => {
            state.location = action.payload.value.location;
            state.locationType = action.payload.value.locationType;
        },
        setShowModal: (state, action) => {
            state.showModal = action.payload.value;
        },
    }
});
export default LocationSlice;
