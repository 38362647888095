import React from 'react';
import { View, Text, Platform } from 'react-native';
//import Vectors
import { Vectors } from '../../../constants/Vectors';
//import styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const MetroOrPostalDisplayComponent = ({ metroStation, postal }) => {
    return (<>
            {!!metroStation ?
            <View style={styles.containerMetroStation}>
                    <View>
                        <Vectors.Metro width={50} height={50}/>
                    </View>
                    <Text style={styles.textMetroStation}>{metroStation}</Text>
                </View>
            :
                <View style={styles.containerPostal}>
                    <View>
                        <Vectors.MapsPinGoogleMaps width={50} height={50}/>
                    </View>
                    <Text style={styles.textPostal}>{postal}</Text>
                </View>}
        </>);
};
export default MetroOrPostalDisplayComponent;
