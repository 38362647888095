// Fr  Styles VipBadgeExplanationSelector( FrameFigma05OB )
// En   Styles VipBadgeExplanationSelector( FrameFigma050B )
import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
        height: '100%',
        alignItems: 'center',
    },
    titleContainer: {
        alignItems: 'center',
        padding: 10,
        marginTop: '2%',
        marginBottom: '2%',
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 10,
    },
    button: {
        backgroundColor: 'white',
        borderRadius: 30,
        width: '90%',
        height: '15%',
        marginBottom: '10%',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',
        // iOS boxShadow
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowOffset: { width: 0, height: 8 },
        shadowOpacity: 0.5,
        shadowRadius: 10,
        // Android boxShadow
        elevation: 15,
    },
    buttonContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        right: '5%',
    },
    buttonText: {
        color: 'white',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    textContainer: {
        flex: 1,
        alignItems: 'center',
        marginLeft: '25%',
    },
    imgBord: {
        width: 145,
        height: 145,
        position: 'absolute',
        left: '-3%',
    },
    goldText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#FFD700',
        textAlign: 'center',
    },
    rubyText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#E84857',
        textAlign: 'center',
    },
    emeraldText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#42DB71',
        textAlign: 'center',
    },
    diamondText: {
        fontSize: 24,
        fontWeight: 'bold',
        color: '#7ACEFA',
        textAlign: 'center',
    },
    separatorG: {
        borderTopWidth: 2,
        borderTopColor: '#FFD700',
        width: '80%',
        marginTop: '3%',
        marginBottom: '3%',
    },
    separatorR: {
        borderTopWidth: 2,
        borderTopColor: '#E84857',
        width: '80%',
        marginTop: '3%',
        marginBottom: '3%',
    },
    separatorE: {
        borderTopWidth: 2,
        borderTopColor: '#42DB71',
        width: '80%',
        marginTop: '3%',
        marginBottom: '3%',
    },
    separatorD: {
        borderTopWidth: 2,
        borderTopColor: '#7ACEFA',
        width: '80%',
        marginTop: '3%',
        marginBottom: '3%',
    },
});
export default styles;
