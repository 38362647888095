// Fr Cette page affiche un formulaire pour copier une activité étape par étape (FrameFigma031)
// En This component displays a form to copy an activity step by step (FrameFigma031)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Import Modules
import { useState, useLayoutEffect } from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import DuplicateClassicOrStepByStepActivityScreen from './DuplicateClassicOrStepByStepActivityScreen/Code';
import DuplicateActivityLocationScreen from './DuplicateActivityLocationScreen/Code';
import DuplicateActivityManageParticipationScreen from './DuplicateActivityManageParticipationScreen/Code';
import DuplicateActivityTopicScreen from './DuplicateActivityTopicScreen/Code';
import DuplicateActivityDescriptionScreen from './DuplicateActivityDescriptionScreen/Code';
import DuplicateActivityCustomizingStepsScreen from './DuplicateActivityCustomizingStepsScreen/Code';
import DuplicateActivityAddressOfStepsScreen from './DuplicateActivityAddressOfStepsScreen/Code';
import DuplicateActivityHelpForOrganizersScreen from './DuplicateActivityHelpForOrganizersScreen/Code';
import DuplicateActivityPremiumFeaturesScreen from './DuplicateActivityPremiumFeaturesScreen/Code';
import DuplicateActivityAmbassadorFeaturesScreen from './DuplicateActivityAmbassadorFeaturesScreen/Code';
import Vectors from '../../../constants/Vectors';
import { useNavigation } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
const CopyActivityScreen = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const navigation = useNavigation();
    // const {user} = useUser();
    const { user } = useSelector((state) => state.user);
    // const {setCurrentActivity, currentActivity, setCurrentActivityDate, currentActivitySwitch, setCurrentActivitySwitch} = useActivity();
    const { currentActivity, currentActivitySwitch } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setCurrentActivity = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivity({ id: nanoid(), value: data }));
    };
    const setCurrentActivityDate = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivityDate({ id: nanoid(), value: data }));
    };
    const setCurrentActivitySwitch = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivitySwitch({ id: nanoid(), value: data }));
    };
    const [questionType, setQuestionType] = useState(null);
    const [step, setStep] = useState(1);
    const [sliderValue, setSliderValue] = useState(0);
    const [headerTitle, setHeaderTitle] = useState((currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.createAtivityType) === 'classic' ? 'Classic' : 'Parcours' /* translations.JsonActivityPages.JsonActivityManagement.JsonTitleCreateActivity */);
    const [roleApp, setRoleApp] = useState(user.roleApp);
    const [rolePremium, setRolePremium] = useState(user.rolePremium);
    // useEffect(() => {
    //   const fetchData = async () => {
    //     const value = await AsyncStorage.getItem('selectedQuestion');
    //     setQuestionType(value);
    //     setRoleApp(user.roleApp);
    //     setRolePremium(user.rolePremium);
    //   };
    //   fetchData();
    // }, [step]);
    const resetSteps = () => {
        console.log('resetSteps');
        setStep(1);
        setQuestionType(null);
        setCurrentActivity({});
        setCurrentActivityDate({});
        setCurrentActivitySwitch({});
        // Réinitialisez toutes les autres valeurs que vous devez réinitialiser
    };
    const defaultTitle = 'Edit Activity';
    const onNextStep = (sliderValue, selectedQuestion) => __awaiter(void 0, void 0, void 0, function* () {
        sliderValue && setSliderValue(sliderValue);
        if (step === 1 && selectedQuestion) {
            // await AsyncStorage.setItem('selectedQuestion', selectedQuestion === 'classic'|| selectedQuestion === 'hello' ? 'classic' : 'withStep');
            setQuestionType(selectedQuestion === 'Classic' || selectedQuestion === 'Parcours' || selectedQuestion === 'Classic' ? 'Classic' : 'withStep');
        }
        if (step < 9) {
            setStep(prevStep => prevStep + 1);
        }
    });
    const onPrevStep = () => {
        if (step === 7 && roleApp === 'basicUser') {
            setStep(4);
        }
        else {
            step === 2 ? setHeaderTitle(currentActivity.createAtivityType === 'classic' ? 'Classic' : currentActivity.createAtivityType) : defaultTitle;
            step === 3 ? setHeaderTitle(`Activity ${currentActivitySwitch.butLoc}`) : defaultTitle;
            step === 4 ? setHeaderTitle('Manage Participation') : defaultTitle;
            setStep(prevStep => prevStep - 1);
        }
    };
    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => (<TouchableOpacity onPress={() => { navigation.navigate('Activities'), resetSteps(); }} style={{ marginLeft: 15 }}>
          <Vectors.Exit />
        </TouchableOpacity>),
            headerTitle: headerTitle,
            headerStyle: { backgroundColor: '#59C09B' },
            headerTintColor: 'white',
            headerTitleStyle: { fontWeight: 'bold', fontSize: 20 },
        });
    }, [navigation, step, headerTitle]);
    // useEffect(() => {
    //   const unsubscribe = navigation.addListener('focus', () => {
    //     resetSteps(); // Réinitialisez les étapes lorsque l'écran est en focus
    //   });
    //   return unsubscribe;
    // }, [navigation]);
    const renderStep = () => {
        switch (step) {
            case 1:
                return <DuplicateClassicOrStepByStepActivityScreen onNextStep={onNextStep} setHeaderTitle={setHeaderTitle}/>;
            case 2:
                return questionType === 'Classic' ? (<DuplicateActivityLocationScreen onNextStep={onNextStep} onPrevStep={onPrevStep} setHeaderTitle={setHeaderTitle}/>) : (<DuplicateActivityCustomizingStepsScreen onNextStep={onNextStep} onPrevStep={onPrevStep} setHeaderTitle={setHeaderTitle}/>);
            case 3:
                if (questionType === 'Classic') {
                    return <DuplicateActivityManageParticipationScreen onNextStep={onNextStep} onPrevStep={onPrevStep} setHeaderTitle={setHeaderTitle}/>;
                }
                else {
                    return (<DuplicateActivityAddressOfStepsScreen onNextStep={onNextStep} sliderValue={sliderValue} onPrevStep={onPrevStep} setHeaderTitle={setHeaderTitle}/>);
                }
            case 4:
                return (<DuplicateActivityHelpForOrganizersScreen rolePremium={rolePremium} onNextStep={onNextStep} onPrevStep={onPrevStep} setHeaderTitle={setHeaderTitle}/>);
            case 5:
                if (rolePremium !== 'noStarUser') {
                    return (<DuplicateActivityPremiumFeaturesScreen rolePremium={rolePremium} onPrevStep={onPrevStep} onNextStep={onNextStep} setHeaderTitle={setHeaderTitle}/>);
                }
                else {
                    onNextStep();
                }
            case 6:
                if (roleApp === 'adminUser' || roleApp === 'ambassadorUser') {
                    return <DuplicateActivityAmbassadorFeaturesScreen onPrevStep={onPrevStep} onNextStep={onNextStep} setHeaderTitle={setHeaderTitle}/>;
                }
                else {
                    onNextStep();
                }
            case 7:
                if (questionType === 'Classic') {
                    return <DuplicateActivityTopicScreen onNextStep={onNextStep} onPrevStep={onPrevStep} setHeaderTitle={setHeaderTitle}/>;
                }
                else {
                    return <DuplicateActivityManageParticipationScreen onNextStep={onNextStep} onPrevStep={onPrevStep} setHeaderTitle={setHeaderTitle}/>;
                }
            case 8:
                if (questionType === 'Classic') {
                    return <DuplicateActivityDescriptionScreen onPrevStep={onPrevStep} onNextStep={onNextStep} setHeaderTitle={setHeaderTitle} resetSteps={resetSteps}/>;
                }
                else {
                    return <DuplicateActivityTopicScreen onNextStep={onNextStep} onPrevStep={onPrevStep} setHeaderTitle={setHeaderTitle}/>;
                }
            case 9:
                if (questionType !== 'classic') {
                    return <DuplicateActivityDescriptionScreen onPrevStep={onPrevStep} onNextStep={onNextStep} setHeaderTitle={setHeaderTitle} resetSteps={resetSteps}/>;
                }
            default:
                return null;
        }
    };
    return <View style={styles.PContainer}>{renderStep()}</View>;
};
const styles = StyleSheet.create({
    PContainer: {
        flex: 1,
        backgroundColor: 'white',
    },
});
export default CopyActivityScreen;
