import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    profile: {
        flex: 1,
        //paddingTop: '10%',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'white',
    },
    mainView: {
        paddingTop: '10%',
        alignItems: 'center',
        width: '100%',
        backgroundColor: 'white',
    },
    picture: {
        position: 'relative',
        justifyContent: 'center',
        alignItems: 'center',
        width: 120,
        height: 120,
        borderRadius: 60, // Add this
        overflow: 'hidden', // Add this
    },
    camera: {
        marginTop: '13%',
        position: 'absolute',
        top: 60,
        right: '37%',
    },
    image: {
        width: 120,
        height: 120,
        //borderRadius: 60,
    },
    btnContainer: {
        width: '40%',
        alignItems: 'center',
        marginTop: '5%',
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
