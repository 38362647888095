export const compareActivities = (a, b) => {
    const getMinutes = (time) => {
        if (time && time.hour != null && time.minute != null) {
            return parseInt(time.hour) * 60 + parseInt(time.minute);
        }
        return 24 * 60;
    };
    const startTimeA = getMinutes(a.startTime);
    const startTimeB = getMinutes(b.startTime);
    if (startTimeA !== startTimeB) {
        return startTimeA - startTimeB;
    }
    else {
        const endTimeA = getMinutes(a.endTime);
        const endTimeB = getMinutes(b.endTime);
        if (a.endTime == null && b.endTime == null) {
            return 0;
        }
        else if (a.endTime == null) {
            return -1;
        }
        else if (b.endTime == null) {
            return 1;
        }
        else {
            return endTimeA - endTimeB;
        }
    }
};
// the function removes newlines its used in ActivitiesCard and MyActiviitesCard
export const formatString = (inputString, maxCharactersPerLine = 64) => {
    // Éliminer les retours à la ligne et les espaces en début et fin de chaîne
    const trimmedString = inputString.trim().replace(/\n/g, '');
    // Vérifier si la chaîne est plus longue que la limite par ligne
    if (trimmedString.length > maxCharactersPerLine) {
        // Tronquer la chaîne après 62 caractères et ajouter ".."
        return trimmedString.slice(0, maxCharactersPerLine - 2) + '..';
    }
    else {
        return trimmedString;
    }
};
// the function removes newlines its used in ActivitiesCard and MyActiviitesCard
export const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date)) {
        return dateStr;
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}/${month}/${year}`;
};
// empile actitivies witch have date
export const ActivitiesWithDate = (activity, grouped) => {
    const date = new Date(activity.date).toISOString().split('T')[0];
    if (!grouped.has(date)) {
        grouped.set(date, []);
    }
    grouped.get(date).push(activity);
};
export const formatDateWithDay = (dateStr, Langague) => {
    const date = new Date(dateStr);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString(Langague.toLowerCase(), options);
};
export function generateRandomIntInRange(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
