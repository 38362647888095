import React, { useState, useRef, useEffect } from 'react';
import { View, Text, Modal, TouchableOpacity, Platform, ScrollView } from 'react-native';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const TimePicker = ({ isVisible, onClose, onTimeSelected }) => {
    const { translations } = useSelector((state) => state.language);
    const [selectedTime, setSelectedTime] = useState({
        hour: 12,
        minute: 30,
    });
    const hourScrollRef = useRef(null);
    const minuteScrollRef = useRef(null);
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const minutes = Array.from({ length: 60 }, (_, i) => i);
    // Scroll à la valeur initiale à l'ouverture de la modal
    useEffect(() => {
        var _a, _b;
        if (isVisible) {
            const hourOffset = selectedTime.hour * 60 - (60 / 2); // Calculer l'offset pour centrer l'heure
            const minuteOffset = selectedTime.minute * 60 - (60 / 2); // Calculer l'offset pour centrer les minutes
            (_a = hourScrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ y: hourOffset, animated: true });
            (_b = minuteScrollRef.current) === null || _b === void 0 ? void 0 : _b.scrollTo({ y: minuteOffset, animated: true });
        }
    }, [isVisible, selectedTime.hour, selectedTime.minute]); // Dépendances pour réagir aux changements
    // Gérer la sélection des valeurs de heure et minute
    const handleTimeChange = (field, value) => {
        var _a, _b;
        setSelectedTime((prevTime) => (Object.assign(Object.assign({}, prevTime), { [field]: value })));
        // Centrer l'élément sélectionné
        if (field === 'hour') {
            (_a = hourScrollRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo({ y: value * 60 - (60 / 2), animated: true }); // 40 est la hauteur de chaque élément
        }
        else {
            (_b = minuteScrollRef.current) === null || _b === void 0 ? void 0 : _b.scrollTo({ y: value * 60 - (60 / 2), animated: true }); // 40 est la hauteur de chaque élément
        }
    };
    // Gérer la confirmation de l'heure
    const handleConfirmTime = () => {
        onTimeSelected(selectedTime); // Renvoie l'heure et les minutes sélectionnées au parent
        onClose();
    };
    // Gérer la sélection des valeurs avec le défilement
    const handleScrollEnd = (field, event) => {
        const selectedValueIndex = Math.round(event.nativeEvent.contentOffset.y / 60); // 40 est la hauteur de chaque élément
        handleTimeChange(field, selectedValueIndex);
    };
    return (<View style={styles.container}>
            {/* Modal pour la sélection de l'heure */}
            <Modal animationType="slide" transparent={true} visible={isVisible} onRequestClose={onClose}>
                <View style={styles.modalContainer}>
                    <View style={styles.modalContent}>
                        <Text style={styles.titleText}>{translations.JsonPickerDate.JsonTitleTime}</Text>
                        <View style={styles.timeContainer}>
                            {/* Picker pour les heures */} 
                            <ScrollView ref={hourScrollRef} onMomentumScrollEnd={event => handleScrollEnd('hour', event)} snapToInterval={40} showsVerticalScrollIndicator={false} style={styles.scrollContainer}>
                                {hours.map((hour, index) => (<TouchableOpacity key={index} style={styles.scrollContent} onPress={() => handleTimeChange('hour', hour)}>
                                        <Text style={[styles.timeText, selectedTime.hour === hour && styles.selectedText]}>
                                            {String(hour).padStart(2, '0')}
                                        </Text>
                                    </TouchableOpacity>))}
                            </ScrollView>
                            <View style={{ flex: 1, alignContent: 'center', justifyContent: 'center' }}>
                                <Text style={{ fontSize: 48, alignSelf: 'center' }}>:</Text>
                            </View>
                            {/* Picker pour les minutes */}
                            <ScrollView ref={minuteScrollRef} onMomentumScrollEnd={event => handleScrollEnd('minute', event)} snapToInterval={40} showsVerticalScrollIndicator={false} style={styles.scrollContainer}>
                                {minutes.map((minute, index) => (<TouchableOpacity key={index} style={styles.scrollContent} onPress={() => handleTimeChange('minute', minute)}>
                                        <Text style={[styles.timeText, selectedTime.minute === minute && styles.selectedText]}>
                                            {String(minute).padStart(2, '0')}
                                        </Text>
                                    </TouchableOpacity>))}
                            </ScrollView>
                        </View>
                        <View style={styles.bottomTimeContainer}>
                                <Text style={styles.bottomTimeText}>{translations.JsonPickerDate.JsonHour}</Text>
                                <Text style={styles.bottomTimeText}>{translations.JsonPickerDate.JsonMinute}</Text>
                        </View>
                        <View style={styles.separator}/>
                        {/* Boutons dans la modal */}    
                        <View style={styles.buttonBottomContainer}>
                            <TouchableOpacity style={styles.buttonBottomContent} onPress={onClose}>
                                <Text style={{ color: 'black' }}>{translations.JsonPickerDate.JsonCancel}</Text>
                                </TouchableOpacity>
                            <TouchableOpacity style={styles.buttonBottomContent} onPress={handleConfirmTime}>
                                <Text style={{ color: 'black' }}>{translations.JsonPickerDate.JsonConfirm}</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                </View>
            </Modal>
        </View>);
};
export default TimePicker;
