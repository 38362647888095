import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    starButton: {
        padding: 5,
    }
});
export default styles;
