// Import Modules
import React from 'react';
import { Platform, Pressable, View } from 'react-native';
// Import Components
import EmailInputScreen from '../EmailInputScreen/Code';
import MemberNumberInputScreen from '../MemberNumberInputScreen/Code';
import PhoneInputScreen from '../PhoneInputScreen/Code';
import BirthdayInputScreen from '../BirthdayInputScreen/Code';
import LastNameInputScreen from '../LastNameInputScreen/Code';
// Import Vectors
import { Vectors } from '../../../../constants/Vectors';
// Import + Export Styles (Importer ici pour usage et exporter les mêmes styles pour utiliser dans les écrans enfants)
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
// Logique de component commence ici --------------------------------------------------
export const Step1InputScreen = ({ selectedButton, onBack, onClose, currentUser }) => {
    return (<>
            <View style={styles.iconRow}>
                <Pressable onPress={onBack}>
                    <Vectors.LeftArrow width={20} height={20}/>
                </Pressable>
                {selectedButton.icon}
            </View>
            {selectedButton.id === 1 && <EmailInputScreen currentUser={currentUser} onClose={onClose}/>}
            {selectedButton.id === 2 && <MemberNumberInputScreen currentUser={currentUser} onClose={onClose}/>}
            {selectedButton.id === 3 && <PhoneInputScreen currentUser={currentUser} onClose={onClose}/>}
            {selectedButton.id === 4 && <BirthdayInputScreen currentUser={currentUser} onClose={onClose}/>}
            {selectedButton.id === 5 && <LastNameInputScreen currentUser={currentUser} onClose={onClose}/>}
        </>);
};
