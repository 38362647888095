var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Axios from "../Axios/AxiosService";
const getCoOrganizers = (activity, allUsers, setCoOrganizers) => __awaiter(void 0, void 0, void 0, function* () {
    const arrayCoOrganizers = activity.coOrganizers;
    if (arrayCoOrganizers.length === 0) {
        setCoOrganizers([]); // Set an empty array if there are no co-organizers
    }
    else {
        const coOrganizers = allUsers
            .filter(user => arrayCoOrganizers.includes(user._id))
            .map(user => ({
            _id: parseInt(user._id, 10), // Convert string ID to number
            firstName: user.firstName || '', // Handle potential undefined firstName
        }));
        setCoOrganizers(coOrganizers);
    }
});
const sendCoOrganiserRequest = (activityId, userId, setSuccess) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield Axios.post(`/activities/${activityId}/co-organizers`, JSON.stringify({ userId }));
        if (response.status === 200) {
            console.log('Co-organiser request sent successfully');
            setSuccess(true);
        }
    }
    catch (error) {
        console.error('Error sending co-organiser request:', error);
        setSuccess(false);
    }
});
export const CoOrganizerServices = {
    getCoOrganizers,
    sendCoOrganiserRequest
};
