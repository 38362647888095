// Store qui centralise toute les données de l'application dans des slices redux toolkit
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import AsyncStorage from '@react-native-async-storage/async-storage';
import LanguageSlice from './Features/Language/LanguagesSlice';
import AuthSlice from './Features/Auth/AuthSlice';
import UserSlice from './Features/User/UserSlice';
import ActivitiesSlice from './Features/Activities/AtivitiesSlice';
import LocationSlice from './Features/Location/LocationSlice';
const appReducer = combineReducers({
    language: LanguageSlice.reducer,
    auth: AuthSlice.reducer,
    user: UserSlice.reducer,
    activities: ActivitiesSlice.reducer,
    location: LocationSlice.reducer
});
const rootReducer = (state, action) => {
    if (action.type === 'RESET_APP') {
        state = undefined;
    }
    return appReducer(state, action);
};
const persistConfig = {
    key: 'socializus2024',
    storage: AsyncStorage
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        // les middleware de vérification des données qui rentre dans le store
        serializableCheck: {
            warnAfter: 128, // augmenter le temps avant l'alerte pour les store trop grand
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        },
        immutableCheck: { warnAfter: 128 }, // augmenter le temps avant l'alerte pour les store trop grand
    })
});
let persistor = persistStore(store);
export { persistor };
export default store;
