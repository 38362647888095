// Fr Cette page affiche l'étape 2 du formulaire pour modifier une activité de type itinéraire(FrameFigma034)
// En This page displays the second step of the form to edit an activity of type itinery (FrameFigma034)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useLayoutEffect, useState, } from 'react';
import { View, Text, Platform, TextInput, Pressable, ScrollView } from 'react-native';
import SwitchBtn from '../../../../components/ButtonSection/SwitchButton/Code';
import AsyncStorage from '@react-native-async-storage/async-storage';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const Code = ({ sliderValue, onNextStep, onPrevStep, setHeaderTitle }) => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const [isFocused, setIsFocused] = useState(false);
    const [addressValues, setAddressValues] = useState(Array(sliderValue).fill(''));
    const [switchBtnStates, setSwitchBtnStates] = useState(Array(sliderValue).fill(false));
    useLayoutEffect(() => {
        setHeaderTitle("Create your Activity");
    }, []);
    const handleAddressChange = (index, address) => {
        setAddressValues(prevState => {
            const updatedValues = [...prevState];
            updatedValues[index] = address;
            return updatedValues;
        });
    };
    const handleSwitchBtnChange = (index, value) => {
        setSwitchBtnStates(prevState => {
            const updatedStates = [...prevState];
            updatedStates[index] = value;
            return updatedStates;
        });
    };
    const saveAddressToAsyncStorage = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield AsyncStorage.setItem('addressValues', JSON.stringify(addressValues));
            console.log('Address values saved to AsyncStorage:', addressValues);
            onNextStep();
        }
        catch (error) {
            console.error('Error saving address values to AsyncStorage:', error);
        }
    });
    const DisplayStep = ({ index }) => {
        return (<View style={{ marginTop: 20, marginHorizontal: 20, paddingLeft: 25, paddingRight: 25 }}>
        <View style={styles.inputContainer}>
          <Text style={styles.textTitle}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonActivitiesWithStepScreen.JsonAddress} {index + 1}
          </Text>
          <TextInput style={isFocused && styles.focusedInput} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} onChangeText={text => handleAddressChange(index, text)}/>
        </View>
        <View style={styles.switchBtn}>
          <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonActivitiesWithStepScreen.JsonFreeShot}</Text>
          <SwitchBtn state={switchBtnStates[index]} setState={value => handleSwitchBtnChange(index, value)}/>
        </View>
      </View>);
    };
    const arrayFromSliderVal = Array(sliderValue);
    return (<ScrollView style={{ backgroundColor: 'white' }}>
       {Array(sliderValue).map((index) => {
            return (<DisplayStep index={index}/>);
        })}
      <View style={{ flexDirection: 'row' }}>
        <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
        <View style={styles.btnContainer}>
          <Pressable style={styles.btnNext} onPress={saveAddressToAsyncStorage}>
            <Text style={styles.btnText}>{translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}</Text>
          </Pressable>
        </View>
      </View>
    </ScrollView>);
};
export default Code;
