import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
    fab: {
        position: 'absolute',
        bottom: 25,
        left: 20,
        backgroundColor: 'white',
        width: 56,
        height: 56,
        borderRadius: 28,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 3,
        elevation: 2
    },
    DateAndIconFilter: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 15,
        marginBottom: 5,
    },
    dateContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: '5%',
        marginTop: 20,
        position: 'absolute',
        backgroundColor: 'white',
    },
    DisplayDate: {
        fontSize: 18,
        fontWeight: 'bold',
        borderRadius: 10,
        textAlign: 'center',
        marginLeft: '2%',
    },
    button: {
        backgroundColor: '#59C09B',
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 15,
        marginRight: '2%',
        borderRadius: 5,
        minWidth: 100,
        height: 40,
        marginLeft: 'auto',
    },
    text: {
        color: 'white',
        marginLeft: 10,
        fontSize: 16,
        fontWeight: 'bold',
    },
    centeredText: {
        textAlign: 'center',
        fontSize: 20,
        marginVertical: 10,
        height: 30
    },
    fixedDateContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
    }
});
export default styles;
