import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: 30,
        fontWeight: 'bold',
        textAlign: 'center',
        marginBottom: 20,
    },
    timerText: {
        fontSize: 50,
        fontWeight: 'bold',
        marginTop: 20,
        marginBottom: 15,
    },
});
export default styles;
