import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: '85%',
        backgroundColor: 'white',
        borderRadius: 10,
        alignItems: 'center',
        padding: '5%',
    },
    headerText: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    headerTextDisable: {
        fontSize: 20,
        color: 'lightgrey',
        fontWeight: 'bold',
    },
    yearContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '25%',
        borderRadius: 8,
        borderWidth: 1,
        borderColor: '#F1F1F1',
        marginBottom: '5%',
    },
    modalYearContent: {
        width: '85%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        borderRadius: 10,
        padding: '2%',
    },
    yearButton: {
        width: '25%',
        marginHorizontal: '4%',
        paddingVertical: '4%',
        alignItems: 'center',
        borderRadius: 15,
    },
    monthContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: '10%',
        justifyContent: 'space-between',
        width: '90%'
    },
    weekContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginBottom: '2%',
    },
    dayContainer: {
        width: '14%',
        padding: '3%',
        alignItems: 'center',
        borderRadius: 8,
    },
    dayContainerToday: {
        width: '14%',
        padding: '3%',
        alignItems: 'center',
        borderRadius: 8,
        borderColor: '#59C09B',
        borderWidth: 1,
    },
    separator: {
        marginTop: '2%',
        height: 1.2,
        backgroundColor: '#F1F1F1',
        width: '100%',
        alignSelf: 'center',
        marginBottom: '5%',
    },
    buttonBottomContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '10%',
        marginBottom: '-15%',
    },
    buttonBottomContent: {
        backgroundColor: '#F1F1F1',
        width: '47%',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
    },
});
export default styles;
