import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        alignItems: 'center',
        //width: '90%',
    },
    containerForParity: {
        flexDirection: 'column',
        alignItems: 'center',
        marginTop: 30,
        width: '90%',
    },
    label: {
        fontSize: 18,
        marginRight: 10,
    },
    slider: {
        width: '100%',
        height: 40,
    },
    valueContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
    },
    sliderValueContainer: {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: [{ translateX: -10 }],
        marginTop: 20,
    },
    VectorsContainerTop: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: -20,
        position: 'absolute',
    },
    sliderValueContainerTop: {
        position: 'absolute',
        top: 0,
        left: '50%',
        transform: [{ translateX: -10 }],
        marginTop: -20,
    },
    sliderValueContainerAge: {
        position: 'absolute',
        top: 0,
        left: '47%',
        transform: [{ translateX: -10 }],
        marginTop: 10,
    },
    sliderValue: {
        fontSize: 16,
        fontWeight: 'bold',
    },
});
export default styles;
