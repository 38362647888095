// Import Modules
import React from 'react';
// Import Vectors
import { Vectors } from '../../../../constants/Vectors';
const NoStarMedals = ({ points, width, height }) => {
    return (<>
      {points >= 0 && points <= 499 && <Vectors.Level1NoStar width={width} height={height}/>}
      {points >= 500 && points <= 999 && <Vectors.Level2NoStar width={width} height={height}/>}
      {points >= 1000 && points <= 1999 && <Vectors.Level3NoStar width={width} height={height}/>}
      {points >= 2000 && points <= 4999 && <Vectors.Level4NoStar width={width} height={height}/>}
      {points >= 5000 && points <= 9999 && <Vectors.Level5NoStar width={width} height={height}/>}
      {points >= 10000 && points <= 49999 && <Vectors.Level6NoStar width={width} height={height}/>}
      {points >= 50000 && <Vectors.Level7NoStar width={width} height={height}/>}
    </>);
};
export default NoStarMedals;
