// Import Modules
import React from 'react';
// Import Components
import NoStarMedals from './NoStarMedals/Code';
import RubyStarMedals from './RubyStartMedals/Code';
import GoldStarMedals from './GoldStartMedals/Code';
import EmeraldStarMedals from './EmeraldStartMedals/Code';
import DiamondStarMedals from './DiamondStarMedals/Code';
import { Vectors } from '../../../constants/Vectors';
import { View, StyleSheet } from 'react-native';
const getRole = (noOfSponsorships) => {
    return noOfSponsorships >= 100 ? 'diamondUser' :
        noOfSponsorships >= 50 ? 'emeraldUser' :
            noOfSponsorships >= 20 ? 'rubyUser' :
                noOfSponsorships >= 10 ? 'goldUser' : 'basicUser';
};
const ProfileMedal = ({ role, noOfSponsorships, points, isAmbassador = false, size }) => {
    let calculatedRole = role || '';
    if (!calculatedRole && noOfSponsorships !== undefined) {
        calculatedRole = getRole(noOfSponsorships);
    }
    const sizeBase = typeof size === 'number' ? (size / 2) + 5 : 0;
    const marginTop = typeof size === 'number' ? -size * 0.4 : 0; // 🇫🇷 Style dynamique pour ajuster l'espacement entre les ailes et les bases - 🏴󠁧󠁢󠁥󠁮󠁧󠁿 Dynamic styling to adjust wing spacing
    // 🇫🇷 Choisit le composant des médailles selon le rôle - 🏴󠁧󠁢󠁥󠁮󠁧󠁿 Choose the medal component according to role
    const MedalComponent = () => {
        switch (calculatedRole) {
            case 'diamondUser':
                return <DiamondStarMedals points={points} width={size} height={size}/>;
            case 'emeraldUser':
                return <EmeraldStarMedals points={points} width={size} height={size}/>;
            case 'rubyUser':
                return <RubyStarMedals points={points} width={size} height={size}/>;
            case 'goldUser':
                return <GoldStarMedals points={points} width={size} height={size}/>;
            default:
                return <NoStarMedals points={points} width={size} height={size}/>;
        }
    };
    const renderBase = (calculatedRole, isAmbassador, size) => {
        if (isAmbassador) {
            return <Vectors.AmbassadorBase width={size} height={size} resizeMode="contain"/>;
        }
        // 🇫🇷 Retourne une base différente selon le rôle - 🏴󠁧󠁢󠁥󠁮󠁧󠁿 Returns a different base depending on the role
        switch (calculatedRole) {
            case 'diamondUser':
                return <Vectors.DiamondBase width={size} height={size} resizeMode="contain"/>;
            case 'emeraldUser':
                return <Vectors.EmeraldBase width={size} height={size} resizeMode="contain"/>;
            case 'rubyUser':
                return <Vectors.RubyBase width={size} height={size} resizeMode="contain"/>;
            case 'goldUser':
                return <Vectors.GoldBase width={size} height={size} resizeMode="contain"/>;
            default:
                return <Vectors.NoStarBase width={size} height={size} resizeMode="contain"/>;
        }
    };
    return (<View style={styles.container}>
            <View style={{ marginBottom: marginTop }}>
                <MedalComponent />
            </View>
            <View>
                {renderBase(calculatedRole, isAmbassador, sizeBase)}
            </View>
        </View>);
};
const styles = StyleSheet.create({
    container: {
        position: 'relative', // 🇫🇷 Conteneur pour gérer les superpositions - 🏴󠁧󠁢󠁥󠁮󠁧󠁿 Container for managing overlays
        alignItems: 'center',
        justifyContent: 'center',
    },
});
export default ProfileMedal;
