import { StyleSheet } from "react-native";
export default StyleSheet.create({
    aboutContainer: {
        width: "80%",
        height: "auto",
    },
    aboutTitleContainer: {
        flexDirection: "row",
        justifyContent: "space-between",
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        minHeight: 40,
        height: "auto",
        width: "100%",
        padding: 10,
        backgroundColor: "#59C09B",
    },
    textAbout: {
        fontFamily: "Poppins",
        fontSize: 18,
        fontWeight: '600',
        lineHeight: 27,
        color: "white",
    },
    contentContainer: {
        padding: 5,
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        borderWidth: 1,
        borderColor: "#59C09B",
        backgroundColor: "white",
        width: "100%",
        minHeight: 200,
        height: "auto",
    },
    textContent: {
        fontFamily: "Poppins",
        lineHeight: 19,
        fontSize: 14,
        fontWeight: '400',
        color: "#1D1144",
    },
    buttonTranslate: {
        width: 100,
        height: 20,
        backgroundColor: "white",
        borderRadius: 20,
        borderWidth: 1,
        borderColor: "black",
        alignItems: "center",
        justifyContent: "center",
        top: '20%'
    },
});
