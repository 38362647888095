var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// 🇫🇷 L'écran de la deuxième étape d'un processus d'inscription multi-étapes (FrameFigma011)
// 🇬🇧 The second step screen of a multi-step registration process (FrameFigma011)
import React, { useState, useEffect } from 'react';
import { View, Text, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as yup from 'yup';
import Vectors from '../../../../constants/Vectors';
import { useError } from '../../../../contexts/ErrorContext';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import ButtonNext from "../../../../components/ButtonSection/NextButton/Code";
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const Step2Screen = ({ onNextStep, onPrevStep }) => {
    const { translations, language } = useSelector((state) => state.language);
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const { error, setError } = useError();
    const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);
    // 🇫🇷 Effet pour récupérer les données stockées lors du montage du composant
    // 🇬🇧 Effect to retrieve stored data on component mount
    useEffect(() => {
        const retrieveData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                // If the data exists in AsyncStorage, we retrieve it, otherwise we use the default values, this allows us to keep the data between pages
                const firstnameValue = yield AsyncStorage.getItem('firstname');
                const lastnameValue = yield AsyncStorage.getItem('lastname');
                if (firstnameValue !== null) {
                    setFirstname(firstnameValue);
                }
                else {
                    yield AsyncStorage.setItem('firstname', '');
                }
                if (lastnameValue !== null) {
                    setLastname(lastnameValue);
                }
                else {
                    yield AsyncStorage.setItem('lastname', '');
                }
                setIsInitialPageLoad(false);
            }
            catch (error) {
                console.error('Erreur lors de la récupération des données:', error);
            }
        });
        retrieveData();
    }, []);
    const validationSchemaPersonal = yup.object().shape({
        firstname: yup
            .string()
            .min(2, translations.JsonCreateProfilePages.JsonErrorNameMinLength)
            .max(30, translations.JsonCreateProfilePages.JsonErrorNameMaxLength),
        lastname: yup
            .string()
            .min(2, translations.JsonCreateProfilePages.JsonErrorNameMinLength)
            .max(30, translations.JsonCreateProfilePages.JsonErrorNameMaxLength),
    });
    const handleNextStep = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield validationSchemaPersonal.validate({ firstname, lastname }, { abortEarly: false });
            yield AsyncStorage.setItem('firstname', firstname);
            yield AsyncStorage.setItem('lastname', lastname);
            setError('');
            onNextStep ? onNextStep() : () => { };
        }
        catch (error) {
            if (error instanceof yup.ValidationError) {
                const errorMessages = error.inner.map(err => {
                    if (err.path === 'firstname') {
                        return `${translations.JsonCreateProfilePages.JsonFirstNamePlaceholder} ${err.message}`;
                    }
                    else if (err.path === 'lastname') {
                        return `${translations.JsonCreateProfilePages.JsonLastNamePlaceholder} ${err.message}`;
                    }
                    else {
                        return err.message; // Utiliser le message d'erreur par défaut
                    }
                });
                setError(errorMessages.join('\n')); // Concaténer tous les messages d'erreur
            }
            else {
                console.error('Error during validation:', error);
            }
        }
    });
    const handleTextChange = (text, setterFunction) => {
        // Filtrer les caractères non autorisés (autres que lettres, accents, espaces, apostrophes et tirets)
        text = text.replace(/[^a-zA-ZÀ-ÿ'\-\s]/g, '');
        // Filtrer les espaces, tirets et apostrophes en début de chaîne
        text = text.replace(/^[\s'-]+/, '');
        // Remplacer les répétitions de caractères spéciaux par un seul
        text = text.replace(/[\s'-]{2,}/g, match => match[0]);
        // Convertir les majuscules qui ne sont pas au début d'un bloc en minuscules
        text = text.replace(/([^\s'-][A-ZÀ-Ÿ])/g, match => match.toLowerCase());
        // Assurer que chaque bloc commence par une majuscule, si nécessaire
        text = text.replace(/(?:^|[\s'-])([a-zà-ÿ])/g, match => match.toUpperCase());
        setterFunction(text);
        setError(''); // Réinitialiser les erreurs
    };
    return (<View key={language} style={styles.container}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <View style={styles.nameContent}>
        <View style={styles.firstName}>
          <TextInputCustomComponent Icon={Vectors.ProfilesLastName} placeholderText={translations.JsonCreateProfilePages.JsonFirstNamePlaceholder} value={firstname} handleTextChange={text => handleTextChange(text, setFirstname)} isPlaceHolderTextAlignCenter={false}/>
        </View>
        <View style={styles.lastName}>
          <TextInputCustomComponent Icon={Vectors.ProfilesLastName} placeholderText={translations.JsonCreateProfilePages.JsonLastNamePlaceholder} value={lastname} handleTextChange={text => handleTextChange(text, setLastname)} isPlaceHolderTextAlignCenter={false}/>
        </View>
      </View>
      <View style={styles.comment}>
        <Text>{translations.JsonCreateProfilePages.JsonSecretNameMessage}</Text>
      </View>
      <View style={styles.btnContainer}>
        <ButtonPrevious onPrevStep={onPrevStep ? onPrevStep : () => { }} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
        <ButtonNext conditionNeed={firstname.length >= 2 && lastname.length >= 2} handleNextStep={handleNextStep} btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}/>
      </View>
    </View>);
};
export default Step2Screen;
