import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
        width: '100%',
    },
    sexe: {
        width: '100%',
        alignItems: 'center',
    },
    containerSexe: {
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginTop: height * 0.12, // Adjusted to 12% of screen height
    },
    pressableMale: {
        height: height * 0.05, // Adjusted to 5% of screen height
        borderWidth: 1,
        width: '40%',
        borderTopLeftRadius: 13,
        borderBottomLeftRadius: 13,
        padding: height * 0.01, // Adjusted to 1% of screen height
    },
    male: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    pressableFemale: {
        height: height * 0.05, // Adjusted to 5% of screen height
        borderWidth: 1,
        width: '40%',
        borderTopRightRadius: 13,
        borderBottomRightRadius: 13,
        padding: height * 0.01, // Adjusted to 1% of screen height
    },
    female: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    icon: {
        marginHorizontal: width * 0.05, // Adjusted to 5% of screen width
    },
    textBtn: {
        alignItems: 'center',
        width: '60%',
    },
    textBtnF: {
        alignItems: 'center',
    },
    textSexe: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    activePressable: {
        backgroundColor: '#59C09B',
    },
    containerAccount: {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: height * 0.05, // Adjusted to 5% of screen height
    },
    personal: {
        borderWidth: 1,
        width: '40%',
        borderTopLeftRadius: 13,
        borderBottomLeftRadius: 13,
        padding: height * 0.01, // Adjusted to 1% of screen height
        height: height * 0.08, // Adjusted to 8% of screen height
        justifyContent: 'center',
        alignItems: 'center',
    },
    pro: {
        borderWidth: 1,
        width: '40%',
        borderTopRightRadius: 13,
        borderBottomRightRadius: 13,
        padding: height * 0.01, // Adjusted to 1% of screen height
        height: height * 0.08, // Adjusted to 8% of screen height
        justifyContent: 'center',
        alignItems: 'center',
    },
    textAccount: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: width * 0.04, // Adjusted to 4% of screen width
    },
    activeAccount: {
        backgroundColor: '#59C09B',
    },
    btnContainer: {
        width: '80%',
        alignItems: 'center',
        marginTop: height * 0.1, // Adjusted to 10% of screen height
    },
});
