import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        marginTop: 10,
        alignItems: 'center',
    },
    text: {
        marginLeft: 5,
        marginRight: 5,
        marginTop: 7,
        color: 'white',
        fontFamily: 'poppins',
        fontWeight: 'bold'
    },
    ribbon: {
        height: 30,
        backgroundColor: '#505050',
    },
    borderTriangle: {
        position: 'absolute',
        top: '100%',
        marginTop: -30,
        borderTopWidth: 15,
        borderTopColor: '#505050',
        borderBottomWidth: 15,
        borderBottomColor: '#505050',
    },
    leftBorderTriangle: {
        left: -15,
        borderLeftWidth: 15,
        borderLeftColor: 'transparent',
    },
    rightBorderTriangle: {
        right: -15,
        borderRightWidth: 15,
        borderRightColor: 'transparent',
    },
});
export default styles;
