import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    card: {
        minHeight: 140,
        width: 100,
        borderRadius: 15,
        borderWidth: 1,
        backgroundColor: 'white',
        borderColor: 'grey',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        margin: 10,
        flex: 1,
    },
    pressable: {
        width: 60,
        height: 60,
        borderRadius: 30,
        marginVertical: 10,
        position: 'relative',
    },
    profileImage: {
        width: 60,
        height: 60,
        borderWidth: 2,
        borderColor: 'grey',
        borderRadius: 30,
    },
    textName: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 'bold',
        lineHeight: 21,
    },
    actionContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: 10,
    },
    cross: {
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: 'red',
    },
    valid: {
        width: 20,
        height: 20,
        borderRadius: 5,
        backgroundColor: 'green',
    }
});
export default styles;
