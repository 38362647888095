//🇫🇷 Ce fichier gère le style mobile du composant NewUserPasswordScreen.js (FrameFigma005)
//🇬🇧 This file manages the mobile style of the NewUserPasswordScreen.js component (FrameFigma005)
import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    verification: {
        marginHorizontal: width * 0.05, // Adjusted to 5% of screen width
        width: '80%',
    },
    inputContainer: {
        marginTop: height * 0.01, // Adjusted to 1% of screen height
        width: '100%',
    },
    boldText: {
        marginVertical: height * 0.1, // Adjusted to 10% of screen height
        fontWeight: 'bold',
        fontSize: width * 0.04, // Adjusted to 4% of screen width
        textAlign: 'center',
    },
    underText: {
        fontSize: width * 0.035, // Adjusted to 3.5% of screen width
        textAlign: 'center',
        marginVertical: height * 0.05, // Adjusted to 5% of screen height
    },
    buttonContainer: {
        //alignItems: 'flex-start',
        flexDirection: 'row',
        marginVertical: height * 0.1, // Adjusted to 10% of screen height
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: width * 0.0625, // Adjusted to 6.25% of screen width
        paddingRight: width * 0.0625, // Adjusted to 6.25% of screen width
        alignItems: 'center',
        zIndex: -10,
    },
    headerError: {
        backgroundColor: 'red',
        padding: width * 0.05, // Adjusted to 5% of screen width
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: width * 0.045, // Adjusted to 4.5% of screen width
        fontWeight: 'bold',
    },
});
