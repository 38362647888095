import React from 'react';
import { ActivityIndicator, Platform, Text, TouchableOpacity, View } from 'react-native';
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs';
import { useSelector } from 'react-redux';
// import contexts
// import screens components
import MyActivitiesScreen from '../../../pages/CalendarSection/MyActivitiesScreen/MyActivitiesNavigator';
import ActivitiesScreen from '../../../pages/CalendarSection/ActivitesListScreen/Code';
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
const CustomTabBar = (props) => {
    const { state, descriptors, navigation } = props;
    return (<View style={{ flexDirection: "row" }}>
            {state.routes.map((route, index) => {
            var _a, _b;
            const { options } = descriptors[route.key];
            const label = (_b = (_a = options.tabBarLabel) !== null && _a !== void 0 ? _a : options.title) !== null && _b !== void 0 ? _b : route.name;
            const isFocused = state.index === index;
            const onPress = () => {
                const event = navigation.emit({
                    type: "tabPress",
                    target: route.key,
                    canPreventDefault: true,
                });
                if (!isFocused && !event.defaultPrevented) {
                    navigation.navigate(route.name);
                }
            };
            return (<TouchableOpacity key={index} role="button" onPress={onPress} style={Object.assign({ backgroundColor: isFocused ? "#59C09B" : "gray", borderRightWidth: index === 0 ? 2 : 0 }, styles.buttonNav)}>
                        <Text style={Object.assign({ color: isFocused ? "gray" : "white" }, styles.titleText)}>
                            {label}
                        </Text>
                    </TouchableOpacity>);
        })}
        </View>);
};
const Tab = createMaterialTopTabNavigator();
const Navbar = () => {
    const { translations } = useSelector((state) => state.language);
    // const {user} = useUser();
    const { user } = useSelector((state) => state.user);
    return (<>
            {!(user === null || user === void 0 ? void 0 : user._id) ? <ActivityIndicator size="large" color="#0000ff"/> :
            <Tab.Navigator screenOptions={{
                    // headerShown: false, pas une option valide pour MaterialTopTabNavigator
                    tabBarScrollEnabled: false,
                }} tabBar={(props) => <CustomTabBar {...props}/>}>
                    <Tab.Screen name={translations.JsonTopNav.JsonSecondLineLeft} component={ActivitiesScreen}/>
                    <Tab.Screen name={translations.JsonTopNav.JsonSecondLineRight} component={MyActivitiesScreen}/>
                </Tab.Navigator>}
        </>);
};
export default Navbar;
