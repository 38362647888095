// Import Modules
import React from 'react';
// Import Vectors
import { Vectors } from '../../../../constants/Vectors';
const EmeraldStarMedals = ({ points, width, height }) => {
    return (<>
      {points >= 0 && points <= 499 && <Vectors.Level1EmeraldStar width={width} height={height}/>}
      {points >= 500 && points <= 999 && <Vectors.Level2EmeraldStar width={width} height={height}/>}
      {points >= 1000 && points <= 1999 && <Vectors.Level3EmeraldStar width={width} height={height}/>}
      {points >= 2000 && points <= 4999 && <Vectors.Level4EmeraldStar width={width} height={height}/>}
      {points >= 5000 && points <= 9999 && <Vectors.Level5EmeraldStar width={width} height={height}/>}
      {points >= 10000 && points <= 49999 && <Vectors.Level6EmeraldStar width={width} height={height}/>}
      {points >= 50000 && <Vectors.Level7EmeraldStar width={width} height={height}/>}
    </>);
};
export default EmeraldStarMedals;
