var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Axios from "../Axios/AxiosService";
// import * as axios from '../axios/index'
const getUsers = () => {
    return Axios.get('/users');
};
const getAllUsers = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield Axios.get(`/users`);
        return response.data;
    }
    catch (error) {
        console.error('Error fetching user data:', error);
        return false;
    }
});
const getUserById = (otherUserId) => __awaiter(void 0, void 0, void 0, function* () {
    if (!otherUserId) {
        console.log('Invalid user ID');
        return null;
    }
    try {
        const response = yield Axios.get(`/users/${otherUserId}`);
        console.log('User data fetched :', response.data._id);
        return response.data;
    }
    catch (error) {
        console.log('Error fetching user data:', error);
        return null;
    }
});
const getUserByFilter = (allUsers, uId) => __awaiter(void 0, void 0, void 0, function* () {
    return allUsers.find(user => user._id === uId); // return user object
});
const addUser = (data) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'POST',
            url: `/users`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: data
        };
        return yield Axios.request(options).then(response => {
            console.log("the user has been added");
            return response.data;
        }).catch(error => {
            console.log('Failed to save profile on server', error.response);
            return false;
        });
    }
    catch (error) {
        console.error('Error while adding a user', error);
        return null;
    }
});
const updateUser = (uId, data) => __awaiter(void 0, void 0, void 0, function* () {
    // REECRIRE CETTE FONCTION
    try {
        const options = {
            method: 'PUT',
            url: `/users/${uId}`,
            headers: { 'Content-Type': 'application/json' },
            data: data
        };
        return yield Axios.request(options).then(response => {
            console.log("the user has been updated");
            return response.data;
        }).catch(error => {
            console.log('Failed to update profile on server', error.response);
            return false;
        });
        ;
    }
    catch (error) {
        console.error('Error while updating user data:', error);
        return null;
    }
});
const deleteUser = (uId) => __awaiter(void 0, void 0, void 0, function* () {
    return Axios.delete(`/users/${uId}`);
});
const deleteUserAccount = (deleteOptions, userId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'DELETE',
            url: `/users/${userId}`,
            headers: { 'Content-Type': 'application/json' },
            data: { deleteOptions }
        };
        return yield Axios.request(options).then(response => true)
            .catch(error => false);
    }
    catch (error) {
        console.error('Error while mofifying user data:', error);
        return null;
    }
});
const logUser = (email, password) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // Attempt to log in with the provided email and password
        const options = {
            method: 'POST',
            url: '/login',
            headers: { 'Content-Type': 'application/json' },
            data: { email, password }
        };
        return yield Axios.request(options).then(loginResponse => {
            const loginData = loginResponse.data;
            //console.log('Login response data:', loginData);
            // If the login is successful, store the user data and navigate to the Activities screen
            if (loginData.authToken && loginData.user) {
                return { request: true, loginData };
            }
            else {
                return { request: true, loginData: null };
            }
        }).catch(error => {
            console.log("the error", error);
            if (error.response.status === 401) {
                return { request: true, action: 'email' };
            }
            else {
                return { request: true, action: 'connectionIssue' };
            }
        });
    }
    catch (error) {
        console.error('error in logUser function :', error);
        return { request: false, action: 'requestError', error: error.message };
    }
});
const VerifyCode = (verificationCode) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'POST',
            url: '/verifyEmailExistingUser',
            headers: { 'Content-Type': 'application/json' },
            data: { verificationCode }
        };
        return yield Axios.request(options).then(response => {
            const responseData = response.data;
            console.log("the response data ", responseData);
            if (responseData.success) {
                return { request: true, verif: true, data: responseData };
            }
            else {
                return { request: true, verif: false };
            }
        }).catch(error => {
            console.log("the error", error);
            return { request: true, action: 'connectionIssue' };
        });
    }
    catch (error) {
        console.error('Error during verification process:', error);
        return { request: false, error: error.message };
    }
});
const checkExistingEmail = (email) => __awaiter(void 0, void 0, void 0, function* () {
    const options = {
        method: 'POST',
        url: '/verifyExistingEmail',
        headers: { 'Content-Type': 'application/json' },
        data: { email }
    };
    return yield Axios.request(options).then(response => response.data);
});
const getCurrentUserLikeStatus = (userIdAutre, userId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'GET',
            url: '/userLikes',
            headers: { 'Content-Type': 'application/json' },
        };
        return yield Axios.request(options).then(response => {
            const data = response.data;
            const likeStatus = data.some((like) => like.likedBy === userId && like.likedUser === userIdAutre);
            return likeStatus;
        });
    }
    catch (error) {
        console.error('Error fetching user data:', error);
        return false;
    }
});
const updateUserProfileImage = (userId, image) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const formData = new FormData();
        formData.append('profileImage', image);
        const options = {
            method: 'PUT',
            url: `/users/${userId}/profileImage`,
            headers: { 'Content-Type': 'multipart/form-data' },
            data: formData
        };
        return yield Axios.request(options).then(response => {
            console.log("the user profile image has been updated");
            return response.data;
        }).catch(error => {
            console.log('Failed to update user profile image on server', error.response);
            return false;
        });
        ;
    }
    catch (error) {
        console.error('Error while updating user data:', error);
        return null;
    }
});
const markVisitAsRead = (visitId, userId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'PATCH',
            url: `/users/${userId}/profileVisits/${visitId}/read`,
            headers: { 'Content-Type': 'application/json' },
        };
        return yield Axios.request(options).then(response => true);
    }
    catch (error) {
        console.log("erreur pendant la mise a jour d'une visite");
        return false;
    }
});
const markVisitAsUnread = (visitId, userId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'PATCH',
            url: `/users/${userId}/profileVisits/${visitId}/unread`,
            headers: { 'Content-Type': 'application/json' },
        };
        return yield Axios.request(options).then(response => true);
    }
    catch (error) {
        console.log("erreur pendant la mise a jour d'une visite");
        return false;
    }
});
const markLikeNotificationAsRead = (notificationId, userId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'PATCH',
            url: `/users/${userId}/likeNotifications/${notificationId}/read`,
            headers: { 'Content-Type': 'application/json' },
        };
        return yield Axios.request(options).then(response => true);
    }
    catch (error) {
        console.log("erreur pendant la mise a jour d'une like notification");
        return false;
    }
});
const markLikeNotificationAsUnread = (notificationId, userId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'PATCH',
            url: `/users/${userId}/likeNotifications/${notificationId}/unread`,
            headers: { 'Content-Type': 'application/json' },
        };
        return yield Axios.request(options).then(response => true);
    }
    catch (error) {
        console.log("erreur pendant la mise a jour d'une like notification");
        return false;
    }
});
const markActivityLikeNotificationAsRead = (notificationId, userId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'PATCH',
            url: `/users/${userId}/activityLikeNotifications/${notificationId}/read`,
            headers: { 'Content-Type': 'application/json' },
        };
        return yield Axios.request(options).then(response => true);
    }
    catch (error) {
        console.log("erreur pendant la mise a jour d'une activity like notification");
        return false;
    }
});
const markActivityLikeNotificationAsUnread = (notificationId, userId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'PATCH',
            url: `/users/${userId}/activityLikeNotifications/${notificationId}/unread`,
            headers: { 'Content-Type': 'application/json' },
        };
        return yield Axios.request(options).then(response => true);
    }
    catch (error) {
        console.log("erreur pendant la mise a jour d'une activity like notification");
        return false;
    }
});
const markFriendNotificationAsRead = (notificationId, userId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'PATCH',
            url: `/users/${userId}/friendNotifications/${notificationId}/read`,
            headers: { 'Content-Type': 'application/json' },
        };
        return yield Axios.request(options).then(response => true);
    }
    catch (error) {
        console.log("erreur pendant la mise a jour d'une friend notification");
        return false;
    }
});
const markFriendNotificationAsUnread = (notificationId, userId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const options = {
            method: 'PATCH',
            url: `/users/${userId}/friendNotifications/${notificationId}/unread`,
            headers: { 'Content-Type': 'application/json' },
        };
        return yield Axios.request(options).then(response => true);
    }
    catch (error) {
        console.log("erreur pendant la mise a jour d'une friend notification");
        return false;
    }
});
export const addFriendPostRequest = (userId, friendUserId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        // Update Logged In User
        let options = {
            method: 'POST',
            url: `/users/${userId}/addFriend`,
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify({
                friendId: friendUserId
            }),
        };
        const response = yield Axios.request(options).then(response => response.data)
            .catch(error => false);
        if (!response)
            return false;
        // Update Other User
        options = {
            method: 'POST',
            url: `/users/${friendUserId}/addFriend`,
            headers: { 'Content-Type': 'application/json' },
            data: JSON.stringify({
                friendId: userId
            }),
        };
        const otherResponse = yield Axios.request(options).then(response => response.data)
            .catch(error => false);
        if (!response)
            return false;
        else
            return true;
    }
    catch (error) {
        console.error('Error while adding a user as friend', error);
        return null;
    }
});
export const userServices = {
    getUsers,
    getUserById,
    updateUser,
    deleteUser,
    getUserByFilter,
    addUser,
    getAllUsers,
    getCurrentUserLikeStatus,
    deleteUserAccount,
    updateUserProfileImage,
    checkExistingEmail,
    logUser,
    VerifyCode,
    markVisitAsRead,
    markVisitAsUnread,
    markLikeNotificationAsRead,
    markLikeNotificationAsUnread,
    markActivityLikeNotificationAsUnread,
    markActivityLikeNotificationAsRead,
    markFriendNotificationAsRead,
    markFriendNotificationAsUnread,
    addFriendPostRequest
};
