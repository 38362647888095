import React from "react";
import { View, Text, Platform, TouchableOpacity } from 'react-native';
// Platform-specific styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const BottomWhiteNav = ({ items }) => {
    return (<View style={styles.container}>
            {items.map((item, index) => {
            const { label, onPress, disabled = false } = item;
            return (<TouchableOpacity key={index} style={[styles.buttonContainer, disabled && { backgroundColor: 'lightgrey' }]} onPress={onPress}>
                    <Text style={styles.buttonText}>{label}</Text>
                </TouchableOpacity>);
        })}
        </View>);
};
export default BottomWhiteNav;
