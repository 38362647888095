//Fr Styles de ButtonNext (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma036) (FrameFigma037A) (FrameFigma031) (FrameFigma035A,FrameFigma035B) (FrameFigma036) (FrameFigma038A,FrameFigma038B) (FrameFigma039)
//En Styles of ButtonNext (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma036) (FrameFigma037A) (FrameFigma031) (FrameFigma035A,FrameFigma035B) (FrameFigma036) (FrameFigma038A,FrameFigma038B) (FrameFigma039)
import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
const styles = StyleSheet.create({
    btnNext: {
        backgroundColor: '#59C09B',
        width: width * 0.45, // Adjusted to 45% of screen width
        height: height * 0.06, // Adjusted to 6% of screen height
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000000', // Couleur de l'ombre
        shadowOffset: { width: 0, height: 4 }, // Décalage de l'ombre
        shadowOpacity: 0.25, // Opacité de l'ombre
        shadowRadius: 4, // Rayon de l'ombre
        elevation: 5, // Pour Android, nécessaire pour l'ombre
    },
    btnText: {
        color: 'white',
        fontFamily: 'Poppins',
        fontSize: width * 0.055, // Adjusted to 5.5% of screen width
        fontWeight: 'bold',
        lineHeight: width * 0.083, // Adjusted to 8.3% of screen width
        textAlign: 'center',
        paddingHorizontal: width * 0.005, // Adjusted padding to 0.5% of screen width
    },
});
export default styles;
