var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { hostname } from '../../../../hostName/hostName';
import { Text, View } from 'react-native';
export const verifyExistingUserName = (userName) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        console.log('Vérification de l\'existence du nom d\'utilisateur :', userName);
        const response = yield fetch(`${hostname}/verifyUserName`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userName }),
        });
        if (response.status === 200) {
            console.log('Le nom d\'utilisateur est unique.');
            return false;
        }
        else if (response.status === 400) {
            console.log('Le nom d\'utilisateur existe déjà dans la base de données.');
            console.log('Nom d\'utilisateur dans la base de données :', yield response.json());
            return true;
        }
        else {
            console.error('Erreur inattendue lors de la vérification du nom d\'utilisateur :', response.status);
            return false;
        }
    }
    catch (error) {
        console.error("Erreur lors de la vérification du nom d'utilisateur existant :", error);
        return false;
    }
});
export const generateUsername = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const firstname = yield AsyncStorage.getItem('firstname');
        const lastname = yield AsyncStorage.getItem('lastname');
        if (!firstname || !lastname) {
            throw new Error("Prénom ou nom de famille non disponible.");
        }
        const sanitizedFirstname = firstname.replace(/[\s']/g, '-');
        const sanitizedLastname = lastname.replace(/[\s']/g, '-');
        const capitalizedFirstname = sanitizedFirstname.charAt(0).toUpperCase() + sanitizedFirstname.slice(1);
        const capitalizedLastname = sanitizedLastname.charAt(0).toUpperCase() + sanitizedLastname.slice(1);
        let userName = `${capitalizedFirstname}.${capitalizedLastname}`;
        if (userName.length > 15) {
            userName = userName.slice(0, 15);
        }
        let isUsernameExists = yield verifyExistingUserName(userName);
        let attempts = 0;
        const maxAttempts = 10;
        while (isUsernameExists && attempts < maxAttempts) {
            console.log('Le username existe déjà :', userName);
            if (userName.length === 15) {
                userName = userName.slice(0, 14) + Math.floor(Math.random() * 10);
            }
            else {
                userName = userName + Math.floor(Math.random() * 10);
            }
            console.log('Vérification avant la régénération :', yield verifyExistingUserName(userName));
            isUsernameExists = yield verifyExistingUserName(userName);
            console.log('Vérification après la régénération :', isUsernameExists);
            attempts++;
        }
        if (attempts === maxAttempts) {
            throw new Error('Impossible de générer un username unique après plusieurs tentatives.');
        }
        console.log('Username généré avec succès :', userName);
        return userName;
    }
    catch (error) {
        console.error('Erreur lors de la génération du username :', error);
        throw error;
    }
});
export const generateUsernameSuggestions = (baseUsername) => __awaiter(void 0, void 0, void 0, function* () {
    const suggestions = [];
    for (let i = 0; i < 4; i++) {
        const suggestion = baseUsername + (Math.floor(Math.random() * 100));
        const isTaken = yield verifyExistingUserName(suggestion);
        if (!isTaken) {
            suggestions.push(suggestion);
        }
    }
    return suggestions;
});
const UniqueUserName = () => {
    useEffect(() => {
        const generateAndSaveUsername = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const generatedUsername = yield generateUsername();
                // Enregistrez le username généré où vous en avez besoin dans votre application
                console.log('Username généré :', generatedUsername);
            }
            catch (error) {
                console.error('Erreur lors de la génération du username :', error);
            }
        });
        generateAndSaveUsername();
    }, []);
    return (<View>
      <Text>Vérification...</Text>
    </View>);
};
export default UniqueUserName;
