import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: 20,
    },
    triangleLeft: {
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderRightWidth: 20,
        borderTopWidth: 20,
        borderRightColor: 'transparent',
        borderTopColor: '#4A4A4A',
        transform: [{ rotate: '90deg' }],
    },
    banner: {
        backgroundColor: '#4A4A4A',
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    bannerText: {
        color: 'white',
        fontSize: 14,
        fontWeight: 'bold',
    },
    triangleRight: {
        width: 0,
        height: 0,
        borderStyle: 'solid',
        borderLeftWidth: 20,
        borderTopWidth: 20,
        borderLeftColor: 'transparent',
        borderTopColor: '#4A4A4A',
        transform: [{ rotate: '-90deg' }],
    },
    DisplayDate: {
        marginTop: '2%',
        marginLeft: '2%',
        backgroundColor: '#ECEBEB',
        // height:'10%',
        width: '20%',
        padding: 10,
        textAlign: 'center',
        borderRadius: 10,
        fontFamily: 'Poppins',
        fontWeight: 'bold'
    },
    colorBackroundWhite: {
        backgroundColor: 'white',
        height: '100%'
    }
});
export default styles;
