// FR  crée un écran de membres qui affiche une liste d'utilisateurs
// EN creates a members screen that displays a list of users
import React from 'react';
import { FlatList, ActivityIndicator, Platform } from 'react-native';
import UserCard from '../../../components/CardSection/UserCard/Code';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
const MembersScreen = ({ searchValue }) => {
    console.log('MembersScreen.js: MembersScreen');
    // const { allUsers } = useUser();
    const { allUsers } = useSelector((state) => state.user);
    const filteredUsers = allUsers.filter((user) => {
        const userName = user.userName ? user.userName.toLowerCase() : '';
        const searchText = searchValue ? searchValue.toLowerCase() : '';
        return userName.includes(searchText);
    });
    // if (loading) {
    //   return <ActivityIndicator size="large" />;
    // }
    return (<>
    {allUsers.length === 0 && <ActivityIndicator size="large"/>}
      <FlatList showsHorizontalScrollIndicator={false} data={filteredUsers} renderItem={({ item }) => <UserCard userCard={item}/>} keyExtractor={item => item._id.toString()} numColumns={2} contentContainerStyle={styles.container}/>
    </>);
};
export default MembersScreen;
