var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { FlatList, ActivityIndicator } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { hostname } from '../../../../hostName/hostName';
import UserCard from '../../../components/CardSection/UserCardFriend/Code';
import { useSelector } from 'react-redux';
const FriendListScreen = () => {
    const [users, setUsers] = useState([]); // Use User interface for state
    const [loading, setLoading] = useState(true);
    // const { user } = useUser();
    const { user } = useSelector((state) => state.user);
    useEffect(() => {
        const fetchUsers = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                console.log('Fetching token and user ID...');
                const userToken = yield AsyncStorage.getItem('userToken');
                const myUserId = yield AsyncStorage.getItem('userId');
                console.log(`Token: ${userToken}, UserID: ${myUserId}`);
                console.log('Making API call...');
                const response = yield fetch(`${hostname}/users`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                console.log('API call complete:', response.ok);
                if (!response.ok) {
                    throw new Error('Failed to fetch users');
                }
                let usersData = yield response.json();
                usersData = usersData.filter(user => user.friends && user.friends.includes(myUserId !== null ? myUserId : ''));
                setUsers(usersData);
            }
            catch (error) {
                console.error('Failed to fetch or process users:', error);
            }
            finally {
                setLoading(false);
            }
        });
        fetchUsers();
        return () => {
            console.log('FriendListScreen cleanup');
            setUsers([]);
        };
    }, []);
    if (loading) {
        return <ActivityIndicator size="large"/>;
    }
    return (<FlatList showsHorizontalScrollIndicator={false} data={users} renderItem={({ item }) => <UserCard user={item}/>} keyExtractor={item => item._id.toString()}/>);
};
export default FriendListScreen;
