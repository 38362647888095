import React, { createContext, useState, useContext } from 'react';
import { Platform } from 'react-native';
const WebDrawerContext = createContext(undefined);
export const WebDrawerProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDrawer = () => {
        if (Platform.OS === 'web') {
            setIsOpen(!isOpen);
        }
        else {
            console.log('Drawer toggle not available on mobile');
        }
    };
    const value = { isOpen, toggleDrawer };
    return <WebDrawerContext.Provider value={value}>{children}</WebDrawerContext.Provider>;
};
export const useWebDrawer = () => {
    const context = useContext(WebDrawerContext);
    if (!context) {
        throw new Error('useWebDrawer must be used within a WebDrawerProvider');
    }
    return context;
};
