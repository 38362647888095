import React from 'react';
import { Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import { DrawerActions, useNavigation } from '@react-navigation/native';
import { useWebDrawer } from '../../DrawerNavigator/DrawerWeb/WebDrawerContext';
const BurgerButton = (onPress) => {
    const navigation = useNavigation(); // Adjust 'any' to your navigation params type if known
    const context = Platform.OS === 'web' ? useWebDrawer() : null;
    const toggleDrawer = context ? context.toggleDrawer : () => {
    };
    const handlePress = () => {
        if (Platform.OS === 'web') {
            toggleDrawer();
        }
        else {
            navigation.dispatch(DrawerActions.openDrawer());
        }
    };
    return (<Pressable style={styles.burgerButton} onPress={handlePress}>
            <View>
                <View style={styles.line}/>
                <View style={styles.line}/>
                <View style={styles.line}/>
            </View>
            <View style={styles.menuBtn}>
                <Text style={styles.menuText}>Menu</Text>
            </View>
        </Pressable>);
};
export default BurgerButton;
const styles = StyleSheet.create({
    burgerButton: {
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        padding: 10,
    },
    line: {
        height: 2,
        width: 35,
        backgroundColor: 'white',
        marginVertical: 2,
    },
    menuBtn: {
        alignItems: 'center',
        marginTop: 5,
    },
    menuText: {
        fontSize: 13,
        color: 'white',
    },
});
