// fr creation du top tab navigator pour les details d'une activité : FrameFigma041
// en create the top tab navigator for the details of an activity: FrameFigma041
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from "react";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { Platform, Text, TouchableOpacity, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { nanoid } from "@reduxjs/toolkit";
// Import Screens
import ActivityDetailsDescriptionScreen from "../ActivityDetailsScreens/ActivityDetailsDescriptionScreen/Code";
import ActivityDetailsAddressSecretScreen from "../ActivityDetailsScreens/ActivityDetailsAddressScreen/ActivityDetailsAddressSecretScreen";
import ActivityDetailsAddressScreen from "../ActivityDetailsScreens/ActivityDetailsAddressScreen/Code";
import ActivityDetailsOnlineScreen from "../ActivityDetailsScreens/ActivityDetailsOnlineScreen/Code";
import ActivityParticipantsScreen from "../ActivityDetailsScreens/ActivityDetailsAttendeesScreen/Code";
// import ActivityChatScreen from "../ActivityDetailsScreens/ActivityDetailsChatScreen/ActivityDetailsChatScreen";
// import Modals
import MyModal from "../../../../components/ModalPopUpModule/ModalSection/ActivityDetailsModal/MyModal";
import ActivityAddressModal from "../../../../components/ModalPopUpModule/ModalSection/ActivityAdressModal/Code";
// import ActivityParticipantsModal from "../../../components/Modal/ActivityDetails/ActivityParticipantsModal";
import ActivityJoinWaitingListModal from "../../../../components/ModalPopUpModule/ModalSection/ActivityJoinWaitingListModal/Code";
//import Vectors
import { Vectors } from "../../../../constants/Vectors";
//import services
import { ParticipantsServices } from "../../../../services/activities/ParticipantsServices";
import { ActivityServices } from "../../../../services/activities/ActivityServices";
import { ActivityLikeServices } from "../../../../services/activities/ActivityLikeServices";
import { InterestedServices } from "../../../../services/activities/InterestedServices";
// import fonction utils
import activityParticpate from "../../../../services/activities/ActivityParticpate";
// Import Contexts
import { useActivityModal } from '../../../../contexts/ActivityModalContext';
import { useDispatch, useSelector } from "react-redux";
import ActivitiesSlice from "../../../../redux/Features/Activities/AtivitiesSlice";
import ChatModal from "../../../../components/ModalPopUpModule/ModalSection/ChatModal/Code";
import StylesMobile from './StylesMobile';
import StylesWeb from './StylesWeb';
// Import Styles
const styles = Platform.OS === 'web' ? StylesWeb : StylesMobile;
const Tab = createMaterialTopTabNavigator();
const ActivityDetailsTabNavigator = () => {
    const navigation = useNavigation();
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    // const {activityView, setActivityView} = useActivity();
    const { activityView } = useSelector((state) => state.activities);
    const { activityModalOpen, setActivityModalOpen } = useActivityModal();
    // const {user} = useUser();
    const { user, allUsers } = useSelector((state) => state.user);
    const [modalAddressVisible, setModalAddressVisible] = useState(false);
    const [modalWaitingListVisible, setModalWaitingListVisible] = useState(false);
    // const [modalParticipantsVisible, setModalParticipantsVisible] = useState(false);
    const dispatch = useDispatch();
    const setActivityView = (data) => {
        dispatch(ActivitiesSlice.actions.setActivityView({ id: nanoid(), value: data }));
    };
    const CustomTabBar = (props) => {
        const { state, descriptors, navigation } = props;
        return (<View style={{ flexDirection: "row" }}>
                {state.routes.map((route, index) => {
                var _a, _b;
                const { options } = descriptors[route.key];
                const label = (_b = (_a = options.tabBarLabel) !== null && _a !== void 0 ? _a : options.title) !== null && _b !== void 0 ? _b : route.name;
                const isFocused = state.index === index;
                const onPress = () => __awaiter(void 0, void 0, void 0, function* () {
                    const event = navigation.emit({
                        type: "tabPress",
                        target: route.key,
                    });
                    if ((route.name === 'Address' || (route.name === 'Online' && !!activityView.addressOnlyForAttendees)) && !ParticipantsServices.isUserParticipate(activityView, user._id)) {
                        !ParticipantsServices.isUserInWaitingList ? setModalAddressVisible(true) : setActivityModalOpen(true);
                        return;
                    }
                    // if((route.name === 'Participants' && !!activityView.addressOnlyForAttendees) && !ParticipantsServices.isUserParticipate(activityView, user._id)){
                    //     setModalParticipantsVisible(true);
                    //     return
                    // }
                    if (!isFocused && !event.defaultPrevented) {
                        const activity = yield ActivityServices.getActivity(activityView._id);
                        const likesArray = yield ActivityLikeServices.getActivityLikesArray(activity.data);
                        const interestedArray = yield InterestedServices.getInterestedArray(activity.data);
                        setActivityView(Object.assign(Object.assign({}, activityView), { attendees: activity.data.attendees, likes: likesArray, comments: interestedArray }));
                        navigation.navigate(route.name);
                    }
                });
                return (<TouchableOpacity key={index} role="button" onPress={onPress} style={Object.assign({ backgroundColor: isFocused ? "#59C09B" : "gray", borderLeftWidth: index > 0 ? 2 : 0 }, styles.buttonNav)}>
                            {index === 0 ? isFocused ? <Vectors.NavbarEditInfoColors width={28} height={28}/> :
                        <Vectors.NavbarEditInfo width={28} height={28}/> : null}
                            {activityView.location.latitude === null && activityView.location.latitude === ''
                        ? index === 1 ? isFocused ? <Vectors.GeneralColorsLinkChain width={28} height={28}/> :
                            <Vectors.GeneralBlackAndWhiteLinkChain width={28} height={28}/> : null
                        : index === 1 ? isFocused ? <Vectors.PinPointColors width={28} height={28}/> :
                            <Vectors.NavbarPinPointMiddle width={28} height={28}/> : null}
                            {index === 2 ? isFocused ? <Vectors.NavbarMembersBottom width={28} height={28}/> :
                        <Vectors.NavbarMembersMiddle width={28} height={28}/> : null}

                            <Text style={Object.assign({ color: isFocused ? "gray" : "white" }, styles.titleText)}>
                                {label}
                            </Text>
                        </TouchableOpacity>);
            })}
            </View>);
    };
    const handleParticipate = (activityView, setActivityView, navigation, user, modalWaitingListVisible, setModalWaitingListVisible) => {
        activityParticpate(activityView, setActivityView, navigation, user, setModalWaitingListVisible);
    };
    const action = (activityView, user, setActivityView, setModalWaitingListVisible) => {
        if (!ParticipantsServices.isUserInWaitingList(activityView, user._id)) {
            ParticipantsServices.joinWaitingList(activityView, user._id, setActivityView, setModalWaitingListVisible);
            setModalWaitingListVisible(false);
            return;
        }
        else {
            ParticipantsServices.leaveWaitingList(activityView, user._id, setActivityView, setModalWaitingListVisible);
            setModalWaitingListVisible(false);
            return;
        }
    };
    return (<>
            <MyModal modalVisible={modalAddressVisible} onClose={() => setModalAddressVisible(false)} w={400}>
                <ActivityAddressModal setModalVisible={setModalAddressVisible} action={() => { handleParticipate(activityView, setActivityView, navigation, user, modalWaitingListVisible, setModalWaitingListVisible), setModalAddressVisible(false); }}/>
            </MyModal>
            {/* <MyModal modalVisible={modalParticipantsVisible} onClose={()=>setModalParticipantsVisible(false)} w={400}>
        <ActivityParticipantsModal setModalVisible={setModalParticipantsVisible} action={()=>{handleParticipate(activityView, setActivityView, navigation, user, modalWaitingListVisible, setModalWaitingListVisible), setModalParticipantsVisible(false)}} />
    </MyModal> */}
            <MyModal modalVisible={modalWaitingListVisible} onClose={() => setModalWaitingListVisible(false)} w={400}>
                <ActivityJoinWaitingListModal action={() => action(activityView, user, setActivityView, setModalWaitingListVisible)} setModalVisible={setModalWaitingListVisible} activity={activityView} user={user}/>
            </MyModal>
            {ParticipantsServices.isUserParticipate(activityView, user._id) ?
            <ChatModal chatId={''} isVisible={activityModalOpen} closeModal={() => setActivityModalOpen(false)} participants={activityView.attendees.map((participant) => ({ _id: participant }))} activityId={activityView._id}/>
            :
                ParticipantsServices.isUserInWaitingList(activityView, user._id) ?
                    <MyModal modalVisible={activityModalOpen} onClose={() => setActivityModalOpen(false)} w={400}>
                        <ActivityJoinWaitingListModal action={() => action(activityView, user, setActivityView, setActivityModalOpen)} setModalVisible={setActivityModalOpen} activity={activityView} user={user} text={'You are already in the waiting list...\n Be patient or leave the list.'}/>
                    </MyModal>
                    :
                        <MyModal modalVisible={activityModalOpen} onClose={() => setActivityModalOpen(false)} w={400}>
                        <ActivityAddressModal setModalVisible={setActivityModalOpen} action={() => { handleParticipate(activityView, setActivityView, navigation, user, modalWaitingListVisible, setModalWaitingListVisible), setActivityModalOpen(false); }}/>
                    </MyModal>}
            <Tab.Navigator tabBar={(props) => <CustomTabBar {...props}/>}>
                <Tab.Screen name='Description' options={{ tabBarLabel: translations.JsonActivityPages.JsonActivityScreen.JsonDescription }} component={ActivityDetailsDescriptionScreen}/>
                {activityView.addressOnlyForAttendees === true && ParticipantsServices.isUserParticipate(activityView, user._id) === false ?
            <Tab.Screen name={(activityView.infoLine || activityView.infoLine) !== '' ? 'Online' : translations.JsonActivityPages.JsonActivityScreen.JsonAddress} options={{ tabBarLabel: translations.JsonActivityPages.JsonActivityScreen.JsonAddress }} component={ActivityDetailsAddressSecretScreen}/>
            :
                activityView.location.latitude !== null && activityView.location.latitude !== '' ?
                    <Tab.Screen name='Address' options={{ tabBarLabel: translations.JsonActivityPages.JsonActivityScreen.JsonAddress }} component={ActivityDetailsAddressScreen}/>
                    :
                        <Tab.Screen name='Online' options={{ tabBarLabel: 'Online' }} component={ActivityDetailsOnlineScreen}/>}
                {/* <Tab.Screen name="Chat" component={ActivityChatScreen} /> */}
                <Tab.Screen name='Participants' options={{ tabBarLabel: translations.JsonActivityPages.JsonActivityScreen.JsonParticipants }} component={ActivityParticipantsScreen}/>
            </Tab.Navigator>
        </>);
};
export default ActivityDetailsTabNavigator;
