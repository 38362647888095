import React, { useEffect, useRef, useState } from 'react';
import { GOOGLE_MAPS_API_KEY } from '@env';
const Maps = ({ Coords, setMyPositionGoogleUrl, myPosition }) => {
    const [isScriptMapLoad, setIsScriptMapLoad] = useState(false);
    const mapRef = useRef(null);
    let script;
    const loadGoogleMapsScript = () => {
        if (window.google && window.google.maps) {
            console.log("L'API Google Maps est déjà chargée.");
            setIsScriptMapLoad(true);
            return;
        }
        script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geometry`;
        script.async = true;
        document.body.appendChild(script);
        script.onload = () => {
            console.log("L'API Google Maps est chargée.");
            setIsScriptMapLoad(true);
        };
    };
    const initMapPointCenter = (coords) => {
        if (coords.lat === null || coords.lng === null) {
            return;
        }
        const mapElement = mapRef.current;
        if (mapElement) {
            const initializedMap = new window.google.maps.Map(mapElement, {
                center: coords,
                zoom: 16,
            });
            new window.google.maps.Marker({
                position: coords,
                map: initializedMap,
            });
            return initializedMap;
        }
    };
    const getLocationDetailsByCoords = () => {
        if (!Coords || myPosition) {
            return;
        }
        if (!window.google) {
            console.error("Google Maps n'est pas chargée");
            return;
        }
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: Coords }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    console.log('Adresse complète:', results[0].formatted_address);
                    console.log('place_id:', results[0].place_id);
                    if (setMyPositionGoogleUrl) {
                        setMyPositionGoogleUrl(`https://www.google.com/maps/place/?q=place_id:${results[0].place_id}`);
                    }
                }
                else {
                    console.log('Adresse introuvable');
                }
            }
            else {
                console.error('Erreur de géocodage:', status);
            }
        });
    };
    const getUserLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                // Remplacez ceci par l'appel réel à votre service ou fonction
                console.log('Position utilisateur:', pos);
            }, () => {
                console.error("Erreur lors de l'obtention de la géolocalisation");
            });
        }
        else {
            console.error("La géolocalisation n'est pas prise en charge par ce navigateur.");
        }
    };
    useEffect(() => {
        if (!Coords) {
            getUserLocation();
        }
    }, [Coords]);
    useEffect(() => {
        loadGoogleMapsScript();
        return () => {
            if (script) {
                console.log("L'API Google Maps est déchargée.");
                document.body.removeChild(script);
            }
        };
    }, []);
    useEffect(() => {
        if (isScriptMapLoad) {
            initMapPointCenter(Coords);
            getLocationDetailsByCoords();
        }
    }, [isScriptMapLoad, Coords]);
    return (<div ref={mapRef} style={{
            height: '253px',
            width: '100%',
            position: 'relative',
        }}/>);
};
export default Maps;
