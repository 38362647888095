import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
    },
    top: {
        backgroundColor: 'white',
        width: '100%',
        height: 'auto',
    },
    image: {
        minWidth: '100%',
        height: 253,
        resizeMode: 'stretch',
    },
    middle: {
        backgroundColor: 'white',
        width: '100%',
        height: 'auto',
        flex: 1,
        paddingTop: 20,
        paddingBottom: 60,
        paddingHorizontal: 20,
        flexDirection: 'column',
    },
    bottom: {
        backgroundColor: 'white',
        width: '100%',
        height: 'auto',
        paddingTop: 20,
        paddingBottom: 10,
    },
    containerButtonBottom: {
        width: "100%",
        height: 50,
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
    },
    displayBannerContainer: {
        marginBottom: 20,
    },
});
export default styles;
