// Fr afficher les termes et conditions à l'utilisateur (FrameFigma004)
// En show terms and conditions to user (FigmaFrame004)
import { View, Text } from 'react-native';
import React from 'react';
const TermsAndConditionsScreen = () => {
    return (<View>
      <Text>TermsAndConditionsScreen</Text>
    </View>);
};
export default TermsAndConditionsScreen;
