import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    addressContent: {
        width: '80%'
    },
    btnContainer: {
        paddingHorizontal: 25,
        flexDirection: 'row',
        marginVertical: '10%',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginTop: '10%',
        zIndex: -2
    },
    nicknameContainer: {
        width: '80%',
        zIndex: -5,
    },
    nickNameMessage: {
        textAlign: "center",
        marginTop: 15,
        marginBottom: 1,
        marginHorizontal: 5
    },
    nickName: {
        // flexDirection: 'row',
        // alignItems: 'center',
        width: '100%',
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    errorText: {
        color: 'red',
        fontSize: 14,
        marginVertical: 5,
    },
    suggestionsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 5,
    },
    suggestionText: {
        color: 'blue',
        fontSize: 14,
        marginHorizontal: 5,
        textDecorationLine: 'underline',
        //cursor: 'pointer',
    },
});
