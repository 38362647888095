const handleUserLiked = (socket, addNotification) => {
    socket.on('UserLiked', data => {
        // Construire l'objet de notification avec plus de détails
        const notification = {
            type: 'userLiked', // Utiliser le type de notification approprié
            message: `Nouveau like : ${data.from}. ${data.message}`, // Vous pouvez conserver le message personnalisé ici
            from: data.from, // ID de l'utilisateur qui a envoyé la notification
            to: data.to, // ID de l'utilisateur qui reçoit la notification
        };
        // Utiliser addNotification pour ajouter cet objet à l'état des notifications
        addNotification(notification);
    });
};
export default handleUserLiked;
