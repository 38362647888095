// ProgressBar.tsx
import React from 'react';
import { View, StyleSheet, Text } from 'react-native';
const styles = StyleSheet.create({
    progressBarContainer: {
        height: 8,
        width: '100%',
        backgroundColor: 'red',
        borderRadius: 5,
        marginTop: '5%',
        justifyContent: 'center',
    },
    progressBar: {
        position: 'absolute',
        height: '100%',
        backgroundColor: 'green',
        borderRadius: 5,
    },
    progressText: {
        position: 'absolute',
        alignSelf: 'center',
        fontSize: 8,
        fontWeight: 'bold',
        color: 'white',
    },
});
const ProgressBar = ({ progress }) => (<View style={styles.progressBarContainer}>
    <View style={[styles.progressBar, { width: `${progress * 100}%` }]}/>
    <Text style={styles.progressText}>{`${Math.floor(progress * 100)}%`}</Text>
  </View>);
export default ProgressBar;
