import React from 'react';
import { View } from 'react-native';
import PhoneInput from 'react-phone-input-2';
const PhoneNumberInput = ({ value, onPhoneNumberChange, onCountryChange, country, }) => {
    return (<View /*style={customStyles.container}*/>
            <PhoneInput country={country ? country : 'fr'} value={value} onChange={(phone, countryData) => {
            onPhoneNumberChange(phone);
            onCountryChange(countryData.countryCode);
        }} autoFormat={true} enableTerritories={false} enableSearch={true} inputStyle={Styles.input} containerStyle={Styles.container} buttonStyle={Styles.button} dropdownStyle={Styles.dropdownContainer} searchStyle={Styles.searchBox}/>
        </View>);
};
export default PhoneNumberInput;
const Styles = {
    container: {
        width: '100%',
        marginHorizontal: '1%',
        //position: 'relative',
    }, //as ViewStyle, // Type assertion for styles
    input: {
        width: '100%',
        height: 40,
        borderColor: 'rgba(0, 0, 0, 1)',
        borderWidth: 1,
        borderRadius: 20,
        paddingVertical: 10,
        paddingHorizontal: '15%',
        backgroundColor: 'white',
        color: 'black',
        fontSize: 16,
    },
    button: {
        border: 'none',
        backgroundColor: 'transparent',
        padding: 10,
    },
    dropdownContainer: {
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#ccc',
        zIndex: 3,
        //position: 'absolute',
        height: '400%',
    },
    searchBox: {
        padding: 10,
        margin: 10,
        borderWidth: 1,
        borderColor: '#ccc',
    },
    icon: {
        position: 'absolute',
        left: '3%',
        top: '50%',
        transform: [{ translateY: '-50%' }],
        zIndex: 2,
    },
};
