import React, { useState } from 'react';
import { Text, View } from 'react-native';
import { formatDateWithDay } from '../../../utils/Functions/functions';
import { useSelector } from 'react-redux';
const styles = require('./StylesWeb').default; //TODO: Pas de style mobile ?
const ActivityDetailsHeaderComponent = () => {
    // const {activityView, setActivityView} = useActivity();
    const { activityView } = useSelector((state) => state.activities);
    const [isLiked, setIsLiked] = useState(activityView.isLiked);
    // const {language, translations} = useLanguage()
    const { language, translations } = useSelector((state) => state.language);
    // const checkLikeStatus = async () => {
    //     const liked = await ActivityLikeServices.isUserLikedActivity(activityView);
    //     setIsLiked(liked);
    // };
    // useEffect(() => {
    //     checkLikeStatus();
    // }, []);
    return (<View style={styles.containerUp}>

            <Text style={styles.titleText}>{activityView.title}</Text>
            <View style={styles.containerDown}>
                <View style={styles.startTimeContainer}>
                    <View style={styles.timeContainer}><Text style={styles.timeText}>{activityView.startTime.hour}:{activityView.startTime.minute}</Text></View>
                    {/* <Text>{activityView ? `${activityView.startTime.hour}:${activityView.startTime.minute}` : 'Chargement...'}</Text> */}
                </View>
                <View style={styles.dateContainer}>
                    <Text style={styles.dateText}>{activityView ? formatDateWithDay(activityView.date, language) : 'Chargement...'}</Text>
                </View>
            </View>
        </View>);
};
export default ActivityDetailsHeaderComponent;
