import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    editProfileContainer: {
        flex: 1,
        backgroundColor: '#fff',
    },
    profileImageContainer: {
        paddingTop: '8%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    image: {
        width: 120,
        height: 120,
        borderRadius: 60,
    },
    camera: {
        position: 'absolute',
        top: 120,
        right: '37%',
    },
    compte: {
        marginTop: '5%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '10%',
    },
    ButtonPerso: {
        borderWidth: 1,
        borderTopLeftRadius: 15,
        borderBottomLeftRadius: 15,
        width: '45%',
        height: '60%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textButtonPersoPro: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    ButtonPro: {
        borderWidth: 1,
        borderTopRightRadius: 15,
        borderBottomRightRadius: 15,
        width: '45%',
        height: '60%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    userForm: {
        width: '100%',
        alignItems: 'center',
    },
    inputContainer: {
        width: '90%',
    },
    vectorLangage: {
        width: 30,
        height: 30,
        marginLeft: '3%',
    },
    label: {
        fontSize: 16,
        fontStyle: 'italic',
        fontWeight: '400',
        lineHeight: 24,
        color: '#C4C4C4',
    },
    containerWithIcon: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
        width: '100%',
        height: '70%',
        borderColor: 'black', // Couleur de la bordure
        borderWidth: 1, // Épaisseur de la bordure
        borderRadius: 20,
        marginBottom: '5%',
    },
    pickerStyle: {
        width: '70%',
        borderWidth: 0, // Supprime les bordures
        fontSize: 16,
        marginLeft: '5%',
        marginRight: '5%',
    },
    flagStyle: {
        width: 24,
        height: 24,
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    errorText: {
        alignContent: 'center',
        textAlign: 'center',
        color: 'red',
        fontSize: 14,
        marginVertical: 5,
    },
    suggestionsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginTop: 10,
    },
    suggestionText: {
        color: 'blue',
        fontSize: 14,
        marginHorizontal: 5,
        marginBottom: 5,
        textDecorationLine: 'underline',
        //cursor: 'pointer',
    },
    suggestionButton: {
        backgroundColor: 'white',
        borderColor: '#59C09B',
        borderWidth: 1,
        borderRadius: 5,
        padding: 8,
        margin: 5,
    },
    separator: {
        marginBottom: '2%',
    }
});
export default styles;
