// Fr  Styles de CreateActivityCustomizingStepsScreen (FrameFigma033)
// En  Styles of CreateActivityCustomizingStepsScreen (FrameFigma033)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        width: '100%',
    },
    textTitleOne: {
        marginTop: 15,
        fontWeight: 'bold',
        fontSize: 18,
    },
    switchBtn: {
        marginTop: 70,
        paddingVertical: 5,
        flexDirection: 'row',
        alignItems: 'center',
    },
    slider: {
        fontWeight: 'bold',
        fontSize: 18,
        marginVertical: 20,
    },
    textbtn: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 10,
    },
    btnContainer: {
        width: '100%',
        alignContent: 'center',
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    btnNext: {
        backgroundColor: '#59C09B',
        width: '55%',
        padding: 8,
        borderRadius: 15,
        elevation: 5,
        margin: 30,
        alignItems: 'center',
    },
    btnText: {
        color: 'white',
        fontSize: 16,
    },
});
