var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// Fr Cette page affiche l'étape 1 pour modifier une activité(FrameFigma031)
// En This page displays the first step to edit an activity(FrameFigma031)
import React, { useState, useRef } from 'react';
import { View, Text, Pressable, Platform, Modal, TouchableWithoutFeedback } from 'react-native';
import { useNavigation } from '@react-navigation/native';
//import components
import SwitchBtn from '../../../../components/ButtonSection/SwitchButton/Code';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import DatePickerModal from '../../../../components/PickerSection/DatePicker/Code';
import TimePicker from '../../../../components/PickerSection/TimePicker/Code';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
import BottomWhiteNav from '../../../../components/NavBarSection/BottomNav/BottomWhiteNav/Code';
// Importation des styles en fonction de la plateforme
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const EditClassicOrStepByStepActivityScreen = ({ onNextStep, setHeaderTitle }) => {
    var _a;
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    // const { currentActivity, setCurrentActivity, currentActivityDate, setCurrentActivityDate, currentActivitySwitch, setCurrentActivitySwitch } = useActivity();
    const { currentActivity, currentActivityDate, currentActivitySwitch } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setCurrentActivity = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivity({ id: nanoid(), value: data }));
    };
    const setCurrentActivityDate = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivityDate({ id: nanoid(), value: data }));
    };
    const setCurrentActivitySwitch = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivitySwitch({ id: nanoid(), value: data }));
    };
    const [selectedDate, setSelectedDate] = useState(null);
    const [endTime, setEndTime] = useState({ hour: 0, minute: 0 });
    const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
    const [title, setTitle] = useState(currentActivity.title);
    const [attendeesValidation, setAttendeesValidation] = useState(currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.attendeesValidation);
    const [selectedQuestion, setSelectedQuestion] = useState((currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.currentActivity) || "Classic");
    const [switchBtn, setSwitchBtn] = useState(currentActivitySwitch === null || currentActivitySwitch === void 0 ? void 0 : currentActivitySwitch.switchBtn);
    const [switchBtn1, setSwitchBtn1] = useState(currentActivitySwitch === null || currentActivitySwitch === void 0 ? void 0 : currentActivitySwitch.switchBtn1);
    const [switchBtn2, setSwitchBtn2] = useState(currentActivitySwitch === null || currentActivitySwitch === void 0 ? void 0 : currentActivitySwitch.switchBtn2);
    const [showModal, setShowModal] = useState(false);
    // const [nextDisabled, setNextDisabled] = useState<boolean>(false);
    const conditionNeedRef = useRef(true);
    const handleQuestionPress = (option) => {
        setSelectedQuestion(option);
        setHeaderTitle(option);
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { createAtivityType: option }));
    };
    //Fonction pour gérer le changement de l'option de la validation des participants
    const handleAttendeeValidationPress = (option) => {
        setAttendeesValidation(option);
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { attendeesValidation: !attendeesValidation }));
    };
    // Fonction pour gérer le changement du titre de l'activité
    const handleActivityTitleChange = (text) => {
        const trimmedText = text.slice(0, 60);
        conditionNeedRef.current = trimmedText.trim().length > 0;
        if (trimmedText.trim().length === 0) {
            setTitle('');
        }
        else {
            setTitle(trimmedText);
        }
    };
    // Fonction pour gérer le changement de la date
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { date: date.toISOString(), startTime: { hour: date.getHours(), minute: date.getMinutes() } }));
        conditionNeedRef.current = true;
        resetHeader();
    };
    //Fonction pour gérer le switch pour le temps de fin
    const handleSwitchEndTime = (state) => {
        if (state) {
            if (title) {
                conditionNeedRef.current = true;
            }
            else if (!title) {
                conditionNeedRef.current = false;
            }
        }
        else if (!state && title) {
            if (endTime.hour === 0 && endTime.minute === 0) {
                conditionNeedRef.current = false;
            }
            else {
                conditionNeedRef.current = true;
            }
        }
        setIsTimePickerOpen(true);
        let switchBtn = !state;
        setCurrentActivityDate(Object.assign(Object.assign({}, currentActivityDate), { switchBtn }));
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtn }));
    };
    //Fonction pour gérer le switch pour la localisation
    const handleSwitchLocation = (state) => {
        let addressOnlyForAttendees = !state;
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { addressOnlyForAttendees }));
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtn1: addressOnlyForAttendees }));
    };
    //Fonction pour gérer le switch pour les invitations
    const handleSwitchInvite = (state) => {
        let inviteCommunity = !state;
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { inviteCommunity }));
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtn2: inviteCommunity }));
    };
    // Fonction pour gérer le changement de l'heure de fin
    const handleEndTime = (time) => {
        setEndTime(time);
        setCurrentActivityDate(Object.assign(Object.assign({}, currentActivityDate), { endTime: { hour: time.hour, minute: time.minute }, switchBtn: switchBtn }));
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { endTime: { hour: time.hour, minute: time.minute } }));
        conditionNeedRef.current = true;
    };
    // Fonction pour gérer les erreurs
    function validateConditionNeed() {
        if (!title || title.trim().length === 0) { // Test si Title null
            setIsError(true);
            conditionNeedRef.current = false;
            showErrorInHeader(translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonTitleErrorMessage);
            return false;
        }
        else if (!currentActivity.date) { // Test si date null
            setIsError(true);
            conditionNeedRef.current = false;
            showErrorInHeader(translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonDateErrorMessage);
            return false;
        }
        else if (currentActivitySwitch.switchBtn && endTime.hour === 0 && endTime.minute === 0) { // Test si endTime null
            setIsError(true);
            conditionNeedRef.current = false;
            showErrorInHeader('error endtime');
            return false;
        }
        setIsError(false);
        conditionNeedRef.current = true;
        return true;
    }
    ;
    const navigation = useNavigation();
    //Permet de gérer l'affichage des erreurs
    const [isError, setIsError] = useState(false);
    const resetHeader = () => {
        setHeaderTitle('');
        setTimeout(() => {
            setHeaderTitle(translations.JsonActivityPages.JsonActivityManagement.JsonTitleModifyActivity);
            setIsError(false);
        }, 10);
    };
    const showErrorInHeader = (errorMessage) => {
        navigation.setOptions({
            headerTitle: () => (<Text style={{
                    fontSize: 20,
                    color: 'white',
                    fontWeight: 'bold',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                }}>
                {errorMessage}
              </Text>),
            headerStyle: { backgroundColor: 'red' },
            headerLeft: false,
        });
    };
    // Si isValid est true on peut passer à l'étape d'après
    const handleNextStep = () => {
        if (validateConditionNeed()) {
            if (currentActivityDate.switchBtn === false) {
                // Si le switch est désactivé
                let { endTime } = currentActivity, rest = __rest(currentActivity, ["endTime"]); // On enlève endTime de currentActivity
                setCurrentActivity(Object.assign({}, rest));
                let { endTime: endTime2 } = currentActivityDate, restDate = __rest(currentActivityDate, ["endTime"]); // On enlève endTime de currentActivityDate
                setCurrentActivityDate(Object.assign({}, restDate));
            }
            ;
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { title: title }));
            setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtn: switchBtn, switchBtn1: switchBtn1, switchBtn2: switchBtn2 }));
            setIsError(false);
            setHeaderTitle(`Activity ${currentActivitySwitch.butLoc}`);
            onNextStep(0, selectedQuestion); // Passer à l'étape suivante
        }
        else {
            return;
        }
    };
    const openModal = () => {
        setShowModal(true);
        setTimeout(() => {
            setShowModal(false);
            handleQuestionPress('Classic');
        }, 1500);
    };
    return (<TouchableWithoutFeedback onPress={resetHeader} disabled={!isError}>
      <View style={styles.container}>
        {!!showModal && selectedQuestion === 'Parcours' ?
            <Modal transparent={true} visible={showModal}>
            <View style={styles.modalContainer}>
              <View style={styles.modalContent}>
                <Text style={styles.modalText}> A venir...</Text>
              </View>
            </View>
          </Modal>
            : null}
          <View style={styles.text}>
            <Text style={styles.text}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonWhatKindOfActivityToCreate}
            </Text>
          </View>
          <View style={styles.questionContainer}>
            <Pressable style={() => [styles.classic, selectedQuestion === 'Classic' && styles.activeQuestion]} onPress={() => handleQuestionPress('Classic')}>
              <Text style={styles.textQuestion}> Classic </Text>
            </Pressable>
            <Pressable 
    // disabled={true}
    style={() => [styles.withStep, selectedQuestion === 'Parcours' && styles.activeQuestion]} onPress={() => { handleQuestionPress('Parcours'), openModal(); }}>
              <Text style={styles.textQuestion}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonActivitiesItinerary}
              </Text>
            </Pressable>
          </View>
          <View style={styles.switchBtn}>
            <Text style={styles.textbtn}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonActivityTitle + ' :'}
            </Text>
          </View>
          <View style={styles.input}>
            <TextInputCustomComponent placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonActivityTitlePlaceholder} handleTextChange={handleActivityTitleChange} value={title} multiline={true} numberOfLines={2}/>
          </View>
          <View style={styles.dateContainer}>
            {/* <Text>{translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonDateTime}: </Text> */}
            <DatePickerModal onDateChange={handleDateChange}/>
          </View>
          <View style={styles.switchBtn}>
            <Text style={styles.textbtn}>End Time</Text>
            <SwitchBtn state={switchBtn} setState={setSwitchBtn} callBack={handleSwitchEndTime}/>
          </View>
          {(switchBtn || (currentActivityDate === null || currentActivityDate === void 0 ? void 0 : currentActivityDate.switchBtn)) && (<View style={styles.dateContainer}>
              <Pressable onPress={() => setIsTimePickerOpen(true)}>
                <Text style={styles.textbtnEndTime}>
                  <Text style={styles.textBoldEndTime}>End Time: </Text>
                  <Text>{((_a = currentActivity.endTime) === null || _a === void 0 ? void 0 : _a.hour) ? `${currentActivity.endTime.hour}:${currentActivity.endTime.minute}` : '___: __'}</Text>
                </Text>
              </Pressable>
              <TimePicker isVisible={isTimePickerOpen} // Contrôle de la visibilité
         onClose={() => setIsTimePickerOpen(false)} // Fermer la modal
         onTimeSelected={handleEndTime} // Gérer l'heure sélectionnée
        />
            </View>)}
          <View style={styles.switchBtn}>
            <Text style={styles.textbtn}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonLocalisationOnlyForAttendees}
            </Text>
            <SwitchBtn state={switchBtn1} setState={setSwitchBtn1} callBack={handleSwitchLocation}/>
          </View>
          <View style={styles.switchBtn}>
            <Text style={styles.textbtn}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonInviteYourCommunity}
            </Text>
            <SwitchBtn state={switchBtn2} setState={setSwitchBtn2} callBack={handleSwitchInvite}/>
          </View>
          <View style={styles.text}>
            <Text style={styles.text}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidation}
            </Text>
          </View>
          <View style={styles.questionContainer}>
            <Pressable style={[styles.classic, attendeesValidation == false && styles.activeQuestion]} onPress={() => handleAttendeeValidationPress(false)}>
              <Text style={styles.textQuestion}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidationAutomatic}
              </Text>
            </Pressable>
            <Pressable onPress={() => handleAttendeeValidationPress(true)} style={[styles.withStep, attendeesValidation == true && styles.activeQuestion]}>
              <Text style={styles.textQuestion}>
                {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidationManual}
              </Text>
            </Pressable>
          </View>
        <BottomWhiteNav items={[
            { label: translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton, onPress: () => handleNextStep(), disabled: !conditionNeedRef.current },
        ]}/>
      </View>
    </TouchableWithoutFeedback>);
};
export default EditClassicOrStepByStepActivityScreen;
