//🇫🇷 Ce fichier gère le style web du composant Sponsorship.js (FrameFigma005)
//🇬🇧 This file manages the web style of the Sponsorship.js component (FrameFigma005)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'white',
    },
    title: {
        marginTop: 60,
        fontSize: 18,
        fontWeight: 'bold',
    },
    buttonContainer: {
        flexDirection: 'row',
        marginTop: 50,
        justifyContent: 'space-around',
        width: '100%',
        alignItems: 'center',
        marginVertical: '10%',
        paddingLeft: 25,
        paddingRight: 25,
    },
    containerInput: {
        marginTop: '10%',
        width: '80%',
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    footerText: {
        position: 'absolute',
        bottom: '40%',
        width: '100%',
        padding: 10,
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlignVertical: 'bottom',
        fontSize: 16,
        color: 'grey',
        textAlign: 'center',
    },
});
