var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//🇫🇷 Ce fichier gère la page où on rentre notre email, il sert à détérminé la validité de l'email et s'il existe dans la base de donnée (FrameFigma004A)
//🇬🇧 This file manages the page where we enter our email, it is used to determine the validity of the email and if it exists in the database (FrameFigma004A)
import React, { useState } from 'react';
import { View, Text, Linking, Platform, Pressable, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Yup from 'yup';
import Vectors from '../../../constants/Vectors';
import TextInputCustomComponent from '../../../components/GeneralSection/TextInputCustomComponent/Code';
import ButtonNext from '../../../components/ButtonSection/NextButton/Code';
import { useError } from '../../../contexts/ErrorContext';
import { useSelector } from 'react-redux';
import { userServices } from '../../../services/user/UserServices';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
/**
 * 🇬🇧 Regex for verification:
 *  A local part containing alphanumeric characters and some special characters.
 *  An arobase symbol (@).
 *  A top-level domain composed of alphanumeric characters.
 *  One or more second-level domains separated by periods.
 * 🇫🇷 Regex pour la vérification:
 *  Une partie locale contenant des caractères alphanumériques et certains caractères spéciaux.
 *  Un symbole arobase (@).
 *  Un domaine de premier niveau composé de caractères alphanumériques.
 *  Un ou plusieurs domaines de second niveau séparés par des points.
 */
const emailRegex = /^[\w.!#$%&'*+/=?^`{|}~-]+@\w+(\.\w+)+$/;
const EmailScreen = ({ navigation }) => {
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { error, setError } = useError();
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [hasSponsorship, setHasSponsorship] = useState(true);
    /**
     * 🇬🇧 Validation schema using Yup to ensure email format and requirement.
     * 🇫🇷 Schéma de validation utilisant Yup pour garantir le format et l'exigence de l'e-mail.
     */
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .matches(emailRegex, 'E-mail invalide')
            .required("L'e-mail est requis"),
    });
    /**
     * 🇬🇧 Handle email text changes, validate the email and set the error status to null when changes are made.
     * 🇫🇷 Gérer le changement de texte de l'e-mail, valider l'e-mail et définir l'état d'erreur comme nul des lors que l'on effectue des changement.
     */
    const handleEmailChange = (text) => {
        setEmail(text.toLowerCase());
        setError(''); // Clear error when user modifies the text
        validationSchema
            .isValid({ email: text })
            .then(valid => {
            setIsEmailValid(valid);
        })
            .catch(() => {
            setIsEmailValid(false);
        });
    };
    /**
     * 🇬🇧 Handle next button press, validate the e-mail and navigate according to the response, Sponsorship for new accounts and Password for existing accounts.
     * 🇫🇷 Gérer l'appui sur le bouton suivant, valider l'e-mail et naviguer en fonction de la réponse, Sponsorship pour les nouveaux compte et Password pour les comptes existant.
     */
    const handleNext = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            console.log("handle next", email);
            yield validationSchema.validate({ email });
            // Send a back-end request to verify the presence of the email
            const responseData = yield userServices.checkExistingEmail(email);
            // Store the email in AsyncStorage so that it can be retrieved when creating the account
            yield AsyncStorage.setItem('email', email);
            setError('');
            console.log("going to the navigation");
            if (responseData.conflict) { // Navigate directly to Password if the email exist
                navigation.navigate('Password');
            }
            else if (hasSponsorship === false) { // Navigate directly to NewUserPassword if no sponsorship
                navigation.navigate('NewUserPassword');
            }
            else {
                navigation.navigate('Sponsorship'); // Navigate to the step before NewUserPassword allowing to add a sponsorCode
            }
            setError(''); // Reset error message on success
        }
        catch (validationError) {
            console.log("the validation error ", validationError);
            setError(translations.JsonLoginRegisterPages.JsonEmailScreen.JsonEmailErrorMessage);
        }
    });
    return (<View style={styles.container}>
      {/*Error header */}
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <View style={styles.content}>
        <View style={{ alignItems: 'center' }}>
          <Text style={[styles.email]}>{translations.JsonLoginRegisterPages.JsonEmailScreen.JsonPresentation}</Text>
          <Text style={styles.subtitle}>{translations.JsonLoginRegisterPages.JsonEmailScreen.JsonCreateOrConnectAccount}</Text>
        </View>
        <View style={styles.email}>
          <TextInputCustomComponent Icon={Vectors.ProfilesArobase} placeholderText={translations.JsonLoginRegisterPages.JsonEmailScreen.JsonEmailPlaceholder} handleTextChange={handleEmailChange} value={email} isPlaceHolderTextAlignCenter={false}/>
        </View>
        <Text style={styles.forgotPassword}>{translations.JsonLoginRegisterPages.JsonEmailScreen.JsonEmailResetPassword}</Text>
        <View style={styles.sponsorship}>
          <TouchableOpacity onPress={() => setHasSponsorship(true)} style={[styles.buttonSponsorshipAccount, hasSponsorship === true && { backgroundColor: '#59C09B' }]}>
            <Text style={styles.textButtonSponsorshipAccount}>{translations.JsonLoginRegisterPages.JsonEmailScreen.JsonEmailWithSponsorship}</Text>
          </TouchableOpacity>
          <TouchableOpacity onPress={() => setHasSponsorship(false)} style={[styles.buttonNoSponsorshipAccount, hasSponsorship === false && { backgroundColor: '#59C09B' }]}>
            <Text style={styles.textButtonSponsorshipAccount}>{translations.JsonLoginRegisterPages.JsonEmailScreen.JsonEmailWithOutSponsorship}</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.btnContainer}>
          <ButtonNext handleNextStep={handleNext} btnText={translations.JsonLoginRegisterPages.JsonEmailScreen.JsonNextButton} conditionNeed={isEmailValid}/>
        </View>
        <View style={styles.condition}>
          <Text style={styles.terms}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonDisclaimer}</Text>
          <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
            <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonTermsAndConditions}</Text>
          </Pressable>
          <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
            <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPrivacyPolicy}</Text>
          </Pressable>
        </View>
      </View>
    </View>);
};
export default EmailScreen;
