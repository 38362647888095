import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    isUserIdAvailable: false,
    activitiesLoading: false,
    activities: [],
    activityView: null,
    author: null,
    activityTopicTitle: '',
    participate: false,
    liked: false,
    likeId: undefined,
    userLikedActivities: [],
    userOrganisedActivities: [],
    userCoOrganisedActivities: [],
    userAttendedActivities: [],
    networkUsers: [],
    currentActivityDate: {},
    currentActivity: {},
    currentActivitySwitch: {}
};
const ActivitiesSlice = createSlice({
    name: 'activities',
    initialState: initialState,
    reducers: {
        setStateValue: (state, action) => {
            state = Object.assign(Object.assign({}, state), action.payload.value);
        },
        setActivities: (state, action) => {
            state.activities = action.payload.value;
        },
        setNetworkUsers: (state, action) => {
            state.networkUsers = action.payload.value;
        },
        setLiked: (state, action) => {
            state.liked = action.payload.value;
        },
        setLikeId: (state, action) => {
            state.likeId = action.payload.value;
        },
        setCurrentActivity: (state, action) => {
            state.currentActivity = action.payload.value;
        },
        setActivityView: (state, action) => {
            state.activityView = action.payload.value;
        },
        setParticipate: (state, action) => {
            state.participate = action.payload.value;
        },
        setActivityTopicTitle: (state, action) => {
            state.activityTopicTitle = action.payload.value;
        },
        setAuthor: (state, action) => {
            state.author = action.payload.value;
        },
        setCurrentActivityDate: (state, action) => {
            state.currentActivityDate = action.payload.value;
        },
        setCurrentActivitySwitch: (state, action) => {
            state.currentActivitySwitch = action.payload.value;
        },
        setUserOrganisedActivities: (state, action) => {
            state.userOrganisedActivities = action.payload.value;
        },
        setUserCoOrganisedActivities: (state, action) => {
            state.userCoOrganisedActivities = action.payload.value;
        },
        createActivity: (state, action) => { },
        deleteActivity: (state, action) => { },
    }
});
export default ActivitiesSlice;
