// Fr  Styles de DuplicateClassicOrStepByStepActivityScreen( FrameFigma031 )
// En  Styles of DuplicateClassicOrStepByStepActivityScreen( FrameFigma031 )
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {},
    scrollContainer: {
        paddingLeft: 25,
        paddingRight: 25,
    },
    textTitleOne: {
        marginTop: 15,
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18,
    },
    questionContainer: {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: '5%',
    },
    classic: {
        borderWidth: 1,
        width: '40%',
        borderTopLeftRadius: 13,
        borderBottomLeftRadius: 13,
        padding: 7,
    },
    withStep: {
        borderWidth: 1,
        width: '40%',
        borderTopRightRadius: 13,
        borderBottomRightRadius: 13,
        padding: 7,
    },
    titleA: {
        fontSize: 18,
        marginVertical: 10,
        marginLeft: 10,
    },
    textQuestion: {
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        fontSize: 18,
    },
    activeQuestion: {
        backgroundColor: '#59C09B',
    },
    titleContainer: {
        fontSize: 16,
        borderWidth: 1,
        marginVertical: 20,
        borderRadius: 15,
        padding: 10,
    },
    textTitle: {
        paddingHorizontal: 15,
        backgroundColor: 'white',
        fontSize: 13,
        textAlign: 'center',
        position: 'absolute',
        zIndex: 1,
        left: 20,
        top: -10,
    },
    focusedInput: {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    dateContainer: {
        width: '100%',
        paddingVertical: 5,
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 10,
    },
    switchBtn: {
        marginVertical: 10,
        paddingVertical: 5,
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 10,
    },
    textbtn: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 10,
    },
    btnContainer: {
        marginTop: 60,
        width: '100%',
        alignItems: "center"
    },
    btnNext: {
        backgroundColor: '#59C09B',
        width: '70%',
        padding: 8,
        borderRadius: 15,
        marginHorizontal: 50,
        alignItems: 'center',
        elevation: 5,
        margin: 30,
    },
    btnText: {
        color: 'white',
        fontSize: 22,
        fontWeight: 'bold',
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
