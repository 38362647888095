import { Dimensions, StyleSheet } from "react-native";
const { height } = Dimensions.get('window');
const desiredHeight = height * 0.3; // 30vh
const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
    },
    top: {
        backgroundColor: 'white',
        width: '100%',
        height: 'auto',
        paddingBottom: '3%'
    },
    middle: {
        backgroundColor: 'white',
        width: '100%',
        height: desiredHeight,
        alignItems: 'center'
    },
    bottom: {
        backgroundColor: 'white',
        width: '100%',
        height: 'auto',
        bottom: 0,
        paddingBottom: '8%'
    },
    containerButtonBottom: {
        width: "100%",
        height: '5%',
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "green",
    },
});
export default styles;
