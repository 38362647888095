import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        elevation: 10,
    },
    modal: {
        width: 300,
        justifyContent: 'center',
        backgroundColor: 'white',
        gap: 15,
        padding: 10,
        paddingVertical: 10,
        shadowColor: 'rgba(0, 0, 0, 0.2)',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.8,
        shadowRadius: 8,
        elevation: 4, // Pour les ombres sous Android
    },
    AlignCenter: {
        alignItems: 'center',
    },
    err: {
        color: 'red',
        marginBottom: 5
    },
    deleteCancelButtonRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 15,
        marginVertical: 5,
    },
    cancelButton: {
        flex: 1,
        borderRadius: 3,
        minHeight: 42,
        borderWidth: 1,
        borderColor: '#404040',
        justifyContent: 'center',
        alignItems: 'center',
    },
    deleteButton: {
        flex: 1,
        borderRadius: 3,
        minHeight: 42,
        borderWidth: 1,
        borderColor: '#404040',
        justifyContent: 'center',
        alignItems: 'center',
    },
    TextBold: {
        fontWeight: 'bold'
    },
    buttonBigSize: {
        fontSize: 18
    },
    btnSelectedGreen: {
        backgroundColor: '#59C09B',
        borderColor: '#59C09B',
    },
    buttonTextWhite: {
        color: 'white',
    },
});
export default styles;
