var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
// Fr Cette page affiche l'étape 1 pour modifier une activité(FrameFigma031)
// En This page displays the first step to edit an activity(FrameFigma031)
import React, { useState, useRef } from 'react';
import { View, ScrollView, Text, Pressable, Platform, Modal } from 'react-native';
import { TimePickerModal } from 'react-native-paper-dates';
//import components
import SwitchBtn from '../../../../components/ButtonSection/SwitchButton/Code';
import MyButtonPress from '../../../../components/ButtonSection/PressButton/Code';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import DatePickerModal from '../../../../components/PickerSection/DatePicker/Code';
// Importation des icons
// Import des contexts
import { useError } from '../../../../contexts/ErrorContext';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
// Importation des styles en fonction de la plateforme
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const DuplicateClassicOrStepByStepActivityScreen = ({ onNextStep, setHeaderTitle }) => {
    var _a;
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { error, setError } = useError();
    // const { currentActivity, setCurrentActivity, currentActivityDate, setCurrentActivityDate, currentActivitySwitch, setCurrentActivitySwitch } = useActivity();
    const { currentActivity, currentActivityDate, currentActivitySwitch } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setCurrentActivity = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivity({ id: nanoid(), value: data }));
    };
    const setCurrentActivityDate = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivityDate({ id: nanoid(), value: data }));
    };
    const setCurrentActivitySwitch = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivitySwitch({ id: nanoid(), value: data }));
    };
    const [selectedDate, setSelectedDate] = useState(currentActivity.date);
    const [endTime, setEndTime] = useState(currentActivity.endTime);
    const [isTimePickerOpen, setIsTimePickerOpen] = useState(false);
    const [title, setTitle] = useState(currentActivity.title);
    const [attendeesValidation, setAttendeesValidation] = useState(currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.attendeesValidation);
    const [selectedQuestion, setSelectedQuestion] = useState((currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.createAtivityType) === 'classic' || (currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.createAtivityType) === 'Classic' ? 'Classic' : currentActivity === null || currentActivity === void 0 ? void 0 : currentActivity.createAtivityType);
    const [switchBtn, setSwitchBtn] = useState(currentActivitySwitch === null || currentActivitySwitch === void 0 ? void 0 : currentActivitySwitch.switchBtn);
    const [switchBtn1, setSwitchBtn1] = useState(currentActivitySwitch === null || currentActivitySwitch === void 0 ? void 0 : currentActivitySwitch.switchBtn1);
    const [switchBtn2, setSwitchBtn2] = useState(currentActivitySwitch === null || currentActivitySwitch === void 0 ? void 0 : currentActivitySwitch.switchBtn2);
    const [showModal, setShowModal] = useState(false);
    // const [nextDisabled, setNextDisabled] = useState<boolean>(false);
    const conditionNeedRef = useRef(true);
    const handleQuestionPress = (option) => {
        setSelectedQuestion(option);
        setHeaderTitle(option);
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { createAtivityType: option }));
    };
    //Fonction pour gérer le changement de l'option de la validation des participants
    const handleAttendeeValidationPress = (option) => {
        setAttendeesValidation(option);
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { attendeesValidation: !attendeesValidation }));
    };
    // Fonction pour gérer le changement du titre de l'activité
    const handleActivityTitleChange = (text) => {
        const trimmedText = text.slice(0, 60);
        conditionNeedRef.current = trimmedText.trim().length > 0;
        if (trimmedText.trim().length === 0) {
            setTitle('');
            setError(translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonTitleErrorMessage);
        }
        else {
            setError('');
            setTitle(trimmedText);
        }
    };
    // Fonction pour gérer le changement de la date
    const handleDateChange = (date) => {
        setSelectedDate(date);
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { date: date.toISOString(), startTime: { hour: date.getHours(), minute: date.getMinutes() } }));
        setError('');
        // validateVectorsOnOff();
    };
    //Fonction pour gérer le switch pour le temps de fin
    const handleSwitchEndTime = (state) => {
        setIsTimePickerOpen(true);
        let switchBtn = !state;
        setCurrentActivityDate(Object.assign(Object.assign({}, currentActivityDate), { switchBtn }));
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtn }));
        validateVectorsOnOff();
    };
    //Fonction pour gérer le switch pour la localisation
    const handleSwitchLocation = (state) => {
        let addressOnlyForAttendees = !state;
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { addressOnlyForAttendees }));
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtn1: addressOnlyForAttendees }));
    };
    //Fonction pour gérer le switch pour les invitations
    const handleSwitchInvite = (state) => {
        let inviteCommunity = !state;
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { inviteCommunity }));
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtn2: inviteCommunity }));
    };
    // Fonction pour gérer le changement de l'heure de fin
    const handleEndTime = ({ hours, minutes }) => {
        setEndTime({ hour: hours, minute: minutes });
        setCurrentActivityDate(Object.assign(Object.assign({}, currentActivityDate), { endTime: { hours, minutes }, switchBtn: switchBtn }));
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { endTime: { hour: hours, minute: minutes } }));
        validateVectorsOnOff();
        setIsTimePickerOpen(false);
    };
    const onDismissTimePicker = () => {
        if (currentActivityDate.switchBtn === true) {
            let state = false;
            setCurrentActivityDate(Object.assign(Object.assign({}, currentActivityDate), { switchBtn: state }));
            setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtn: state }));
            setSwitchBtn(state);
            setIsTimePickerOpen(false);
        }
        ;
    };
    // Cette fonction permet de vérifier les champs de texte obligatoire et d'ajouter le message d'erreur dans le setError
    function validateVectorsOnOff() {
        let errorMessage = '';
        if (!currentActivity.startTime) {
            errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonDateTimeErrorMessage;
            setError(errorMessage);
            return false;
        }
        else if (!title || title.trim().length === 0) {
            errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonTitleErrorMessage;
            setError(errorMessage);
            return false;
        }
        else if (!currentActivity.date) {
            errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonDateErrorMessage;
            setError(errorMessage);
            return false;
        }
        else if (!!currentActivitySwitch.switchBtn && currentActivityDate.endTime === null) {
            errorMessage = 'error endtime';
            return false;
        }
        setError('');
        return true;
    }
    ;
    // Si isValid est true on peut passer à l'étape d'après
    const handleNextStep = () => {
        const isValid = validateVectorsOnOff();
        if (!!isValid) {
            if (currentActivityDate.switchBtn === false) {
                // Si le switch est désactivé
                let { endTime } = currentActivity, rest = __rest(currentActivity, ["endTime"]); // On enlève endTime de currentActivity
                setCurrentActivity(Object.assign({}, rest));
                let { endTime: endTime2 } = currentActivityDate, restDate = __rest(currentActivityDate, ["endTime"]); // On enlève endTime de currentActivityDate
                setCurrentActivityDate(Object.assign({}, restDate));
            }
            ;
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { title: title }));
            setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtn: switchBtn, switchBtn1: switchBtn1, switchBtn2: switchBtn2 }));
            setError('');
            console.log('currentActivity', currentActivity);
            setHeaderTitle(`Activity ${currentActivitySwitch.butLoc}`);
            onNextStep(0, selectedQuestion); // Passer à l'étape suivante
        }
        else {
            return;
        }
    };
    const openModal = () => {
        setShowModal(true);
        setTimeout(() => {
            setShowModal(false);
            handleQuestionPress('Classic');
        }, 1500);
    };
    return (<View style={styles.container}>
      {/* Cette condition vérifie si error est non null pour afficher le message d'erreur */}
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      {!!showModal && selectedQuestion === 'Parcours' ?
            <Modal transparent={true} visible={showModal}>
          <View style={styles.modalContainer}>
            <View style={styles.modalContent}>
              <Text style={styles.modalText}> A venir...</Text>
            </View>
          </View>
        </Modal>
            : null}
      <ScrollView style={styles.scrollContainer}>
        <View style={styles.textTitleOne}>
          <Text style={styles.textTitleOne}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonWhatKindOfActivityToCreate}
          </Text>
        </View>
        <View style={styles.questionContainer}>
          <Pressable style={() => [styles.classic, selectedQuestion === 'Classic' && styles.activeQuestion]} onPress={() => handleQuestionPress('Classic')}>
            <Text style={styles.textQuestion}> Classic </Text>
          </Pressable>
          <Pressable 
    // disabled={true}
    style={() => [styles.withStep, selectedQuestion === 'Parcours' && styles.activeQuestion]} onPress={() => { handleQuestionPress('Parcours'), openModal(); }}>
            <Text style={styles.textQuestion}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonActivitiesItinerary}
            </Text>
          </Pressable>
        </View>
        <View>
          <Text style={styles.titleA}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonActivityTitle + ' :'}
          </Text>
        </View>
        <View>
          <TextInputCustomComponent placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonActivityTitlePlaceholder} handleTextChange={handleActivityTitleChange} value={title} multiline={true} numberOfLines={2}/>
        </View>
        <View style={styles.dateContainer}>
          {/* <Text>{translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonDateTime}: </Text> */}
          <DatePickerModal onDateChange={handleDateChange}/>
        </View>
        <View style={styles.switchBtn}>
          <Text style={styles.textbtn}>End Time</Text>
          <SwitchBtn state={switchBtn} setState={setSwitchBtn} callBack={handleSwitchEndTime}/>
        </View>
        {(switchBtn || (currentActivityDate === null || currentActivityDate === void 0 ? void 0 : currentActivityDate.switchBtn)) && (<View style={styles.dateContainer}>
            <Pressable onPress={() => setIsTimePickerOpen(true)}>
              <Text style={styles.textbtnEndTime}>
                <Text style={styles.textBoldEndTime}>End Time: </Text>
                <Text>{((_a = currentActivity.endTime) === null || _a === void 0 ? void 0 : _a.hour) ? `${currentActivity.endTime.hour}:${currentActivity.endTime.minute}` : '___: __'}</Text>
              </Text>
            </Pressable>

            <TimePickerModal visible={isTimePickerOpen} onDismiss={onDismissTimePicker} onConfirm={handleEndTime} hours={18} minutes={30}/>
          </View>)}
        <View style={styles.switchBtn}>
          <Text style={styles.textbtn}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonLocalisationOnlyForAttendees}
          </Text>
          <SwitchBtn state={switchBtn1} setState={setSwitchBtn1} callBack={handleSwitchLocation}/>
        </View>
        <View style={styles.switchBtn}>
          <Text style={styles.textbtn}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonMainInformationScreen.JsonInviteYourCommunity}
          </Text>
          <SwitchBtn state={switchBtn2} setState={setSwitchBtn2} callBack={handleSwitchInvite}/>
        </View>
        <View style={styles.textTitleOne}>
          <Text style={styles.textTitleOne}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidation}
          </Text>
        </View>
        <View style={styles.questionContainer}>
          <Pressable style={[styles.classic, attendeesValidation == false && styles.activeQuestion]} onPress={() => handleAttendeeValidationPress(false)}>
            <Text style={styles.textQuestion}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidationAutomatic}
            </Text>
          </Pressable>
          <Pressable onPress={() => handleAttendeeValidationPress(true)} style={[styles.withStep, attendeesValidation == true && styles.activeQuestion]}>
            <Text style={styles.textQuestion}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonActivityQuestionsScreen.JsonAttendeeValidationManual}
            </Text>
          </Pressable>
        </View>
        <View style={styles.btnContainer}>
          <MyButtonPress disabled={!conditionNeedRef.current} text={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton} txtCol={'white'} txtS={20} txtFW={'bold'} w={190} h={47} bgC={!conditionNeedRef.current ? 'grey' : '#59c09b'} br={10} cb={handleNextStep}/>
        </View>
      </ScrollView>
    </View>);
};
export default DuplicateClassicOrStepByStepActivityScreen;
