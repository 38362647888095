var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { ScrollView, View, Text, Pressable, Image, Platform } from 'react-native';
import Vectors from '../../../../constants/Vectors';
import ActivityImagePicker from '../../../../components/PickerSection/ActivityImagePicker/Code';
import { useNavigation } from '@react-navigation/native';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import Modal from '../../../../components/ModalPopUpModule/ModalSection/ActivityDetailsModal/Code';
import ImageCropperWeb from '../../../../components/ImageCropperSection/ImageActivityCrop/ImageCropperWeb';
import ImageCropperMobile from '../../../../components/ImageCropperSection/ImageActivityCrop/ImageCropperMobile';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import { useDispatch, useSelector } from 'react-redux';
import { useError } from "../../../../contexts/ErrorContext";
import { ActivityCRUD } from "../../../../services/activities/ActivityCRUD";
import ActivitiesSlice from '../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
import { ActivityServices } from '../../../../services/activities/ActivityServices';
// Importer les styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const DuplicateActivityDescriptionScreen = ({ onPrevStep, onNextStep, setHeaderTitle, resetSteps }) => {
    const navigation = useNavigation();
    const { translations } = useSelector((state) => state.language);
    const { user } = useSelector((state) => state.user);
    const { error, setError } = useError();
    // const { currentActivity, setCurrentActivity, setCurrentActivityDate, setCurrentActivitySwitch, getActivities } = useActivity();
    const { currentActivity } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setCurrentActivity = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivity({ id: nanoid(), value: data }));
    };
    const setCurrentActivityDate = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivityDate({ id: nanoid(), value: data }));
    };
    const setCurrentActivitySwitch = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivitySwitch({ id: nanoid(), value: data }));
    };
    const [modalVisible, setModalVisible] = useState(false);
    const [cropperModalVisible, setCropperModalVisible] = useState(false);
    const [activityImage, setActivityImage] = useState(currentActivity.activityImage || '');
    const [description, setDescription] = useState(currentActivity.description || '');
    const [howToFind, setHowToFind] = useState(currentActivity.howToFind || '');
    const openIllustrationModal = () => {
        setModalVisible(true);
    };
    const handleCroppedImage = (croppedImage) => {
        setActivityImage(croppedImage);
        setCropperModalVisible(false);
        setError('');
    };
    const handleActivityImagePicker = (imageUrl) => {
        setActivityImage(imageUrl);
        if (!imageUrl.file) {
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { activityImage: imageUrl }));
        }
        else {
            setCropperModalVisible(true);
        }
        setError('');
    };
    const fetchActivitiesData = () => __awaiter(void 0, void 0, void 0, function* () {
        const activitiesData = yield ActivityServices.getActivities(user._id);
        if (activitiesData) {
            dispatch(ActivitiesSlice.actions.setActivities({ id: nanoid(), value: activitiesData.activities }));
            dispatch(ActivitiesSlice.actions.setUserOrganisedActivities({ id: nanoid(), value: activitiesData.userOrganisedActivities }));
            dispatch(ActivitiesSlice.actions.setUserCoOrganisedActivities({ id: nanoid(), value: activitiesData.userCoOrganisedActivities }));
            dispatch(ActivitiesSlice.actions.setNetworkUsers({ id: nanoid(), value: activitiesData.networkUsers }));
        }
        // dispatch(ActivitiesSlice.actions.setStateValue({ id: nanoid(), value: { activities: activitiesData.activities } }));
        else
            console.log("failed to fetch activities data");
    });
    const saveActivity = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const newActivity = yield ActivityCRUD.CreateActivity(currentActivity, activityImage);
            if (newActivity) {
                yield fetchActivitiesData();
                setCurrentActivity({});
                setCurrentActivityDate({});
                setCurrentActivitySwitch({});
                navigation.navigate(Platform.OS === 'web' ? 'Activities' : 'Home');
            }
            else {
                console.error("Échec de la sauvegarde de l'activité sur le serveur");
            }
        }
        catch (error) {
            console.error("Erreur lors de la sauvegarde de l'activité:", error);
        }
    });
    const handlePrevState = () => {
        setHeaderTitle('Activity Topic');
        onPrevStep();
    };
    const handleNextStep = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!activityImage) {
            setError(translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonImageErrorMessage);
        }
        else if (!description) {
            setError(translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonDescriptionErrorMessage);
        }
        else if (!howToFind && !currentActivity.infoLine) {
            setError(translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonHowToFindMeErrorMessage);
        }
        else {
            yield saveActivity();
            onNextStep();
        }
    });
    const CropperComponent = Platform.select({
        web: () => <ImageCropperWeb file={activityImage === null || activityImage === void 0 ? void 0 : activityImage.file} handleCroppedImage={handleCroppedImage}/>,
        default: () => <ImageCropperMobile uri={activityImage === null || activityImage === void 0 ? void 0 : activityImage.file} handleCroppedImage={handleCroppedImage}/>,
    });
    const handleDescriptionText = (text) => {
        const trimmedText = text.slice(0, 7500);
        setDescription(trimmedText);
        setError('');
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { description: trimmedText }));
    };
    const handleHowToFindText = (text) => {
        const trimmedText = text.slice(0, 7500);
        setHowToFind(trimmedText);
        setError('');
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { howToFind: trimmedText }));
    };
    return (<ScrollView showsVerticalScrollIndicator={false}>
      {error && (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>)}
      <View style={styles.container}>
        <View style={styles.title}>
          <Text style={styles.title}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonExplainYourActivityMessage}
          </Text>
        </View>
        {activityImage ? (<>
            <Modal modalVisible={cropperModalVisible} onClose={() => setCropperModalVisible(false)}>
              <CropperComponent />
            </Modal>
            <Pressable onPress={openIllustrationModal}>
              <Pressable onPress={() => setCropperModalVisible(true)} style={styles.cropBtn}>
                <Text style={styles.cropBtnText}>
                  {translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonEditButton}
                </Text>
                <Vectors.ProfilesEdit />
              </Pressable>
              <Image source={{ uri: activityImage.file ? activityImage.imageUrl : activityImage }} style={styles.imageStyle}/>
            </Pressable>
          </>) : (<Pressable style={styles.choosePhoto} onPress={openIllustrationModal}>
            <View style={styles.btnChoose}>
              <View style={styles.field}>
                <Vectors.GeneralColorsUpload />
              </View>
              <Text style={styles.btnChooseText}>{translations.JsonActivityPages.JsonCreateActivityImageModal.JsonAddImageMessage}</Text>
            </View>
          </Pressable>)}
        <ActivityImagePicker modalVisible={modalVisible} setModalVisible={setModalVisible} onImageSelected={handleActivityImagePicker} selectedActivityTitle={currentActivity.activityTopicTitle}/>
        <TextInputCustomComponent placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonDescriptionPlaceholder} handleTextChange={handleDescriptionText} value={description} multiline numberOfLines={12}/>
        <TextInputCustomComponent placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonHowToFindMePlaceholder} handleTextChange={handleHowToFindText} value={howToFind} multiline numberOfLines={12}/>
        <View style={styles.btnContainer}>
          <ButtonPrevious onPrevStep={handlePrevState} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
          <ButtonNext conditionNeed={!!activityImage && description.trim().length > 0 && (currentActivity.infoLine || howToFind.trim().length > 0)} handleNextStep={handleNextStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPublishButton}/>
        </View>
      </View>
    </ScrollView>);
};
export default DuplicateActivityDescriptionScreen;
