var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// 🇫🇷 Selectionner le language de l'application (FrameFigma002)
// 🇬🇧 Select the application language (FrameFigma002)
import React, { useEffect, useState, useCallback } from 'react';
import { FlatList, Platform, Text, TextInput, TouchableOpacity, View, Dimensions, ImageBackground } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import countriesListCircleFlags from '../../../components/GeneralSection/LanguagesFlagsComponent/LanguagesFlags';
import Vectors from '../../../constants/Vectors';
import { useSelector, useDispatch } from 'react-redux';
import LanguageSlice from '../../../redux/Features/Language/LanguagesSlice';
import { nanoid } from '@reduxjs/toolkit';
import HomePageBR2 from '../../../assets/Images/Home/HomePageBR2.png';
//🇬🇧 Conditionally loads styles based on the platform (web or mobile)
//🇫🇷 Conditionnellement charge les styles en fonction de la plateforme (web ou mobile)
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const LanguagesScreen = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState(countriesListCircleFlags);
    const navigation = useNavigation();
    const dispatch = useDispatch();
    const [isFocused, setIsFocused] = useState(false);
    const [numColumns, setNumColumns] = useState(3);
    // Récupération des dimensions de l'écran
    const { width } = Dimensions.get('window');
    // Adaptation du nombre de colonnes selon la taille de l'écran
    const updateLayout = () => {
        const newWidth = Dimensions.get('window').width; // Obtenir la largeur actuelle
        if (newWidth > 1024) {
            setNumColumns(9); // 9 colonnes pour grand écran
        }
        else if (newWidth > 768) {
            setNumColumns(6); // 6 colonnes pour tablette
        }
        else {
            setNumColumns(3); // 3 colonnes pour mobile
        }
    };
    const resetRootStyles = () => {
        if (Platform.OS !== 'web')
            return;
        const rootElement = document.getElementById('root');
        if (rootElement)
            rootElement.style.width = '450px';
    };
    const applyConditionalStyles = () => {
        if (Platform.OS !== 'web')
            return;
        const rootElement = document.getElementById('root');
        if (!rootElement)
            return;
        if (Dimensions.get('window').width <= 450) {
            rootElement.style.width = '100%';
        }
        else {
            rootElement.style.width = '1900px';
        }
    };
    useEffect(useCallback(() => {
        // Apply styles when the screen is focused
        applyConditionalStyles();
        updateLayout(); // (FR) Met à jour le nombre de colonnes à l'initialisation - (EN) Updates the number of columns at initialisation
        // Handle window resize
        const handleResize = () => {
            applyConditionalStyles();
            updateLayout(); // (FR) Met à jour le nombre de colonnes lors du redimensionnement - (EN) Updates the number of columns when resizing
        };
        Platform.OS === 'web' && window.addEventListener('resize', handleResize);
        // Cleanup when the screen is unfocused
        return () => {
            Platform.OS === 'web' && window.removeEventListener('resize', handleResize);
            resetRootStyles();
        };
    }, []));
    const sizeIcon = Math.max(Math.min(width / numColumns * 0.9, 100), 40);
    const handleLanguageSelect = (bigram, language) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield AsyncStorage.setItem('selectedLanguage', bigram);
            yield AsyncStorage.setItem('selectedLanguageName', language);
            // await changeLanguage(bigram); // Met à jour le contexte de langue
            dispatch(LanguageSlice.actions.changeLanguage({ id: nanoid(), value: bigram }));
            navigation.navigate('Start'); // Assurez-vous que 'Start' est le nom correct de votre écran
        }
        catch (e) {
            console.error('Erreur lors de la sauvegarde de la langue', e);
        }
    });
    useEffect(() => {
        const filtered = countriesListCircleFlags.filter(item => item.language.toLowerCase().includes(searchQuery.toLowerCase()));
        setFilteredData(filtered);
    }, [searchQuery]);
    const renderItem = ({ item }) => (<TouchableOpacity onPress={() => handleLanguageSelect(item.bigram, item.language)} style={styles.itemContainer}>
      <item.FlagComponent width={sizeIcon} height={sizeIcon}/>  
      {/*Calcul de la taille des drapeaux en fonction de l'écran */}
      <Text style={styles.languageText}>{item.language}</Text>
    </TouchableOpacity>);
    //const lg=useSelector((state: RootState)=>state.counter);
    //console.log("the object language ", lg);
    //🇬🇧 Overall rendering of the component, with a title, a search field, and a FlatList to display the languages
    //🇫🇷 Rendu global du composant, avec un titre, un champ de recherche, et une FlatList pour afficher les langue
    return (<View style={{ flex: 1 }}> 
      {numColumns === 9 ? (<ImageBackground source={HomePageBR2} style={[styles.container, { width: '100%', height: '100%', opacity: 0.95 }]} resizeMode="cover">
          <View style={styles.overlay}/>
          <View>
            <Text style={styles.title}>{translations.JsonHomeScreen.JsonSelectLanguage}</Text>
          </View>
          <View style={styles.searchInput}>
            <View style={styles.GeneralBlackAndWhiteLoop}>
              <Vectors.GeneralBlackAndWhiteLoop />
            </View>
            <TextInput placeholder={translations.JsonHomeScreen.JsonSelectLanguage} onChangeText={setSearchQuery} value={searchQuery} style={[isFocused && styles.focusedInput2]} placeholderTextColor="#ADADAD" onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}/>
          </View>
          <FlatList data={filteredData} renderItem={renderItem} keyExtractor={(_item, index) => 'key' + index} numColumns={numColumns} // Nombre de colonnes réactif
         key={`flatlist-${numColumns}-${width}`} // Clé dynamique pour forcer le re-rendu
         contentContainerStyle={styles.listContentContainer} columnWrapperStyle={styles.columnWrapper} showsVerticalScrollIndicator={false} style={styles.flatList}/>
        </ImageBackground>) : (<View style={[styles.container, { backgroundColor: '#2C2C46' }]}>
          <View>
            <Text style={styles.title}>{translations.JsonHomeScreen.JsonSelectLanguage}</Text>
          </View>
          <View style={styles.searchInput}>
            <View style={styles.GeneralBlackAndWhiteLoop}>
              <Vectors.GeneralBlackAndWhiteLoop />
            </View>
            <TextInput placeholder={translations.JsonHomeScreen.JsonSelectLanguage} onChangeText={setSearchQuery} value={searchQuery} style={[isFocused && styles.focusedInput2]} placeholderTextColor="#ADADAD" onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)}/>
          </View>
          <FlatList data={filteredData} renderItem={renderItem} keyExtractor={(_item, index) => 'key' + index} numColumns={numColumns} // Nombre de colonnes réactif
         key={`flatlist-${numColumns}-${width}`} // Clé dynamique pour forcer le re-rendu
         contentContainerStyle={styles.listContentContainer} columnWrapperStyle={styles.columnWrapper} showsVerticalScrollIndicator={false} style={styles.flatList}/>
        </View>)}
      
    </View>);
};
export default LanguagesScreen;
