var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// 🇫🇷 Ce fichier gère la page où on rentre notre numéro de téléphone (FrameFigma008)
// 🇬🇧 This file manages the page where we enter our phone number (FrameFigma008).
// ! NON IMPLEMENTER dans le back-end
import React, { useRef, useState, useEffect } from 'react';
import { View, Text, Platform, TextInput, TouchableOpacity, Alert } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Yup from 'yup';
import Clipboard from '@react-native-clipboard/clipboard';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import { useError } from '../../../../contexts/ErrorContext';
import Vectors from '../../../../constants/Vectors';
import { hostname } from '../../../../../hostName/hostName';
import { useDispatch, useSelector } from 'react-redux';
import UserSlice from '../../../../redux/Features/User/UserSlice';
import { nanoid } from '@reduxjs/toolkit';
import AuthSlice from '../../../../redux/Features/Auth/AuthSlice';
import { setToken } from '../../../../services/Axios/AxiosService';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const VerifyPhoneExistingUserScreen = ({ navigation, route }) => {
    // 🇬🇧 Using various contexts and extracting values
    // 🇫🇷 Utilisation de différents contextes et extraction des valeurs
    const { translations } = useSelector((state) => state.language);
    const { error, setError } = useError();
    const { newPassword } = route.params;
    const dispatch = useDispatch();
    const updateUserState = (loginData) => {
        dispatch(UserSlice.actions.updateUser({ id: nanoid(), value: loginData }));
    };
    const login = (token, userData) => {
        dispatch(AuthSlice.actions.login({ id: nanoid(), value: { token, userData } }));
        setToken(token);
    };
    // 🇬🇧 State declarations
    // 🇫🇷 Déclarations d'état
    const [phoneNumber, setPhoneNumber] = useState('');
    const [resendDisabled, setResendDisabled] = useState(false);
    const pinRefs = useRef(Array(6).fill(null).map(() => React.createRef()));
    const [pins, setPins] = useState(Array(6).fill(''));
    const [countdown, setCountdown] = useState(180);
    // 🇬🇧 Effect to fetch phone number from storage on component mount
    // 🇫🇷 Effet pour récupérer le numéro de téléphone du stockage au montage du composant
    useEffect(() => {
        getPhoneNumberFromStorage();
    }, []);
    // 🇬🇧 Effect to handle countdown timer for resending verification code
    // 🇫🇷 Effet pour gérer le compte à rebours pour le renvoi du code de vérification, pour le téléphone le temps d'attente doit être d'une journée
    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown === 0) {
                    clearInterval(timer);
                    setResendDisabled(false);
                }
                return prevCountdown === 0 ? 0 : prevCountdown - 1;
            });
        }, 1000);
        return () => clearInterval(timer);
    }, [countdown]);
    // 🇬🇧 Yup schema for verification code validation
    // 🇫🇷 Schéma Yup pour la validation du code de vérification
    const verificationSchema = Yup.object().shape({
        verificationCode: Yup.string()
            .matches(/^[0-9]+$/, translations.JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonPhoneNotValid)
            .length(6, translations.JsonLoginRegisterPages.JsonVerificationCode.JsonShortMessage)
            .required(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonEnterVerificationCode),
    });
    // 🇬🇧 Function to retrieve phone number from storage
    // 🇫🇷 Fonction pour récupérer le numéro de téléphone du stockage
    const getPhoneNumberFromStorage = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const storedPhoneNumber = yield AsyncStorage.getItem('phoneNumber');
            if (storedPhoneNumber) {
                setPhoneNumber(storedPhoneNumber);
            }
        }
        catch (error) {
            console.error("Erreur lors de la récupération du numéro de téléphone depuis le AsyncStorage", error);
        }
    });
    // 🇬🇧 Function to format time for countdown display
    // 🇫🇷 Fonction pour formater le temps pour l'affichage du compte à rebours, doit etre d'une journée
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    // 🇬🇧 Function to send verification SMS
    // 🇫🇷 Fonction pour envoyer le SMS de vérification, a refaire car non implementer dans le back-end
    const sendVerificationSMS = () => __awaiter(void 0, void 0, void 0, function* () {
        setResendDisabled(true);
        setCountdown(180);
        try {
            const response = yield fetch(`${hostname}/sendVerificationExistingUserPhone`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber }),
            });
            if (response.ok) {
                //console.log('Verification SMS sent successfully');
            }
            else {
                console.error('Failed to send verification SMS');
            }
        }
        catch (error) {
            console.error("Erreur lors de l'envoi du SMS de vérification", error);
        }
    });
    // 🇬🇧 Function to handle verification process
    // 🇫🇷 Fonction pour gérer le processus de vérification
    const handleVerification = () => __awaiter(void 0, void 0, void 0, function* () {
        const verificationCode = pins.join('');
        if (!verificationCode) {
            setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonEnterVerificationCode);
            return;
        }
        try {
            yield verificationSchema.validate({ verificationCode });
            const response = yield fetch(`${hostname}/verifyPhoneExistingUser`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ verificationCode }),
            });
            if (response.ok) {
                const responseData = yield response.json();
                if (responseData.success) {
                    yield updatePassword(phoneNumber, newPassword);
                    yield AsyncStorage.setItem('authToken', responseData.authToken);
                    yield AsyncStorage.setItem('userData', JSON.stringify(responseData.user));
                    yield AsyncStorage.setItem('userId', responseData.user._id);
                    yield AsyncStorage.setItem('userEmail', responseData.user.email);
                    yield AsyncStorage.setItem('profileImage', responseData.user.profileImage);
                    yield AsyncStorage.setItem('userName', responseData.user.userName);
                    login(responseData.authToken, responseData.user);
                    updateUserState(responseData.user);
                    navigation.navigate('Activities', { userId: responseData.user._id });
                    setError('');
                }
                else {
                    setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonCodeError);
                }
            }
            else {
                setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonCodeError);
            }
        }
        catch (error) {
            console.error('Error during verification process:', error);
            setError(error.message);
        }
    });
    // 🇬🇧 Function to update password
    // 🇫🇷 Fonction pour mettre à jour le mot de passe
    const updatePassword = (phoneNumber, newPassword) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield fetch(`${hostname}/reset-password`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ phoneNumber, newPassword }),
            });
            const responseData = yield response.json();
            //console.log('Password update response:', responseData);
        }
        catch (error) {
            console.error('Error updating password:', error);
        }
    });
    // 🇬🇧 Function to handle pasting verification code
    // 🇫🇷 Fonction pour gérer le collage du code de vérification
    const handlePasteText = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const text = yield Clipboard.getString();
            const filteredText = text.replace(/[^\d]/g, '');
            const textArray = filteredText.slice(0, 6).split('');
            setPins(pins.map((_, index) => textArray[index] || ''));
        }
        catch (error) {
            console.error('Error pasting text:', error);
            Alert.alert('Error', 'Failed to paste text');
        }
    });
    // 🇬🇧 Function to handle text change in PIN inputs
    // 🇫🇷 Fonction pour gérer le changement de texte dans les entrées PIN
    const handleTextChange = (index, value) => {
        if (/^\d*$/.test(value)) {
            const newPins = [...pins];
            newPins[index] = value;
            setPins(newPins);
            setError('');
            if (value && index < 5) {
                pinRefs.current[index + 1].focus();
            }
            else if (!value && index > 0) {
                pinRefs.current[index - 1].focus();
            }
        }
        else {
            setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonOnlyNumbersAllowed);
        }
    };
    return (<View style={styles.verification}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <View style={styles.container}>
        <Text style={styles.verificationText}>Enter the verification code which you have received on your phone</Text>
        <TouchableOpacity style={styles.pasteContainer} onPress={handlePasteText}>
          <Vectors.Paste style={styles.paste}/>
        </TouchableOpacity>
        <View style={styles.inputs}>
          {pins.map((pin, index) => (<TextInput key={index} ref={ref => (pinRefs.current[index] = ref)} style={[styles.opt, error && styles.optError]} keyboardType={'number-pad'} maxLength={1} value={pin} onChangeText={value => handleTextChange(index, value)}/>))}
        </View>
        <View style={styles.btnContainer}>
          <ButtonPrevious onPrevStep={() => navigation.navigate('PhoneUserScreen')} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
          <ButtonNext handleNextStep={handleVerification} btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton} conditionNeed={pins.join('').length === 6}/>
        </View>
        <View style={styles.resend}>
          <Text style={styles.resendText}>Didn’t received the verification code ?( please try by email, text message only available once by day)</Text>
        </View>
        <TouchableOpacity style={styles.btnResendContainer} onPress={() => navigation.navigate('VerifyEmailExistingUserScreen', { newPassword: newPassword })}>
          <Text style={styles.btnTextResend}>{'Resend to email'}</Text>
        </TouchableOpacity>
      </View>
    </View>);
};
export default VerifyPhoneExistingUserScreen;
