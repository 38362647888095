import React from 'react';
import { View, Text, Image, Platform, Pressable } from 'react-native';
import { hostname } from '../../../../hostName/hostName';
import { useNavigation } from '@react-navigation/native';
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
const UserCardHorizontal = ({ user, prop1, prop2 }) => {
    if (!user) {
        return <Text>Utilisateur introuvable</Text>;
    }
    const navigation = useNavigation();
    const imageUrl = user.user.profileImage
        ? user.user.profileImage
        : `${hostname}/uploads/${user.user.profileImage}`;
    return (<View style={styles.card}>
            <Pressable onPress={() => navigation.navigate('ProfileScreen', { userId: user.user._id })}>
                {imageUrl && (<Image source={{ uri: imageUrl }} style={[styles.profileImage, user.isFriend ? styles.profileImageFriend : styles.profileImage]}/>)}
            </Pressable>
            <Text style={user.user.sexe === "Male" ? styles.nameMale : styles.nameFemale}>
                {user.user.userName.length > 14 ? user.user.userName.substring(0, 11) + '...' : user.user.userName}
            </Text>
        </View>);
};
export default UserCardHorizontal;
