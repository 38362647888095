// FR gère un composant de fenêtre modale de chat (FrameFigma025A,025,025B)
// EN This script manages a chat modal component (FrameFigma025A,025,025B)
import React, { createContext, useContext, useState } from 'react';
const ActivityModalContext = createContext(undefined);
export const useActivityModal = () => {
    const context = useContext(ActivityModalContext);
    if (!context) {
        throw new Error('useError must be used within a ErrorProvider');
    }
    return context;
};
;
export const ActivityModalProvider = ({ children }) => {
    const [isActivityModalVisible, setIsActivityModalVisible] = useState(false);
    const [chatDetails, setChatDetails] = useState({
        chatId: null,
        userId: null,
        currentUser: null,
        privateMessages: {},
    });
    const openActivityModal = (chatId, userId, currentUser, privateMessages) => {
        console.log('Modal opening with chatId:', chatId, 'and messages:', privateMessages);
        console.log('Modal will be visible:', true);
        setChatDetails({ chatId, userId, currentUser, privateMessages });
        setIsActivityModalVisible(true);
    };
    const closeActivityModal = (onCloseCallback) => {
        setIsActivityModalVisible(false);
        setChatDetails({ chatId: null, userId: null, currentUser: null, privateMessages: {} });
        if (onCloseCallback) {
            onCloseCallback(); // Exécute la fonction de rappel après avoir fermé le modal
        }
    };
    const [activityModalOpen, setActivityModalOpen] = useState(false);
    return <ActivityModalContext.Provider value={{
            isActivityModalVisible,
            chatDetails,
            openActivityModal,
            closeActivityModal,
            activityModalOpen,
            setActivityModalOpen
        }}>
    {children}
  </ActivityModalContext.Provider>;
};
