// Fr  Styles VipBadgeAdvantagesRubyScreen( FrameFigma052B )
// En   Styles VipBadgeAdvantagesRubyScreen( FrameFigma052B )
import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },
    titleContainer: {
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '5%',
        marginBottom: '5%',
    },
    title: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 13,
    },
    card: {
        backgroundColor: 'white',
        flexDirection: 'row',
        borderRadius: 40,
        marginTop: '5%',
        marginBottom: '5%',
        width: '90%',
        height: '55%',
        position: 'relative',
        overflow: 'hidden',
        // iOS boxShadow
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowOffset: { width: 0, height: 8 },
        shadowOpacity: 0.5,
        shadowRadius: 10,
        // Android boxShadow
        elevation: 10,
    },
    backgroundImage: {
        position: 'absolute',
        marginLeft: '-40%',
        marginTop: '-5%',
        width: '140%',
        height: '138%',
        opacity: 0.9,
    },
    rubyTitle: {
        fontWeight: 'bold',
        fontSize: 24,
        color: '#E84857',
        textAlign: 'center',
    },
    separatorR: {
        borderTopWidth: 2,
        borderTopColor: '#E84857',
        width: '70%',
        marginTop: '3%',
        marginBottom: '12%',
    },
    textContainer: {
        alignItems: 'center',
        marginBottom: '10%',
    },
    cardText: {
        fontWeight: 'bold',
        alignItems: 'center',
        marginBottom: '3%',
    },
    img: {
        width: 150,
        height: 150,
        position: 'relative',
        marginBottom: '-5%',
        marginTop: '-10%',
        marginLeft: '-5%',
    },
    buttonGrey: {
        flexDirection: 'row',
        borderColor: '#fff',
        borderRadius: 10,
        height: '12%',
        width: '60%',
        overflow: 'hidden',
        // iOS boxShadow
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowOffset: { width: 0, height: 8 },
        shadowOpacity: 0.5,
        shadowRadius: 10,
        // Android boxShadow
        elevation: 10,
        backgroundColor: '#505050',
        justifyContent: 'center',
        alignItems: 'center',
    },
    imgsmall: {
        width: 75,
        height: 75,
        position: 'relative',
        marginLeft: '-15%',
        marginTop: '-5%',
        marginRight: '-5%',
    },
    textbutton: {
        fontWeight: 'bold',
        color: 'white',
        fontSize: 18,
    },
    buttonInvite: {
        borderColor: '#fff',
        borderRadius: 10,
        height: '5%',
        width: '50%',
        overflow: 'hidden',
        // iOS boxShadow
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowOffset: { width: 0, height: 8 },
        shadowOpacity: 0.5,
        shadowRadius: 10,
        // Android boxShadow
        elevation: 10,
        backgroundColor: '#59C09B',
        justifyContent: 'center',
        alignItems: 'center',
    },
});
export default styles;
