var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useState, useRef } from 'react';
import { View, Platform, Text, TouchableOpacity, ActivityIndicator, SectionList } from 'react-native';
// import { useFocusEffect } from '@react-navigation/native';
// import components
import ActivityCard from '../../../components/CardSection/ActivityCard/Code';
// import contexts
// import sevrices
// import Vectors Icons
import { Vectors } from '../../../constants/Vectors';
import { useFocusEffect } from '@react-navigation/native';
import { formatDateWithDay } from '../../../utils/Functions/functions';
import { useDispatch, useSelector } from 'react-redux';
import { userServices } from '../../../services/user/UserServices';
import UserSlice from '../../../redux/Features/User/UserSlice';
import { nanoid } from '@reduxjs/toolkit';
import { ActivityServices } from '../../../services/activities/ActivityServices';
import ActivitiesSlice from '../../../redux/Features/Activities/AtivitiesSlice';
// import styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const ActivitiesScreen = ({ navigation }) => {
    // const { activities, getActivities } = useActivity();
    // const { user, allUsers, getAllUsers } = useUser();
    const { user, allUsers } = useSelector((state) => state.user);
    const { activities } = useSelector((state) => state.activities);
    const { getAllUsers } = userServices;
    const { getActivities } = ActivityServices;
    const dispatch = useDispatch();
    // const { language, translations } = useLanguage();
    const { language, translations } = useSelector((state) => state.language);
    const [loading, setLoading] = useState(true);
    const [currentTopSectionIndex, setCurrentTopSectionIndex] = useState(0);
    // const [userId, setUserId] = useState<any>(null);
    // const [visibleDate, setVisibleDate] = useState(null);
    const [isScrolling, setIsScrolling] = useState(false);
    // console.log("the user from the slice ", user)
    const sectionListRef = useRef(null);
    useFocusEffect(useCallback(() => {
        fetchActivitiesData();
        if (!allUsers.length)
            fetchAllUser();
        setLoading(false);
    }, []));
    const fetchAllUser = () => __awaiter(void 0, void 0, void 0, function* () {
        // console.log("starting fetch all user", userToken);
        let all = yield getAllUsers();
        // console.log("the result of fetch all user ", all);
        if (all)
            dispatch(UserSlice.actions.setAllUsers({ id: nanoid(), value: all }));
    });
    const fetchActivitiesData = () => __awaiter(void 0, void 0, void 0, function* () {
        const activitiesData = yield getActivities(user._id);
        if (activitiesData) {
            dispatch(ActivitiesSlice.actions.setActivities({ id: nanoid(), value: activitiesData.activities }));
            dispatch(ActivitiesSlice.actions.setUserOrganisedActivities({ id: nanoid(), value: activitiesData.userOrganisedActivities }));
            dispatch(ActivitiesSlice.actions.setUserCoOrganisedActivities({ id: nanoid(), value: activitiesData.userCoOrganisedActivities }));
            dispatch(ActivitiesSlice.actions.setNetworkUsers({ id: nanoid(), value: activitiesData.networkUsers }));
        }
        // dispatch(ActivitiesSlice.actions.setStateValue({ id: nanoid(), value: { activities: activitiesData.activities } }));
        else
            console.log("failed to fetch activities data");
    });
    const numberUsersConnect = allUsers.filter((user) => user.status !== 'offline').length;
    const filteredActivities = activities.filter((activity) => {
        const activityDate = new Date(activity.date);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return activityDate >= today && !activity.unApprovedUsers.includes(user._id);
    });
    const groupedActivities = [];
    if (filteredActivities.length > 0) {
        const sortedActivities = filteredActivities.sort((a, b) => new Date(a.date).valueOf() - new Date(b.date).valueOf());
        const activitiesByDate = sortedActivities.reduce((acc, activity) => {
            const date = formatDateWithDay(activity.date, language);
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(activity);
            return acc;
        }, {});
        let index = 0;
        for (const [date, activities] of Object.entries(activitiesByDate)) {
            groupedActivities.push({ date, data: activities, index });
            index++;
        }
    }
    const handleScrolling = ({ nativeEvent }) => {
        if (nativeEvent.contentOffset.y > 0) {
            setIsScrolling(true);
        }
        else {
            setIsScrolling(false);
        }
    };
    const renderSectionHeader = ({ section }) => (<View style={styles.DateAndIconFilter}>
      <View style={styles.dateContainer}>
        <Vectors.Calendar width={25} height={25}/>
        <Text style={[
            styles.DisplayDate,
            {
                color: isScrolling && section.index === currentTopSectionIndex
                    ? '#F48A0D'
                    : !isScrolling && section.index === 0
                        ? '#F48A0D'
                        : 'black',
            }
        ]}>
          {section.date}
        </Text>
      </View>
      {!isScrolling && section.index === 0 && (<View style={styles.button}>
          <Vectors.GeneralBlackAndWhiteFilter width={25} height={25} color='white'/>
          <Text style={styles.text}>Filters</Text>
        </View>)}
    </View>);
    const renderItem = ({ item }) => (<View>
      <ActivityCard key={item._id} activity={item} userId={user._id}/>
    </View>);
    const file = "file:///data/user/0/com.frontend/cache/rn_image_picker_lib_temp_bf1b8da5-fbc6-4db7-95d5-f62aff19faff.jpg";
    return (<View style={styles.container}>
      {loading && (!(user === null || user === void 0 ? void 0 : user._id) || !activities) ? <ActivityIndicator size={"large"}/> :
            <>
          {!isScrolling && (<Text style={styles.centeredText}>
              {`${allUsers.length} ${translations.JsonActivityPages.JsonActivityScreen.JsonMembers} ${numberUsersConnect} ${translations.JsonHomeScreen.JsonOnline}`}
            </Text>)}
          <SectionList ref={sectionListRef} sections={groupedActivities} keyExtractor={(item, index) => item._id + index} renderItem={renderItem} renderSectionHeader={renderSectionHeader} stickySectionHeadersEnabled showsVerticalScrollIndicator={false} onScroll={handleScrolling} onViewableItemsChanged={({ viewableItems }) => {
                    const topSection = viewableItems.find(item => item.index === 0 && item.section);
                    if (topSection) {
                        setCurrentTopSectionIndex(topSection.section.index);
                        console.log(topSection.section.index);
                    }
                }} viewabilityConfig={{
                    itemVisiblePercentThreshold: 50,
                }} contentContainerStyle={{ width: '100%' }}/>
          <TouchableOpacity style={styles.fab} onPress={() => navigation.navigate("CreateActivity")}>
            <View>
              <Vectors.Plus width={40} height={40}/>
            </View>
          </TouchableOpacity>
        </>}
    </View>);
};
export default ActivitiesScreen;
