import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity, Platform, ScrollView, SafeAreaView } from 'react-native';
import Vectors from '../../../../../constants/Vectors';
// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const Drink = () => {
    const [isFull, setIsFull] = useState(true); // État initial, le verre est plein
    const [timerActive, setTimerActive] = useState(false); // État pour activer/désactiver le minuteur
    const [timeRemaining, setTimeRemaining] = useState({ hours: 1, minutes: 0, seconds: 0 }); // Temps restant du minuteur
    const toggleGlass = () => {
        if (!timerActive) {
            setIsFull(!isFull); // Inverser l'état du verre (plein/vide)
            setTimerActive(true); // Activer le minuteur
        }
    };
    useEffect(() => {
        let timer;
        if (timerActive) {
            // Démarre le minuteur
            timer = setInterval(() => {
                setTimeRemaining(prevTime => {
                    if (prevTime.seconds > 0) {
                        return Object.assign(Object.assign({}, prevTime), { seconds: prevTime.seconds - 1 });
                    }
                    else if (prevTime.minutes > 0) {
                        return Object.assign(Object.assign({}, prevTime), { minutes: prevTime.minutes - 1, seconds: 59 });
                    }
                    else if (prevTime.hours > 0) {
                        return Object.assign(Object.assign({}, prevTime), { hours: prevTime.hours - 1, minutes: 59, seconds: 59 });
                    }
                    else {
                        setTimerActive(false); // Désactiver le minuteur
                        setIsFull(true); // Après le minuteur, le verre redevient plein
                        return { hours: 0, minutes: 0, seconds: 0 };
                    }
                });
            }, 1000); // Répéter toutes les secondes (1000 ms = 1 seconde)
        }
        return () => clearInterval(timer); // Nettoyer le timer à chaque démontage
    }, [timerActive]);
    // Formater le temps restant pour l'affichage
    const formatTime = () => {
        const { hours, minutes, seconds } = timeRemaining;
        return `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}min${seconds.toString().padStart(2, '0')}s`;
    };
    return (<SafeAreaView style={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View style={styles.container}>
            {!timerActive && <Text style={styles.text}>Harry Up</Text>}
            {timerActive && <Text style={styles.text}>Time remaining</Text>}
            {timerActive && (<Text style={styles.timerText}>{formatTime()}</Text>)}
            <TouchableOpacity onPress={toggleGlass} disabled={!isFull}>
              {isFull ? <Vectors.FullDrink /> : <Vectors.EmptyDrink />}
            </TouchableOpacity>
            <Text style={styles.text}>{isFull ? "Hi, Barman hit the shot and give me one" : "No free drink left in this pub."}</Text>
          </View>
        </ScrollView>
      </SafeAreaView>);
};
export default Drink;
