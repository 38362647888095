// Fr Cette page affiche l'étape 2 du formulaire pour créer une activité de type VIP(FrameFigma039)
// En This page displays the second step of the form to create an activity of type VIP (FrameFigma039)
import React, { useEffect, useState } from 'react';
import { Platform, Pressable, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import SwitchBtn from '../../../../components/ButtonSection/SwitchButton/Code';
import SliderComponent from '../../../../components/GeneralSection/SliderComponent/SliderComponent';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import Modal from '../../../../components/ModalPopUpModule/ModalSection/ActivityDetailsModal/Code';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const DuplicateActivityPremiumFeaturesScreen = ({ onNextStep, onPrevStep, setHeaderTitle, rolePremium }) => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    // const {user} = useUser();
    const { user } = useSelector((state) => state.user);
    // var pour afficher le pop up ComingSoon
    const [modalVisible, setModalVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [switchBtnOption, setSwitchBtnOption] = useState(false);
    const [repeatEvent, setRepeatEvent] = useState(false);
    const [manageParity, setManageParity] = useState(false);
    const [guestWithFriends, setGuestWithFriends] = useState(false);
    const [ageRestriction, setAgeRestriction] = useState(false);
    const [sliderSexeValue, setSliderSexeValue] = useState(50);
    const [sliderFriendValue, setSliderFriendValue] = useState(3);
    const [sliderAgeValue, setSliderAgeValue] = useState([18, 99]);
    const [formData, setFormData] = useState({
        optionalselected: [],
        repeatChoice: '',
        repeatDays: [],
        endDate: '',
        infoLine: '',
    });
    //  a modifier plus tard
    const optionalFeatures = [
        { id: 'parity', text: '50% Guys 50% girls' },
        { id: 'girls', text: 'Girls only' },
        { id: 'guys', text: 'Guys only' },
        { id: 'friendsOnly', text: 'Event visible for friends only' },
        { id: 'PhoneVisible', text: 'Phone number visble to attendees' },
    ];
    const days = [
        { id: 1, text: 'Monday' },
        { id: 2, text: 'Tuesday' },
        { id: 3, text: 'Wednesday' },
        { id: 4, text: 'Thursday' },
        { id: 5, text: 'Friday' },
        { id: 6, text: 'Saturday' },
        { id: 7, text: 'Sunday' },
    ];
    const handleOptionPress = (btn) => {
        setFormData((prevFormData) => (Object.assign(Object.assign({}, prevFormData), { optionalselected: prevFormData.optionalselected.includes(btn.id)
                ? prevFormData.optionalselected.filter((id) => id !== btn.id)
                : [...prevFormData.optionalselected, btn.id] })));
    };
    const handleDayPress = (dayId) => {
        setFormData((prevFormData) => (Object.assign(Object.assign({}, prevFormData), { repeatDays: prevFormData.repeatDays.includes(dayId)
                ? prevFormData.repeatDays.filter((id) => id !== dayId)
                : [...prevFormData.repeatDays, dayId] })));
    };
    const handleStringChange = (key, value) => {
        setFormData(prevFormData => (Object.assign(Object.assign({}, prevFormData), { [key]: value })));
    };
    useEffect(() => {
        setHeaderTitle('Premium features');
    }, []);
    const featureRequirements = {
        'switchBtnOption': 'diamondStarUser',
        'repeatEvent': 'emeraldStarUser',
        'manageParity': 'diamondStarUser',
        'guestWithFriends': 'goldStarUser',
        'ageRestriction': 'goldStarUser',
    };
    const switchBtnFunction = (feature, setState) => {
        const requiredRole = featureRequirements[feature];
        if (rolePremium !== requiredRole) {
            let modalMessage;
            if (rolePremium === 'noStarUser' && (requiredRole === 'goldStarUser' || requiredRole === 'emeraldStarUser')) {
                modalMessage = `This feature will be available for VIP ${requiredRole} members or more!`;
            }
            else if (rolePremium === 'goldStarUser' && requiredRole === 'emeraldStarUser') {
                modalMessage = 'This feature will be available for VIP emerald star members or more!';
            }
            else if (rolePremium !== 'diamondStarUser') {
                modalMessage = `This feature will be available for VIP diamond star members!`;
            }
            else {
                modalMessage = '🚧 We are working on this feature';
            }
            setModalVisible(true);
            setModalMessage(modalMessage);
        }
        else {
            setModalVisible(true);
            setModalMessage('🚧 We are working on this feature');
        }
    };
    const handleSwitchOption = () => {
        switchBtnFunction('switchBtnOption', setSwitchBtnOption);
    };
    const handleRepeatEvent = () => {
        switchBtnFunction('repeatEvent', setRepeatEvent);
    };
    const handleManageParity = () => {
        switchBtnFunction('manageParity', setManageParity);
    };
    const handleGuestWithFriends = () => {
        switchBtnFunction('guestWithFriends', setGuestWithFriends);
    };
    const handleAgeRestriction = () => {
        switchBtnFunction('ageRestriction', setAgeRestriction);
    };
    return (<ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
            <View style={styles.switchBtn}>
                {/* json a corriger */}
                {/* {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonOptionalFeatures} */}
                <Text style={styles.textbtn}>
                    {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonOptionalFeatures}
                </Text>
                <SwitchBtn state={switchBtnOption} 
    // setState={setSwitchBtnOption}
    setState={handleSwitchOption}/>
            </View>
            {switchBtnOption && (<View style={styles.issueButtonRow}>
                    {optionalFeatures.map((button) => (<View key={button.id}>
                            <TouchableOpacity style={[styles.issueButton, formData.optionalselected.includes(button.id) && styles.btnSelectedGreen]} onPress={() => handleOptionPress(button)}>
                                <Text style={[styles.buttonText, formData.optionalselected.includes(button.id) && styles.buttonTextWhite]}>{button.text}</Text>
                            </TouchableOpacity>
                        </View>))}
                </View>)}
            <View style={styles.switchBtn}>
                <Text style={styles.textbtn}>
                    {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonRepeatEvent} :{' '}
                </Text>
                <SwitchBtn state={repeatEvent} 
    // setState={setRepeatEvent}
    setState={handleRepeatEvent}/>
            </View>
            {repeatEvent && (<View style={styles.repeatContainer}>
                    <View style={styles.btnsRowContainer}>
                        <Pressable style={[styles.repeatItem, styles.repeatItemLeft, formData.repeatChoice === 'weekly' && styles.btnSelectedGreen]} onPress={() => handleStringChange('repeatChoice', 'weekly')}>
                            <Text style={[styles.textbtn, formData.repeatChoice === 'weekly' && styles.buttonTextWhite]}>
                                {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonWeekly}
                            </Text>
                        </Pressable>
                        <Pressable style={[styles.repeatItem, formData.repeatChoice === 'monthly' && styles.btnSelectedGreen]} onPress={() => handleStringChange('repeatChoice', 'monthly')}>
                            <Text style={[styles.textbtn, formData.repeatChoice === 'monthly' && styles.buttonTextWhite]}>
                                {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonMonthly}
                            </Text>
                        </Pressable>
                        <Pressable style={[styles.repeatItem, styles.repeatItemRight, formData.repeatChoice === 'yearly' && styles.btnSelectedGreen]} onPress={() => handleStringChange('repeatChoice', 'yearly')}>
                            <Text style={[styles.textbtn, formData.repeatChoice === 'yearly' && styles.buttonTextWhite]}>
                                {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonYearly}
                            </Text>
                        </Pressable>
                    </View>
                    {/* json a corriger, pas pareil que figma */}
                    <Text style={styles.textbtn}>
                        {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonSelectDays}
                    </Text>
                    <View style={styles.daysContainer}>
                        {days.map((day) => (<View key={day.id}>
                                <TouchableOpacity style={[
                    styles.dayButton,
                    formData.repeatDays.includes(day.id) && [styles.btnSelectedGreen, { borderWidth: 0 }],
                    day.id === 7 ? { width: 199 } : { width: 131 },
                ]} onPress={() => handleDayPress(day.id)}>
                                    <Text style={[styles.buttonText, formData.repeatDays.includes(day.id) && styles.buttonTextWhite]}>{day.text}</Text>
                                </TouchableOpacity>
                            </View>))}
                    </View>
                    <View style={styles.dateContainer}>
                        {/* pas encore tester en mobile  */}
                        {/* <DateTimePickerModal
                title="Set an end date"
                //onDateChangeForOnlyDate={(date: CalendarDate) => handleStringChange('endDate', date)}
                type="onlyDate"
            /> */}
                        {/* <DatePickerModal onDateChange={handleDateChange} /> */}
                    </View>
                    
                </View>)}
            <View style={styles.switchBtn}>
                <Text style={styles.textbtn}>
                    {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonParity}
                </Text>
                <SwitchBtn state={manageParity} 
    // setState={setManageParity}
    setState={handleManageParity}/>
            </View>
            {manageParity && (<View style={styles.parityContainer}>
                    <SliderComponent min={0} max={100} sliderValue={sliderSexeValue} setSliderValue={setSliderSexeValue} type="parity"/>
                    <View style={styles.twoSliders}></View>
                </View>)}
            <View style={styles.switchBtn}>
                <Text style={styles.textbtn}>
                    {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonAllowGuests}:
                </Text>
                <SwitchBtn setState={handleGuestWithFriends} state={guestWithFriends}/>
            </View>
            {guestWithFriends && <SliderComponent min={1} max={5} sliderValue={sliderFriendValue} setSliderValue={setSliderFriendValue}/>}
            <View style={styles.switchBtn}>
                <Text style={styles.textbtn}>
                    {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonAgeRestrict}
                </Text>
                <SwitchBtn setState={handleAgeRestriction} state={ageRestriction}/>
            </View>
            {ageRestriction && (<SliderComponent min={18} max={99} sliderValue={sliderAgeValue} setSliderValue={setSliderAgeValue} type="age" translations={translations}/>)}
            <Modal modalVisible={modalVisible} onClose={() => setModalVisible(false)} children={<Text>{modalMessage}</Text>}/>
            <View style={styles.btnContainer}>
                <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
                <ButtonNext 
    // conditionNeed={
    //     switchBtnOption === true && formData.optionalselected.length > 0
    //     || repeatEvent === true && formData.repeatChoice !== '' && formData.repeatDays.length > 0 && formData.endDate !== null
    //     || manageParity === true && formData.infoLine !== ''}
    handleNextStep={onNextStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton}/>
            </View>
        </ScrollView>);
};
export default DuplicateActivityPremiumFeaturesScreen;
