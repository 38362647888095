import React, { useState, useEffect } from 'react';
import { StyleSheet, ScrollView, Text } from 'react-native';
// Importez vos composants TopProfile et BottomProfile
import MyProfileTopScreen from './PublicProfileTopScreen/Code';
import MyProfileBottomNavigator from './PublicProfileBottomNavigator';
// Import du contexte d'utilisateur
import { useSelector } from 'react-redux';
const PublicProfileParent = ({ route, navigation }) => {
    // console.log("socket in parent", socket)
    // const { user, allUsers, /* fetchUserDetails */} = useUser();
    const { user, allUsers } = useSelector((state) => state.user);
    const { activities } = useSelector((state) => state.activities);
    const [userActivities, setUserActivities] = useState([]);
    const userID = route.params.userId;
    const profileOwner = userID === (user === null || user === void 0 ? void 0 : user._id) ? user : allUsers.find((u) => u._id === userID);
    const status = route.params.status;
    // Charger les détails de l'utilisateur au montage du composant
    // useEffect(() => {
    //   fetchUserDetails();
    // }, []);
    useEffect(() => {
        // User is organising
        const userOrganisedActivities = activities.filter((activity) => activity.author === user._id);
        // User is Attending
        const userAttendedActivities = activities.filter((activity) => activity.attendees.includes(user._id));
        setUserActivities([...userOrganisedActivities, ...userAttendedActivities]);
    }, [userID]);
    // Retourner l'interface utilisateur
    return (<ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      <Text>Profile de {userID}</Text>
      {/* Haut de profil */}
      <MyProfileTopScreen userId={userID} user={user} profileOwner={profileOwner} status={status}/>
      {/* Bas de profil */}
      <MyProfileBottomNavigator allUsers={allUsers} profileOwner={profileOwner} userActivities={userActivities}/>
      {/* a creer */}

    </ScrollView>);
};
// Styles du composant ProfileParent
const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white'
        // Vous pouvez ajouter plus de styles selon vos besoins
    },
});
export default PublicProfileParent;
