import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import MyButtonPress from '../../../ButtonSection/PressButton/Code';
// import services
import { ParticipantsServices } from '../../../../services/activities/ParticipantsServices';
const ActivityWaitingListModal = ({ setModalVisible, action, activity, user, text = '' }) => {
    const isWaitingList = ParticipantsServices.isUserInWaitingList(activity, user._id);
    return (<>
            {!isWaitingList
            ? <Text style={styles.modalText}>Too many participants, join waiting list?</Text>
            : <Text style={styles.modalText}>{text ? text : 'Leave waiting list?'}</Text>}
            <View style={styles.modalButtons}>
                <MyButtonPress text={!isWaitingList ? 'Join' : 'Leave'} txtCol={'#59C09B'} txtS={20} txtFW={'700'} w={113} h={35} bgC='white' br={11} b={1} bC={'#59C09B'} cb={action}/>
                <MyButtonPress text='Cancel' txtCol={'white'} txtS={20} txtFW={'700'} w={113} h={35} bgC='#59C09B' br={11} cb={() => setModalVisible(false)}/>
            </View>
        </>);
};
export default ActivityWaitingListModal;
const styles = StyleSheet.create({
    modalButtons: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
    },
    modalText: {
        fontFamily: 'Poppins',
        fontSize: 20,
        color: '#59C09B',
        fontWeight: '700',
        textAlign: 'center',
        margin: 20,
    },
});
