// Fr  Styles de CreateActivityManageParticipationScreen(FrameFigma035A,FrameFigma035B)
// En  Styles of CreateActivityManageParticipationScreen(FrameFigma035A,FrameFigma035B)
import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
    scrollContainer: {
        paddingHorizontal: width * 0.0625, // 25/400
    },
    textTop: {
        fontSize: width * 0.03, // 13/400
        marginTop: -height * 0.02, // 10/400
    },
    contentContainer: {
        marginVertical: height * 0.02, // 10/400
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sliderContainer: {
        marginVertical: height * 0.02, // 10/400
        alignItems: 'center',
    },
    textbtn: {
        alignSelf: 'flex-start',
        fontSize: width * 0.045, // 18/400
        fontWeight: 'bold',
    },
    inputContainer: {
        paddingHorizontal: width * 0.02, // 8/400
        justifyContent: 'center',
        fontSize: width * 0.04, // 16/400
        borderWidth: width * 0.00625, // 2.5/400
        marginVertical: height * 0.05, // 20/400
        borderRadius: width * 0.0375, // 15/400
        padding: width * 0.05, // 20/400
    },
    headerError: {
        backgroundColor: 'red',
        padding: width * 0.05, // 20/400
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: width * 0.045, // 18/400
        fontWeight: 'bold',
    },
});
