import React, { useState, useRef } from 'react';
import { View, Alert, StyleSheet } from 'react-native';
import PhoneInput from 'react-native-phone-number-input';
const PhoneCodePicker = ({ onPhoneVerification }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const phoneInput = useRef(null);
    const handlePhoneNumberChange = (text) => {
        setPhoneNumber(text);
        onPhoneVerification(text);
    };
    const buttonPress = () => {
        Alert.alert(phoneNumber);
        onPhoneVerification(phoneNumber);
    };
    return (<View style={styles.container}>
        <PhoneInput ref={phoneInput} defaultValue={phoneNumber} defaultCode="IN" layout="first" withShadow autoFocus containerStyle={styles.phoneContainer} textContainerStyle={styles.textInput} onChangeFormattedText={handlePhoneNumberChange}/>
      </View>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    phoneContainer: {
        width: '100%',
        height: 50,
    },
    button: {
        marginTop: 30,
        width: '75%',
        padding: 10,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'green',
    },
    textInput: {
        paddingVertical: 0,
    },
    continueText: {
        color: 'white',
    },
});
export default PhoneCodePicker;
