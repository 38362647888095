var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Text, StyleSheet, TouchableOpacity, View } from 'react-native';
import Vectors from '../../../constants/Vectors';
const FriendAddedNotificationCard = ({ friendName, friendId, isRead, onRead, onUnread, isMute }) => {
    console.log('FriendAddedNotificationCard', friendName, friendId, isRead);
    const handlePress = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isRead) {
            yield onUnread(friendId);
        }
        else {
            yield onRead(friendId);
        }
    });
    return (<TouchableOpacity style={[styles.card, isRead ? styles.pressedCard : styles.unpressedCard]} onPress={handlePress}>
        {isMute
            ? <Vectors.NotificationsBellMute style={styles.NotificationsVectors}/>
            : isRead
                ? <Vectors.NotificationsBellOn style={styles.NotificationsVectors}/>
                : <Vectors.NotificationsBellRinging style={styles.NotificationsVectors}/>}
        <View style={styles.textContainer}>
          <Text style={styles.text}>Maintenant tu es ami avec {friendName}</Text>
        </View>
      </TouchableOpacity>);
};
const styles = StyleSheet.create({
    card: {
        flex: 1,
        width: '100%',
        flexDirection: 'row',
        paddingHorizontal: '5%',
        alignItems: 'center',
        justifyContent: 'flex-start',
        minHeight: 87,
        maxHeight: 'auto',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 0,
        },
        borderColor: 'transparent',
        shadowOpacity: 0.25,
        shadowRadius: 8,
    },
    textContainer: {
        flex: 0.7,
        justifyContent: 'flex-start',
    },
    unpressedCard: {
        backgroundColor: '#B6DFD0',
    },
    pressedCard: {
        backgroundColor: '#FFFFFF',
    },
    text: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    NotificationsVectors: {
        width: 30,
        height: 30,
    },
});
export default FriendAddedNotificationCard;
