import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
const styles = StyleSheet.create({
    locationButton: {
        marginTop: height * 0.015, // Adjusted to 1.5% of screen height
    },
    locationButtonText: {
        color: '#007AFF',
        fontSize: width * 0.04, // Adjusted to 4% of screen width
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        maxHeight: height * 0.6
    },
    modalContent: {
        width: width * 0.8, // Adjusted to 80% of screen width
        height: height * 1.1,
        backgroundColor: 'white',
        borderRadius: width * 0.025, // Adjusted to 2.5% of screen width for borderRadius
        padding: width * 0.05, // Adjusted to 5% of screen width for padding
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.1,
        shadowRadius: 8,
    },
    modalHeader: {
        alignItems: 'center',
        alignSelf: 'center',
        marginBottom: height * 0.02, // Adjusted to 2% of screen height
        width: '100%',
    },
    vectorHeader: {
        height: width * 0.09, // Adjusted to 9% of screen width
        width: width * 0.09, // Adjusted to 9% of screen width
        color: '#1A73E8',
    },
    modalHeaderText: {
        fontSize: width * 0.06, // Adjusted to 6% of screen width
        fontWeight: 'bold',
    },
    modalTitle: {
        textAlign: 'center',
        fontSize: width * 0.045, // Adjusted to 4.5% of screen width
        marginBottom: height * 0.02, // Adjusted to 2% of screen height
    },
    modalOptionsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        width: '100%',
    },
    modalOption: {
        flex: 1,
        padding: width * 0.04, // Adjusted to 4% of screen width for padding
        borderRadius: width * 0.0125, // Adjusted to 1.25% of screen width for borderRadius
        backgroundColor: '#F0F0F0',
        alignItems: 'center',
        marginVertical: height * 0.01, // Adjusted to 1% of screen height
        marginHorizontal: width * 0.025, // Adjusted to 2.5% of screen width
    },
    modalOptionText: {
        textAlign: "center",
        fontSize: width * 0.04, // Adjusted to 4% of screen width
        fontWeight: 'bold',
    },
    modalOptionCircle: {
        width: width * 0.15, // Adjusted to 15% of screen width
        height: width * 0.15, // Adjusted to 15% of screen width
        borderRadius: width * 0.075, // Adjusted to 7.5% of screen width for borderRadius
        borderWidth: 1,
        borderColor: '#C0C0C0',
        marginBottom: height * 0.01, // Adjusted to 1% of screen height
        justifyContent: 'center',
        alignItems: 'center',
    },
    activeModalOptionCircle: {
        borderColor: '#007AFF',
    },
    vectorInsideCircle: {
        width: width * 0.075, // Adjusted to 7.5% of screen width
        height: width * 0.075, // Adjusted to 7.5% of screen width
        color: '#007AFF', // Change this color if necessary
    },
    modalOptionCancel: {
        width: '100%',
        padding: width * 0.04, // Adjusted to 4% of screen width for padding
        borderRadius: width * 0.0125, // Adjusted to 1.25% of screen width for borderRadius
        backgroundColor: '#59C09B',
        alignItems: 'center',
        marginTop: height * 0.02, // Adjusted to 2% of screen height
    },
    modalOptionCancelText: {
        fontSize: width * 0.04, // Adjusted to 4% of screen width
        fontWeight: 'bold',
        color: 'white',
    },
});
export default styles;
