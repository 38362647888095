import React from 'react';
import { View, Modal, Text, TouchableOpacity, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
const ProfileImagePickerWeb = ({ onImageSelected, modalVisible, setModalVisible }) => {
    const { translations } = useSelector((state) => state.language);
    const handleImageSelection = (event) => {
        var _a;
        const file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            onImageSelected({ file, imageUrl });
            setModalVisible(false);
        }
    };
    return (<View style={styles.container}>
        <Modal animationType="slide" transparent={true} visible={modalVisible} onRequestClose={() => setModalVisible(false)}>
          <View style={styles.centeredView}>
            <View style={styles.modalView}>
              <TouchableOpacity style={styles.button}>
                <Text style={styles.textStyle}>{translations.JsonImagePickerModal.JsonLibrary}</Text>
                <input type="file" onChange={handleImageSelection} style={styles.fileInput} accept="image/*"/>
              </TouchableOpacity>
              <TouchableOpacity style={styles.button} onPress={() => setModalVisible(false)}>
                <Text style={styles.textStyle}>{translations.JsonActivityPages.JsonActivityScreen.JsonCancel}</Text>
              </TouchableOpacity>
            </View>
          </View>
        </Modal>
      </View>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
        backgroundColor: '#59C09B',
        marginBottom: 10,
        width: 200,
        position: 'relative',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    fileInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
    },
});
export default ProfileImagePickerWeb;
