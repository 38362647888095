//FR permet à l'utilisateur de sélectionner une image depuis sa galerie, puis de la recadrer en forme de cercle. (FrameFigma037A,FrameFigma037E)
//EN Allow the user to select an image from their gallery, then crop it into a circular shape (FrameFigma037A,FrameFigma037E)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Import modules
import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, Image, StyleSheet } from 'react-native';
import ImagePicker from 'react-native-image-crop-picker'; // Bibliothèque pour la gestion de l'image et le crop
// Styles
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    imageContainer: {
        width: 300,
        height: 300,
        borderRadius: 150, // Pour un crop circulaire, modifiable selon les besoins
        overflow: 'hidden',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#eee',
    },
    image: {
        width: '100%',
        height: '100%',
    },
    button: {
        marginVertical: 20,
        backgroundColor: '#59C09B',
        padding: 10,
        borderRadius: 5,
    },
    buttonText: {
        color: 'white',
        textAlign: 'center',
    },
});
const ImageCropperMobile = ({ uri, handleCroppedImage, imgType, hideToggleAspect }) => {
    const [image, setImage] = useState({ uri: uri, mime: "" });
    const [cropedImage, setCropedImage] = useState();
    useEffect(() => {
        cropImage();
        return () => {
        };
    }, []);
    const openImagePicker = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const pickedImage = yield ImagePicker.openPicker({
                width: 300,
                height: 300,
                cropping: true,
                cropperCircleOverlay: true,
                compressImageQuality: 0.7,
                includeBase64: true,
            });
            // console.log("picked image right away ", pickedImage);
            setImage({ uri: pickedImage.path, mime: pickedImage.mime });
        }
        catch (error) {
            console.error('Error picking the image: ', error);
        }
    });
    const cropImage = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const tempCropedImage = yield ImagePicker.openCropper({
                mediaType: 'photo',
                path: image.uri,
                includeBase64: true,
                width: 800,
                height: 400,
                cropping: true,
                multiple: false,
                writeTempFile: false,
                enableRotationGesture: false,
            });
            // console.log("croped image right away ", tempCropedImage);
            setImage({ uri: tempCropedImage.path, mime: tempCropedImage.mime });
            setCropedImage({ uri: tempCropedImage.path, mime: tempCropedImage.mime });
        }
        catch (error) {
            console.error('Error picking the image: ', error);
        }
    });
    const saveImage = () => {
        // console.log("the cropped image ", cropedImage)
        if (cropedImage)
            handleCroppedImage({ file: cropedImage, imageUrl: cropedImage.uri });
        else
            handleCroppedImage({ file: cropedImage, imageUrl: image.uri });
    };
    // console.log("the file ", image, cropedImage)
    return (<View style={styles.container}>
      {/* <Pressable style={styles.button} onPress={openImagePicker}>
          <Text style={styles.buttonText}>Select A New Image</Text>
        </Pressable> */}
      <Pressable style={styles.button} onPress={cropImage}>
        <Text style={styles.buttonText}>Crop Image</Text>
      </Pressable>
      {image && (<View style={styles.imageContainer}>
          <Image source={{ uri: image.uri }} style={styles.image}/>
        </View>)}

      <Pressable style={styles.button} onPress={saveImage}>
        <Text style={styles.buttonText}>Save Image</Text>
      </Pressable>
    </View>);
};
export default ImageCropperMobile;
