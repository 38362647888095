var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// 🇫🇷 L'écran de la première étape d'un processus d'inscription multi-étapes (FrameFigma010)
// 🇬🇧 The first step screen of a multi-step registration process (FrameFigma010)
import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, Platform, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Vectors from '../../../../constants/Vectors';
import ButtonNext from "../../../../components/ButtonSection/NextButton/Code";
import LocationModal from '../../../../components/ModalPopUpModule/ModalSection/LocationPermissionModal/Code'; // Import LocationModal for web
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentLocation } from '../../../../services/Location/LocationService';
import LocationSlice from '../../../../redux/Features/Location/LocationSlice';
import { nanoid } from '@reduxjs/toolkit';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const StepOneScreen = ({ onNextStep }) => {
    // const { translations } = useLanguage();
    // const { location, getCurrentLocation, locationType, setShowModal, showModal } = useLocation();
    const { translations } = useSelector((state) => state.language);
    const { location, locationType } = useSelector((state) => state.location);
    const dispatch = useDispatch();
    const [selectedSexe, setSelectedSexe] = useState('Male');
    const [selectedAccount, setSelectedAccount] = useState('personal');
    const [showGetLocationButton, setShowGetLocationButton] = useState(true); // State to toggle the "Get Location" button visibility on mobile
    const [showModal, setShowModal] = useState(true);
    const getUserCurrentLocation = (type) => __awaiter(void 0, void 0, void 0, function* () {
        let userLocation = yield getCurrentLocation(type);
        console.log("the location returned ", userLocation);
        if (userLocation)
            dispatch(LocationSlice.actions.setLocation({
                id: nanoid(), value: userLocation
            }));
    });
    // 🇫🇷 Effet pour récupérer les données stockées lors du montage du composant
    // 🇬🇧 Effect to retrieve stored data on component mount
    useEffect(() => {
        const retrieveData = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                // If the data exists in AsyncStorage, we retrieve it, otherwise we use the default values, this allows us to keep the data between pages
                const sexeValue = yield AsyncStorage.getItem('sexe');
                const accountTypeValue = yield AsyncStorage.getItem('accountType');
                const modalState = yield AsyncStorage.getItem('modalState');
                if (sexeValue !== null) {
                    setSelectedSexe(sexeValue);
                }
                else {
                    yield AsyncStorage.setItem('sexe', 'Male');
                    setSelectedSexe('Male');
                }
                if (accountTypeValue !== null) {
                    setSelectedAccount(accountTypeValue);
                }
                else {
                    yield AsyncStorage.setItem('accountType', 'personal');
                    setSelectedAccount('personal');
                }
                if (modalState !== null) {
                    setShowModal(modalState === 'true');
                }
            }
            catch (error) {
                console.error('Error retrieving data:', error);
            }
        });
        retrieveData();
    }, []);
    // 🇫🇷 Gestion de la sélection du sexe
    // 🇬🇧 Handle sex selection
    const handleSexePress = (option) => __awaiter(void 0, void 0, void 0, function* () {
        setSelectedSexe(option);
        try {
            yield AsyncStorage.setItem('sexe', option);
        }
        catch (error) {
            console.error('Error saving sexe:', error);
        }
    });
    // 🇫🇷 Gestion de la sélection du type de compte
    // 🇬🇧 Handle account type selection
    const handleAccountPress = (option) => __awaiter(void 0, void 0, void 0, function* () {
        const accountType = option === 'isPersonalAccount' ? 'personal' : 'professional';
        try {
            yield AsyncStorage.setItem('accountType', accountType);
            setSelectedAccount(accountType);
        }
        catch (error) {
            console.error('Error saving account type:', error);
        }
    });
    // 🇫🇷 Obtenir le texte du bouton de localisation
    // 🇬🇧 Get location button text
    const getLocationButtonText = () => {
        return location ? translations.JsonLocationModal.JsonLocationReceived : translations.JsonLocationModal.JsonLocationButton;
    };
    // 🇫🇷 Gestion de l'affichage de la modal
    // 🇬🇧 Handle modal display
    const handleShowModal = (state) => __awaiter(void 0, void 0, void 0, function* () {
        setShowModal(state);
        try {
            // The modal state is used so that you only get the prompt of localisation once
            yield AsyncStorage.setItem('modalState', state.toString());
        }
        catch (error) {
            console.error('Error saving modal state:', error);
        }
    });
    // 🇫🇷 Gestion de la sélection du type de localisation
    // 🇬🇧 Handle location type selection
    const handleSelectLocationType = (type) => __awaiter(void 0, void 0, void 0, function* () {
        yield getUserCurrentLocation(type);
        handleShowModal(false);
    });
    const handleGetLocationButtonPress = () => __awaiter(void 0, void 0, void 0, function* () {
        // Function to handle "Get Location" button press on mobile
        setShowGetLocationButton(true); // Show the button when pressed
        yield getUserCurrentLocation('exact'); // Initiate location fetching
    });
    return (<View style={styles.container}>
      <View style={styles.sexe}>
        {/* Sélection du sexe */}
        <View style={styles.containerSexe}>
          <Pressable style={() => [styles.pressableMale, selectedSexe === 'Male' && styles.activePressable]} onPress={() => handleSexePress('Male')}>
            <View style={styles.male}>
              <View style={styles.icon}>
                <Vectors.ProfilesMaleSign />
              </View>
              <View style={styles.textBtn}>
                <Text style={styles.textSexe}>{translations.JsonCreateProfilePages.JsonMale}</Text>
              </View>
            </View>
          </Pressable>
          <Pressable style={() => [styles.pressableFemale, selectedSexe === 'Female' && styles.activePressable]} onPress={() => handleSexePress('Female')}>
            <View style={styles.female}>
              <View style={styles.icon}>
                <Vectors.ProfilesFemaleSign />
              </View>
              <View style={styles.textBtnF}>
                <Text style={styles.textSexe}>{translations.JsonCreateProfilePages.JsonFemale}</Text>
              </View>
            </View>
          </Pressable>
        </View>
        {/* Sélection du type de compte */}
        <View style={styles.containerAccount}>
          <Pressable style={() => [styles.personal, selectedAccount === 'personal' && styles.activeAccount]} onPress={() => handleAccountPress('isPersonalAccount')}>
            <Text style={styles.textAccount}>{translations.JsonCreateProfilePages.JsonPersonal}</Text>
          </Pressable>

          <Pressable style={() => [styles.pro, selectedAccount === 'professional' && styles.activeAccount]} onPress={() => handleAccountPress('isProAccount')}>
            <Text style={styles.textAccount}>{translations.JsonCreateProfilePages.JsonProfessional}</Text>
          </Pressable>
        </View>
        {/* Bouton pour passer à l'étape suivante */}
        <View style={styles.btnContainer}>
          <ButtonNext conditionNeed={selectedSexe !== null} handleNextStep={onNextStep ? onNextStep : () => { }} btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}/>
          {/* Conditional rendering of "Get Location" button on mobile */}
          {Platform.OS === 'web' ? (<TouchableOpacity style={styles.locationButton} onPress={() => handleShowModal(true)}>
              <Text style={styles.locationButtonText}>{getLocationButtonText()}</Text>
            </TouchableOpacity>) : (<TouchableOpacity style={styles.locationButton} onPress={handleGetLocationButtonPress}>
              <Text style={styles.locationButtonText}>{getLocationButtonText()}</Text>
            </TouchableOpacity>)}
        </View>
      </View>
      {/* Render LocationModal only for web */}
      {Platform.OS === 'web' && (<LocationModal visible={showModal} locationType={locationType} onRequestClose={() => handleShowModal(false)} onSelectLocationType={handleSelectLocationType}/>)}
    </View>);
};
export default StepOneScreen;
