//FR  affiche une carte Google Maps avec des utilisateurs, en fonction de la plateforme utilisée
//EN  that displays a Google Maps with users, depending on the platform being used
import React from 'react';
import { ScrollView, Platform, ActivityIndicator } from 'react-native';
import MapsWebUser from './ActivitiesMapWeb/Code';
import MapsMobileUser from './ActivitiesMapMobile/Code';
import { useSelector } from 'react-redux';
const UsersMapsScreen = () => {
    // const {allUsers, user} = useUser();
    const { user, allUsers } = useSelector((state) => state.user);
    // Déterminer le composant à utiliser en fonction de la plateforme
    const MapsComponent = Platform.OS === 'web' ? MapsWebUser : MapsMobileUser;
    return (<>
            {!allUsers || !user
            ? <ActivityIndicator size="large" color="#0000ff"/>
            :
                <ScrollView>
                    {/* Afficher la carte avec les utilisateurs */}
                    <MapsComponent users={allUsers}/>
                </ScrollView>}
        </>);
};
export default UsersMapsScreen;
