var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//FR il affiche une liste d'activités à venir pour l'utilisateur actuel (FrameFigma21B)
//GB it displays a list of upcoming activities for the current user." (FrameFigma21B)
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { View, Text, Platform, Animated } from 'react-native';
import { useFocusEffect } from '@react-navigation/native';
//import components
import MyActivityCard from '../../../../components/CardSection/MyActivityCard/Code';
//import contexts
// import utils fonctions
import { formatDate, ActivitiesWithDate } from '../../../../utils/Functions/functions';
import { userServices } from '../../../../services/user/UserServices';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
const UpcomingScreen = ({ route }) => {
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const activitiesStore = useSelector((state) => state.activities);
    const { user } = useSelector((state) => state.user);
    const [activities, setActivities] = useState([]);
    const [userId, setUserId] = useState(null);
    const { setIsDisplayBannerVisible } = route.params;
    const scrollY = useRef(new Animated.Value(0)).current;
    useEffect(() => {
        fetchActivities();
    }, []);
    useEffect(() => {
        const listener = scrollY.addListener(({ value }) => {
            if (value > 0) {
                setIsDisplayBannerVisible(false);
            }
            else {
                setIsDisplayBannerVisible(true);
            }
            console.log('scrollY');
        });
        return () => {
            scrollY.removeListener(listener);
        };
    }, [scrollY]);
    useFocusEffect(useCallback(() => {
        console.log('useFocusEffect');
        fetchActivities();
        return () => {
            fetchActivities();
        };
    }, [activities.length]));
    const fetchActivities = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const userId = user._id;
            setUserId(userId);
            console.log('Voila User id est : ' + userId);
            const userId1 = yield userServices.getUsers();
            const userId2 = yield userServices.getUserByFilter(userId1.data, userId);
            console.log('Voila User id de nik mok est : ' + userId1.data.length + userId1.data);
            console.log('Voila User id de nik mok 2 est : ' + userId2.activityLikes);
            // const activties = await ActivityServices.getActivities()
            if (Array.isArray(userId2)) {
                console.log('Voila User id de nik mok 2 est 1 : ', userId2);
            }
            else if (typeof userId2 === 'object') {
                console.log('Voila User id de nik mok 2 est  2: ', JSON.stringify(userId2, null, 2));
                const userId = user._id;
                if (userId) { // Ensure userId is not null
                    setUserId(userId);
                    console.log('User ID is: ' + userId);
                    const userId1 = yield userServices.getUsers();
                    const userId2 = yield userServices.getUserByFilter(userId1.data, userId);
                    console.log('Number of users:', userId1.data.length);
                    console.log('User details:', userId2 === null || userId2 === void 0 ? void 0 : userId2.activityLikes);
                    // const activitiesResponse = await ActivityServices.getActivities();
                    const myUpcomingActivities = activitiesStore.activities.filter((activity) => {
                        return (activity.attendees.includes(userId) || activity.waitingList.includes(userId)) && new Date(activity.date) >= new Date();
                    });
                    setActivities(myUpcomingActivities);
                    console.log('Upcoming activities: ', myUpcomingActivities);
                }
                else {
                    console.error("User ID is null or undefined.");
                }
            }
        }
        catch (error) {
            if (error instanceof TypeError && error.message === 'Network request failed') {
                console.error("Network request failed:", error);
            }
            else {
                console.error("Error fetching activities:", error);
            }
        }
    });
    const groupedActivities = useMemo(() => {
        const grouped = new Map();
        activities.forEach(activity => ActivitiesWithDate(activity, grouped));
        const sortedGroupedActivities = Array.from(grouped.entries()).sort(([date1], [date2]) => {
            return new Date(date1).valueOf() - new Date(date2).valueOf();
        });
        sortedGroupedActivities.forEach(([date, activities]) => {
            activities.sort((a, b) => {
                const startTimeA = a.startTime.hour * 60 + a.startTime.minute;
                const startTimeB = b.startTime.hour * 60 + b.startTime.minute;
                return startTimeA - startTimeB;
            });
        });
        return new Map(sortedGroupedActivities);
    }, [activities]);
    return (<View style={styles.colorBackroundWhite}>
      <Animated.ScrollView onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], { useNativeDriver: false })} showsVerticalScrollIndicator={false}>
        {groupedActivities.size > 0 ? (Array.from(groupedActivities.entries()).map(([date, activities]) => (<View key={date} data-date={date}>
              <Text style={styles.DisplayDate}>{formatDate(date)}</Text>
              {activities.map((activity) => (<View key={activity._id ? activity._id.toString() : activity.date.toString()}>
                  <MyActivityCard activity={activity} userId={userId}/>
                </View>))}
            </View>))) : (<View style={styles.textTwoContainer}>
            <Text style={styles.textTwo}>
              {translations.JsonActivityPages.JsonMyActivitiesScreen.JsonUpcomingActivityEmpty}
            </Text>
          </View>)}
      </Animated.ScrollView>
    </View>);
};
export default UpcomingScreen;
