//Fr Styles de Modal (FrameFigma041, FrameFigma042) (FrameFigma044) (FrameFigma037A) (FrameFigma038A, FrameFigma038B) (FrameFigma039)
//En Styles of Modal (FrameFigma041, FrameFigma042) (FrameFigma044) (FrameFigma037A) (FrameFigma038A, FrameFigma038B) (FrameFigma039)
import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 50,
        elevation: 10,
    },
    modal: {
        width: 400,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        gap: 20,
        padding: 10,
        borderRadius: 10,
        // boxShadow property is not supported in react-native's ViewStyle
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 8,
        paddingBottom: 50,
    },
    close: {
        alignSelf: 'flex-end',
    },
    actionButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
        backgroundColor: '#6f6f6f',
        borderRadius: 10,
    },
    buttonText: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 'bold',
        marginRight: 5,
    },
});
export default styles;
