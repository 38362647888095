import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    backImg: {
        flex: 1,
        width: '100%',
        height: '100%',
        resizeMode: 'cover',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        width: width * 0.9, // 90% de la largeur de l'écran
        height: height * 0.3, // 30% de la hauteur de l'écran
        marginBottom: height * 0.05, // 5% de la hauteur de l'écran comme marge
        resizeMode: 'contain', // S'assure que l'image est contenue dans les dimensions données sans être coupée
    },
    content: {
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.65)',
        padding: 20,
        width: '100%', // 90% de la largeur de l'écran
        marginBottom: height * 0.1, // 10% de la hauteur de l'écran comme marge
    },
    btnContainer: {
        width: '100%',
        alignItems: 'center',
    },
    btnStart: {
        backgroundColor: 'white',
        padding: 10,
        paddingHorizontal: width * 0.1, // 10% de la largeur de l'écran
        borderRadius: 15,
    },
    btnText: {
        color: 'black',
        fontSize: 22,
        fontWeight: 'bold',
    },
    languageBtn: {
        margin: height * 0.05, // 5% de la hauteur de l'écran comme marge
        paddingVertical: 5,
        paddingHorizontal: 8,
        width: '60%',
        maxWidth: 200,
        alignSelf: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 10,
    },
    DescriptionTxt: {
        margin: 10,
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
        fontSize: 22,
    },
});
export default styles;
