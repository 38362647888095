import { StyleSheet } from "react-native";
export default StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
    },
    modalContent: {
        width: "20%",
        padding: 10,
        backgroundColor: 'white',
        borderRadius: 10,
        alignItems: 'center',
    },
    modalButton: {
        padding: '10%',
        width: '100%',
        alignItems: 'center',
        borderRadius: 5,
    },
    modalText: {
        fontSize: 16,
        color: 'black',
    },
});
