import React from "react";
import { View, StyleSheet } from 'react-native';
import { Vectors } from "../../../../constants/Vectors";
// const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
    },
    starButton: {
        padding: 5,
    }
});
const StarRatingAverage = ({ ratings, width = 24, height = 24 }) => {
    const totalRatings = ratings.length;
    const averageRating = totalRatings > 0 ? ratings.reduce((sum, rating) => sum + rating, 0) / totalRatings : 0;
    const fullStars = Math.floor(averageRating);
    const halfStar = averageRating % 1 >= 0.5;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);
    return (<View style={styles.container}>
            {[...Array(fullStars)].map((_, index) => (<Vectors.ProfileStar key={`full-${index}`} width={width} height={height}/>))}
            {halfStar && <Vectors.ProfileStarHalf width={width} height={height}/>}
            {[...Array(emptyStars)].map((_, index) => (<Vectors.ProfileStaro key={`full-${index}`} width={width} height={height}/>))}
        </View>);
};
export default StarRatingAverage;
