var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useRef, useEffect } from 'react';
import { View, Text, Pressable } from 'react-native';
import ReactCrop, { centerCrop, makeAspectCrop, convertToPixelCrop } from 'react-image-crop';
import { Vectors } from '../../../constants/Vectors';
import styles from './StylesWeb';
import 'react-image-crop/dist/ReactCrop.css';
function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
    return centerCrop(makeAspectCrop({ unit: '%', width: 100 }, aspect, mediaWidth, mediaHeight), mediaWidth, mediaHeight);
}
const ImageCropperWeb = ({ file, handleCroppedImage, imgType, hideToggleAspect }) => {
    const imgRef = useRef(null);
    const [imgSrc, setImgSrc] = useState('');
    const [crop, setCrop] = useState();
    const [completedCrop, setCompletedCrop] = useState();
    const [aspect, setAspect] = useState(16 / 9);
    const handleFileLoad = () => {
        if (file instanceof File) {
            setCrop(undefined); // Reset crop state
            const reader = new FileReader();
            reader.onload = () => {
                var _a;
                setImgSrc(((_a = reader.result) === null || _a === void 0 ? void 0 : _a.toString()) || '');
            };
            reader.readAsDataURL(file);
        }
    };
    useEffect(() => {
        if (imgType === 'profileImage') {
            setAspect(1); // Square crop for profile images
        }
        handleFileLoad();
    }, [file]);
    const onImageLoad = (e) => {
        if (aspect) {
            const { width, height } = e.currentTarget;
            setCrop(centerAspectCrop(width, height, aspect));
        }
    };
    const saveCropClick = () => __awaiter(void 0, void 0, void 0, function* () {
        const image = imgRef.current;
        console.log("the image ", image);
        if (!image || !(completedCrop === null || completedCrop === void 0 ? void 0 : completedCrop.width) || !(completedCrop === null || completedCrop === void 0 ? void 0 : completedCrop.height)) {
            console.error('Crop canvas does not exist or crop is not completed');
            return;
        }
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        // Utilisez OffscreenCanvas si supporté, sinon fallback à un canvas normal
        const offscreen = new OffscreenCanvas(completedCrop.width * scaleX, completedCrop.height * scaleY);
        const ctx = offscreen.getContext('2d');
        if (!ctx) {
            console.error('No 2D context');
            return;
        }
        // Logging for debugging
        //console.log('Drawing image with scale factors:', { scaleX, scaleY });
        //console.log('Crop details:', completedCrop);
        // Drawing the cropped area
        ctx.drawImage(image, completedCrop.x * scaleX, completedCrop.y * scaleY, completedCrop.width * scaleX, completedCrop.height * scaleY, 0, 0, completedCrop.width * scaleX, completedCrop.height * scaleY);
        // Convert the final canvas to a blob
        const blob = yield offscreen.convertToBlob({ type: 'image/jpeg' });
        if (!blob) {
            console.error('Failed to create blob from the canvas');
            return;
        }
        const imageUrl = URL.createObjectURL(blob);
        //console.info('Image URL:', imageUrl);
        const fileNameWithoutExtension = file.name.split('.').slice(0, -1).join('.');
        const croppedFileName = `${fileNameWithoutExtension}_cropped.jpeg`;
        //const croppedImageFile:any = new File([blob], croppedFileName, {type: 'image/jpeg'});
        const croppedImageFile = new File([blob], croppedFileName, {
            type: 'image/jpeg',
            lastModified: Date.now(),
        });
        console.info('Cropped Image File:', croppedImageFile);
        // handleCroppedImage({ file: croppedImageFile, imageUrl });
        return { file: croppedImageFile, imageUrl };
    });
    const handleToggleAspectClick = () => {
        if (aspect) {
            setAspect(undefined); // Freeform crop
        }
        else {
            setAspect(16 / 9); // Fixed aspect crop
            if (imgRef.current) {
                const { width, height } = imgRef.current;
                const newCrop = centerAspectCrop(width, height, 16 / 9);
                setCrop(newCrop);
                setCompletedCrop(convertToPixelCrop(newCrop, width, height));
            }
        }
    };
    return (<View style={styles.container}>
			{!hideToggleAspect && (<View>
					<Pressable onPress={handleToggleAspectClick} disabled={imgType === 'profileImage'} style={imgType === 'profileImage' ? [styles.toggleAspectButton, styles.toggleAspectButtonDisabled, styles.toggleAspectButtonDisabledText] : styles.toggleAspectButton}>
						{aspect ? (<Vectors.PadLock width={20} height={20} fill={imgType === 'profileImage' ? '#999' : '#000'}/>) : (<Vectors.PadLockOpen width={20} height={20}/>)}
						<Text style={imgType === 'profileImage' && styles.toggleAspectButtonDisabledText}>Toggle Aspect</Text>
					</Pressable>
				</View>)}
			{imgSrc && (<ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} onComplete={c => setCompletedCrop(c)} aspect={aspect}>
					<img ref={imgRef} alt="Crop me" src={imgSrc} style={{ transform: `scale(1) rotate(0deg)` }} onLoad={onImageLoad}/>
				</ReactCrop>)}
			<Pressable onPress={() => __awaiter(void 0, void 0, void 0, function* () {
            const croppedImageUrl = yield saveCropClick();
            handleCroppedImage(croppedImageUrl);
        })} style={styles.saveButton}>
				<Vectors.CheckSignGeneralColor width={40} height={40}/>
			</Pressable>
		</View>);
};
export default ImageCropperWeb;
