import React from 'react';
import { Image, Platform, Pressable, Text, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
// import components
import OnlineStatus from '../../GeneralSection/NotificationBulles/OnlineStatus';
// import styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const ParticipantCard = ({ participant, isAuthor, isUserAuthor }) => {
    const navigation = useNavigation();
    return (<View style={styles.card}>
            <Pressable style={styles.pressable} onPress={() => navigation.navigate('ProfileScreen', { userId: participant._id })}>
                <Image source={{ uri: participant.profileImage }} style={[styles.profileImage, isAuthor && { borderColor: '#05FF00' }]}/>
                {participant.status === 'online' ? <OnlineStatus host={isAuthor}/> : null}
            </Pressable>
            <Text style={[styles.textName, participant.sexe === 'Male' ? { color: '#001AFF' } : { color: '#FF13CB' }]}>
                {participant.firstName}
            </Text>
            {isAuthor ? <Text style={{ color: '#05FF00' }}>Event Host</Text> : null}
            {isUserAuthor ? (<View style={styles.actionContainer}>
                    <View style={styles.valid}></View>
                    <View style={styles.cross}></View>
                </View>) : null}
        </View>);
};
export default ParticipantCard;
