// Fr  Cette page affiche les différents couples disponibles avec le nombre de points qu’il faut pour les avoir, mais également votre tableau des scores ( FrameFigma050a )
// En   This page displays the different couples available with the number of points required to obtain them, as well as your scoreboard. ( FrameFigma050a )
import React from "react";
import { Text, View, Pressable, ScrollView, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
// import des Vectors
import { Vectors } from "../../../constants/Vectors";
import { useSelector } from "react-redux";
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const MemberShipScreen = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const Separator = () => {
        return <View style={styles.separator}/>;
    };
    const SeparatorPlat = () => {
        return <View style={styles.separatorPlat}/>;
    };
    const SeparatorNoir = () => {
        return <View style={styles.SeparatorNoir}/>;
    };
    const navigation = useNavigation();
    { /*Variable qui correspond au nombre d'actions que l'utilisateur a faites, elle doit être modifiée grâce au back-end à chaque fois que l'utilisateur fera une action. */ }
    let Profilevisits = 0;
    let Numberoffriends = 0;
    let Activitiesattendance = 0;
    let Coorganiserofactivities = 0;
    let Organiserofactivities = 0;
    let Sponsorship = 0;
    { /*Variable qui correspond au points gagné par l'utilisateur */ }
    let ProfilevisitsResultat = Profilevisits * 5;
    let NumberoffriendsResultat = Numberoffriends * 20;
    let ActivitiesattendanceResultat = Activitiesattendance * 25;
    let CoorganiserofactivitiesResultat = Coorganiserofactivities * 50;
    let OrganiserofactivitiesResultat = Organiserofactivities * 100;
    let SponsorshipResultat = Sponsorship * 250;
    { /* Partie haut de la page   */ }
    return (<ScrollView>
      {/* Cups et points   */}
      <View style={styles.container}>
        <View style={styles.rowText}>
          <Text style={styles.text}>
            {translations.JsonAchievementsPages.JsonMedalsScreen.JsonMedalsPresentation}
          </Text>
        </View>
        <View style={styles.row}>
          <View style={styles.card}>
            {/* Vectors Level2  */}
          <Vectors.Level2NoStar style={styles.img}/>
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.Json1000Points}
              </Text>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonBronze1}
              </Text>
            </View>
          </View>
          <View style={styles.card}>
            {/* Vectors Level3  */}
            <Vectors.Level3NoStar style={styles.img}/>
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.Json2000Points}
              </Text>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonBronze2}
              </Text>
            </View>
          </View>
          <View style={styles.card}>
            {/* Vectors Level4  */}
            <Vectors.Level4NoStar style={styles.img}/>
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.Json5000Points}
              </Text>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonSilver1}
              </Text>
            </View>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.card}>
            {/* Vectors Level5  */}
            <Vectors.Level5NoStar style={styles.img}/>
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.Json10000Points}
              </Text>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonSilver2}
              </Text>
            </View>
          </View>
          <View style={styles.card}>
            {/* Vectors Level6  */}
            <Vectors.Level6NoStar style={styles.img}/>
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.Json20000Points}
              </Text>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonMaster}
              </Text>
            </View>
          </View>
          <View style={styles.card}>
            {/* Vectors Level7  */}
            <Vectors.Level7NoStar style={styles.img}/>
            <View style={styles.cardContent}>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.Json50000Points}
              </Text>
              <Text style={styles.textCard}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonGrandMaster}
              </Text>
            </View>
          </View>
        </View>
        {/**Notre boutton  */}
        <View style={styles.cardButton}>
          <Pressable style={styles.button} onPress={() => navigation.navigate('VipBadges')}>
            <Text style={styles.buttonText}>
              {translations.JsonAchievementsPages.JsonMedalsScreen.JsonGetVIPBadges}
            </Text>
          </Pressable>
          {/**notre texte en bas du tableau qui vas s'afficher */}
          <View style={{ marginTop: 20 }}>
            <Text style={styles.text}>
              {translations.JsonAchievementsPages.JsonMedalsScreen.JsonHowToGetMorePoints}
            </Text>
          </View>
        </View>
        {/**notre tablau */}
        <View style={styles.table}>
          <View style={styles.rowTab}>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonSources}
              </Text>
            </View>
            <Separator />
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonNumber}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonPoints}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellText}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonTotal}
              </Text>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonProfileVisit}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Profilevisits}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>5</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{ProfilevisitsResultat}</Text>
            </View>
          </View>
          <SeparatorPlat />
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonFriendsNumber}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Numberoffriends}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>20</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{NumberoffriendsResultat}</Text>
            </View>
          </View>
          <SeparatorPlat />
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonActivitiesParticipation}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Activitiesattendance}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>25</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{ActivitiesattendanceResultat}</Text>
            </View>
          </View>
          <SeparatorPlat />
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonActivitiesOrganisation}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Coorganiserofactivities}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>50</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{CoorganiserofactivitiesResultat}</Text>
            </View>
          </View>
          <SeparatorPlat />
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonActivitiesCoOrganisation}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Organiserofactivities}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>100</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{OrganiserofactivitiesResultat}</Text>
            </View>
          </View>
          <SeparatorPlat />
          <View style={styles.row}>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>
                {translations.JsonAchievementsPages.JsonMedalsScreen.JsonSponsorship}
              </Text>
            </View>
            <SeparatorNoir />
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{Sponsorship}</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>250</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTextCentre}>{SponsorshipResultat}</Text>
            </View>
          </View>

        </View>
      </View>
    </ScrollView>);
};
export default MemberShipScreen;
