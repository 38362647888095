//🇫🇷 Ce fichier gère le style web du composant OnlyFriendsCanChatModal.tsx (FrameFigma002)
//🇬🇧 This file manages the web style of the OnlyFriendsCanChatModal.tsx component (FrameFigma002)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    },
    focusedInput2: {
        flex: 1,
        backgroundColor: 'white',
        borderColor: 'rgba(0, 0, 0, 0)',
        borderWidth: 1,
    },
    itemContainer: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 10,
    },
    flatList: {
        width: '100%',
        opacity: 1,
    },
    languageText: {
        marginTop: 5,
        fontWeight: 'bold',
        color: 'white',
    },
    columnWrapper: {
        marginVertical: '5%',
    },
    title: {
        margin: 15,
        fontWeight: "bold",
        fontSize: 20,
        color: "white",
        textAlign: "center",
    },
    searchInput: {
        flexDirection: "row",
        marginTop: 20,
        marginBottom: 15,
        paddingVertical: 10,
        paddingRight: 8,
        paddingLeft: 40,
        width: '90%',
        height: 45,
        alignSelf: "center",
        borderRadius: 25,
        backgroundColor: "white",
        fontSize: 14,
        zIndex: 1,
        borderWidth: 1,
        borderColor: "#59b09c",
    },
    GeneralBlackAndWhiteLoop: {
        paddingLeft: 5,
        position: 'absolute',
        top: 15,
        left: 10,
        zIndex: 2,
    },
    overlay: Object.assign(Object.assign({}, StyleSheet.absoluteFillObject), { backgroundColor: 'rgba(44, 44, 70, 0.95)' }),
});
