// StylesMobile.tsx
//Fr Styles de Code (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma037A) (FrameFigma031) (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma035A,FrameFigma035B)
//En Styles of Code (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma037A) (FrameFigma031) (FrameFigma032A,FrameFigma032B,FrameFigma032C) (FrameFigma035A,FrameFigma035B)
//(FrameFigma006A, FrameFigma004A)
import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    input: {
        borderColor: 'rgba(0, 0, 0, 1)',
        borderWidth: 1,
        // marginVertical: height * 0.000015,
        width: '100%',
        borderRadius: 20,
        // paddingVertical: height * 0.000012,
    },
    focusedInput2: {
        backgroundColor: 'white',
        // Removed outlineColor as it is not a valid ViewStyle property
    },
    defaultInput: {
        backgroundColor: '#F0EEE9',
    },
    focusedInput: {
        backgroundColor: 'white',
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    placeholder: {
        overflow: 'hidden',
        fontSize: width * 0.04,
        fontWeight: '400',
    },
    input2: {
        overflow: 'hidden',
        fontSize: width * 0.04,
        fontWeight: '400',
        textAlign: 'left',
    },
    iconLeft: {
        position: 'absolute',
        marginLeft: width * 0.04,
        zIndex: 2,
    },
    iconEye: {
        position: 'absolute',
        right: width * 0.025,
        zIndex: 1,
        marginTop: width * 0.025,
    },
});
export default styles;
