import React, { useRef, useEffect, useState } from 'react';
import { useNavigation } from '@react-navigation/native';
import { View, Text, Animated, Easing, Pressable, Platform, ScrollView, SafeAreaView, Button } from 'react-native';
import { Vectors } from '../../constants/Vectors';
import StarRating from '../../components/ProfileSection/Rating/StarRating/Code';
import StarRatingAverage from '../../components/ProfileSection/Rating/StarRatingAverage/Code';
import ProfileMedal from '../../components/ProfileSection/ProfileMedal/Code';
import CustomDatePicker from '../../components/PickerSection/DatePicker/Code';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const InProcess = () => {
    const bounceValue = useRef(new Animated.Value(0)).current;
    const navigation = useNavigation();
    const navigateToScreen = (screenName) => {
        navigation.navigate(screenName);
    };
    const [ratings, setRatings] = useState([]); // (FR)gère la note donné par l'utilisateur - (EN) manages the rating given by the user
    const handleRatingChange = (newRating) => {
        setRatings((prevRatings) => [...prevRatings, newRating]); // (FR)Ajout la nouvelle note au tableau - (EN) Add new note to table
    };
    const handleResetRatings = () => {
        setRatings([]); // (FR)Réinitialise le tableau de note - (EN) Resets note table
    };
    // Bouncing animation
    useEffect(() => {
        Animated.loop(Animated.sequence([
            Animated.timing(bounceValue, {
                toValue: 1,
                duration: 1000,
                easing: Easing.bounce,
                useNativeDriver: true,
            }),
            Animated.timing(bounceValue, {
                toValue: 0,
                duration: 1000,
                easing: Easing.bounce,
                useNativeDriver: true,
            }),
        ])).start();
    }, [bounceValue]);
    // Transform the animated value into a scale for bouncing effect
    const bounce = bounceValue.interpolate({
        inputRange: [0, 1],
        outputRange: [1, 1.2],
    });
    const [selectedDate, setSelectedDate] = useState(new Date()); // État pour stocker la date sélectionnée
    const [currentActivity, setCurrentActivity] = useState({}); // Activité actuelle
    // Fonction pour gérer le changement de la date
    const handleDateChange = (date) => {
        setSelectedDate(date); // Mets à jour la date sélectionnée
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { date: date.toISOString(), startTime: { hour: date.getHours(), minute: date.getMinutes() } }));
    };
    return (<SafeAreaView style={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false}>
          <Pressable style={styles.logoContainer} onPress={(() => navigateToScreen('Drink'))}>
          <View style={styles.logoContainer}>
            <Animated.Text style={[styles.emoji, { transform: [{ scale: bounce }] }]}>
              🚀
            </Animated.Text>
          </View>
          <View style={styles.element}>
            <Vectors.FullDrink style={styles.elementVectors}/>
            <View>
              <Text style={styles.elementTitle}>Titre: <Text style={styles.elementText}>Drink</Text></Text>
              <Text style={styles.elementTitle}>Date du début: <Text style={styles.elementText}>09/07/2024</Text></Text>
              <Text style={styles.elementTitle}>Catégorie: <Text style={styles.elementText}>Développement</Text></Text>
            </View>
          </View>
          </Pressable>
          <View style={styles.element}>
            <View>
              <StarRating onRatingChange={handleRatingChange} width={18} height={18}/>
              <StarRatingAverage ratings={ratings} width={18} height={18}/>
              <Button title="Reset" onPress={handleResetRatings} color='black'/>
            </View>
            <View>
              <Text style={styles.elementTitle}>Titre: <Text style={styles.elementText}>Rating</Text></Text>
              <Text style={styles.elementTitle}>Date du début: <Text style={styles.elementText}>01/10/2024</Text></Text>
              <Text style={styles.elementTitle}>Catégorie: <Text style={styles.elementText}>Frontend</Text></Text>
            </View>
          </View>
          <View style={[styles.element, { flexDirection: 'column' }]}>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <View style={{ alignItems: 'center', marginRight: 15 }}>
                  <ProfileMedal points={500} noOfSponsorships={0} isAmbassador={false} size={54}/>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>point: 500</Text>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>parrainages: 0</Text>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>ambassadeur: non</Text>
              </View>
              <View style={{ alignItems: 'center', marginRight: 15 }}>
                  <ProfileMedal points={1000} noOfSponsorships={10} isAmbassador={true} size={54}/>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>point: 1000</Text>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>parrainages: 10</Text>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>ambassadeur: oui</Text>
              </View>
              <View style={{ alignItems: 'center', marginRight: 15 }}>
                  <ProfileMedal points={5000} noOfSponsorships={25} isAmbassador={false} size={54}/>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>point: 5000</Text>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>parrainages: 25</Text>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>ambassadeur: non</Text>
              </View>
            </View>
            <View style={{ flexDirection: 'row', marginTop: 10, alignItems: 'center' }}>
              <View style={{ alignItems: 'center', marginRight: 15 }}>
                  <ProfileMedal points={10000} noOfSponsorships={50} isAmbassador={true} size={54}/>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>point: 10 000</Text>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>parrainages: 50</Text>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>ambassadeur: oui</Text>
              </View>
              <View style={{ alignItems: 'center', marginRight: 15 }}>
                  <ProfileMedal points={50000} noOfSponsorships={100} isAmbassador={true} size={54}/>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>point: 50 000</Text>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>parrainages: 100</Text>
                  <Text style={[styles.elementText, { fontSize: 12 }]}>ambassadeur: oui</Text>
              </View> 
            </View>           
          </View>   
          <View style={styles.element}>
            <CustomDatePicker onDateChange={handleDateChange}/>
          </View>             
        </ScrollView>
    </SafeAreaView>);
};
export default InProcess;
