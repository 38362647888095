var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { View, Modal, Text, TouchableOpacity, StyleSheet, Alert } from 'react-native';
import { launchCamera, launchImageLibrary } from 'react-native-image-picker';
import { checkCameraAndStoragePermission } from '../../GeneralSection/Permissions/PermissionsAndroid';
import { useSelector } from 'react-redux';
const ProfileImagePickerAndroid = ({ onImageSelected, modalVisible, setModalVisible }) => {
    const { translations } = useSelector((state) => state.language);
    const handleTakePhoto = () => __awaiter(void 0, void 0, void 0, function* () {
        const hasCameraPermission = yield checkCameraAndStoragePermission();
        if (!hasCameraPermission) {
            Alert.alert('Permission refusée', 'La permission de caméra est nécessaire pour prendre une photo.');
            return;
        }
        launchCamera({ mediaType: 'photo', includeBase64: true }, (response) => {
            if (response.didCancel) {
                Alert.alert('Annulé');
            }
            else if (response.errorCode) {
                Alert.alert('Erreur de la caméra', response.errorMessage || 'Une erreur inconnue est survenue.');
            }
            else if (response.assets && response.assets[0].uri) {
                onImageSelected({ file: new File([response.assets[0].uri], response.assets[0].uri.split('/').pop() || '', { type: response.assets[0].type, lastModified: Date.now() }), imageUrl: response.assets[0].uri });
            }
        });
    });
    const handleChooseFromLibrary = () => __awaiter(void 0, void 0, void 0, function* () {
        const hasStoragePermission = yield checkCameraAndStoragePermission();
        if (!hasStoragePermission) {
            Alert.alert('Permission refusée', 'La permission de lire le stockage est nécessaire pour choisir une photo.');
            return;
        }
        launchImageLibrary({ mediaType: 'photo', includeBase64: true }, (response) => {
            if (response.didCancel) {
                Alert.alert('Annulé');
            }
            else if (response.errorCode) {
                Alert.alert('Erreur de la bibliothèque', response.errorMessage || 'Une erreur inconnue est survenue.');
            }
            else if (response.assets && response.assets[0].uri) {
                onImageSelected({ file: new File([response.assets[0].uri], response.assets[0].uri.split('/').pop() || '', { type: response.assets[0].type, lastModified: Date.now() }), imageUrl: response.assets[0].uri });
            }
        });
    });
    return (<View style={{ marginVertical: 10 }}>
      <Modal animationType="slide" transparent={true} visible={modalVisible} onRequestClose={() => setModalVisible(!modalVisible)}>
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <TouchableOpacity style={styles.button} onPress={() => {
            handleTakePhoto();
            setModalVisible(!modalVisible);
        }}>
              <Text style={styles.textStyle}>{translations.JsonImagePickerModal.JsonTakePicture}</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.button} onPress={() => {
            handleChooseFromLibrary();
            setModalVisible(!modalVisible);
        }}>
              <Text style={styles.textStyle}>{translations.JsonImagePickerModal.JsonLibrary}</Text>
            </TouchableOpacity>

            <TouchableOpacity style={styles.button} onPress={() => setModalVisible(!modalVisible)}>
              <Text style={styles.textStyle}>{translations.JsonActivityPages.JsonActivityScreen.JsonCancel}</Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </View>);
};
const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
        backgroundColor: '#2196F3',
        marginBottom: 10,
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
});
export default ProfileImagePickerAndroid;
