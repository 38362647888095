import axios from 'axios';
import { hostname } from '../../../hostName/hostName';
const Axios = axios.create({
    baseURL: `${hostname}`
});
const setToken = (token) => {
    console.log("the token in setToken", token);
    Axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
    console.log("the header after set theb= token ", Axios.defaults.headers.common.Authorization);
};
export default Axios;
export { setToken };
