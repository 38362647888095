import { StyleSheet } from "react-native";
const constStyle = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#EEEEEE',
        paddingTop: 50,
    },
    logoContainer: {
        alignSelf: 'center',
    },
    emoji: {
        fontSize: 100,
        marginBottom: 50,
    },
    element: {
        flexDirection: 'row',
        width: 400,
        backgroundColor: '#f0f8ff',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        elevation: 2,
        marginBottom: 20,
    },
    elementVectors: {
        width: 56,
        height: 56,
    },
    elementTitle: {
        marginLeft: 50,
        fontWeight: 'bold',
        fontSize: 14,
    },
    elementText: {
        fontWeight: 'normal',
    },
});
export default constStyle;
