var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ActivityServices } from "./ActivityServices";
import { userServices } from "../user/UserServices";
import AsyncStorage from "@react-native-async-storage/async-storage";
// Function to get the likes array from an activity
const getActivityLikesArray = (activity) => __awaiter(void 0, void 0, void 0, function* () {
    const likesArray = activity.likes || [];
    return likesArray;
});
// Function to check if a user has liked an activity
const isUserLikedActivity = (activity) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userId = yield AsyncStorage.getItem('userId');
        if (!userId) {
            throw new Error('User ID is null or undefined');
        }
        return activity.likes.includes(userId);
    }
    catch (error) {
        console.error('Error checking if user liked activity:', error);
        return false;
    }
});
// Function to add a like to an activity
const addActivityLike = (activity) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userId = yield AsyncStorage.getItem('userId');
        if (!userId) {
            throw new Error('User ID is null or undefined');
        }
        const updatedActivity = Object.assign(Object.assign({}, activity), { likes: [...activity.likes, userId] });
        const response = yield ActivityServices.updateActivity(activity._id, updatedActivity);
        return response.data.likes;
    }
    catch (error) {
        console.error('Error adding like:', error);
        return undefined;
    }
});
// Function to remove a like from an activity
const removeActivityLike = (activity) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userId = yield AsyncStorage.getItem('userId');
        if (!userId) {
            throw new Error('User ID is null or undefined');
        }
        const updatedLikes = activity.likes.filter(like => like !== userId);
        const updatedActivity = Object.assign(Object.assign({}, activity), { likes: updatedLikes });
        const response = yield ActivityServices.updateActivity(activity._id, updatedActivity);
        return response.data.likes;
    }
    catch (error) {
        console.error('Error removing like:', error);
        return undefined;
    }
});
// Function to get activities liked by the current user
const getUserLikedActivities = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userId = yield AsyncStorage.getItem('userId');
        if (!userId) {
            throw new Error('User ID is null or undefined');
        }
        const user = yield userServices.getUserById(userId);
        return user.activityLikes;
    }
    catch (error) {
        console.error('Error getting user liked activities:', error);
        return undefined;
    }
});
// Function to add a like to an activity for the user
const addUserLikeActivity = (activityId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userId = yield AsyncStorage.getItem('userId');
        if (!userId) {
            throw new Error('User ID is null or undefined');
        }
        const user = yield userServices.getUserById(userId);
        const updatedLikes = [...user.activityLikes, activityId];
        const response = yield userServices.updateUser(userId, Object.assign(Object.assign({}, user), { activityLikes: updatedLikes }));
        return response.data.user.activityLikes;
    }
    catch (error) {
        console.error('Error adding user liked activity:', error);
        return undefined;
    }
});
// Function to remove a like from an activity for the user
const removeUserLikeActivity = (activityId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const userId = yield AsyncStorage.getItem('userId');
        if (!userId) {
            throw new Error('User ID is null or undefined');
        }
        const user = yield userServices.getUserById(userId);
        const updatedLikes = user.activityLikes.filter((id) => id !== activityId);
        const response = yield userServices.updateUser(userId, Object.assign(Object.assign({}, user), { activityLikes: updatedLikes }));
        return response.data.user.activityLikes;
    }
    catch (error) {
        console.error('Error removing user liked activity:', error);
        return undefined;
    }
});
export const ActivityLikeServices = {
    addActivityLike,
    removeActivityLike,
    getActivityLikesArray,
    isUserLikedActivity,
    getUserLikedActivities,
    addUserLikeActivity,
    removeUserLikeActivity
};
