//FR  permet d'inviter des amis via WhatsApp
//EN allows users to invite friends via WhatsApp
import React from 'react';
import { Linking, View, Text, Pressable } from 'react-native';
import { useSelector } from 'react-redux';
const InviteByWhatsApp = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const inviteByWhatsApp = () => {
        const message = `${translations.JsonInviteFriendsScreen.JsonInvitationMessage}`;
        const whatsappLink = `whatsapp://send?text=${encodeURIComponent(message)}`;
        Linking.openURL(whatsappLink);
        // Ajoutez ici la logique pour incrémenter et sauvegarder le nombre d'invitations
    };
    return (<View>
      <Pressable onPress={inviteByWhatsApp}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteWhatsapp}</Text>
      </Pressable>
    </View>);
};
export default InviteByWhatsApp;
