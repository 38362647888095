import React, { useState } from 'react';
import { Modal, Platform, Pressable, Text, View } from 'react-native';
import UnsplashPicker from '../UnsplashPicker/Code';
import ProfileImagePickerWeb from '../ImagePicker/ImagePickerWeb';
import ProfileImagePickerAndroid from '../ImagePicker/ImagePickerAndroid';
import ProfileImagePickerIOS from '../ImagePicker/ImagePickerIOS';
import Vectors from '../../../constants/Vectors';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const ActivityImagePicker = ({ modalVisible, setModalVisible, onImageSelected, selectedActivityTitle }) => {
    const { translations } = useSelector((state) => state.language);
    const [modalUnsplashVisible, setModalUnsplashVisible] = useState(false);
    const [platformSpecificModalVisible, setPlatformSpecificModalVisible] = useState(false);
    const PlatformSpecificImagePicker = Platform.select({
        ios: ProfileImagePickerIOS,
        android: ProfileImagePickerAndroid,
        web: ProfileImagePickerWeb,
    });
    const handleImageSelected = (imageUrl) => {
        if (onImageSelected) {
            onImageSelected({ imageUrl: imageUrl, source: 'unsplash' });
        }
        setModalVisible(false);
        setModalUnsplashVisible(false);
        setPlatformSpecificModalVisible(false);
    };
    const openUnsplashPicker = () => {
        setModalVisible(false);
        setModalUnsplashVisible(true);
    };
    const openPlatformSpecificModal = () => {
        setModalVisible(false);
        setPlatformSpecificModalVisible(true);
    };
    const onImageSelectedHandler = (data) => {
        console.log('Selected image from Local Source file:', data.file);
        console.log('Selected image from Local Source imageUrl:', data.imageUrl);
        onImageSelected(Object.assign(Object.assign({}, data), { source: 'file' }));
    };
    return (<View>
            <Modal animationType="slide" transparent={true} visible={modalVisible} onRequestClose={() => setModalVisible(false)}>
                <View style={[styles.modalContainer, { justifyContent: 'flex-end' }]}>
                    <View style={styles.askModalView}>
                        <Pressable style={styles.askSourceBtn} onPress={() => setModalVisible(false)}>
                            <Text> X </Text>
                        </Pressable>
                        <Text style={styles.modalMsgTxt}>
                            {translations.JsonActivityPages.JsonCreateActivityImageModal.JsonPickPhotoFrom}
                        </Text>
                        <View style={styles.personalPicContainer}>
                            <View style={styles.unsplash}>
                                <Pressable style={styles.photoSourceIcon} onPress={openUnsplashPicker}>
                                    <Vectors.GeneralBlackAndWhiteUnsplashFill />
                                </Pressable>
                                <Text style={styles.modalImgTitle}>
                                    {translations.JsonActivityPages.JsonCreateActivityImageModal.JsonPickPhotoFromUnsplash}
                                </Text>
                            </View>
                            <View>
                                <Pressable style={styles.photoSourceIcon} onPress={openPlatformSpecificModal}>
                                    <Vectors.GeneralBlackAndWhiteImageGallery />
                                </Pressable>
                                <Text style={styles.modalImgTitle}>
                                    {translations.JsonActivityPages.JsonCreateActivityImageModal.JsonPickPhotoFromLibrary}
                                </Text>
                            </View>
                        </View>
                    </View>
                </View>
            </Modal>
            <UnsplashPicker modalVisible={modalUnsplashVisible} setModalVisible={setModalUnsplashVisible} onImageSelected={handleImageSelected} searchTermInitial={selectedActivityTitle}/>

            {PlatformSpecificImagePicker && (<PlatformSpecificImagePicker onImageSelected={onImageSelectedHandler} modalVisible={platformSpecificModalVisible} setModalVisible={setPlatformSpecificModalVisible}/>)}
        </View>);
};
export default ActivityImagePicker;
