import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    addressContent: {
        marginTop: '5%',
        width: '80%'
    },
    btnContainer: {
        paddingHorizontal: 25,
        flexDirection: 'row',
        marginVertical: '10%',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
        marginTop: '10%',
        zIndex: -2
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
