// FR page d'écran de démarrage(FrameFigma001)
// EN the application's startup screen(FrameFigma001)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useFocusEffect } from '@react-navigation/native';
import { Image, ImageBackground, Platform, Text, TouchableOpacity, View, Dimensions } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Logo from '../../../assets/Images/General/LogoSocializus.png';
import liveConcert from '../../../assets/Images/Home/LiveConcert.png';
import { useSelector } from 'react-redux';
const { width, height } = Dimensions.get('window');
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const StartScreen = ({ navigation }) => {
    // const {translations, changeLanguage} = useLanguage();
    const { language, translations } = useSelector((state) => state.language);
    console.log("the object language ", language);
    const LogoSection = () => <Image source={Logo} style={styles.logo}/>;
    const LanguageBtnSection = () => {
        const [selectedLanguage, setSelectedLanguage] = useState('');
        useFocusEffect(React.useCallback(() => {
            const refreshLanguage = () => __awaiter(void 0, void 0, void 0, function* () {
                const selectedLanguageName = yield AsyncStorage.getItem('selectedLanguageName');
                setSelectedLanguage(selectedLanguageName || translations.JsonHomeScreen.JsonSelectLanguage);
            });
            refreshLanguage();
        }, [translations]));
        const handleChangeLanguage = () => navigation.navigate('Languages');
        return (<View style={styles.languageBtn}>
        <TouchableOpacity onPress={handleChangeLanguage}>
          <Text>{selectedLanguage}</Text>
        </TouchableOpacity>
      </View>);
    };
    const StartSection = () => (<View style={styles.content}>
      <View style={styles.DescriptionTxtView}>
        <Text style={styles.DescriptionTxt}>{translations.JsonHomeScreen.JsonShareActivities}</Text>
      </View>
      <View style={styles.btnContainer}>
        <TouchableOpacity style={styles.btnStart} onPress={() => navigation.navigate('Email')}>
          <Text style={styles.btnText}>{translations.JsonHomeScreen.JsonStart}</Text>
        </TouchableOpacity>
      </View>
    </View>);
    return (<View key={language} style={styles.container}>
      <ImageBackground source={liveConcert} style={styles.backImg}>
        <LanguageBtnSection />
        <LogoSection />
        <StartSection />
      </ImageBackground>
    </View>);
};
export default StartScreen;
