import React from 'react';
import { View, Text, Platform } from 'react-native';
import countriesListCircleFlags from '../../../../components/GeneralSection/LanguagesFlagsComponent/LanguagesFlags';
import { activitiesIcon } from '../../../../constants/VectorsOnOff/ActivityListIcon';
import { useSelector } from 'react-redux';
const PublicProfileInfoScreen = ({ profileOwner }) => {
    var _a;
    const styles = Platform.OS === 'web'
        ? require('./StylesWeb').default
        : require('./StylesMobile').default;
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const LanguageFlagNative = countriesListCircleFlags.find((flag) => flag.bigram === (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.nativeLanguage));
    const mapLanguagesToFlags = (Languages) => {
        return Languages === null || Languages === void 0 ? void 0 : Languages.map((language) => {
            const flag = countriesListCircleFlags.find((flag) => flag.bigram === language);
            return flag === null || flag === void 0 ? void 0 : flag.FlagComponent;
        });
    };
    const languageFlagsSpoken = mapLanguagesToFlags(profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.spokenLanguage);
    const userActivityVectors = activitiesIcon.filter((icon) => {
        var _a;
        return (_a = profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.activitiesTopic) === null || _a === void 0 ? void 0 : _a.some((topic) => Number(topic) === Number(icon.id));
    });
    return (<View style={styles.container}>
            <View style={styles.infoContainer}>
                {profileOwner && (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.about) ? (<Text style={styles.aboutDescription}>{profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.about}</Text>) : (<Text>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNoUserAbout}</Text>)}
            </View>
            <View style={[styles.detailsContainer, styles.pad20]}>
                <View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonCity}: </Text>
                        <Text style={styles.text}>{(_a = profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.city) === null || _a === void 0 ? void 0 : _a.split(",")[0]}</Text>
                    </View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonChildren}: </Text>
                        <Text style={styles.text}>{profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.children}</Text>
                    </View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonStudies}: </Text>
                        <Text style={styles.text}>{profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.studies}</Text>
                    </View>
                </View>
                <View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonUniversity}: </Text>
                        <Text style={styles.text}>{profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.university}</Text>
                    </View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonAlcohol}: </Text>
                        <Text style={styles.text}>{profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.alcohol}</Text>
                    </View>
                    <View style={styles.individualEntry}>
                        <Text style={styles.bold}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonTobacco}: </Text>
                        <Text style={styles.text}>{profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.tobacco}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.languagesContainer}>
                <View style={styles.individualEntry}>
                    <Text style={styles.langTitle}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNativeLanguage}: </Text>
                    {(profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.nativeLanguage) && LanguageFlagNative ? <LanguageFlagNative.FlagComponent width={20} height={20} style={styles.flags}/> : <Text>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNotSelected}</Text>}
                </View>
                <View style={styles.individualEntry}>
                    <Text style={styles.langTitle}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonSpokenLanguage}: </Text>
                    {(profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.spokenLanguage) && profileOwner.spokenLanguage.length > 0 && languageFlagsSpoken ? languageFlagsSpoken.map((Flag, index) => (Flag && <Flag width={20} height={20} style={styles.flags} key={index}/>)) : <Text>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNotSelected}</Text>}
                </View>
            </View>

            <View style={styles.greenBar}>
                <Text style={styles.greenBarText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonWhatILoveToDo}:</Text>
            </View>
            {userActivityVectors.length > 0
            ? <View style={styles.bottomVectors}>
                    {userActivityVectors.map((icon) => (<View key={icon.id}>
                            {icon.activityIconOn}
                        </View>))}
                </View>
            : <Text>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNotSelected}</Text>}
        </View>);
};
export default PublicProfileInfoScreen;
