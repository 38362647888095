// Import Modules
import React from 'react';
import { Modal, Platform, Text, View } from 'react-native';
// Import Vectors
import { Vectors } from '../../../../constants/Vectors';
import ButtonNext from "../../../ButtonSection/NextButton/Code";
import { useSelector } from "react-redux";
// Import + Export Styles (Importer ici pour usage et exporter les mêmes styles pour utiliser dans les écrans enfants)
const styles = Platform.OS === 'web' ? require('./StylesWeb.tsx').default : require('./StylesMobile.tsx').default;
// Logique de component commence ici --------------------------------------------------
export const OnlyFriendsChatModal = ({ onClose, isVisible }) => {
    const { translations } = useSelector((state) => state.language);
    return (<Modal animationType="fade" transparent={true} visible={isVisible} onRequestClose={onClose}>
            <View style={styles.container}>
                <View style={styles.modal}>
                    <View style={styles.modalIconContainer}>
                        <Vectors.ProfilesConfidential />
                    </View>
                    <View style={styles.modalTitleContainer}>
                        <Text style={styles.modalTitle}>{translations.JsonPrivateChatScreen.JsonPrivateMessageOnlyToFriends}</Text>
                    </View>
                    <View style={styles.innerContainer}>
                        <ButtonNext handleNextStep={onClose} btnText={translations.JsonConfirmFriendshipPopup.JsonButtonClose}/>
                    </View>
                </View>
            </View>
        </Modal>);
};
