var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { View, Text, Pressable, ActivityIndicator, Linking, Animated } from 'react-native';
import { useNavigation } from '@react-navigation/native';
// import components
import ActivityDetailsHeaderComponent from '../../../../../components/ActivitySection/ActivityDetailsHeaderComponent/Code';
import ActivityDetailsAuthorComponent from '../../../../../components/ActivitySection/ActivityDetailsAuthorOrganiserComponent/Code';
import ActivityDetailsScrollBarParticipantsComponent from '../../../../../components/ActivitySection/ActivityDetailsScrollBarParticipantsComponent/Code';
import ActivityDetailsButtonTopComponent from '../../../../../components/ButtonSection/ActivityDetailsTopButton/Code';
import ActivityDetailsButtonsBottomComponent from '../../../../../components/ButtonSection/ActivityDetailsBottomButton/Code';
import OnlineBanner from '../../../../../components/BannerSection/MyActivityOnlineBanner/Code';
import MyButtonPress from '../../../../../components/ButtonSection/PressButton/Code';
//import context
//import vectors
import Vectors from '../../../../../constants/Vectors';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
import { ActivityServices } from '../../../../../services/activities/ActivityServices';
import { userServices } from '../../../../../services/user/UserServices';
import { ParticipantsServices } from '../../../../../services/activities/ParticipantsServices';
//import styles
const styles = require('./StylesWeb').default;
const ActivityDetailsOnlineScreen = () => {
    // const { activityView, setActivityView, isLoading } = useActivity();
    const { activityView } = useSelector((state) => state.activities);
    // const { user, activitiesLoading } = useUser();
    const { user } = useSelector((state) => state.user);
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const navigation = useNavigation();
    const setActivityView = (data) => {
        setIsLoading(true);
        dispatch(ActivitiesSlice.actions.setActivityView({ id: nanoid(), value: data }));
        setIsLoading(false);
    };
    const joinActivity = (activity, userId) => __awaiter(void 0, void 0, void 0, function* () {
        const activityJoin = Object.assign(Object.assign({}, activity), { attendees: [...activity.attendees, userId] });
        const userJoin = Object.assign(Object.assign({}, user), { activities: [...user.activities, activity._id], status: 'online' });
        try {
            yield ActivityServices.updateActivity(activity._id, activityJoin);
            // await ActivityServices.joinActivity(activity._id, userId,  inWaitingList=false);
            yield userServices.updateUser(user._id, userJoin);
            setActivityView(Object.assign(Object.assign({}, activity), { attendees: [...activity.attendees, user._id] })); // mets à jour le contexte
            {
                activity.infoLine === '' ? navigation.navigate('Address') : navigation.navigate('Online');
            }
        }
        catch (error) {
            console.log('Error joining activity', error);
        }
    });
    const leaveActivity = (activity, userId) => __awaiter(void 0, void 0, void 0, function* () {
        const ActivityLeave = Object.assign(Object.assign({}, activity), { attendees: activity.attendees.filter((id) => id !== userId) });
        const userLeave = Object.assign(Object.assign({}, user), { status: 'online', activities: user.activities.filter((id) => id !== activity._id) });
        try {
            yield ActivityServices.updateActivity(activity._id, ActivityLeave);
            // await ActivityServices.leaveActivity(activity._id, userId, inWaitingList=false);
            yield userServices.updateUser(user._id, userLeave);
            navigation.navigate('Activities');
        }
        catch (error) {
            console.log('Error leaving activity', error);
        }
    });
    const handleParticipateAction = (activity) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (!!ParticipantsServices.isUserParticipate(activity, user._id)) {
                yield leaveActivity(activity, user._id);
                navigation.navigate('Activities');
            }
            else {
                yield joinActivity(activity, user._id);
                setActivityView(Object.assign(Object.assign({}, activity), { attendees: [...activity.attendees, user._id] })); // mets à jour le contexte
                {
                    activity.infoLine === '' ? navigation.navigate('Address') : navigation.navigate('Online');
                }
            }
        }
        catch (error) {
            console.log('Error joining or leaving activity', error);
        }
    });
    return (<>
            {activityView === undefined || activityView === null || !activityView ?
            <>
                    <View style={styles.container}>
                        <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityDeleted}</Text>
                        <Pressable onPress={() => navigation.navigate('Activities')}>
                            <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonViewAllActivities}</Text>
                        </Pressable>
                    </View>
                </>
            :
                <>
                    {isLoading ?
                        <ActivityIndicator />
                        :
                            <>
                            <Animated.ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
                                <View style={styles.top}>
                                    <ActivityDetailsHeaderComponent />
                                    {/* <View style={styles.image}> */}
                                    <Vectors.GeneralColorsOnlineMeeting width={450} height={253}/>
                                    {/* </View> */}
                                    <ActivityDetailsAuthorComponent />
                                    <ActivityDetailsScrollBarParticipantsComponent />
                                    <ActivityDetailsButtonTopComponent cbParticipate={() => handleParticipateAction(activityView)} cbLeave={() => alert('Leave')} cbInvite={() => alert('Invite')} id={user._id} act={activityView}/>
                                </View>
                                <View style={styles.middle}>
                                    <View style={styles.displayBannerContainer}>
                                        <OnlineBanner Json={translations.JsonActivityPages.JsonMyActivitiesScreen.JsonOnlineMeetingOnly}/>
                                    </View>

                                    <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                        <View style={{ marginHorizontal: 20 }}>
                                            <Vectors.GeneralColorsLinkChain width={34} height={34}/>
                                        </View>
                                        <MyButtonPress text={'Click here to Join'} cb={() => Linking.openURL(activityView.infoLine)} txtCol={'white'} txtS={18} txtFW={'600'} w={200} h={55} bgC={'black'} br={20}/>
                                    </View>
                                </View>
                                <View style={styles.bottom}>
                                    <View style={styles.containerButtonBottom}>
                                        <ActivityDetailsButtonsBottomComponent id={user._id}/>
                                    </View>
                                </View>
                            </Animated.ScrollView>
                        </>}
                </>}
        </>);
};
export default ActivityDetailsOnlineScreen;
