import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
    },
    img: {
        resizeMode: 'stretch',
        height: 400,
        width: '100%',
    },
    content: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginVertical: 25,
        marginLeft: 10,
        width: '95%',
    },
    titleTxt: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'black',
    },
    descriptionContent: {
        width: '90%',
        alignSelf: 'center',
    },
    descriptionTxt: {
        fontSize: 18,
        textAlign: 'center',
        color: 'black',
        lineHeight: 25,
    },
});
