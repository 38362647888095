//🇫🇷 Ce fichier gère le style web du composant NewUserPasswordScreen.js (FrameFigma006b)
//🇬🇧 This file manages the web style of the NewUserPasswordScreen.js component (FrameFigma006b)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    passwordContent: {
        marginTop: '20%',
        marginHorizontal: '5%',
        width: '80%',
    },
    terms: {
        fontSize: 12,
        textAlign: 'center',
    },
    condition: {
        marginVertical: '5%',
        width: '60%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
    },
    underline: {
        fontSize: 12,
        textDecorationLine: 'underline',
        textAlign: 'center',
    },
    buttonContainer: {
        //alignItems: 'flex-start',
        alignItems: 'center',
        flexDirection: 'row',
        marginVertical: '10%',
        justifyContent: 'space-between',
        width: '100%',
        paddingLeft: 25,
        paddingRight: 25,
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
