import React, { useState } from "react";
import { View, TouchableOpacity, Platform } from 'react-native';
import { Vectors } from "../../../../constants/Vectors";
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const StarRating = ({ onRatingChange, height = 24, width = 24 }) => {
    const [rating, setRating] = useState(0); // (FR) Etat de la note - (EN) Note status
    // (FR) Permet la modification de la note - (EN) Enables note modification
    const handleStarPress = (starValue) => {
        setRating(starValue);
        onRatingChange(starValue);
        console.log(`Note sélectionnée: ${starValue}`);
    };
    return (<View style={styles.container}>
			{[1, 2, 3, 4, 5].map((starValue) => (<TouchableOpacity key={starValue} onPress={() => handleStarPress(starValue)}>
					{starValue <= rating ? (<Vectors.ProfileStar width={width} height={height}/>) : (<Vectors.ProfileStaro width={width} height={height}/>)}
				</TouchableOpacity>))}
		</View>);
};
export default StarRating;
