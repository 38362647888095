// Fr  Styles de EditClassicOrStepByStepActivityScreen( FrameFigma031 )
// En  Styles of EditClassicOrStepByStepActivityScreen( FrameFigma031 )
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
    },
    text: {
        marginTop: 15,
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18,
    },
    questionContainer: {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: '5%',
    },
    textQuestion: {
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        fontSize: 18,
    },
    classic: {
        borderWidth: 1,
        width: '40%',
        borderTopLeftRadius: 13,
        borderBottomLeftRadius: 13,
        padding: 7,
    },
    withStep: {
        borderWidth: 1,
        width: '40%',
        borderTopRightRadius: 13,
        borderBottomRightRadius: 13,
        padding: 7,
    },
    input: {
        width: '90%',
        marginBottom: '2%',
    },
    activeQuestion: {
        backgroundColor: '#59C09B',
    },
    dateContainer: {
        width: '90%',
        flexDirection: 'row',
        alignItems: 'center',
    },
    switchBtn: {
        width: '90%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    textbtn: {
        fontSize: 18,
        fontWeight: 'bold',
        marginVertical: 10,
    },
});
