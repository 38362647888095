import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        paddingTop: 10,
    },
    socialContainer: {
        borderRadius: 15,
        padding: 10,
    },
    logoContainer: {
        width: 150,
        height: 150,
        alignSelf: 'center',
        paddingBottom: 15,
        paddingTop: 10,
    },
    logo: {
        width: 135,
        height: 135,
    },
    row: {
        flexDirection: 'row',
        marginBottom: 15,
    },
    socialWrapper: {
        flex: 1,
        height: 139,
        backgroundColor: '#EEEEEE',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 10,
        marginHorizontal: 30,
    },
    social: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    SettingsIcon: {
        alignSelf: 'center',
        marginBottom: 5,
        width: 54,
        height: 54,
    },
    containerTxt: {
        textTransform: 'uppercase',
        fontFamily: 'Poppins',
        fontSize: 16,
        fontWeight: '500',
        color: '#000000',
        textAlign: 'center',
        lineHeight: 24,
    },
});
export default styles;
