import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    NotificationsVectors: {
        width: 30,
        height: 30,
    },
    activityCard: {
        marginVertical: 15,
        marginHorizontal: 10,
        height: 130,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 20,
        backgroundColor: '#feebef',
    },
    activityCardLiked: {
        backgroundColor: '#feebef',
    },
    activityCardParticipant: {
        backgroundColor: '#D5EFE6',
    },
    activityCardWaitingList: {
        backgroundColor: '#FFF1C9',
    },
    leftSide: {
        marginTop: '40%',
        height: '100%',
        width: 123,
    },
    activityImage: {
        width: 124,
        height: 70,
        backgroundColor: 'white',
    },
    imageContainer: {
        width: 124,
        height: '100%',
    },
    activityImageOne: {
        width: '100%',
        height: '100%',
        borderTopLeftRadius: 20,
        resizeMode: 'contain',
    },
    componentHeart: {
        position: 'absolute',
        top: 5,
        left: 6,
        width: 12,
        height: 12,
    },
    rightSide: {
        flex: 1,
        height: '100%',
        justifyContent: 'space-between',
        borderTopRightRadius: 20,
    },
    centerItemUp: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        paddingTop: 5,
        maxWidth: '100%',
    },
    centerItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        paddingLeft: 10,
    },
    activityTitleContainer: {
        maxWidth: '85%',
    },
    activityTitle: {
        fontWeight: 'bold',
        fontSize: 14,
        marginTop: '5%',
        marginLeft: '8%',
        flexWrap: 'wrap',
    },
    authorImageView: {
        paddingBottom: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    authorImage: {
        marginTop: '10%',
        width: 57,
        height: 57,
        borderRadius: 50,
        justifyContent: 'center',
    },
    bottomItem: {
        flex: 1,
        flexDirection: 'row',
        marginLeft: '35%',
    },
    bottomItemCenter: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    textParticipants: {
        marginLeft: 5,
        fontWeight: 'bold',
    },
    timeContainer: {
        backgroundColor: '#F48A0D',
        borderBottomRightRadius: 20,
        borderTopLeftRadius: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 10,
        minWidth: 81,
        minHeight: 23,
        fontSize: 5,
    },
    activityTime: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 12,
    },
    userStatusTagContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        borderTopRightRadius: 20,
        borderBottomLeftRadius: 20,
    },
    userStatusTag: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 124,
        height: 30,
        borderBottomLeftRadius: 20,
        borderTopRightRadius: 20,
    },
    userStatusTagParticipant: {
        backgroundColor: '#59C09B',
    },
    userStatusTagWaitingList: {
        backgroundColor: '#FFC107',
    },
    userStatusTagTopic: {
        backgroundColor: '#000000',
    },
    userStatusTagText: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 18,
    },
    iconContainer: {
        height: 17,
        width: 17,
        justifyContent: 'center',
        alignItems: 'center',
    },
    circle: {
        height: 17,
        width: 17,
        borderRadius: 8.5,
        borderColor: '#59C09B',
        borderWidth: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'white',
        marginTop: '80%',
        marginLeft: '80%',
    },
    circleText: {
        fontFamily: 'Inter-Regular',
        textAlign: 'center',
        textAlignVertical: 'center',
        color: "#59C09B",
        fontWeight: 'bold',
    },
});
