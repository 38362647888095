import { StyleSheet, Dimensions } from "react-native";
const { width, height } = Dimensions.get('window');
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        maxHeight: height * 0.3,
        maxWidth: width * 0.4
    },
    toggleAspectButton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
        backgroundColor: '#59C09B',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#ccc',
        marginBottom: 10,
    },
    toggleAspectButtonDisabled: {
        backgroundColor: '#ccc',
    },
    toggleAspectButtonText: {
        color: '#000',
    },
    toggleAspectButtonDisabledText: {
        color: '#999',
    },
    saveButton: {
        backgroundColor: '#E8E8E8',
        borderRadius: 50,
        padding: 10,
        marginVertical: 10,
    },
});
export default styles;
