import React, { useState } from 'react';
import { Modal, Text, View, Pressable, Platform } from 'react-native';
// Import Components
import SelectButtonWithIcon from '../../../ButtonSection/SelectWithIconButton/Code';
import { Step1InputScreen } from '../../AddFriendPopUpSection/Step1InputScreen/Code';
// Import Contexts
// Import Vectors
import { Vectors } from '../../../../constants/Vectors';
import { useSelector } from 'react-redux';
// Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb.tsx').default : require('./StylesMobile.tsx').default;
// Component
const AddFriendModal = ({ visible, onClose, currentUser }) => {
    const [selectedButton, setSelectedButton] = useState(null);
    const [buttonClicked, setButtonClicked] = useState(false);
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const t = translations.JsonConfirmFriendshipPopup;
    const buttons = [
        { id: 1, text: 'Email', icon: <Vectors.ProfilesEmail /> },
        { id: 2, text: 'Member Number', icon: <Vectors.ProfilesMemberNumber /> },
    ];
    if (currentUser.useMyPhone) {
        buttons.push({ id: 3, text: 'Phone', icon: <Vectors.ProfilesPhone /> });
    }
    if (currentUser.useMyBirthday) {
        buttons.push({ id: 4, text: 'Birthday', icon: <Vectors.ProfilesBirthday /> });
    }
    if (currentUser.useMyLastName) {
        buttons.push({ id: 5, text: 'Last Name', icon: <Vectors.ProfilesLastName /> });
    }
    const handleButtonPress = (button) => {
        setSelectedButton(button);
    };
    const handleBack = () => {
        setSelectedButton(null);
        setButtonClicked(false);
    };
    return (<Modal animationType="fade" transparent={true} visible={visible} onRequestClose={onClose}>
      <View style={styles.container}>
        <View style={styles.modal}>
          {selectedButton && buttonClicked ? (<Step1InputScreen selectedButton={selectedButton} onBack={handleBack} currentUser={currentUser} onClose={onClose}/>) : (<>
              <Pressable style={styles.close} onPress={onClose}>
                <Vectors.CloseSignGreen style={styles.NotificationsVectors}/>
              </Pressable>

              <View style={styles.modalTitleContainer}>
                <Text style={styles.modalTitle}>{t.JsonPopupConfirmFriendship}</Text>
              </View>

              <View style={styles.btnContainer}>
                {buttons.map(button => (<View key={button.id} style={styles.buttonItem}>
                    <SelectButtonWithIcon text={button.text} icon={button.icon} selected={(selectedButton === null || selectedButton === void 0 ? void 0 : selectedButton.id) === button.id} onPress={() => handleButtonPress(button)}/>
                  </View>))}
              </View>

              <View>
                <Pressable style={[styles.confirmButton, { backgroundColor: selectedButton ? '#59C09B' : '#6f6f6f' }]} onPress={() => setButtonClicked(true)} disabled={!selectedButton}>
                  <Text style={styles.confirmButton.buttonText}>
                    {translations.JsonCreateProfilePages.JsonContinueButton}
                  </Text>
                  <Vectors.RightArrowWhite style={styles.NotificationsVectors}/>
                </Pressable>
              </View>
            </>)}
        </View>
      </View>
    </Modal>);
};
export default AddFriendModal;
