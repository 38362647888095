import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        paddingHorizontal: 25,
    },
    textDescription: {
        textAlign: 'center',
        fontFamily: 'Roboto',
        fontSize: 16,
        fontWeight: '500',
        marginTop: 30,
    },
    inputContainer: {
        width: '100%',
        marginTop: 30,
    },
    btnContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        marginTop: 60,
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
