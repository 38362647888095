import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    drawerHeader: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#59C09B',
        height: '15%',
        top: '-2%',
    },
    profileImage: {
        width: 60,
        height: 60,
        borderRadius: 30,
    },
    userInfosContainer: {
        marginLeft: '3%',
        flex: 1,
        flexDirection: 'row',
    },
    userNameContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    userNameMale: {
        color: 'blue',
        fontSize: 15,
        fontWeight: 'bold',
    },
    userNameFemale: {
        color: 'pink',
        fontSize: 15,
        fontWeight: 'bold',
    },
    trophyContainer: {
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex-end',
        marginLeft: 'auto',
        marginRight: '5%',
    },
    textPoint: {
        marginTop: '2%',
        fontSize: 12,
        fontWeight: 'bold',
        color: 'black'
    },
    drawerItem: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 15,
    },
    drawerText: {
        marginLeft: '-10%',
        fontSize: 18,
        color: 'black',
        fontWeight: 'normal',
    },
    drawerTextLogout: {
        marginLeft: '5%',
        color: 'black',
        fontSize: 18,
        fontWeight: 'normal',
    },
    separator: {
        marginTop: '1%',
        height: 1,
        backgroundColor: 'grey',
        width: '70%',
        alignSelf: 'center',
    },
    drawerIcon: {
        width: 24,
        height: 24,
    },
});
