// FR Permet à l'utilisateur de choisir des conditions et des offres spécifiques pour des coorganisateurs potentiels (FrameFigma035A,FrameFigma035B)
// EN "Allow the user to choose specific conditions and offers for potential co-organizers." (FrameFigma035A,FrameFigma035B)
//(FrameFigma035A,FrameFigma035B)
// Import Modules
import React, { useLayoutEffect, useState } from 'react';
import { Platform, Text, View } from 'react-native';
// Import Components
import SelectButtonWithIcon from '../../ButtonSection/SelectWithIconButton/Code';
// Import Contexts / Hooks / Utils
// Import Vectors
import { Vectors } from '../../../constants/Vectors';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
// donner valuer par default pour eviter error liee a .length
const RequestCoOrganisers = ({ coOrgConditionsExisting = '', coOrgOffersExisting = '' }) => {
    console.log('coOrgConditionsExisting', coOrgConditionsExisting);
    console.log('coOrgOffersExisting', coOrgOffersExisting);
    // Contexts
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    // const {currentActivity, setCurrentActivity} = useActivity();
    const { currentActivity } = useSelector((state) => state.activities);
    // States
    const [coOrgConditions, setCoOrgConditions] = useState([]);
    const [coOrgOffers, setCoOrgOffers] = useState([]);
    const dispatch = useDispatch();
    // Fonction pour verifier si un tableau est vide
    const isNullOrEmpty = (arr) => {
        return (Array.isArray(arr) && arr.length === 1 && arr[0] === 'null') || arr.length === 0 || arr === '';
    };
    useLayoutEffect(() => {
        // on verifie si la props est vide
        if (!isNullOrEmpty(coOrgConditionsExisting)) {
            const parsedConditionsExisting = coOrgConditionsExisting.split(',').map(Number); // Les informations sont converties en nombre
            setCoOrgConditions(parsedConditionsExisting);
        }
        if (!isNullOrEmpty(coOrgOffersExisting)) {
            const parsedOffersExisting = coOrgOffersExisting.split(',').map(Number);
            setCoOrgOffers(parsedOffersExisting);
            return () => {
                setCoOrgConditions([]);
                setCoOrgOffers([]);
            };
        }
    }, [coOrgConditionsExisting, coOrgOffersExisting]);
    // Define the buttons
    const conditionButtons = [
        {
            id: 1,
            text: translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerReceivePrivateMessage,
            icon: <Vectors.ProfilesEmail />,
        },
        {
            id: 2,
            text: translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerComeEarly,
            icon: <Vectors.GreenClock />,
        },
    ];
    const giftButtons = [
        {
            id: 1,
            text: translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerOfferDrink,
            icon: <Vectors.FreeDrink />,
        },
        {
            id: 2,
            text: translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerOfferPass,
            icon: <Vectors.FreePass />,
        },
        {
            id: 3,
            text: translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerOfferOtherGift,
            icon: <Vectors.OtherGift />,
        },
    ];
    // Generic function to handle button press
    const handleButtonPress = (button, //Consulter la doc, JSX déprecié
    state, setState, type) => {
        if (state.includes(button.id)) {
            // On verifie si l'id est déjà dans le state
            setState(state.filter(id => id !== button.id));
            dispatch(ActivitiesSlice.actions.setCurrentActivity({
                id: nanoid(), value: Object.assign(Object.assign({}, currentActivity), { [type]: state.filter(id => id !== button.id) })
            }));
        }
        else {
            setState([...state, button.id]);
            dispatch(ActivitiesSlice.actions.setCurrentActivity({
                id: nanoid(), value: Object.assign(Object.assign({}, currentActivity), { [type]: [...state.map(String), button.id.toString()] })
            }));
        }
    };
    return (<View style={styles.container}>
            {/* Condition buttons */}
            <View style={styles.innerContainer}>
                <Text style={styles.sectionTitle}>
                    {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerRequestChoose}
                </Text>
                <View style={styles.btnContainer}>
                    {conditionButtons.map(button => (<SelectButtonWithIcon key={button.id} text={button.text} icon={button.icon} selected={coOrgConditions.includes(button.id)} onPress={() => handleButtonPress(button, coOrgConditions, setCoOrgConditions, 'coOrganizerConditions')}/>))}
                </View>
            </View>

            {/* Offer buttons */}
            <View style={styles.innerContainer}>
                <Text style={styles.sectionTitle}>
                    {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerMyOffer}
                </Text>
                <View style={styles.btnContainer}>
                    {giftButtons.map(button => (<SelectButtonWithIcon key={button.id} text={button.text} icon={button.icon} selected={coOrgOffers.includes(button.id)} onPress={() => handleButtonPress(button, coOrgOffers, setCoOrgOffers, 'coOrganizerGifts')}/>))}
                </View>
            </View>
        </View>);
};
export default RequestCoOrganisers;
