var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Axios from "../Axios/AxiosService";
import { GOOGLE_MAPS_API_KEY } from '@env';
import { setGeoLocalistaionCoords } from "../../utils/Functions/Geolocation";
// Maps suggestions Web and Mobile
const getGoogleMapsWebAddressSuggestion = (url) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield Axios.get(`/api/places/autocomplete?input=${url}`);
        console.log('response WebAddress suggestion:', response);
        return response.data.predictions;
    }
    catch (error) {
        console.error('axios getGoogleMapsWebAddressSuggestion error', error);
        return;
    }
});
const getGoogleMapsMobileAddressSuggestion = (url) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch(`https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${url}&key=${GOOGLE_MAPS_API_KEY}&language=en`);
        const data = yield response.json();
        console.log('response Mobile Address suggestion:', data);
        return data.predictions;
    }
    catch (error) {
        console.error('fetch getGoogleMapsMobileAddressSuggestion error', error);
        return;
    }
});
// display Maps Address Web and Mobile
const setGoogleMapsWebAddress = (suggestion) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield Axios.get(`/api/places/details?placeid=${suggestion}`);
        return response.data.result;
    }
    catch (error) {
        console.error('axios setGoogleMapsWebAddress error:', error);
        return;
    }
});
const setGoogleMapsMobileAddress = (suggestion) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch(`https://maps.googleapis.com/maps/api/place/details/json?placeid=${suggestion}&key=${GOOGLE_MAPS_API_KEY}&language=en`);
        const data = yield response.json();
        return data.result;
    }
    catch (error) {
        console.error('axios setGoogleMapsMobileAddress error:', error);
        return;
    }
});
// Maps Url Web and Mobile
const getCoordsMapsUrl = (googleUrl, setter) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const encodedUrl = encodeURIComponent(googleUrl);
        const response = yield Axios.get(`/api/maps?url=${encodedUrl}`);
        if (response) {
            setter(false);
            const { lat, lng } = response.data;
            return { lat, lng };
        }
    }
    catch (error) {
        setter(true);
        console.log('axios setGoogleMapsUrl error:', error);
    }
});
// inutilisé, ancienement pour code Postal ??
const getGoogleMapsDetails = (lat, lng) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        console.log("the parameters", lat, lng);
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`;
        const geocodeResponse = yield fetch(geocodeUrl);
        const geocodeData = yield geocodeResponse.json();
        console.log('geocodeData', geocodeData);
        return geocodeData;
    }
    catch (error) {
        console.error('getGoogleMapsDetails error:', error);
        return;
    }
});
const getMyGPSwebCoords = () => __awaiter(void 0, void 0, void 0, function* () {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            console.log('lat:', position.coords.latitude);
            console.log('lng:', position.coords.longitude);
            // setCoords({lat: position.coords.latitude, lng: position.coords.longitude})
            setGeoLocalistaionCoords({ latitude: position.coords.latitude, longitude: position.coords.longitude });
        }, () => console.error('Geolocation failed or permission denied'));
    }
});
// const getMyGoogleUrlbyPlaceId = async (placeId: string): Promise<any | undefined> => {
//     try {
//         const response = await Axios.get(`/api/maps/placeid/${placeId}`);
//         return response.data;
//     } catch (error) {
//         console.error('axios getMyGoogleUrlbyPlaceId error:', error);
//         return;
//     }
// }
export const GoogleMapsApiServices = {
    getGoogleMapsWebAddressSuggestion,
    // getGoogleMapsWebUrlSuggestion,
    getGoogleMapsMobileAddressSuggestion,
    // getGoogleMapsMobileUrlSuggestion,
    setGoogleMapsWebAddress,
    // setGoogleMapsWebUrl,
    setGoogleMapsMobileAddress,
    getCoordsMapsUrl,
    getGoogleMapsDetails,
    getMyGPSwebCoords,
    // getMyGoogleUrlbyPlaceId
};
