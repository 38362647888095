//🇫🇷 Le fichier JSON (front-mobile/assets/json) permet d'afficher les différentes variables avec différentes langues, voir le dossier src/json/En.json
//🇬🇧 The JSON file (front-mobile/assets/json) allowing you to display the different variables with different languages ​​is in the src/json/En.json folder
// 🇫🇷 Ce fichier gère la page qui permet d'interagir avec notifications. (FrameFigma024C )
// 🇬🇧 This file manages the page used to interact with our notifications.  (FrameFigma024C )
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { View, Text, ScrollView, Platform } from 'react-native';
import SwitchBtn from '../../../components/ButtonSection/SwitchButton/Code';
import { hostname } from '../../../../hostName/hostName';
import axios from 'axios';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const NotificationsManageScreen = ({ user }) => {
    const { allUsers } = useSelector((state) => state.user);
    const { activities } = useSelector((state) => state.activities);
    const { userToken } = useSelector((state) => state.auth);
    const { translations } = useSelector((state) => state.language);
    const [userActivities, setUserActivities] = useState([]);
    const [activityIds, setActivityIds] = useState([]);
    const [organisersMet, setOrganisersMet] = useState([]);
    const [activityTopics, setActivityTopics] = useState([]);
    // manque recuprearation de activityTopics
    const [notificationsStateUser, setNotificationsStateUser] = useState({
        visitorNotification: false,
        eventCommentsNotification: false,
        friendRequest: false,
        friendBirthday: false,
        likeNotification: false,
        activityLikeNotifications: false,
        activityInMyCity: false,
        activityByTopics: false,
        organisersMetNotification: false,
    });
    const [notificationsStateActivity, setNotificationsStateActivity] = useState({
        activitiesRemindNotification: false,
        activityNearCompletion: false,
        activityCompleted: false,
        activityDiscussion: false,
        activityReminder: false,
        organizerUpdate: false,
    });
    const updateNotificationPreferencesUser = (settings) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield axios.put(`${hostname}/updateNotifications/${user._id}`, settings, {
                headers: {
                    Authorization: `Bearer ${userToken}`,
                },
            });
            console.log('Notifications settings updated:', response.data);
        }
        catch (error) {
            console.error('Failed to update notifications settings:', error);
        }
    });
    const updateNotificationPreferencesActivity = (settings) => __awaiter(void 0, void 0, void 0, function* () {
        const userId = user._id;
        console.log(activityIds, userActivities);
        activityIds.map((id) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield axios.put(`${hostname}/activities/${id}/updateNotifications`, { settings, userId }, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                console.log('Notifications settings updated:', response.data);
            }
            catch (error) {
                console.error('Failed to update notifications settings:', error);
            }
        }));
    });
    const toggleNotification = (notificationKey, notificationType) => {
        const notificationsState = notificationType === "user" ? notificationsStateUser : notificationsStateActivity;
        const newSettings = Object.assign(Object.assign({}, notificationsState), { [notificationKey]: !notificationsState[notificationKey] });
        if (notificationType === "user") {
            setNotificationsStateUser(newSettings);
            updateNotificationPreferencesUser({ notificationSettings: newSettings });
        }
        else {
            setNotificationsStateActivity(newSettings);
            updateNotificationPreferencesActivity({ notificationSettings: newSettings });
        }
    };
    const notificationsUser = [
        { key: 'visitorNotification', label: translations.JsonNotificationsUser.JsonVisitorNotification },
        { key: 'eventCommentsNotification', label: translations.JsonNotificationsUser.JsonEventCommentsNotification },
        { key: 'friendRequest', label: translations.JsonNotificationsUser.JsonFriendRequest },
        { key: 'friendBirthday', label: translations.JsonNotificationsUser.JsonFriendBirthday },
        { key: 'likeNotification', label: translations.JsonNotificationsUser.JsonLikeNotification },
        { key: 'activityLikeNotifications', label: translations.JsonNotificationsUser.JsonActivityLikeNotifications },
        { key: 'activityInMyCity', label: translations.JsonNotificationsUser.JsonActivityInMyCity },
        {
            key: 'activityByTopics', label: translations.JsonNotificationsUser.JsonActivityByTopics,
            child: activityTopics
        },
        {
            key: 'organisersMetNotification', label: translations.JsonNotificationsUser.JsonOrganisersMetNotification,
            child: organisersMet
        },
    ];
    const notificationsActivity = [
        // { key: 'followOrganizer', label: "Suivre l'organisateur" },
        { key: 'activitiesRemindNotification', label: translations.JsonNotificationsActivity.JsonActivitiesRemindNotification },
        { key: 'activityNearCompletion', label: translations.JsonNotificationsActivity.JsonActivitiesRemindNotification },
        { key: 'activityCompleted', label: translations.JsonNotificationsActivity.JsonActivityNearCompletion },
        { key: 'activityDiscussion', label: translations.JsonNotificationsActivity.JsonActivityCompleted },
        { key: 'activityReminder', label: translations.JsonNotificationsActivity.JsonActivityDiscussion },
        { key: 'organizerUpdate', label: translations.JsonNotificationsActivity.JsonNotificationsActivity },
    ];
    useEffect(() => {
        const organisers = userActivities === null || userActivities === void 0 ? void 0 : userActivities.map(activity => {
            const organizer = allUsers === null || allUsers === void 0 ? void 0 : allUsers.find(user => user._id === activity.author);
            const organizerName = organizer ? organizer.userName : "Unknown";
            return { key: organizerName, label: organizerName };
        });
        if (user !== undefined) {
            const userSetting = user === null || user === void 0 ? void 0 : user.notificationSettings;
            setNotificationsStateUser(Object.assign(Object.assign({}, notificationsStateUser), userSetting));
            // User is organising
            const userOrganisedActivities = activities.filter((activity) => activity.author === user._id);
            // User is Attending
            const userAttendedActivities = activities.filter((activity) => activity.attendees.includes(user._id));
            setUserActivities([...userOrganisedActivities, ...userAttendedActivities]);
        }
        setOrganisersMet(organisers);
    }, []);
    useEffect(() => {
        var _a, _b;
        if (userActivities !== undefined && userActivities.length > 0) {
            const activityIDs = userActivities === null || userActivities === void 0 ? void 0 : userActivities.map(activity => activity._id);
            setActivityIds(prevActivityIds => [...prevActivityIds, ...activityIDs]);
            const activitySetting = ((_a = userActivities[0]) === null || _a === void 0 ? void 0 : _a.notificationSettings) ? (_b = userActivities[0]) === null || _b === void 0 ? void 0 : _b.notificationSettings[user._id] : null;
            setNotificationsStateActivity(Object.assign(Object.assign({}, notificationsStateActivity), activitySetting));
        }
    }, [userActivities]);
    console.log(userActivities, activityIds);
    return (<ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      {/* <Text>Bienvenue, {user?.firstName}!</Text> */}
      <Text style={styles.textCenter}>Here you can manage your notifications about  :</Text>
      <View style={[styles.notifsContainer, styles.marginBas]}>
        {notificationsUser.map(notification => (<View key={notification.key} style={styles.notifContainer}>
            <View style={styles.notifParentContainer}>
              <Text style={styles.textCenter}>{notification.label}</Text>
              <SwitchBtn state={notificationsStateUser[notification.key]} setState={() => toggleNotification(notification.key, "user")}/>
            </View>
            {notification.child !== undefined && notificationsStateUser[notification.key] && notification.child.map((notification) => (<View key={notification.key} style={styles.notifChildContainer}>
                <Text style={styles.textCenter}>{notification.label}</Text>
                <SwitchBtn state={notificationsStateUser[notification.key]} setState={() => toggleNotification(notification.key, "user")}/>
              </View>))}
          </View>))}
      </View>

      <View style={styles.notifsContainer}>
        {notificationsUser.map(notification => (<View key={notification.key} style={styles.notifContainer}>
            <View style={styles.notifParentContainer}>
              <Text style={styles.textCenter}>{notification.label}</Text>
              <SwitchBtn state={notificationsStateActivity[notification.key]} setState={() => toggleNotification(notification.key, "activity")}/>
            </View>
            {notification.child !== undefined && notificationsStateActivity[notification.key] && notification.child.map((notification) => (<View key={notification.key} style={styles.notifChildContainer}>
                <Text style={styles.textCenter}>{notification.label}</Text>
                <SwitchBtn state={notificationsStateActivity[notification.key]} setState={() => toggleNotification(notification.key, "activity")}/>
              </View>))}
          </View>))}
      </View>
    </ScrollView>);
};
export default NotificationsManageScreen;
