export const handleChat = (socket, updateGroupChatMessages, updatePrivateChatMessages, displayChatHistory) => {
    if (!socket)
        return {};
    // Gestion des messages de groupe reçus
    socket.on('newChatMessage', ({ chatId, message }) => {
        console.log(`Message de groupe reçu - ChatID: ${chatId}, Message: ${message}`);
        updateGroupChatMessages(chatId, [message]);
    });
    // Gestion des messages privés reçus
    socket.on('newPrivateChatMessage', message => {
        console.log(`Message privé reçu - ChatID: ${message.chatId}, SenderID: ${message.senderId}, Message: ${message.content}`);
        updatePrivateChatMessages(message.chatId, [message], false);
    });
    // Gestion de la réponse de l'historique de chat
    socket.on('chatHistoryResponse', ({ chatId, messages, error }) => {
        if (error) {
            console.error(`Erreur lors de la récupération de l'historique de chat - ChatID: ${chatId}, Erreur: ${error}`);
        }
        else {
            console.log(`Historique de chat reçu - ChatID: ${chatId}, Messages:`, messages);
            displayChatHistory(chatId, messages);
        }
    });
    const requestChatHistory = (chatId) => {
        console.log(`Demande de l'historique de chat avec l'ID de chat: ${chatId}`);
        socket.emit('requestChatHistory', { chatId });
    };
    const sendChatMessage = (chatId, receiverIds, message, activityId = undefined) => {
        console.log(`Envoi du message - ReceiverId: ${receiverIds}, ChatId: ${chatId}, Message: ${message}, Activity: ${activityId}`);
        socket.emit('privateChatMessage', { chatId, receiverIds, message, activityId });
        socket.emit('requestAllChatsForUser');
    };
    const joinChatRoom = (chatId, receiverIds, activityId = undefined) => {
        console.log(`Rejoindre la salle de chat avec l'ID de récepteur: ${receiverIds}`);
        socket.emit('joinChatRoom', { chatId, receiverIds, activityId });
    };
    const leaveChatRoom = (chatId, receiverIds, activityId = undefined) => {
        console.log(`Quitter la salle de chat avec l'ID de récepteur: ${receiverIds}`);
        socket.emit('leaveChatRoom', { chatId, receiverIds, activityId });
    };
    const requestAllChatsForUser = () => {
        console.log(`Demande de récupération de tous les chats pour l'utilisateur`);
        socket.emit('requestAllChatsForUser');
    };
    return { sendChatMessage, joinChatRoom, leaveChatRoom, requestChatHistory, requestAllChatsForUser };
};
