import React from 'react';
import { Platform } from 'react-native';
import ProfileImagePickerIOS from './ImagePickerIOS';
import ProfileImagePickerAndroid from './ImagePickerAndroid';
import ProfileImagePickerWeb from './ImagePickerWeb';
const ProfileImagePickerComponent = Platform.select({
    ios: ProfileImagePickerIOS,
    android: ProfileImagePickerAndroid,
    web: ProfileImagePickerWeb,
});
const ProfileImagePickerModal = ({ modalVisible, setModalVisible, onImageSelected, }) => {
    if (!ProfileImagePickerComponent) {
        return null;
    }
    return (<ProfileImagePickerComponent onImageSelected={onImageSelected} modalVisible={modalVisible} setModalVisible={setModalVisible}/>);
};
export default ProfileImagePickerModal;
