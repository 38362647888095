// Fr  Styles de CreateClassicOrStepByStepActivityScreen( FrameFigma031 )
// En  Styles of CreateClassicOrStepByStepActivityScreen( FrameFigma031 )
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        width: '100%',
    },
    content: {
        width: '90%',
        alignItems: 'center',
        alignSelf: 'center',
    },
    textTitleOne: {
        marginTop: '5%',
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18,
    },
    questionContainer: {
        flexDirection: 'row',
        width: '90%',
        height: '10%',
        alignItems: 'center',
        justifyContent: 'center',
        marginVertical: '5%',
    },
    leftButton: {
        borderWidth: 1,
        width: '50%',
        height: '70%',
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        justifyContent: 'center',
    },
    rightButton: {
        borderWidth: 1,
        width: '50%',
        height: '70%',
        borderTopRightRadius: 20,
        borderBottomRightRadius: 20,
        justifyContent: 'center',
    },
    activeQuestion: {
        backgroundColor: '#59C09B',
    },
    textQuestion: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18,
    },
    titleA: {
        fontSize: 18,
        marginBottom: '2%',
    },
    dateContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: '5%',
    },
    switchBtn: {
        width: '100%',
        marginBottom: '5%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    textbtn: {
        fontSize: 18,
        fontWeight: 'bold',
    },
});
