//FR Il affiche une carte avec des marqueurs représentant les emplacements des utilisateurs pour le web 
//EN designed for web applications. It displays a map with markers representing the locations of users
import React, { useEffect, useRef, useState } from 'react';
import { GOOGLE_MAPS_API_KEY } from '@env';
import { useSelector } from 'react-redux';
const MapsWebUser = () => {
    var _a;
    // const { user, allUsers } = useUser();
    const { user, allUsers } = useSelector((state) => state.user);
    const mapRef = useRef(null);
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const locationType = user.locationType || 'null';
    const initialPosition = ((_a = user.location) === null || _a === void 0 ? void 0 : _a.coordinates)
        ? { lat: user.location.coordinates[1], lng: user.location.coordinates[0] }
        : { lat: 48.8566, lng: 2.3522 }; // Default initial position is Paris
    useEffect(() => {
        if (!window.google || !window.google.maps) {
            const script = document.createElement('script');
            script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places,geometry`;
            script.async = true;
            script.defer = true;
            document.body.appendChild(script);
            script.onload = () => setScriptLoaded(true);
        }
        else {
            setScriptLoaded(true);
        }
    }, []);
    useEffect(() => {
        if (scriptLoaded) {
            initMap();
        }
    }, [scriptLoaded, locationType]);
    const initMap = () => {
        var _a, _b, _c, _d;
        if (locationType === 'exact') {
            navigator.geolocation.getCurrentPosition(success => {
                const currentPosition = {
                    lat: success.coords.latitude,
                    lng: success.coords.longitude,
                };
                const position = adjustPosition(currentPosition);
                createMap(position, true);
            }, error => {
                console.error('Geolocation failed or permission denied, using initial position.');
                const fallbackPosition = adjustPosition(initialPosition);
                createMap(fallbackPosition, true);
            });
        }
        else if (locationType === 'approximate') {
            const currentPosition = {
                lat: (_b = (_a = user.location) === null || _a === void 0 ? void 0 : _a.coordinates[1]) !== null && _b !== void 0 ? _b : 0,
                lng: (_d = (_c = user.location) === null || _c === void 0 ? void 0 : _c.coordinates[0]) !== null && _d !== void 0 ? _d : 0,
            };
            const position = adjustPosition(currentPosition);
            createMap(position, true);
        }
        else {
            const fallbackPosition = adjustPosition(initialPosition);
            createMap(fallbackPosition, false);
        }
    };
    const adjustPosition = (position) => {
        return {
            lat: parseFloat(position.lat.toString()),
            lng: parseFloat(position.lng.toString()),
        };
    };
    const createMap = (position, showCurrentUser) => {
        const map = new window.google.maps.Map(mapRef.current, {
            center: position,
            zoom: 14,
            tilt: 60,
        });
        window.google.maps.event.addListener(map, 'zoom_changed', function () {
            if (map.getZoom() < 14) {
                map.setTilt(0);
            }
            else {
                map.setTilt(60);
            }
        });
        if (showCurrentUser) {
            const userLocationMarker = new window.google.maps.Marker({
                position,
                map: map,
                icon: {
                    path: window.google.maps.SymbolPath.CIRCLE,
                    scale: 7,
                    fillColor: '#0000FF',
                    fillOpacity: 1,
                    strokeWeight: 2,
                },
            });
            const userLocationCircle = new window.google.maps.Circle({
                strokeColor: '#0000FF',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#ADD8E6',
                fillOpacity: 0.35,
                map: map,
                center: position,
                radius: 500,
            });
            const overlay = new window.google.maps.OverlayView();
            overlay.onAdd = function () {
                const layer = document.createElement('div');
                layer.style.borderStyle = 'none';
                layer.style.borderWidth = '0px';
                layer.style.position = 'absolute';
                layer.style.whiteSpace = 'nowrap';
                layer.style.fontSize = '14px';
                layer.style.fontWeight = 'bold';
                layer.style.color = 'blue';
                layer.innerText = 'You are Here';
                const panes = this.getPanes();
                panes.floatPane.appendChild(layer);
                overlay.draw = function () {
                    const projection = this.getProjection();
                    //const pixel = projection.fromLatLngToDivPixel(userLocationMarker.getPosition() as google.maps.LatLng);
                    //layer.style.left = pixel.x - layer.offsetWidth / 2 + 'px';
                    //layer.style.top = pixel.y - layer.offsetHeight - 30 + 'px';
                };
            };
            overlay.setMap(map);
        }
        allUsers.forEach((userItem) => {
            var _a;
            if (userItem._id !== user.userId && ((_a = userItem.location) === null || _a === void 0 ? void 0 : _a.coordinates)) {
                const [lng, lat] = userItem.location.coordinates;
                const userPosition = { lat, lng };
                const overlay = new window.google.maps.OverlayView();
                overlay.onAdd = function () {
                    var _a;
                    const layer = document.createElement('div');
                    layer.style.width = '50px';
                    layer.style.height = '50px';
                    layer.style.borderRadius = '50%';
                    layer.style.overflow = 'hidden';
                    layer.style.position = 'absolute';
                    layer.style.border = '2px solid white';
                    layer.style.boxShadow = '0 0 5px rgba(0, 0, 0, 0.5)';
                    const img = document.createElement('img');
                    img.src = (_a = userItem.profileImage) !== null && _a !== void 0 ? _a : '';
                    img.style.width = '100%';
                    img.style.height = '100%';
                    img.style.borderRadius = '50%';
                    img.style.objectFit = 'cover';
                    layer.appendChild(img);
                    const panes = this.getPanes();
                    panes.overlayImage.appendChild(layer);
                    overlay.draw = function () {
                        const projection = this.getProjection();
                        const position = new window.google.maps.LatLng(userPosition.lat, userPosition.lng);
                        const pixel = projection.fromLatLngToDivPixel(position);
                        layer.style.left = pixel.x - 25 + 'px';
                        layer.style.top = pixel.y - 25 + 'px';
                    };
                };
                overlay.setMap(map);
            }
        });
    };
    return <div ref={mapRef} style={{ height: '100vh', width: '100%' }}/>;
};
export default MapsWebUser;
