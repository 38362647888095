import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    scrollViewContainer: {
        flex: 1,
        backgroundColor: 'white',
        padding: 20,
    },
    logoConfidentiality: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    textConfidential: {
        textAlign: "center",
        color: '#FF0000',
        fontSize: 14,
        fontWeight: '700',
    },
    label: {
        marginTop: 10,
        fontSize: 16,
        flexDirection: 'row',
    },
    labelText: {
        fontWeight: 'bold',
    },
    memberId: {
        color: '#59C09B',
        fontWeight: 'bold',
    },
    elementContainer: {
        width: '100%',
        alignItems: 'center',
        marginTop: '5%',
        alignSelf: 'center',
    },
    input: {
        width: '100%',
        height: 50,
        marginBottom: '5%',
    },
    numberContainer: {
        overflow: 'hidden',
        width: '100%',
        height: 58,
        fontSize: 16,
        marginTop: '3%',
    },
    inputDesc: {
        color: '#C4C4C4',
        fontStyle: 'italic',
        fontSize: 12,
        marginBottom: '3%',
    },
    Confidentiality: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        marginTop: '5%',
    },
    containerBottom: {
        marginBottom: '20%',
        marginTop: '5%',
    },
    button: {
        padding: 10,
        margin: 5,
        //width: '40%',
        //height: 100,
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#59C09B',
        width: 156,
        height: 156,
        justifyContent: 'center',
        alignItems: 'center',
        //boxShadow: "0px 4px 4px 0px #00000040",
        shadowColor: '#000000', // Couleur de l'ombre (noir)
        shadowOffset: { width: 0, height: 4 }, // Décalages horizontal et vertical
        shadowOpacity: 0.25, // Opacité de l'ombre
        shadowRadius: 4, // Rayon de flou
        elevation: 5, // Valeur approximative pour Android
    },
    buttonSelected: {
        backgroundColor: '#59C09B',
    },
    buttonNotSelected: {
        backgroundColor: 'white',
    },
    buttonTextTitle: {
        textAlign: "center",
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: '5%',
    },
    buttonText: {
        fontSize: 12,
        textAlign: "center",
    },
    buttonSponsorsContainer: {
        width: '90%',
        height: '25%',
        borderRadius: 10,
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        //boxShadow: "0px 4px 4px 0px #00000040",
        shadowColor: '#000000', // Couleur de l'ombre (noir)
        shadowOffset: { width: 0, height: 4 }, // Décalages horizontal et vertical
        shadowOpacity: 0.25, // Opacité de l'ombre
        shadowRadius: 4, // Rayon de flou
        elevation: 5, // Valeur approximative pour Android
        marginBottom: '2%',
    },
    buttonSponsors: {
        borderRadius: 10,
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonRowContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: '2%',
        alignItems: 'center',
    },
    VectorsContainer: {
        width: 48,
        height: 48,
        borderRadius: 50, // Pour rendre l'élément circulaire
        backgroundColor: '#FFF',
        justifyContent: 'center',
        alignItems: 'center',
    },
    Vectors: {
        width: 24,
        height: 24,
    },
});
export default styles;
