//FR affiche une carte avec des marqueurs pour représenter différentes activités à des emplacements spécifiques
//EN responsible for displaying a map with markers representing various activities based on their locations and types.
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { View, Dimensions, PermissionsAndroid, Image, Platform } from 'react-native';
import Geolocation from '@react-native-community/geolocation';
import MapView, { Marker } from 'react-native-maps';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { hostname } from '../../../../../hostName/hostName';
// Mappage des icônes pour chaque type d'activité
const activitiesIconMapping = {
    1: require('../../../../assets/Images/Activity/Business.png'),
    2: require('../../../../assets/Images/Activity/Afterwork.png'),
    3: require('../../../../assets/Images/Activity/Apero.png'),
    4: require('../../../../assets/Images/Activity/Travel.png'),
    5: require('../../../../assets/Images/Activity/Dancing.png'),
    6: require('../../../../assets/Images/Activity/Sports.png'),
    7: require('../../../../assets/Images/Activity/Music.png'),
    8: require('../../../../assets/Images/Activity/Private.png'),
    9: require('../../../../assets/Images/Activity/MutualHelp.png'),
    10: require('../../../../assets/Images/Activity/Linguistic.png'),
    11: require('../../../../assets/Images/Activity/Movie.png'),
    12: require('../../../../assets/Images/Activity/Games.png'),
    13: require('../../../../assets/Images/Activity/Culture.png'),
    14: require('../../../../assets/Images/Activity/Meal.png'),
    15: require('../../../../assets/Images/Activity/Party.png'),
    16: require('../../../../assets/Images/Activity/Picnic.png'),
};
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const MapsMobileActivities = ({ users }) => {
    const [activities, setActivities] = useState([]);
    const [region, setRegion] = useState({
        latitude: 48.8566,
        longitude: 2.3522,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
    });
    const requestLocationPermission = () => __awaiter(void 0, void 0, void 0, function* () {
        if (Platform.OS === 'ios') {
            Geolocation.requestAuthorization();
            return true;
        }
        if (Platform.OS === 'android') {
            const granted = yield PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION, {
                title: 'Location Permission',
                message: 'This app needs access to your location',
                buttonPositive: 'OK',
                buttonNegative: 'Cancel',
            });
            return granted === PermissionsAndroid.RESULTS.GRANTED;
        }
        return false;
    });
    const getCurrentPosition = () => {
        Geolocation.getCurrentPosition(position => {
            setRegion(Object.assign(Object.assign({}, region), { latitude: position.coords.latitude, longitude: position.coords.longitude }));
        }, error => console.warn(error), { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 });
    };
    const fetchActivities = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const userToken = yield AsyncStorage.getItem('userToken');
            const response = yield fetch(`${hostname}/activities`, {
                headers: { Authorization: `Bearer ${userToken}` },
            });
            if (!response.ok)
                throw new Error('Failed to fetch activities');
            const data = yield response.json();
            setActivities(data);
        }
        catch (error) {
            console.error(error);
        }
    });
    useEffect(() => {
        requestLocationPermission().then(granted => {
            if (granted) {
                getCurrentPosition();
                fetchActivities();
            }
        });
    }, []);
    return (<View style={{ flex: 1 }}>
      <MapView style={{ width: Dimensions.get('window').width, height: Dimensions.get('window').height }} region={region} showsUserLocation={true} onRegionChangeComplete={region => setRegion(region)}>
        {activities.map((activity) => (<Marker key={activity._id} coordinate={{ latitude: activity.location.latitude, longitude: activity.location.longitude }} title={activity.title} description={activity.description}>
            <View style={[styles.imageContainer, { width: Dimensions.get('window').width * 0.15, height: Dimensions.get('window').width * 0.15, }]}>
              <Image source={activitiesIconMapping[activity.topic]} style={styles.imageItem}/>
            </View>
          </Marker>))}
      </MapView>
    </View>);
};
export default MapsMobileActivities;
