var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from "react";
import { useWebSocket } from "../../sockets/WebSocketLocal";
import styles from './StylesWeb';
import { FlatList, Image, Text, TouchableOpacity, View } from "react-native";
import FetchUserBasics from "../../utils/Functions/FetchUserBasics";
import ChatModal from "../../components/ModalPopUpModule/ModalSection/ChatModal/Code";
import { useSelector } from "react-redux";
export default function InboxScreen() {
    var _a;
    const { userChats } = useWebSocket();
    const { user } = useSelector((state) => state.user);
    const [selectedChatId, setSelectedChatId] = useState('');
    const [chatPreviews, setChatPreviews] = useState([]);
    function getChatPreview(chat, userId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const otherParticipantsIds = (_a = chat.participants) === null || _a === void 0 ? void 0 : _a.filter(participant => participant._id !== userId);
            const lastMessage = chat.messages[chat.messages.length - 1];
            if (!otherParticipantsIds || !lastMessage) {
                return undefined;
            }
            const otherParticipants = yield Promise.all(otherParticipantsIds.map((participant) => __awaiter(this, void 0, void 0, function* () {
                return yield FetchUserBasics(participant._id);
            })));
            return {
                [chat._id]: {
                    otherParticipants,
                    lastMessageContent: lastMessage.content,
                    lastMessageTimestamp: lastMessage.timestamp,
                    _id: lastMessage.chatId
                }
            };
        });
    }
    useEffect(() => {
        const fetchChatPreviews = () => __awaiter(this, void 0, void 0, function* () {
            const previews = yield Promise.all(userChats.map(chat => getChatPreview(chat, user._id)));
            setChatPreviews(previews.filter(preview => preview !== undefined));
        });
        fetchChatPreviews().then(() => {
            console.log('Chat previews updated.');
        });
    }, [userChats]);
    return (<View>
            {chatPreviews.length > 0 ? (<FlatList data={chatPreviews} extraData={chatPreviews} keyExtractor={(item) => Object.keys(item)[0]} renderItem={({ item }) => {
                const chatId = Object.keys(item)[0];
                const preview = item[chatId];
                return (<TouchableOpacity onPress={() => setSelectedChatId(chatId)} style={styles.conversationItem}>
                                <Image source={{ uri: preview.otherParticipants[0].profileImage }} style={styles.image}/>
                                <View>
                                    <Text style={styles.name}>
                                        {preview.otherParticipants.map(participant => participant.firstName).join(', ')}
                                    </Text>
                                    <Text style={styles.lastMessage}>{preview.lastMessageContent}</Text>
                                </View>
                            </TouchableOpacity>);
            }}/>) : (<Text>No chats available.</Text>)}
            {selectedChatId && (<ChatModal chatId={selectedChatId} isVisible={!!selectedChatId} closeModal={() => setSelectedChatId('')} activityId={((_a = userChats.find(chat => chat._id === selectedChatId)) === null || _a === void 0 ? void 0 : _a._id) || ''} participants={[{ _id: '' }]}/>)}
        </View>);
}
