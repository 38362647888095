//🇫🇷 Ce fichier gère le style mobile du composant ExistingUserPasswordScreen.js (FrameFigma006a)
//🇬🇧 This file manages the mobile style of the ExistingUserPasswordScreen.js component (FrameFigma006a)
import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    passwordContent: {
        marginTop: height * 0.2,
        marginHorizontal: width * 0.05,
        width: '80%',
    },
    password: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    terms: {
        fontSize: width * 0.03,
        textAlign: 'center',
    },
    underline: {
        fontSize: width * 0.03,
        textDecorationLine: 'underline',
        textAlign: 'center',
    },
    condition: {
        marginVertical: height * 0.05,
        width: '60%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
    },
    forgotPassword: {
        marginTop: height * 0.1,
        marginHorizontal: width * 0.05,
        width: '80%',
    },
    forgotTxt: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    btnForgotContainer: {
        margin: 10,
        borderWidth: 1,
        padding: 10,
        borderRadius: 15,
    },
    btnForgot: {
        alignContent: 'center',
        justifyContent: 'center',
    },
    btnTextForgot: {
        color: '#59C09B',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    buttonContainer: {
        flexDirection: 'row',
        marginTop: height * 0.0625,
        justifyContent: 'space-around',
        width: '100%',
        alignItems: 'center',
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
