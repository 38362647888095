//FR Il affiche une carte avec des utilisateurs en fonction de la plateforme utilisée (Web ou mobile)
//EN Displays a map with users based on the platform being used (Web or mobile).
import React from 'react';
import { ScrollView, Platform } from 'react-native';
import MapsWebUser from './MapsWebUser';
import MapsMobileUser from './MapsMobileUser';
const UsersMapsScreen = () => {
    // Determine the component to use based on the platform
    const MapsComponent = Platform.OS === 'web' ? MapsWebUser : MapsMobileUser;
    return (<ScrollView>
            {/* Display the map with users */}
            <MapsComponent />
        </ScrollView>);
};
export default UsersMapsScreen;
