import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
    },
    modalContent: {
        width: 400,
        height: 350,
        backgroundColor: 'white',
        borderRadius: 10,
        alignItems: 'center',
        padding: 10,
    },
    titleText: {
        fontSize: 24,
        marginVertical: '5%',
        fontWeight: 'bold',
    },
    timeContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '90%',
    },
    scrollContainer: {
        height: 140,
        width: 25,
        borderWidth: 1,
        borderRadius: 15,
        borderColor: 'lightgrey',
        backgroundColor: '#F1F1F1',
    },
    scrollContent: {
        height: 60,
        justifyContent: 'center',
        alignItems: 'center',
    },
    timeText: {
        fontSize: 48,
        textAlign: 'center',
    },
    selectedText: {
        color: '#59C09B',
        fontWeight: 'bold',
    },
    bottomTimeContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '75%',
        marginTop: '2%',
    },
    bottomTimeText: {
        fontSize: 18,
    },
    separator: {
        marginVertical: '5%',
        height: 1.2,
        backgroundColor: '#F1F1F1',
        width: '100%',
        alignSelf: 'center',
    },
    buttonBottomContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '100%',
        height: '15%',
        marginBottom: '-25%',
    },
    buttonBottomContent: {
        backgroundColor: '#F1F1F1',
        width: '47%',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 8,
    },
});
export default styles;
