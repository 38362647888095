import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',
        height: 'auto',
        padding: 10,
        backgroundColor: 'darkgray',
        marginBottom: '5%',
    },
    adressContainer: {
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'center',
        alignSelf: 'center',
        marginHorizontal: 5
    },
    iconContainer: {
        marginLeft: '5%',
        width: 40,
        height: 40,
        resizeMode: 'contain',
    },
    locationContainer: {
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    locationText: {
        fontSize: 16,
        fontWeight: '700',
        color: 'white',
        textAlign: 'center',
        width: '70%',
    },
});
export default styles;
