// 🇫🇷 Afficher un champ de texte personnalisé, (FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma037A,FrameFigma031,FrameFigma032A,FrameFigma032B,FrameFigma032C,FrameFigma035A,FrameFigma035B)
// 🇬🇧 Display a customized text field (FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma037A,FrameFigma031,FrameFigma032A,FrameFigma032B,FrameFigma032C,FrameFigma035A,FrameFigma035B)
import { View, TextInput, Platform, TouchableOpacity, Pressable } from 'react-native';
import React, { useState } from 'react';
import VectorsOnOff from '../../../constants/VectorsOnOff/VectorsOnOff';
const TextInputCustom = ({ Icon, multiline = false, numberOfLines = 1, placeholderText, isPassword = false, value = '', handleTextChange, isPlaceHolderTextAlignCenter = true, errorInput = false, editable = true, IconRight, cb }) => {
    const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    // const [onPressIn, setOnPressIn] = useState(false);
    const handleTogglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };
    return (<View style={styles.container}>
      {Icon && (<View style={styles.iconLeft}>
          <Icon />
        </View>)}
      <View style={[
            isFocused || isPasswordVisible || (value && value.length > 0) ? styles.focusedInput : styles.defaultInput,
            styles.input,
            Icon ? { paddingHorizontal: '15%' } : { paddingHorizontal: '6%' },
            errorInput && { borderColor: 'red' },
        ]}>
        <TextInput style={[
            isFocused && styles.focusedInput2,
            value && value.length > 0 ? styles.input2 : [styles.placeholder, isPlaceHolderTextAlignCenter ? { textAlign: 'center' } : { textAlign: 'left' }],
        ]} multiline={multiline} numberOfLines={numberOfLines} placeholder={placeholderText} placeholderTextColor="#8C8F8E" secureTextEntry={isPassword ? !isPasswordVisible : false} value={value} onChangeText={handleTextChange} onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} editable={editable}/>
        {isPassword && (<TouchableOpacity style={styles.iconEye} onPress={handleTogglePasswordVisibility}>
            {isPasswordVisible ? <VectorsOnOff.IconEyeToggleOff /> : <VectorsOnOff.IconEyeToggle />}
          </TouchableOpacity>)}
      </View>
        {IconRight && !editable ?
            <Pressable style={[styles.iconRight /* ,!!onPressIn && styles.inconPressIN  */]} onPress={cb} /* onPressIn={()=>{setOnPressIn(true)}} onPressOut={()=>setOnPressIn(false)} */>
          <IconRight />

        </Pressable> : null}
    </View>);
};
export default TextInputCustom;
