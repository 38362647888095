import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    NotificationsVectors: {
        width: 24,
        height: 24,
    },
    activityCard: {
        marginVertical: 15,
        marginHorizontal: 10,
        height: 100,
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 20,
        backgroundColor: 'white',
        shadowColor: '#000000', // Couleur de l'ombre
        shadowOffset: { width: 0, height: 4 }, // Décalage de l'ombre (largeur, hauteur)
        shadowOpacity: 0.3, // Opacité de l'ombre
        shadowRadius: 2, // Rayon de l'ombre
        elevation: 5, // Pour Android, nécessaire pour l'ombre
    },
    activityCardLiked: {
        backgroundColor: '#FEEBEF',
    },
    activityCardParticipant: {
        backgroundColor: '#D5EFE6',
    },
    activityCardWaitingList: {
        backgroundColor: '#FFF9E6',
    },
    leftSide: {
        height: '100%',
        width: 123,
    },
    activityImage: {
        width: 124,
        height: 70,
        backgroundColor: 'white',
    },
    imageContainer: {
        width: 124,
        height: '100%',
    },
    activityImageOne: {
        width: '100%',
        height: '100%',
        borderTopLeftRadius: 20,
        resizeMode: 'contain',
    },
    componentHeart: {
        position: 'absolute',
        top: 5,
        left: 6,
        width: 12,
        height: 12,
    },
    rightSide: {
        flex: 1,
        height: '100%',
        justifyContent: 'space-between',
        borderTopRightRadius: 20,
    },
    centerItemUp: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: 10,
        paddingTop: 5,
        maxWidth: '100%',
    },
    centerItem: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignContent: 'center',
        paddingLeft: 10,
    },
    activityTitleContainer: {
        maxWidth: '85%',
    },
    activityTitle: {
        fontWeight: 'bold',
        fontSize: 14,
    },
    authorImageView: {
        paddingBottom: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    authorImage: {
        width: 55,
        height: 55,
        borderRadius: 50,
        justifyContent: 'center',
    },
    bottomItem: {
        flex: 1,
        flexDirection: 'row',
    },
    bottomItemCenter: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 5,
    },
    textParticipants: {
        marginLeft: 5,
        fontWeight: 'bold',
    },
    timeContainer: {
        backgroundColor: '#F48A0D',
        borderBottomRightRadius: 20,
        borderTopLeftRadius: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 10,
        marginTop: 5,
        minWidth: 70,
        height: 30,
    },
    activityTime: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        fontSize: 12,
    },
    userStatusTag: {
        justifyContent: 'center',
        alignItems: 'center',
        width: 124,
        height: 30,
        borderBottomLeftRadius: 20,
    },
    participant: {
        backgroundColor: '#59C09B',
    },
    waitingList: {
        backgroundColor: '#FFC107',
    },
    topic: {
        backgroundColor: '#000000',
    },
    text: {
        color: 'white',
        fontWeight: 'bold',
        fontSize: 18,
    },
});
