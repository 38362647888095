// Fr Cette page affiche l'étape 2 pour modifier une activité(FrameFigma032A,FrameFigma032B,FrameFigma032C)
// En This page displays the second step to edit an activity(FrameFigma032A,FrameFigma032B,FrameFigma032C)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Import Modules
import React, { useState, useRef } from 'react';
import { View, Text, Platform, TouchableWithoutFeedback } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
import SwitchBtn from '../../../../components/ButtonSection/SwitchButton/Code';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import MyButtonPress from '../../../../components/ButtonSection/PressButton/Code';
import AddressAutoPickerActivity from '../../../../components/PickerSection/AddressAutoAcitivitiesPicker/AddressAutoPickerActivity';
import Maps from '../../../../components/PickerSection/AddressMapPickerWeb/Maps01';
// Import Contexts
import { useError } from '../../../../contexts/ErrorContext';
//import Utils
import { CopyPasteFunctions } from '../../../../utils/Functions/CopyPasteFunctions';
// import Vectors (icons)
import Vectors from '../../../../constants/Vectors';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
import AddressMapPickerMobile from '../../../../components/PickerSection/AdressMapMobilePicker/Code';
import { getMyPosition } from '../../../../services/googleMapsApi/GeoLocationUtils';
import BottomWhiteNav from '../../../../components/NavBarSection/BottomNav/BottomWhiteNav/Code';
// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const EditActivityLocationScreen = ({ onNextStep, onPrevStep, setHeaderTitle }) => {
    const navigation = useNavigation();
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    // const {currentActivity, setCurrentActivity, currentActivitySwitch, setCurrentActivitySwitch} = useActivity();
    const { currentActivity, currentActivitySwitch } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setCurrentActivity = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivity({ id: nanoid(), value: data }));
    };
    const setCurrentActivitySwitch = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivitySwitch({ id: nanoid(), value: data }));
    };
    const { error, setError } = useError();
    const [showModal, setShowModal] = useState(false);
    const [address, setAddress] = useState(currentActivity.location.address);
    const [query, setQuery] = useState(currentActivity.location.address);
    const [Coords, setCoords] = useState({ lat: currentActivity.location.latitude, lng: currentActivity.location.longitude });
    const [postalCode, setPostalCode] = useState(currentActivity.location.postalCode);
    const [switchMetroBtn, setSwitchMetroBtn] = useState(currentActivitySwitch.switchMetroBtn);
    const [metroStation, setMetroStation] = useState(currentActivity.metroStation);
    const [googleUrl, setGoogleUrl] = useState(currentActivity.googleUrl);
    const [isGoogleUrlResponseOnError, setIsGoogleUrlResponseOnError] = useState(false);
    const [myPositionGoogleUrl, setMyPositionGoogleUrl] = useState(googleUrl);
    const [isNewAddress, setIsNewAddress] = useState(false);
    const [onlineUrl, setOnlineUrl] = useState(currentActivity.infoLine);
    const conditionNeedRef = useRef(currentActivitySwitch.conditionNeedLoc);
    const selectedButtonRef = useRef(currentActivitySwitch.butLoc);
    const googleUrlRef = useRef(googleUrl);
    const addressRef = useRef(address);
    const myPositionRef = useRef(!!currentActivity.myPosition);
    const metroStationRef = useRef(metroStation);
    const onlineUrlRef = useRef(onlineUrl);
    console.log(googleUrlRef.current, addressRef.current, myPositionRef.current, onlineUrlRef.current);
    const t = translations.JsonActivityPages.JsonActivityManagement.JsonStandardActivityChooseAddressScreen;
    const adressePlaceholder = selectedButtonRef.current === 'Address'
        ? t.JsonAddressPlaceholder
        : selectedButtonRef.current === 'Online'
            ? t.JsonOnlinePlaceholder
            : t.JsonGoogleMapPlaceholder;
    const handleAddressChange = (text) => {
        myPositionRef.current = false;
        addressRef.current = text;
        setQuery(text);
        const addressLength = text ? text.trim().length : 0;
        if (addressLength === 0) {
            setAddress('');
            conditionNeedRef.current = false;
            setIsNewAddress(false);
            addressRef.current = '';
            return;
        }
        setIsNewAddress(true);
        setMyPositionGoogleUrl('');
        conditionNeedRef.current = true;
    };
    const handleGoogleUrlChange = (text) => {
        setGoogleUrl(text);
        googleUrlRef.current = text;
        validateGoogleUrl(text);
        if (isGoogleUrlResponseOnError === true) {
            setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { conditionNeedLoc: false }));
            conditionNeedRef.current = false;
            return;
        }
        else if (!isGoogleUrlResponseOnError && validateGoogleUrl(text) === true) {
            setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { conditionNeedLoc: true }));
            conditionNeedRef.current = true;
            return;
        }
        conditionNeedRef.current = true;
    };
    const handleOnlineUrlChange = (text) => {
        setOnlineUrl(text);
        onlineUrlRef.current = text;
        conditionNeedRef.current = validateOnlineUrl(onlineUrlRef.current);
        setMyPositionGoogleUrl('');
        setAddress('');
        setGoogleUrl('');
    };
    const handleGetMyPosition = () => __awaiter(void 0, void 0, void 0, function* () {
        yield getMyPosition(setCoords, setPostalCode, setMyPositionGoogleUrl, myPositionRef, currentActivity, Coords);
    });
    const handleMetroChange = (text) => {
        const cleanedText = text.replace(/[^a-zA-ZÀ-ÿ0-9'\-\s]/g, '');
        conditionNeedRef.current = cleanedText.length > 0;
        metroStationRef.current = cleanedText;
        setMetroStation(cleanedText);
    };
    // Fonction pour gérer le changement du switch
    const handleSwitchChange = (value) => {
        setSwitchMetroBtn(value);
        if (!value && !address) {
            conditionNeedRef.current = false;
            return;
        }
        else if (value && !metroStation) {
            conditionNeedRef.current = false;
            return;
        }
        else if (!value && address) {
            conditionNeedRef.current = true;
        }
    };
    const validateOnlineUrl = (url) => {
        const urlRegex = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/;
        const urlLength = url ? url.length : 0;
        if (urlLength === 0) {
            return false;
        }
        if (urlRegex.test(url)) {
            return true;
        }
        else {
            return false;
        }
    };
    const validateGoogleUrl = (url) => {
        const regex = /^https:\/\/(?:www\.google\.[a-zA-Z]{2,3}\/)?maps[\/\.][^\s\/\.]+.*$/;
        const urlLength = !!url ? url.length : 0;
        if (urlLength === 0) {
            conditionNeedRef.current = false;
            return false;
        }
        if (regex.test(url)) {
            if (isGoogleUrlResponseOnError === true) {
                return true;
            }
            else {
                return true;
            }
        }
        else {
            conditionNeedRef.current = false;
            return false;
        }
    };
    // Fonction pour gérer les erreurs
    function validateConditionNeed() {
        if (selectedButtonRef.current === 'Address' && !address) { // Test si adress null
            setIsError(true);
            showErrorInHeader(translations.JsonActivityPages.JsonActivityManagement.JsonStandardActivityChooseAddressScreen.JsonAddressErrorMessage);
            return false;
        }
        else if (switchMetroBtn && metroStationRef.current.length === 0) { // Test si Metro null
            setIsError(true);
            showErrorInHeader('Please take metro station');
            setMetroStation('');
            metroStationRef.current = '';
            conditionNeedRef.current = false;
            return false;
        }
        else if (selectedButtonRef.current === 'Online' && !validateOnlineUrl(onlineUrlRef.current)) { // Test si online URL valide
            setIsError(true);
            showErrorInHeader(t.JsonOnlineErrorMessage);
            conditionNeedRef.current = false;
            return false;
        }
        else if (selectedButtonRef.current === 'with link Url') { // Test si Google URL valide
            if (!validateGoogleUrl(googleUrlRef.current) && isGoogleUrlResponseOnError) {
                setIsError(true);
                showErrorInHeader(t.JsonOnlineErrorMessage);
                setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { conditionNeedLoc: false }));
                conditionNeedRef.current = false;
                return false;
            }
            else {
                setIsError(true);
                showErrorInHeader(t.JsonOnlineErrorMessage);
                googleUrlRef.current = '';
                conditionNeedRef.current = false;
                setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { conditionNeedLoc: false }));
                return false;
            }
        }
        if (selectedButtonRef.current === 'Address' || selectedButtonRef.current === 'My GPS Position' || selectedButtonRef.current === 'with link Url') { // Reinitialise metroStation
            if (!switchMetroBtn && metroStationRef.current.length > 0) {
                setMetroStation('');
            }
        }
        conditionNeedRef.current = true;
        return true;
    }
    ;
    const initAddress = () => {
        conditionNeedRef.current = false;
        myPositionRef.current = false;
        setHeaderTitle('Activity Address');
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { butLoc: 'Address' }));
        selectedButtonRef.current = 'Address';
        setMyPositionGoogleUrl('');
        setGoogleUrl('');
        googleUrlRef.current = '';
        setMyPositionGoogleUrl('');
        setOnlineUrl('');
        onlineUrlRef.current = '';
    };
    const initGoogleUrl = () => {
        conditionNeedRef.current = false;
        myPositionRef.current = false,
            setHeaderTitle('Activity with GPS link');
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { butLoc: 'with link Url' }));
        selectedButtonRef.current = 'with link Url';
        setAddress('');
        setQuery('');
        addressRef.current = '';
        setGoogleUrl('');
        googleUrlRef.current = '';
        setMyPositionGoogleUrl('');
        setOnlineUrl('');
        onlineUrlRef.current = '';
    };
    const initOnlineUrl = () => {
        conditionNeedRef.current = false;
        myPositionRef.current = false;
        setHeaderTitle('Activity Online');
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { butLoc: 'Online' }));
        selectedButtonRef.current = 'Online';
        setAddress('');
        setQuery('');
        addressRef.current = '';
        setGoogleUrl('');
        googleUrlRef.current = '';
        setMyPositionGoogleUrl('');
        setPostalCode('');
    };
    const initMyPosition = () => {
        conditionNeedRef.current = false;
        myPositionRef.current = true;
        setHeaderTitle('My GPS Position'),
            setAddress('');
        addressRef.current = '';
        setQuery('');
        setGoogleUrl('');
        googleUrlRef.current = '';
        setOnlineUrl('');
        onlineUrlRef.current = '';
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { butLoc: 'My GPS Position' }));
        selectedButtonRef.current = 'My GPS Position';
        handleGetMyPosition();
    };
    //Permet de gérer l'affichage des erreurs
    const [isError, setIsError] = useState(false);
    const resetHeader = () => {
        setHeaderTitle('');
        setTimeout(() => {
            setHeaderTitle(`Activity ${currentActivitySwitch.butLoc}`);
            setIsError(false);
        }, 10);
    };
    const showErrorInHeader = (errorMessage) => {
        navigation.setOptions({
            headerTitle: () => (<Text style={{
                    fontSize: 20,
                    color: 'white',
                    fontWeight: 'bold',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                }}>
                  {errorMessage}
                </Text>),
            headerStyle: { backgroundColor: 'red' },
            headerLeft: false,
        });
    };
    const handleNextStep = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!validateConditionNeed()) {
            return;
        }
        const location = {
            address: address,
            latitude: Coords.lat,
            longitude: Coords.lng,
            postalCode: postalCode === null || postalCode === void 0 ? void 0 : postalCode.postalCode
        };
        if (selectedButtonRef.current === 'with link Url') {
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { location: location, googleUrl: googleUrl, myPosition: false, infoLine: onlineUrl, metroStation: metroStation }));
        }
        else if (selectedButtonRef.current === 'My GPS Position') {
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { location: location, googleUrl: myPositionGoogleUrl, myPosition: true, infoLine: onlineUrl, metroStation: metroStation }));
        }
        else if (selectedButtonRef.current === 'Online') {
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { location: { address: '', latitude: '', longitude: '', postalCode: '' }, googleUrl: '', myPosition: false, infoLine: onlineUrl, metroStation: '' }));
        }
        else {
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { location: location, googleUrl: googleUrl, myPosition: false, infoLine: onlineUrl, metroStation: metroStation }));
        }
        if (!!switchMetroBtn && metroStationRef.current.length > 0) {
            setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchMetroBtn: false, butLoc: selectedButtonRef.current, conditionNeedLoc: conditionNeedRef /* myPosition: myPositionRef.current */ }));
        }
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchMetroBtn: switchMetroBtn, butLoc: selectedButtonRef.current, conditionNeedLoc: conditionNeedRef /*  myPosition: myPositionRef.current */ }));
        setHeaderTitle("Manage Participation");
        onNextStep();
    });
    const handlePrevStep = () => {
        const location = {
            address: address,
            latitude: Coords.lat,
            longitude: Coords.lng,
            postalCode: postalCode
        };
        if (selectedButtonRef.current === 'with link Url') {
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { location: location, googleUrl: googleUrl, myPosition: false, infoLine: onlineUrl, metroStation: metroStation }));
        }
        else if (selectedButtonRef.current === 'My GPS Position') {
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { location: location, googleUrl: myPositionGoogleUrl, myPosition: true, infoLine: onlineUrl, metroStation: metroStation }));
        }
        else {
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { location: location, googleUrl: googleUrl, myPosition: false, infoLine: onlineUrl, metroStation: metroStation }));
        }
        if (!!switchMetroBtn && metroStationRef.current.length === 0) {
            setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchMetroBtn: false, butLoc: selectedButtonRef.current, conditionNeedLoc: conditionNeedRef /* , myPosition: myPositionRef.current */ }));
        }
        else {
            setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchMetroBtn: switchMetroBtn, butLoc: selectedButtonRef.current, conditionNeedLoc: conditionNeedRef /* , myPosition: myPositionRef.current */ }));
        }
        setIsError(false);
        onPrevStep();
    };
    const handleCopy = () => {
        openModal();
        CopyPasteFunctions.copyTextToClipboard(myPositionGoogleUrl);
        ;
    };
    const openModal = () => {
        setShowModal(true);
        setTimeout(() => {
            setShowModal(false);
        }, 1000);
    };
    const disabledErrorMetro = () => {
        setIsError(false);
        setMetroStation('');
        metroStationRef.current = '';
    };
    useFocusEffect(React.useCallback(() => {
        console.log('useFocusEfect locationScreen');
        !switchMetroBtn && metroStationRef.current.length === 0 ?
            disabledErrorMetro() : null;
    }, [switchMetroBtn, address, isGoogleUrlResponseOnError]));
    return (<>
        <TouchableWithoutFeedback onPress={resetHeader} disabled={!isError}>
            <View style={styles.container}>
                <View style={styles.buttonContainer}>
                    <MyButtonPress text={'Address'} txtCol={selectedButtonRef.current === 'Address' ? 'white' : '#59C09B'} txtS={12} txtFW={'500'} w={104} h={32} bgC={selectedButtonRef.current === 'Address' ? '#59C09B' : 'white'} br={6} b={1} bC={'#59C09B'} pad={5} cb={initAddress}/>
                    <MyButtonPress text={'Online'} txtCol={selectedButtonRef.current === 'Online' ? 'white' : '#59C09B'} txtS={12} txtFW={'500'} w={104} h={32} bgC={selectedButtonRef.current === 'Online' ? '#59C09B' : 'white'} br={6} b={1} bC={'#59C09B'} pad={5} cb={initOnlineUrl}/>
                    <MyButtonPress text={'Google map Url'} txtCol={selectedButtonRef.current === 'with link Url' ? 'white' : '#59C09B'} txtS={12} txtFW={'500'} w={104} h={32} bgC={selectedButtonRef.current === 'with link Url' ? '#59C09B' : 'white'} br={6} b={1} bC={'#59C09B'} pad={5} cb={initGoogleUrl}/>
                </View>
                {selectedButtonRef.current !== 'Online' ?
            <>
                        <View style={styles.addressAutoPicker}>
                            <AddressAutoPickerActivity placeholder={adressePlaceholder} query={query} setQuery={setQuery} selectedButtonRef={selectedButtonRef.current} address={address} setAddress={setAddress} handleAddressChange={handleAddressChange} googleUrl={googleUrl} googleUrlRef={googleUrlRef.current} setGoogleUrl={setGoogleUrl} handleGoogleUrlChange={handleGoogleUrlChange} isGoogleUrlResponseOnError={isGoogleUrlResponseOnError} setIsGoogleUrlResponseOnError={setIsGoogleUrlResponseOnError} setCoords={setCoords} isNewAddress={isNewAddress} setIsNewAddress={setIsNewAddress} myPositionRef={myPositionRef.current} myPositionGoogleUrl={myPositionGoogleUrl} setMyPositionGoogleUrl={setMyPositionGoogleUrl} cb={handleCopy} validateGoogleUrl={validateGoogleUrl} error={error} errorContent={t.JsonOnlineErrorMessage} setError={setError} validateConditionNeed={validateConditionNeed} setPostalCode={setPostalCode}/>
                        </View>
                        {(!addressRef.current && !googleUrlRef.current && !myPositionRef.current)
                    ? null :
                    <View style={styles.map}>
                                {Platform.OS === 'web' ? (<Maps Coords={Coords} setMyPositionGoogleUrl={setMyPositionGoogleUrl} myPosition={myPositionRef}/>) : (<AddressMapPickerMobile onAddressSelect={() => { }} selectedLatitude={Coords.lat} selectedLongitude={Coords.lng}/>)}
                            </View>}
                        <View style={styles.switchBtn}>
                            <Text style={styles.textbtn}>{t.JsonAddMetroIndication}</Text>
                            <SwitchBtn state={switchMetroBtn} setState={handleSwitchChange}/>
                        </View>
                        {switchMetroBtn && (<View style={styles.placeholder}>
                                <TextInputCustomComponent placeholderText="add your metro station" value={metroStation} handleTextChange={handleMetroChange}/>
                            </View>)}
                    </>
            :
                <View>
                        <TextInputCustomComponent Icon={Vectors.GeneralColorsLinkChain} placeholderText={t.JsonOnlinePlaceholder} value={onlineUrl} handleTextChange={handleOnlineUrlChange} multiline={true} numberOfLines={2}/>
                    </View>}
                <View style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                    <MyButtonPress text={'My GPS Position'} txtCol={'white'} txtS={16} txtFW={'700'} w={180} h={50} bgC={!!myPositionRef.current ? 'blue' : '#59C09B'} br={10} b={1} bC={'#59C09B'} pad={10} cb={initMyPosition}/>
                </View>
                <BottomWhiteNav items={[
            { label: translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton, onPress: () => handlePrevStep() },
            { label: translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton, onPress: () => handleNextStep(), disabled: !conditionNeedRef.current }
        ]}/>
            </View>
        </TouchableWithoutFeedback>   
        </>);
};
export default EditActivityLocationScreen;
