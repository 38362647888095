// Fr Cette page affiche l'étape 3 du formulaire pour modifier une activité (FrameFigma035A,FrameFigma035B)
// En This page displays the third step of the form to edit an activity (FrameFigma035A,FrameFigma035B)
import React, { useState } from 'react';
import { Platform, ScrollView, Text, View } from 'react-native';
import SwitchBtn from '../../../../components/ButtonSection/SwitchButton/Code';
import SliderComponent from '../../../../components/GeneralSection/SliderComponent/SliderComponent';
import { useError } from '../../../../contexts/ErrorContext';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
// Import Components
import RequestCoOrganisers from '../../../../components/ActivitySection/ActivityDetailsRequestCoOrganisersComponent/Code';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const DuplicateActivityManageParticipationScreen = ({ onNextStep, onPrevStep, setHeaderTitle }) => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { error, setError } = useError();
    // const {currentActivity, setCurrentActivity, currentActivitySwitch, setCurrentActivitySwitch} = useActivity();
    const { currentActivity, currentActivitySwitch } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setCurrentActivity = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivity({ id: nanoid(), value: data }));
    };
    const setCurrentActivitySwitch = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivitySwitch({ id: nanoid(), value: data }));
    };
    const [ticketing, setTicketing] = useState(currentActivitySwitch.price);
    const [sliderValue, setSliderValue] = useState(currentActivity.attendeeLimit);
    const [sliderValue1, setSliderValue1] = useState(currentActivity.friendsNumber);
    const [buyTicketsLink, setBuyTicketsLink] = useState(currentActivity.buyTicketsLink);
    const [switchBtnUnlimitted, setSwitchBtnUnlimitted] = useState(currentActivitySwitch.switchBtnUnlimitted);
    const [switchBtn3, setSwitchBtn3] = useState(currentActivitySwitch.switchBtn3);
    const [switchBtn4, setSwitchBtn4] = useState(currentActivitySwitch.switchBtn4);
    const [switchBtn5, setSwitchBtn5] = useState(currentActivitySwitch.switchBtn5);
    const [switchBtn6, setSwitchBtn6] = useState(currentActivitySwitch.switchBtn6);
    // const conditionNeedRef = useRef<boolean>(true);
    //Fonction pour activiter l'option illimité d'invités
    const handleSwitchUnlimitted = (state) => {
        setSliderValue(2);
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { switchBtnUnlimitted: !state }));
    };
    //Fonction pour gerer l'état du switch price
    const handleSwitchPrice = (state) => {
        setError('');
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { hasPrice: !state }));
    };
    //Fonction pour recupérer le lien de la billeterie
    const handleInputTcketLink = (text) => {
        setBuyTicketsLink(text);
        setError('');
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { buyTicketsLink: text }));
    };
    //Fonction pour gerer l'état du switch notification
    const handleSwitchHelpOrganizers = (state) => {
        setError('');
        let myState = !state;
        !myState ? setSwitchBtn6(false) : null;
    };
    //Fonction pour gerer l'état du switch notification
    const handleSwitchNotifyPreviousAttendees = (state) => {
        setError('');
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { notifyPreviousAttendees: !state }));
    };
    //Fonction pour gerer l'état du switch invitation
    const handleSwitchInviteMore = (state) => {
        setError('');
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { inviteMore: !state }));
    };
    //Fonction pour gerer l'état du switch invitation
    const handleSwitchRequestCoOrga = (state) => {
        setError('');
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { requestCoOrga: !state, coOrganizerConditions: '', coOrganizerGifts: '' }));
    };
    //Fonction pour le slider du nombre d'invité
    const handleSliderAttendeeLimitation = (value) => {
        setError('');
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { attendeeLimit: value }));
    };
    //Fonction pour le slider du nombre d'invité
    const handleSliderFriendsNumber = (value) => {
        setError('');
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { friendsNumber: value }));
    };
    const handlePrevStep = () => {
        setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtnUnlimitted: switchBtnUnlimitted, price: !!ticketing && buyTicketsLink === '' ? false : ticketing, switchBtn3: !switchBtn4 && !switchBtn5 && !switchBtn6 ? false : true, switchBtn4: switchBtn4, switchBtn5: switchBtn5, switchBtn6: switchBtn6 }));
        onPrevStep();
    };
    const handleNextStep = () => {
        const urlRegex = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/;
        let errorMessage = '';
        if ((ticketing === true && buyTicketsLink && !urlRegex.test(buyTicketsLink)) || (ticketing && buyTicketsLink === '')) {
            errorMessage = translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonTicketingLinkErrorMessage;
        }
        else if (switchBtn3 === true && switchBtn4 === false && switchBtn5 === false && switchBtn6 === false) {
            errorMessage =
                'Sélectionne au moins une option parmi "Notifier mes participants précédents", "Inviter plus de personnes" ou "Demander un co-organisateur".';
        }
        if (errorMessage !== '') {
            setError(errorMessage);
            return;
        }
        if (!ticketing) {
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { buyTicketsLink: '', hasPrice: false }));
        }
        console.log(switchBtnUnlimitted, 'switchBtnUnlimitted');
        if (switchBtnUnlimitted === true) {
            console.log('avant setter');
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { attendeeLimit: 1000000 }));
        }
        console.log('par la');
        if (!switchBtn3) {
            console.log('switchBtn3 désactivé');
            setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { coOrganizerConditions: '', coOrganizerGifts: '', notifyPreviousAttendees: false, inviteMore: false, requestCoOrga: false }));
            setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtnUnlimitted: switchBtnUnlimitted, switchBtn3: false, switchBtn4: false, switchBtn5: false, switchBtn6: false }));
            setHeaderTitle('Help for organizers');
            onNextStep();
            return;
        }
        if (switchBtn6) {
            console.log('switchBtn6 activé');
            if (!currentActivity.coOrganizerConditions.length || currentActivity.coOrganizerConditions[0] === '') {
                setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { coOrganizerConditions: '' }));
            }
            if (!currentActivity.coOrganizerGifts.length || currentActivity.coOrganizerGifts[0] === '') {
                setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { coOrganizerGifts: '' }));
            }
            if ((!currentActivity.coOrganizerConditions.length || currentActivity.coOrganizerConditions[0] === '') && (!currentActivity.coOrganizerGifts.length || currentActivity.coOrganizerGifts[0] === '')) {
                console.log("switchBtn6 activé et les deux tableaux sont vides");
                setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtnUnlimitted: switchBtnUnlimitted, switchBtn3: !switchBtn4 && !switchBtn5 ? false : true, switchBtn4: switchBtn4, switchBtn5: switchBtn5, switchBtn6: false }));
                setHeaderTitle('Help for organizers');
                onNextStep();
                return;
            }
            else {
                setCurrentActivitySwitch(Object.assign(Object.assign({}, currentActivitySwitch), { switchBtnUnlimitted: switchBtnUnlimitted, switchBtn3: true, switchBtn4: switchBtn4, switchBtn5: switchBtn5, switchBtn6: true }));
                setHeaderTitle('Help for organizers');
                onNextStep();
                return;
            }
        }
        ;
        console.log('sinon ici');
        setCurrentActivity(Object.assign({}, currentActivity));
        setCurrentActivitySwitch({
            switchBtn3: !switchBtn4 && !switchBtn5 && !switchBtn6 ? false : true,
            switchBtn4: switchBtn4,
            switchBtn5: switchBtn5,
            switchBtn6: switchBtn6,
        });
        setHeaderTitle('Help for organizers');
        onNextStep();
        return;
    };
    return (<ScrollView showsVerticalScrollIndicator={false}>
            {error ? (<View style={styles.headerError}>
                    <Text style={styles.headerErrorText}>{error}</Text>
                </View>) : null}
            <View style={styles.container}>
                <View>
                    <View style={styles.switchBtn}>
                        <Text style={styles.textbtn}>
                            {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonUnlimitedAttendees}
                        </Text>
                        <SwitchBtn state={switchBtnUnlimitted} setState={setSwitchBtnUnlimitted} callBack={handleSwitchUnlimitted}/>
                    </View>
                    <Text>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonOnlyAppWithoutRestrictionForOrganizers}</Text>
                </View>
                {switchBtnUnlimitted !== true && (<View style={styles.slider}>
                        <Text style={styles.slider}>
                            {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonAttendeesLimitation}
                        </Text>
                        <SliderComponent min={2} max={30} sliderValue={sliderValue} setSliderValue={setSliderValue} callBack={handleSliderAttendeeLimitation}/>
                    </View>)}
                <View style={styles.switchBtn}>
                    <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonTicketing}</Text>
                    <SwitchBtn state={ticketing} setState={setTicketing} callBack={handleSwitchPrice}/>
                </View>
                {ticketing === true && (<View>
                        <View style={{ width: '100%' }}>
                            <TextInputCustomComponent placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonBuyTicket} handleTextChange={handleInputTcketLink} value={buyTicketsLink} isPlaceHolderTextAlignCenter={false}/>
                        </View>
                    </View>)}
                <View style={styles.slider}>
                    <Text style={styles.slider}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonHowMuchFriends}</Text>
                    <SliderComponent min={0} max={9} sliderValue={sliderValue1} setSliderValue={setSliderValue1} callBack={handleSliderFriendsNumber}/>
                </View>
                <View style={styles.switchBtn}>
                    <Text style={styles.textbtn}>{translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonHelpOrganizers}</Text>
                    <SwitchBtn state={switchBtn3} setState={setSwitchBtn3} callBack={handleSwitchHelpOrganizers}/>
                </View>
                {switchBtn3 === true && (<View style={styles.switchBtnSousSwitchBtn2}>
                        {/* ici pour reculer(des marginLeft) les sous boutton */}
                        <View style={styles.switchBtn2}>
                            <Text style={styles.textbtn}>
                                {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonNotifyMyPreviousAttendees}
                            </Text>
                            <SwitchBtn state={switchBtn4} setState={setSwitchBtn4} callBack={handleSwitchNotifyPreviousAttendees}/>
                        </View>
                        <View style={styles.switchBtn2}>
                            <Text style={styles.textbtn}>
                                {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonInviteMorePeople}
                            </Text>
                            <SwitchBtn state={switchBtn5} setState={setSwitchBtn5} callBack={handleSwitchInviteMore}/>
                        </View>
                        <View style={styles.switchBtn2}>
                            <Text style={styles.textbtn}>
                                {translations.JsonActivityPages.JsonActivityManagement.JsonGeneralOptionsScreen.JsonCoOrganizerRequestAsk}
                            </Text>
                            <SwitchBtn state={switchBtn6} setState={setSwitchBtn6} callBack={handleSwitchRequestCoOrga}/>
                        </View>
                    </View>)}
                <View>
                    {switchBtn6 && (<RequestCoOrganisers coOrgConditionsExisting={currentActivity.coOrganizerConditions} coOrgOffersExisting={currentActivity.coOrganizerGifts}/>)}
                </View>
                <View style={styles.btnContainer}>
                    {/* si btn ticketing est on, ya condition, sinon pas de condition */}
                    <ButtonPrevious onPrevStep={handlePrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
                    <ButtonNext handleNextStep={handleNextStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton} conditionNeed={!(ticketing && buyTicketsLink === '')
            && !(switchBtn6 && (currentActivity.coOrganizerConditions.length === 0 && currentActivity.coOrganizerGifts.length === 0))
            && !(switchBtn3 && !switchBtn4 && !switchBtn5 && !switchBtn6)}/>
                </View>
            </View>
        </ScrollView>);
};
export default DuplicateActivityManageParticipationScreen;
