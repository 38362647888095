import React from 'react';
import { View, Text, Pressable, Platform } from 'react-native';
// import styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const SwitchBtn = ({ state, setState, disabled, callBack }) => {
    return (<Pressable style={[
            styles.container,
            {
                backgroundColor: state ? '#4caf50' : '#E64C3C',
                opacity: disabled ? 0.5 : 1,
            },
        ]} onPress={() => {
            setState(!state);
            callBack && callBack(state);
        }} disabled={disabled}>
            <View style={[styles.circle, { marginLeft: state ? 23 : 2 }]}/>
            <Text style={[styles.switchInfo, state ? { right: 22 } : { left: 20 }, disabled ? { color: 'grey' } : {}]}>{state ? 'ON' : 'OFF'}</Text>
        </Pressable>);
};
export default SwitchBtn;
