// FR il demande à utilisateur de fournir des informations pour la suppression de son compte (FrameFigma111)
// GB  Please provide the information required for the deletion of your account."(FrameFigma111)
import { View, Text, TextInput, TouchableOpacity, ScrollView } from 'react-native';
import React, { useState } from 'react';
import styles from './StylesWeb';
import { useNavigation } from "@react-navigation/native";
// import DeleteAccountScreenURL from '../DeleteAccountScreenURL/DeleteAccountScreenURL'
import ConfirmationModal from '../ConfirmationModal/Code';
import { userServices } from '../../../services/user/UserServices';
const DeleteAccountStep1 = () => {
    // const { deleteUserAccount } = useUser();
    const { deleteUserAccount } = userServices;
    const navigation = useNavigation();
    const [formData, setFormData] = useState({
        userId: '',
        feedback: '',
        subscribe: 'no',
        issuesFeedback: [],
    });
    const [showConfirmation, setShowConfirmation] = useState(false);
    const handleChange = (type, value) => {
        if (type === "feedback")
            setFormData(prev => (Object.assign(Object.assign({}, prev), { feedback: value })));
        else if (type === "subscribe")
            setFormData(prev => (Object.assign(Object.assign({}, prev), { subscribe: value })));
        else if (type === "issuesFeedback") {
            const newIssue = formData.issuesFeedback.includes(value)
                ? formData.issuesFeedback.filter(existingvalue => existingvalue !== value)
                : [...formData.issuesFeedback, value];
            setFormData(prev => (Object.assign(Object.assign({}, prev), { issuesFeedback: newIssue })));
        }
        ;
    };
    return (<ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      <Text style={styles.deleteAccountText}>Why do you want {'\n'} to delete your account?</Text>
      <Text style={styles.feedbackText}>Can you provide us with additional feedback about your experience with our service?</Text>
      <TextInput style={styles.input} multiline={true} value={formData.feedback} onChangeText={text => handleChange("feedback", text)}/>{/* placeholder="Enter your feedback"  */}
      <Text style={styles.feedbackText}>Are you sure, you will never receive the newsletter by email in the future?</Text>
      <View style={styles.buttonContainer}>
        <TouchableOpacity style={[styles.btnYesNo, formData.subscribe == "no" && styles.btnSelectedGreen]} onPress={() => handleChange("subscribe", "no")}>
          <Text style={[styles.buttonText, styles.buttonBold, formData.subscribe == "no" && styles.buttonTextWhite]}>No</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.btnYesNo, formData.subscribe == "yes" && styles.btnSelectedRed]} onPress={() => handleChange("subscribe", "yes")}>
          <Text style={[styles.buttonText, styles.buttonBold, formData.subscribe == "yes" && styles.buttonTextWhite]}>Yes</Text>
        </TouchableOpacity>
      </View>
      <Text style={styles.feedbackText}>Can you tell us if you encountered any specific issues with our service?</Text>
      <View style={styles.issueButtonContainer}>
        <View style={styles.issueButtonRow}>
          <TouchableOpacity style={[styles.issueButton, formData.issuesFeedback.includes("useless") && styles.btnSelectedGreen]} onPress={() => handleChange("issuesFeedback", "useless")}>
            <Text style={[styles.buttonText, formData.issuesFeedback.includes("useless") && styles.buttonTextWhite]}>Not useful</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.issueButton, formData.issuesFeedback.includes("slow") && styles.btnSelectedGreen]} onPress={() => handleChange("issuesFeedback", "slow")}>
            <Text style={[styles.buttonText, formData.issuesFeedback.includes("slow") && styles.buttonTextWhite]}>Too slow</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.issueButtonRow}>
          <TouchableOpacity style={[styles.issueButton, formData.issuesFeedback.includes("complicated") && styles.btnSelectedGreen]} onPress={() => handleChange("issuesFeedback", "complicated")}>
            <Text style={[styles.buttonText, formData.issuesFeedback.includes("complicated") && styles.buttonTextWhite]}>Complicated</Text>
          </TouchableOpacity>
          <TouchableOpacity style={[styles.issueButton, formData.issuesFeedback.includes("competitor") && styles.btnSelectedGreen]} onPress={() => handleChange("issuesFeedback", "competitor")}>
            <Text style={[styles.buttonText, formData.issuesFeedback.includes("competitor") && styles.buttonTextWhite]}>Better competitor</Text>
          </TouchableOpacity>
        </View>
      </View>
      <Text style={styles.feedbackText}>Are you sure, {'\n'} you want to delete your account?</Text>
      <View style={styles.deleteCancelButtonRow}>
        <TouchableOpacity style={styles.cancelButton} onPress={() => { navigation.navigate('Activities'); }}>
          <Text style={[styles.buttonBigSize, styles.buttonBold]}>CANCEL</Text>
        </TouchableOpacity>
        <TouchableOpacity style={[styles.deleteButton, styles.btnSelectedRed]} onPress={() => setShowConfirmation(true)} // Utilisation de la fonction de suppression
    >
          <Text style={[styles.buttonBigSize, styles.buttonBold, styles.buttonTextWhite]}>DELETE</Text>
        </TouchableOpacity>
      </View>
      {showConfirmation && <ConfirmationModal showConfirmation={showConfirmation} setShowConfirmation={setShowConfirmation} formData={formData}></ConfirmationModal>}
    </ScrollView>);
};
export default DeleteAccountStep1;
