//🇫🇷 Ce fichier gère le style web du composant VerifyEmailExistingUserScreen.js (FrameFigma007A FrameFigma007B FrameFigma007C)
//🇬🇧 This file manages the web style of the VerifyEmailExistingUserScreen.js component (FrameFigma007A FrameFigma007B FrameFigma007C)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    verification: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'white',
    },
    container: {
        width: '80%',
        flex: 1,
        marginTop: '15%',
        alignItems: 'center', // Center align the container
    },
    verificationText: {
        textAlign: 'center',
        fontSize: 14,
        fontWeight: '400',
        lineHeight: 18.46,
        marginBottom: 20, // Add some space below the text
    },
    inputs: {
        flexDirection: 'row',
        justifyContent: 'center', // Center align the inputs
        marginVertical: 20,
    },
    opt: {
        width: 43.35,
        height: 40.17,
        backgroundColor: 'white',
        borderColor: '#000000',
        borderWidth: 1,
        borderRadius: 10,
        textAlign: 'center',
        marginHorizontal: 5, // Adjust margin to center align
        fontSize: 18, // Adjust font size for better readability
    },
    optError: {
        borderColor: 'red', // Changement de la bordure en rouge en cas d'erreur
    },
    resend: {
        alignItems: 'center',
        marginVertical: 20,
    },
    resendText: {
        textAlign: 'center',
        fontSize: 16,
        marginBottom: 10,
    },
    btnResendContainer: {
        width: '40%',
        marginTop: 10,
        borderWidth: 1,
        borderColor: '#59C09B',
        paddingVertical: 6,
        borderRadius: 15,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    btnResend: {
        alignContent: 'center',
        justifyContent: 'center',
    },
    btnTextResend: {
        color: '#59C09B',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    btnContainer: {
        flexDirection: 'row',
        marginVertical: '10%',
        justifyContent: 'space-between',
        width: '110%',
        alignItems: 'center',
    },
    btnText: {
        color: 'white',
        fontSize: 22,
        fontWeight: 'bold',
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    paste: {
        width: 20,
        height: 20,
        alignSelf: 'flex-end',
        marginRight: 1,
    },
    pasteContainer: {
        alignSelf: 'flex-end',
        marginRight: '5%',
    },
});
