var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ActivityServices } from "./ActivityServices";
// Updated Service Functions
const getParticipants = (activity, allUsers) => {
    const participantsIds = activity.attendees;
    if (participantsIds.length === 0) {
        return [];
    }
    else {
        return allUsers.filter((user) => participantsIds.includes(user._id));
    }
};
const getParticipantById = (allUsers, id) => {
    return allUsers.find((user) => user._id === id);
};
const isUserParticipate = (activity, userId) => {
    return activity.attendees.includes(userId);
};
const joinWaitingList = (activity, userId, setActivityView, setModalWaitingListVisible) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        yield ActivityServices.updateActivity(activity._id, Object.assign(Object.assign({}, activity), { waitingList: [...activity.waitingList, userId] }));
        setActivityView(Object.assign(Object.assign({}, activity), { waitingList: [...activity.waitingList, userId] }));
        setModalWaitingListVisible(false);
    }
    catch (error) {
        console.log('Error joining waiting list', error);
    }
});
const leaveWaitingList = (activity, userId, setActivityView, setModalWaitingListVisible) => __awaiter(void 0, void 0, void 0, function* () {
    const waitingListArray = activity.waitingList.filter((id) => id !== userId);
    try {
        yield ActivityServices.updateActivity(activity._id, Object.assign(Object.assign({}, activity), { waitingList: waitingListArray }));
        setActivityView(Object.assign(Object.assign({}, activity), { waitingList: waitingListArray }));
        setModalWaitingListVisible(false);
    }
    catch (error) {
        console.log('Error leaving waiting list', error);
    }
});
const isUserInWaitingList = (activity, userId) => {
    return activity.waitingList.includes(userId);
};
const getParticipantwaitingList = (activity, allUsers) => {
    const participantsIds = activity.waitingList;
    if (participantsIds.length === 0) {
        return ['Pas de participants dans la waitingList'];
    }
    else {
        return allUsers.filter((user) => participantsIds.includes(user._id));
    }
};
const getCoOrganizers = (activity, allUsers) => {
    const participantsIds = activity.coOrganizers;
    if (participantsIds.length === 0) {
        return ['Pas de coOrganizers pour le moment'];
    }
    else {
        return allUsers.filter((user) => participantsIds.includes(user._id));
    }
};
const getCoOrganizersWaitingList = (activity, allUsers) => {
    const participantsIds = activity.unApprovedCoOrganizers;
    if (participantsIds.length === 0) {
        return ['Pas de coOrganizers dans la waitingList'];
    }
    else {
        return allUsers.filter((user) => participantsIds.includes(user._id));
    }
};
const getUnApprovedParticipants = (activity, allUsers) => {
    const participantsIds = activity.unApprovedUsers;
    if (participantsIds.length === 0) {
        return ['Pas de participants non approuvés pour le moment'];
    }
    else {
        return allUsers.filter((user) => participantsIds.includes(user._id));
    }
};
export const ParticipantsServices = {
    getParticipants,
    getParticipantById,
    isUserParticipate,
    joinWaitingList,
    leaveWaitingList,
    isUserInWaitingList,
    getParticipantwaitingList,
    getCoOrganizers,
    getCoOrganizersWaitingList,
    getUnApprovedParticipants,
};
