// Fr  Styles de DuplicateActivityManageParticipationScreen(FrameFigma035A,FrameFigma035B)
// En  Styles of DuplicateActivityManageParticipationScreen(FrameFigma035A,FrameFigma035B) 
import { StyleSheet } from "react-native";
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        paddingLeft: 25,
        paddingRight: 25
    },
    switchBtn: {
        marginVertical: 10,
        paddingVertical: 5,
        flexDirection: "row",
        alignItems: "center",
    },
    switchBtn2: {
        marginVertical: 10,
        paddingVertical: 5,
        flexDirection: "row",
        alignItems: "center",
    },
    textbtn: {
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
        marginVertical: 10,
    },
    slider: {
        fontWeight: "bold",
        fontSize: 18,
        marginVertical: 20,
    },
    inputContainer: {
        paddingHorizontal: 8,
        justifyContent: "center",
        fontSize: 16,
        borderWidth: 1,
        marginVertical: 20,
        width: '90%',
        borderRadius: 15,
        padding: 20,
    },
    textTitle: {
        paddingHorizontal: 15,
        backgroundColor: "white",
        fontSize: 13,
        textAlign: "center",
        position: "absolute",
        zIndex: 1,
        left: 20,
        top: -10,
    },
    focusedInput: {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    btnContainer: {
        width: '100%',
        alignContent: 'center',
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    btnNext: {
        backgroundColor: '#59C09B',
        width: '55%',
        padding: 8,
        borderRadius: 15,
        marginHorizontal: 50,
        alignItems: 'center',
        elevation: 5,
        margin: 30,
    },
    btnText: {
        color: 'white',
        fontSize: 16,
    },
});
