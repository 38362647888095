import React from 'react';
import { Platform, View, Text, FlatList, TouchableOpacity } from 'react-native';
import ParticipantCard from '../../CardSection/ParticpantsCard/Code';
import { authorServices } from '../../../services/activities/auhorServices';
import Vectors from '../../../constants/Vectors';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const ActivityDetailsParticipantsDisplay = ({ title, activity, data, groupeData, setter, getter, isUserAuthor }) => {
    return (<View>
            <View style={styles.titleAttendees}>
                <Text style={styles.titleText}>{title}- ({data[0].firstName ? data.length : 0})</Text>
                <View style={[styles.arrowDown, getter && { transform: [{ rotate: '90deg' }] }]}>
                    <TouchableOpacity onPress={() => setter(!getter)}>
                        <Vectors.RightArrow />
                    </TouchableOpacity>
                </View>
            </View>
            {getter && (<FlatList showsVerticalScrollIndicator={false} data={groupeData} renderItem={({ item: rowItems }) => (<View style={styles.row}>
                            {rowItems.map((item) => item['firstName'] ? (<ParticipantCard key={item._id} participant={item} isAuthor={authorServices.isAuthor(activity.author, item._id)} isUserAuthor={isUserAuthor}/>) : (<Text key={item._id}>{item}</Text>))}
                        </View>)} keyExtractor={(index) => index.toString()} style={{ paddingHorizontal: 10, paddingVertical: 20 }}/>)}
        </View>);
};
export default ActivityDetailsParticipantsDisplay;
