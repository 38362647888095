import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F7F7F7',
        borderRadius: 10,
        padding: 10,
        height: 110,
        width: 100,
    },
    // Style de bouton selected
    selected: {
        backgroundColor: '#59C09B',
    },
    Vectorsection: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: 50,
        padding: 5,
        marginVertical: 5
    },
    textSection: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginVertical: 5
    },
    text: {
        textAlign: 'center',
        fontSize: 13,
        fontWeight: 'bold',
    }
});
export default styles;
