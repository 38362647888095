var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// sélectionner une adresse à partir d'une carte interactive (FrameFigma042,FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma032A,FrameFigma032B,FrameFigma032C)
// Select an address from an interactive map. (FrameFigma042,FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma032A,FrameFigma032B,FrameFigma032C)
import React, { useState, useEffect } from 'react';
import { View, StyleSheet } from 'react-native';
import Geolocation from '@react-native-community/geolocation'; // Import du module de géolocalisation
import MapView, { Marker } from 'react-native-maps';
import { GOOGLE_MAPS_API_KEY } from '@env';
const AddressMapPickerMobile = ({ onAddressSelect, selectedLatitude, selectedLongitude }) => {
    const [location, setLocation] = useState({
        latitude: selectedLatitude || 0,
        longitude: selectedLongitude || 0,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
    });
    useEffect(() => {
        const fetchLocation = () => __awaiter(void 0, void 0, void 0, function* () {
            Geolocation.getCurrentPosition((position) => {
                const { latitude, longitude } = position.coords;
                setLocation({
                    latitude,
                    longitude,
                    latitudeDelta: 0.0922,
                    longitudeDelta: 0.0421,
                });
                fetchAddress(latitude, longitude);
            }, (error) => {
                console.warn(`Error getting location: ${error.message}`);
            }, { enableHighAccuracy: true, timeout: 15000, maximumAge: 10000 });
        });
        if (!selectedLatitude || !selectedLongitude) {
            fetchLocation();
        }
        else {
            setLocation({
                latitude: selectedLatitude,
                longitude: selectedLongitude,
                latitudeDelta: 0.0922,
                longitudeDelta: 0.0421,
            });
            fetchAddress(selectedLatitude, selectedLongitude);
        }
    }, [selectedLatitude, selectedLongitude]);
    const fetchAddress = (latitude, longitude) => __awaiter(void 0, void 0, void 0, function* () {
        const geocodeUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAPS_API_KEY}`;
        try {
            const response = yield fetch(geocodeUrl);
            const data = yield response.json();
            if (data.results.length > 0) {
                const address = data.results[0].formatted_address;
                onAddressSelect && onAddressSelect(address, latitude, longitude);
            }
        }
        catch (error) {
            console.error('Failed to fetch address', error);
        }
    });
    const handleDragEnd = (e) => __awaiter(void 0, void 0, void 0, function* () {
        const { latitude, longitude } = e.nativeEvent.coordinate;
        fetchAddress(latitude, longitude);
    });
    return (<View style={styles.container}>
      <MapView style={styles.map} region={location}>
        <Marker coordinate={location} title="Selected Location" description="This is the selected location" draggable // Rendre le marqueur déplaçable
     onDragEnd={handleDragEnd} // Gérer la fin du déplacement
    />
      </MapView>
    </View>);
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        width: 500,
        height: 500
    },
    map: {
        width: 500,
        height: 300
    },
});
export default AddressMapPickerMobile;
