//Fr Styles de ButtonNext (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma036) (FrameFigma037A) (FrameFigma031) (FrameFigma035A,FrameFigma035B) (FrameFigma036) (FrameFigma038A,FrameFigma038B) (FrameFigma039)
//En Styles of ButtonNext (FrameFigma031,FrameFigma032A,FrameFigma035A) (FrameFigma036) (FrameFigma037A) (FrameFigma031) (FrameFigma035A,FrameFigma035B) (FrameFigma036) (FrameFigma038A,FrameFigma038B) (FrameFigma039)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    btnNext: {
        backgroundColor: '#59C09B',
        width: 190,
        paddingVertical: 15,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000000', // Couleur de l'ombre
        shadowOffset: { width: 0, height: 4 }, // Décalage de l'ombre
        shadowOpacity: 0.25, // Opacité de l'ombre
        shadowRadius: 4, // Rayon de l'ombre
        elevation: 5, // Pour Android, nécessaire pour l'ombre
    },
    btnText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
        paddingHorizontal: 2,
    },
});
