var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { View, ImageBackground, Text, Pressable, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { hostname } from '../../../../../hostName/hostName';
import { Vectors } from '../../../../constants/Vectors';
import ProfileMedal from '../../../../components/ProfileSection/ProfileMedal/Code';
import AddFriendModal from '../../../../components/ModalPopUpModule/ModalSection/AddFriendModal/Code';
import { calculateUserPoints } from '../../../../utils/Functions/UserPointsCalculator';
import { addUserLike, deleteUserLike } from '../../../../services/user/UserLikesServices';
import ChatModal from '../../../../components/ModalPopUpModule/ModalSection/ChatModal/Code';
import { useWebSocket } from '../../../../sockets/WebSocketLocal';
import { useSelector } from 'react-redux';
import { CompleteProfileScreen } from "../../../../components/ModalPopUpModule/AddFriendPopUpSection/CompleteProfileToChatScreen/Code";
import { OnlyFriendsChatModal } from "../../../../components/ModalPopUpModule/AddFriendPopUpSection/CompleteProfileToChatScreen/OnlyFriendsCanChatModal";
const PublicProfileTopScreen = ({ userId, profileOwner, status }) => {
    var _a;
    const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
    const { socket, privateChatMessages, updateCurrentChatId, sendChatMessage, requestChatHistory, userChats } = useWebSocket();
    const { user } = useSelector((state) => state.user);
    const { translations } = useSelector((state) => state.language);
    const [showModal, setShowModal] = useState(false);
    const [showChatModal, setShowChatModal] = useState(false);
    const [showCompleteModal, setShowCompleteModal] = useState(false);
    const [showOnlyFriendsChatModal, setShowOnlyFriendsChatModal] = useState(false);
    const toggleChatModal = () => setShowChatModal(!showChatModal);
    const [userIsLiked, setUserIsLiked] = useState(false);
    const toggleModal = () => setShowModal(!showModal);
    const toggleCompleteModal = () => setShowCompleteModal(!showCompleteModal);
    const toggleOnlyFriendsChatModal = () => setShowOnlyFriendsChatModal(!showOnlyFriendsChatModal);
    const userPoints = calculateUserPoints(userId || (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner._id));
    const [currentChatId, setCurrentChatId] = useState('');
    useEffect(() => {
        if (userId) {
            const chatId = Object.keys(privateChatMessages).find(key => key.includes(userId));
            if (chatId) {
                updateCurrentChatId(chatId);
                setCurrentChatId(chatId);
            }
        }
    }, [userId, privateChatMessages]);
    const toggleLike = () => __awaiter(void 0, void 0, void 0, function* () {
        setUserIsLiked(!userIsLiked);
        if (userIsLiked) {
            deleteUserLike(user._id, userId);
        }
        else {
            addUserLike(user._id, userId);
        }
    });
    const recordProfileVisit = (visitedUserID) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const userToken = yield AsyncStorage.getItem('userToken');
            const response = yield fetch(`${hostname}/users/${visitedUserID}/visit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${userToken}`,
                },
                body: JSON.stringify({ visitorId: user === null || user === void 0 ? void 0 : user._id }),
            });
            if (!response.ok) {
                throw new Error('Failed to record profile visit');
            }
            const profileVisits = yield response.json();
            console.log('Profile visit recorded:', profileVisits);
            if (socket && visitedUserID !== (user === null || user === void 0 ? void 0 : user._id)) {
                socket.emit('profileVisit', { visitedUserId: visitedUserID, visitorUserId: user === null || user === void 0 ? void 0 : user._id });
            }
        }
        catch (error) {
            console.error('Error recording profile visit:', error);
        }
    });
    useEffect(() => {
        // if (socket && userId && userId !== user?._id) {
        recordProfileVisit(user._id);
        // }
    }, [userId /* , user?._id, socket */]);
    useEffect(() => {
        const getUserLikes = (currentUserId) => __awaiter(void 0, void 0, void 0, function* () {
            const token = yield AsyncStorage.getItem('userToken');
            const userIdStr = yield AsyncStorage.getItem('userId');
            const userId = userIdStr || "";
            const response = yield fetch(`${hostname}/userLikes`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const data = yield response.json();
            const likeStatus = data.some((like) => like.likedBy === userId && like.likedUser === currentUserId);
            setUserIsLiked(likeStatus);
        });
        if (userId) {
            getUserLikes(userId);
        }
    }, [userId]);
    if (!profileOwner) {
        return <Text>Loading profile...</Text>;
    }
    const calculYears = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 ||
            (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };
    function mountChat() {
        if (!userChats.find(chat => {
            var _a;
            const participantIds = (_a = chat.participants) === null || _a === void 0 ? void 0 : _a.map(participant => participant._id);
            return participantIds && participantIds.includes(userId) && participantIds.includes(profileOwner._id);
        })) {
        }
    }
    function friendsWithUser() {
        return profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.friends.includes(user._id);
    }
    function completeProfile() {
        return !!user.dateOfBirth && (!!user.useMyBirthday || !!user.useMyPhone || !!user.useMyEmail || !!user.useMyLastName);
    }
    return (<View style={styles.container}>
      <ImageBackground source={{ uri: profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.profileImage }} style={styles.profileImage}>
        {status && <View style={styles.statusOnline}/>}
        {!userIsLiked && userId !== (user === null || user === void 0 ? void 0 : user._id) ? (<Pressable onPress={toggleLike} style={styles.likeButton}>
            <Vectors.UsersInteractionBlackAndWhiteHeartGrey width={40} height={40}/>
          </Pressable>) : null}
        {userIsLiked && userId !== (user === null || user === void 0 ? void 0 : user._id) ? (<Pressable onPress={toggleLike} style={styles.likeButton}>
            <Vectors.UsersInteractionColorsHeartRed width={40} height={40}/>
          </Pressable>) : null}
      </ImageBackground>
      <View style={styles.cupIconContainer}>
        <View style={styles.cupIcon}>
          <ProfileMedal role={(profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.role) === 'diamondUser' ? 'diamondUser' :
            (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.role) === 'emeraldUser' ? 'emeraldUser' :
                (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.role) === 'rubyUser' ? 'rubyUser' :
                    (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.role) === 'goldUser' ? 'goldUser' :
                        'basicUser'} points={userPoints} isAmbassador={false} size={54}/>
        </View>
        <Text style={styles.pointsTextBelowCup}>
          {userPoints} {translations.JsonProfilePages.JsonMyProfileScreen.JsonPoints}
        </Text>
      </View>
      <View style={styles.infosContainer}>
        <Text style={styles.info}>Organizer: ⭐⭐⭐⭐⭐</Text>
        <Text style={styles.info}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonReliability}: -------------</Text>
      </View>
      <View style={styles.infosContainer2}>
        <Text style={styles.userName}>{profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.userName}</Text>
        {(profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.useMyAge) ? (<Text style={styles.info2Text}>
            {calculYears(profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.dateOfBirth)}{' '}
            {translations.JsonProfilePages.JsonMyProfileScreen.JsonYears}
          </Text>) : null}
        <View style={styles.infosCityRow}>
          <Vectors.PinBlackAndWhite style={styles.Location} width={24} height={24}/>
          <Text style={styles.text}>{(_a = profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.city) === null || _a === void 0 ? void 0 : _a.split(',')[0]}</Text>
        </View>
      </View>
      {userId !== (user === null || user === void 0 ? void 0 : user._id) ? (<View style={styles.btnsAmiContainer}>
          {friendsWithUser() ? null : (<Pressable style={[styles.actionButton, styles.addFriendButton]} onPress={completeProfile() ? toggleModal : toggleCompleteModal}>
              <Vectors.ProfilesAddFriend width={18} height={18} style={styles.buttonIcon}/>
              <Text style={styles.buttonText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonAddFriend}</Text>
            </Pressable>)}
          <Pressable onPress={() => { friendsWithUser() ? toggleChatModal() : toggleOnlyFriendsChatModal(); }} style={[styles.actionButton, styles.chatButton]}>
            <Vectors.ProfilesChatColors width={18} height={18} style={styles.buttonIcon}/>
            <Text style={styles.buttonText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonChat}</Text>
          </Pressable>
          {showChatModal && (<ChatModal isVisible={showChatModal} closeModal={() => toggleChatModal()} chatId={currentChatId} activityId={''} participants={[{ _id: user._id }, { _id: profileOwner._id }]}/>)}
          <Pressable style={[styles.actionButton, styles.blockButton]}>
            <Vectors.ProfilesBlockFriend width={18} height={18} style={styles.buttonIcon}/>
            <Text style={styles.buttonText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonBlock}</Text>
          </Pressable>
        </View>) : null}
      <AddFriendModal visible={showModal} onClose={toggleModal} currentUser={profileOwner}/>
      <CompleteProfileScreen onClose={toggleCompleteModal} isVisible={showCompleteModal}/>
      <OnlyFriendsChatModal onClose={toggleOnlyFriendsChatModal} isVisible={showOnlyFriendsChatModal}/>
    </View>);
};
export default PublicProfileTopScreen;
