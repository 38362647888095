//FR afficher un bouton avec une icône et du texte, conçu pour être pressable
//EN "Display a button with an icon and text, designed to be pressable.
// Import modules
import React from "react";
import { View, Text, Platform, Pressable } from "react-native";
// styles selon la plateforme
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
// Logique de component commence ici -----------------------------------------------------
const SelectButtonWithIcon = ({ icon, text, onPress, selected = false }) => {
    return (<Pressable onPress={onPress} style={[styles.container, selected && styles.selectedContainer]}>
            <View style={styles.Vectorsection}>
                {icon}
            </View>
            <View style={styles.textSection}>
                <Text style={styles.text}>{text}</Text>
            </View>
        </Pressable>);
};
export default SelectButtonWithIcon;
