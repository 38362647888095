import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    //🇫🇷 Conteneur principal de la page, occupant tout l'espace disponible.
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
    //🇫🇷 Style pour le conteneur ScrollView, assurant que le fond reste blanc.
    scrollViewContainer: {
        backgroundColor: 'white',
    },
    //🇫🇷 Conteneur pour le texte, centré avec un padding pour éviter que le contenu touche les bords.
    textViewContainer: {
        alignItems: 'center',
        padding: 20,
    },
    //🇫🇷 Style pour le texte, centré avec une taille de police et des marges spécifiques.
    text: {
        textAlign: 'center',
        fontSize: 17,
        fontWeight: '400',
        marginBottom: '8%',
        marginTop: '3%',
        marginHorizontal: 10,
    },
    //🇫🇷 Conteneur pour les icônes, permettant un arrangement en ligne avec retour à la ligne si nécessaire.
    iconContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-evenly',
        marginBottom: 20,
    },
    //🇫🇷 Style pour chaque icône avec son sous-titre, alignés au centre et divisant l'écran en trois colonnes.
    iconWithCaption: {
        alignItems: 'center',
        marginBottom: 20,
        width: '33%',
    },
    //🇫🇷 Style appliqué directement aux icônes pour définir une taille fixe.
    Vectorstyle: {
        height: 50,
        width: 50,
    },
    //🇫🇷 Style pour le sous-titre de chaque icône, centré juste en dessous de l'icône.
    iconCaption: {
        marginTop: 8,
        textAlign: 'center',
        fontWeight: '900',
    },
    //🇫🇷 Style non utilisé dans l'exemple fourni, mais préparé pour les sous-titres généraux si nécessaire.
    subtitle: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    //🇫🇷 Style pour le texte dans `subtitle`, appliquant une taille de police, un poids et un alignement spécifiques.
    line: {
        fontSize: 20,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    //🇫🇷 Style pour l'espace autour de la section du numéro de téléphone.
    phoneNumber: {
        marginTop: 16,
    },
});
export default styles;
