import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    containerUp: {
        backgroundColor: '#D9D9D9',
        paddingHorizontal: 20,
        paddingVertical: 5,
    },
    titleText: {
        fontWeight: '700',
        fontSize: 16,
        lineHeight: 24,
        fontFamily: 'Poppins, sans-serif',
        textAlign: 'center'
    },
    containerDown: {
        flexDirection: 'row',
        justifyContent: 'center',
        gap: 10,
        paddingVertical: 5,
    },
    startTimeContainer: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
        height: 'auto',
        padding: 2,
        borderWidth: 2,
        borderColor: 'black',
        borderRadius: 5,
    },
    timeContainer: {
        width: 'auto',
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'black',
        paddingHorizontal: 2.5,
        paddingVertical: 1
    },
    timeText: {
        color: '#D9D9D9',
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '700',
        fontSize: 18,
    },
    dateContainer: {
        width: 'auto',
        height: 'auto',
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 4,
        paddingVertical: 1,
        borderWidth: 2,
        borderColor: 'black',
        borderRadius: 5,
        minWidth: '60%',
    },
    dateText: {
        fontFamily: 'Poppins, sans-serif',
        fontWeight: '600',
        fontSize: 16,
        lineHeight: 24,
    },
});
export default styles;
