// Fr  Styles de CreateActivityDescriptionScreen(FrameFigma037A)
// En  Styles of CreateActivityDescriptionScreen(FrameFigma037A)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    scrollContainer: {
        flex: 1,
        width: '90%',
    },
    titleText: {
        fontWeight: 'bold',
        alignItems: 'center',
        fontSize: 18,
        marginVertical: 20,
    },
    choosePhoto: {
        backgroundColor: '#DCF1EA',
        borderRadius: 15,
        width: '100%',
        height: 200,
        alignSelf: 'center',
        justifyContent: 'center',
    },
    btnChoose: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    cropBtn: {
        backgroundColor: 'black',
        width: '20%',
        height: 27,
        left: 55,
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        position: 'absolute',
        zIndex: 10,
    },
    cropBtnText: {
        color: 'white',
    },
    imageStyle: {
        width: '100%', // Utilisez '100%' pour que l'image prenne toute la largeur du conteneur parent
        height: 200, // Hauteur fixe ou ajustable selon vos besoins
        resizeMode: 'contain', // Utilisez 'cover' pour que l'image couvre toute la zone disponible
        alignSelf: 'center',
        backgroundColor: 'white',
    },
    spaceBottom: {
        marginBottom: 80,
    },
});
