// Fr Cette page affiche des details sur une activité pouvoir participer ou partager (FrameFigma041)
// En This page displays details about an activity able to participate or share it(FrameFigma041)
import React, { useState } from 'react';
import { View, Text, Platform, Pressable, ScrollView } from 'react-native';
import { useNavigation } from '@react-navigation/native';
// Import Services
// import { activityServices } from '../../../../_services/dataServices/activityServices';
// Import Contexts
// Import Components
import ActivityDetailsHeaderComponent from '../../../../../components/ActivitySection/ActivityDetailsHeaderComponent/Code';
import ActivityDetailsAdressMap from '../../../../../components/ActivitySection/ActivityDetailsAdressMapComponent/Code';
import ActivityDetailsAuthorComponent from '../../../../../components/ActivitySection/ActivityDetailsAuthorOrganiserComponent/Code';
import ActivityDetailsScrollBarParticipants from '../../../../../components/ActivitySection/ActivityDetailsScrollBarParticipantsComponent/Code';
import ActivityDetailsButtonTopComponent from '../../../../../components/ButtonSection/ActivityDetailsTopButton/Code';
import ActivityDetailsDescriptionComponent from '../../../../../components/ActivitySection/ActivityDetailsDescriptionComponent/Code';
import ActivityDetailsButtonsBottomComponent from '../../../../../components/ButtonSection/ActivityDetailsBottomButton/Code';
import ActivityDetailsAddressBannerComponent from '../../../../../components/BannerSection/ActivityDetailsAddressBanner/Code';
import MetroOrPostalDisplayComponent from '../../../../../components/ActivitySection/ActivityDetailsMetroOrPostalDisplayComponent/Code';
import { useSelector } from 'react-redux';
// import ActivityDetailsAttendeesContentComponent from '../../../../components/ActivityDetails/attendees/ActivityDetailsAttendeesContentComponent';
// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const ActivityDetailsAddressScreen = ({}) => {
    var _a;
    const navigation = useNavigation();
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    // const { activityView } = useActivity();
    const { activityView } = useSelector((state) => state.activities);
    // const { user } = useUser();
    const { user } = useSelector((state) => state.user);
    const [isScriptMapLoad, setIsScriptMapLoad] = useState(false);
    const cbInvite = () => {
        console.log('Invite');
    };
    const cbParticipate = () => {
        console.log('Invite');
    };
    const cbLeave = () => {
        console.log('Invite');
    };
    return (<>
            {activityView === undefined || activityView === null || !activityView ? (<View style={styles.container}>
                    <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityDeleted}</Text>
                    <Pressable onPress={() => navigation.navigate('Activities')}>
                        <Text>{translations.JsonActivityPages.JsonActivityScreen.JsonViewAllActivities}</Text>
                    </Pressable>
                </View>) : (<ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
                    <View style={styles.top}>
                        <ActivityDetailsHeaderComponent />
                        {/* Dimension de la Map dans Maps.js */}
                        <ActivityDetailsAdressMap activity={activityView} setIsScriptMapLoad={setIsScriptMapLoad}/>
                        <ActivityDetailsAddressBannerComponent address={activityView.location.address} postal={activityView.location.postalCode}/>
                        <ActivityDetailsAuthorComponent />
                        <ActivityDetailsScrollBarParticipants />
                        {!!activityView.metroStation || !!activityView.location.postalCode ?
                <MetroOrPostalDisplayComponent postal={(_a = activityView === null || activityView === void 0 ? void 0 : activityView.location) === null || _a === void 0 ? void 0 : _a.postalCode} metroStation={activityView === null || activityView === void 0 ? void 0 : activityView.metroStation}/>
                :
                    null}
                        <ActivityDetailsButtonTopComponent id={user._id} act={activityView} cbParticipate={cbParticipate} cbLeave={cbLeave} cbInvite={cbInvite}/>
                    </View>
                    <View style={styles.middle}>
                        <ActivityDetailsDescriptionComponent aboutTitle={'How find us :'} content={activityView.howToFind}/>
                    </View>
                    <View style={styles.bottom}>
                        {/* //TODO: Remplacer l'id par celui de l'utilisateur connecté */}
                        <ActivityDetailsButtonsBottomComponent id={user._id} /*style={styles.containerButtonBottom}*//>
                    </View>
                </ScrollView>)}
        </>);
};
export default ActivityDetailsAddressScreen;
