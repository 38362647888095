// Import Modules
import React from 'react';
import { Platform, Pressable, Text, View } from 'react-native';
// Import Contexts
// Import Vectors
import { Vectors } from "../../../../constants/Vectors";
import { useSelector } from 'react-redux';
// Import Styles
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
// Logique de component commence ici --------------------------------------------------
const Step2ConfirmationScreen = ({ verificationSuccess, onClose }) => {
    // Contexts
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const t = translations.JsonConfirmFriendshipPopup;
    return (<View style={styles.container}>
            {verificationSuccess ? (<View style={styles.innerContainer}>
                    <Vectors.Checkbox width={40} height={40}/>
                    <Text>{t.JsonPopupCongratulations}</Text>
                    <Text>{t.JsonPopupYouAreNowFriend}</Text>
                </View>) : (<View style={styles.innerContainer}>
                    <Vectors.CloseLargeRedSign width={40} height={40}/>
                    <Text>{t.JsonPopupEmailErrorNotARealFriend}</Text>
                </View>)}
            <View>
                <Pressable onPress={onClose}>
                    <Text>{t.JsonButtonClose}</Text>
                </Pressable>
            </View>
        </View>);
};
export default Step2ConfirmationScreen;
