export default function OrderChatsByMostRecent(chats) {
    return chats.sort((a, b) => {
        try {
            const aTimestamp = a.messages[a.messages.length - 1].timestamp;
            const bTimestamp = b.messages[b.messages.length - 1].timestamp;
            return new Date(bTimestamp).getTime() - new Date(aTimestamp).getTime();
        }
        catch (error) {
            return new Date(b.messages[b.messages.length - 1].timestamp).getTime();
        }
    });
}
