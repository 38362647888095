// Fr  Styles de EditActivityManageParticipationScreen(FrameFigma035A,FrameFigma035B)
// En  Styles of EditActivityManageParticipationScreen(FrameFigma035A,FrameFigma035B) 
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    scrollContainer: {
        width: 400,
    },
    textTop: {
        fontSize: 13,
        marginTop: -20,
    },
    contentContainer: {
        marginVertical: 20,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    sliderContainer: {
        marginVertical: 20,
        width: '100%',
    },
    textbtn: {
        fontSize: 18,
        alignSelf: 'flex-start',
        fontWeight: 'bold',
    },
    inputContainer: {
        paddingHorizontal: 8,
        justifyContent: 'center',
        fontSize: 16,
        borderWidth: 1,
        marginVertical: 20,
        borderRadius: 15,
        padding: 20,
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
