import { Platform, Text, View } from 'react-native';
import React, { useEffect, useState, useMemo } from 'react';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import Vectors from '../../../../constants/Vectors';
import { useError } from '../../../../contexts/ErrorContext';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const DuplicateActivityAmbassadorFeaturesScreen = ({ onNextStep, onPrevStep, setHeaderTitle }) => {
    const { translations } = useSelector((state) => state.language);
    const { error, setError } = useError();
    const [links, setLinks] = useState({
        whatsappGroupLink: '',
        facebookGroupLink: '',
        facebookLink: '',
        meetupLink: '',
        telegramLink: '',
        blogLink: '',
    });
    const urlRegex = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/;
    useEffect(() => {
        setHeaderTitle('Ambassador features');
    }, [setHeaderTitle]);
    const handleTextChange = (name, value) => {
        setLinks(prevLinks => (Object.assign(Object.assign({}, prevLinks), { [name]: value })));
    };
    const inputData = useMemo(() => [
        {
            icon: Vectors.Whatsapp,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonWhatsapp,
            value: links.whatsappGroupLink,
            handleTextChange: (value) => handleTextChange('whatsappGroupLink', value),
            errorInput: links.whatsappGroupLink !== '' && !urlRegex.test(links.whatsappGroupLink),
        },
        {
            icon: Vectors.SocialNetworksFbGroup,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonFbGroup,
            value: links.facebookGroupLink,
            handleTextChange: (value) => handleTextChange('facebookGroupLink', value),
            errorInput: links.facebookGroupLink !== '' && !urlRegex.test(links.facebookGroupLink),
        },
        {
            icon: Vectors.SocialNetworksFbPage,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonFbPage,
            value: links.facebookLink,
            handleTextChange: (value) => handleTextChange('facebookLink', value),
            errorInput: links.facebookLink !== '' && !urlRegex.test(links.facebookLink),
        },
        {
            icon: Vectors.SocialNetworksMeetup,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonMeetup,
            value: links.meetupLink,
            handleTextChange: (value) => handleTextChange('meetupLink', value),
            errorInput: links.meetupLink !== '' && !urlRegex.test(links.meetupLink),
        },
        {
            icon: Vectors.SocialNetworksTelegram,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonTelegram,
            value: links.telegramLink,
            handleTextChange: (value) => handleTextChange('telegramLink', value),
            errorInput: links.telegramLink !== '' && !urlRegex.test(links.telegramLink),
        },
        {
            icon: Vectors.SocialNetworksOtherLink,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonOtherLink,
            value: links.blogLink,
            handleTextChange: (value) => handleTextChange('blogLink', value),
            errorInput: links.blogLink !== '' && !urlRegex.test(links.blogLink),
        },
    ], [links, translations]);
    const handleNextStep = () => {
        const hasError = inputData.some(input => input.errorInput);
        const errorMessage = hasError
            ? translations.JsonActivityPages.JsonActivityManagement.JsonStandardActivityChooseAddressScreen.JsonOnlineErrorMessage
            : '';
        setError(errorMessage);
        if (!hasError) {
            onNextStep();
        }
    };
    useEffect(() => {
        setError('');
    }, [links, setError]);
    return (<View>
        {error && (<View style={styles.headerError}>
              <Text style={styles.headerErrorText}>{error}</Text>
            </View>)}
        <View style={styles.container}>
          <Text style={styles.textDescription}>
            {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonOptionalFeatures}
          </Text>
          <View style={styles.inputContainer}>
            {inputData.map((input, i) => (<TextInputCustomComponent Icon={input.icon} placeholderText={input.placeholderText} isPlaceHolderTextAlignCenter value={input.value} handleTextChange={input.handleTextChange} key={i} errorInput={input.errorInput}/>))}
          </View>
          <View style={styles.btnContainer}>
            <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
            <ButtonNext handleNextStep={handleNextStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton} conditionNeed={Object.values(links).some(link => link !== '')}/>
          </View>
        </View>
      </View>);
};
export default DuplicateActivityAmbassadorFeaturesScreen;
