var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Image, Pressable, Text, View } from 'react-native';
import ImagePicker from 'react-native-image-crop-picker';
import { Vectors } from '../../../constants/Vectors';
import styles from './StylesMobile';
const ImageProfileCropMobile = ({ uri, handleCroppedImage }) => {
    const [image, setImage] = useState(null);
    const [imagePath, setImagePath] = useState(uri);
    useEffect(() => {
        cropImage();
        return () => {
        };
    }, []);
    const openImagePicker = () => {
        ImagePicker.openPicker({
            width: 300,
            height: 300,
            cropping: true,
            mediaType: 'photo',
        })
            .then(image => {
            setImage(image);
            setImagePath(image.path);
        })
            .catch(error => {
            console.log('User cancelled image picker', error);
        });
    };
    const cropImage = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const image = yield ImagePicker.openCropper({
                mediaType: 'photo',
                path: imagePath,
                includeBase64: true,
                width: 300,
                height: 300,
            });
            setImage(image);
            setImagePath(image.path);
        }
        catch (error) {
            console.error('Error picking the image: ', error);
        }
    });
    const saveImage = () => {
        const croppedImage = {
            file: new File([image.path], image.path.split('/').pop() || '', { type: image.mime, lastModified: Date.now() }),
            imageUrl: image.path,
        };
        handleCroppedImage(croppedImage);
    };
    return (<View style={styles.container}>
            <Pressable style={styles.choosePhoto} onPress={openImagePicker}>
                <View style={styles.btnChoose}>
                    <View style={styles.field}>
                        <Vectors.IconUpload />
                    </View>
                    <Text style={styles.btnChooseText}>Choose Another Image</Text>
                </View>
            </Pressable>
            <Image source={{ uri: imagePath }} style={styles.imagePreview}/>
            {image && <Pressable onPress={saveImage} style={styles.saveButton}>
                    <Vectors.CheckSignGeneralColor width={40} height={40}/>
                </Pressable>}
        </View>);
};
export default ImageProfileCropMobile;
