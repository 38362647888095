import { useNavigation } from '@react-navigation/native';
import React from 'react';
import { Image, Linking, SafeAreaView, ScrollView, Text, Pressable, View, Platform, TouchableOpacity } from 'react-native';
import { Vectors } from '../../../constants/Vectors';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector } from 'react-redux';
import EditProfileScreen from '../../ProfileModule/EditProfileSection/EditProfileNavigator';
import SettingsAnimatedGif from '../../../assets/Images/General/SettingsAnimated.gif'; // Importation correcte de l'image
import ContactUsScreen from '../../ContactUsScreen/Code';
import DeleteAccountStep1 from '../../DeleteAccountSection/DeleteAccountScreenStep1/Code';
import DeleteAccountStep2 from '../../DeleteAccountSection/DeleteAccountScreenStep2/Code';
import TermsAndConditionsScreen from '../../TermsAndConditionsScreen/Code';
import Rewards from '../../AchievementModule/RewardScreen/Code';
import NewsScreen from '../../NewsScreen/Code';
import { default as NotificationNavigator } from '../../NotificationsSection/NotificationsNavigator';
import InProcess from '../../InProcess/Code';
import CupsAndAwards from '../../AchievementModule/CupsAndAwardsScreen/Code';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const SettingsScreen = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const navigation = useNavigation();
    const optionsData = [
        {
            title: translations.JsonSettingsScreen.JsonBlockedUsers,
            screen: 'BlockedUsers',
            icon: <Vectors.SettingsBlockedUser style={styles.SettingsIcon}/>,
            textStyle: styles.containerTxt,
        },
        {
            title: translations.JsonSettingsScreen.JsonLikedUsers,
            screen: 'LikedUsers',
            icon: <Vectors.SettingsLikedUsers style={styles.SettingsIcon}/>,
            textStyle: styles.containerTxt,
        },
        {
            title: translations.JsonSettingsScreen.JsonEditProfile,
            screen: 'EditProfile',
            icon: <Vectors.SettingsEditProfile style={styles.SettingsIcon}/>,
            textStyle: styles.containerTxt,
        },
        {
            title: translations.JsonSettingsScreen.JsonMedals,
            screen: 'CupsAndAwards',
            icon: <Vectors.Level4GoldStar style={styles.SettingsIcon}/>,
            textStyle: styles.containerTxt,
        },
        {
            title: translations.JsonSettingsScreen.JsonSponsorship,
            screen: 'Rewards',
            icon: <Vectors.Gift style={[{ width: 90 }, { height: 90 }, { marginTop: '-10%' }]}/>,
            textStyle: [styles.containerTxt, { marginTop: '-10%' }],
        },
        {
            title: translations.JsonSettingsScreen.JsonNotifications,
            screen: 'Notifications',
            icon: <Vectors.NotificationsBellOn style={[{ width: 64 }, { height: 64 }]}/>,
            textStyle: styles.containerTxt,
        },
        {
            title: translations.JsonSettingsScreen.JsonContactUs,
            icon: <Vectors.SettingsContactUs style={styles.SettingsIcon}/>,
            screen: 'ContactUs',
            textStyle: styles.containerTxt,
        },
        {
            title: translations.JsonSettingsScreen.JsonNews,
            icon: <Vectors.SettingsNews style={styles.SettingsIcon}/>,
            screen: 'News',
            textStyle: styles.containerTxt,
        },
        {
            title: translations.JsonSettingsScreen.JsonDeleteMyAccount,
            icon: <Vectors.SettingsDeleteAccount style={styles.SettingsIcon}/>,
            screen: 'DeleteAccount',
            textStyle: styles.containerTxt,
        },
        {
            title: translations.JsonSettingsScreen.JsonTermsOfSales,
            screen: 'TermsAndConditions',
            icon: <Vectors.SettingsTermsOfSales style={styles.SettingsIcon}/>,
            textStyle: styles.containerTxt,
        },
    ];
    const navigateToScreen = (screenName) => {
        navigation.navigate(screenName);
    };
    const navigateToExternalLink = (url) => {
        Linking.openURL(url);
    };
    return (<SafeAreaView style={styles.container}>
      <ScrollView style={{ backgroundColor: 'white' }} showsVerticalScrollIndicator={false}>
        <View style={styles.logoContainer}>
          <Image source={SettingsAnimatedGif} style={styles.logo}/>
        </View>
        <View style={styles.socialContainer}>
          {optionsData.map((option, index) => (<React.Fragment key={index}>
              {index % 2 === 0 && (<View style={styles.row}>
                  <Pressable style={styles.socialWrapper} onPress={(() => navigateToScreen(option.screen))}>
                    <View style={styles.social}>
                      {option.icon}
                      <Text style={option.textStyle}>{option.title}</Text>
                    </View>
                  </Pressable>
                  {optionsData[index + 1] && (<Pressable style={styles.socialWrapper} onPress={(() => navigateToScreen(optionsData[index + 1].screen))}>
                      <View style={styles.social}>
                        {optionsData[index + 1].icon}
                        <Text style={optionsData[index + 1].textStyle}>{optionsData[index + 1].title}</Text>
                      </View>
                    </Pressable>)}
                </View>)}
            </React.Fragment>))}
          <View style={styles.row}>
            <Pressable style={styles.socialWrapper} onPress={() => Linking.openURL(process.env.PRIVACY_POLICY_LINK)}>
              <View style={styles.social}>
                <Vectors.SettingsLegalNotice style={styles.SettingsIcon}/>
                <Text style={styles.containerTxt}>{translations.JsonSettingsScreen.JsonLegalNotice}</Text>
              </View>
            </Pressable>
            <Pressable style={styles.socialWrapper} onPress={() => Linking.openURL(process.env.PRIVACY_POLICY_LINK)}>
              <View style={styles.social}>
                <Vectors.SettingsPrivacyPolicy style={styles.SettingsIcon}/>
                <Text style={styles.containerTxt}>{translations.JsonSettingsScreen.JsonPrivacyPolicy}</Text>
              </View>
            </Pressable>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>);
};
const headerLeftArrow = () => {
    const navigation = useNavigation();
    return (<TouchableOpacity onPress={() => navigation.goBack()}>
    <View style={{ marginLeft: 15 }}>
      <Vectors.LeftArrow />
    </View>
  </TouchableOpacity>);
};
const Stack = createStackNavigator();
export const SettingsStack = () => {
    const defaultScreenOptions = {
        headerShown: true,
        headerStyle: { backgroundColor: '#59C09B' },
        headerTintColor: 'white',
        headerTitleStyle: { fontWeight: 'bold', fontSize: 20 },
        headerTitleAlign: "center",
    };
    return (<Stack.Navigator>
      <Stack.Screen component={SettingsScreen} name="setttings" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={EditProfileScreen} name="EditProfile" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={CupsAndAwards} name="CupsAndAwards" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={Rewards} name="Rewards" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={ContactUsScreen} name="ContactUs" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={DeleteAccountStep1} name="DeleteAccount" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={DeleteAccountStep2} name="DeleteAccountStep2" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={NewsScreen} name="News" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={NotificationNavigator} name="Notifications" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={InProcess} name="BlockedUsers" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={InProcess} name="LikedUsers" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={TermsAndConditionsScreen} name="TermsAndConditions" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
    </Stack.Navigator>);
};
export default SettingsScreen;
