var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// 🇫🇷 Ce fichier gère la page qui vérifie le code de vérification envoyé à l'adresse e-mail spécifiée non présente dans la base de donéee. (FrameFigma007A FrameFigma007B FrameFigma007C)
// 🇬🇧 This file manages the page that checks the verification code sent to the specified email address. (FrameFigma007A FrameFigma007B FrameFigma007C)
import { View, Text, Platform, TextInput, TouchableOpacity, Pressable } from 'react-native';
import React, { useRef, useState, useEffect } from 'react';
import { hostname } from '../../../../../hostName/hostName';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Yup from 'yup';
import Clipboard from '@react-native-clipboard/clipboard';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import { useError } from '../../../../contexts/ErrorContext';
import Vectors from '../../../../constants/Vectors';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const VerifyEmailScreen = ({ navigation }) => {
    const { translations, language } = useSelector((state) => state.language);
    const { error, setError } = useError();
    // Refs for the pin input fields
    const pinRefs = useRef(Array(6).fill(null).map(() => React.createRef()));
    const [pins, setPins] = useState(Array(6).fill(''));
    const [email, setEmail] = useState('');
    const [resendDisabled, setResendDisabled] = useState(false);
    const [countdown, setCountdown] = useState(180);
    // Fetch email from storage when the component mounts
    useEffect(() => {
        getEmailFromStorage();
    }, []);
    // Countdown timer for resend button
    useEffect(() => {
        const timer = setInterval(() => {
            setCountdown(prevCountdown => {
                if (prevCountdown === 0) {
                    clearInterval(timer);
                    setResendDisabled(false);
                }
                return prevCountdown === 0 ? 0 : prevCountdown - 1;
            });
        }, 1000);
        return () => clearInterval(timer);
    }, [countdown]);
    /**
     * 🇬🇧 Validation schema for verification code using Yup.
     * 🇫🇷 Schéma de validation pour le code de vérification utilisant Yup.
     */
    const verificationSchema = Yup.object().shape({
        verificationCode: Yup.string()
            .matches(/^[0-9]+$/, translations.JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonPhoneNotValid)
            .length(6, translations.JsonLoginRegisterPages.JsonVerificationCode.JsonShortMessage)
            .required(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonEnterVerificationCode),
    });
    /**
     * 🇬🇧 Retrieve email from AsyncStorage and set it in the state.
     * 🇫🇷 Récupérer l'e-mail depuis AsyncStorage et le définir dans l'état.
     */
    const getEmailFromStorage = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const storedEmail = yield AsyncStorage.getItem('email');
            if (storedEmail) {
                setEmail(storedEmail);
            }
        }
        catch (error) {
            console.error("Erreur lors de la récupération de l'e-mail depuis le AsyncStorage", error);
        }
    });
    /**
     * 🇬🇧 Format countdown timer as MM:SS.
     * 🇫🇷 Formater le minuteur de compte à rebours en MM:SS.
     */
    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };
    /**
     * 🇬🇧 Handle verification code submission and user login process.
     * 🇫🇷 Gérer la soumission du code de vérification et le processus de connexion de l'utilisateur.
     */
    const handleVerification = () => __awaiter(void 0, void 0, void 0, function* () {
        const verificationCode = pins.join('');
        if (!verificationCode) {
            setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonEnterVerificationCode);
            return;
        }
        try {
            yield verificationSchema.validate({ verificationCode });
            const response = yield fetch(`${hostname}/verifyEmail`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ verificationCode }),
            });
            if (response.ok) {
                const responseData = yield response.json();
                if (responseData.isVerified) {
                    // Si l'e-mail est vérifié, rediriger vers la page de profil existant
                    navigation.navigate('Home');
                }
                else {
                    // Si l'e-mail n'est pas vérifié, rediriger vers la création de profil
                    navigation.navigate('CreateProfile');
                }
                // Enregistrer l'état de vérification de l'e-mail dans AsyncStorage
                yield AsyncStorage.setItem('isVerified', responseData.isVerified ? 'true' : 'false');
                setError('');
            }
            else {
                setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonCodeError);
            }
        }
        catch (error) {
            setError(error.message);
        }
    });
    /**
     * 🇬🇧 Send verification email and start countdown timer.
     * 🇫🇷 Envoyer un e-mail de vérification et démarrer le minuteur de compte à rebours.
     */
    const sendVerificationEmail = () => __awaiter(void 0, void 0, void 0, function* () {
        setResendDisabled(true);
        setCountdown(180);
        try {
            yield fetch(`${hostname}/sendVerification`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ email }),
            });
        }
        catch (error) {
            console.error("Erreur lors de l'envoi de la demande de vérification par e-mail", error);
        }
    });
    /**
     * 🇬🇧 Handle paste event for PIN input fields, only allow numeric values.
     * 🇫🇷 Gérer l'événement de collage pour les champs de saisie PIN, autoriser uniquement les valeurs numériques.
     */
    const handlePasteText = () => __awaiter(void 0, void 0, void 0, function* () {
        const text = yield Clipboard.getString();
        const filteredText = text.replace(/[^\d]/g, '');
        const textArray = filteredText.split('').slice(0, 6);
        if (text !== filteredText) {
            setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonCopyPaste);
        }
        else {
            setError('');
        }
        setPins(pins.map((pin, index) => textArray[index] || pin));
    });
    /**
     * 🇬🇧 Update the value of a specific PIN input field and handle focus.
     * 🇫🇷 Mettre à jour la valeur d'un champ de saisie PIN spécifique et gérer le focus.
     */
    const updatePin = (index, value) => {
        if (/^\d*$/.test(value)) {
            const newPins = [...pins];
            newPins[index] = value;
            setPins(newPins);
            setError('');
            if (value && index < 5) {
                pinRefs.current[index + 1].current.focus();
            }
            else if (!value && index > 0) {
                pinRefs.current[index - 1].current.focus();
            }
        }
        else {
            setError(translations.JsonLoginRegisterPages.JsonVerificationCode.JsonOnlyNumbersAllowed);
        }
    };
    /**
     * 🇬🇧 Handle text change in the PIN input field.
     * 🇫🇷 Gérer le changement de texte dans le champ de saisie PIN.
     */
    const handleTextChange = (index, value) => {
        updatePin(index, value);
        if (error) {
            setError('');
        }
    };
    return (<View key={language} style={styles.verification}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <View style={styles.container}>
        <Text style={styles.verificationText}>{translations.JsonLoginRegisterPages.JsonVerificationCode.JsonEnterVerificationCode}</Text>
        <TouchableOpacity style={styles.pasteContainer} onPress={handlePasteText}>
          <Vectors.Paste style={styles.paste}/>
        </TouchableOpacity>
        <View style={styles.inputs}>
          {pins.map((pin, index) => (<TextInput key={index} ref={pinRefs.current[index]} style={[styles.opt, error && styles.optError]} // Ajout du style conditionnel
         keyboardType={'number-pad'} maxLength={1} value={pin} onChangeText={value => handleTextChange(index, value)}/>))}
        </View>
        <View style={styles.btnContainer}>
          <ButtonPrevious onPrevStep={() => navigation.navigate('NewUserPassword')} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
          <ButtonNext handleNextStep={handleVerification} btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton} conditionNeed={pins.join('').length === 6}/>
        </View>
        <View style={styles.resend}>
          <Text style={styles.resendText}>{translations.JsonLoginRegisterPages.JsonVerificationCode.JsonCodeNotReceved}</Text>
        </View>
        <View style={styles.btnResendRow}>
            <Pressable onPress={sendVerificationEmail} disabled={resendDisabled} style={styles.btnResendContainer}>
              <Text style={[styles.btnTextResend, resendDisabled && styles.btnTextDisabled]}>
                {resendDisabled ? formatTime(countdown) : 'Resend to email'}
              </Text>
            </Pressable>
            <TouchableOpacity style={styles.btnResendContainer} onPress={() => navigation.navigate('PhoneExistingUserScreen')}>
              <Text style={styles.btnTextResend}>{'Resend to mobile'}</Text>
            </TouchableOpacity>
          </View>
      </View>
    </View>);
};
export default VerifyEmailScreen;
