var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// AppWeb.js
import React, { useState, useEffect } from 'react';
import { View, TouchableOpacity, ActivityIndicator } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { hostname } from '../hostName/hostName';
// Importations des composants/pages
import HomeScreen from './pages/AppStartSection/HomeScreen/Code';
import LanguagesScreen from './pages/AppStartSection/LanguagesScreen/Code';
import EmailScreen from './pages/LoginRegisterSection/EmailScreen/Code';
import ExistingUserPasswordScreen from './pages/LoginRegisterSection/PasswordScreen/ExistingUserPasswordScreen/Code';
import NewUserPasswordScreen from './pages/LoginRegisterSection/PasswordScreen/NewUserPasswordScreen/Code';
import Sponsorship from './pages/LoginRegisterSection/Sponsorship/Code';
import ResetPasswordScreen from './pages/LoginRegisterSection/PasswordScreen/ResetPasswordScreen/Code';
import VerifyEmailScreen from './pages/LoginRegisterSection/VerifyEmail/VerifyEmailNewUserScreen/Code';
import VerifyEmailExistingUserScreen from './pages/LoginRegisterSection/VerifyEmail/VerifyEmailExistingUserScreen/Code';
import StartScreen from './pages/AppStartSection/StartScreen/Code';
import PhoneExistingUserScreen from './pages/LoginRegisterSection/PhoneScreen/PhoneUserScreen/Code';
import VerifyPhoneExistingUserScreen from './pages/LoginRegisterSection/VerifyPhoneScreen/VerifyPhoneUserScreen/Code';
import CreateProfileScreen from './pages/ProfileModule/CreateProfileSection/CreateProfileNavigator';
// Composants spécifiques à l'utilisateur connecté
import BottomNav from './components/NavBarSection/BottomNav/BottomGreenNav/Code';
import DrawerWeb from './components/DrawerNavigator/DrawerWeb/Code';
// Importe les screen du drawer
import MyProfileScreen from './pages/ProfileModule/MyProfileSection/MyProfileParents';
//import MyScreenParents from './src/pages/ProfileModule/MyScreen/MyProfileParents';
import ProfileScreenParents from './pages/ProfileModule/PublicProfileSection/PublicProfileParents';
import EditProfileScreen from './pages/ProfileModule/EditProfileSection/EditProfileNavigator';
import InviteFriendsScreen from './pages/InviteFriendsScreen/Code';
import ContactUsScreen from './pages/ContactUsScreen/Code';
import SettingsScreen from './pages/SettingsSection/SettingsScreen/Code';
import NewsScreen from './pages/NewsScreen/Code';
import UpdateScreen from './pages/UpdateScreen/Code';
import TermsAndConditionsScreen from './pages/TermsAndConditionsScreen/Code';
import LogoutButton from './components/ButtonSection/LogoutButton/Code';
import { WebDrawerProvider } from './components/DrawerNavigator/DrawerWeb/WebDrawerContext';
// import VectorsOnOff from './src/components/VectorsOnOff/VectorsOnOff';
import { Vectors } from './constants/Vectors';
import DeleteAccountStep1 from './pages/DeleteAccountSection/DeleteAccountScreenStep1/Code';
import DeleteAccountStep2 from './pages/DeleteAccountSection/DeleteAccountScreenStep2/Code';
import StepOneScreen from './pages/ProfileModule/CreateProfileSection/CreateProfileStep1Screen/Code';
import StepTwoScreen from './pages/ProfileModule/CreateProfileSection/CreateProfileStep2Screen/Code';
import Step3PersonalScreen from './pages/ProfileModule/CreateProfileSection/CreateProfileStep3PersoScreen/Code';
import Step3ProScreen from './pages/ProfileModule/CreateProfileSection/CreateProfileStep3ProScreen/Code';
import Step4Screen from './pages/ProfileModule/CreateProfileSection/CreateProfileStep4Screen/Code';
import MessageChatScreen from './pages/MessagesScreen/Code';
// Import screen invite friends
import InviteByEmail from './pages/InviteFriendsScreen/SocialInvite/InviteByEmail';
import InviteByLink from './pages/InviteFriendsScreen/SocialInvite/InviteByLink';
import InviteByMessenger from './pages/InviteFriendsScreen/SocialInvite/InviteByMessenger';
import InviteBySMS from './pages/InviteFriendsScreen/SocialInvite/InviteBySMS';
import InviteBySnapchat from './pages/InviteFriendsScreen/SocialInvite/InviteBySnapchat';
// Import context
import { useError } from './contexts/ErrorContext';
import { useActivityModal } from './contexts/ActivityModalContext';
// Ecrans pour Module Activities
import ActivityDetailsTabNavigator from './pages/ActivityModule/ActivityDetailsSection/ActivityDetailsNavigator/Code';
// import ActivityScreen from './src/pages/ActivityModule/DetailsSectionToDelete/ActivityDetailsNavigator';
import CreateActivityScreen from './pages/ActivityModule/CreateActivitySection/Code';
import CopyActivityScreen from './pages/ActivityModule/DuplicateActivitySection/Code';
import EditActivityScreen from './pages/ActivityModule/EditActivitySection/Code';
import { useSelector } from 'react-redux';
import CupsAndAwards from './pages/AchievementModule/CupsAndAwardsScreen/Code';
import VipBadges from './pages/AchievementModule/VipBadgesSection/VipBadgeExplanationSelector/Code';
import VipBadgeGold from './pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesGoldScreen/Code';
import VipBadgeRuby from './pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesRubyScreen/Code';
import VipBadgeEmerald from './pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesEmeraldScreen/Code';
import VipBadgeDiamand from './pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesDiamondScreen/Code';
import VipBoardExplanation from './pages/AchievementModule/VipBoardExplanationScreen/Code';
import Rewards from './pages/AchievementModule/RewardScreen/Code';
import ConceptSlidesScreen from './pages/ConceptSlidesScreen/Code';
import { default as NotificationNavigator } from './pages/NotificationsSection/NotificationsNavigator';
import Drink from './pages/ActivityModule/ActivityDetailsSection/ActivityDetailsScreens/ActivityDetailsFreeShooterScreen/Code';
import { setToken } from './services/Axios/AxiosService';
import InProcess from './pages/InProcess/Code';
const headerLeftArrow = (navigation) => (<TouchableOpacity onPress={() => navigation.goBack()}>
    <View style={{ marginLeft: 15 }}>
      <Vectors.LeftArrow />
    </View>
  </TouchableOpacity>);
const headerRightMsg = (openActivityChatModal, setOpenActivityChatModal) => (<>
    {!!openActivityChatModal ?
        <View style={{ marginRight: 15, width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}>
        <Vectors.NavbarChatColors style={{ width: 50, height: 50 }}/>
      </View>
        :
            <TouchableOpacity onPress={() => setOpenActivityChatModal(!openActivityChatModal)}>
        <View style={{ marginRight: 15, width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}>
          <Vectors.NavbarBlackAndWhiteChat style={{ width: 50, height: 50 }}/>
        </View>
      </TouchableOpacity>}
  </>);
const headerExit = (navigation, targetScreen) => (<TouchableOpacity onPress={() => navigation.navigate(targetScreen)}>
    <View style={{ marginLeft: 15 }}>
      <Vectors.Exit />
    </View>
  </TouchableOpacity>);
const LoggedInScreens = () => {
    return (<>
      <DrawerWeb />
      <View style={{ flex: 1 }}>
        <BottomNav />
      </View>
    </>);
};
const AppContent = () => {
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { isLoggedIn, userToken, userId } = useSelector((state) => state.auth);
    const { error, clearError } = useError(); // Utiliser le contexte d'erreurs
    const navigationRef = useNavigationContainerRef();
    const [isNavigationReady, setIsNavigationReady] = useState(false);
    useEffect(() => {
        let isSubscribed = true; // Flag pour vérifier le montage du composant
        const saveCurrentRoute = () => {
            const currentRoute = navigationRef.getCurrentRoute();
            if (currentRoute && currentRoute.name !== 'Activities' && isSubscribed) {
                const routeParams = currentRoute.name === 'Activity' /* currentRoute.name === 'Description' || currentRoute.name === 'Address' || currentRoute.name === 'Online' || currentRoute.name === 'Participants' */ ? JSON.stringify(currentRoute.params) : null;
                const routeName = currentRoute.name === 'MyProfileScreen' || currentRoute.name === 'ProfileScreen' || currentRoute.name === 'EditActivity' /* || currentRoute.name === 'Description' || currentRoute.name === 'Address' || currentRoute.name === 'Participants'|| currentRoute.name === 'Online' */
                    ? 'Activities'
                    : currentRoute.name;
                const routeState = JSON.stringify({ routeName, routeParams });
                AsyncStorage.setItem('lastRouteState', routeState);
            }
        };
        if (isNavigationReady) {
            saveCurrentRoute();
        }
        const unsubscribeState = navigationRef.addListener('state', () => {
            if (isSubscribed) {
                saveCurrentRoute();
            }
        });
        return () => {
            isSubscribed = false;
            unsubscribeState();
        };
    }, [navigationRef, isNavigationReady]);
    useEffect(() => {
        const restoreLastRoute = () => __awaiter(void 0, void 0, void 0, function* () {
            const lastRouteState = yield AsyncStorage.getItem('lastRouteState');
            if (lastRouteState && isNavigationReady) {
                const { routeName, routeParams } = JSON.parse(lastRouteState);
                let params = null;
                if (routeParams) {
                    params = JSON.parse(routeParams);
                }
                navigationRef.navigate(routeName, params);
            }
        });
        if (isNavigationReady) {
            restoreLastRoute();
        }
        if (isLoggedIn)
            setToken(userToken);
    }, [isNavigationReady, navigationRef]);
    const getHeaderTitle = (routeName) => {
        switch (routeName) {
            case 'Email':
                return translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonEmail;
            case 'Password':
                return translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonHeaderTitlePassword;
            case 'ResetPassword':
                return translations.JsonLoginRegisterPages.JsonEmailScreen.JsonHeaderTitleResetPassword;
            case 'Sponsorship':
                return translations.JsonAchievementsPages.JsonTitleSponsorship;
            case 'NewUserPassword':
                return translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonHeaderTitlePassword;
            case 'VerifyEmail':
                return translations.JsonLoginRegisterPages.JsonVerificationCode.JsonTitleVerification;
            case 'VerifyEmailExistingUserScreen':
                return translations.JsonLoginRegisterPages.JsonVerificationCode.JsonTitleVerification;
            case 'CreateActivity':
                return translations.JsonActivityPages.JsonActivityManagement.JsonTitleCreateActivity;
            case 'EditActivity':
                return translations.JsonActivityPages.JsonActivityManagement.JsonTitleModifyActivity;
            case 'CreateProfile':
                return translations.JsonCreateProfilePages.JsonTitleCreateProfile;
            case 'EditProfile':
                return translations.JsonProfilePages.JsonTitleEditProfile;
            case 'CopyActivity':
                return translations.JsonActivityPages.JsonActivityManagement.JsonTitleCopyActivity;
            case 'PhoneExistingUserScreen':
                return 'PhoneExistingUserScreen';
            case 'VerifyPhoneExistingUserScreen':
                return 'VerifyPhoneExistingUserScreen';
            default:
                return '';
        }
    };
    const defaultScreenOptions = {
        headerShown: true,
        headerStyle: { backgroundColor: '#59C09B' },
        headerTintColor: 'white',
        headerTitleStyle: { fontWeight: 'bold', fontSize: 20 },
        headerTitleAlign: 'center',
    };
    // Permet de gerer les msg d'erreurs
    const conditionalScreenOptions = ({ navigation, route }, targetScreen) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerShown: !error, headerStyle: {
            backgroundColor: error ? 'red' : '#59C09B',
        }, headerTitle: error ? error : getHeaderTitle(route.name), headerLeft: () => headerExit(navigation, targetScreen) }));
    const linking = {
        prefixes: [`http://${hostname}:8080`],
        config: {
            screens: {
                Home: 'home',
                Start: 'start',
                Languages: 'languages',
                Email: 'email',
                Password: 'password',
                NewUserPassword: 'new-user-password',
                ResetPassword: 'reset',
                VerifyEmail: 'verify-email',
                CreateProfile: {
                    path: 'CreateProfile',
                    screens: {
                        StepOneScreen: 'StepOne',
                        StepTwoScreen: 'SetTwo',
                        Step3PersonalScreen: 'SetthreePersonal',
                        Step3ProScreen: 'StepThreePro',
                        Step4Screen: 'StepFour',
                    },
                },
                LoggedIn: {
                    screens: {
                        Activities: 'activities',
                        MembersNavigator: 'MembersNavigator',
                        ConceptSlides: 'concept',
                        News: 'news',
                        ContactUs: 'contact',
                        Activity: {
                            path: 'Activity/**',
                            // path: 'Activity/Description' ,
                            // path: 'Activity/Address',
                            // path: 'Activity/Participants',
                            // path : 'Activity/Online',
                            // path: 'Activity/Boubou',
                            // path: 'Activity/:activityId',
                            // parse: {
                            //   activityId: activityId => `${activityId}`,
                            // },
                        },
                        MyProfileScreen: {
                            path: 'MyProfileScreen/:userId',
                            parse: {
                                userId: (userId) => `${userId}`,
                            },
                        },
                        ProfileScreen: {
                            path: 'ProfileScreen/:userId',
                            parse: {
                                userId: (userId) => `${userId}`,
                            },
                        },
                        EditProfile: {
                            path: 'EditProfile/**',
                        },
                        CopyActivity: {
                            path: 'CopyActivity/:activityId',
                            parse: {
                                activityId: (activityId) => `${activityId}`,
                            },
                        },
                    },
                },
            },
        },
    };
    const Stack = createStackNavigator();
    // const { author } = useActivity();
    const { author } = useSelector((state) => state.activities);
    const { activityModalOpen, setActivityModalOpen } = useActivityModal();
    // const { user } = useUser();
    return (<>
      {/* { !user._id ? <ActivityIndicator size="large" color="#0000ff" /> :
        <> */}
      <NavigationContainer ref={navigationRef} onReady={() => setIsNavigationReady(true)} linking={linking}>
        <Stack.Navigator screenOptions={defaultScreenOptions} initialRouteName="ConceptSlides">
          {isLoggedIn ? (<>
              {/* Affiche le WebDrawer et le BottomNav uniquement pour les utilisateurs connectés */}
              <Stack.Screen name="Activities" component={LoggedInScreens} options={{ headerShown: false }}/>
              <Stack.Screen name="ConceptSlides" component={ConceptSlidesScreen} options={({ navigation }) => (Object.assign({}, defaultScreenOptions))}/>
              <Stack.Screen name="MyProfileScreen" component={MyProfileScreen} options={({ navigation }) => ({
                headerShown: false
            })}/>
              <Stack.Screen name="ProfileScreen" component={ProfileScreenParents} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonProfilePages.JsonMyProfileScreen.JsonTitleProfile }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>

              <Stack.Screen name="CreateActivity" component={CreateActivityScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'ProfileScreen');
            }}/>
              <Stack.Screen name="CopyActivity" component={CopyActivityScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'ProfileScreen');
            }}/>
              <Stack.Screen name="Activity" component={ActivityDetailsTabNavigator} options={({ navigation }) => (Object.assign(Object.assign({ title: author && author.firstName ? `${author.firstName.toUpperCase()} organize` : 'Unknown User organize' }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation), headerRight: () => headerRightMsg(activityModalOpen, setActivityModalOpen) }))}/>
              <Stack.Screen name="EditActivity" component={EditActivityScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'ProfileScreen');
            }}/>
              <Stack.Screen name="InviteFriends" component={InviteFriendsScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="ContactUs" component={ContactUsScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="Settings" component={SettingsScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="News" component={NewsScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="Update" component={UpdateScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="TermsAndConditions" component={TermsAndConditionsScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="Logout" component={LogoutButton} options={{ headerShown: false }}/>
              <Stack.Screen name="EditProfile" component={EditProfileScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'ProfileScreen');
            }}/>
              <Stack.Screen name="DeleteAccount" component={DeleteAccountStep1} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="DeleteAccountStep2" component={DeleteAccountStep2} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              {/*  import de Nelson provenat du code 2023 */}
              <Stack.Screen name="CupsAndAwards" component={CupsAndAwards} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonTitleCupsAndAwards }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="Drink" component={Drink} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonTitleFreeDrink }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="InProcess" component={InProcess} options={({ navigation }) => (Object.assign(Object.assign({ title: 'InProcess' }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="VipBadges" component={VipBadges} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonTitleVipBadges }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="VipBadgeGold" component={VipBadgeGold} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonGoldStar }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="VipBadgeRuby" component={VipBadgeRuby} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonRubyStar }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="VipBadgeEmerald" component={VipBadgeEmerald} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonEmeraldStar }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="VipBadgeDiamond" component={VipBadgeDiamand} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonDiamondStar }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="VipBoardExplanation" component={VipBoardExplanation} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonSponsorshipScreen.JsonVIPBoardExplanation }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="Rewards" component={Rewards} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonSponsorshipScreen.JsonRewards }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="BlockedUsers" component={InProcess} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonSponsorshipScreen.JsonRewards }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="LikedUsers" component={InProcess} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonSponsorshipScreen.JsonRewards }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="Notifications" component={NotificationNavigator} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonSponsorshipScreen.JsonNotificationsTab }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
              <Stack.Screen name="MessageChatScreen" component={MessageChatScreen} options={{ headerShown: false }}/>
              <Stack.Screen name="InviteByEmail" component={InviteByEmail}/>
              <Stack.Screen name="InviteByLink" component={InviteByLink} options={{ headerShown: false }}/>
              <Stack.Screen name="InviteByMessenger" component={InviteByMessenger} options={{ headerShown: false }}/>
              <Stack.Screen name="InviteBySMS" component={InviteBySMS} options={{ headerShown: false }}/>
              <Stack.Screen name="InviteBySnapchat" component={InviteBySnapchat} options={{ headerShown: false }}/>

              {/* Ajoutez ici les autres écrans du drawer avec `headerShown: true` */}
              {/* ... */}
            </>) : (
        // Écrans pour les utilisateurs non connectés
        <>
              <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false }}/>
              <Stack.Screen name="Start" component={StartScreen} options={{ headerShown: false }}/>

              <Stack.Screen name="Languages" component={LanguagesScreen} options={{ headerShown: false }}/>
              <Stack.Screen name="Email" component={EmailScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'Home');
            }}/>
              <Stack.Screen name="Password" component={ExistingUserPasswordScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
              <Stack.Screen name="Sponsorship" component={Sponsorship} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
              <Stack.Screen name="NewUserPassword" component={NewUserPasswordScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
              <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
              <Stack.Screen name="PhoneExistingUserScreen" component={PhoneExistingUserScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
              <Stack.Screen name="VerifyPhoneExistingUserScreen" component={VerifyPhoneExistingUserScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
              <Stack.Screen name="VerifyEmailExistingUserScreen" component={VerifyEmailExistingUserScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
              <Stack.Screen name="VerifyEmail" component={VerifyEmailScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
              <Stack.Screen name="CreateProfile" component={CreateProfileScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError); // Add focus listener to clear error
                return conditionalScreenOptions({ navigation, route }, 'CreateProfile');
            }}/>
              <Stack.Screen name="StepOne" component={StepOneScreen} options={{ headerShown: false }}/>
              <Stack.Screen name="StepTwo" component={StepTwoScreen} options={{ headerShown: false }}/>
              <Stack.Screen name="StepThreePersonal" component={Step3PersonalScreen} options={{ headerShown: false }}/>
              <Stack.Screen name="StepThreePro" component={Step3ProScreen} options={{ headerShown: false }}/>
              <Stack.Screen name="StepFour" component={Step4Screen} options={{ headerShown: false }}/>
              {/* Ajoutez ici les autres écrans pour les utilisateurs non connectés */}
              {/* ... */}
            </>)}
        </Stack.Navigator>
      </NavigationContainer>
    </>
    // }</>
    );
};
const AppWeb = () => {
    // const { user, userIdStorage } = useUser();
    const { user, userIdStorage } = useSelector((state) => state.user);
    // const { activities} =useActivity();
    console.log("user slice data ", user, userIdStorage);
    return (<>
      {user === null && userIdStorage !== null ? <ActivityIndicator size="large" color="#0000ff"/> :
            <>
          {/* <AuthProvider> */}
          <WebDrawerProvider>
            <AppContent />
          </WebDrawerProvider>
          {/* </AuthProvider> */}
        </>}
    </>);
};
export default AppWeb;
