import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
    },
    scrollViewContainer: {
        flex: 1,
        padding: '5%',
    },
    inputMoreOnYou: {
        height: '6%',
        borderColor: 'gray',
        borderWidth: 1,
        borderRadius: 10,
        paddingLeft: 20,
        paddingTop: 15,
        color: 'grey',
        marginBottom: '10%',
    },
    sectionTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: '2%',
    },
    activitiesContainer: {
        width: '100%',
        marginBottom: '10%',
        flexWrap: 'wrap',
        flexDirection: 'row',
        justifyContent: 'space-between',
        color: 'black',
    },
    activityCard: {
        width: '22%',
        marginVertical: '2%',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000000', // Couleur de l'ombre
        shadowOffset: { width: 0, height: 4 }, // Décalages horizontal et vertical
        shadowOpacity: 0.25, // Opacité de l'ombre (0.25 correspond à #40 en hexadécimal)
        shadowRadius: 4, // Rayon de flou
        elevation: 2, // Valeur approximative pour Android
    },
    activityTitle: {
        marginBottom: 5,
        fontSize: 12.5,
        paddingTop: 10,
    },
    flagsContainer: {
        alignSelf: 'center',
        width: '100%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        marginBottom: '10%',
    },
    flagWrapper: {
        width: '22%',
        marginVertical: '2%',
        backgroundColor: '#FFFFFF', // Default background color
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: '#59C09B',
        shadowColor: "#000000",
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.2,
        shadowRadius: 3,
    },
    flagWrapperSelected: {
        backgroundColor: '#59C09B', // Background color when selected
    },
    persoContainer: {
        marginBottom: '45%',
    },
    sectionPerso: {
        fontSize: 18,
        fontWeight: 'bold',
        marginTop: '5%',
    },
    buttonGroup: {
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    buttonGroup2: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
    },
    button: {
        backgroundColor: 'white',
        marginTop: '3%',
        justifyContent: 'center',
        padding: '3%',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#59C09B',
        width: '30%',
        shadowColor: '#000000', // Couleur de l'ombre
        shadowOffset: { width: 0, height: 4 }, // Décalages horizontal et vertical
        shadowOpacity: 0.25, // Opacité de l'ombre (0.25 correspond à #40 en hexadécimal)
        shadowRadius: 4, // Rayon de flou
        elevation: 2, // Valeur approximative pour Android
    },
    button2: {
        marginTop: '3%',
        backgroundColor: 'white',
        padding: '5%',
        borderRadius: 10,
        borderWidth: 1,
        borderColor: '#59C09B',
        width: "45%",
        shadowColor: '#000000', // Couleur de l'ombre
        shadowOffset: { width: 0, height: 4 }, // Décalages horizontal et vertical
        shadowOpacity: 0.25, // Opacité de l'ombre (0.25 correspond à #40 en hexadécimal)
        shadowRadius: 4, // Rayon de flou
        elevation: 2, // Valeur approximative pour Android
        justifyContent: 'center',
    },
    buttonSelected: {
        backgroundColor: '#59C09B',
    },
    buttonText: {
        fontSize: 13,
        textAlign: 'center',
        fontWeight: 'bold',
        color: 'black',
    },
    buttonTextSelected: {
        color: 'white',
    },
});
