/*FR
  Module définissant un objet VectorsOnOff avec des composants SVG représentant des icônes.
  Centralise les icônes utilisées dans l'application pour une utilisation modulaire. (frame 003/003/004/009/010/011/012/022popup suite 2/022A/021B2/091/022B)
*/
/*GB
  This module defines a VectorsOnOff object with SVG components representing Vectors.
  It centralizes the Vectors used in the application for modular usage. (frame 003/003/004/009/010/011/012/022popup suite 2/022A/021B2/091/022B)
*/
import React from 'react';
import Vectors from '../Vectors';
const VectorsOnOff = {
    IconFemaleSign: () => <Vectors.ProfilesFemaleSign />, // non utilisé
    IconMaleSign: () => <Vectors.ProfilesMaleSign />, // non utilisé
    IconFemale: () => <Vectors.ProfilesFemale />, //webDrawer
    IconMale: () => <Vectors.ProfilesMale />, //webDrawer
    IconNoGender: () => <Vectors.ProfilesNoGender />, //webDrawer
    IconEyeToggle: () => <Vectors.GeneralColorsEyeToggle />, //Code
    IconEyeToggleOff: () => <Vectors.GeneralColorsEyeToggleCross />, //Code
    IconContactOn: () => <Vectors.NavbarColorsContactUs />, // non utilisé
    IconContactOff: () => <Vectors.NavbarBlackAndWhiteContactUs />, // non utilisé
    IconNewsOn: () => <Vectors.NavbarColorsNews />, // non utilisé
    IconNewsOff: () => <Vectors.NavbarBlackAndWhiteNews />, // non utilisé
    IconConceptOn: () => <Vectors.NavbarColorsConcept />, // non utilisé
    IconConceptOff: () => <Vectors.NavbarBlackAndWhiteConcept />, // non utilisé
    IconMembersOn: () => <Vectors.NavbarMembersBottom />, // BootomNav , MiddleActivityNav
    IconMembersOff: () => <Vectors.NavbarBlackAndWhiteMembersBottom />, // BootomNav
    IconCalendarOff: () => <Vectors.NavbarBlackAndWhiteCalendarBottom />, // BootomNav
    IconCalendarOn: () => <Vectors.CalendarOn />, // BootomNav , aussi utilisé en tant qu import Vectors
    MapOn: () => <Vectors.NavbarColorsMap />, // BootomNav
    MapOff: () => <Vectors.NavbarBlackAndWhiteMap />, // BootomNav
    BellOn: () => <Vectors.NotificationsBellOn />, // BootomNav , aussi utilisé en tant qu import Vectors
    BellOff: () => <Vectors.NavbarBlackAndWhiteBell />, // BootomNav
    MessengerOn: () => <Vectors.NavbarColorsMessenger />, // BootomNav
    MessengerOff: () => <Vectors.NavbarBlackAndWhiteMessenger />, // BootomNav
};
export default VectorsOnOff;
