import React, { createContext, useState, useContext } from 'react';
const MobileDrawerContext = createContext(undefined);
export const MobileDrawerProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleDrawer = () => {
        setIsOpen(!isOpen);
    };
    const value = { isOpen, toggleDrawer };
    return <MobileDrawerContext.Provider value={value}>{children}</MobileDrawerContext.Provider>;
};
export const useMobileDrawer = () => {
    const context = useContext(MobileDrawerContext);
    if (context === undefined) {
        throw new Error('useMobileDrawer must be used within a MobileDrawerProvider');
    }
    return context;
};
