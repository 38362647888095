import React from 'react';
import { View, Text, Platform, Pressable } from 'react-native';
import NetworkUserCard from '../../../../components/CardSection/UserCardBasic/Code';
import { useSelector } from 'react-redux';
const PublicProfileNetworkScreenProps = ({ profileOwner, allUsers, userActivities }) => {
    const styles = Platform.OS === 'web'
        ? require('./StylesWeb').default
        : require('./StylesMobile').default;
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const userHasMet = userActivities === null || userActivities === void 0 ? void 0 : userActivities.flatMap((activity) => {
        var _a;
        // Trouver les rencontres depuis les listes attendees
        const attendees = (_a = activity.attendees) === null || _a === void 0 ? void 0 : _a.filter((attendee) => attendee !== (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner._id));
        // Enlever les doublons dans la liste userHasMet
        const uniqueUserHasMet = [...new Set(attendees)];
        return uniqueUserHasMet;
    });
    const filteredNetwork = allUsers === null || allUsers === void 0 ? void 0 : allUsers.filter((user) => userHasMet.includes(user._id) || (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.friends.includes(user._id)));
    const userNetwork = filteredNetwork === null || filteredNetwork === void 0 ? void 0 : filteredNetwork.map((user) => ({
        user: user,
        hasMet: userHasMet.includes(user._id),
        isFriend: profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.friends.includes(user._id),
    }));
    return (<View style={styles.container}>
            <View style={styles.header}>
                <View style={styles.headerLeft}>
                    <View style={styles.networkNumber}>
                        <Text style={styles.membersTitle}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNetwork}</Text>
                        <Text style={styles.networkCount}>{userNetwork === null || userNetwork === void 0 ? void 0 : userNetwork.length}</Text>
                    </View>
                    {/* Manque les traductions pour 'members met' */}
                    {/* <Text>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNetwork}</Text> */}
                    <Text style={styles.underHeader}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonMembersMet}</Text>
                </View>
                <View style={styles.headerRight}>
                    <View style={styles.legendItem}>
                        <View style={styles.legendCircleGray}></View>
                        <Text style={styles.legendText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonPeopleMet}</Text>
                    </View>
                    <View style={styles.legendItem}>
                        <View style={styles.legendCircleBlue}></View>
                        <Text style={styles.legendText}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonPeopleFriend}</Text>
                    </View>
                    {/* <RightArrow width={20} height={20} viewBox={'0 0 20 20'}/> */}
                </View>
            </View>
            <View>
                    <View style={styles.network}>
                        {userNetwork.length > 0 ? userNetwork.map((user) => (<View style={styles.networkItem} key={user.user._id}>
                                <NetworkUserCard user={user}/>
                            </View>)) : <View><Text>{translations.JsonProfilePages.JsonMyProfileScreen.JsonNoConnection}</Text></View>}
                    </View>
                </View>
                <Pressable>
                <Text style={styles.seeAll}>{translations.JsonActivityPages.JsonActivityScreen.JsonActivityParticipantsScreen.JsonSeeAll}</Text>
                </Pressable>
            </View>);
};
export default PublicProfileNetworkScreenProps;
