var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// 🇫🇷 Ce fichier gère la troisème étape pour les modifications profile, affecte surtout les préference de l'utilisateur. (FrameFigma017)
// 🇬🇧 This file manages the third step for user profile modifications, especially the user's preferences. (FrameFigma017)
import React, { useState, useEffect } from 'react';
import { ScrollView, Text, TextInput, TouchableOpacity, View, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import { activitiesIcon } from '../../../../constants/VectorsOnOff/ActivityListIcon';
import countriesListSquareFlags from '../../../../components/GeneralSection/LanguagesFlagsComponent/LanguagesFlagsSquare';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import Vectors from '../../../../constants/Vectors';
import { useDispatch, useSelector } from 'react-redux';
import { userServices } from '../../../../services/user/UserServices';
import UserSlice from '../../../../redux/Features/User/UserSlice';
import { nanoid } from '@reduxjs/toolkit';
import BottomWhiteNav from '../../../../components/NavBarSection/BottomNav/BottomWhiteNav/Code';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const EditProfileStep3Screen = () => {
    // const { user, updateUser, fetchUser } = useUser();
    const { user, allUsers } = useSelector((state) => state.user);
    const { userToken } = useSelector((state) => state.auth);
    const { updateUser, getUserByFilter } = userServices;
    const navigation = useNavigation();
    const [isChanged, setIsChanged] = useState(false);
    const [formData, setFormData] = useState({
        about: '',
        activitiesTopic: [],
        spokenLanguage: [],
        studies: '',
        children: "Don't Display",
        tobacco: "Don't Display",
        alcohol: "Don't Display",
    });
    const [formDataBefore, setFormDataBefore] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const dispatch = useDispatch();
    /**
     * 🇬🇧 Loads initial data from AsyncStorage or user object.
     * 🇫🇷 Charge les données initiales depuis AsyncStorage ou l'objet utilisateur.
     */
    useEffect(() => {
        const loadData = () => __awaiter(void 0, void 0, void 0, function* () {
            const storedData = yield AsyncStorage.getItem('profileData3');
            if (storedData) {
                const parsedData = JSON.parse(storedData);
                setFormData(parsedData);
                const initialFormData = {
                    about: (user === null || user === void 0 ? void 0 : user.about) || '',
                    activitiesTopic: (user === null || user === void 0 ? void 0 : user.activitiesTopic) ? [...user.activitiesTopic] : [],
                    spokenLanguage: (user === null || user === void 0 ? void 0 : user.spokenLanguage) ? [...user.spokenLanguage] : [],
                    studies: (user === null || user === void 0 ? void 0 : user.studies) || '',
                    children: (user === null || user === void 0 ? void 0 : user.children) || "Don't Display",
                    tobacco: (user === null || user === void 0 ? void 0 : user.tobacco) || "Don't Display",
                    alcohol: (user === null || user === void 0 ? void 0 : user.alcohol) || "Don't Display",
                };
                setFormDataBefore(initialFormData);
                setIsChanged(JSON.stringify(parsedData) !== JSON.stringify(initialFormData));
            }
            else if (user) {
                const initialData = {
                    about: (user === null || user === void 0 ? void 0 : user.about) || '',
                    activitiesTopic: (user === null || user === void 0 ? void 0 : user.activitiesTopic) ? [...user.activitiesTopic] : [],
                    spokenLanguage: (user === null || user === void 0 ? void 0 : user.spokenLanguage) ? [...user.spokenLanguage] : [],
                    studies: (user === null || user === void 0 ? void 0 : user.studies) || '',
                    children: (user === null || user === void 0 ? void 0 : user.children) || "Don't Display",
                    tobacco: (user === null || user === void 0 ? void 0 : user.tobacco) || "Don't Display",
                    alcohol: (user === null || user === void 0 ? void 0 : user.alcohol) || "Don't Display",
                };
                setFormData(initialData);
                setFormDataBefore(initialData);
            }
        });
        loadData();
    }, [user]);
    /**
     * 🇬🇧 Handles changes to the activities topic.
     * 🇫🇷 Gère les changements du sujet des activités.
     */
    const handleActivitiesTopicChange = (id) => {
        const newTopics = formData.activitiesTopic.includes(id)
            ? formData.activitiesTopic.filter(existingId => existingId !== id)
            : [...formData.activitiesTopic, id];
        setFormData(prev => (Object.assign(Object.assign({}, prev), { activitiesTopic: newTopics })));
        handleFieldChange('activitiesTopic', newTopics);
    };
    /**
     * 🇬🇧 Handles changes to the spoken languages.
     * 🇫🇷 Gère les changements des langues parlées.
     */
    const handleSpokenLanguageChange = (bigram) => {
        const newLanguages = formData.spokenLanguage.includes(bigram)
            ? formData.spokenLanguage.filter(b => b !== bigram)
            : [...formData.spokenLanguage, bigram];
        setFormData(prevFormData => (Object.assign(Object.assign({}, prevFormData), { spokenLanguage: newLanguages })));
        handleFieldChange('spokenLanguage', newLanguages);
    };
    /**
     * 🇬🇧 Handles field changes and updates form data.
     * 🇫🇷 Gère les changements de champ et met à jour les données du formulaire.
     */
    const handleFieldChange = (key, value) => {
        setFormData(prevState => {
            var _a;
            const updatedFormData = Object.assign(Object.assign({}, prevState), { [key]: value });
            if (key === 'activitiesTopic' || key === 'spokenLanguage') {
                setIsChanged(JSON.stringify((_a = formDataBefore === null || formDataBefore === void 0 ? void 0 : formDataBefore[key]) === null || _a === void 0 ? void 0 : _a.sort()) !== JSON.stringify(value.sort()));
            }
            else {
                setIsChanged(JSON.stringify(updatedFormData) !== JSON.stringify(formDataBefore));
            }
            AsyncStorage.setItem('profileData3', JSON.stringify(updatedFormData));
            return updatedFormData;
        });
    };
    /**
     * 🇬🇧 Handles form submission.
     * 🇫🇷 Gère la soumission du formulaire.
     */
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setIsLoading(true);
        if (!isChanged) {
            try {
                yield AsyncStorage.removeItem('profileData1');
                yield AsyncStorage.removeItem('profileData2');
                yield AsyncStorage.removeItem('profileData3');
                //console.log('Temp data removed');
            }
            catch (error) {
                console.error('Failed to remove form data from AsyncStorage', error);
            }
            navigation.navigate('MyProfileScreen', { userData: user, userId: user._id });
            return;
        }
        const submitData = Object.assign(Object.assign({}, formData), { activitiesTopic: formData.activitiesTopic });
        try {
            let updated = yield updateUser(user._id, submitData);
            if (updated)
                dispatch(UserSlice.actions.updateUserProperties({ id: nanoid(), value: submitData }));
            //console.log('User updated successfully');
            yield AsyncStorage.removeItem('profileData1');
            yield AsyncStorage.removeItem('profileData2');
            yield AsyncStorage.removeItem('profileData3');
            getUserByFilter(allUsers, user.id);
            navigation.navigate('MyProfileScreen', { userData: user, userId: user._id });
        }
        catch (error) {
            console.error('Error updating user:', error);
        }
        finally {
            setIsLoading(false);
        }
    });
    /**
     * 🇬🇧 Renders a 3 button group for selection.
     * 🇫🇷 Affiche un groupe de 3 boutons pour la sélection.
     */
    const renderButtonGroup = (key, choices, currentValue) => {
        const valueMap = {
            children: {
                [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret]: "Don't Display",
                [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonYes]: 'Yes',
                [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNo]: 'No',
            }
        };
        return (<View style={styles.buttonGroup}>
        {choices.map(choice => (<TouchableOpacity key={choice} onPress={() => handleFieldChange(key, valueMap[key][choice])} style={[
                    styles.button,
                    Array.isArray(currentValue) ? currentValue.includes(valueMap[key][choice]) : currentValue === valueMap[key][choice] ? styles.buttonSelected : {},
                ]}>
            <Text style={[
                    styles.buttonText,
                    Array.isArray(currentValue) ? currentValue.includes(valueMap[key][choice]) : currentValue === valueMap[key][choice] ? styles.buttonTextSelected : {},
                ]}>{choice}</Text>
          </TouchableOpacity>))}
      </View>);
    };
    /**
     * 🇬🇧 Renders a 4 button group for selection.
     * 🇫🇷 Affiche le groupe de 4 boutons pour la sélection.
     */
    const renderButtonGroup2 = (key, choices, currentValue) => {
        const valueMap = {
            tobacco: {
                [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret]: "Don't Display",
                [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSmoking]: 'Smoking',
                [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNoSmoking]: 'No smoking',
                [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonForSpecialOccasions]: 'For special occasions',
            },
            alcohol: {
                [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret]: "Don't Display",
                [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNoDrinking]: "I don't drink",
                [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSometimes]: 'Sometimes',
                [translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonForSpecialOccasions]: 'For special occasions',
            },
        };
        return (<View style={styles.buttonGroup2}>
        {choices.map(choice => (<TouchableOpacity key={choice} onPress={() => handleFieldChange(key, valueMap[key][choice])} style={[
                    styles.button2,
                    Array.isArray(currentValue) ? currentValue.includes(valueMap[key][choice]) : currentValue === valueMap[key][choice] ? styles.buttonSelected : {},
                ]}>
            <Text style={[
                    styles.buttonText,
                    Array.isArray(currentValue) ? currentValue.includes(valueMap[key][choice]) : currentValue === valueMap[key][choice] ? styles.buttonTextSelected : {},
                ]}>{choice}</Text>
          </TouchableOpacity>))}
      </View>);
    };
    return (<View style={styles.container}>
      <ScrollView style={styles.scrollViewContainer} showsVerticalScrollIndicator={false}>
        <Text style={styles.sectionTitle}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonMoreOnYou}</Text>
        <TextInput style={styles.inputMoreOnYou} placeholder={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonExample} multiline value={formData.about} onChangeText={text => handleFieldChange('about', text)}/>

        <Text style={styles.sectionTitle}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonWhatILoveToDo}</Text>
        <View style={styles.activitiesContainer}>
          {activitiesIcon.map(activity => {
            const isSelected = formData.activitiesTopic.includes(activity.id.toString());
            return (<TouchableOpacity key={activity.id} onPress={() => handleActivitiesTopicChange(activity.id.toString())} style={[
                    styles.activityCard,
                    { backgroundColor: isSelected ? "#59C09B" : "white" },
                ]}>
                {isSelected ? activity.activityIconOn : activity.activityIconOff}
                <Text style={[
                    styles.activityTitle,
                    {
                        fontWeight: isSelected ? "bold" : "400",
                        color: isSelected ? "white" : "black",
                    },
                ]}>
                  {activity.activityTitle}
                </Text>
              </TouchableOpacity>);
        })}
        </View>

        <Text style={styles.sectionTitle}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSpokenLanguages}</Text>
        <View style={styles.flagsContainer}>
          {countriesListSquareFlags.map(({ language, FlagComponent, bigram }) => (<TouchableOpacity key={bigram} onPress={() => handleSpokenLanguageChange(bigram)} style={[styles.flagWrapper, formData.spokenLanguage.includes(bigram) ? styles.flagWrapperSelected : {}]}>
              <FlagComponent width={30} height={30}/>
            </TouchableOpacity>))}
        </View>

        <Text style={styles.sectionTitle}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonStudies}</Text>
        <TextInputCustomComponent Icon={Vectors.ProfilesLastName} placeholderText={translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonStudies} value={formData.studies} handleTextChange={text => handleFieldChange('studies', text)} isPlaceHolderTextAlignCenter={false}/>

        <View style={styles.persoContainer}>
          <Text style={styles.sectionPerso}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonChildren}</Text>
          {renderButtonGroup('children', [
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret,
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonYes,
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNo
        ], formData.children)}
          <Text style={styles.sectionPerso}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonTobacco}</Text>
          {renderButtonGroup2('tobacco', [
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret,
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNoSmoking,
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSmoking,
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonForSpecialOccasions
        ], formData.tobacco)}
          <Text style={styles.sectionPerso}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonAlcohol}</Text>
          {renderButtonGroup2('alcohol', [
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSecret,
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonNoDrinking,
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonSometimes,
            translations.JsonProfilePages.JsonEditProfileScreen.JsonStep3.JsonForSpecialOccasions
        ], formData.alcohol)}
        </View>
      </ScrollView>
      <BottomWhiteNav items={[
            { label: isChanged ? translations.JsonProfilePages.JsonEditProfileScreen.JsonSaveButton : translations.JsonProfilePages.JsonEditProfileScreen.JsonCancelButton, onPress: () => handleSubmit() },
        ]}/>
    </View>);
};
export default EditProfileStep3Screen;
