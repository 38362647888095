var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// PermissionsIOS.js
import { check, PERMISSIONS, request, RESULTS } from 'react-native-permissions';
// Demande de permission pour la caméra sur iOS
export const checkCameraPermissionIOS = () => __awaiter(void 0, void 0, void 0, function* () {
    const permission = PERMISSIONS.IOS.CAMERA;
    const result = yield check(permission);
    if (result === RESULTS.GRANTED) {
        console.info('Permission de caméra déjà accordée.');
        return true;
    }
    console.log('Demande de permission de caméra...');
    const requestResult = yield request(permission);
    if (requestResult === RESULTS.GRANTED) {
        console.info('Permission de caméra accordée.');
        return true;
    }
    else {
        console.error('Permission de caméra refusée.');
        return false;
    }
});
// Demande de permission pour la bibliothèque de photos sur iOS
export const checkPhotoLibraryPermissionIOS = () => __awaiter(void 0, void 0, void 0, function* () {
    const permission = PERMISSIONS.IOS.PHOTO_LIBRARY;
    const result = yield check(permission);
    if (result === RESULTS.GRANTED) {
        console.info('Permission de bibliothèque de photos déjà accordée.');
        return true;
    }
    console.log('Demande de permission de bibliothèque de photos...');
    const requestResult = yield request(permission);
    if (requestResult === RESULTS.GRANTED) {
        console.info('Permission de bibliothèque de photos accordée.');
        return true;
    }
    else {
        console.error('Permission de bibliothèque de photos refusée.');
        return false;
    }
});
export default {
    checkCameraPermissionIOS,
    checkPhotoLibraryPermissionIOS,
};
