// FR sélectionner une adresse automatiquement à partir de suggestions (FrameFigma041,FrameFigma031,FrameFigma032A,FrameFigma035A)
// EN Automatically select an address from suggestions (FrameFigma041,FrameFigma031,FrameFigma032A,FrameFigma035A)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { View, Platform, ScrollView, Text } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Vectors from '../../../constants/Vectors';
import { GOOGLE_MAPS_API_KEY } from '@env';
import TextInputCustomComponent from '../../GeneralSection/TextInputCustomComponent/Code';
import { hostname } from '../../../../hostName/hostName';
// Import Contexts
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const AddressAutoPicker = ({ onAddressSelect, selectedAddress = '', setSelectedAddress = () => { }, placeholder = '', addressType = 'default', }) => {
    const [addressSelected, setAddressSelected] = useState(false);
    const [query, setQuery] = useState(selectedAddress || '');
    const [suggestions, setSuggestions] = useState([]);
    useEffect(() => {
        const fetchAddresses = () => __awaiter(void 0, void 0, void 0, function* () {
            if (query.length > 1 && !addressSelected) {
                try {
                    const apiUrl = Platform.OS === 'web'
                        ? `${hostname}/api/places/autocomplete?input=${encodeURIComponent(query)}`
                        : `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURIComponent(query)}&key=${GOOGLE_MAPS_API_KEY}&language=en`;
                    const response = yield fetch(apiUrl);
                    const data = yield response.json();
                    if (response.ok && !addressSelected) {
                        setSuggestions(data.predictions);
                    }
                    else {
                        setSuggestions([]);
                    }
                }
                catch (error) {
                    console.error('Failed to fetch address suggestions:', error);
                    setSuggestions([]);
                }
            }
            else {
                setSuggestions([]);
            }
        });
        fetchAddresses();
    }, [query, addressSelected]);
    const handleSuggestionSelect = (selectedSuggestion) => __awaiter(void 0, void 0, void 0, function* () {
        const apiUrl = Platform.OS === 'web'
            ? `${hostname}/api/places/details?placeid=${selectedSuggestion.place_id}`
            : `https://maps.googleapis.com/maps/api/place/details/json?placeid=${selectedSuggestion.place_id}&key=${GOOGLE_MAPS_API_KEY}&language=ar`;
        try {
            const response = yield fetch(apiUrl);
            const data = yield response.json();
            if (response.ok) {
                const { lat, lng } = data.result.geometry.location;
                if (!(yield AsyncStorage.getItem('latitude'))) {
                    yield AsyncStorage.setItem('latitude', JSON.stringify(lat));
                }
                if (!(yield AsyncStorage.getItem('longitude'))) {
                    yield AsyncStorage.setItem('longitude', JSON.stringify(lng));
                }
                yield AsyncStorage.setItem('city', selectedSuggestion.description);
                setQuery(selectedSuggestion.description);
                setAddressSelected(true);
                setSelectedAddress(selectedSuggestion.description);
                onAddressSelect && onAddressSelect(selectedSuggestion.description, lat, lng);
                setSuggestions([]);
            }
            else {
                console.error('No location found for the selected place');
            }
        }
        catch (error) {
            console.error('Failed to fetch place details:', error);
        }
    });
    const handleTextChange = (text) => {
        setQuery(text);
        setSelectedAddress('');
        setAddressSelected(false);
    };
    return (<View>
      <TextInputCustomComponent Icon={Vectors.ProfilesCity} placeholderText={placeholder} handleTextChange={handleTextChange} value={query} isPlaceHolderTextAlignCenter={false} multiline={addressType === 'activity'} numberOfLines={addressType === 'activity' ? 2 : 1}/>
      {query.length > 0 && suggestions.length > 0 && !addressSelected && (<ScrollView style={styles.suggestionsContainer}>
          {suggestions.map((suggestion, index) => (<Text key={index} onPress={() => handleSuggestionSelect(suggestion)} style={styles.suggestion}>
              {suggestion.description}
            </Text>))}
        </ScrollView>)}
    </View>);
};
export default AddressAutoPicker;
