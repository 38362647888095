//FR  demande a utilisateur de saisir son mot de passe pour fermer son compte (FrameFigma111B)
//GB  The component asks the user to enter their password to close their account. (FrameFigma111B)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Import Modules
import React, { useState } from "react";
import { Modal, View, Text, Pressable, Platform, TouchableOpacity } from "react-native";
// Import Contexts
import { hostname } from "../../../../hostName/hostName";
import { useNavigation } from "@react-navigation/native";
// Import Vectors
import { Vectors } from "../../../constants/Vectors";
// Import Components
import TextInputCustomComponent from '../../../components/GeneralSection/TextInputCustomComponent/Code';
import { useSelector } from "react-redux";
const ConfirmationModal = ({ showConfirmation, setShowConfirmation, formData }) => {
    const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const navigation = useNavigation();
    // const { user } = useUser();
    const { user } = useSelector((state) => state.user);
    const { userToken } = useSelector((state) => state.auth);
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const handleNext = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            if (password === '') {
                setError('Saisis ton mot de passe avant de supprimer.');
                return;
            }
            const email = user === null || user === void 0 ? void 0 : user.email;
            const response = yield fetch(`${hostname}/login`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${userToken}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email,
                    password,
                }),
            });
            const responseData = yield response.json();
            if (responseData.authToken && responseData.user) {
                setShowConfirmation(!showConfirmation);
                navigation.navigate('DeleteAccountStep2', formData);
            }
        }
        catch (validationError) {
            setError('Mot de passe incorrect. Réessaie.');
        }
    });
    return (<Modal animationType="slide" transparent={true} visible={showConfirmation} onRequestClose={() => {
            setShowConfirmation(!showConfirmation);
        }}>
            <View style={styles.container}>
                <View style={styles.modal}>
                    <Pressable onPress={() => setShowConfirmation(!showConfirmation)}>
                        <Vectors.LeftArrowGreen width={30} height={30}/>
                    </Pressable>
                    <View style={styles.AlignCenter}>
                        <Text style={styles.TextBold}>Pour sécuriser ton compte, saisie ton mot de passe de connexion</Text>
                        <TextInputCustomComponent Icon={Vectors.ProfilesPasswordPadLock} placeholderText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordPlaceholder} value={password} handleTextChange={text => { setPassword(text), setError(''); }} isPlaceHolderTextAlignCenter={false} isPassword={true}/>
                        {error && <Text style={styles.err}>{error}</Text>}
                    </View>
                    <View style={styles.deleteCancelButtonRow}>
                        <TouchableOpacity style={[styles.cancelButton, styles.btnSelectedGreen]} onPress={() => setShowConfirmation(!showConfirmation)}>
                            <Text style={[styles.buttonBigSize, styles.TextBold, styles.buttonTextWhite]}>CANCEL</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.deleteButton} onPress={() => handleNext()}>
                            <Text style={[styles.buttonBigSize, styles.TextBold]}>DELETE</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
        </Modal>);
};
export default ConfirmationModal;
