var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// InputScreen.tsx
import React, { useState } from 'react';
import { Platform, Pressable, Text, TextInput, View } from 'react-native';
import Step2ConfirmationScreen from '../../ModalPopUpModule/AddFriendPopUpSection/Step2ConfirmationScreen/Code';
import PhoneNumberInput from '../../PickerSection/PhoneWebPicker/Code';
import { useSelector } from 'react-redux';
import { userServices } from '../../../services/user/UserServices';
const styles = Platform.OS === 'web' ? require('../../ModalPopUpModule/AddFriendPopUpSection/Step1InputScreen/StylesWeb').default :
    require('../../ModalPopUpModule/AddFriendPopUpSection/Step1InputScreen/StylesMobile').default;
const InputScreen = ({ icon, currentUser, onClose, placeholder, validationKey, initialValue, isDate, isPhone }) => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { user } = useSelector((state) => state.user);
    const t = translations.JsonConfirmFriendshipPopup;
    const [inputValue, setInputValue] = useState(initialValue);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [verificationSuccess, setVerificationSuccess] = useState(false);
    const handleInputChange = (value) => {
        if (isDate) {
            setInputValue(new Date(value).toISOString());
        }
        else {
            setInputValue(value);
        }
    };
    const handlePhoneChange = (phone) => {
        setInputValue(phone);
    };
    const handleCountryChange = (countryCode) => {
        // Utilisez cette fonction si nécessaire
    };
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setButtonClicked(true);
        if (inputValue === currentUser[validationKey]) {
            setVerificationSuccess(true);
            yield userServices.addFriendPostRequest(user._id, currentUser._id);
        }
    });
    return (<>
            {buttonClicked ? (<Step2ConfirmationScreen verificationSuccess={verificationSuccess} onClose={onClose}/>) : (<>
                    <View>
                        <Text style={styles.modalTitle}>{t[`JsonPopupEnter${validationKey.charAt(0).toUpperCase() + validationKey.slice(1)}`]}</Text>
                        {icon}
                    </View>
                    {isPhone ? (<PhoneNumberInput value={inputValue} onPhoneNumberChange={handlePhoneChange} onCountryChange={handleCountryChange}/>) : (<View style={styles.inputContainer}>
                            <TextInput value={inputValue} style={styles.input} onChangeText={handleInputChange} placeholder={placeholder} placeholderTextColor={'#C3BDBD'}/>
                        </View>)}
                    <View>
                        <Pressable style={[styles.confirmButton, { backgroundColor: inputValue ? '#59C09B' : '#6f6f6f' }]} disabled={!inputValue} onPress={handleSubmit}>
                            <Text style={styles.confirmButton.buttonText}>{t.JsonButtonConfirm}</Text>
                        </Pressable>
                    </View>
                </>)}
        </>);
};
export default InputScreen;
