// Fr une page de mise à jour d'application #Frame Figma 061
// En a frame 061 application update page   #Figma Frame 061
import { View, Text, Image, Pressable, Linking, ScrollView, SafeAreaView, Platform } from 'react-native';
import React from 'react';
import Logo from '../../assets/Images/General/LogoSocializus.png';
import { Vectors } from "../../constants/Vectors";
// import { useLanguage } from '../../utils/LanguageContext';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const UpdateScreen = () => {
    const { translations, language } = useSelector((state) => state.language);
    return (<SafeAreaView style={styles.container}>
      <ScrollView key={language} contentContainerStyle={styles.contentContainer}>
        <Image source={Logo} style={styles.logo} resizeMode='contain'/>
        <Text style={styles.update}>{translations.JsonUpdateScreen.JsonDontForgetUpdate.toUpperCase()}</Text>
        <Pressable style={styles.buttonStore} onPress={() => Linking.openURL('https://apps.apple.com/fr/app/socializus/id1492352535')}>
            <View style={styles.logoApple}>
              <Vectors.SocialNetworksAppleLogo />
            </View>
            <View style={[styles.textContainer, { marginRight: '10%' }]}>
              <Text style={styles.smallText}>{translations.JsonUpdateScreen.JsonUpdateScreenTextApple}</Text>
              <Text style={styles.textStore}>App Store</Text>
            </View>
        </Pressable>
        <Pressable style={styles.buttonStore} onPress={() => Linking.openURL('https://play.google.com/store/apps/details?id=com.social.firebase.example.socializus&hl=fr&pli=1')}>
          <View style={styles.logoGoogle}>
              <Vectors.SocialNetworksGooglePlayLogo />
            </View>
          <View style={styles.textContainer}>
            <Text style={styles.smallText}>{translations.JsonUpdateScreen.JsonUpdateScreenTextGoogle}</Text>
              <Text style={styles.textStore}>Google Play</Text>
            </View>
        </Pressable>
        <Pressable style={styles.buttonStore} onPress={() => Linking.openURL('https://appgallery.huawei.com/app/C111588013')}>
          <View style={styles.logoHuawei}>
              <Vectors.SocialNetworksHuaweilogo />
            </View>
          <View style={styles.textContainer}>
            <Text style={styles.smallText}>{translations.JsonUpdateScreen.JsonUpdateScreenTextHuawei}</Text>
              <Text style={styles.textStore}>AppGallery</Text>
            </View>
        </Pressable>
        <Pressable style={styles.buttonStore} onPress={() => Linking.openURL('https://socializus.app/home')}>
          <View style={styles.logoWebsite}>
              <Vectors.SocialNetworksWebsiteLogo />
          </View>
          <View style={[styles.textContainer, { marginRight: '15%' }]}>
            <Text style={styles.smallText}>{translations.JsonUpdateScreen.JsonUpdateScreenTextWeb}</Text>
            <Text style={styles.textStore}>Internet</Text>
            </View>
        </Pressable>
      </ScrollView>
    </SafeAreaView>);
};
export default UpdateScreen;
