import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flexDirection: 'row',
        backgroundColor: 'white',
        bottom: 0,
        position: 'absolute',
        width: '100%',
        height: '10%',
        alignSelf: 'center',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    buttonContainer: {
        flex: 1,
        height: '70%',
        marginHorizontal: '3%',
        backgroundColor: '#59C09B',
        borderRadius: 20,
        alignItems: 'center',
        justifyContent: 'center',
        elevation: 5,
        shadowColor: 'black', // Couleur de l'ombre
        shadowOffset: { width: 0, height: 4 }, // Décalages
        shadowOpacity: 1, // Opacité (pleine opacité)
        shadowRadius: 5, // Rayon de flou
    },
    buttonText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
