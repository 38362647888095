var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ActivityServices } from './ActivityServices';
const CreateActivity = (datas, activityImage) => __awaiter(void 0, void 0, void 0, function* () {
    const formData = new FormData();
    // console.log("the activity ", datas);
    console.log("the image ", activityImage);
    try {
        for (const key in datas) {
            if (datas.hasOwnProperty(key)) {
                if (typeof datas[key] === 'object' && datas[key] !== null) {
                    formData.append(key, JSON.stringify(datas[key]));
                }
                else {
                    formData.append(key, datas[key]);
                }
            }
        }
        if (activityImage && activityImage.file) {
            if (activityImage.file.mime) {
                console.log("there is a mime");
                formData.append('activityImage', {
                    // Create a file object-like structure
                    uri: 'file://' + activityImage.file.uri, // File URI
                    name: Date.now().toString(), // File name
                    type: activityImage.file.mime, // MIME type of the file (adjust this based on your file type)
                });
            }
            else
                formData.append('activityImage', activityImage.file);
        }
        console.log('formData:', formData);
        const res = yield ActivityServices.createActivity(formData);
        console.log("the response of activity creation ", res);
        if (res)
            return res;
        else
            return false;
    }
    catch (error) {
        console.log('Error creating activity:', error);
        return false;
    }
});
const EditActivity = (currentActivity, activityImage) => __awaiter(void 0, void 0, void 0, function* () {
    // const currentActivity = {...currentActivityData, activityImage:''}
    const formData = new FormData();
    try {
        for (const key in currentActivity) {
            if (currentActivity.hasOwnProperty(key)) {
                if (typeof currentActivity[key] === 'object' && currentActivity[key] !== null) {
                    formData.append(key, JSON.stringify(currentActivity[key]));
                }
                else {
                    formData.append(key, currentActivity[key]);
                }
            }
        }
        if (activityImage && activityImage.file) {
            formData.append('activityImage', activityImage.file);
        }
        const res = yield ActivityServices.editActivity(currentActivity._id, formData);
        return res.data;
    }
    catch (error) {
        console.log('Error editing activity:', error);
    }
});
export const ActivityCRUD = {
    CreateActivity,
    EditActivity,
};
