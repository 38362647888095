// FR permet aux utilisateurs d'inviter des amis en utilisant un lien
// EN allows users to invite friends using a link
import React from 'react';
import { View, Text, Pressable, Linking } from 'react-native';
import { useSelector } from 'react-redux';
const InviteByLink = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const inviteByLink = () => {
        const link = 'https://install.socializus.com/';
        Linking.openURL(link);
    };
    return (<View>
      <Pressable onPress={inviteByLink}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteWithLink}</Text>
      </Pressable>
    </View>);
};
export default InviteByLink;
