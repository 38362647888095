//🇫🇷 Ce fichier gère le style mobile du composant VerifyEmailExistingUserScreen.js (FrameFigma007A FrameFigma007B FrameFigma007C)
//🇬🇧 This file manages the mobile style of the VerifyEmailExistingUserScreen.js component (FrameFigma007A FrameFigma007B FrameFigma007C)
import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    verification: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'white',
    },
    verificationText: {
        marginTop: height * 0.08,
        textAlign: 'center',
        fontSize: width * 0.035,
        fontWeight: '400',
        lineHeight: width * 0.045,
        marginBottom: height * 0.025,
    },
    inputs: {
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: height * 0.025,
    },
    opt: {
        width: width * 0.11,
        height: height * 0.05,
        backgroundColor: 'white',
        borderColor: '#000000',
        borderWidth: 1,
        borderRadius: 10,
        textAlign: 'center',
        marginHorizontal: width * 0.0125,
        fontSize: width * 0.045,
    },
    optError: {
        borderColor: 'red',
    },
    resend: {
        alignItems: 'center',
        marginVertical: height * 0.025,
    },
    resendText: {
        alignContent: 'center',
        textAlign: 'center',
        fontSize: width * 0.04,
        marginBottom: height * 0.0125,
    },
    btnResendContainer: {
        marginTop: height * 0.0125,
        borderWidth: 1,
        borderColor: '#59C09B',
        paddingVertical: height * 0.0075,
        borderRadius: 15,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
    },
    btnResend: {
        alignContent: 'center',
        justifyContent: 'center',
    },
    btnTextResend: {
        color: '#59C09B',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    btnContainer: {
        flexDirection: 'row',
        marginTop: height * 0.0625,
        justifyContent: 'space-around',
        width: '100%',
        alignItems: 'center',
    },
    btnText: {
        color: 'white',
        fontSize: width * 0.055,
        fontWeight: 'bold',
    },
    headerError: {
        backgroundColor: 'red',
        padding: width * 0.05,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: width * 0.045,
        fontWeight: 'bold',
    },
    paste: {
        width: width * 0.05,
        height: width * 0.05,
        alignSelf: 'flex-end',
        marginRight: width * 0.0025,
    },
    pasteContainer: {
        alignSelf: 'flex-end',
        marginRight: width * 0.05,
    },
});
