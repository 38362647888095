//FR affiche les notifications d'un utilisateur(FrameFigma024A)
//EN displays user notifications(FrameFigma024A)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { ScrollView, View, Text, StyleSheet, TouchableOpacity } from 'react-native';
import VisitedNotificationCard from '../../../components/CardSection/VisitedNotificationCard/Code';
import FriendAddedNotificationCard from '../../../components/CardSection/FriendAddedNotificationCard/Code';
import LikedNotificationCard from '../../../components/CardSection/LikedNotificationCard/Code';
import ActivitiesLikeNotificationCard from '../../../components/CardSection/ActivitiesLikeNotificationCard/Code';
import Vectors from '../../../constants/Vectors';
import { useDispatch, useSelector } from 'react-redux';
import { userServices } from '../../../services/user/UserServices';
import UserSlice from '../../../redux/Features/User/UserSlice';
import { nanoid } from '@reduxjs/toolkit';
const NotificationsInfoScreen = () => {
    // const {
    //   user,
    //   profileVisits = [], 
    //   markVisitAsRead,
    //   markVisitAsUnread,
    //   friendNotifications = [], 
    //   markFriendNotificationAsRead,
    //   markFriendNotificationAsUnread,
    //   likeNotifications = [], 
    //   markLikeNotificationAsRead,
    //   markLikeNotificationAsUnread,
    //   activityLikeNotifications = [], 
    //   markActivityLikeNotificationAsRead,
    //   markActivityLikeNotificationAsUnread,
    // } = useUser();
    // FICHIER DE NOTIFICATIONS À TRAVAILLER EN PROFONDEUR
    const { user, profileVisits = [], friendNotifications = [], likeNotifications = [], activityLikeNotifications = [], } = useSelector((state) => state.user);
    const { userToken } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const markVisitAsRead = (visitId) => __awaiter(void 0, void 0, void 0, function* () {
        let marked = yield userServices.markVisitAsRead(visitId, user._id);
        if (marked)
            dispatch(UserSlice.actions.updateProfileVisits({ id: nanoid(), value: { visitId, isRead: true } }));
    });
    const markVisitAsUnread = (visitId) => __awaiter(void 0, void 0, void 0, function* () {
        let marked = yield userServices.markVisitAsUnread(visitId, user._id);
        if (marked)
            dispatch(UserSlice.actions.updateProfileVisits({ id: nanoid(), value: { visitId, isRead: false } }));
    });
    const markFriendNotificationAsRead = (notificationId) => __awaiter(void 0, void 0, void 0, function* () {
        let marked = yield userServices.markFriendNotificationAsRead(notificationId, user._id);
        if (marked)
            dispatch(UserSlice.actions.updateFriendNotifications({ id: nanoid(), value: { notificationId, isRead: true } }));
    });
    const markFriendNotificationAsUnread = (notificationId) => __awaiter(void 0, void 0, void 0, function* () {
        let marked = yield userServices.markFriendNotificationAsUnread(notificationId, user._id);
        if (marked)
            dispatch(UserSlice.actions.updateFriendNotifications({ id: nanoid(), value: { notificationId, isRead: false } }));
    });
    const markLikeNotificationAsRead = (notificationId) => __awaiter(void 0, void 0, void 0, function* () {
        let marked = yield userServices.markLikeNotificationAsRead(notificationId, user._id);
        if (marked)
            dispatch(UserSlice.actions.updateLikeNotifications({ id: nanoid(), value: { notificationId, isRead: true } }));
    });
    const markLikeNotificationAsUnread = (notificationId) => __awaiter(void 0, void 0, void 0, function* () {
        let marked = yield userServices.markLikeNotificationAsUnread(notificationId, user._id);
        if (marked)
            dispatch(UserSlice.actions.updateLikeNotifications({ id: nanoid(), value: { notificationId, isRead: false } }));
    });
    const markActivityLikeNotificationAsRead = (notificationId) => __awaiter(void 0, void 0, void 0, function* () {
        let marked = yield userServices.markActivityLikeNotificationAsRead(notificationId, user._id);
        if (marked)
            dispatch(UserSlice.actions.updateActivityLikeNotifications({ id: nanoid(), value: { notificationId, isRead: true } }));
    });
    const markActivityLikeNotificationAsUnread = (notificationId) => __awaiter(void 0, void 0, void 0, function* () {
        let marked = yield userServices.markActivityLikeNotificationAsUnread(notificationId, user._id);
        if (marked)
            dispatch(UserSlice.actions.updateActivityLikeNotifications({ id: nanoid(), value: { notificationId, isRead: false } }));
    });
    console.log("profileVisits", profileVisits);
    console.log("friendNotifications", friendNotifications);
    console.log("likeNotifications", likeNotifications);
    console.log("activityLikeNotifications", activityLikeNotifications);
    const [isMute, setIsMute] = useState(false);
    const settings = user === null || user === void 0 ? void 0 : user.notificationSettings;
    return (<>
      <ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
        {(settings === null || settings === void 0 ? void 0 : settings.visitorNotification) && profileVisits.map(visit => {
            var _a;
            return (<VisitedNotificationCard key={visit._id} visitId={visit._id} visitorUserName={((_a = visit.visitor) === null || _a === void 0 ? void 0 : _a.userName) || 'Utilisateur inconnu'} visitedAt={visit.visitedAt} isRead={visit.isRead} onRead={markVisitAsRead} onUnread={markVisitAsUnread} isMute={isMute}/>);
        })}
        {(settings === null || settings === void 0 ? void 0 : settings.friendRequest) && friendNotifications.map(notification => {
            var _a;
            return notification.friend ? (<FriendAddedNotificationCard key={notification._id} friendName={(_a = notification.friend) === null || _a === void 0 ? void 0 : _a.userName} friendId={notification.friend._id} isRead={notification.isRead} onRead={() => markFriendNotificationAsRead(notification._id)} onUnread={() => markFriendNotificationAsUnread(notification._id)} isMute={isMute}/>) : (<View key={notification._id} style={styles.notificationMissing}>
              <Text>Un ami précédemment ajouté a supprimé son compte.</Text>
            </View>);
        })}

        {(settings === null || settings === void 0 ? void 0 : settings.likeNotification) && likeNotifications.map(notification => {
            var _a;
            return (<LikedNotificationCard key={notification._id} likerUserName={(_a = notification.likedBy) === null || _a === void 0 ? void 0 : _a.userName} likedAt={notification.likedAt} isRead={notification.isRead} onRead={() => markLikeNotificationAsRead(notification._id)} onUnread={() => markLikeNotificationAsUnread(notification._id)} isMute={isMute} notificationId={notification._id}/>);
        })}
        {(settings === null || settings === void 0 ? void 0 : settings.activityLikeNotifications) && activityLikeNotifications.map(notification => {
            var _a;
            return (<ActivitiesLikeNotificationCard key={notification._id} notificationId={notification._id} likerUserName={(_a = notification.activityLike.likedBy) === null || _a === void 0 ? void 0 : _a.userName} likedAt={notification.likedAt} isRead={notification.isRead} onRead={() => markActivityLikeNotificationAsRead(notification._id)} onUnread={() => markActivityLikeNotificationAsUnread(notification._id)} isMute={isMute} activityTitle={notification._id}/>);
        })}
      </ScrollView>
      <TouchableOpacity style={styles.iconMute} onPress={() => setIsMute(!isMute)}>
        <View>
          <Vectors.NotificationsBellMute style={{ width: 50, height: 50 }}/>
        </View>
      </TouchableOpacity>
    </>);
};
const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white',
        minHeight: "100%",
    },
    notificationMissing: {
        padding: 10,
        backgroundColor: '#f2f2f2',
        marginVertical: 5,
        borderRadius: 5,
    },
    iconMute: {
        position: 'absolute',
        bottom: 20,
        right: 20,
        backgroundColor: 'white',
        width: 56,
        height: 56,
        borderRadius: 28,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 10,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 4
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        borderColor: '#00000080',
        borderWidth: 1,
    },
    // autres styles...
});
export default NotificationsInfoScreen;
