//🇫🇷 Le fichier JSON (front-mobile/assets/json) permet d'afficher les différentes variables avec différentes langues, voir le dossier src/json/En.json
//🇬🇧 The JSON file (front-mobile/assets/json) allowing you to display the different variables with different languages ​​is in the src/json/En.json folder
// 🇫🇷  Ce fichier gère la page de rédaction d'un mot de passe pour un nouveau compte (User) (FrameFigma006b)
// 🇬🇧 This file manage the page for writing a password for a new account (User). (FrameFigma006b)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { View, Text, Pressable, Linking, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import * as Yup from 'yup';
import Vectors from '../../../../constants/Vectors';
import { hostname } from '../../../../../hostName/hostName';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import { useError } from '../../../../contexts/ErrorContext';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const NewUserPasswordScreen = ({ navigation }) => {
    const { translations, language } = useSelector((state) => state.language);
    const { error, setError } = useError();
    /**
     * 🇬🇧 Validation schema using Yup to ensure password format and requirement.
     * 🇫🇷 Schéma de validation utilisant Yup pour garantir le format et l'exigence du mot de passe.
     */
    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(8, translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordErrorMessageTooShort)
            .required(translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordErrorMessageEmpty),
    });
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    useEffect(() => {
        getEmailFromStorage();
    }, []);
    /**
     * 🇬🇧 Retrieve email from storage and set it in the state.
     * 🇫🇷 Récupérer l'e-mail du stockage et le définir dans l'état.
     */
    const getEmailFromStorage = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const storedEmail = yield AsyncStorage.getItem('email');
            if (storedEmail) {
                setEmail(storedEmail);
            }
        }
        catch (error) {
            console.error("Erreur lors de la récupération de l'e-mail depuis le AsyncStorage", error);
        }
    });
    /**
     * 🇬🇧 Handle the 'Next' button press, validate the password, store it temporarily,
     * send a verification email, and navigate to the verification screen.
     * 🇫🇷 Gérer l'appui sur le bouton 'Suivant', valider le mot de passe, le stocker temporairement,
     * envoyer un e-mail de vérification et naviguer vers l'écran de vérification.
     */
    const handleNext = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // Validate the password against the schema
            yield validationSchema.validate({ password });
            /**
             * 🇬🇧 WARNING - The password is stored in plain text.
             * 🇫🇷 ATTENTION - Le mot de passe est stocké en texte brut.
             */
            yield AsyncStorage.setItem('password', password);
            // Call the function to send the verification email
            sendVerificationEmail();
            // Navigate to the email verification screen
            navigation.navigate('VerifyEmail');
            // Reset the password error
            setError('');
        }
        catch (validationError) {
            // Set the validation error message to the state
            setError(validationError.message);
        }
    });
    /**
     * 🇬🇧 Send verification email to the provided email address.
     * 🇫🇷 Envoyer un e-mail de vérification à l'adresse e-mail fournie.
     */
    const sendVerificationEmail = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield fetch(`${hostname}/sendVerification`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
        }
        catch (error) {
            console.error("Erreur lors de l'envoi de la demande de vérification par e-mail", error);
        }
    });
    /**
     * 🇬🇧 Handle password text change and reset error if any.
     * 🇫🇷 Gérer le changement de texte du mot de passe et réinitialiser le message d'erreur s'il y en a.
     */
    const handleTextChange = (text) => {
        setPassword(text);
        if (error) {
            setError('');
        }
    };
    return (<View key={language} style={styles.container}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <View style={styles.passwordContent}>
        <TextInputCustomComponent Icon={Vectors.ProfilesPasswordPadLock} placeholderText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPasswordPlaceholder} value={password} handleTextChange={handleTextChange} isPlaceHolderTextAlignCenter={false} isPassword={true}/>
      </View>
      <View style={styles.buttonContainer}>
          <ButtonPrevious onPrevStep={() => navigation.navigate('Sponsorship')} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
          <ButtonNext handleNextStep={handleNext} btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton} conditionNeed={password.length >= 8}/>
      </View>
      <View style={styles.condition}>
        <Text style={styles.terms}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonDisclaimer}</Text>
        <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
          <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonTermsAndConditions}</Text>
        </Pressable>
        <Pressable onPress={() => Linking.openURL('https://www.termsandconditionsgenerator.com/live.php?token=lkCADkefwST3eZ61BvL6lZv3ppyMd3An')}>
          <Text style={styles.underline}>{translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonPrivacyPolicy}</Text>
        </Pressable>
      </View>
    </View>);
};
export default NewUserPasswordScreen;
