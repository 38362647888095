// Fr  Styles de CreateActivityTopicScreen(FrameFigma036)
// En  Styles of CreateActivityTopicScreen(FrameFigma036)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    title: {
        fontWeight: 'bold',
        marginLeft: 20,
        fontSize: 18,
        marginTop: 25,
        marginBottom: 15,
    },
    info: {
        marginBottom: 5,
        paddingHorizontal: 20,
    },
    activityIconContainer: {
        width: 440,
        flexWrap: 'wrap',
        borderRadius: 30,
        marginTop: 15,
        flexDirection: 'row',
        justifyContent: 'center',
        alignSelf: 'center',
    },
    activityCard: {
        marginHorizontal: 8,
        marginVertical: 8,
        borderRadius: 10,
        width: 90,
        height: 100,
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#000000',
        shadowOffset: { width: 0, height: 4 },
        shadowOpacity: 0.25, // équivalent de 40% en opacité
        shadowRadius: 4,
        elevation: 4, // Android utilise `elevation` pour les ombres
    },
    activityTitle: {
        marginBottom: 5,
        fontSize: 12.5,
    },
    flexView: {
        flex: 1,
        justifyContent: 'center',
    },
});
