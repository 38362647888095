import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: 'white',
        borderRadius: 10,
        elevation: 5,
        width: '80%',
        alignItems: 'center',
        borderColor: 'black',
        borderWidth: 1,
    },
    date: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    scrollPicker: {
        width: 120,
        height: 180,
    },
    item: {
        height: 60,
        justifyContent: 'center',
        alignItems: 'center',
    },
    dateText: {
        fontSize: 18,
        textAlign: 'center',
    },
    selectedText: {
        color: '#59C09B',
        fontWeight: 'bold',
    },
    saveButton: {
        backgroundColor: '#59C09B',
        padding: 10,
        borderRadius: 5,
        alignItems: 'center',
        marginVertical: '5%',
    },
    saveButtonText: {
        color: 'white',
        fontSize: 16,
    },
    disabledItem: {
        opacity: 0.5,
    },
    disabledText: {
        color: 'gray',
    },
    birthdate: {
        flexDirection: 'row',
        alignItems: 'center',
        borderColor: 'black',
        borderWidth: 1,
        backgroundColor: 'white',
        borderRadius: 20,
        height: '100%',
        paddingHorizontal: 10,
    },
    logoBirthday: {
        width: 24,
        height: 24,
        marginRight: 10,
    },
    selectedDate: {
        fontSize: 16,
    },
});
export default styles;
