// SousSocketFront/handleUserInfoRequest.js
export const handleUserInfoRequest = (socket) => {
    const requestUserInfo = (userIds) => {
        socket.emit('requestUserInfo', { userIds });
    };
    const onUserInfoResponse = (callback) => {
        socket.on('userInfoResponse', callback);
    };
    const offUserInfoResponse = (callback) => {
        socket.off('userInfoResponse', callback);
    };
    return { requestUserInfo, onUserInfoResponse, offUserInfoResponse };
};
