import { Platform, Text, View } from 'react-native';
import React, { useEffect, useState } from 'react';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import Vectors from '../../../../constants/Vectors';
import { useError } from '../../../../contexts/ErrorContext';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const CreateActivityAmbassadorFeaturesScreen = ({ onNextStep, onPrevStep, setHeaderTitle }) => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const [whatsappGroupLink, setWhatsappGroupLink] = useState('');
    const [facebookGroupLink, setFacebookGroupLink] = useState('');
    const [facebookLink, setFacebookLink] = useState('');
    const [meetupLink, setMeetupLink] = useState('');
    const [telegramLink, setTelegramLink] = useState('');
    const [blogLink, setBlogLink] = useState('');
    const { error, setError } = useError();
    const urlRegex = /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[^\s]{2,}|www\.[^\s]+\.[^\s]{2,})$/;
    useEffect(() => {
        setHeaderTitle('Ambassador features');
    }, []);
    const handleTextChange = (name, value) => {
        switch (name) {
            case 'Whatsapp':
                setWhatsappGroupLink(value);
                break;
            case 'Facebook Group':
                setFacebookGroupLink(value);
                break;
            case 'Facebook':
                setFacebookLink(value);
                break;
            case 'Meetup':
                setMeetupLink(value);
                break;
            case 'Telegram':
                setTelegramLink(value);
                break;
            case 'Blog':
                setBlogLink(value);
                break;
            default:
                break;
        }
        setInputData(prevInputData => prevInputData.map(input => (input.name === name ? Object.assign(Object.assign({}, input), { value: value, errorInput: value !== '' && !urlRegex.test(value) }) : input)));
    };
    const [inputData, setInputData] = useState([
        {
            icon: Vectors.Whatsapp,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonWhatsapp,
            value: whatsappGroupLink,
            handleTextChange: (value) => handleTextChange('Whatsapp', value),
            errorInput: false,
            name: 'Whatsapp',
        },
        {
            icon: Vectors.SocialNetworksFbGroup,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonFbGroup,
            value: facebookGroupLink,
            handleTextChange: (value) => handleTextChange('Facebook Group', value),
            errorInput: false,
            name: 'Facebook Group',
        },
        {
            icon: Vectors.SocialNetworksFbPage,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonFbPage,
            value: facebookLink,
            handleTextChange: (value) => handleTextChange('Facebook', value),
            errorInput: false,
            name: 'Facebook',
        },
        {
            icon: Vectors.SocialNetworksMeetup,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonMeetup,
            value: meetupLink,
            handleTextChange: (value) => handleTextChange('Meetup', value),
            errorInput: false,
            name: 'Meetup',
        },
        {
            icon: Vectors.SocialNetworksTelegram,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonTelegram,
            value: telegramLink,
            handleTextChange: (value) => handleTextChange('Telegram', value),
            errorInput: false,
            name: 'Telegram',
        },
        {
            icon: Vectors.SocialNetworksOtherLink,
            placeholderText: translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonOtherLink,
            value: blogLink,
            handleTextChange: (value) => handleTextChange('Blog', value),
            errorInput: false,
            name: 'Blog',
        },
    ]);
    const handleNextStep = () => {
        const hasError = inputData.some(input => input.value !== '' && !urlRegex.test(input.value));
        const errorMessage = hasError
            ? translations.JsonActivityPages.JsonActivityManagement.JsonStandardActivityChooseAddressScreen.JsonOnlineErrorMessage
            : '';
        setError(errorMessage);
        if (!hasError) {
            onNextStep();
        }
    };
    useEffect(() => {
        setError('');
    }, [whatsappGroupLink, facebookGroupLink, facebookLink, meetupLink, telegramLink, blogLink]);
    return (<View>
      {error && (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>)}
      <View style={styles.container}>
        <Text style={styles.textDescription}>
          {translations.JsonActivityPages.JsonActivityManagement.JsonAdminAndPremiumAccountAdditionalFeatures.JsonOptionalFeatures}
        </Text>
        <View style={styles.inputContainer}>
          {inputData.map((input, i) => (<TextInputCustomComponent Icon={input.icon} placeholderText={input.placeholderText} isPlaceHolderTextAlignCenter value={input.value} handleTextChange={input.handleTextChange} key={i} errorInput={input.errorInput}/>))}
        </View>
        <View style={styles.btnContainer}>
          <ButtonPrevious onPrevStep={onPrevStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
          <ButtonNext handleNextStep={handleNextStep} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonContinueButton} conditionNeed={whatsappGroupLink != '' ||
            facebookGroupLink != '' ||
            facebookLink != '' ||
            meetupLink != '' ||
            telegramLink != '' ||
            blogLink != '' ||
            true}/>
        </View>
      </View>
    </View>);
};
export default CreateActivityAmbassadorFeaturesScreen;
