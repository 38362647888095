import { StyleSheet } from "react-native";
const constStyle = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#EEEEEE',
        paddingTop: 40,
    },
    logoContainer: {
        alignSelf: 'center',
    },
    emoji: {
        fontSize: 80,
        marginBottom: 50,
    },
    element: {
        flexDirection: 'row',
        width: 360,
        marginHorizontal: 10,
        backgroundColor: '#f0f8ff',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 10,
        elevation: 1,
        marginBottom: 15,
    },
    elementVectors: {
        width: 48,
        height: 48,
    },
    elementTitle: {
        marginLeft: '10%',
        fontWeight: 'bold',
        fontSize: 14,
        marginRight: '10%',
    },
    elementText: {
        fontWeight: 'normal',
    }
});
export default constStyle;
