//FR  permet d'inviter des amis via Instagram
//EN allows users to invite friends via Instagram
import React from 'react';
import { View, Text, Pressable, Linking } from 'react-native';
import { useSelector } from 'react-redux';
const InviteByInstagram = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const inviteByInstagram = () => {
        const instagramLink = 'instagram://app';
        Linking.openURL(instagramLink);
    };
    return (<View>
      <Pressable onPress={inviteByInstagram}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteInstagram}</Text>
      </Pressable>
    </View>);
};
export default InviteByInstagram;
