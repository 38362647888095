var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Platform } from "react-native";
import Geolocation from '@react-native-community/geolocation';
export const getCurrentLocation = (...args_1) => __awaiter(void 0, [...args_1], void 0, function* (type = 'exact') {
    let location = undefined;
    if (Platform.OS === 'web' && navigator.geolocation) {
        navigator.geolocation.getCurrentPosition((position) => __awaiter(void 0, void 0, void 0, function* () {
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;
            if (type === 'approximate') {
                latitude = latitude + (Math.random() - 0.5) * 0.02; //1.57Km +=
                longitude = longitude + (Math.random() - 0.5) * 0.02;
            }
            // console.log("location data ", { latitude, longitude, type })
            location = { location: { latitude, longitude }, locationType: type };
        }), (error) => {
            console.error('Error getting location:', error);
            location = null;
        });
    }
    else if (Platform.OS !== 'web') {
        Geolocation.getCurrentPosition((position) => __awaiter(void 0, void 0, void 0, function* () {
            let latitude = position.coords.latitude;
            let longitude = position.coords.longitude;
            if (type === 'approximate') {
                latitude = latitude + (Math.random() - 0.5) * 0.02;
                longitude = longitude + (Math.random() - 0.5) * 0.02;
            }
            // console.log("location data mobile", { latitude, longitude, type })
            location = { location: { latitude, longitude }, locationType: type };
        }), (error) => {
            console.error('Error getting location:', error);
            location = null;
        }, { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 });
    }
    else {
        console.error('Geolocation not supported');
        location = null;
    }
    console.log("start sleepping");
    yield sleep(10000);
    console.log("end sleeping");
    return location;
});
const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay));
