var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { View, Text, Platform, Animated } from 'react-native';
import MyActivityCard from '../../../../components/CardSection/MyActivityCard/Code';
import { useFocusEffect } from '@react-navigation/native';
import { formatDate, ActivitiesWithDate } from '../../../../utils/Functions/functions';
import { ActivityLikeServices } from '../../../../services/activities/ActivityLikeServices';
import { userServices } from '../../../../services/user/UserServices';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
{ }
const PastScreen = ({ route }) => {
    const [activities, setActivities] = useState([]);
    const [likedActivities, setLikedActivities] = useState([]);
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const [userId, setUserId] = useState(null); // Ajouter l'état pour userId
    const { user } = useSelector((state) => state.user);
    const activitiesStore = useSelector((state) => state.activities);
    const [loading, setLoading] = useState(true);
    const { isDisplayBannerVisible, setIsDisplayBannerVisible } = route.params;
    const scrollY = useRef(new Animated.Value(0)).current;
    useFocusEffect(useCallback(() => {
        setIsDisplayBannerVisible(true);
    }, []));
    useEffect(() => {
        const listener = scrollY.addListener(({ value }) => {
            if (value > 0) {
                setIsDisplayBannerVisible(false);
            }
            else {
                setIsDisplayBannerVisible(true);
            }
        });
        return () => {
            scrollY.removeListener(listener);
        };
    }, [scrollY]);
    const fetchActivities = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const userId = user._id;
            ;
            if (!userId) {
                console.error("User ID is null or undefined.");
                return;
            }
            setUserId(userId);
            const MyProfilUser = yield userServices.getUserByFilter(yield (yield userServices.getUsers()).data, user._id);
            // const PastlikedActivities = await (await ActivityServices.getActivities()).data.filter(( activity :any ) => MyProfilUser.activityLikes.includes(activity._id) && new Date(activity.date) <= new Date())
            const PastlikedActivities = activitiesStore.activities.filter((activity) => user.activityLikes.includes(activity._id) && new Date(activity.date) <= new Date());
            const users = yield userServices.getUsers();
            const myProfileUser = yield userServices.getUserByFilter(users.data, userId);
            // Ensure `activityLikes` is defined and is an array
            const activityLikes = Array.isArray(myProfileUser === null || myProfileUser === void 0 ? void 0 : myProfileUser.activityLikes) ? myProfileUser.activityLikes : [];
            const pastLikedActivities = activitiesStore.activities.filter((activity) => activityLikes.includes(activity._id) && new Date(activity.date) <= new Date());
            // const response = await ActivityServices.getActivities();
            const liked = yield ActivityLikeServices.getUserLikedActivities();
            const myPastActivitiesAttendeesAndWaitingList = activitiesStore.activities.filter((activity) => {
                return (activity.attendees.includes(userId) || activity.waitingList.includes(userId)) && new Date(activity.date) <= new Date();
            });
            // Combine and remove duplicates
            const combinedArray = [...myPastActivitiesAttendeesAndWaitingList, ...pastLikedActivities];
            const uniqueActivities = Array.from(new Set(combinedArray.map(activity => activity._id)))
                .map(id => combinedArray.find(activity => activity._id === id));
            setActivities(uniqueActivities);
        }
        catch (error) {
            if (error instanceof TypeError && error.message === 'Network request failed') {
                console.error("Network request failed:", error);
            }
            else {
                console.error("Error fetching activities:", error);
            }
        }
    });
    useFocusEffect(useCallback(() => {
        console.log('useFocusEffect');
        fetchActivities();
        return () => {
            fetchActivities();
        };
    }, [activities.length]));
    const groupedActivities = useMemo(() => {
        const grouped = new Map();
        activities.forEach(activity => ActivitiesWithDate(activity, grouped));
        const sortedGroupedActivities = Array.from(grouped.entries()).sort(([date1], [date2]) => {
            return new Date(date1).valueOf() - new Date(date2).valueOf();
        });
        sortedGroupedActivities.forEach(([date, activities]) => {
            activities.sort((a, b) => {
                const startTimeA = a.startTime.hour * 60 + a.startTime.minute;
                const startTimeB = b.startTime.hour * 60 + b.startTime.minute;
                return startTimeA - startTimeB;
            });
        });
        return new Map(sortedGroupedActivities);
    }, [activities]);
    return (<View style={styles.colorBackroundWhite}>
      <Animated.ScrollView onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], { useNativeDriver: false })} showsVerticalScrollIndicator={false}>
        {groupedActivities.size > 0 ? (Array.from(groupedActivities.entries()).map(([date, activities]) => (<View key={date} data-date={date}>
              <Text style={styles.DisplayDate}>{formatDate(date)}</Text>
              {activities.map((activity) => (<View key={activity._id ? activity._id.toString() : activity.date.toString()}>
                  <MyActivityCard activity={activity} userId={userId}/>
                </View>))}
            </View>))) : (<View style={styles.textTwoContainer}>
            <Text style={styles.textTwo}>
              {translations.JsonActivityPages.JsonMyActivitiesScreen.JsonPastActivityEmpty}
            </Text>
          </View>)}
      </Animated.ScrollView>
    </View>);
};
export default PastScreen;
