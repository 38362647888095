var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState } from 'react';
import { View, StyleSheet, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import MyButtonPress from '../PressButton/Code';
// Import Modals
import MyModal from '../../ModalPopUpModule/ModalSection/ActivityDetailsModal/MyModal';
import ActivityDeleteModal from '../../ModalPopUpModule/ModalSection/ActivityDeleteModal/Code';
// Import services
// import { ActivityServices } from '../../../../services/activities/ActivityServices';
// Import functions
// import { trashActivity } from '../../../utils/Activities/Activity/ActivityCRUD';
// Import vectors (icons)
import Vectors from '../../../constants/Vectors';
// Import contexts
import { useError } from '../../../contexts/ErrorContext';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
import { ActivityServices } from '../../../services/activities/ActivityServices';
const ActivityDetailsButtonsBottomComponent = ({ id, disabled = false }) => {
    const navigation = useNavigation();
    // const {
    //     author,
    //     activityView,
    //     setCurrentActivity,
    //     setCurrentActivityDate,
    //     setCurrentActivitySwitch,
    // } = useActivity(); 
    // const { user } = useUser();
    const { user } = useSelector((state) => state.user);
    const { setError } = useError();
    const { author, activityView } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setCurrentActivity = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivity({ id: nanoid(), value: data }));
    };
    const setCurrentActivityDate = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivityDate({ id: nanoid(), value: data }));
    };
    const setCurrentActivitySwitch = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivitySwitch({ id: nanoid(), value: data }));
    };
    const [modalVisible, setModalVisible] = useState(false);
    const handleCopyToCreateActivity = () => {
        try {
            let { _id, coOrganizerRequests, coOrganizerOffers, coOrganizers, interested, likes, isLiked, unApprovedUsers, unApprovedCoOrganizers, waitingList, comments, ages, optionalFeaturesOptions, peopleLanguageOptions, repeatEventDays } = activityView, endRest = __rest(activityView, ["_id", "coOrganizerRequests", "coOrganizerOffers", "coOrganizers", "interested", "likes", "isLiked", "unApprovedUsers", "unApprovedCoOrganizers", "waitingList", "comments", "ages", "optionalFeaturesOptions", "peopleLanguageOptions", "repeatEventDays"]);
            setCurrentActivity(Object.assign(Object.assign({}, endRest), { author: user._id, attendees: [user._id], date: '', startTime: '', endTime: null, coOrganizerConditions: '', coOrganizerGifts: '' }));
            setCurrentActivitySwitch({
                butLoc: activityView.infoLine
                    ? 'Online'
                    : activityView.myPosition
                        ? 'My GPS Position'
                        : activityView.location.address
                            ? 'Address'
                            : activityView.googleUrl
                                ? 'with link Url'
                                : 'Edit activity',
                switchBtn: false,
                switchBtn1: !!activityView.addressOnlyForAttendees,
                switchBtn2: !!activityView.inviteCommunity,
                switchMetroBtn: !!activityView.metroStation,
                conditionNeedLoc: true,
                switchBtn3: !!(activityView.notifyPreviousAttendees || (activityView === null || activityView === void 0 ? void 0 : activityView.inviteMore)),
                switchBtn4: !!activityView.notifyPreviousAttendees,
                switchBtn5: !!activityView.inviteMore,
                switchBtn6: false,
                price: !!activityView.hasPrice,
                switchBtnUnlimitted: activityView.attendeeLimit === 1000000 ? true : !activityView.attendeeLimit,
            });
            navigation.navigate('CopyActivity');
        }
        catch (error) {
            console.error(error);
            // setError(error);
        }
    };
    const handleEditActivity = () => __awaiter(void 0, void 0, void 0, function* () {
        let rest = activityView;
        if (!activityView.coOrganizers.length ||
            !activityView.unApprovedUsers.length ||
            !activityView.unApprovedCoOrganizers.length ||
            !activityView.waitingList.length ||
            !activityView.likes.length ||
            !activityView.comments.length ||
            !activityView.interested.length) {
            if (!activityView.coOrganizers.length) {
                let { coOrganizers } = rest, newRest = __rest(rest, ["coOrganizers"]);
                rest = newRest;
            }
            if (!activityView.unApprovedUsers.length) {
                let { unApprovedUsers } = rest, newRest = __rest(rest, ["unApprovedUsers"]);
                rest = newRest;
            }
            if (!activityView.unApprovedCoOrganizers.length) {
                let { unApprovedCoOrganizers } = rest, newRest = __rest(rest, ["unApprovedCoOrganizers"]);
                rest = newRest;
            }
            if (!activityView.waitingList.length) {
                let { waitingList } = rest, newRest = __rest(rest, ["waitingList"]);
                rest = newRest;
            }
            if (!activityView.likes.length) {
                let { likes } = rest, newRest = __rest(rest, ["likes"]);
                rest = newRest;
            }
            if (!activityView.comments.length) {
                let { comments } = rest, newRest = __rest(rest, ["comments"]);
                rest = newRest;
            }
            if (!activityView.interested.length) {
                let { interested } = rest, newRest = __rest(rest, ["interested"]);
                rest = newRest;
            }
            let updatedActivity = rest;
            let { coOrganizerRequests, coOrganizerOffers, isLiked, ages, optionalFeaturesOptions, peopleLanguageOptions, repeatEventDays } = updatedActivity, endRest = __rest(updatedActivity, ["coOrganizerRequests", "coOrganizerOffers", "isLiked", "ages", "optionalFeaturesOptions", "peopleLanguageOptions", "repeatEventDays"]);
            setCurrentActivity(Object.assign({}, endRest));
        }
        else {
            let updatedActivity = activityView;
            let { coOrganizerRequests, coOrganizerOffers, isLiked } = updatedActivity, endRest = __rest(updatedActivity, ["coOrganizerRequests", "coOrganizerOffers", "isLiked"]);
            setCurrentActivity(Object.assign({}, endRest));
        }
        setCurrentActivityDate({
            selectedDate: activityView.date,
            switchBtn: !!activityView.endTime,
        });
        setCurrentActivitySwitch({
            butLoc: activityView.infoLine
                ? 'Online'
                : activityView.myPosition
                    ? 'My GPS Position'
                    : activityView.location.address
                        ? 'Address'
                        : activityView.googleUrl
                            ? 'with link Url'
                            : 'Edit activity',
            switchBtn: !!activityView.endTime,
            switchBtn1: !!activityView.addressOnlyForAttendees,
            switchBtn2: !!activityView.inviteCommunity,
            switchMetroBtn: !!activityView.metroStation,
            conditionNeedLoc: true,
            switchBtn3: !!(activityView.notifyPreviousAttendees || (activityView === null || activityView === void 0 ? void 0 : activityView.inviteMore) || activityView.requestCoOrga),
            switchBtn4: !!activityView.notifyPreviousAttendees,
            switchBtn5: !!activityView.inviteMore,
            switchBtn6: !!activityView.requestCoOrga,
            price: !!activityView.hasPrice,
            switchBtnUnlimitted: activityView.attendeeLimit === 1000000 ? true : !activityView.attendeeLimit,
        });
        navigation.navigate('EditActivity');
    });
    const trashActivity = (activityId) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield ActivityServices.deleteActivity(activityId);
            navigation.navigate('Activities');
        }
        catch (error) {
            console.error('Error deleting activity:', error);
        }
    });
    return (<>
            <MyModal modalVisible={modalVisible} onClose={() => setModalVisible(false)} w={400}>
                <ActivityDeleteModal setModalVisible={setModalVisible} deleteAct={() => trashActivity(activityView._id)}/>
            </MyModal>
            {author._id !== id ? (<View style={styles.ButtonsContainer}>
                    <MyButtonPress disabled={activityView.isPassed} text="Co Organize" txtCol="white" txtS={20} txtFW="bold" w={190} h={47} bgC={disabled ? 'grey' : '#FFA113'} br={10} cb={() => alert('Invite')}/>
                    <MyButtonPress text="Duplicate" txtCol="white" txtS={20} txtFW="bold" w={190} h={47} bgC="#59c09b" br={10} cb={handleCopyToCreateActivity}/>
                    {activityView.hasPrice && (<MyButtonPress disabled={activityView.isPassed} text="Buy Tickets" txtCol="black" txtS={20} txtFW="bold" w={190} h={47} bgC={disabled ? 'grey' : 'yellow'} br={10} cb={() => Linking.openURL(activityView.buyTicketsLink)}/>)}
                </View>) : (<View style={styles.ButtonsContainer}>
                    <MyButtonPress disabled={activityView.isPassed} text="Edit" txtCol="white" txtS={20} txtFW="bold" w={190} h={47} bgC={disabled ? 'grey' : 'black'} br={10} cb={handleEditActivity} Icon={Vectors.ProfilesEdit}/>
                    <MyButtonPress text="Duplicate" txtCol="white" txtS={20} txtFW="bold" w={190} h={47} bgC="#59c09b" br={10} cb={handleCopyToCreateActivity}/>
                    {activityView.hasPrice && (<MyButtonPress disabled={activityView.isPassed} text="Buy Tickets" txtCol="black" txtS={20} txtFW="bold" w={190} h={47} bgC={disabled ? 'grey' : 'yellow'} br={10} cb={() => Linking.openURL(activityView.buyTicketsLink)}/>)}
                    <MyButtonPress disabled={activityView.isPassed} text="Delete" txtCol="white" txtS={20} txtFW="bold" w={190} h={47} bgC={disabled ? 'grey' : 'red'} br={10} cb={() => setModalVisible(true)} Icon={Vectors.SettingsDeleteAccount}/>
                </View>)}
        </>);
};
export default ActivityDetailsButtonsBottomComponent;
const styles = StyleSheet.create({
    ButtonsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: 10,
        justifyContent: 'space-evenly',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
    },
});
