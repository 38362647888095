var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { View, FlatList, Image, Text, Pressable } from 'react-native';
import { useFocusEffect, useNavigation } from '@react-navigation/native';
// Import contexts
// Import Services
import { ParticipantsServices } from '../../../services/activities/ParticipantsServices';
import { authorServices } from '../../../services/activities/auhorServices';
// Import Vectors
import Vectors from '../../../constants/Vectors';
import OnlineStatus from '../../GeneralSection/NotificationBulles/OnlineStatus';
import { useDispatch, useSelector } from 'react-redux';
import { userServices } from '../../../services/user/UserServices';
import UserSlice from '../../../redux/Features/User/UserSlice';
import { nanoid } from '@reduxjs/toolkit';
// Import Styles
const styles = require('./StylesWeb').default;
const ActivityDetailsScrollBarParticipants = () => {
    const navigation = useNavigation();
    const { allUsers } = useSelector((state) => state.user);
    const { getAllUsers } = userServices;
    // const { activityView } = useActivity();
    const { activityView } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const isOnline = (item) => {
        return item.status === 'online';
    };
    useFocusEffect(React.useCallback(() => {
        fetchAllUsers();
    }, []));
    const fetchAllUsers = () => __awaiter(void 0, void 0, void 0, function* () {
        const all = yield getAllUsers();
        if (all)
            dispatch(UserSlice.actions.setAllUsers({ id: nanoid(), value: all }));
    });
    const participants = ParticipantsServices.getParticipants(activityView, allUsers);
    // Ensure that participants is an array of Participant objects
    const validParticipants = Array.isArray(participants) && participants.every(participant => typeof participant === 'object')
        ? participants
        : [];
    return (<View style={styles.container}>
            <View style={styles.containerParticipants}>
                <View style={styles.containerScrollBarParticipants}>
                    <View style={styles.leftArrow}>
                        <Vectors.RightArrow />
                    </View>
                    <FlatList contentContainerStyle={styles.containerParticipantsStatus} data={validParticipants} horizontal={true} renderItem={({ item }) => (item.firstName ? (<Pressable style={styles.participantImage} onPress={() => navigation.navigate('ProfileScreen', { userId: item._id, status: isOnline(item) })}>
                                    <Image style={[styles.participantImage, { borderWidth: 2, borderColor: authorServices.isAuthor(activityView.author, item._id) ? '#05FF00' : 'grey' }]} source={{ uri: item.profileImage }}/>
                                    {item.status === 'online' ? <OnlineStatus host={authorServices.isAuthor(activityView.author, item._id)} left={34} deg='48'/> : null}
                                </Pressable>) : <Text>{item.firstName}</Text>)} keyExtractor={item => item._id}/>
                    <View style={styles.rightArrow}>
                        <Vectors.RightArrow />
                    </View>
                </View>
                <View style={styles.containerParticipantsNumber}>
                    <Pressable onPress={() => navigation.navigate('Participants')}>
                        <View style={styles.logoParticipants}>
                            <Vectors.UsersInteractionBlackAndWhiteParticipants />
                        </View>
                    </Pressable>
                    <Text style={styles.numberOfParticipants}>
                        {activityView.attendeeLimit ? activityView.attendeeLimit === 1000000
            ? activityView.attendees.length : activityView.attendees.length + '/' + activityView.attendeeLimit
            : activityView.attendees.length}
                        {/* {activityView.attendees.length} {activityView.attendeeLimit && " / " + activityView.attendeeLimit} */}
                    </Text>
                </View>
            </View>
            <View style={styles.containerParticipantsDataForActivity}>
                <View style={styles.containerInterestedParticipants}>
                    <Text style={styles.DataForActivityText}>Interested :</Text>
                    <View style={styles.participantsInterestedForActivity}>
                        <Text style={styles.dataInterestedNumbers}>{activityView.interested.length}</Text>
                    </View>
                </View>
                <View style={styles.containerActivityFollowers}>
                    <Text style={styles.DataForActivityText}>followers : </Text>
                    <View style={styles.emptyHeart}>
                        <Vectors.UsersInteractionColorsHeartEmpty />
                        <Text style={styles.emptyHeartText}>{activityView.likes.length}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.separator}/>
        </View>);
};
export default ActivityDetailsScrollBarParticipants;
