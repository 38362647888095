// FR (FrameFigma031,FrameFigma032A,FrameFigma035A,FrameFigma035B,FrameFigma033,FrameFigma038A,FrameFigma038B,FrameFigma039)
import { View, Text, Platform } from 'react-native';
import React, { useEffect } from 'react';
import Slider from '@react-native-community/slider';
// import VectorsOnOff from '../../components/VectorsOnOff/VectorsOnOff';
import Vectors from '../../../constants/Vectors';
import { Slider as SliderRange } from '@miblanchard/react-native-slider';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const SliderComponent = ({ min, max, sliderValue, setSliderValue, type = '', translations, callBack }) => {
    useEffect(() => {
        console.log('sliderValue', sliderValue);
    }, [sliderValue]);
    return type === '' ? (<View style={styles.container}>
      <Slider style={styles.slider} minimumValue={min} maximumValue={max} value={sliderValue} onValueChange={(value) => {
            if (isNaN(value)) {
                setSliderValue(0);
                callBack && callBack(Math.round(0));
            }
            else {
                setSliderValue(value);
                callBack && callBack(Math.round(value));
            }
        }}/>
      {/* pour deplacer les valeur min et max en ligne suivant, pour mieux correpondant a figma */}
      <View style={styles.valueContainer}>
        <Text style={styles.label}>{min}</Text>
        <Text style={styles.label}>{max}</Text>
      </View>

      <View style={styles.sliderValueContainer}>
        {/* Avant d'afficher sliderValue, assurez-vous qu'il s'agit d'un nombre valide */}
        <Text style={styles.sliderValue}>
          {isNaN(Math.round(Number(sliderValue))) ? 0 : Math.round(Number(sliderValue))}
        </Text>
      </View>
    </View>) : type === 'parity' ? (<View style={styles.containerForParity}>
      <Slider style={styles.slider} minimumValue={min} maximumValue={max} value={sliderValue} onValueChange={value => setSliderValue(value)} thumbTintColor="#A5CCF2" minimumTrackTintColor="#A5CCF2" maximumTrackTintColor="#FF4181"/>
      {/* pour deplacer les valeur min et max en ligne suivant, pour mieux correpondant a figma */}
      <View style={styles.valueContainer}>
        <Text style={styles.label}>{min}%</Text>
        <Text style={styles.label}>{max}%</Text>
      </View>

      <View style={styles.VectorsContainerTop}>
        <Vectors.ProfilesMaleSign />
        <Vectors.ProfilesFemaleSign />
      </View>

      <View style={styles.sliderValueContainerTop}>
        <Text style={styles.sliderValue}>{Math.round(sliderValue)}%</Text>
      </View>
    </View>) : (<View style={styles.container}>
      <SliderRange containerStyle={styles.slider} maximumTrackTintColor="#d3d3d3" maximumValue={max} minimumTrackTintColor="#1fb28a" minimumValue={min} value={sliderValue} onValueChange={(value) => setSliderValue(value)} thumbTintColor="#1a9274"/>
      {/* pour deplacer les valeur min et max en ligne suivant, pour mieux correpondant a figma */}
      <View style={styles.valueContainer}>
        <Text style={styles.label}>{min}</Text>
        <Text style={styles.label}>
          {max} {translations && translations.JsonProfilePages.JsonMyProfileScreen.JsonYears}
        </Text>
      </View>

      <View style={styles.sliderValueContainerAge}>
        <Text style={styles.sliderValue}>
          {Math.round(sliderValue)}-{Math.round(sliderValue)}
        </Text>
      </View>
    </View>);
};
export default SliderComponent;
