// FR rend la partie supérieure de l'écran de profil utilisateur
// EN renders the top part of the user profile screen
import React from 'react';
import { View, ImageBackground, Text, TouchableOpacity, Platform } from 'react-native';
import { Vectors } from '../../../../constants/Vectors';
import ProfileMedal from '../../../../components/ProfileSection/ProfileMedal/Code';
import { calculateUserPoints } from '../../../../utils/Functions/UserPointsCalculator';
import { useSelector } from 'react-redux';
const MyProfileTopScreen = ({ userId, user, profileOwner, navigation, status }) => {
    var _a;
    const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const userPoints = calculateUserPoints(userId || (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner._id));
    const calculYears = (dateOfBirth) => {
        const today = new Date();
        const birthDate = new Date(dateOfBirth);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    };
    return (<View style={styles.container}>
      <ImageBackground source={{ uri: profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.profileImage }} style={styles.profileImage}>
        <TouchableOpacity onPress={() => navigation.navigate('EditProfile', { userData: user })} style={styles.editButton}>
          <Vectors.ProfilesEdit width={24} height={24} style={styles.editIcon}/>
          <Text style={styles.editButtonText}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonTitleEditProfile}</Text>
        </TouchableOpacity>
      </ImageBackground>
      <View style={styles.cupIconContainer}>
        <View style={styles.cupIcon}>
          <ProfileMedal role={(profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.role) === 'diamondUser' ? 'diamondUser' :
            (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.role) === 'emeraldUser' ? 'emeraldUser' :
                (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.role) === 'rubyUser' ? 'rubyUser' :
                    (profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.role) === 'goldUser' ? 'goldUser' :
                        'basicUser'} points={userPoints} isAmbassador={false} size={54}/>
        </View>
        <Text style={styles.pointsTextBelowCup}>
          {userPoints} {translations.JsonProfilePages.JsonMyProfileScreen.JsonPoints}
        </Text>
      </View>
      <View style={styles.infosContainer}>
        <Text style={styles.info}>Organizer: ⭐⭐⭐⭐⭐</Text>
        <Text style={styles.info}>{translations.JsonProfilePages.JsonMyProfileScreen.JsonReliability}: -------------</Text>
      </View>
      <View style={styles.infosContainer2}>
        <Text style={styles.userName}>{profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.userName}</Text>
        {!!(profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.useMyAge) ? (<Text style={styles.info2Text}>
            {calculYears(profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.dateOfBirth)} {translations.JsonProfilePages.JsonMyProfileScreen.JsonYears}
          </Text>) : (null)}
        <View style={styles.infosCityRow}>
          <Vectors.PinBlackAndWhite style={styles.Location} width={24} height={24}/>
          <Text style={styles.text}>{(_a = profileOwner === null || profileOwner === void 0 ? void 0 : profileOwner.city) === null || _a === void 0 ? void 0 : _a.split(',')[0]}</Text>
        </View>
      </View>
    </View>);
};
export default MyProfileTopScreen;
