import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        paddingTop: 40,
        backgroundColor: 'white',
    },
    textContainer: {
        gap: 25,
        alignItems: 'center',
        marginBottom: 40,
        paddingHorizontal: '8%',
    },
    bold: {
        fontWeight: 'bold',
        fontSize: 16,
    },
    textNormal: {
        textAlign: 'center',
        fontSize: 16,
    },
    deleteCancelButtonRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: 15,
        marginVertical: 5,
        paddingHorizontal: '10%'
    },
    cancelButton: {
        flex: 1,
        borderRadius: 3,
        minHeight: 42,
        borderWidth: 1,
        borderColor: '#404040',
        justifyContent: 'center',
        alignItems: 'center',
    },
    deleteButton: {
        flex: 1,
        borderRadius: 3,
        minHeight: 42,
        borderWidth: 1,
        borderColor: '#404040',
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonBigSize: {
        fontSize: 18
    },
    btnSelectedRed: {
        backgroundColor: '#FF0000',
        borderColor: '#FF0000',
    },
    buttonTextWhite: {
        color: 'white',
    },
});
export default styles;
