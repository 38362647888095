import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        marginTop: 10,
        alignItems: 'center',
    },
    text: {
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
        color: 'black',
        fontFamily: 'poppins',
        fontWeight: 'bold',
        fontSize: 18,
    },
    ribbon: {
        height: 40,
        backgroundColor: '#C4C4C480',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.8,
        shadowRadius: 2,
        elevation: 5,
    },
    borderTriangle: {
        position: 'absolute',
        top: '100%',
        marginTop: -40,
        borderTopWidth: 20,
        borderTopColor: '#C4C4C480',
        borderBottomWidth: 20,
        borderBottomColor: '#C4C4C480',
    },
    leftBorderTriangle: {
        left: -20,
        borderLeftWidth: 20,
        borderLeftColor: 'transparent',
    },
    rightBorderTriangle: {
        right: -20,
        borderRightWidth: 20,
        borderRightColor: 'transparent',
    },
});
export default styles;
