import { createSlice } from '@reduxjs/toolkit';
import { translationsMap } from './Languages';
;
const initialState = {
    language: 'en',
    bigram: 'En',
    translations: translationsMap.En,
};
const LanguageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        changeLanguage: (state, action) => {
            state.bigram = action.payload.value;
            state.translations = translationsMap[action.payload.value];
        }
    }
});
export default LanguageSlice;
