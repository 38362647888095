import { StyleSheet } from "react-native";
// Créer les styles en utilisant StyleSheet.create et les types définis
const styles = StyleSheet.create({
    confirmButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 20,
        backgroundColor: '#6f6f6f',
        borderRadius: 10,
    },
    buttonText: {
        color: '#ffffff',
        fontSize: 14,
        fontWeight: 'bold',
        marginRight: 5,
    },
    inputContainer: {
        width: '100%', // Ensures that the input container takes the full width to align labels to the left
    },
    input: {
        borderWidth: 1,
        borderColor: '#cccccc',
        padding: 10,
        borderRadius: 15,
    },
    iconRow: {
        alignSelf: 'flex-start',
        width: '50%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    modalTitle: {
        fontSize: 20,
        fontWeight: 'bold',
    }
});
export default styles;
