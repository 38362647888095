var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//FR Il affiche une carte avec des marqueurs représentant les emplacements des utilisateurs pour le mobile
//EN designed for mobile applications. It displays a map with markers representing the locations of users
import React, { useEffect, useState } from 'react';
import { View, PermissionsAndroid, Platform, Dimensions, Image } from 'react-native';
import Geolocation from '@react-native-community/geolocation';
import MapView, { Marker } from 'react-native-maps';
import { useSelector } from 'react-redux';
const MapsMobileUser = () => {
    // const { user, allUsers } = useUser();
    const { user, allUsers } = useSelector((state) => state.user);
    const [region, setRegion] = useState({
        latitude: 48.8566,
        longitude: 2.3522,
        latitudeDelta: 0.0922,
        longitudeDelta: 0.0421,
    });
    const [imageSize, setImageSize] = useState(30);
    const requestLocationPermission = () => __awaiter(void 0, void 0, void 0, function* () {
        if (Platform.OS === 'ios') {
            Geolocation.requestAuthorization();
            return true;
        }
        else if (Platform.OS === 'android') {
            const granted = yield PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION, {
                title: 'Location Permission',
                message: 'This app needs access to your location.',
                buttonPositive: 'OK',
                buttonNegative: 'Cancel',
            });
            return granted === PermissionsAndroid.RESULTS.GRANTED;
        }
        return false;
    });
    const getCurrentPosition = () => {
        Geolocation.getCurrentPosition((position) => {
            setRegion({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                latitudeDelta: 0.0922,
                longitudeDelta: 0.0421,
            });
        }, (error) => console.warn(error), { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 });
    };
    useEffect(() => {
        requestLocationPermission().then(granted => {
            if (granted) {
                getCurrentPosition();
            }
        });
    }, []);
    useEffect(() => {
        const newSize = calculateImageSize(region.latitudeDelta);
        setImageSize(newSize);
    }, [region]);
    const calculateImageSize = (latitudeDelta) => {
        const baseSize = 30;
        if (latitudeDelta < 0.01)
            return baseSize * 1.5;
        else if (latitudeDelta < 0.05)
            return baseSize * 1.2;
        return baseSize;
    };
    return (<View style={{ flex: 1 }}>
      <MapView style={{ width: Dimensions.get('window').width, height: Dimensions.get('window').height }} region={region} showsUserLocation={true} onRegionChangeComplete={region => setRegion(region)}>
        {allUsers.map((userItem) => {
            if (userItem._id !== user._id && userItem.location && userItem.location.coordinates && userItem.profileImage) {
                const [longitude, latitude] = userItem.location.coordinates;
                return (<Marker key={userItem._id} coordinate={{ latitude, longitude }} title={userItem.userName} description="Another user">
                <Image source={{ uri: userItem.profileImage }} style={{
                        width: imageSize,
                        height: imageSize,
                        borderRadius: imageSize / 2,
                        borderWidth: 1,
                        borderColor: 'white',
                    }} resizeMode="cover"/>
              </Marker>);
            }
            return null;
        })}
      </MapView>
    </View>);
};
export default MapsMobileUser;
