import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    addressContent: {
        marginTop: height * 0.05, // Adjusted to 5% of screen height
        width: '80%',
    },
    nickNameContent: {
        alignItems: 'center',
        zIndex: -5,
    },
    nicknameContainer: {
        width: '80%',
        zIndex: -5,
    },
    btnContainer: {
        width: '80%',
        alignItems: 'center',
        marginTop: height * 0.16, // Adjusted to 10% of screen height
        zIndex: -5,
    },
    nickNameMessage: {
        textAlign: "center",
        marginTop: height * 0.02, // Adjusted to 2% of screen height
        marginBottom: height * 0.001, // Adjusted to 0.1% of screen height
        marginHorizontal: width * 0.01, // Adjusted to 1% of screen width
    },
    nickName: {
        width: '100%',
    },
    headerError: {
        backgroundColor: 'red',
        padding: height * 0.025, // Adjusted to 2.5% of screen height
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: width * 0.045, // Adjusted to 4.5% of screen width
        fontWeight: 'bold',
    },
    errorText: {
        color: 'red',
        fontSize: 14,
        marginVertical: 5,
    },
    suggestionsContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 5,
    },
    suggestionText: {
        color: 'blue',
        fontSize: 14,
        marginHorizontal: 5,
        textDecorationLine: 'underline',
        //cursor: 'pointer',
    },
});
