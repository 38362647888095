// Fr  Styles de EditActivityLocationScreen( FrameFigma032A,FrameFigma032B,FrameFigma032C )
// En  Styles of EditActivityLocationScreen( FrameFigma032A,FrameFigma032B,FrameFigma032C ) 
import { Dimensions, StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    buttonContainer: {
        width: '90%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
        marginTop: '2%',
    },
    button: {
        padding: 6,
        backgroundColor: '#59C09B',
        borderRadius: 6,
        margin: 1,
    },
    addressAutoPicker: {
        width: '90%',
    },
    map: {
        marginVertical: 20,
        width: Dimensions.get('screen').width - 50,
        height: 300,
        overflow: 'hidden',
    },
    switchBtn: {
        //marginVertical: 10,
        paddingVertical: 5,
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 10,
    },
    textbtn: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 10,
    },
});
