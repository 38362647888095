import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        padding: 20,
        justifyContent: 'center',
        alignItems: 'center',
    },
    saveButton: {
        marginTop: 20,
        padding: 10,
        backgroundColor: '#4CAF50',
        borderRadius: 5,
        alignItems: 'center',
    },
    saveButtonText: {
        color: 'white',
        fontWeight: 'bold',
    },
    toggleAspectButton: {
        marginTop: 10,
        padding: 10,
        backgroundColor: '#2196F3',
        borderRadius: 5,
        alignItems: 'center',
        flexDirection: 'row',
    },
    toggleAspectButtonDisabled: {
        backgroundColor: '#B0BEC5',
    },
    toggleAspectButtonText: {
        color: 'white',
    },
    toggleAspectButtonDisabledText: {
        color: '#90A4AE',
    },
});
export default styles;
