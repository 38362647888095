var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// fr pour afficher des activités de manière attrayante et permettre aux utilisateurs d'interagir avec elles de manière intuitive
// En To display activities in an attractive manner and allow users to interact with them intuitively.
import React from 'react';
import { useState, useEffect } from 'react';
import { View, Text, Pressable, Image, Platform, ActivityIndicator } from 'react-native';
import { useNavigation } from '@react-navigation/native';
//import vectors (anciennement icons)
import { Vectors } from '../../../constants/Vectors';
import { activitiesIcon } from '../../../constants/VectorsOnOff/ActivityListIcon';
//import contexts
//import services
import { authorServices } from '../../../services/activities/auhorServices';
import { ActivityLikeServices } from '../../../services/activities/ActivityLikeServices';
import { InterestedServices } from '../../../services/activities/InterestedServices';
import { ActivityServices } from '../../../services/activities/ActivityServices';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
//import styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const ActivityCard = ({ activity, userId }) => {
    var _a, _b, _c, _d, _e, _f;
    const navigation = useNavigation();
    // const { setAuthor, setActivityView, setActivityTopicTitle, activities } = useActivity();
    const { activities } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setAuthor = (data) => {
        dispatch(ActivitiesSlice.actions.setAuthor({ id: nanoid(), value: data }));
    };
    const setActivityView = (data) => {
        dispatch(ActivitiesSlice.actions.setActivityView({ id: nanoid(), value: data }));
    };
    const setActivityTopicTitle = (data) => {
        dispatch(ActivitiesSlice.actions.setActivityTopicTitle({ id: nanoid(), value: data }));
    };
    const [isLiked, setIsLiked] = useState(false);
    // const [isUserParticpate, setIsUserParticpate] = useState<boolean>(false);
    const [authorDetails, setAuthorDetails] = useState(null);
    const actionLike = () => __awaiter(void 0, void 0, void 0, function* () {
        if (isLiked) {
            setIsLiked(!isLiked);
            yield ActivityLikeServices.removeUserLikeActivity(activity._id);
            yield ActivityLikeServices.removeActivityLike(activity);
        }
        else {
            setIsLiked(!isLiked);
            yield ActivityLikeServices.addUserLikeActivity(activity._id);
            yield ActivityLikeServices.addActivityLike(activity);
        }
    });
    function checkLikeStatus(activity) {
        return __awaiter(this, void 0, void 0, function* () {
            const liked = yield ActivityLikeServices.isUserLikedActivity(activity);
            setIsLiked(liked);
            // setActivityView({...activity, isLiked:liked});
            return liked;
        });
    }
    ;
    const getActAuthor = (authorID) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const author = yield authorServices.getAuthor(authorID);
            setAuthorDetails(author);
            return author;
        }
        catch (error) {
            console.log("Error from getActAuthor", error);
        }
    });
    useEffect(() => {
        checkLikeStatus(activity);
    }, [activity.likes]);
    useEffect(() => {
        console.log('useEffectAuthor');
        getActAuthor(activity.author);
    }, []);
    const userStatus = {
        participant: (activity === null || activity === void 0 ? void 0 : activity.attendees.length) > 0 ? (_a = activity.attendees) === null || _a === void 0 ? void 0 : _a.includes(userId) : false,
        inWaitingList: (activity === null || activity === void 0 ? void 0 : activity.waitingList.length) > 0 ? activity.waitingList.includes(userId) : false,
    };
    // logique des données contexts et navigation vers details de l activité
    function displayActivityDetails(activityId) {
        return __awaiter(this, void 0, void 0, function* () {
            var _a;
            const response = yield ActivityServices.getActivity(activityId);
            const activity = response.data;
            yield InterestedServices.addUserIdToInterestedArray(activity);
            ;
            setActivityView(Object.assign(Object.assign({}, activity), { isLiked: isLiked }));
            setAuthor(authorDetails);
            setActivityTopicTitle((_a = activitiesIcon[activity.topic]) === null || _a === void 0 ? void 0 : _a.activityTitle);
            navigation.navigate('Activity', { activityId: activity._id });
        });
    }
    //Gestion de l'affichage de l'heure de l'activité
    const [formattedStartTime, setFormattedStartTime] = useState('');
    const [formattedEndTime, setFormattedEndTime] = useState('');
    useEffect(() => {
        if (activity.date) {
            // Convertir la chaîne de caractères en objet Date
            const dateObject = new Date(activity.date);
            // Vérifier si la conversion a réussi
            if (!isNaN(dateObject.getTime())) {
                const hours = dateObject.getUTCHours().toString().padStart(2, '0');
                const minutes = dateObject.getUTCMinutes().toString().padStart(2, '0');
                setFormattedStartTime(`${hours}:${minutes}`);
            }
            else {
                console.error('Date invalide');
            }
        }
        if (activity.endDate) {
            // Convertir la chaîne de caractères en objet Date
            const endDateObject = new Date(activity.endDate);
            // Vérifier si la conversion a réussi
            if (!isNaN(endDateObject.getTime())) {
                const hours = endDateObject.getUTCHours().toString().padStart(2, '0');
                const minutes = endDateObject.getUTCMinutes().toString().padStart(2, '0');
                setFormattedEndTime(`${hours}:${minutes}`);
            }
            else {
                console.error('Date invalide');
            }
        }
    }, [activity]);
    return (<>
      {authorDetails ?
            <>
          <Pressable style={[
                    styles.activityCard,
                    isLiked && styles.activityCardLiked,
                    userStatus.participant && styles.activityCardParticipant,
                    userStatus.inWaitingList && styles.activityCardWaitingList,
                ]} onPress={() => displayActivityDetails(activity._id)}>
            <View style={styles.leftSide}>
              <View style={styles.activityImage}>
                <View style={styles.imageContainer}>
                  <Image source={{ uri: activity.activityImage }} style={styles.activityImageOne}/>
                </View>
                <View style={[
                    styles.userStatusTag,
                    userStatus.participant
                        ? styles.participant
                        : userStatus.inWaitingList
                            ? styles.waitingList
                            : styles.topic,
                ]}>
                  <Text style={styles.text}>
                    {userStatus.participant
                    ? 'Attendee'
                    : userStatus.inWaitingList
                        ? 'Waiting List'
                        : ((_b = activitiesIcon[activity.topic]) === null || _b === void 0 ? void 0 : _b.activityTitle) || 'Activity'}
                  </Text>
                </View>
              </View>
              <View style={styles.componentHeart}>
                <Pressable style={styles.heart} onPress={actionLike}>
                  {isLiked ? <Vectors.UsersInteractionColorsHeartRed /> : <Vectors.UsersInteractionBlackAndWhiteHeartGrey />}
                </Pressable>
              </View>
            </View>
            <View style={styles.rightSide}>
              <View style={styles.centerItemUp}>
                <View style={styles.activityTitleContainer}>
                  <Text style={styles.activityTitle}>{activity.title}</Text>
                </View>
                <View style={styles.authorImageView}>
                  {/* <Image source={{uri: authorDetails ? authorDetails.profileImage : null}} style={styles.authorImage} /> */}
                  {authorDetails ? <Image source={{ uri: authorDetails.profileImage }} style={styles.authorImage}/> : null}
                </View>
              </View>
              <View style={styles.centerItem}>
                <View style={styles.bottomItem}>
                  <View style={[styles.bottomItemCenter, { minWidth: 70 }]}>
                    <View>
                      <Vectors.NavbarMembersBottom style={styles.NotificationsVectors}/>
                    </View>
                    <Text style={styles.textParticipants}>
                      {activity.attendeeLimit ? activity.attendeeLimit === 1000000
                    ? activity.attendees.length : activity.attendees.length + '/' + activity.attendeeLimit
                    : activity.attendees.length}
                      {/* {activity.attendeeLimit === 1000000 ? activity.attendees.length : activity.attendees.length + '/' + activity.attendeeLimit} */}
                    </Text>
                  </View>
                  <View style={[styles.bottomItemCenter, { marginLeft: 20 }]}>
                    <View style={{ marginRight: 5, marginBottom: 5 }}>
                      {!!activity.infoLine ?
                    <Vectors.GeneralColorsLinkChain style={styles.NotificationsVectors}/>
                    : !!activity.metroStation ?
                        <Vectors.Metro style={styles.NotificationsVectors}/>
                        : <Vectors.PinGoogleMaps style={styles.NotificationsVectors}/>}
                    </View>
                    <Text>{(_c = activity.location) === null || _c === void 0 ? void 0 : _c.postalCode}</Text>
                  </View>
                </View>
                <View style={styles.timeContainer}>
                  {/* <Text style={styles.activityTime}>
                  {activity.startTime?.hour}:{activity.startTime.minute > 9 ? activity.startTime?.minute : '0' + activity.startTime?.minute}
                </Text> */}
                  <Text style={styles.activityTime}>{formattedStartTime}</Text>
                  {activity.endTime && (<>
                      <Text style={styles.activityTime}> - </Text>
                      <Text style={styles.activityTime}>
                        {(_d = activity.endTime) === null || _d === void 0 ? void 0 : _d.hour}:{activity.endTime.minute > 9 ? (_e = activity.endTime) === null || _e === void 0 ? void 0 : _e.minute : '0' + ((_f = activity.endTime) === null || _f === void 0 ? void 0 : _f.minute)}
                      </Text>
                      {/* <Text style={styles.activityTime}>{formattedEndTime}</Text> //A ajouter dans endDate sera opérationnel*/} 
                    </>)}
                </View>
              </View>
            </View>
          </Pressable>
        </>
            : <ActivityIndicator size="large" color="#0000ff"/>}
    </>);
};
export default ActivityCard;
