import React from 'react';
import { View, Text, Platform } from 'react-native';
//import Vectors
import { Vectors } from '../../../constants/Vectors';
//import styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const ActivityDetailsAddressBannerComponent = ({ address, postal }) => {
    return (<View style={styles.container}>
            <View style={styles.adressContainer}>
                <View style={styles.iconContainer}>
                    <Vectors.MapsPinGoogleMaps />
                </View>
                {!!address ?
            <View style={styles.locationContainer}>
                        <Text style={styles.locationText}>{address}</Text>
                        <Text style={styles.locationText}>{postal}</Text>
                    </View>
            :
                <Text style={styles.locationText}>Le point de rendez-vous est donnée avec un point GPS</Text>}
            </View>
        </View>);
};
export default ActivityDetailsAddressBannerComponent;
