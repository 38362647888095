import React from 'react';
import { Platform, StyleSheet } from 'react-native';
import AppWeb from './src/AppWeb';
import AppMobile from './src/AppMobile';
import { Provider as ReduxProvider } from 'react-redux';
import { registerTranslation } from 'react-native-paper-dates';
import { ActivityModalProvider } from './src/contexts/ActivityModalContext';
// en cas de build il faut utiliser userSocketService
// import { WebSocketProvider } from './src/sockets/WebSocketProvider';
import { WebSocketProvider } from './src/sockets/WebSocketLocal';
import { ErrorProvider } from './src/contexts/ErrorContext';
import store, { persistor } from './src/redux/Store';
import { PersistGate } from 'redux-persist/integration/react';
// import { View } from 'react-native-reanimated/lib/typescript/Animated';
registerTranslation('fr', {
    save: 'Sauvegarder',
    selectSingle: 'Sélectionner la date',
    selectMultiple: 'Sélectionner les dates',
    selectRange: 'Sélectionner la période',
    dateIsDisabled: 'La date est désactivée',
    previous: 'Précédent',
    next: 'Suivant',
    typeInDate: 'Entrez la date',
    pickDateFromCalendar: 'Choisissez la date à partir du calendrier',
    close: 'Fermer',
    hour: '',
    minute: '',
    notAccordingToDateFormat: inputFormat => `Le format de la date doit être : ${inputFormat}`,
    mustBeHigherThan: function (date) {
        return 'Doit être supérieur à';
    },
    mustBeLowerThan: function (date) {
        return 'Doit être inférieur à';
    },
    mustBeBetween: function (startDate, endDate) {
        return 'Doit être entre';
    }
});
const App = () => {
    // Sélectionner le composant en fonction de la plateforme
    const SelectedApp = Platform.OS === 'web' ? AppWeb : AppMobile;
    // Retourner le composant sélectionné englobé dans le LanguageProvider
    return (<ReduxProvider store={store}>
      <PersistGate persistor={persistor}>
        <ErrorProvider>
          <WebSocketProvider>
            <ActivityModalProvider>
              <SelectedApp />
            </ActivityModalProvider>
          </WebSocketProvider>
        </ErrorProvider>
      </PersistGate>
    </ReduxProvider>);
};
const style = StyleSheet.create({
    test: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        paddingHorizontal: 20,
        paddingVertical: 30,
        backgroundColor: "#AAA"
    },
});
export default App;
