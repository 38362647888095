// FR Vectors qui est utilisé dans les pages ( FrameFigma050a FrameFigma050B FrameFigma051A FrameFigma051B FrameFigma051C FrameFigma051D FrameFigma052 FrameFigma053)
// 🇬🇧 Vectors used in the ( FrameFigma050a FrameFigma050B FrameFigma051A FrameFigma051B FrameFigma051C FrameFigma051D FrameFigma052 FrameFigma053)
/* Achievements /Box -------------------- */
import FreeGift from '../assets/Vectors/Achievements/Box/FreeGift.svg';
import Gift from '../assets/Vectors/Achievements/Box/Gift.svg';
import GiftDiamondStars from '../assets/Vectors/Achievements/Box/GiftDiamondStars.svg';
import GiftEmeraldStars from '../assets/Vectors/Achievements/Box/GiftEmeraldStars.svg';
import GiftGoldStars from '../assets/Vectors/Achievements/Box/GiftGoldStars.svg';
import GiftRubyStars from '../assets/Vectors/Achievements/Box/GiftRubyStars.svg';
import OtherGift from '../assets/Vectors/Achievements/Box/OtherGift.svg';
/* Achievements /Cups -------------------- */
import CupsLevel36 from '../assets/Vectors/Achievements/Cups/Level36.svg';
import WireGreen from '../assets/Vectors/Achievements/Cups/Sponsorship/WireGreen.svg';
// ./Diamond
import Level1DiamondStar from '../assets/Vectors/Achievements/Cups/Wings/Diamond/Level1DiamondStar.svg';
import Level2DiamondStar from '../assets/Vectors/Achievements/Cups/Wings/Diamond/Level2DiamondStar.svg';
import Level3DiamondStar from '../assets/Vectors/Achievements/Cups/Wings/Diamond/Level3DiamondStar.svg';
import Level4DiamondStar from '../assets/Vectors/Achievements/Cups/Wings/Diamond/Level4DiamondStar.svg';
import Level5DiamondStar from '../assets/Vectors/Achievements/Cups/Wings/Diamond/Level5DiamondStar.svg';
import Level6DiamondStar from '../assets/Vectors/Achievements/Cups/Wings/Diamond/Level6DiamondStar.svg';
import Level7DiamondStar from '../assets/Vectors/Achievements/Cups/Wings/Diamond/Level7DiamondStar.svg';
// ./Emerald
import Level1EmeraldStar from '../assets/Vectors/Achievements/Cups/Wings/Emerald/Level1EmeraldStar.svg';
import Level2EmeraldStar from '../assets/Vectors/Achievements/Cups/Wings/Emerald/Level2EmeraldStar.svg';
import Level3EmeraldStar from '../assets/Vectors/Achievements/Cups/Wings/Emerald/Level3EmeraldStar.svg';
import Level4EmeraldStar from '../assets/Vectors/Achievements/Cups/Wings/Emerald/Level4EmeraldStar.svg';
import Level5EmeraldStar from '../assets/Vectors/Achievements/Cups/Wings/Emerald/Level5EmeraldStar.svg';
import Level6EmeraldStar from '../assets/Vectors/Achievements/Cups/Wings/Emerald/Level6EmeraldStar.svg';
import Level7EmeraldStar from '../assets/Vectors/Achievements/Cups/Wings/Emerald/Level7EmeraldStar.svg';
// ./Gold
import Level1GoldStar from '../assets/Vectors/Achievements/Cups/Wings/Gold/Level1GoldStar.svg';
import Level2GoldStar from '../assets/Vectors/Achievements/Cups/Wings/Gold/Level2GoldStar.svg';
import Level3GoldStar from '../assets/Vectors/Achievements/Cups/Wings/Gold/Level3GoldStar.svg';
import Level4GoldStar from '../assets/Vectors/Achievements/Cups/Wings/Gold/Level4GoldStar.svg';
import Level5GoldStar from '../assets/Vectors/Achievements/Cups/Wings/Gold/Level5GoldStar.svg';
import Level6GoldStar from '../assets/Vectors/Achievements/Cups/Wings/Gold/Level6GoldStar.svg';
import Level7GoldStar from '../assets/Vectors/Achievements/Cups/Wings/Gold/Level7GoldStar.svg';
// ./NoStar
import Level1NoStar from '../assets/Vectors/Achievements/Cups/Wings/NoStar/Level1NoStar.svg';
import Level2NoStar from '../assets/Vectors/Achievements/Cups/Wings/NoStar/Level2NoStar.svg';
import Level3NoStar from '../assets/Vectors/Achievements/Cups/Wings/NoStar/Level3NoStar.svg';
import Level4NoStar from '../assets/Vectors/Achievements/Cups/Wings/NoStar/Level4NoStar.svg';
import Level5NoStar from '../assets/Vectors/Achievements/Cups/Wings/NoStar/Level5NoStar.svg';
import Level6NoStar from '../assets/Vectors/Achievements/Cups/Wings/NoStar/Level6NoStar.svg';
import Level7NoStar from '../assets/Vectors/Achievements/Cups/Wings/NoStar/Level7NoStar.svg';
// ./Ruby
import Level1RubyStar from '../assets/Vectors/Achievements/Cups/Wings/Ruby/Level1RubyStar.svg';
import Level2RubyStar from '../assets/Vectors/Achievements/Cups/Wings/Ruby/Level2RubyStar.svg';
import Level3RubyStar from '../assets/Vectors/Achievements/Cups/Wings/Ruby/Level3RubyStar.svg';
import Level4RubyStar from '../assets/Vectors/Achievements/Cups/Wings/Ruby/Level4RubyStar.svg';
import Level5RubyStar from '../assets/Vectors/Achievements/Cups/Wings/Ruby/Level5RubyStar.svg';
import Level6RubyStar from '../assets/Vectors/Achievements/Cups/Wings/Ruby/Level6RubyStar.svg';
import Level7RubyStar from '../assets/Vectors/Achievements/Cups/Wings/Ruby/Level7RubyStar.svg';
// /Stars
import StarsDiamond from '../assets/Vectors/Achievements/Stars/Diamond.svg';
import StarsEmerald from '../assets/Vectors/Achievements/Stars/Emerald.svg';
import StarsGold from '../assets/Vectors/Achievements/Stars/Gold.svg';
import StarsRatingHalfAndOff from '../assets/Vectors/Achievements/Stars/RatingHalfAndOff.svg';
import StarsRatingOff from '../assets/Vectors/Achievements/Stars/RatingOff.svg';
import StarsRatingOn from '../assets/Vectors/Achievements/Stars/RatingOn.svg';
import RatingOnActiveUsers from '../assets/Vectors/Achievements/Stars/RatingOnActiveUsers.svg';
import StarsRuby from '../assets/Vectors/Achievements/Stars/Ruby.svg';
// /Border
import RubyBorder from "../assets/Vectors/Achievements/Border/RubyBorder.svg";
import GoldBorder from "../assets/Vectors/Achievements/Border/GoldBorder.svg";
import EmeraldBorder from "../assets/Vectors/Achievements/Border/EmeraldBorder.svg";
import DiamondBorder from "../assets/Vectors/Achievements/Border/DiamondBorder.svg";
import GoldSmallBorder from "../assets/Vectors/Achievements/Border/GoldSmallBorder.svg";
import RubySmallBorder from "../assets/Vectors/Achievements/Border/RubySmallBorder.svg";
import EmeraldSmallBorder from "../assets/Vectors/Achievements/Border/EmeraldSmallBorder.svg";
import DiamandSmallBorder from "../assets/Vectors/Achievements/Border/DiamondSmallBorder.svg";
// /Base
import AmbassadorBase from "../assets/Vectors/Achievements/Cups/Base/AmbassadorBase.svg";
import DiamondBase from "../assets/Vectors/Achievements/Cups/Base/DiamondBase.svg";
import EmeraldBase from "../assets/Vectors/Achievements/Cups/Base/EmeraldBase.svg";
import GoldBase from "../assets/Vectors/Achievements/Cups/Base/GoldBase.svg";
import NoStarBase from "../assets/Vectors/Achievements/Cups/Base/NoStarBase.svg";
import RubyBase from "../assets/Vectors/Achievements/Cups/Base/RubyBase.svg";
// /Sign
import CheckSign from "../assets/Vectors/Achievements/Sign/CheckSign.svg";
import SignRed from "../assets/Vectors/Achievements/Sign/CloseLargeSignRed.svg";
/* Activity /Goodies -------------------- */
import FreeDrink from '../assets/Vectors/Activity/Goodies/FreeDrink.svg';
import GoodiesFreeDrink from '../assets/Vectors/Activity/Goodies/FreeDrink.svg';
import FreePass from '../assets/Vectors/Activity/Goodies/FreePass.svg';
import GoodiesFreePass from '../assets/Vectors/Activity/Goodies/FreePass.svg';
import GoodiesFree from '../assets/Vectors/Activity/Goodies/Free.svg';
import GoodiesMoreFun from '../assets/Vectors/Activity/Goodies/MoreFun.svg';
import FiltresButton from '../assets/Vectors/Activity/Goodies/Filtres.svg';
//import Full/EmptyDrink from '../assets/Vectors/General/Colors/FullDrink.svg';
import FullDrink from '../assets/Vectors/Activity/Goodies/FullDrink.svg';
import EmptyDrink from '../assets/Vectors/Activity/Goodies/EmptyDrink.svg';
/* General /Black and White -------------------- */
import RightArrowWhite from '../assets/Vectors/General/BlackAndWhite/RightArrowWhite.svg';
import GeneralBlackAndWhiteRightArrowWhite from '../assets/Vectors/General/BlackAndWhite/RightArrowWhite.svg';
import LeftArrow from '../assets/Vectors/General/BlackAndWhite/LeftArrow.svg';
import GeneralBlackAndWhiteLeftArrow from '../assets/Vectors/General/BlackAndWhite/LeftArrow.svg';
import CloseSign from '../assets/Vectors/General/BlackAndWhite/CloseSign.svg';
import GeneralBlackAndWhiteCloseSign from '../assets/Vectors/General/BlackAndWhite/CloseSign.svg';
import RightArrow from '../assets/Vectors/General/BlackAndWhite/RightArrow.svg';
import GeneralBlackAndWhiteRightArrow from '../assets/Vectors/General/BlackAndWhite/RightArrow.svg';
import PadLock from '../assets/Vectors/General/BlackAndWhite/PadLock.svg';
import GeneralBlackAndWhitePadLock from '../assets/Vectors/General/BlackAndWhite/PadLock.svg';
import PadLockOpen from '../assets/Vectors/General/BlackAndWhite/PadLockOpen.svg';
import GeneralBlackAndWhitePadLockOpen from '../assets/Vectors/General/BlackAndWhite/PadLockOpen.svg';
import Exit from '../assets/Vectors/General/BlackAndWhite/Exit.svg';
import Paste from '../assets/Vectors/General/BlackAndWhite/Paste.svg';
import ThreeDots from '../assets/Vectors/General/BlackAndWhite/ThreeDots.svg';
import ThreeDotsVertiBlack from '../assets/Vectors/General/BlackAndWhite/ThreeDotsVertiBlack.svg';
/* General /Color -------------------- */
import Calendar from '../assets/Vectors/General/Colors/Calendar.svg';
import GeneralColorsCalendar from '../assets/Vectors/General/Colors/Calendar.svg';
import CloseLargeRedSign from '../assets/Vectors/General/Colors/CloseLargeSignRed.svg';
import GeneralColorsCloseLargeSignRed from '../assets/Vectors/General/Colors/CloseLargeSignRed.svg';
import CloseRed from '../assets/Vectors/General/Colors/CloseRed.svg';
import GeneralColorsCloseRed from '../assets/Vectors/General/Colors/CloseRed.svg';
import Checkbox from '../assets/Vectors/General/Colors/Checkbox.svg';
import GeneralColorsCheckbox from '../assets/Vectors/General/Colors/Checkbox.svg';
import CheckSignGeneralColor from '../assets/Vectors/General/Colors/CheckSign.svg';
import GeneralColorsCheckSign from '../assets/Vectors/General/Colors/CheckSign.svg';
import CloseSignGreen from '../assets/Vectors/General/Colors/CloseSignGreen.svg';
import GeneralColorsCloseSignGreen from '../assets/Vectors/General/Colors/CloseSignGreen.svg';
import Plus from '../assets/Vectors/General/Colors/Plus.svg';
import GeneralColorsPlus from '../assets/Vectors/General/Colors/Plus.svg';
import Information from '../assets/Vectors/General/Colors/Information.svg';
import GreenClock from '../assets/Vectors/General/Colors/GreenClock.svg';
import GeneralColorsGreenClock from '../assets/Vectors/General/Colors/GreenClock.svg';
import LeftArrowGreen from '../assets/Vectors/General/Colors/LeftArrowGreen.svg';
import UsersInteractionColorsGroup from '../assets/Vectors/UsersInteraction/Colors/Group.svg';
import ButtonWorldStart from '../assets/Vectors/General/Colors/ButtonWorldStart.svg';
import SendMessage from '../assets/Vectors/General/Colors/SendMessage.svg';
/* Maps -------------------- */
import PinBlackAndWhite from '../assets/Vectors/Maps/PinBlackAndWhite.svg';
import MapsPinBlackAndWhite from '../assets/Vectors/Maps/PinBlackAndWhite.svg';
import PinGoogleMaps from '../assets/Vectors/Maps/PinGoogleMaps.svg';
import MapsPinGoogleMaps from '../assets/Vectors/Maps/PinGoogleMaps.svg';
import PinPointColors from '../assets/Vectors/Maps/PinPointColors.svg';
import MapsPinPointColors from '../assets/Vectors/Maps/PinPointColors.svg';
import Metro from '../assets/Vectors/Maps/Metro.svg';
import MapsMetro from '../assets/Vectors/Maps/Metro.svg';
/* Navbar /Black and White -------------------- */
import NavbarEditInfo from '../assets/Vectors/NavBar/BlackAndWhite/EditInfo.svg';
import NavbarBlackAndWhiteEditInfo from '../assets/Vectors/NavBar/BlackAndWhite/EditInfo.svg';
import NavbarPinPointMiddle from '../assets/Vectors/NavBar/BlackAndWhite/PinPointMiddle.svg';
import NavbarBlackAndWhitePinPointMiddle from '../assets/Vectors/NavBar/BlackAndWhite/PinPointMiddle.svg';
import NavbarChat from '../assets/Vectors/NavBar/BlackAndWhite/Chat.svg';
import NavbarBlackAndWhiteChat from '../assets/Vectors/NavBar/BlackAndWhite/Chat.svg';
import NavbarBlackAndWhiteMembersBottom from '../assets/Vectors/NavBar/BlackAndWhite/MembersBottom.svg';
import NavbarBlackAndWhiteContactUs from '../assets/Vectors/NavBar/BlackAndWhite/Contact-us.svg';
import NavbarBlackAndWhiteConcept from '../assets/Vectors/NavBar/BlackAndWhite/Concept.svg';
import NavbarBlackAndWhiteMap from '../assets/Vectors/NavBar/BlackAndWhite/Map.svg';
import NavbarBlackAndWhiteBell from '../assets/Vectors/NavBar/BlackAndWhite/Bell.svg';
import NavbarBlackAndWhiteMessenger from '../assets/Vectors/NavBar/BlackAndWhite/Messenger.svg';
import NavbarBlackAndWhiteNews from '../assets/Vectors/NavBar/BlackAndWhite/News.svg';
/* Navbar /Color -------------------- */
import NavbarEditInfoColors from '../assets/Vectors/NavBar/Colors/EditInfo.svg';
import NavbarColorsEditInfo from '../assets/Vectors/NavBar/Colors/EditInfo.svg';
import NavbarChatColors from '../assets/Vectors/NavBar/Colors/ChatColors.svg';
import NavbarColorsChat from '../assets/Vectors/NavBar/Colors/ChatColors.svg';
import NavbarMembersMiddle from '../assets/Vectors/NavBar/Colors/MembersMiddle.svg';
import NavbarColorsMembersMiddle from '../assets/Vectors/NavBar/Colors/MembersMiddle.svg';
import NavbarMembersBottom from '../assets/Vectors/NavBar/Colors/MembersBottomColor.svg';
import NavbarColorsContactUs from '../assets/Vectors/NavBar/Colors/ContactUs.svg';
import NavbarColorsConcept from '../assets/Vectors/NavBar/Colors/Concept.svg';
import NavbarColorsNews from '../assets/Vectors/NavBar/Colors/News.svg';
import NavbarColorsMap from '../assets/Vectors/NavBar/Colors/Map.svg';
import NavbarColorsMessenger from '../assets/Vectors/NavBar/Colors/Messenger.svg';
import DonationIcon from '../assets/Vectors/NavBar/Colors/DonationIcon.svg';
/* Calendar -------------------- */
import CalendarOn from '../assets/Vectors/NavBar/Colors/CalendarBottom.svg';
import NavbarBlackAndWhiteCalendarBottom from '../assets/Vectors/NavBar/BlackAndWhite/CalendarBottom.svg';
/* Activity/Topics /BlackAndWhite -------------------- */
import TopicsBlackAndWhiteAfterwork from '../assets/Vectors/Activity/Topics/BlackAndWhite/Afterwork.svg';
import TopicsBlackAndWhiteApero from '../assets/Vectors/Activity/Topics/BlackAndWhite/Apero.svg';
import TopicsBlackAndWhiteBusiness from '../assets/Vectors/Activity/Topics/BlackAndWhite/Business.svg';
import TopicsBlackAndWhiteCulture from '../assets/Vectors/Activity/Topics/BlackAndWhite/Culture.svg';
import TopicsBlackAndWhiteDancing from '../assets/Vectors/Activity/Topics/BlackAndWhite/Dancing.svg';
import TopicsBlackAndWhiteGames from '../assets/Vectors/Activity/Topics/BlackAndWhite/Games.svg';
import TopicsBlackAndWhiteLinguistic from '../assets/Vectors/Activity/Topics/BlackAndWhite/Linguistic.svg';
import TopicsBlackAndWhiteMeal from '../assets/Vectors/Activity/Topics/BlackAndWhite/Meal.svg';
import TopicsBlackAndWhiteMovie from '../assets/Vectors/Activity/Topics/BlackAndWhite/Movie.svg';
import TopicsBlackAndWhiteMusic from '../assets/Vectors/Activity/Topics/BlackAndWhite/Music.svg';
import TopicsBlackAndWhiteMutualHelp from '../assets/Vectors/Activity/Topics/BlackAndWhite/MutualHelp.svg';
import TopicsBlackAndWhiteParty from '../assets/Vectors/Activity/Topics/BlackAndWhite/Party.svg';
import TopicsBlackAndWhitePicnic from '../assets/Vectors/Activity/Topics/BlackAndWhite/Picnic.svg';
import TopicsBlackAndWhitePrivate from '../assets/Vectors/Activity/Topics/BlackAndWhite/Private.svg';
import TopicsBlackAndWhiteSports from '../assets/Vectors/Activity/Topics/BlackAndWhite/Sports.svg';
import TopicsBlackAndWhiteTravel from '../assets/Vectors/Activity/Topics/BlackAndWhite/Travel.svg';
/* Activity/Topics /colors -------------------- */
import TopicsColorsApero from '../assets/Vectors/Activity/Topics/Colors/Apero.svg';
import TopicsColorsBusiness from '../assets/Vectors/Activity/Topics/Colors/Business.svg';
import TopicsColorsCulture from '../assets/Vectors/Activity/Topics/Colors/Culture.svg';
import TopicsColorsDancing from '../assets/Vectors/Activity/Topics/Colors/Dancing.svg';
import TopicsColorsGames from '../assets/Vectors/Activity/Topics/Colors/Games.svg';
import TopicsColorsLinguistic from '../assets/Vectors/Activity/Topics/Colors/Linguistic.svg';
import TopicsColorsMeal from '../assets/Vectors/Activity/Topics/Colors/Meal.svg';
import TopicsColorsMovie from '../assets/Vectors/Activity/Topics/Colors/Movie.svg';
import TopicsColorsMusic from '../assets/Vectors/Activity/Topics/Colors/Music.svg';
import TopicsColorsMutualHelp from '../assets/Vectors/Activity/Topics/Colors/MutualHelp.svg';
import TopicsColorsParty from '../assets/Vectors/Activity/Topics/Colors/Party.svg';
import TopicsColorsPicnic from '../assets/Vectors/Activity/Topics/Colors/Picnic.svg';
import TopicsColorsPrivate from '../assets/Vectors/Activity/Topics/Colors/Private.svg';
import TopicsColorsSports from '../assets/Vectors/Activity/Topics/Colors/Sports.svg';
import TopicsColorsTravel from '../assets/Vectors/Activity/Topics/Colors/Travel.svg';
/* Users Interaction /Color -------------------- */
import PeopleOn from '../assets/Vectors/UsersInteraction/Colors/PeopleOn.svg';
import UsersInteractionColorsPeopleOn from '../assets/Vectors/UsersInteraction/Colors/PeopleOn.svg';
import HeartFilled from '../assets/Vectors/UsersInteraction/Colors/HeartFilled.svg';
import UsersInteractionColorsHeartFilled from '../assets/Vectors/UsersInteraction/Colors/HeartFilled.svg';
import TopicsColorsAfterwork from '../assets/Vectors/Activity/Topics/Colors/Afterwork.svg';
/* Feedback -------------------- */
import FeedbackContactBug from '../assets/Vectors/Feedback/ContactBug.svg';
import FeedbackContactOrga from '../assets/Vectors/Feedback/ContactOrga.svg';
import FeedbackContactPhone from '../assets/Vectors/Feedback/ContactPhone.svg';
/* General/BlackAndWhite -------------------- */
import GeneralBlackAndWhiteAttachment from '../assets/Vectors/General/BlackAndWhite/Attachment.svg';
import GeneralBlackAndWhiteCloseLargeSign from '../assets/Vectors/General/BlackAndWhite/CloseLargeSign.svg';
import GeneralBlackAndWhiteCloseSignWhite from '../assets/Vectors/General/BlackAndWhite/CloseSignWhite.svg';
import GeneralBlackAndWhiteDownArrow from '../assets/Vectors/General/BlackAndWhite/DownArrow.svg';
import GeneralBlackAndWhiteLinkChain from '../assets/Vectors/General/BlackAndWhite/LinkChainWhite.svg';
import GeneralBlackAndWhiteFilter from '../assets/Vectors/General/BlackAndWhite/Filter.svg';
import GeneralBlackAndWhiteFilterWithDescription from '../assets/Vectors/General/BlackAndWhite/FilterWithDescription.svg';
import GeneralBlackAndWhiteImageGallery from '../assets/Vectors/General/BlackAndWhite/ImageGallery.svg';
import GeneralBlackAndWhiteInfoSign from '../assets/Vectors/General/BlackAndWhite/InfoSign.svg';
import GeneralBlackAndWhiteLoop from '../assets/Vectors/General/BlackAndWhite/Loop.svg';
import GeneralBlackAndWhiteLoopWhite from '../assets/Vectors/General/BlackAndWhite/LoopWhite.svg';
import GeneralBlackAndWhiteNextWhite from '../assets/Vectors/General/BlackAndWhite/NextWhite.svg';
import GeneralBlackAndWhitePoints from '../assets/Vectors/General/BlackAndWhite/Points.svg';
import GeneralBlackAndWhitePreviousWhite from '../assets/Vectors/General/BlackAndWhite/PreviousWhite.svg';
import GeneralBlackAndWhiteSendPlane from '../assets/Vectors/General/BlackAndWhite/SendPlane.svg';
import GeneralBlackAndWhiteShareArrow from '../assets/Vectors/General/BlackAndWhite/ShareArrow.svg';
import GeneralBlackAndWhiteShareBlack from '../assets/Vectors/General/BlackAndWhite/ShareBlack.svg';
import GeneralBlackAndWhiteShareWhite from '../assets/Vectors/General/BlackAndWhite/ShareWhite.svg';
import GeneralBlackAndWhiteSharpPlus from '../assets/Vectors/General/BlackAndWhite/SharpPlus.svg';
import GeneralBlackAndWhiteSlideSearch from '../assets/Vectors/General/BlackAndWhite/SlideSearch.svg';
import GeneralBlackAndWhiteUnsplashFill from '../assets/Vectors/General/BlackAndWhite/UnsplashFill.svg';
import GeneralBlackAndWhiteVerify from '../assets/Vectors/General/BlackAndWhite/Verify.svg';
import GeneralBlackAndWhiteZoomIn from '../assets/Vectors/General/BlackAndWhite/ZoomIn.svg';
import GeneralBlackAndWhiteZoomOut from '../assets/Vectors/General/BlackAndWhite/ZoomOut.svg';
import GeneralBlackAndWhiteCopyPast from '../assets/Vectors/General/BlackAndWhite/CopyPast.svg';
/* General/color -------------------- */
import GeneralColorsGoldStar from '../assets/Vectors/General/Colors/IconsGoldStar.svg';
import GeneralColorsCreateAnActivity from '../assets/Vectors/General/Colors/CreateAnActivity.svg';
import GeneralColorsComingSoon from '../assets/Vectors/General/Colors/ComingSoon.svg';
import GeneralColorsCongratulationsStatus from '../assets/Vectors/General/Colors/CongratulationsStatus.svg';
import GeneralColorsCopy from '../assets/Vectors/General/Colors/Copy.svg';
import GeneralColorsDollarSign from '../assets/Vectors/General/Colors/DollarSign.svg';
import GeneralColorsEyeToggle from '../assets/Vectors/General/Colors/EyeToggle.svg';
import GeneralColorsEyeToggleCross from '../assets/Vectors/General/Colors/EyeToggleCross.svg';
import GeneralColorsIdea from '../assets/Vectors/General/Colors/Idea.svg';
import GeneralColorsInfoGreen from '../assets/Vectors/General/Colors/InfoGreen.svg';
import GeneralColorsInviteFriends from '../assets/Vectors/General/Colors/InviteFriends.svg';
import GeneralColorsLeftArrow from '../assets/Vectors/General/Colors/LeftArrow.svg';
import GeneralColorsLinkChain from '../assets/Vectors/General/Colors/LinkChain.svg';
import GeneralColorsLogoSocializusBlack from '../assets/Vectors/General/Colors/LogoSocializusBlack.svg';
import GeneralColorsLogoSocializusGrey from '../assets/Vectors/General/Colors/LogoSocializusGrey.svg';
import GeneralColorsLogoSocializusWhite from '../assets/Vectors/General/Colors/LogoSocializusWhite.svg';
import GeneralColorsLogOut from '../assets/Vectors/General/Colors/LogOut.svg';
import GeneralColorsMagnifierOn from '../assets/Vectors/General/Colors/MagnifierOn.svg';
import GeneralColorsOffButton from '../assets/Vectors/General/Colors/OffButton.svg';
import GeneralColorsOnButton from '../assets/Vectors/General/Colors/OnButton.svg';
import GeneralColorsOnlineMeeting from '../assets/Vectors/General/Colors/OnlineMeeting.svg';
import GeneralColorsQrCode from '../assets/Vectors/General/Colors/QrCode.svg';
import GeneralColorsSettings from '../assets/Vectors/General/Colors/Settings.svg';
import GeneralColorsStopSign from '../assets/Vectors/General/Colors/StopSign.svg';
import GeneralColorsTicket from '../assets/Vectors/General/Colors/Ticket.svg';
import GeneralColorsUpdate from '../assets/Vectors/General/Colors/Update.svg';
import GeneralColorsUpload from '../assets/Vectors/General/Colors/Upload.svg';
import GeneralColorsWarning from '../assets/Vectors/General/Colors/Warning.svg';
import IconUpload from '../assets/Vectors/General/Colors/Upload.svg';
/* InviteFriends -------------------- */
// Images pour le fichier OnlyFriendsCanChatModal.tsx
import ContactFriends from '../assets/Vectors/InviteFriends/ContactFriends.svg';
import Instagram from '../assets/Vectors/InviteFriends/Instagram.svg';
import EmailEnvelope from '../assets/Vectors/InviteFriends/EmailEnvelope.svg';
import Link from '../assets/Vectors/InviteFriends/InviteLinkChain.svg';
import PhoneMsg from '../assets/Vectors/InviteFriends/TextMessage.svg';
import Messenger from '../assets/Vectors/InviteFriends/Messenger.svg';
import RightArrowGrey from '../assets/Vectors/InviteFriends/RightArrowGrey.svg';
import Snapchat from '../assets/Vectors/InviteFriends/Snapchat.svg';
import Tiktok from '../assets/Vectors/InviteFriends/TikTok.svg';
import Twitter from '../assets/Vectors/InviteFriends/Twitter.svg';
import Whatsapp from '../assets/Vectors/InviteFriends/WhatsApp.svg';
import Friendship from '../assets/Vectors/InviteFriends/Friendship.svg';
//Maps
import MapsLocationBlackAndWhite from '../assets/Vectors/Maps/LocationBlackAndWhite.svg';
import MapsLocationColors from '../assets/Vectors/Maps/LocationColors.svg';
// /Notifications
import NotificationsBellMute from '../assets/Vectors/Notifications/BellMute.svg';
import NotificationsBellOn from '../assets/Vectors/Notifications/BellOn.svg';
import NotificationsBellRinging from '../assets/Vectors/Notifications/BellRinging.svg';
import NotificationsClock from '../assets/Vectors/Notifications/Clock.svg';
// Profiles
import ProfilesLanguage from '../assets/Vectors/Profiles/Language.svg';
import ProfilesPasswordPadLock from '../assets/Vectors/Profiles/PasswordPadLock.svg';
import ProfilesAddFriend from '../assets/Vectors/Profiles/AddFriend.svg';
import ProfilesArobase from '../assets/Vectors/Profiles/Arobase.svg';
import ProfilesBirthday from '../assets/Vectors/Profiles/Birthday.svg';
import ProfilesBlockFriend from '../assets/Vectors/Profiles/BlockFriend.svg';
import ProfilesCalendarColors from '../assets/Vectors/Profiles/CalendarColors.svg';
import ProfilesCamera from '../assets/Vectors/Profiles/Camera.svg';
import ProfilesChatColors from '../assets/Vectors/Profiles/ChatColors.svg';
import ProfilesCity from '../assets/Vectors/Profiles/City.svg';
import ProfilesConfidential from '../assets/Vectors/Profiles/Confidential.svg';
import ProfilesEdit from '../assets/Vectors/Profiles/Edit.svg';
import ProfilesEmail from '../assets/Vectors/Profiles/Email.svg';
import ProfilesFemale from '../assets/Vectors/Profiles/Female.svg';
import ProfilesFemaleSign from '../assets/Vectors/Profiles/FemaleSign.svg';
import ProfilesInformation from '../assets/Vectors/Profiles/Information.svg';
import ProfilesLastName from '../assets/Vectors/Profiles/LastName.svg';
import ProfilesMale from '../assets/Vectors/Profiles/Male.svg';
import ProfilesMaleSign from '../assets/Vectors/Profiles/MaleSign.svg';
import ProfilesMemberNumber from '../assets/Vectors/Profiles/MemberNumber.svg';
import ProfilesNoGender from '../assets/Vectors/Profiles/NoGender.svg';
import ProfilesPhone from '../assets/Vectors/Profiles/Phone.svg';
import ProfilesSchedule from '../assets/Vectors/Profiles/Schedule.svg';
import ProfilesStopWatch from '../assets/Vectors/Profiles/StopWatch.svg';
import ProfilesTrash from '../assets/Vectors/Profiles/Trash.svg';
import ProfilesTrashcan from '../assets/Vectors/Profiles/Trashcan.svg';
import ProfilesUnblock from '../assets/Vectors/Profiles/Unblock.svg';
import ProfileStar from '../assets/Vectors/Profiles/Star.svg';
import ProfileStaro from '../assets/Vectors/Profiles/Star-o.svg';
import ProfileStarHalf from '../assets/Vectors/Profiles/StarHalf.svg';
// /Settings
import SettingsBlockedUser from '../assets/Vectors/Settings/BlockedUser.svg';
import SettingsContactUs from '../assets/Vectors/Settings/ContactUs.svg';
import SettingsDeleteAccount from '../assets/Vectors/Settings/DeleteAccount.svg';
import SettingsEditProfile from '../assets/Vectors/Settings/EditProfile.svg';
import SettingsLegalNotice from '../assets/Vectors/Settings/LegalNotice.svg';
import SettingsLikedUsers from '../assets/Vectors/Settings/LikedUsers.svg';
import SettingsNews from '../assets/Vectors/Settings/News.svg';
import SettingsPrivacyPolicy from '../assets/Vectors/Settings/PrivacyPolicy.svg';
import SettingsGears from '../assets/Vectors/Settings/SettingsGears.svg';
import SettingsTermsOfSales from '../assets/Vectors/Settings/TermsOfSales.svg';
/* SocialNetworks -------------------- */
import SocialNetworksAppleLogo from '../assets/Vectors/SocialNetworks/AppleLogo.svg';
import SocialNetworksDiscord from '../assets/Vectors/SocialNetworks/Discord.svg';
import SocialNetworksFacebook from '../assets/Vectors/SocialNetworks/Facebook.svg';
import SocialNetworksFbGroup from '../assets/Vectors/SocialNetworks/FbGroup.svg';
import SocialNetworksFbPage from '../assets/Vectors/SocialNetworks/FbPage.svg';
import SocialNetworksGooglePlayLogo from '../assets/Vectors/SocialNetworks/GooglePlayLogo.svg';
import SocialNetworksGoogleSymbol from '../assets/Vectors/SocialNetworks/GoogleSymbol.svg';
import SocialNetworksHuaweilogo from '../assets/Vectors/SocialNetworks/HuaweiLogo.svg';
import SocialNetworksLinkChain from '../assets/Vectors/SocialNetworks/LinkChain.svg';
import SocialNetworksMeetup from '../assets/Vectors/SocialNetworks/Meetup.svg';
import SocialNetworksMessenger from '../assets/Vectors/SocialNetworks/Messenger.svg';
import SocialNetworksOtherLink from '../assets/Vectors/SocialNetworks/OtherLink.svg';
import SocialNetworksTelegram from '../assets/Vectors/SocialNetworks/Telegram.svg';
import SocialNetworksWebsiteLogo from '../assets/Vectors/SocialNetworks/WebsiteLogo.svg';
import SocialNetworksWhatsapp from '../assets/Vectors/SocialNetworks/Whatsapp.svg';
/* UsersInteraction /BlackAndWhite -------------------- */
import UsersInteractionBlackAndWhiteHappyOff from '../assets/Vectors/UsersInteraction/BlackAndWhite/HappyOff.svg';
import UsersInteractionBlackAndWhiteHeartGrey from '../assets/Vectors/UsersInteraction/BlackAndWhite/HeartGrey.svg';
import UsersInteractionBlackAndWhiteParticipants from '../assets/Vectors/UsersInteraction/BlackAndWhite/Participants.svg';
import UsersInteractionBlackAndWhitePeopleOff from '../assets/Vectors/UsersInteraction/BlackAndWhite/PeopleOff.svg';
/* UsersInteraction /Colors -------------------- */
import UsersInteractionColorsHappyOn from '../assets/Vectors/UsersInteraction/Colors/HappyOn.svg';
import UsersInteractionColorsHeartEmpty from '../assets/Vectors/UsersInteraction/Colors/HeartEmpty.svg';
import UsersInteractionColorsHeartRed from '../assets/Vectors/UsersInteraction/Colors/HeartRed.svg';
import UsersInteractionColorsNonProfit from '../assets/Vectors/UsersInteraction/Colors/NonProfit.svg';
export const Vectors = {
    /* Achievements /Box -------------------- */
    FreeGift,
    Gift,
    GiftDiamondStars,
    GiftEmeraldStars,
    GiftGoldStars,
    GiftRubyStars,
    OtherGift,
    /* Achievements /Cups -------------------- */
    CupsLevel36,
    WireGreen,
    //Vectors/Achievements/Cups/Diamond
    Level1DiamondStar,
    Level2DiamondStar,
    Level3DiamondStar,
    Level4DiamondStar,
    Level5DiamondStar,
    Level6DiamondStar,
    Level7DiamondStar,
    //Vectors/Achievements/Cups/Emerald
    Level1EmeraldStar,
    Level2EmeraldStar,
    Level3EmeraldStar,
    Level4EmeraldStar,
    Level5EmeraldStar,
    Level6EmeraldStar,
    Level7EmeraldStar,
    //Achievements/Cups/Gold
    Level1GoldStar,
    Level2GoldStar,
    Level3GoldStar,
    Level4GoldStar,
    Level5GoldStar,
    Level6GoldStar,
    Level7GoldStar,
    //Achievements/Cups/NoStar
    Level1NoStar,
    Level2NoStar,
    Level3NoStar,
    Level4NoStar,
    Level5NoStar,
    Level6NoStar,
    Level7NoStar,
    //Achievements/Cups/Ruby
    Level1RubyStar,
    Level2RubyStar,
    Level3RubyStar,
    Level4RubyStar,
    Level5RubyStar,
    Level6RubyStar,
    Level7RubyStar,
    //Achievements/Stars
    StarsDiamond,
    StarsEmerald,
    StarsGold,
    StarsRatingHalfAndOff,
    StarsRatingOff,
    StarsRatingOn,
    RatingOnActiveUsers,
    StarsRuby,
    //Achievements/Border
    GoldSmallBorder,
    RubySmallBorder,
    EmeraldSmallBorder,
    DiamandSmallBorder,
    GoldBorder,
    RubyBorder,
    EmeraldBorder,
    DiamondBorder,
    //Base
    AmbassadorBase,
    DiamondBase,
    EmeraldBase,
    GoldBase,
    NoStarBase,
    RubyBase,
    //Achievements/Sign
    CheckSign,
    SignRed,
    /* Activity /Goodies -------------------- */
    FreeDrink,
    FreePass,
    GoodiesFree,
    GoodiesFreeDrink,
    GoodiesFreePass,
    GoodiesMoreFun,
    FiltresButton,
    FullDrink,
    EmptyDrink,
    /* General /Black and White -------------------- */
    Exit,
    RightArrowWhite,
    LeftArrow,
    CloseSign,
    RightArrow,
    PadLock,
    PadLockOpen,
    Paste,
    IconUpload,
    ThreeDots,
    ThreeDotsVertiBlack,
    /* General /Color -------------------- */
    Calendar,
    CloseLargeRedSign,
    CloseRed,
    Checkbox,
    CheckSignGeneralColor,
    CloseSignGreen,
    Plus,
    Information,
    GreenClock,
    LeftArrowGreen,
    UsersInteractionColorsGroup,
    ButtonWorldStart,
    TopicsColorsAfterwork,
    SendMessage,
    /* Maps -------------------- */
    PinBlackAndWhite,
    PinGoogleMaps,
    PinPointColors,
    Metro,
    /* Navbar /Black and White -------------------- */
    NavbarEditInfo,
    NavbarPinPointMiddle,
    NavbarChat,
    NavbarBlackAndWhiteContactUs,
    NavbarBlackAndWhiteConcept,
    NavbarBlackAndWhiteNews,
    NavbarBlackAndWhiteMembersBottom,
    NavbarBlackAndWhiteMap,
    NavbarBlackAndWhiteBell,
    NavbarBlackAndWhiteMessenger,
    /* Navbar /Color -------------------- */
    NavbarEditInfoColors,
    NavbarChatColors,
    NavbarMembersMiddle,
    NavbarMembersBottom,
    NavbarColorsContactUs,
    NavbarColorsConcept,
    NavbarColorsNews,
    NavbarColorsMap,
    NavbarColorsMessenger,
    DonationIcon,
    /* Calendar -------------------- */
    CalendarOn,
    NavbarBlackAndWhiteCalendarBottom,
    /* Activity/Topics /BlackAndWhite -------------------- */
    TopicsBlackAndWhiteAfterwork,
    TopicsBlackAndWhiteApero,
    TopicsBlackAndWhiteBusiness,
    TopicsBlackAndWhiteCulture,
    TopicsBlackAndWhiteDancing,
    TopicsBlackAndWhiteGames,
    TopicsBlackAndWhiteLinguistic,
    TopicsBlackAndWhiteMeal,
    TopicsBlackAndWhiteMovie,
    TopicsBlackAndWhiteMusic,
    TopicsBlackAndWhiteMutualHelp,
    TopicsBlackAndWhiteParty,
    TopicsBlackAndWhitePicnic,
    TopicsBlackAndWhitePrivate,
    TopicsBlackAndWhiteSports,
    TopicsBlackAndWhiteTravel,
    /* Activity/Topics /colors -------------------- */
    TopicsColorsApero,
    TopicsColorsBusiness,
    TopicsColorsCulture,
    TopicsColorsDancing,
    TopicsColorsGames,
    TopicsColorsLinguistic,
    TopicsColorsMeal,
    TopicsColorsMovie,
    TopicsColorsMusic,
    TopicsColorsMutualHelp,
    TopicsColorsParty,
    TopicsColorsPicnic,
    TopicsColorsPrivate,
    TopicsColorsSports,
    TopicsColorsTravel,
    /* Users Interaction /Color -------------------- */
    PeopleOn,
    HeartFilled,
    /* Feedback -------------------- */
    FeedbackContactBug,
    FeedbackContactOrga,
    FeedbackContactPhone,
    /* General/BlackAndWhite -------------------- */
    GeneralBlackAndWhiteAttachment,
    GeneralBlackAndWhiteCloseLargeSign,
    GeneralBlackAndWhiteCloseSign,
    GeneralBlackAndWhiteCloseSignWhite,
    GeneralBlackAndWhiteDownArrow,
    GeneralBlackAndWhiteFilter,
    GeneralBlackAndWhiteFilterWithDescription,
    GeneralBlackAndWhiteImageGallery,
    GeneralBlackAndWhiteInfoSign,
    GeneralBlackAndWhiteLeftArrow,
    GeneralBlackAndWhiteLoop,
    GeneralBlackAndWhiteLoopWhite,
    GeneralBlackAndWhiteNextWhite,
    GeneralBlackAndWhitePadLock,
    GeneralBlackAndWhitePadLockOpen,
    GeneralBlackAndWhitePoints,
    GeneralBlackAndWhitePreviousWhite,
    GeneralBlackAndWhiteRightArrow,
    GeneralBlackAndWhiteRightArrowWhite,
    GeneralBlackAndWhiteSendPlane,
    GeneralBlackAndWhiteShareArrow,
    GeneralBlackAndWhiteShareBlack,
    GeneralBlackAndWhiteShareWhite,
    GeneralBlackAndWhiteSharpPlus,
    GeneralBlackAndWhiteSlideSearch,
    GeneralBlackAndWhiteUnsplashFill,
    GeneralBlackAndWhiteVerify,
    GeneralBlackAndWhiteZoomIn,
    GeneralBlackAndWhiteZoomOut,
    GeneralBlackAndWhiteLinkChain,
    GeneralBlackAndWhiteCopyPast,
    /* General/color -------------------- */
    GeneralColorsCalendar,
    GeneralColorsCheckbox,
    GeneralColorsCheckSign,
    GeneralColorsCloseLargeSignRed,
    GeneralColorsCloseRed,
    GeneralColorsCloseSignGreen,
    GeneralColorsComingSoon,
    GeneralColorsCongratulationsStatus,
    GeneralColorsCopy,
    GeneralColorsDollarSign,
    GeneralColorsEyeToggle,
    GeneralColorsEyeToggleCross,
    GeneralColorsGreenClock,
    GeneralColorsIdea,
    GeneralColorsInfoGreen,
    GeneralColorsInviteFriends,
    GeneralColorsLeftArrow,
    GeneralColorsLinkChain,
    GeneralColorsLogoSocializusBlack,
    GeneralColorsLogoSocializusGrey,
    GeneralColorsLogoSocializusWhite,
    GeneralColorsLogOut,
    GeneralColorsMagnifierOn,
    GeneralColorsOffButton,
    GeneralColorsOnButton,
    GeneralColorsOnlineMeeting,
    GeneralColorsPlus,
    GeneralColorsQrCode,
    GeneralColorsSettings,
    GeneralColorsStopSign,
    GeneralColorsTicket,
    GeneralColorsUpdate,
    GeneralColorsUpload,
    GeneralColorsWarning,
    GeneralColorsCreateAnActivity,
    GeneralColorsGoldStar,
    /* InviteFriends -------------------- */
    ContactFriends,
    Instagram,
    EmailEnvelope,
    Link,
    PhoneMsg,
    Messenger,
    RightArrowGrey,
    Snapchat,
    Tiktok,
    Twitter,
    Whatsapp,
    Friendship,
    //Maps
    MapsLocationBlackAndWhite,
    MapsLocationColors,
    MapsMetro,
    MapsPinBlackAndWhite,
    MapsPinGoogleMaps,
    MapsPinPointColors,
    NavbarBlackAndWhiteChat,
    NavbarBlackAndWhitePinPointMiddle,
    NavbarBlackAndWhiteEditInfo,
    NavbarColorsEditInfo,
    NavbarColorsMembersMiddle,
    NavbarColorsChat,
    // /Notifications
    NotificationsBellMute,
    NotificationsBellOn,
    NotificationsBellRinging,
    NotificationsClock,
    // /Profiles
    ProfilesAddFriend,
    ProfilesArobase,
    ProfilesBirthday,
    ProfilesBlockFriend,
    ProfilesCalendarColors,
    ProfilesCamera,
    ProfilesChatColors,
    ProfilesCity,
    ProfilesConfidential,
    ProfilesEdit,
    ProfilesEmail,
    ProfilesFemale,
    ProfilesFemaleSign,
    ProfilesInformation,
    ProfilesLastName,
    ProfilesMale,
    ProfilesMaleSign,
    ProfilesMemberNumber,
    ProfilesNoGender,
    ProfilesPhone,
    ProfilesSchedule,
    ProfilesStopWatch,
    ProfilesTrash,
    ProfilesTrashcan,
    ProfilesUnblock,
    ProfilesPasswordPadLock,
    ProfilesLanguage,
    ProfileStar,
    ProfileStaro,
    ProfileStarHalf,
    //Settings
    SettingsBlockedUser,
    SettingsContactUs,
    SettingsDeleteAccount,
    SettingsEditProfile,
    SettingsLegalNotice,
    SettingsLikedUsers,
    SettingsNews,
    SettingsPrivacyPolicy,
    SettingsGears,
    SettingsTermsOfSales,
    SocialNetworksAppleLogo,
    SocialNetworksDiscord,
    SocialNetworksFacebook,
    SocialNetworksFbGroup,
    SocialNetworksFbPage,
    SocialNetworksGooglePlayLogo,
    SocialNetworksGoogleSymbol,
    SocialNetworksHuaweilogo,
    SocialNetworksLinkChain,
    SocialNetworksMeetup,
    SocialNetworksMessenger,
    SocialNetworksOtherLink,
    SocialNetworksTelegram,
    SocialNetworksWebsiteLogo,
    SocialNetworksWhatsapp,
    /* UsersInteraction /BlackAndWhite -------------------- */
    UsersInteractionBlackAndWhiteHappyOff,
    UsersInteractionBlackAndWhiteHeartGrey,
    UsersInteractionBlackAndWhiteParticipants,
    UsersInteractionBlackAndWhitePeopleOff,
    /* UsersInteraction /BlackAndWhite -------------------- */
    UsersInteractionColorsHappyOn,
    UsersInteractionColorsHeartEmpty,
    UsersInteractionColorsHeartFilled,
    UsersInteractionColorsHeartRed,
    UsersInteractionColorsNonProfit,
    UsersInteractionColorsPeopleOn,
};
export default Vectors;
