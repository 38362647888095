// This component displays the top of the page address the googlemap (FrameFigma042)
// Import Modules
import React, { useRef } from "react";
import { View, Platform } from "react-native";
// Import Components
import Maps01 from "../../PickerSection/AddressMapPickerWeb/Maps01";
import AddressMapPickerMobile from "../../PickerSection/AdressMapMobilePicker/Code";
const ActivityDetailsAdressMap = ({ activity, setIsScriptMapLoad }) => {
    const Coords = {
        lat: activity.location.latitude,
        lng: activity.location.longitude,
    };
    // Create a mutable reference
    const myPositionRef = useRef(false);
    return (<View>
      <View style={{ zIndex: -1 }}>
        {/* view ici pour ajout des espace entre AddressAutoPicker et Map */}
        {Platform.OS === 'web' ? (<Maps01 Coords={Coords} setMyPositionGoogleUrl={() => { }} myPosition={myPositionRef}/>) : (<AddressMapPickerMobile selectedLatitude={Coords.lat} selectedLongitude={Coords.lng}/>)}
      </View>
    </View>);
};
export default ActivityDetailsAdressMap;
