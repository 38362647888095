import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    cardContainer: {
        backgroundColor: '#FFFFFF',
        shadowColor: '#000000',
        flexDirection: 'row',
        alignItems: 'center',
        padding: width * 0.025,
        borderBottomWidth: 1,
        borderBottomColor: '#BCBCBC',
    },
    profileImage: {
        width: width * 0.2,
        height: width * 0.2,
        borderRadius: width * 0.1,
    },
    infoContainer: {
        flex: 1,
        paddingLeft: width * 0.025,
    },
    nameContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    nameText: {
        fontWeight: 'bold',
        fontSize: width * 0.045,
    },
    cityText: {
        fontSize: width * 0.035,
        color: '#666666',
        marginTop: height * 0.01,
    },
    arrowImage: {
        width: width * 0.06,
        height: '100%',
        marginLeft: 'auto',
    },
});
