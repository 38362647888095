// FR Ce composant permet aux utilisateurs d'inviter des amis par e-mail.
// EN that allows users to invite friends via email.
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { View, Text, Pressable, TextInput, Modal, StyleSheet, Linking } from 'react-native';
import axios from 'axios';
import { hostname } from '../../../../hostName/hostName';
import { useSelector } from 'react-redux';
const InviteByEmail = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { user } = useSelector((state) => state.user);
    const { userId, userToken } = useSelector((state) => state.auth);
    const [friendEmail, setFriendEmail] = useState('');
    const [emailModalVisible, setEmailModalVisible] = useState(true);
    const inviteByEmail = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield axios.get(`${hostname}/sponsorships/${userId}/generateReferralLink`, {
                headers: { Authorization: `Bearer ${userToken}` },
            });
            const referralLink = response.data.referralLink;
            console.info('referralLink', referralLink);
            const emailBody = `${translations.JsonInviteFriendsScreen.JsonInvitationMessage} Voici mon lien de parrainage: ${referralLink}`;
            const mailtoLink = `mailto:${friendEmail}?subject=Invitation&body=${encodeURIComponent(emailBody)}`;
            Linking.openURL(mailtoLink);
            setEmailModalVisible(false); // Fermer le modal après l'envoi
        }
        catch (error) {
            console.error('Erreur lors de la génération du lien de parrainage', error);
        }
    });
    return (<View>
            <Modal animationType="slide" transparent={true} visible={emailModalVisible} onRequestClose={() => setEmailModalVisible(false)}>
                <View style={styles.centeredView}>
                    <View style={styles.modalView}>
                        <TextInput style={styles.input} onChangeText={setFriendEmail} value={friendEmail} placeholder="Email de votre ami" keyboardType="email-address"/>
                        <Pressable style={[styles.button, styles.buttonClose]} onPress={inviteByEmail}>
                            <Text style={styles.textStyle}>Envoyer l'invitation</Text>
                        </Pressable>
                    </View>
                </View>
            </Modal>
        </View>);
};
const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalView: {
        margin: 20,
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
    },
    button: {
        borderRadius: 20,
        padding: 10,
        elevation: 2,
    },
    buttonClose: {
        backgroundColor: '#2196F3',
    },
    textStyle: {
        color: 'white',
        fontWeight: 'bold',
        textAlign: 'center',
    },
    input: {
        height: 40,
        margin: 12,
        borderWidth: 1,
        padding: 10,
        width: 200,
    },
});
export default InviteByEmail;
