// FR le parent du profil utilisateur, il gère l'affichage global du profil
// EN  the parent of the user profile, responsible for managing the overall display of the profile
import React, { useState, useEffect } from 'react';
import { StyleSheet, ScrollView, Text, TouchableOpacity, View } from 'react-native';
import { useNavigation } from '@react-navigation/native';
// Importez vos composants TopProfile et BottomProfile
import MyProfileTopScreen from './MyProfileBodyTopScreen/Code';
import MyProfileBottomNavigator from './MyProfileBottomNavigator';
// Import du contexte d'utilisateur
import { useSelector } from 'react-redux';
import { createStackNavigator } from '@react-navigation/stack';
import EditProfileScreen from '../../ProfileModule/EditProfileSection/EditProfileNavigator';
import Vectors from '../../../constants/Vectors';
function ProfileParent({ route, navigation }) {
    // Utiliser le contexte pour accéder aux données de l'utilisateur
    // const { user, allUsers, /* fetchUserDetails */ } = useUser();
    const { user, allUsers } = useSelector((state) => state.user);
    const { activities } = useSelector((state) => state.activities);
    const [userActivities, setUserActivities] = useState([]);
    console.log('ProfileParent.js: ProfileParent');
    // Utiliser le contexte pour accéder aux données de l'utilisateur
    // const userID = route.params.userId
    // const status = route.params.status
    const userID = user._id;
    const status = user.status;
    const profileOwner = userID === (user === null || user === void 0 ? void 0 : user._id) ? user : allUsers.find((u) => u._id === userID);
    // Charger les détails de l'utilisateur au montage du composant
    // useEffect(() => {
    //   fetchUserDetails();
    // }, []);
    useEffect(() => {
        // User is organising
        const userOrganisedActivities = activities.filter((activity) => activity.author === user._id);
        // User is Attending
        const userAttendedActivities = activities.filter((activity) => activity.attendees.includes(user._id));
        setUserActivities([...userOrganisedActivities, ...userAttendedActivities]);
    }, [user._id]);
    // Retourner l'interface utilisateur
    return (<ScrollView style={styles.container} showsVerticalScrollIndicator={false}>
      <Text>Profile de {userID}</Text>
      {/* Haut de profil */}
      <MyProfileTopScreen userId={userID} user={user} profileOwner={profileOwner} navigation={navigation} status={status}/>
      {/* Bas de profil */}
      <MyProfileBottomNavigator allUsers={allUsers} profileOwner={profileOwner} userActivities={userActivities}/>
      {/* a creer */}

    </ScrollView>);
}
;
// Styles du composant ProfileParent
const styles = StyleSheet.create({
    container: {
        backgroundColor: 'white'
        // Vous pouvez ajouter plus de styles selon vos besoins
    },
});
// export default ProfileParent;
const Stack = createStackNavigator();
const headerLeftArrow = () => {
    const navigation = useNavigation();
    return (<TouchableOpacity onPress={() => navigation.goBack()}>
    <View style={{ marginLeft: 15 }}>
      <Vectors.LeftArrow />
    </View>
  </TouchableOpacity>);
};
export const ProfileStack = () => {
    const defaultScreenOptions = {
        headerShown: true,
        headerStyle: { backgroundColor: '#59C09B' },
        headerTintColor: 'white',
        headerTitleStyle: { fontWeight: 'bold', fontSize: 20 },
        headerTitleAlign: "center",
    };
    return (<Stack.Navigator>
      <Stack.Screen component={ProfileParent} name="MyProfileScreen" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
      <Stack.Screen component={EditProfileScreen} name="EditProfile" options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow() }))}/>
    </Stack.Navigator>);
};
export default ProfileStack;
