// FR une barre de navigation pour différentes sections dans une application
// EN  navigation bar for different sections within an application
import React, { useState } from 'react';
import { View, Pressable, Text, TextInput, Platform, } from 'react-native';
import MembersScreen from './MembersScreen/Code';
import FriendListScreen from './FriendListScreen/Code';
import NetworkScreen from './NetworkScreen/Code';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
const MembersNavigator = (_props) => {
    const { translations, language } = useSelector((state) => state.language);
    const [activeButton, setActiveButton] = useState("Members");
    const [subFilter, setSubFilter] = useState("");
    const [searchValue, setSearchValue] = useState("");
    return (<>
      <View key={language} style={styles.menuContainer}>
        <Pressable style={[
            styles.button,
            activeButton === "Members" && { backgroundColor: "#59c09b" },
        ]} onPress={() => setActiveButton("Members")}>
          <Text style={styles.text}>
            {translations.JsonTopNav.JsonMembersFirstLine}
          </Text>
        </Pressable>

        <View style={styles.separator}/>

        <Pressable style={[
            styles.button,
            activeButton === "Network" && { backgroundColor: "#59c09b" },
        ]} onPress={() => setActiveButton("Network")}>
          <Text style={styles.text}>
            {translations.JsonTopNav.JsonMembersSecondLineNetwork}
          </Text>
        </Pressable>

        <View style={styles.separator}/>

        <Pressable style={[
            styles.button,
            activeButton === "FriendList" && { backgroundColor: "#59c09b" },
        ]} onPress={() => setActiveButton("FriendList")}>
          <Text style={styles.text}>
            {translations.JsonTopNav.JsonMembersSecondLineFriends}
          </Text>
        </Pressable>
      </View>
      {activeButton === "Members" && (<>
          <View style={styles.secondaryMenuContainer}>
            <Pressable style={[
                styles.smallButton,
                subFilter === "Male" && { backgroundColor: "#59c09b" },
            ]} onPress={() => setSubFilter("Male")}>
              {/* Remplacez par <Image source={MaleIcon} style={{ width: 21, height: 21 }} /> */}
              <Text style={styles.smallText}>Male</Text>
            </Pressable>

            <View style={styles.smallSeparator}/>

            <Pressable style={[
                styles.smallButton,
                subFilter === "Pros" && { backgroundColor: "#59c09b" },
            ]} onPress={() => setSubFilter("Pros")}>
              <Text style={styles.smallText}>Pros</Text>
            </Pressable>

            <View style={styles.smallSeparator}/>

            <Pressable style={[
                styles.smallButton,
                subFilter === "Female" && { backgroundColor: "#59c09b" },
            ]} onPress={() => setSubFilter("Female")}>
              {/* Remplacez par <Image source={FemaleIcon} style={{ width: 21, height: 21 }} /> */}
              <Text style={styles.smallText}>Female</Text>
            </Pressable>
          </View>

          <View style={styles.searchContainer}>
            {/* Remplacez par une icône de recherche si nécessaire */}
            {/* <Image source={SearchIcon} style={styles.searchIcon} /> */}
            <TextInput style={styles.searchInput} placeholder={translations.JsonMembersScreen.JsonSearchMemberPlaceHolder} placeholderTextColor="#CCCCCC" onChangeText={(text) => setSearchValue(text)}/>
          </View>
        </>)}

      {activeButton === "Members" && <MembersScreen searchValue={searchValue}/>}
      {activeButton === "Network" && <NetworkScreen />}
      {activeButton === "FriendList" && <FriendListScreen />}
    </>);
};
export default MembersNavigator;
