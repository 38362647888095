import React from 'react';
import { View, Text, Image, Pressable, Platform } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { hostname } from '../../../../hostName/hostName';
import ProfileMedal from '../../ProfileSection/ProfileMedal/Code';
import { Vectors } from "../../../constants/Vectors";
import { calculateUserPoints } from '../../../utils/Functions/UserPointsCalculator';
// Import styles based on platform
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
const UserCardFriend = ({ user }) => {
    var _a, _b;
    const navigation = useNavigation();
    const userPoints = calculateUserPoints(user === null || user === void 0 ? void 0 : user._id);
    if (!user) {
        return <Text>Utilisateur introuvable</Text>;
    }
    const imageUrl = user.profileImage ? user.profileImage : `${hostname}/uploads/${user.profileImage}`;
    const handleMemberPress = () => {
        // Type assertion for navigation params
        navigation.navigate('ProfileScreen', { userId: user._id });
    };
    const formattedCity = (_b = (_a = user === null || user === void 0 ? void 0 : user.city) === null || _a === void 0 ? void 0 : _a.split(',')[0]) === null || _b === void 0 ? void 0 : _b.trim();
    return (<Pressable style={styles.cardContainer} onPress={handleMemberPress}>
      <Image source={{ uri: imageUrl }} style={styles.profileImage}/>
      <ProfileMedal points={userPoints} role={user === null || user === void 0 ? void 0 : user.roleApp} isAmbassador={false} size={24}/>
      <View style={styles.infoContainer}>
        <View style={styles.nameContainer}>
          <Text style={styles.nameText}>{user.userName}</Text>
        </View>
        <Text style={styles.cityText}>{formattedCity}</Text>
      </View>
      <Vectors.RightArrowGrey style={styles.arrowImage}/>
    </Pressable>);
};
export default UserCardFriend;
