// FR calcule et met à jour les points d'un utilisateur en fonction de diverses activités et interactions pour Drawer
// EN calculates and updates a user's points based on various activities and interactions for Drawer
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect, useState } from 'react';
import { hostname } from '../../../hostName/hostName';
import { useSelector } from 'react-redux';
export const CalculateUserPointsDrawer = (userId) => {
    const { allUsers } = useSelector((state) => state.user);
    const { userToken } = useSelector((state) => state.auth);
    const [userPoints, setUserPoints] = useState(0);
    useEffect(() => {
        const fetchUserDetails = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g;
            try {
                const response = yield fetch(`${hostname}/userLikes`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                const data = yield response.json();
                const userLikesReceived = data.filter((like) => like.likedUser === userId);
                const user = allUsers.find(user => user._id === userId);
                if (!user)
                    return;
                const userProfileVisits = ((_a = user === null || user === void 0 ? void 0 : user.profileVisits) === null || _a === void 0 ? void 0 : _a.length) > 0 ? [...new Set((_b = user.profileVisits) === null || _b === void 0 ? void 0 : _b.map((visit) => visit.visitor))].length : 0;
                const userFriends = ((_c = user === null || user === void 0 ? void 0 : user.friends) === null || _c === void 0 ? void 0 : _c.length) > 0 ? user.friends.length : 0;
                const userSponsoredUsers = ((_d = user === null || user === void 0 ? void 0 : user.sponsoredUsers) === null || _d === void 0 ? void 0 : _d.length) > 0 ? user.sponsoredUsers.length : 0;
                const activitiesOrganising = (_e = user.activitiesOrganising) !== null && _e !== void 0 ? _e : 0;
                const activitiesAttending = (_f = user.activitiesAttending) !== null && _f !== void 0 ? _f : 0;
                const activitiesCoOrganising = (_g = user.activitiesCoOrganising) !== null && _g !== void 0 ? _g : 0;
                const calculatedPoints = userFriends * 20 +
                    activitiesOrganising * 100 +
                    activitiesAttending * 25 +
                    activitiesCoOrganising * 50 +
                    userProfileVisits * 5 +
                    userSponsoredUsers * 250 +
                    userLikesReceived.length * 10;
                console.log('User Likes:', userLikesReceived);
                console.log('Calculated Points:', calculatedPoints);
                setUserPoints(calculatedPoints);
                // Update user points on the server
                yield fetch(`${hostname}/users/${userId}/updatePoints`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ appPoints: calculatedPoints }),
                });
            }
            catch (error) {
                console.error('Error fetching user details:', error);
            }
        });
        fetchUserDetails();
    }, [userId, userToken, allUsers]);
    return userPoints;
};
