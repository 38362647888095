var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { SafeAreaView, ScrollView, Text, Pressable, View, Platform, TouchableOpacity } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import { Vectors } from '../../constants/Vectors';
import { Share } from 'react-native';
// Import des composants spécifiques pour chaque type d'invitation
import InviteByMessenger from './SocialInvite/InviteByMessenger';
import InviteByWhatsApp from './SocialInvite/InviteByWhatsApp';
import InviteBySMS from './SocialInvite/InviteBySMS';
import InviteByTwitter from './SocialInvite/InviteByTwitter';
import InviteByInstagram from './SocialInvite/InviteByInstagram';
import InviteByTikTok from './SocialInvite/InviteByTikTok';
import InviteBySnapchat from './SocialInvite/InviteBySnapchat';
import InviteByLink from './SocialInvite/InviteByLink';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const InviteFriends = ({ route }) => {
    var _a, _b, _c;
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const navigation = useNavigation();
    const [emailModalVisible, setEmailModalVisible] = useState(false);
    const user = (_a = route.params) === null || _a === void 0 ? void 0 : _a.user;
    const userId = (_b = route.params) === null || _b === void 0 ? void 0 : _b.userId;
    const userToken = (_c = route.params) === null || _c === void 0 ? void 0 : _c.userToken;
    const share = (shareOptions) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield Share.share(shareOptions);
        }
        catch (error) {
            console.error('Erreur lors du partage:', error);
        }
    });
    // Définition des boutons sociaux avec les composants correspondants
    const socialButtons = [
        { Icon: Vectors.EmailEnvelope, text: translations.JsonInviteFriendsScreen.JsonInviteEmail, /* action: () => setEmailModalVisible(true),*/ shareOptions: { message: 'Yeb, \n \n Voici le lien pour se connecter à l\'application :\n https://Socializus.app \n\n On y va ? \uD83D\uDE01' /*, url: 'https://Socializus.app'*/ } },
        { Icon: Vectors.Messenger, Component: InviteByMessenger, text: translations.JsonInviteFriendsScreen.JsonInviteFacebook, shareOptions: { message: 'Yeb, \n \n Voici le lien pour se connecter à l\'application :\n https://Socializus.app \n\n On y va ? \uD83D\uDE01' /*, url: 'https://Socializus.app'*/ } },
        { Icon: Vectors.PhoneMsg, Component: InviteBySMS, text: translations.JsonInviteFriendsScreen.JsonInviteTextMessage, shareOptions: { message: 'Yeb, \n \n Voici le lien pour se connecter à l\'application :\n https://Socializus.app \n\n On y va ? \uD83D\uDE01' /*, url: 'https://Socializus.app'*/ } },
        { Icon: Vectors.Whatsapp, Component: InviteByWhatsApp, text: translations.JsonInviteFriendsScreen.JsonInviteWhatsapp, shareOptions: { message: 'Yeb, \n \n Voici le lien pour se connecter à l\'application :\n https://Socializus.app \n\n On y va ? \uD83D\uDE01' } },
        { Icon: Vectors.Instagram, Component: InviteByInstagram, text: translations.JsonInviteFriendsScreen.JsonInviteInstagram, shareOptions: { message: 'Yeb, \n \n Voici le lien pour se connecter à l\'application :\n https://Socializus.app \n\n On y va ? \uD83D\uDE01' /*, url: 'https://Socializus.app'*/ } },
        { Icon: Vectors.Snapchat, Component: InviteBySnapchat, text: translations.JsonInviteFriendsScreen.JsonInviteSnapchat, shareOptions: { message: 'Yeb, \n \n Voici le lien pour se connecter à l\'application :\n https://Socializus.app \n\n On y va ? \uD83D\uDE01' /*, url: 'https://Socializus.app'*/ } },
        { Icon: Vectors.Twitter, Component: InviteByTwitter, text: translations.JsonInviteFriendsScreen.JsonInviteTwitter, shareOptions: { message: 'Yeb, \n \n Voici le lien pour se connecter à l\'application :\n https://Socializus.app \n\n On y va ? \uD83D\uDE01' /*, url: 'https://Socializus.app'*/ } },
        { Icon: Vectors.Tiktok, Component: InviteByTikTok, text: translations.JsonInviteFriendsScreen.JsonInviteTiktok, shareOptions: { message: 'Yeb, \n \n Voici le lien pour se connecter à l\'application :\n https://Socializus.app \n\n On y va ? \uD83D\uDE01' /*, url: 'https://Socializus.app'*/ } },
        { Icon: Vectors.Link, Component: InviteByLink, text: translations.JsonInviteFriendsScreen.JsonInviteWithLink, shareOptions: { message: 'Yeb, \n \n Voici le lien pour se connecter à l\'application :\n https://Socializus.app \n\n On y va ? \uD83D\uDE01' /*, url: 'https://Socializus.app'*/ } },
    ];
    return (<SafeAreaView style={styles.container}>
      <ScrollView style={{ backgroundColor: 'white' }}>
        <View style={styles.inviteStyle}>
          <TouchableOpacity style={styles.btnContainer} onPress={() => share({ message: 'Invitez vos amis sur Socializus !', url: 'https://example.com' })}>
            <Text style={styles.btnText}>Share</Text>
          </TouchableOpacity>
          <View style={styles.friendship}>
            <Vectors.Friendship width="100" height="100"/>
          </View>
          <View style={styles.inviteFriendsView}>
            <View style={styles.numberInviteFriendsView}>
              <View style={styles.numberInvitedFriendView}>
                <Text style={styles.numberInvitedFriendText}>12</Text>
              </View>
              <Text style={styles.numberInviteFriendsText}>You invited friends in the app and{"\n"} they are in your friend list.</Text>
            </View>
            <View style={styles.inviteFriendsStyle}>
              <Text style={styles.inviteTextFriendsStyle}>{translations.JsonInviteFriendsScreen.JsonInviteFriendReferralCode} <Text style={styles.specialText}> 376 HA</Text> </Text>
            </View>
          </View>
          <Text style={styles.countText}> {translations.JsonInviteFriendsScreen.JsonInvitationFriendsMethods}</Text>
          <View style={styles.dispositionInvitedIcon}>
            {socialButtons.map(({ Icon, text, shareOptions }, index) => (<Pressable key={index} style={styles.social} onPress={() => share(shareOptions)}>
                <View style={styles.Vectorstyles}>
                  <Icon width="60" height="60"/>
                </View>

                <View style={styles.txt}><Text>{text}</Text></View>
              </Pressable>))}
          </View>
        </View>
        {/* <InviteByEmail /> */}
      </ScrollView>
    </SafeAreaView>);
};
export default InviteFriends;
