//🇫🇷 Ce fichier gère le style mobile du composant OnlyFriendsCanChatModal.tsx (FrameFigma001 FrameFigma003)
//🇬🇧 This file manages the mobile style of the OnlyFriendsCanChatModal.tsx component (FrameFigma001 FrameFigma003)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        marginTop: '10%',
        width: 340,
        height: 250,
    },
    content: {
        flex: 1,
        alignItems: 'center',
        //style for start text
        // backgroundColor: 'rgba(0, 0, 0, 0.6)',
        // marginBottom: '85%',
        padding: 25,
        width: '100%',
        //marginBottom: '70%',
    },
    btnContainer: {
        marginTop: '50%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'white',
        borderRadius: 15,
        paddingVertical: 10,
        paddingHorizontal: 20,
        width: 100,
        height: 80,
        shadowColor: 'rgba(0, 0, 0, 0.5)',
        shadowOffset: { width: 0, height: 8 },
        shadowOpacity: 0.5,
        shadowRadius: 10,
        elevation: 5,
        borderWidth: 2,
        borderColor: '#38A858',
    },
    btnStart: {
        width: '100%',
        height: '100%',
        resizeMode: 'contain',
    },
    btnText: {
        color: '#000000',
        fontSize: 24,
        fontWeight: 'bold',
    },
    condition: {
        //marginTop:'25%',
        width: '90%',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center'
    },
    terms: {
        fontSize: 13,
        color: 'white',
        marginLeft: 4,
    },
    termsBold: {
        fontSize: 13,
        fontWeight: 'bold',
        marginLeft: 4,
        color: 'white',
    },
});
