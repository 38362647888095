import './public/global.css';
import {AppRegistry} from 'react-native';
import App from './App';
import {name as appName} from './app.json';

// Avant d'ajouter les écouteurs d'événements, filtrez les warnings
const originalConsoleWarn = console.warn;
console.warn = (...args) => {
  const warningsToIgnore = [
    'useNativeDriver',
    'editable is deprecated. Use readOnly',
    'keyboardType is deprecated. Use inputMode',
    'selectable prop is deprecated. Use styles.userSelect',
    'accessibilityLabel is deprecated. Use aria-label',
    'TouchableWithoutFeedback is deprecated. Please use Pressable',
    'Image: style.resizeMode is deprecated. Please use props.resizeMode',
    'accessibilityRole is deprecated. Use role.',
    'props.pointerEvents is deprecated. Use style.pointerEvents',
    'TouchableOpacity is deprecated. Please use Pressable.',
    'focusable is deprecated.',
    '"textAlignVertical" style is deprecated. Use "verticalAlign".',
    'Image: style.tintColor is deprecated. Please use props.tintColor.',
    'returnKeyType is deprecated. Use enterKeyHint.',
    'accessibilityDisabled is deprecated.',
    'StyleSheet.compose(a, b) is deprecated; use array syntax, i.e., [a,b].',
  ];

  if (warningsToIgnore.some(ignore => args[0].includes(ignore))) {
    return; // Ne pas afficher le warning dans la console
  }

  // Sinon, affichez le warning comme d'habitude
  originalConsoleWarn(...args);
};

document.addEventListener('DOMContentLoaded', () => {
  AppRegistry.registerComponent(appName, () => App);
  AppRegistry.runApplication(appName, {
    initialProps: {},
    rootTag: document.getElementById('root'),
  });
});
