//FR  définit un composant simple appelé NetworkScreen
//EN defines a simple component named NetworkScreen
import React from 'react';
import { View, Text, FlatList, Image, Platform } from 'react-native';
import { useSelector } from 'react-redux';
// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const NetworkScreen = () => {
    // const {networkUsers} = useActivity();
    const { networkUsers } = useSelector((state) => state.activities);
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    console.log("networkUsers", networkUsers);
    const renderUser = ({ item: user }) => (<View style={styles.userContainer}>
      <Image source={{ uri: user.profileImage }} style={styles.userImage}/>
      <View style={styles.userInfo}>
        <Text style={styles.userName}>{user.firstName} {user.lastName}</Text>
        <Text style={styles.userRole}>{user.rolePremium}</Text>
        <Text style={styles.userCity}>{user.city}</Text>
      </View>
    </View>);
    return (<View style={styles.container}>

      <FlatList showsHorizontalScrollIndicator={false} data={networkUsers} keyExtractor={item => item._id} renderItem={renderUser} contentContainerStyle={styles.listContainer}/>
    </View>);
};
export default NetworkScreen;
