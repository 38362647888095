var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { View, Text, Platform, Animated } from 'react-native';
import MyActivityCard from '../../../../components/CardSection/MyActivityCard/Code';
import { useFocusEffect } from '@react-navigation/native';
import { formatDate, compareActivities, ActivitiesWithDate } from '../../../../utils/Functions/functions';
import { userServices } from '../../../../services/user/UserServices';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web'
    ? require('./StylesWeb').default
    : require('./StylesMobile').default;
const LikedScreen = ({ route }) => {
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { activities } = useSelector((state) => state.activities);
    const [likedActivities, setLikedActivities] = useState([]);
    const [userId, setUserId] = useState(null);
    const { user } = useSelector((state) => state.user);
    const { isDisplayBannerVisible, setIsDisplayBannerVisible } = route.params;
    const scrollY = useRef(new Animated.Value(0)).current;
    useFocusEffect(useCallback(() => {
        setIsDisplayBannerVisible(true);
    }, []));
    useEffect(() => {
        const listener = scrollY.addListener(({ value }) => {
            if (value > 0) {
                setIsDisplayBannerVisible(false);
            }
            else {
                setIsDisplayBannerVisible(true);
            }
        });
        return () => {
            scrollY.removeListener(listener);
        };
    }, [scrollY]);
    // Récupération des activités likées
    const fetchLikedActivities = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const userId = user._id;
            if (!userId) {
                console.error('User ID is null');
                return;
            }
            setUserId(userId);
            // const MyProfilUser = await userServices.getUserByFilter(await (await userServices.getUsers()).data,user._id);  
            const users = yield userServices.getUsers();
            const myProfileUser = yield userServices.getUserByFilter(users.data, userId);
            // Ensure `activityLikes` is defined and is an array
            const activityLikes = Array.isArray(myProfileUser === null || myProfileUser === void 0 ? void 0 : myProfileUser.activityLikes) ? myProfileUser.activityLikes : [];
            const likedActivities = activities.filter((activity) => activityLikes.includes(activity._id));
            setLikedActivities(likedActivities);
        }
        catch (error) {
            console.error('Erreur lors de la récupération des activités aimées :', error);
        }
    });
    useFocusEffect(useCallback(() => {
        fetchLikedActivities();
    }, []));
    const groupedActivities = useCallback(() => {
        const grouped = new Map();
        likedActivities.forEach((activity) => ActivitiesWithDate(activity, grouped));
        const sortedGroupedActivities = Array.from(grouped.entries()).sort(([date1], [date2]) => {
            return new Date(date1).valueOf() - new Date(date2).valueOf();
        });
        return new Map(sortedGroupedActivities);
    }, [likedActivities]);
    const sortedGroupedActivities = groupedActivities();
    sortedGroupedActivities.forEach(activities => {
        activities.sort(compareActivities);
        console.log(sortedGroupedActivities);
    });
    return (<View style={styles.colorBackroundWhite}>
      <Animated.ScrollView onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollY } } }], { useNativeDriver: false })} showsVerticalScrollIndicator={false}>
        {likedActivities.length > 0 ? (Array.from(sortedGroupedActivities).map(([date, activities]) => (<View key={date}>
              <Text style={styles.DisplayDate}>{formatDate(date)}</Text>
              {activities.map((activity) => (<MyActivityCard key={activity._id} activity={activity} userId={userId}/>))}
            </View>))) : (<View style={styles.textTwoContainer}>
            <Text style={styles.textTwo}>
              {translations.JsonActivityPages.JsonMyActivitiesScreen.JsonLikedActivityEmpty}
            </Text>
          </View>)}
      </Animated.ScrollView>
    </View>);
};
export default LikedScreen;
