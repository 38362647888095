import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        minHeight: '100%',
        backgroundColor: 'white',
        width: '100%',
        paddingHorizontal: '4%',
        paddingTop: 15,
    },
    textCenter: {
        textAlign: 'center',
        fontSize: 16,
        flex: 1,
    },
    notifsContainer: {
        gap: 15,
        marginTop: 15,
    },
    marginBas: {
        marginBottom: 60,
    },
    notifContainer: {
        gap: 15,
        alignItems: 'flex-end',
    },
    notifParentContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        paddingLeft: 10,
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#F8F8F8',
        borderColor: '#DDDDDD',
        borderWidth: 1,
        borderRadius: 10,
        minHeight: 48,
    },
    notifChildContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        padding: 10,
        paddingLeft: 10,
        alignItems: 'center',
        width: '85%',
        backgroundColor: '#F8F8F8',
        borderColor: '#DDDDDD',
        borderWidth: 1,
        borderRadius: 10,
        minHeight: 48,
    },
});
