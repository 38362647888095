import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
        paddingHorizontal: 10,
    },
    containerRow: {
        flexDirection: 'row',
        height: 60,
        width: '100%'
    },
    containerCol: {
        flexDirection: 'column',
        height: 50,
        position: 'relative',
        justifyContent: 'space-around',
        left: 5,
    },
    imageProfile: {
        width: 91,
        height: 91,
        borderRadius: 45.5,
        position: 'relative',
        top: '-50%',
        left: '1%',
        borderWidth: 2,
        borderColor: 'white',
    },
    textName: {
        fontFamily: "Poppins",
        fontSize: 14,
        fontWeight: '500',
        lineHeight: 21,
        top: 10
    },
    descriptionAuthor: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: '70%',
    },
    Icon: {
        width: '50%',
        height: '100%',
        position: 'absolute',
        left: '65%',
    },
    coOrganizersContainer: {
        flexDirection: 'row',
        width: '100%',
        height: 'auto',
        paddingLeft: 10,
    },
    textCoOrganizers: {
        fontFamily: "Poppins",
        fontSize: 13,
        fontWeight: '500',
        lineHeight: 19.5,
        marginLeft: 5,
    },
    separator: {
        width: '100%',
        height: 1,
        backgroundColor: '#9D9D9D',
        borderRadius: 1,
        marginVertical: 5,
        opacity: 0.5
    }
});
export default styles;
