// Fr  Styles de EditClassicOrStepByStepActivityScreen( FrameFigma031 )
// En  Styles of EditClassicOrStepByStepActivityScreen( FrameFigma031 )
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        zIndex: 1,
        width: 450,
    },
    text: {
        marginTop: 15,
        fontWeight: 'bold',
        alignItems: 'center',
        fontSize: 18,
    },
    questionContainer: {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: '5%',
    },
    textQuestion: {
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        fontSize: 18,
    },
    classic: {
        borderWidth: 1,
        width: '45%',
        borderTopLeftRadius: 13,
        borderBottomLeftRadius: 13,
        padding: 7,
    },
    withStep: {
        borderWidth: 1,
        width: '45%',
        borderTopRightRadius: 13,
        borderBottomRightRadius: 13,
        padding: 7,
    },
    input: {
        width: '90%',
        alignSelf: 'center',
        marginBottom: 10,
    },
    activeQuestion: {
        backgroundColor: '#59C09B',
    },
    dateContainer: {
        width: '90%',
        flexDirection: 'row',
        alignItems: 'center',
        alignSelf: 'center',
    },
    switchBtn: {
        marginVertical: 2.5,
        width: '90%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        alignSelf: 'center',
    },
    textbtn: {
        fontSize: 18,
        fontWeight: 'bold',
        marginVertical: 10,
    },
    //Style du modal en cours de création
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        top: -50,
    },
    modalContent: {
        width: 300,
        height: 200,
        backgroundColor: '#59C09B',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    }
});
