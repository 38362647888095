var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// 🇫🇷 L'écran de la quatrième et dernière étape de la création de profile (FrameFigma012B)
// 🇬🇧 The forth and final step screen of a multi-step registration process (FrameFigma012B)
import React, { useState, useEffect } from 'react';
import { View, Pressable, Platform, Text, Image } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Vectors from '../../../../constants/Vectors';
import { useNavigation } from '@react-navigation/native';
import { useError } from '../../../../contexts/ErrorContext';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import ProgressBar from '../../../../components/ProfileSection/ProgressBar/Code';
import ProfileImagePickerModal from '../../../../components/PickerSection/ImagePicker/ProfileImagePickerModal';
import ImageCropperWeb from '../../../../components/ImageCropperSection/ImageProfileCrop/Code';
import ImageCropperMobile from '../../../../components/ImageCropperSection/ImageProfileCrop/CodeMobile';
import Modal from '../../../../components/ModalPopUpModule/ModalSection/ActivityDetailsModal/Code';
import { userServices } from '../../../../services/user/UserServices';
import { useDispatch, useSelector } from 'react-redux';
import UserSlice from '../../../../redux/Features/User/UserSlice';
import { nanoid } from '@reduxjs/toolkit';
import AuthSlice from '../../../../redux/Features/Auth/AuthSlice';
import { setToken } from '../../../../services/Axios/AxiosService';
// Import styles based on platform
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const Step4Screen = () => {
    var _a;
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { error, setError } = useError(); // Initialize error and setError from useError hook
    const [isLoading, setIsLoading] = useState(false);
    const [isImageSelected, setIsImageSelected] = useState(false);
    const { user } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const setUser = (user) => {
        dispatch(UserSlice.actions.setUser({ id: nanoid(), value: user }));
    };
    const login = (token, userData) => {
        dispatch(AuthSlice.actions.login({ id: nanoid(), value: { token, userData } }));
        setToken(token);
    };
    const navigation = useNavigation();
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [sexe, setSexe] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [city, setCity] = useState('');
    const [accountType, setAccountType] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isVerified, setIsVerified] = useState('');
    const [userName, setUserName] = useState('');
    const [progress, setProgress] = useState(0);
    const [cropperModalVisible, setCropperModalVisible] = useState(false);
    useEffect(() => {
        const fetchUserDetails = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const savedSexe = yield AsyncStorage.getItem('sexe'); //From CreateProfileSection (Step1)
                const savedFirstName = yield AsyncStorage.getItem('firstname'); //From CreateProfileSection (Step2)
                const savedLastName = yield AsyncStorage.getItem('lastname'); //From CreateProfileSection (Step2)
                const savedCity = yield AsyncStorage.getItem('city'); //From CreateProfileSection (Step3)
                const savedAccountType = yield AsyncStorage.getItem('accountType'); //From CreateProfileSection (Step1)
                const savedEmail = yield AsyncStorage.getItem('email'); //From LoginRegisterSection (EmailScreen)
                const savedPassword = yield AsyncStorage.getItem('password'); //From LoginRegisterSection (NewUserPasswordScreen)
                const savedIsVerified = yield AsyncStorage.getItem('isVerified'); //From LoginRegisterSection (VerifyEmailScreen)
                const savedUserName = yield AsyncStorage.getItem('userName'); //From CreateProfileSection (Step3)
                setSexe(savedSexe || '');
                setFirstName(savedFirstName || '');
                setLastName(savedLastName || '');
                setCity(savedCity || '');
                setAccountType(savedAccountType || '');
                setEmail(savedEmail || '');
                setPassword(savedPassword || '');
                setIsVerified(savedIsVerified || '');
                setUserName(savedUserName || '');
            }
            catch (error) {
                setError(error.message);
                console.error("Error fetching user details from AsyncStorage", error);
            }
        });
        fetchUserDetails();
    }, []);
    const openImagePickerModal = () => {
        setModalVisible(true);
    };
    const saveProfile = () => __awaiter(void 0, void 0, void 0, function* () {
        // Validate image selection
        if (!isImageSelected) {
            //console.log('Please select an image before continuing.');
            setError(translations.JsonCreateProfilePages.JsonFakeUserMessage); // Set error using setError from useError hook
            return;
        }
        setIsLoading(true); // Initiate loading state
        try {
            // Validate username existence
            if (!userName) {
                setError("Username is not defined.");
                console.error("Username is not defined.");
                setIsLoading(false);
                return;
            }
            // Retrieve additional data from AsyncStorage
            const sponsorId = yield AsyncStorage.getItem('sponsorId'); //From LoginRegisterSection (Sponsorship)
            const latitude = parseFloat((yield AsyncStorage.getItem('latitude')) || '0'); //From CreateProfileSection (Step3)
            const longitude = parseFloat((yield AsyncStorage.getItem('longitude')) || '0'); //From CreateProfileSection (Step3)
            const locationType = yield AsyncStorage.getItem('locationType'); //From CreateProfileSection (Step1)
            // Create FormData object for multipart/form-data submission
            const formDataToSend = new FormData();
            // Prepare user data object
            const userData = Object.assign({ sexe: sexe, firstName: firstName, lastName: lastName, city: city, email: email, password: password, isVerified: isVerified, userName: userName, accountType: accountType, locationType: locationType }, (sponsorId && { sponsorId: sponsorId }));
            // Append user data to FormData
            Object.keys(userData).forEach(key => {
                const value = userData[key];
                if (value !== undefined) {
                    formDataToSend.append(key, value);
                }
            });
            // Append profile image if selected
            if (selectedImage && selectedImage.file) {
                console.info('Selected image:', selectedImage);
                Platform.OS === 'web' ? formDataToSend.append('profileImage', selectedImage.file) :
                    formDataToSend.append('profileImage', {
                        uri: 'file://' + selectedImage.imageUrl, // File URI
                        name: selectedImage.file.name, // File name
                        type: selectedImage.file.type, // MIME type of the file (adjust this based on your file type)
                    });
            }
            // Append geolocation data
            formDataToSend.append('location[type]', 'Point');
            formDataToSend.append('location[coordinates][0]', longitude.toString());
            formDataToSend.append('location[coordinates][1]', latitude.toString());
            // Send POST request to create user profile
            // const response = await fetch(`${hostname}/users`, {
            //   method: 'POST',
            //   body: formDataToSend,
            // });
            const responseData = yield userServices.addUser(formDataToSend);
            if (responseData) {
                // const responseData = await response.json();
                // Perform login with received token and user data
                login(responseData.token, {
                    _id: responseData.user._id,
                    email: responseData.user.email,
                    userName: responseData.user.userName,
                    profileImage: responseData.user.profileImage,
                });
                // Navigate to Home screen upon successful profile creation
                if (navigation) {
                    const user = yield userServices.getUserById(responseData.user._id);
                    setUser(user);
                    navigation.navigate('Home');
                }
                else {
                    console.error('Navigation object is not defined.');
                }
            }
            else {
                // Handle server errors
                setError(`Failed to save profile on server`);
            }
        }
        catch (error) {
            // Handle general errors
            setError(`Error saving profile: ${error}`);
            console.log('Error saving profile:', error);
        }
        finally {
            setIsLoading(false); // Reset loading state
        }
    });
    const onImageSelectedHandler = (data) => {
        console.log("the image selected ", data);
        setSelectedImage(data);
        setIsImageSelected(true);
        setCropperModalVisible(true);
    };
    // Handle Cropped Image
    const handleCroppedImage = (croppedImage) => {
        setError('');
        setSelectedImage(croppedImage);
        setCropperModalVisible(false);
        simulateProgress();
    };
    // 🇫🇷 Le simulate progress ne représente pas les calcul éfféctuer dans le back-end, mais on ma explicitement demander de le laisser
    // 🇬🇧 The simulate progress does not represent the calculations carried out in the back-end, but I was explicitly asked to leave it
    const simulateProgress = () => {
        setIsLoading(true);
        setProgress(0);
        const interval = setInterval(() => {
            setProgress(currentProgress => {
                const newProgress = currentProgress + 0.1;
                if (newProgress >= 1) {
                    clearInterval(interval);
                    setIsLoading(false);
                    return 1;
                }
                return newProgress;
            });
        }, 100);
    };
    return (<View style={styles.profile}>
      {error && (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>)}
      <View style={styles.mainView}>
        <View style={styles.picture}>
          <Pressable onPress={openImagePickerModal} style={styles.pressableContainer}>
            {selectedImage ? (<Image source={{ uri: (selectedImage === null || selectedImage === void 0 ? void 0 : selectedImage.imageUrl) || ((_a = selectedImage === null || selectedImage === void 0 ? void 0 : selectedImage.file) === null || _a === void 0 ? void 0 : _a.toString()) || '' }} style={styles.image}/>) : (<Vectors.ProfilesNoGender />)}
          </Pressable>
        </View>
        {isLoading && <ProgressBar progress={progress}/>}
        <View style={styles.camera}>
          <Pressable onPress={openImagePickerModal}>
            <Vectors.ProfilesCamera />
          </Pressable>
        </View>
        {isImageSelected && (<Modal modalVisible={cropperModalVisible} onClose={() => setCropperModalVisible(false)}>
            {Platform.OS === 'web' ? (<ImageCropperWeb file={selectedImage === null || selectedImage === void 0 ? void 0 : selectedImage.file} handleCroppedImage={handleCroppedImage}/>)
                : (<ImageCropperMobile uri={selectedImage === null || selectedImage === void 0 ? void 0 : selectedImage.imageUrl} handleCroppedImage={handleCroppedImage}/>)}

          </Modal>)}
        {modalVisible && (<ProfileImagePickerModal onImageSelected={onImageSelectedHandler} modalVisible={modalVisible} setModalVisible={setModalVisible}/>)}
        <View style={styles.btnContainer}>
          <ButtonNext conditionNeed={isImageSelected && !isLoading} handleNextStep={saveProfile} btnText={translations.JsonCreateProfilePages.JsonSaveButton}/>
        </View>
      </View>
    </View>);
};
export default Step4Screen;
