const styles = {
    logoutButton: {
        flexDirection: 'row',
        alignItems: 'center',
        //paddingVertical: 5,
    },
    drawerItemText: {
        fontWeight: 'bold',
        marginLeft: 10,
        fontSize: 25,
    },
    modal: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        width: 250,
        backgroundColor: 'white',
        padding: 20,
        borderRadius: 10,
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
        elevation: 5,
    },
    modalText: {
        fontSize: 18,
        marginBottom: 15,
        textAlign: 'center',
    },
    modalButton: {
        padding: 10,
        margin: 5,
        backgroundColor: '#2196F3',
        borderRadius: 5,
    },
    modalButtonText: {
        color: 'white',
        textAlign: 'center',
    },
};
export default styles;
