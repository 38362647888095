import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    buttonNav: {
        flex: 1,
        borderRightColor: "white",
        justifyContent: "center",
        height: 50,
    },
    titleText: {
        fontSize: 18,
        fontWeight: "bold",
        textAlign: "center",
    },
    fab: {
        position: 'absolute',
        bottom: 50,
        left: 20,
        backgroundColor: 'white',
        width: 56,
        height: 56,
        borderRadius: 28,
        alignItems: 'center',
        justifyContent: 'center',
        elevation: 10,
    },
    plus: {
        fontSize: 50,
        color: '#59C09B',
        lineHeight: 56,
    },
});
