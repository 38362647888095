import React from 'react';
import { View, StyleSheet } from 'react-native';
const OnlineStatus = ({ host = null, w = 10, h = 10, br = 5, bw = 1, deg = '45', right = 24, left = 42, bottom = 8 }) => {
    const styles = StyleSheet.create({
        halfCircle: {
            position: 'absolute',
            width: 12,
            height: 6,
            borderTopLeftRadius: 9,
            borderTopRightRadius: 9,
            transform: [{ rotate: `-${deg}deg` }],
            backgroundColor: host ? '#05FF00' : 'grey',
            left: left,
            bottom: bottom,
            justifyContent: 'center',
            alignItems: 'center',
        },
        status: {
            width: w,
            height: h,
            borderRadius: br,
            backgroundColor: '#2AE09F',
            borderWidth: bw,
            borderColor: 'white',
            position: 'relative',
            alignSelf: 'center',
            top: host !== null ? bottom / 2 - 1 : undefined,
            right: host === null ? right : undefined,
            bottom: host === null ? bottom : undefined,
        },
    });
    return (<>
            {host !== null ?
            <View style={styles.halfCircle}>
                    <View style={styles.status}/>
                </View>
            :
                <View style={styles.status}/>}
        </>);
};
export default OnlineStatus;
