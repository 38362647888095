import React, { useState, useEffect } from "react";
import { Text, View, ActivityIndicator } from "react-native";
import { format } from "date-fns";
import styles from './StylesWeb';
export default function Message({ message, index, messageArray, userBasics, user }) {
    var _a, _b, _c;
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (userBasics && userBasics[message.sender]) {
            setLoading(false);
        }
    }, [userBasics, message.sender]);
    function isShowDate(date1, date2, index) {
        if (index > 0)
            return !((date1 === null || date1 === void 0 ? void 0 : date1.slice(0, 10)) === (date2 === null || date2 === void 0 ? void 0 : date2.slice(0, 10)));
        else
            return true;
    }
    if (loading) {
        return <ActivityIndicator size="large" color="#0000ff"/>;
    }
    const senderInfo = userBasics[message.sender];
    return (<View key={message._id}>
            {messageArray && isShowDate((_a = messageArray[index - 1]) === null || _a === void 0 ? void 0 : _a.timestamp, (_b = messageArray[index]) === null || _b === void 0 ? void 0 : _b.timestamp, index) &&
            <Text style={styles.dateCenter}>{format(new Date((_c = messageArray[index]) === null || _c === void 0 ? void 0 : _c.timestamp), 'dd/MM/yyyy')}</Text>}

            {message.sender !== user._id
            ?
                <View style={styles.messageContainerLeft}>
                    <Text style={senderInfo.sexe === "Male" ? styles.nameMale : styles.nameFemale}>{senderInfo.userName}</Text>
                    <View style={styles.messageContainerLeft2}>
                        <View style={[styles.arrowDownLeft, (senderInfo.sexe === 'Male' ? styles.arrowDownLeftMale : styles.arrowDownLeftFemale)]}></View>
                        <View style={[styles.messageTextContainerLeft, (senderInfo.sexe === 'Male' ? styles.messageContainerMale : styles.messageContainerFemale)]}>
                            <Text style={styles.messageContent}>{message.content}</Text>
                            <Text style={styles.timestamp}>{format(new Date(message.timestamp), 'HH:mm')}</Text>
                        </View>
                    </View>
                </View>
            :
                <View style={styles.messageContainerRight}>
                    <View style={styles.messageTextContainerRight}>
                        <Text style={styles.messageContent}>{message.content}</Text>
                        <Text style={styles.timestamp}>{format(new Date(message.timestamp), 'HH:mm')}</Text>
                    </View>
                    <View style={styles.arrowDownRight}></View>
                </View>}
        </View>);
}
