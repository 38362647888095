import { StyleSheet, Dimensions } from 'react-native';
const { width } = Dimensions.get('window');
// Define styles that can be handled by StyleSheet.create
const baseStyles = StyleSheet.create({
    tabBarStyle: {
        height: 70, // Adjusted height for mobile devices.
    },
    tabBarItemStyle: {
        flexDirection: "column", // Ensures Vectors and texts are one above the other.
    },
    tabBarIconStyle: {
        height: 30, // Fixed height for all Vectors.
    },
    tabBarLabelStyle: {
        fontSize: 16, // Font size adapted for mobile devices.
        marginLeft: 0, // Removes left margin.
        marginBottom: 3, // Removes bottom margin.
    },
});
// Define custom styles object with additional properties
const customStyles = {
    tabBarOptions: {
        activeTintColor: 'blue',
        inactiveTintColor: 'gray',
    },
    screenOptionsStyles: {
        tabBarStyle: baseStyles.tabBarStyle,
        tabBarItemStyle: baseStyles.tabBarItemStyle,
        tabBarActiveTintColor: "white", // Text color for the active tab.
        tabBarInactiveTintColor: "white", // Text color for the inactive tabs.
        tabBarActiveBackgroundColor: "#F48225",
        tabBarInactiveBackgroundColor: "#59C09B",
    },
    tabScreenOptions: {
        tabBarIconStyle: baseStyles.tabBarIconStyle,
        tabBarLabelStyle: baseStyles.tabBarLabelStyle,
    },
};
export default customStyles;
