// 🇫🇷 cette page affiche les informations sur le premium badges gold star (FrameFigma051A)
// 🇫🇷 this page displays information about the premium gold star badges (FrameFigma051A)
import React from "react";
import { Text, View, Pressable, Platform } from "react-native";
import { useNavigation } from "@react-navigation/native";
// import des Vectors
import { Vectors } from "../../../../constants/Vectors";
import { useSelector } from "react-redux";
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const VipBadgesDiamond = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const navigation = useNavigation();
    return (<View style={styles.container}>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>
          {translations.JsonAchievementsPages.JsonVipBadgesScreen.JsonBecomeADonator}
        </Text>
      </View>
      <Pressable style={styles.buttonInvite} onPress={() => navigation.navigate('VipBoardExplanation')}>
          <Text style={styles.textbutton}>
            {translations.JsonAchievementsPages.JsonVipBadgesScreen.JsonInvite}
          </Text>
        </Pressable>
      <View style={styles.card}>
        <Vectors.DiamondBorder style={styles.backgroundImage}/>
        <View style={{ alignItems: 'center', flex: 1 }}>
          <Vectors.StarsDiamond style={styles.img}/>
          <Text style={styles.diamondTitle}> 
              {translations.JsonAchievementsPages.JsonRubyStar}
          </Text>
          <View style={styles.separatorD}/>
          <View style={styles.textContainer}>
          {/*texte random*/}
              <Text style={styles.cardText}>
              • Feature one lorem ipsum lorem ipsum lorem.
              </Text>


              <Text style={styles.cardText}>
              • Feature one lorem ipsum lorem ipsum lorem.
              </Text>

              <Text style={styles.cardText}>
              • Feature one lorem ipsum lorem ipsum lorem.
              </Text>


              <Text style={styles.cardText}>
              • Feature one lorem ipsum lorem ipsum lorem.
              </Text>

          </View>
          <Pressable style={styles.buttonGrey} onPress={() => navigation.navigate('VipBoardExplanation')}>
              <Vectors.StarsDiamond style={styles.imgsmall}/>
              <Text style={styles.textbutton}>
                {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonMoreInformations}
              </Text>
          </Pressable>
        </View>   
      </View>
      <Text style={styles.title}>
          {translations.JsonAchievementsPages.JsonPremiumBadgesPriceTableScreen.JsonDonatorToGetAStar}
        </Text>
    </View>);
};
export default VipBadgesDiamond;
