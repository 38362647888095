var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//(FrameFigma041) (FrameFigma031,FrameFigma032A,FrameFigma035A)
import React, { useState, useEffect } from 'react';
import { View, Platform, ScrollView, Text } from 'react-native';
// Import Components
import TextInputCustomComponent from '../../GeneralSection/TextInputCustomComponent/Code';
// import services
import { GoogleMapsApiServices } from '../../../services/googleMapsApi/GoogleMapsApiServices';
//inport Vectors (icons)
import Vectors from '../../../constants/Vectors';
// Import Styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const AddressAutoPickerActivity = ({ placeholder, query, setQuery, selectedButtonRef, address, setAddress, googleUrl, setGoogleUrl, setIsGoogleUrlResponseOnError, googleUrlRef, handleAddressChange, handleGoogleUrlChange, setCoords, isNewAddress, setIsNewAddress, myPositionRef, myPositionGoogleUrl, setMyPositionGoogleUrl, cb, validateGoogleUrl, error, errorContent, setError, validateConditionNeed, setPostalCode }) => {
    const [suggestions, setSuggestions] = useState([]);
    const fetchAddress = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!!query && !!isNewAddress) {
            try {
                const response = Platform.OS === 'web'
                    ? yield GoogleMapsApiServices.getGoogleMapsWebAddressSuggestion(encodeURIComponent(query))
                    : yield GoogleMapsApiServices.getGoogleMapsMobileAddressSuggestion(encodeURIComponent(query));
                if (response) {
                    console.log("the response of the suggestions", response);
                    setSuggestions(response);
                }
                else {
                    setSuggestions([]);
                }
            }
            catch (error) {
                console.log('Failed to fetch address suggestions:', error);
                setSuggestions([]);
            }
        }
        else {
            setSuggestions([]);
        }
    });
    useEffect(() => {
        const queryLength = query ? query.toString().length : 0;
        if (!!googleUrl && !isNewAddress && validateGoogleUrl(googleUrl) === true) {
            setMapsToDisplayByGoogleUrl(googleUrl);
        }
        else {
            if (queryLength > 0) {
                fetchAddress();
            }
            else {
                setSuggestions([]);
                return;
            }
        }
    }, [query, googleUrl]);
    const setMapsToDisplayBySuggestionselect = (selectedSuggestion) => __awaiter(void 0, void 0, void 0, function* () {
        console.log('query:', query);
        console.log('selectedSuggestion:', selectedSuggestion);
        console.log('address:', address);
        setIsNewAddress(false);
        setSuggestions([]);
        try {
            const response = Platform.OS === 'web'
                ? yield GoogleMapsApiServices.setGoogleMapsWebAddress(selectedSuggestion.place_id)
                : yield GoogleMapsApiServices.setGoogleMapsMobileAddress(selectedSuggestion.place_id);
            if (response) {
                const { formatted_address, geometry, address_components, url } = response;
                setQuery('');
                setAddress(formatted_address || '');
                setCoords({ lat: geometry === null || geometry === void 0 ? void 0 : geometry.location.lat, lng: geometry === null || geometry === void 0 ? void 0 : geometry.location.lng });
                setGoogleUrl(url || '');
                const postalCodeComponent = address_components === null || address_components === void 0 ? void 0 : address_components.find((component) => component.types.includes('postal_code'));
                const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';
                setPostalCode(postalCode);
            }
        }
        catch (error) {
            console.error('Failed to fetch place details');
        }
    });
    const setMapsToDisplayByGoogleUrl = (googleUrl) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!!myPositionRef || selectedButtonRef === 'Address' || !googleUrl) {
            return;
        }
        try {
            const coords = yield GoogleMapsApiServices.getCoordsMapsUrl(googleUrl, setIsGoogleUrlResponseOnError);
            if (coords && coords.lat !== undefined && coords.lng !== undefined) {
                setCoords({ lat: coords.lat, lng: coords.lng });
                const response = yield GoogleMapsApiServices.getGoogleMapsDetails(coords.lat, coords.lng);
                if (response && response.results && response.results.length > 0) {
                    const postalCodeComponent = (_a = response.results[0].address_components) === null || _a === void 0 ? void 0 : _a.find((component) => component.types.includes('postal_code'));
                    const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';
                    setPostalCode({ postalCode });
                    setError('');
                }
            }
            else {
                setCoords({ lat: 35.266512, lng: -43.541179 }); // in the middle of the Atlantic Ocean
                setError(errorContent);
            }
        }
        catch (error) {
            console.log('Failed to fetch coords from google url:', error);
        }
    });
    console.log("the suggestions to show", suggestions);
    return (<View>
            {selectedButtonRef === 'Address' ?
            <TextInputCustomComponent Icon={Vectors.ProfilesCity} placeholderText={placeholder} handleTextChange={handleAddressChange} value={!!query ? query : !suggestions ? '' : address} isPlaceHolderTextAlignCenter={false} multiline={true} numberOfLines={2}/>
            :
                <TextInputCustomComponent Icon={Vectors.ProfilesCity} placeholderText={placeholder} handleTextChange={handleGoogleUrlChange} value={!!myPositionRef ? myPositionGoogleUrl : googleUrl} isPlaceHolderTextAlignCenter={false} multiline={true} numberOfLines={2} editable={!!myPositionRef ? false : true} IconRight={!!myPositionRef ? Vectors.GeneralBlackAndWhiteCopyPast : null} cb={cb}/>}
            {[address].length > 0 && suggestions.length > 0 ? (<ScrollView style={styles.suggestionsContainer}>
                    {suggestions.map((suggestion, index) => (<Text key={index} onPress={() => setMapsToDisplayBySuggestionselect(suggestion)} style={styles.suggestion}>
                            {suggestion.description}
                        </Text>))}
                </ScrollView>) : null}
        </View>);
};
export default AddressAutoPickerActivity;
