// Fr  Styles de Code(FrameFigma034)
// En  Styles of Code(FrameFigma034)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    inputContainer: {
        paddingHorizontal: 8,
        justifyContent: 'center',
        fontSize: 16,
        borderWidth: 1,
        marginVertical: 20,
        width: '90%',
        borderRadius: 15,
        padding: 20,
    },
    textTitle: {
        paddingHorizontal: 15,
        backgroundColor: 'white',
        fontSize: 13,
        textAlign: 'center',
        position: 'absolute',
        zIndex: 1,
        left: 20,
        top: -10,
    },
    focusedInput: {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    switchBtn: {
        marginVertical: 10,
        flexDirection: 'row',
        alignItems: 'center',
    },
    textbtn: {
        fontSize: 12.3,
        fontWeight: 'bold',
        textAlign: 'center',
        marginVertical: 10,
    },
    btnContainer: {
        width: '100%',
        alignContent: 'center',
        marginTop: 30,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    btnNext: {
        backgroundColor: '#59C09B',
        width: '55%',
        padding: 8,
        borderRadius: 15,
        elevation: 5,
        margin: 15,
        alignItems: 'center',
    },
    btnText: {
        color: 'white',
        fontSize: 16,
    },
});
