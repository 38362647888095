import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    tabBar: {
        flexDirection: 'row',
        backgroundColor: '#000',
        justifyContent: 'center',
        alignItems: 'center',
    },
    tabItem: {
        flex: 1,
        alignItems: 'center',
        padding: 16,
        //cursor: 'pointer',
    },
    text: {
        color: 'white',
        fontSize: 16,
    },
    separator: {
        height: '100%',
        width: 2,
        backgroundColor: 'white',
    },
    button: {
        backgroundColor: '#59C09B',
        padding: 10,
        borderRadius: 5,
        marginTop: 10,
    },
    buttonText: {
        color: 'white',
        textAlign: 'center',
    },
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 20,
    },
    input: {
        borderWidth: 1,
        borderColor: 'gray',
        width: '100%',
        padding: 10,
        marginVertical: 10,
        color: 'black',
        backgroundColor: 'white',
    },
    title: {
        fontSize: 20,
        fontWeight: 'bold',
        color: 'white',
    },
    imageContainer: {
        backgroundColor: '#59C09B',
        borderRadius: 50,
        alignContent: 'center'
    },
    imageItem: {
        width: '50%',
        height: '50%'
    }
});
export default styles;
