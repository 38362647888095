import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        width: '100%',
        paddingHorizontal: 10,
    },
    containerParticipants: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
    },
    containerScrollBarParticipants: {
        flexDirection: 'row',
        width: '75%',
        alignItems: 'center',
        marginHorizontal: '1%',
    },
    leftArrow: {
        transform: [{ rotate: '180deg' }], // Corrected rotation
        width: 20,
        height: 20,
    },
    rightArrow: {
        transform: [{ rotate: '0deg' }], // No need to specify 0deg unless for clarity
        width: 20,
        height: 20,
    },
    containerParticipantsStatus: {
        flexGrow: 1,
        alignItems: 'center',
        paddingVertical: 10,
        overflow: 'hidden',
    },
    participantImage: {
        width: 50,
        height: 50,
        borderRadius: 25,
        marginRight: 8,
    },
    containerParticipantsNumber: {
        flexDirection: 'column',
        marginLeft: '1%',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'auto',
    },
    logoParticipants: {
        width: 40,
        height: 40,
    },
    numberOfParticipants: {
        fontWeight: 'bold',
        marginTop: 0, // Use 0 instead of '-1%'
    },
    containerParticipantsDataForActivity: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
    },
    containerInterestedParticipants: {
        height: 'auto', // Flexbox handles dynamic height
        flexDirection: 'row',
        alignItems: 'center',
    },
    participantsInterestedForActivity: {
        width: 25,
        height: 25,
        borderRadius: 12.5,
        backgroundColor: '#59C09B',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: 5,
    },
    DataForActivityText: {
        fontFamily: 'Poppins', // Ensure this font is linked correctly
        fontSize: 16,
        fontWeight: '500', // Use valid font weight
        lineHeight: 24,
        opacity: 0.5,
    },
    dataInterestedNumbers: {
        fontFamily: 'Poppins', // Ensure this font is linked correctly
        fontWeight: '500', // Use valid font weight
        lineHeight: 13.5,
        color: 'black',
        opacity: 1,
    },
    containerActivityFollowers: {
        height: 'auto', // Flexbox handles dynamic height
        flexDirection: 'row',
        alignItems: 'center',
    },
    emptyHeart: {
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        width: 40,
        height: 25,
    },
    emptyHeartText: {
        position: 'absolute',
        color: 'black',
        marginBottom: 3,
    },
    separator: {
        width: '100%',
        height: 1,
        backgroundColor: '#9D9D9D',
        borderRadius: 1,
        marginVertical: 5,
        opacity: 0.5,
    },
});
export default styles;
