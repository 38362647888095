import React from 'react';
import { View, StyleSheet } from 'react-native';
import MyButtonPress from '../PressButton/Code';
// Import vectors (icons)
import Vectors from '../../../constants/Vectors';
// Import services
import { ParticipantsServices } from '../../../services/activities/ParticipantsServices';
const ActivityDetailsButtonTopComponent = ({ cbParticipate, cbLeave, cbInvite, id, act, disabled = false, }) => {
    return (<View style={styles.ButtonsContainer}>
            <MyButtonPress disabled={disabled} text="Invite" txtCol="white" txtS={20} txtFW="bold" w={190} h={47} bgC={disabled ? 'grey' : '#1976D2'} br={10} 
    //alignItems="center"
    //TODO: Verifier la définition du bouton
    cb={cbInvite} Icon={Vectors.GeneralBlackAndWhiteShareWhite} wIcon={20} hIcon={20}/>
            {!ParticipantsServices.isUserParticipate(act, id) ? (ParticipantsServices.isUserInWaitingList(act, id) ? (<MyButtonPress disabled={disabled} text="Leave WaitingList" txtCol="white" txtS={20} txtFW="bold" w={190} h={47} bgC={disabled ? 'grey' : 'orange'} br={10} cb={cbLeave}/>) : (<MyButtonPress disabled={disabled} text="Participate" txtCol="white" txtS={20} txtFW="bold" w={190} h={47} bgC={disabled ? 'grey' : '#59c09b'} br={10} cb={cbParticipate}/>)) : (<MyButtonPress disabled={disabled} text="Unsubscribe" txtCol="white" txtS={20} txtFW="bold" w={190} h={47} bgC={disabled ? 'grey' : 'red'} br={10} cb={cbParticipate}/>)}
        </View>);
};
export default ActivityDetailsButtonTopComponent;
const styles = StyleSheet.create({
    ButtonsContainer: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center',
        width: '100%',
        height: 'auto',
    },
});
