//FR  permet d'inviter des amis via Twitter
//EN allows users to invite friends via Twitter
import React from 'react';
import { View, Text, Pressable, Linking } from 'react-native';
import { useSelector } from 'react-redux';
const InviteByTwitter = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const inviteByTwitter = () => {
        const twitterLink = `twitter://post?message=${translations.JsonInviteFriendsScreen.JsonInvitationMessage}`;
        Linking.openURL(twitterLink);
    };
    return (<View>
      <Pressable onPress={inviteByTwitter}>
        <Text>{translations.JsonInviteFriendsScreen.JsonInviteTwitter}</Text>
      </Pressable>
    </View>);
};
export default InviteByTwitter;
