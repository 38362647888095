import React from 'react';
import { View, Text, Modal, TouchableOpacity, Platform } from 'react-native';
import Vectors from '../../../../constants/Vectors';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const LocationModal = ({ visible, locationType, onRequestClose, onSelectLocationType, }) => {
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    return (<Modal transparent={true} visible={visible} onRequestClose={onRequestClose} animationType="fade">
      <View style={styles.modalContainer}>
        <View style={styles.modalContent}>
          <View style={styles.modalHeader}>
            <Vectors.MapsLocationBlackAndWhite style={styles.vectorHeader}/>
          </View>
          <Text style={styles.modalTitle}>
            {/*Do you allow Socializus to access the position of this device?*/}
            {translations.JsonLocationModal.JsonLocationPermission}
          </Text>
          <View style={styles.modalOptionsContainer}>
            <TouchableOpacity style={styles.modalOption} onPress={() => onSelectLocationType('exact')}>
              <View style={[
            styles.modalOptionCircle,
            locationType === 'exact' && styles.activeModalOptionCircle,
        ]}>
                {locationType === 'exact' && (<Vectors.MapsLocationBlackAndWhite style={styles.vectorInsideCircle}/>)}
              </View>
              <Text style={styles.modalOptionText}>{/*Exact Location*/}{translations.JsonLocationModal.JsonExactLocation}</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.modalOption} onPress={() => onSelectLocationType('approximate')}>
              <View style={[
            styles.modalOptionCircle,
            locationType === 'approximate' &&
                styles.activeModalOptionCircle,
        ]}>
                {locationType === 'approximate' && (<Vectors.MapsLocationBlackAndWhite style={styles.vectorInsideCircle}/>)}
              </View>
              <Text style={styles.modalOptionText}>{translations.JsonLocationModal.JsonApproximateLocation}</Text>
            </TouchableOpacity>
          </View>
          <TouchableOpacity style={styles.modalOptionCancel} onPress={onRequestClose}>
            <Text style={styles.modalOptionCancelText}>{translations.JsonProfilePages.JsonEditProfileScreen.JsonCancelButton}</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>);
};
export default LocationModal;
