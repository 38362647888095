var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { GoogleMapsApiServices } from '../../services/googleMapsApi/GoogleMapsApiServices';
import { getCurrentLocation } from '../Location/LocationService';
export const getMyPosition = (setCoords, setPostalCode, setMyPositionGoogleUrl, myPositionRef, currentActivity, Coords) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    /** my code */
    let response = yield getCurrentLocation();
    console.log("the response of get location ", response);
    if (!response)
        return null;
    let location = response.location;
    let postalCodeResponse = yield GoogleMapsApiServices.getGoogleMapsDetails(location.latitude, location.longitude);
    console.log("the postal code ", postalCodeResponse);
    if ((_a = postalCodeResponse === null || postalCodeResponse === void 0 ? void 0 : postalCodeResponse.results[0]) === null || _a === void 0 ? void 0 : _a.address_components) {
        const postalCodeComponent = postalCodeResponse.results[0].address_components.find((component) => component.types.includes('postal_code'));
        const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';
        setPostalCode({ postalCode: postalCode });
    }
    setCoords({ lat: location.latitude, lng: location.longitude });
    setMyPositionGoogleUrl(`https://www.google.com/maps?q=${location.latitude},${location.longitude}`);
    myPositionRef.current = true;
    return;
    /** end of my code */
    // const response = await getMyGeoCoords();
    // if (!response) {
    //     console.log('pas de coord en storage');
    //     await GoogleMapsApiServices.getMyGPSwebCoords();
    //     const myCoords = await getMyGeoCoords();
    //     setCoords(myCoords);
    //     const post = await GoogleMapsApiServices.getGoogleMapsDetails(myCoords.lat, myCoords.lng);
    //     console.log('response', post);
    //     if (post?.results[0]?.address_components) {
    //         const postalCodeComponent = post.results[0].address_components.find((component: any) => component.types.includes('postal_code'));
    //         const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';
    //         setPostalCode({ postalCode: postalCode });
    //     }
    //     myPositionRef.current = true;
    //     return;
    // } else if (Coords && currentActivity.location) {
    //     if (currentActivity.location.latitude === Coords.lat && currentActivity.location.longitude === Coords.lng) {
    //         await GoogleMapsApiServices.getMyGPSwebCoords();
    //         const myCoords = await getMyGeoCoords();
    //         setCoords(myCoords);
    //         const post = await GoogleMapsApiServices.getGoogleMapsDetails(myCoords.lat, myCoords.lng);
    //         console.log('response', post);
    //         if (post?.results[0]?.address_components) {
    //             const postalCodeComponent = post.results[0].address_components.find((component: any) => component.types.includes('postal_code'));
    //             const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';
    //             setPostalCode({ postalCode: postalCode });
    //             setMyPositionGoogleUrl(`https://www.google.com/maps?q=${myCoords.lat},${myCoords.lng}` as unknown as UrlType);
    //         }
    //         myPositionRef.current = true;
    //         return;
    //     } else {
    //         console.log('coords du storage 1');
    //         console.log('response', response.lat, response.lng);
    //         setCoords(response);
    //         const post = await GoogleMapsApiServices.getGoogleMapsDetails(response.lat, response.lng);
    //         console.log('response', post);
    //         if (post?.results[0]?.address_components) {
    //             const postalCodeComponent = post.results[0].address_components.find((component: any) => component.types.includes('postal_code'));
    //             const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';
    //             setPostalCode({ postalCode: postalCode });
    //             setMyPositionGoogleUrl(`https://www.google.com/maps?q=${response.lat},${response.lng}` as unknown as UrlType);
    //         }
    //         myPositionRef.current = true;
    //         return;
    //     }
    // } else {
    //     console.log('coords du storage 2');
    //     setCoords(response);
    //     const post = await GoogleMapsApiServices.getGoogleMapsDetails(response.lat, response.lng);
    //     console.log('response', post);
    //     if (post?.results[0]?.address_components) {
    //         const postalCodeComponent = post.results[0].address_components.find((component: any) => component.types.includes('postal_code'));
    //         const postalCode = postalCodeComponent ? postalCodeComponent.long_name : '';
    //         setPostalCode({ postalCode: postalCode });
    //         setMyPositionGoogleUrl(`https://www.google.com/maps?q=${response.lat},${response.lng}` as unknown as UrlType);
    //     }
    //     myPositionRef.current = true;
    //     return;
    // }
    // logique pour récupérer la position de l'utilisateur directement dans Maps.js qui rend le GoogleUrl
    // voir pour loader
    //a voir pour recup coords de geoloc: soit storage avant et position instantanée après, soit par default comme la , soit par services mais loader aussi
});
