var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//FR qui calcule et met à jour les points d'un utilisateur sur la base de diverses activités et interactions
//EN that calculates and updates a user's points based on various activities and interactions
import { useEffect, useState } from 'react';
import { hostname } from '../../../hostName/hostName';
import { useSelector } from 'react-redux';
export const calculateUserPoints = (userId) => {
    var _a, _b, _c, _d;
    const { allUsers } = useSelector((state) => state.user);
    const { userToken } = useSelector((state) => state.auth);
    const [userLikes, setUserLikes] = useState([]);
    const [userOrganisedActivities, setUserOrganisedActivities] = useState([]);
    const [userCoOrganisedActivities, setUserCoOrganisedActivities] = useState([]);
    const [userAttendedActivities, setUserAttendedActivities] = useState([]);
    const currentUser = allUsers.find(user => user._id === userId);
    useEffect(() => {
        const fetchLikes = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield fetch(`${hostname}/userLikes`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = yield response.json();
                    const userLikesReceived = data.filter((like) => like.likedUser === userId);
                    setUserLikes(userLikesReceived);
                }
            }
            catch (error) {
                console.error(error);
            }
        });
        const fetchActivities = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield fetch(`${hostname}/activities`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        'Content-Type': 'application/json',
                    },
                });
                if (response.ok) {
                    const data = yield response.json();
                    const userOrganisedActivities = data.filter((activity) => activity.author === userId);
                    const userCoOrganisedActivities = data.filter((activity) => activity.coOrganizers.includes(userId));
                    const userAttendedActivities = data.filter((activity) => activity.attendees.includes(userId));
                    setUserOrganisedActivities(userOrganisedActivities);
                    setUserCoOrganisedActivities(userCoOrganisedActivities);
                    setUserAttendedActivities(userAttendedActivities);
                }
            }
            catch (error) {
                console.error(error);
            }
        });
        fetchLikes();
        fetchActivities();
    }, [userId]);
    const userProfileVisits = ((_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.profileVisits) === null || _a === void 0 ? void 0 : _a.length) > 0 ? [...new Set((_b = currentUser.profileVisits) === null || _b === void 0 ? void 0 : _b.map((visit) => visit.visitor))].length : 0;
    const userFriends = ((_c = currentUser === null || currentUser === void 0 ? void 0 : currentUser.friends) === null || _c === void 0 ? void 0 : _c.length) > 0 ? currentUser.friends.length : 0;
    const userActivitiesOrganising = (userOrganisedActivities === null || userOrganisedActivities === void 0 ? void 0 : userOrganisedActivities.length) > 0 ? userOrganisedActivities.length : 0;
    const userActivitiesCoOrganising = (userCoOrganisedActivities === null || userCoOrganisedActivities === void 0 ? void 0 : userCoOrganisedActivities.length) > 0 ? userCoOrganisedActivities.length : 0;
    const userActivitiesAttending = (userAttendedActivities === null || userAttendedActivities === void 0 ? void 0 : userAttendedActivities.length) > 0 ? userAttendedActivities.length : 0;
    const userSponsoredUsers = ((_d = currentUser === null || currentUser === void 0 ? void 0 : currentUser.sponsoredUsers) === null || _d === void 0 ? void 0 : _d.length) > 0 ? currentUser.sponsoredUsers.length : 0;
    const userLiked = (userLikes === null || userLikes === void 0 ? void 0 : userLikes.length) > 0 ? userLikes.length : 0;
    const userPoints = userFriends * 20 +
        userActivitiesOrganising * 100 +
        userActivitiesAttending * 25 +
        userActivitiesCoOrganising * 50 +
        userProfileVisits * 5 +
        userSponsoredUsers * 250 +
        userLiked * 10;
    useEffect(() => {
        const updateUserPoints = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield fetch(`${hostname}/users/${userId}/updatePoints`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ appPoints: userPoints }),
                });
                if (response.ok) {
                    console.info('User Points Updated');
                }
                else {
                    console.error('Failed to update user points:', response.status, response.statusText);
                }
            }
            catch (error) {
                console.error('Error updating user points:', error);
            }
        });
        if (userPoints !== 0) {
            updateUserPoints();
        }
    }, [userPoints]);
    return userPoints;
};
