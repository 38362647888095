//🇫🇷 Ce fichier gère le style mobile du composant Sponsorship.js (FrameFigma005)
//🇬🇧 This file manages the mobile style of the Sponsorship.js component (FrameFigma005)
import { StyleSheet, Dimensions } from 'react-native';
const { width, height } = Dimensions.get('window');
export default StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: 'white',
    },
    title: {
        marginTop: height * 0.08,
        fontSize: width * 0.045,
        fontWeight: 'bold',
    },
    buttonContainer: {
        flexDirection: 'row',
        marginTop: height * 0.0625,
        justifyContent: 'space-around',
        width: '100%',
        alignItems: 'center',
    },
    containerInput: {
        marginTop: '10%',
        width: '80%',
    },
    headerError: {
        backgroundColor: 'red',
        padding: width * 0.05,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: width * 0.045,
        fontWeight: 'bold',
    },
    footerText: {
        position: 'absolute',
        bottom: '40%',
        width: '100%',
        padding: width * 0.025,
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlignVertical: 'bottom',
        fontSize: width * 0.04,
        color: 'grey',
        textAlign: 'center',
    },
});
