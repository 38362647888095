var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// AppMobile.js
import React, { useState, useEffect } from 'react';
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { View, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useError } from './contexts/ErrorContext';
// Importations des composants/pages
import HomeScreen from './pages/AppStartSection/HomeScreen/Code';
import LanguagesScreen from './pages/AppStartSection/LanguagesScreen/Code';
import EmailScreen from './pages/LoginRegisterSection/EmailScreen/Code';
import ExistingUserPasswordScreen from './pages/LoginRegisterSection/PasswordScreen/ExistingUserPasswordScreen/Code';
import NewUserPasswordScreen from './pages/LoginRegisterSection/PasswordScreen/NewUserPasswordScreen/Code';
import Sponsorship from './pages/LoginRegisterSection/Sponsorship/Code';
import ResetPasswordScreen from './pages/LoginRegisterSection/PasswordScreen/ResetPasswordScreen/Code';
import VerifyEmailScreen from './pages/LoginRegisterSection/VerifyEmail/VerifyEmailNewUserScreen/Code';
import VerifyEmailExistingUserScreen from './pages/LoginRegisterSection/VerifyEmail/VerifyEmailExistingUserScreen/Code';
import StartScreen from './pages/AppStartSection/StartScreen/Code';
import CreateProfileScreen from './pages/ProfileModule/CreateProfileSection/CreateProfileNavigator'; // Composants spécifiques à l'utilisateur connecté
// Composants spécifiques à l'utilisateur connecté
import BottomNav from './components/NavBarSection/BottomNav/BottomGreenNav/Code';
// import DrawerNavigation from './components/DrawerNavigator/DrawerMobile/DrawerNavigation';
// Importe les screen du drawer
import { MobileDrawerProvider } from './components/DrawerNavigator/DrawerMobile/MobileDrawerContext';
import DrawerNavigation from './components/DrawerNavigator/DrawerMobile/DrawerNavigation';
// import VectorsOnOff from './src/components/VectorsOnOff/VectorsOnOff';
import { Vectors } from './constants/Vectors';
import StepOneScreen from './pages/ProfileModule/CreateProfileSection/CreateProfileStep1Screen/Code';
import StepTwoScreen from './pages/ProfileModule/CreateProfileSection/CreateProfileStep2Screen/Code';
import Step3PersonalScreen from './pages/ProfileModule/CreateProfileSection/CreateProfileStep3PersoScreen/Code';
import Step3ProScreen from './pages/ProfileModule/CreateProfileSection/CreateProfileStep3ProScreen/Code';
import Step4Screen from './pages/ProfileModule/CreateProfileSection/CreateProfileStep4Screen/Code';
import { useSelector } from 'react-redux';
import ConceptSlidesScreen from './pages/ConceptSlidesScreen/Code';
import { hostname } from '../hostName/hostName';
import { useActivityModal } from './contexts/ActivityModalContext';
import { setToken } from './services/Axios/AxiosService';
// import { SettingsStack } from './pages/SettingsSection/SettingsScreen/Code';
const headerLeftArrow = (navigation) => (<TouchableOpacity onPress={() => navigation.goBack()}>
    <View style={{ marginLeft: 15 }}>
      <Vectors.LeftArrow />
    </View>
  </TouchableOpacity>);
const headerRightMsg = (openActivityChatModal, setOpenActivityChatModal) => (<>
    {!!openActivityChatModal ?
        <View style={{ marginRight: 15, width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}>
        <Vectors.NavbarChatColors style={{ width: 50, height: 50 }}/>
      </View>
        :
            <TouchableOpacity onPress={() => setOpenActivityChatModal(!openActivityChatModal)}>
        <View style={{ marginRight: 15, width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}>
          <Vectors.NavbarBlackAndWhiteChat style={{ width: 50, height: 50 }}/>
        </View>
      </TouchableOpacity>}
  </>);
const headerExit = (navigation, targetScreen) => (<TouchableOpacity onPress={() => navigation.navigate(targetScreen)}>
    <View style={{ marginLeft: 15 }}>
      <Vectors.Exit />
    </View>
  </TouchableOpacity>);
const AppContent = () => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { isLoggedIn, userToken, userId } = useSelector((state) => state.auth);
    const user = useSelector((state) => state.user);
    // console.log("the object auth in appMobile ", isLoggedIn, auth);
    const { error, clearError } = useError(); // Utiliser le contexte d'erreurs
    const navigationRef = useNavigationContainerRef();
    const [isNavigationReady, setIsNavigationReady] = useState(false);
    useEffect(() => {
        let isSubscribed = true; // Flag pour vérifier le montage du composant
        const saveCurrentRoute = () => {
            console.log("the route will be saved");
            const currentRoute = navigationRef.getCurrentRoute();
            if (currentRoute && currentRoute.name !== 'Activities' && isSubscribed) {
                const routeParams = currentRoute.name === 'Activity' ? JSON.stringify(currentRoute.params) : null;
                const routeName = currentRoute.name === 'MyProfileScreen' || currentRoute.name === 'ProfileScreen' || currentRoute.name === 'EditActivity'
                    ? 'Activities'
                    : currentRoute.name;
                const routeState = JSON.stringify({ routeName, routeParams });
                AsyncStorage.setItem('lastRouteState', routeState);
            }
        };
        if (isNavigationReady) {
            saveCurrentRoute();
        }
        const unsubscribeState = navigationRef.addListener('state', () => {
            if (isSubscribed) {
                saveCurrentRoute();
            }
        });
        return () => {
            isSubscribed = false;
            unsubscribeState();
        };
    }, [navigationRef, isNavigationReady]);
    useEffect(() => {
        const restoreLastRoute = () => __awaiter(void 0, void 0, void 0, function* () {
            const lastRouteState = yield AsyncStorage.getItem('lastRouteState');
            if (lastRouteState && isNavigationReady) {
                const { routeName, routeParams } = JSON.parse(lastRouteState);
                let params = null;
                if (routeParams) {
                    params = JSON.parse(routeParams);
                }
                navigationRef.navigate(routeName, params);
            }
        });
        if (isNavigationReady) {
            restoreLastRoute();
        }
        if (isLoggedIn)
            setToken(userToken);
    }, [isNavigationReady, navigationRef]);
    const getHeaderTitle = (routeName) => {
        switch (routeName) {
            case 'Email':
                return translations.JsonProfilePages.JsonEditProfileScreen.JsonStep2.JsonEmail;
            case 'Password':
                return translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonHeaderTitlePassword;
            case 'ResetPassword':
                return translations.JsonLoginRegisterPages.JsonEmailScreen.JsonHeaderTitleResetPassword;
            case 'Sponsorship':
                return translations.JsonAchievementsPages.JsonTitleSponsorship;
            case 'NewUserPassword':
                return translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonHeaderTitlePassword;
            case 'VerifyEmail':
                return translations.JsonLoginRegisterPages.JsonVerificationCode.JsonTitleVerification;
            case 'VerifyEmailExistingUserScreen':
                return translations.JsonLoginRegisterPages.JsonVerificationCode.JsonTitleVerification;
            case 'CreateActivity':
                return translations.JsonActivityPages.JsonActivityManagement.JsonTitleCreateActivity;
            case 'EditActivity':
                return translations.JsonActivityPages.JsonActivityManagement.JsonTitleModifyActivity;
            case 'CreateProfile':
                return translations.JsonCreateProfilePages.JsonTitleCreateProfile;
            case 'EditProfile':
                return translations.JsonProfilePages.JsonTitleEditProfile;
            case 'CopyActivity':
                return translations.JsonActivityPages.JsonActivityManagement.JsonTitleCopyActivity;
            default:
                return '';
        }
    };
    const defaultScreenOptions = {
        headerShown: true,
        headerStyle: { backgroundColor: '#59C09B' },
        headerTintColor: 'white',
        headerTitleStyle: { fontWeight: 'bold', fontSize: 20 },
        headerTitleAlign: "center",
    };
    // Permet de gerer les msg d'erreurs
    const conditionalScreenOptions = ({ navigation, route }, targetScreen) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerShown: !error, headerStyle: {
            backgroundColor: error ? 'red' : '#59C09B',
        }, headerTitle: error ? error : getHeaderTitle(route.name), headerLeft: () => headerExit(navigation, targetScreen) }));
    const linking = {
        prefixes: [`http://${hostname}:8080`],
        config: {
            screens: {
                Home: 'home',
                Start: 'start',
                Languages: 'languages',
                Email: 'email',
                Password: 'password',
                NewUserPassword: 'new-user-password',
                ResetPassword: 'reset',
                VerifyEmail: 'verify-email',
                CreateProfile: {
                    path: 'CreateProfile',
                    screens: {
                        StepOneScreen: 'StepOne',
                        StepTwoScreen: 'SetTwo',
                        Step3PersonalScreen: 'SetthreePersonal',
                        Step3ProScreen: 'StepThreePro',
                        Step4Screen: 'StepFour',
                    },
                },
                LoggedIn: {
                    screens: {
                        Activities: 'activities',
                        MembersNavigator: 'MembersNavigator',
                        ConceptSlides: 'concept',
                        News: 'news',
                        ContactUs: 'contact',
                        Activity: {
                            path: 'Activity/:activityId',
                            parse: {
                                activityId: (activityId) => `${activityId}`,
                            },
                        },
                        MyProfileScreen: {
                            path: 'MyProfileScreen/:userId',
                            parse: {
                                userId: (userId) => `${userId}`,
                            },
                        },
                        ProfileScreen: {
                            path: 'ProfileScreen/:userId',
                            parse: {
                                userId: (userId) => `${userId}`,
                            },
                        },
                        EditProfile: 'EditProfile',
                        CopyActivity: {
                            path: 'CopyActivity/:activityId',
                            parse: {
                                activityId: (activityId) => `${activityId}`,
                            },
                        },
                    },
                },
            },
        },
    };
    const Stack = createStackNavigator();
    // const { activityTopicTitle, author} = useActivity();
    const { activityTopicTitle, author } = useSelector((state) => state.activities);
    const { activityModalOpen, setActivityModalOpen } = useActivityModal();
    return (<NavigationContainer ref={navigationRef} onReady={() => setIsNavigationReady(true)} linking={linking}>
      {isLoggedIn ? (
        //Navigation du drawer et de la navBar
        <DrawerNavigation />
        // Ajouter les screens dans DrawerNavigation.tsx => './components/DrawerNavigator/DrawerMobile/DrawerNavigation'
        ) : (
        // Stack Navigator pour les utilisateurs non connectés
        <Stack.Navigator>
          <Stack.Screen name="Home" component={HomeScreen} options={{ headerShown: false }}/>
          <Stack.Screen name="Start" component={StartScreen} options={{ headerShown: false }}/>
          <Stack.Screen name="ConceptSlides" component={ConceptSlidesScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Stack.Screen name="Languages" component={LanguagesScreen} options={{ headerShown: false }}/>
          <Stack.Screen name="Email" component={EmailScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError);
                return conditionalScreenOptions({ navigation, route }, 'Home');
            }}/>
          <Stack.Screen name="Password" component={ExistingUserPasswordScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError);
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
          <Stack.Screen name="Sponsorship" component={Sponsorship} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError);
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
          <Stack.Screen name="NewUserPassword" component={NewUserPasswordScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError);
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
          <Stack.Screen name="ResetPassword" component={ResetPasswordScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError);
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
          <Stack.Screen name="VerifyEmailExistingUserScreen" component={VerifyEmailExistingUserScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError);
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
          <Stack.Screen name="VerifyEmail" component={VerifyEmailScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError);
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
          <Stack.Screen name="CreateProfile" component={CreateProfileScreen} options={({ navigation, route }) => {
                navigation.addListener('focus', clearError);
                return conditionalScreenOptions({ navigation, route }, 'Email');
            }}/>
          <Stack.Screen name="StepOne" component={StepOneScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Stack.Screen name="StepTwo" component={StepTwoScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Stack.Screen name="Step3Personal" component={Step3PersonalScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Stack.Screen name="Step3Pro" component={Step3ProScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Stack.Screen name="Step4" component={Step4Screen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Stack.Screen name="BottomNav" component={BottomNav} options={{ headerShown: false }}/>
        </Stack.Navigator>)}
    </NavigationContainer>);
};
const AppMobile = () => {
    return (<MobileDrawerProvider>
      <AppContent />
    </MobileDrawerProvider>);
};
export default AppMobile;
