// Fr  Styles de CreateActivityDescriptionScreen(FrameFigma037A)
// En  Styles of CreateActivityDescriptionScreen(FrameFigma037A)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
    },
    scrollContainer: {
        flex: 1,
        width: '90%',
    },
    titleText: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18,
        marginVertical: '5%',
        alignItems: 'center',
    },
    choosePhoto: {
        width: '100%',
        height: 200,
        alignSelf: 'center',
        justifyContent: 'center',
        backgroundColor: '#DCF1EA',
        borderRadius: 15,
    },
    btnChoose: {
        justifyContent: 'center',
        alignItems: 'center',
    },
    cropBtn: {
        backgroundColor: 'black',
        width: '20%',
        height: '15%',
        left: '6%',
        borderRadius: 10,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around',
        position: 'absolute',
        zIndex: 10,
    },
    cropBtnText: {
        color: 'white',
    },
    imageStyle: {
        width: '90%', // Assurez-vous que la largeur de 'choosePhoto' est définie
        height: 200, // Hauteur fixe, ou 'auto' si vous préférez
        resizeMode: 'cover',
        alignSelf: 'center',
    },
    inputContainer: {
        fontSize: 16,
        marginVertical: '3%',
    },
    spaceBottom: {
        marginBottom: '20%',
    },
});
