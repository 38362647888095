import { StyleSheet } from "react-native";
export default StyleSheet.create({
    timestamp: {
        fontSize: 12,
        color: 'white',
        textAlign: 'right'
    },
    dateCenter: {
        textAlign: 'center',
        paddingBottom: 15
    },
    nameMale: {
        color: '#001AFF',
    },
    nameFemale: {
        color: '#FF13CB',
    },
    messageContainerFemale: {
        backgroundColor: '#BA4EA2',
    },
    messageContainerMale: {
        backgroundColor: '#4A84DD',
    },
    messagesSpace: {
        width: '100%',
        // flex: 1,
        alignContent: 'space-between',
    },
    messageContainerLeft: {
        // marginBottom: 15,
        justifyContent: 'flex-start'
    },
    messageContainerLeft2: {
        marginBottom: 15,
        flexDirection: 'row',
        alignItems: 'flex-end',
    },
    messageContainerRight: {
        flexDirection: 'row',
        marginBottom: 15,
        alignItems: 'flex-end',
        justifyContent: 'flex-end'
    },
    messageTextContainerLeft: {
        left: -13,
        backgroundColor: '#f0f0f0',
        padding: 10,
        borderRadius: 15,
        maxWidth: '70%',
    },
    messageTextContainerRight: {
        right: -13,
        backgroundColor: '#57B355',
        padding: 10,
        borderRadius: 15,
        maxWidth: '70%',
    },
    messageContent: {
        color: 'white',
        fontSize: 16,
    },
    arrowDownRight: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderRightWidth: 20,
        borderBottomWidth: 10,
        borderRightColor: 'transparent',
        borderBottomColor: '#57B355',
    },
    arrowDownLeft: {
        width: 0,
        height: 0,
        backgroundColor: 'transparent',
        borderStyle: 'solid',
        borderRightWidth: 20,
        borderBottomWidth: 10,
        borderRightColor: 'transparent',
        transform: [{ rotateX: '180deg' }, { rotateZ: '180deg' }], //same que right, jueste ajouté cette ligne
    },
    arrowDownLeftMale: {
        borderBottomColor: '#4A84DD',
    },
    arrowDownLeftFemale: {
        borderBottomColor: '#BA4EA2',
    },
    message: {
        marginVertical: 5,
    },
});
