// Fr Cette page affiche l'étape 5 du formulaire pour modifier une activité (FrameFigma037A)
// En This page displays the FIFTH step of the form to edit an activity (FrameFigma037A)
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { ScrollView, View, Text, Pressable, Platform, Image, TouchableWithoutFeedback } from 'react-native';
import Vectors from '../../../../constants/Vectors';
import ActivityImagePicker from '../../../../components/PickerSection/ActivityImagePicker/Code';
import { useNavigation } from '@react-navigation/native';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import Modal from '../../../../components/ModalPopUpModule/ModalSection/ActivityDetailsModal/Code';
import ImageCropperWeb from '../../../../components/ImageCropperSection/ImageActivityCrop/ImageCropperWeb';
import ImageCropperMobile from '../../../../components/ImageCropperSection/ImageActivityCrop/ImageCropperMobile';
import BottomWhiteNav from '../../../../components/NavBarSection/BottomNav/BottomWhiteNav/Code';
// Importer les styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
//import utils
import { ActivityCRUD } from '../../../../services/activities/ActivityCRUD';
import { useDispatch, useSelector } from 'react-redux';
import ActivitiesSlice from '../../../../redux/Features/Activities/AtivitiesSlice';
import { nanoid } from '@reduxjs/toolkit';
import { ActivityServices } from '../../../../services/activities/ActivityServices';
//Definir le type des props
const CreateActivityDescriptionScreen = ({ onPrevStep, onNextStep, setHeaderTitle }) => {
    const navigation = useNavigation();
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { user } = useSelector((state) => state.user);
    // const { currentActivity, setCurrentActivity, setCurrentActivityDate, currentActivitySwitch, setCurrentActivitySwitch, getActivities } = useActivity();
    const { currentActivity, currentActivitySwitch } = useSelector((state) => state.activities);
    const dispatch = useDispatch();
    const setCurrentActivity = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivity({ id: nanoid(), value: data }));
    };
    const setCurrentActivityDate = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivityDate({ id: nanoid(), value: data }));
    };
    const setCurrentActivitySwitch = (data) => {
        dispatch(ActivitiesSlice.actions.setCurrentActivitySwitch({ id: nanoid(), value: data }));
    };
    const [questionType, setQuestionType] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [cropperModalVisible, setCropperModalVisible] = useState(false);
    const [activityImage, setActivityImage] = useState(currentActivity.activityImage ? currentActivity.activityImage : ''); // Ajouté pour gérer l'image sélectionnée
    const [description, setDescription] = useState(currentActivity.description ? currentActivity.description : '');
    const [howToFind, setHowToFind] = useState(currentActivity.howToFind ? currentActivity.howToFind : '');
    // const {selectedActivityTitle, switchBtnUnlimitted = false} = currentActivity; //Avec la destructuration je recupère ces deux valeurs
    const fetchActivitiesData = () => __awaiter(void 0, void 0, void 0, function* () {
        const activitiesData = yield ActivityServices.getActivities(user._id);
        if (activitiesData) {
            dispatch(ActivitiesSlice.actions.setActivities({ id: nanoid(), value: activitiesData.activities }));
            dispatch(ActivitiesSlice.actions.setUserOrganisedActivities({ id: nanoid(), value: activitiesData.userOrganisedActivities }));
            dispatch(ActivitiesSlice.actions.setUserCoOrganisedActivities({ id: nanoid(), value: activitiesData.userCoOrganisedActivities }));
            dispatch(ActivitiesSlice.actions.setNetworkUsers({ id: nanoid(), value: activitiesData.networkUsers }));
        }
        // dispatch(ActivitiesSlice.actions.setStateValue({ id: nanoid(), value: { activities: activitiesData.activities } }));
        else
            console.log("failed to fetch activities data");
    });
    const openIllustrationModal = () => {
        setModalVisible(true);
    };
    // Handle Cropped Image
    const handleCroppedImage = (croppedImage) => {
        setActivityImage(croppedImage);
        setCropperModalVisible(false);
        resetHeader();
    };
    //Fonction pour ajouter dans le context le lien de l'image
    const handleActivityImagePicker = (image) => {
        if (image.imageUrl) {
            if (Platform.OS === 'web') {
                setActivityImage(image);
                setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { activityImage: image.imageUrl }));
            }
            else {
                setActivityImage(image);
                setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { activityImage: image.imageUrl }));
            }
            if (image.source == 'file') // image selectionnée depuis la bibliothèque de l'utilisateur
                setCropperModalVisible(true);
            resetHeader();
        }
    };
    const saveActivity = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            // console.log("the current activity to save ", currentActivity);
            const newActivity = yield ActivityCRUD.CreateActivity(currentActivity, activityImage);
            // return
            if (newActivity) {
                yield fetchActivitiesData();
                //Vider le context
                setCurrentActivity({});
                setCurrentActivityDate({});
                setCurrentActivitySwitch({});
                Platform.OS === 'web' ? navigation.navigate('Activities') : navigation.navigate('Activities'); //
            }
            else {
                console.error('Échec de la sauvegarde de l activité sur le serveur:');
                showErrorInHeader('failed to create activity');
                setIsError(true);
            }
        }
        catch (error) {
            console.error('Erreur lors de la sauvegarde de l activité:', error);
        }
    });
    //Permet de gérer l'affichage des erreurs
    const [isError, setIsError] = useState(false);
    const resetHeader = () => {
        setHeaderTitle('');
        setTimeout(() => {
            setHeaderTitle('Activity description');
            setIsError(false);
        }, 10);
    };
    const showErrorInHeader = (errorMessage) => {
        navigation.setOptions({
            headerTitle: () => (<Text style={{
                    fontSize: 20,
                    color: 'white',
                    fontWeight: 'bold',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                }}>
                {errorMessage}
              </Text>),
            headerStyle: { backgroundColor: 'red' },
            headerLeft: false,
        });
    };
    const handlePrevState = () => {
        setHeaderTitle('Activity Topic');
        onPrevStep();
    };
    const handleNextStep = () => __awaiter(void 0, void 0, void 0, function* () {
        // Vérifiez si les champs obligatoires sont remplis
        if (!activityImage) {
            setIsError(true);
            showErrorInHeader(translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonImageErrorMessage);
        }
        else if (!description) {
            setIsError(true);
            showErrorInHeader(translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonDescriptionErrorMessage);
        }
        else if (!howToFind && !currentActivity.infoLine) {
            setIsError(true);
            showErrorInHeader(translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonHowToFindMeErrorMessage);
        }
        else {
            setIsError(false);
            yield saveActivity(); // Enregistrez d'abord les données de l'activité
            onNextStep(); // Passez à l'étape suivante une fois que les données sont enregistrées
        }
    });
    const CropperComponent = Platform.select({
        web: () => <ImageCropperWeb file={activityImage === null || activityImage === void 0 ? void 0 : activityImage.file} handleCroppedImage={handleCroppedImage}/>,
        default: () => <ImageCropperMobile uri={activityImage.imageUrl} handleCroppedImage={handleCroppedImage}/>,
    });
    //Fonction pour recuper la description
    const handleDescriptionText = (text) => {
        setDescription(text.slice(0, 7500));
        setIsError(false);
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { description }));
    };
    //Fonction pour recuperer le text howToFind
    const handleHowToFindText = (text) => {
        setHowToFind(text.slice(0, 7500));
        setIsError(false);
        setCurrentActivity(Object.assign(Object.assign({}, currentActivity), { howToFind }));
    };
    return (<TouchableWithoutFeedback onPress={resetHeader} disabled={!isError}>
      <View style={styles.container}>
        <ScrollView showsVerticalScrollIndicator={false} style={styles.scrollContainer}>
            <Text style={styles.titleText}>
              {translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonExplainYourActivityMessage}
            </Text>
            {activityImage ? (
        // Appeler l'Image Cropper si Image Uploaded
        <>
                <Modal modalVisible={cropperModalVisible} onClose={() => setCropperModalVisible(false)}>
                  <CropperComponent />
                </Modal>
                <Pressable onPress={openIllustrationModal}>
                  <Pressable onPress={() => setCropperModalVisible(true)} style={styles.cropBtn}>
                    <Text style={styles.cropBtnText}>
                      {translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonEditButton}
                    </Text>
                    <Vectors.ProfilesEdit />
                  </Pressable>
                  {/* // activityImage.imageUrl pour image choisis depuis le bibliotheque, activityImage pour unsplash/cropped image */}
                  <Image source={{ uri: activityImage.imageUrl ? activityImage.imageUrl : activityImage }} style={styles.imageStyle}/>
                </Pressable>
              </>) : (<Pressable style={styles.choosePhoto} onPress={openIllustrationModal}>
                <View style={styles.btnChoose}>
                  <Vectors.GeneralColorsUpload />
                  <Text style={styles.btnChooseText}>{translations.JsonActivityPages.JsonCreateActivityImageModal.JsonAddImageMessage}</Text>
                </View>
              </Pressable>)}
            <ActivityImagePicker modalVisible={modalVisible} setModalVisible={setModalVisible} 
    // ouvrir crop image automatiquement pour image local library
    onImageSelected={handleActivityImagePicker} selectedActivityTitle={currentActivity.activityTopicTitle}/>
            <View style={styles.inputContainer}>
            <TextInputCustomComponent placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonDescriptionPlaceholder} handleTextChange={handleDescriptionText} value={description} multiline={true} numberOfLines={12} isPlaceHolderTextAlignCenter={false}/>
            </View>
            <View style={styles.inputContainer}>
            <TextInputCustomComponent placeholderText={translations.JsonActivityPages.JsonActivityManagement.JsonExplainYourActivityScreen.JsonHowToFindMePlaceholder} handleTextChange={handleHowToFindText} value={howToFind} multiline={true} numberOfLines={12} isPlaceHolderTextAlignCenter={false}/>
            </View>
            <View style={styles.spaceBottom}/>
        </ScrollView>
        <BottomWhiteNav items={[
            { label: translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton, onPress: () => handlePrevState() },
            { label: translations.JsonActivityPages.JsonActivityManagement.JsonPublishButton, onPress: () => handleNextStep(), disabled: !activityImage || description.trim().length === 0 || howToFind.length === 0 }
        ]}/>
      </View>
    </TouchableWithoutFeedback>);
};
export default CreateActivityDescriptionScreen;
