import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 50,
        width: '100%',
    },
    modalView: {
        backgroundColor: 'white',
        borderRadius: 20,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000', // Couleur de l'ombre
        shadowOffset: { width: 0, height: 2 }, // Décalage de l'ombre (largeur, hauteur)
        shadowOpacity: 0.25, // Opacité de l'ombre
        shadowRadius: 4, // Rayon de l'ombre
        elevation: 5,
        height: '100%',
    },
    searchInput: {
        height: 40,
        margin: 12,
        borderWidth: 1,
        padding: 10,
        width: '100%',
    },
    image: {
        width: 100,
        height: 100,
        margin: 5,
    },
    closeButton: {
        marginTop: 10,
        alignSelf: 'flex-end',
    },
    close: {
        alignSelf: 'flex-end',
    },
});
