import React from "react";
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { View, Platform, TouchableOpacity } from 'react-native';
import Navbar from '../../TopNavbarActivities/Code';
import MapsScreen from '../../../../pages/MapsSection/MapsNavigator';
import MembersNavigator from '../../../../pages/MembersSection/MembersNavigator';
import NotificationsNavigator from '../../../../pages/NotificationsSection/NotificationsNavigator';
import BurgerButton from '../../../ButtonSection/BurgerButton/Code';
import VectorsOnOff from '../../../../constants/VectorsOnOff/VectorsOnOff';
import { useNavigation, DrawerActions } from '@react-navigation/native';
import MessageTabWrapper from './MessageTabWrapper';
import { useSelector } from 'react-redux';
import { createStackNavigator } from "@react-navigation/stack";
import CopyActivityScreen from "../../../../pages/ActivityModule/DuplicateActivitySection/Code";
import CreateActivityScreen from "../../../../pages/ActivityModule/CreateActivitySection/Code";
import Vectors from "../../../../constants/Vectors";
import ActivityDetailsTabNavigator from "../../../../pages/ActivityModule/ActivityDetailsSection/ActivityDetailsNavigator/Code";
import EditActivityScreen from "../../../../pages/ActivityModule/EditActivitySection/Code";
import EditProfileScreen from '../../../../pages/ProfileModule/EditProfileSection/EditProfileNavigator';
import { useActivityModal } from "../../../../contexts/ActivityModalContext";
import Svg from 'react-native-svg';
// Platform-specific styles
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const Tab = createBottomTabNavigator();
const Stack = createStackNavigator();
const BottomNav = () => {
    var _a, _b, _c, _d;
    const { user, friendNotifications, profileVisits, isLoading, likeNotifications, activityLikeNotifications } = useSelector((state) => state.user);
    const navigation = useNavigation();
    const { translations } = useSelector((state) => state.language);
    // Calculate the total number of unread notifications
    const totalNotificationCount = !isLoading && user
        ? [
            ((_a = user.notificationSettings) === null || _a === void 0 ? void 0 : _a.visitorNotification) && profileVisits,
            ((_b = user.notificationSettings) === null || _b === void 0 ? void 0 : _b.friendRequest) && friendNotifications,
            ((_c = user.notificationSettings) === null || _c === void 0 ? void 0 : _c.likeNotification) && likeNotifications,
            ((_d = user.notificationSettings) === null || _d === void 0 ? void 0 : _d.activityLikeNotifications) && activityLikeNotifications,
        ].reduce((acc, notifications) => {
            return acc + (notifications ? notifications.filter((notification) => !notification.isRead).length : 0);
        }, 0)
        : 0;
    return (<Tab.Navigator screenOptions={Object.assign({ headerShown: false, tabBarActiveTintColor: 'blue', tabBarInactiveTintColor: 'gray', tabBarStyle: [{ display: 'flex' }, null] }, styles.screenOptionsStyles)} initialRouteName="Activities">
            <Tab.Screen name="Activities" component={Navbar} options={{
            // title: translations.JsonBottomNav.JsonActivitiesTab,
            title: '',
            tabBarIcon: ({ focused }) => focused ? <VectorsOnOff.IconCalendarOn /> : <VectorsOnOff.IconCalendarOff />,
            headerTitle: translations.JsonTopNav.JsonFirstLine,
            headerStyle: { backgroundColor: '#59C09B' },
            headerTitleAlign: 'center',
            headerLeft: () => <BurgerButton onPress={() => navigation.dispatch(DrawerActions.openDrawer())}/>,
            headerTitleStyle: {
                color: 'white',
                fontWeight: 'bold',
                fontSize: 30,
            },
            // ...styles.tabScreenOptions,
            headerShown: true,
        }}/>
            <Tab.Screen name="Members" component={MembersNavigator} options={{
            // title: translations.JsonBottomNav.JsonMembersTab,
            title: '',
            tabBarIcon: ({ focused }) => (focused ? (<Svg width={44} height={44}>
                            <VectorsOnOff.IconMembersOn />
                          </Svg>) : (<Svg width={44} height={44}>
                            <VectorsOnOff.IconMembersOff />
                          </Svg>)),
            headerTitle: translations.JsonTopNav.JsonMembersFirstLine,
            headerStyle: { backgroundColor: '#59C09B' },
            headerTitleAlign: 'center',
            headerLeft: () => <BurgerButton onPress={() => navigation.dispatch(DrawerActions.openDrawer())}/>,
            headerTitleStyle: {
                color: 'white',
                fontWeight: 'bold',
                fontSize: 30,
            },
            // ...styles.tabScreenOptions,
            headerShown: true,
        }}/>
            <Tab.Screen name="Maps" component={MapsScreen} options={{
            // title: translations.JsonBottomNav.JsonMapsTab,
            title: '',
            tabBarIcon: ({ focused }) => focused ? <VectorsOnOff.MapOn /> : <VectorsOnOff.MapOff />,
            headerTitle: translations.JsonTopNav.JsonFirstLineMaps,
            headerStyle: { backgroundColor: '#59C09B' },
            headerTitleAlign: 'center',
            headerLeft: () => <BurgerButton onPress={() => navigation.dispatch(DrawerActions.openDrawer())}/>,
            headerTitleStyle: {
                color: 'white',
                fontWeight: 'bold',
                fontSize: 30,
            },
            // ...styles.tabScreenOptions,
            headerShown: true,
        }}/>
            <Tab.Screen name="Notifications" component={NotificationsNavigator} options={{
            tabBarBadge: totalNotificationCount > 0 ? totalNotificationCount : undefined,
            tabBarBadgeStyle: {
                backgroundColor: '#FFF510',
                color: 'black',
                fontSize: 10,
                minWidth: 20,
                height: 20,
                textAlign: 'center',
                borderRadius: 15,
                paddingTop: 2,
            },
            // title: translations.JsonBottomNav.JsonNotificationsTab,
            title: '',
            tabBarIcon: ({ focused }) => focused ? <VectorsOnOff.BellOn /> : <VectorsOnOff.BellOff />,
            headerTitle: translations.JsonTopNav.JsonFirstLineNotifications,
            headerStyle: { backgroundColor: '#59C09B' },
            headerTitleAlign: 'center',
            headerLeft: () => <BurgerButton onPress={() => navigation.dispatch(DrawerActions.openDrawer())}/>,
            headerTitleStyle: {
                color: 'white',
                fontWeight: 'bold',
                fontSize: 30,
            },
            // ...styles.tabScreenOptions,
            headerShown: true,
        }}/>
            <Tab.Screen name="Message" component={MessageTabWrapper} options={{
            // title: translations.JsonBottomNav.JsonMessagesTab,
            title: '',
            tabBarIcon: ({ focused }) => focused ? <VectorsOnOff.MessengerOn /> : <VectorsOnOff.MessengerOff />,
            headerTitle: translations.JsonTopNav.JsonMessagesFirstLine,
            headerStyle: { backgroundColor: '#59C09B' },
            headerTitleAlign: 'center',
            headerLeft: () => <BurgerButton onPress={() => navigation.dispatch(DrawerActions.openDrawer())}/>,
            headerTitleStyle: {
                color: 'white',
                fontWeight: 'bold',
                fontSize: 30,
            },
            // ...styles.tabScreenOptions,
            headerShown: true,
        }}/>
        </Tab.Navigator>);
};
const headerLeftArrow = (navigation) => (<TouchableOpacity onPress={() => navigation.goBack()}>
        <View style={{ marginLeft: 15 }}>
            <Vectors.LeftArrow />
        </View>
    </TouchableOpacity>);
const headerRightMsg = (openActivityChatModal, setOpenActivityChatModal) => (<>
        {!!openActivityChatModal ?
        <View style={{ marginRight: 15, width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                <Vectors.NavbarChatColors style={{ width: 50, height: 50 }}/>
            </View>
        :
            <TouchableOpacity onPress={() => setOpenActivityChatModal(!openActivityChatModal)}>
                <View style={{ marginRight: 15, width: 50, height: 50, justifyContent: 'center', alignItems: 'center' }}>
                    <Vectors.NavbarBlackAndWhiteChat style={{ width: 50, height: 50 }}/>
                </View>
            </TouchableOpacity>}
    </>);
const MainNavigation = () => {
    const { activityTopicTitle, author } = useSelector((state) => state.activities);
    const { activityModalOpen, setActivityModalOpen } = useActivityModal();
    const defaultScreenOptions = {
        headerShown: true,
        headerStyle: { backgroundColor: '#59C09B' },
        headerTintColor: 'white',
        headerTitleStyle: { fontWeight: 'bold', fontSize: 20 },
        headerTitleAlign: "center",
    };
    return (<Stack.Navigator>
            <Stack.Screen name="Activities" component={BottomNav} options={({ navigation }) => ({
            headerShown: false
        })}/>
            <Stack.Screen name="CopyActivity" component={CopyActivityScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
            <Stack.Screen name="CreateActivity" component={CreateActivityScreen} options={({ navigation }) => (Object.assign({}, defaultScreenOptions))}/>
            <Stack.Screen name="Activity" component={ActivityDetailsTabNavigator} options={({ navigation }) => (Object.assign(Object.assign({ title: author && author.firstName ? `${author.firstName.toUpperCase()} organize` : activityTopicTitle }, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation), headerRight: () => headerRightMsg(activityModalOpen, setActivityModalOpen) }))}/>
            <Stack.Screen name="EditActivity" component={EditActivityScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
            <Stack.Screen name="EditProfile" component={EditProfileScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
        </Stack.Navigator>);
};
export default Platform.OS === 'web' ? BottomNav : MainNavigation;
