import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
    },
    nameContent: {
        width: '80%',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        marginTop: '7%',
    },
    lastName: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    firstName: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
    },
    btnContainer: {
        paddingHorizontal: 25,
        flexDirection: 'row',
        marginVertical: '10%',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'center',
    },
    comment: {
        marginTop: 10,
        fontSize: 12,
        width: '60%',
    },
    errorText: {
        color: 'red',
        fontSize: 12,
    },
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
});
