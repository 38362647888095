import { StyleSheet } from 'react-native';
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    contentContainer: {
        flexGrow: 1,
        backgroundColor: "#404040",
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingBottom: 250,
    },
    textContainer: {
        flexDirection: 'column',
    },
    logo: {
        marginTop: 30,
        width: '80%',
        height: '30%',
    },
    update: {
        color: 'white',
        fontSize: 26,
        fontWeight: '700',
        textAlign: 'center',
        marginTop: 30,
    },
    textStore: {
        color: 'white',
        fontSize: 34,
        fontWeight: 'bold',
        flexDirection: 'column',
    },
    buttonStore: {
        backgroundColor: '#000',
        marginTop: 20,
        width: 264,
        height: 96,
        borderWidth: 2,
        borderColor: 'white',
        borderRadius: 17,
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    smallText: {
        color: 'white',
        fontSize: 16,
        flexDirection: 'column',
        marginBottom: -8,
    },
    logoApple: {
        width: 50,
        height: 50,
        marginTop: -8,
        marginRight: 8,
    },
    logoGoogle: {
        width: 50,
        height: 50,
    },
    logoHuawei: {
        width: 50,
        height: 50,
        marginRight: 10
    },
    logoWebsite: {
        height: 65,
        width: 65,
        marginRight: 16
    },
});
export default styles;
