var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// 🇫🇷 L'écran de la troisème étape d'un processus d'inscription multi-étapes (FrameFigma012A)
// 🇬🇧 The third step screen of a multi-step registration process (FrameFigma012A)
import React, { useState, useEffect } from 'react';
import { View, Text, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AddressAutoPicker from '../../../../components/PickerSection/AdressAutoProfilePicker/Code';
import { generateUsername } from '../../../../components/ProfileSection/UniqueUserName/Code';
import { useError } from '../../../../contexts/ErrorContext';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const StepThreePersonalScreen = ({ onNextStep, onPrevStep }) => {
    // const { translations } = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const { error, setError } = useError();
    const [selectedAddress, setSelectedAddress] = useState('');
    const [generatedUserName, setGeneratedUserName] = useState('');
    //const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
    const [validationErrors, setValidationErrors] = useState({});
    /*useEffect(() => {
      setIsButtonDisabled(!generatedUserName || !!validationErrors.nickname);
    }, [generatedUserName, validationErrors]);*/
    // 🇫🇷 lors du chargement de la page on génére un pseudonyme a l'aide de firstName & lastName enregistrer dans l'AsyncStorage
    // 🇬🇧 When the page loads, generate a pseudonym using firstName and lastName and store it in AsyncStorage.
    useEffect(() => {
        const generateAndSetUsername = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const username = yield generateUsername();
                setGeneratedUserName(username);
                yield AsyncStorage.setItem('userName', username);
            }
            catch (error) {
                setError(error.message);
                console.error('Error generating username:', error);
            }
        });
        generateAndSetUsername();
    }, []);
    const handleNextStep = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedAddress.trim() === '' || generatedUserName.trim() === '') {
            setError(translations.JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonCityMissing);
            //console.log('Required fields not filled for professional account');
            return;
        }
        setValidationErrors({});
        onNextStep ? onNextStep() : () => { };
    });
    // 🇫🇷 Ajoute dans l'AsyncStorage les éléments (city, latitude, longitude)
    // 🇬🇧 Add to the AsyncStorage the items (city, latitude, longitude)
    const handleAddressSelect = (address) => {
        setSelectedAddress(address);
        setError(''); // Reset error message
    };
    return (<View style={styles.container}>
      {error && (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>)}
      <View style={styles.addressContent}>
        <AddressAutoPicker onAddressSelect={handleAddressSelect} selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} placeholder={translations.JsonCreateProfilePages.JsonCityPlaceholder}/>
      </View>

      <View style={styles.btnContainer}>
        <ButtonPrevious onPrevStep={onPrevStep ? onPrevStep : () => { }} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
        <ButtonNext conditionNeed={selectedAddress !== ''} handleNextStep={handleNextStep} btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}/>
      </View>
    </View>);
};
export default StepThreePersonalScreen;
