var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Modal, Platform, Text, TouchableOpacity, View } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import Vectors from '../../../constants/Vectors';
import { useWebSocket } from '../../../sockets/WebSocketLocal';
import { useDispatch, useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const LogoutButton = ({ style }) => {
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const [showModal, setShowModal] = useState(false);
    const navigation = useNavigation(); // Adjust 'any' to your navigation params type if known
    const { handleUserLogout } = useWebSocket();
    const dispatch = useDispatch();
    const logout = () => {
        console.log("start login out");
        dispatch({ type: "RESET_APP" });
    };
    const handleLogout = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const token = yield AsyncStorage.getItem('userToken');
            const tokenExpiryTime = yield AsyncStorage.getItem('tokenExpiryTime');
            const currentTime = new Date().getTime();
            handleUserLogout();
            logout();
            navigation.navigate('Home');
            // if (tokenExpiryTime && currentTime > parseInt(tokenExpiryTime)) {
            //     console.log('Token expiré. Déconnexion...');
            //     logout();
            //     navigation.navigate('Home');
            // } else {
            //     handleUserLogout();
            // }
        }
        catch (error) {
            console.error('Erreur lors de la déconnexion : ', error instanceof Error ? error.message : 'Unknown error');
        }
        finally {
            setShowModal(false);
        }
    });
    return (<>
            <TouchableOpacity onPress={() => setShowModal(true)} style={styles.logoutButton}>
                <Vectors.GeneralColorsLogOut />
                <Text style={[styles.ItemText, style]}>{translations.JsonDrawerNavMenu.JsonLogout}</Text>
            </TouchableOpacity>
            <Modal visible={showModal} transparent={true} animationType="slide" onRequestClose={() => setShowModal(false)}>
                <View style={styles.modal}>
                    <View style={styles.modalContent}>
                        <Text style={styles.modalText}>{translations.JsonDrawerNavMenu.JsonPopupMessage.JsonTitleComfirmation}</Text>
                        <TouchableOpacity style={styles.modalButton} onPress={handleLogout}>
                            <Text style={styles.modalButtonText}>{translations.JsonDrawerNavMenu.JsonPopupMessage.JsonYesButton}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.modalButton} onPress={() => setShowModal(false)}>
                            <Text style={styles.modalButtonText}>{translations.JsonDrawerNavMenu.JsonPopupMessage.JsonNoButton}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </Modal>
        </>);
};
export default LogoutButton;
