var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Axios from '../Axios/AxiosService';
const getActivities = (userId) => __awaiter(void 0, void 0, void 0, function* () {
    if (!userId) {
        return;
    }
    try {
        const response = yield Axios.get(`/activities`);
        const attendedActivities = response.data.filter((activity) => activity.attendees.includes(userId));
        const networkUserIds = new Set();
        attendedActivities.forEach((activity) => {
            activity.attendees.forEach((attendeeId) => {
                if (attendeeId !== userId) {
                    networkUserIds.add(attendeeId);
                }
            });
        });
        const options = {
            method: 'POST',
            url: `/users/details`,
            headers: { 'Content-Type': 'application/json' },
            data: { userIds: Array.from(networkUserIds) }
        };
        const networkUsers = yield Axios.request(options).then(response => response.data);
        return {
            activities: response.data,
            userOrganisedActivities: response.data.filter((activity) => activity.author === userId),
            userCoOrganisedActivities: response.data.filter((activity) => activity.coOrganizers.includes(userId)),
            userAttendedActivities: attendedActivities,
            networkUsers: networkUsers
        };
    }
    catch (error) {
        console.error('Error fetching activities:', error);
        return false;
    }
});
const getActivitiesUserOrganisedAndParticipate = (userId) => {
};
const getActivity = (activityId) => {
    return Axios.get(`/activities/${activityId}`);
};
const createActivity = (activityData) => __awaiter(void 0, void 0, void 0, function* () {
    // const formData = new FormData();
    // for (const key in activityData) {
    //   if (activityData.hasOwnProperty(key)) {
    //     if (typeof activityData[key] === 'object' && activityData[key] !== null) {
    //       formData.append(key, JSON.stringify(activityData[key]));
    //     } else {
    //       formData.append(key, activityData[key]);
    //     }
    //   }
    // }
    const options = {
        method: 'POST',
        url: `/activities`,
        headers: { 'Content-Type': 'multipart/form-data' },
        data: activityData
    };
    try {
        const response = Axios.request(options).then(response => response.data)
            .catch(error => {
            console.log("failed to create an activity ", error);
            return null;
        });
        return response;
    }
    catch (error) {
        console.log("failed to create activity", error);
        return null;
    }
});
const deleteActivity = (activityId) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        return yield Axios.delete(`/activities/${activityId}`).then(response => response.data).catch(error => false);
    }
    catch (error) {
        console.error('Error deleting activity:', error);
        return null;
    }
});
const addActivity = (datas) => __awaiter(void 0, void 0, void 0, function* () {
    return yield Axios.post(`/activities`, datas);
});
const updateActivity = (activityId, datas) => {
    return Axios.put(`/activities/${activityId}`, datas);
};
const editActivity = (activityId, datas) => __awaiter(void 0, void 0, void 0, function* () {
    return Axios.put(`/activitieOne/${activityId}`, datas);
});
const getActivitybyId = (activities, activityId) => activities.find((activity) => activity._id === activityId);
const getActivitybyName = (activities, activityName) => activities.find((activity) => activity.title === activityName);
export const ActivityServices = {
    createActivity,
    addActivity,
    deleteActivity,
    updateActivity,
    editActivity,
    getActivities,
    getActivity,
    getActivitybyId,
    getActivitybyName
};
