import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: 'white',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 10,
        paddingHorizontal: 20,
        paddingTop: 10
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 10
    },
    networkNumber: {
        flexDirection: 'row',
    },
    headerLeft: {
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    seeAll: {
        fontWeight: 'bold',
        color: 'white',
        backgroundColor: '#59C09B',
        textAlign: 'center',
        margin: 20,
        paddingVertical: 5,
        paddingHorizontal: 20,
        borderRadius: 30
    },
    headerRight: {
        flexDirection: 'column',
        rowGap: 10,
        left: 30
    },
    underHeader: {
        top: -10,
    },
    legendCircleGray: {
        width: 15,
        height: 15,
        borderColor: '#9D9D9D',
        borderWidth: 2,
        borderRadius: 15
    },
    legendCircleBlue: {
        width: 15,
        height: 15,
        borderColor: '#65B3EA',
        borderWidth: 2,
        borderRadius: 15
    },
    legendItem: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    legendText: {
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: '400',
    },
    membersTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        marginBottom: 5
    },
    networkCount: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#59C09B',
        marginLeft: 15
    },
    network: {
        flex: 1,
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        gap: 2,
    },
    networkItem: {
        overflow: 'visible',
        width: 90,
        marginBottom: '4%',
    },
});
