// Fr  Styles de DuplicateClassicOrStepByStepActivityScreen(FrameFigma031)
// En  Styles of DuplicateClassicOrStepByStepActivityScreen(FrameFigma031)
import { StyleSheet } from 'react-native';
export default StyleSheet.create({
    container: {},
    scrollContainer: {
        paddingLeft: 25,
        paddingRight: 25,
    },
    textTitleOne: {
        //marginTop: '2.5%',
        marginTop: 15,
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: 18,
        lineHeight: 27,
    },
    questionContainer: {
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        marginVertical: '5%',
    },
    classic: {
        borderWidth: 1,
        width: '45%',
        borderTopLeftRadius: 13,
        borderBottomLeftRadius: 13,
        padding: 7,
    },
    withStep: {
        borderWidth: 1,
        width: '45%',
        borderTopRightRadius: 13,
        borderBottomRightRadius: 13,
        padding: 7,
    },
    titleA: {
        fontSize: 18,
        marginTop: 10,
        marginLeft: 10,
    },
    textQuestion: {
        fontWeight: 'bold',
        textAlign: 'center',
        width: '100%',
        fontSize: 18,
    },
    activeQuestion: {
        backgroundColor: '#59C09B',
    },
    titleContainer: {
        paddingHorizontal: 8,
        justifyContent: 'center',
        fontSize: 16,
        borderWidth: 1,
        marginTop: 10,
        width: '95%',
        borderRadius: 15,
        padding: 20,
        marginLeft: 10,
    },
    textTitle: {
        paddingHorizontal: 15,
        backgroundColor: 'white',
        fontSize: 13,
        textAlign: 'center',
        position: 'absolute',
        zIndex: 1,
        left: 20,
        top: -10,
    },
    focusedInput: {
        borderColor: 'rgba(0, 0, 0, 0)',
    },
    dateContainer: {
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 10,
    },
    switchBtn: {
        marginVertical: 2.5,
        paddingBottom: 5,
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 10,
    },
    textbtn: {
        fontSize: 18,
        fontWeight: 'bold',
        marginVertical: 10,
    },
    textbtnEndTime: {
        fontSize: 18,
    },
    textBoldEndTime: {
        fontWeight: 'bold',
    },
    btnContainer: {
        marginTop: 60,
        width: '100%',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    buttonActive: {
        backgroundColor: '#59C09B',
        width: '55%',
        padding: 8,
        borderRadius: 15,
        alignItems: 'center',
        elevation: 5,
        margin: 30,
    },
    buttonInactive: {
        backgroundColor: 'grey',
        width: '55%',
        padding: 8,
        borderRadius: 15,
        alignItems: 'center',
        elevation: 5,
        margin: 30,
    },
    //Style du container du message d'erreur
    headerError: {
        backgroundColor: 'red',
        padding: 20,
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    //Style du texte du message d'erreur
    headerErrorText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    },
    //Style du modal en cours de création
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        top: -50,
    },
    modalContent: {
        width: 300,
        height: 200,
        backgroundColor: '#59C09B',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalText: {
        color: 'white',
        fontSize: 18,
        fontWeight: 'bold',
    }
});
