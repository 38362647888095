var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
//FR  un formulaire à plusieurs étapes pour créer un profil utilisateur.
//EN  a multi-step form for creating a user profile
import React, { useState, useLayoutEffect, useEffect } from 'react';
import { View, StyleSheet, TouchableOpacity } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation } from '@react-navigation/native';
import Step1Screen from './CreateProfileStep1Screen/Code';
import Step2Screen from './CreateProfileStep2Screen/Code';
import Step3PersonalScreen from './CreateProfileStep3PersoScreen/Code';
import Step3ProScreen from './CreateProfileStep3ProScreen/Code';
import Step4Screen from './CreateProfileStep4Screen/Code';
import Vectors from '../../../constants/Vectors';
const CreateProfileScreen = () => {
    const navigation = useNavigation();
    const [step, setStep] = useState(1);
    const [accountType, setAccountType] = useState(null); // Use null as initial value
    // Utilisez useEffect pour récupérer la valeur de accountType depuis AsyncStorage au chargement de l'écran
    useEffect(() => {
        const getAccountType = () => __awaiter(void 0, void 0, void 0, function* () {
            const storedAccountType = yield AsyncStorage.getItem('accountType');
            //console.log('Stored Account Type:', storedAccountType);
            setAccountType(storedAccountType);
        });
        getAccountType();
    }, [step]); // Changez ici pour déclencher le useEffect à chaque changement de 'step'
    const onNextStep = () => __awaiter(void 0, void 0, void 0, function* () {
        if (step < 4) {
            setStep(step + 1);
        }
    });
    const onPrevStep = () => __awaiter(void 0, void 0, void 0, function* () {
        if (step > 1) {
            setStep(step - 1);
        }
    });
    // le header est gérer localement
    useLayoutEffect(() => {
        navigation.setOptions({
            headerLeft: () => step > 1 ? (<TouchableOpacity onPress={onPrevStep} style={{ marginLeft: 15 }}>
            <Vectors.LeftArrow />
          </TouchableOpacity>) : null,
            title: 'Create Profile',
            headerStyle: { backgroundColor: '#59C09B' },
            headerTintColor: 'white',
            headerTitleStyle: { fontWeight: 'bold', fontSize: 20 },
        });
    }, [navigation, step]);
    const renderStep = () => {
        switch (step) {
            case 1:
                return <Step1Screen onNextStep={onNextStep}/>;
            case 2:
                return <Step2Screen onNextStep={onNextStep} onPrevStep={onPrevStep}/>;
            case 3:
                return accountType === 'personal' ? (<Step3PersonalScreen onNextStep={onNextStep} onPrevStep={onPrevStep}/>) : (<Step3ProScreen onNextStep={onNextStep} onPrevStep={onPrevStep}/>);
            case 4:
                return <Step4Screen />;
            default:
                return null;
        }
    };
    return <View style={styles.container}>{renderStep()}</View>;
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});
export default CreateProfileScreen;
