//Fr Drawer de l'application mobile #Frame Figma 100A
//Fr Drawer mobile application #Frame Figma 100A
import React from 'react';
import { Image, Pressable, Text, View, Linking } from 'react-native';
import { useNavigation } from '@react-navigation/native';
import styles from './StylesMobile';
import ProfileMedal from '../../ProfileSection/ProfileMedal/Code';
import { CalculateUserPointsDrawer } from '../../../utils/Functions/CalculateUserPointsDrawer';
import Vectors from '../../../constants/Vectors';
import { useSelector } from 'react-redux';
import { DrawerContentScrollView, DrawerItem } from '@react-navigation/drawer';
import LogoutButton from '../../../components/ButtonSection/LogoutButton/Code';
import ProgressBar from '../../ProfileSection/ProgressBar/Code';
const DrawerMobile = () => {
    var _a, _b, _c;
    const { user } = useSelector((state) => state.user);
    // const {translations} = useLanguage();
    const { translations } = useSelector((state) => state.language);
    const navigation = useNavigation();
    const t = translations.JsonDrawerNavMenu;
    const navigateToScreen = (screenName) => {
        navigation.navigate(screenName, {
            userId: user === null || user === void 0 ? void 0 : user._id,
        });
    };
    const userPoints = CalculateUserPointsDrawer((_a = user === null || user === void 0 ? void 0 : user._id) !== null && _a !== void 0 ? _a : '');
    const userSponsorships = ((_b = user === null || user === void 0 ? void 0 : user.sponsoredUsers) === null || _b === void 0 ? void 0 : _b.length) || 0;
    if (!user) {
        return null;
    }
    return (<DrawerContentScrollView style={{ flex: 1 }}>
            <View style={styles.drawerHeader}>
                <Pressable onPress={() => navigateToScreen(t.JsonMyProfile)}>
                    {user.profileImage ? <Image source={{ uri: user.profileImage }} style={styles.profileImage}/> : <Vectors.ProfilesNoGender />}
                </Pressable>
                <View style={styles.userInfosContainer}>
                    <View style={styles.userNameContainer}>
                    <Text style={user.sexe === 'Male' ? styles.userNameMale : styles.userNameFemale}>{user.userName}</Text>
                    </View>
                    <View style={styles.trophyContainer}>
                        <ProfileMedal noOfSponsorships={((_c = user.sponsoredUsers) === null || _c === void 0 ? void 0 : _c.length) || 0} points={userPoints} isAmbassador={false} size={24}/>
                    <Text style={styles.textPoint}>
                        {userPoints} {translations.JsonAchievementsPages.JsonMedalsScreen.JsonPoints}
                    </Text>
                    {/* TODO: Remplacer par la vrai valeur de la note de l'organisateur et de la valeur de la ProgressBar lors de l'implementation dans le back-end */}
                    <Text style={{ fontSize: 12 }}>⭐⭐⭐⭐⭐</Text>
                    <ProgressBar progress={0.8}/>
                    </View>
                </View>
            </View>
            <DrawerItem label={t.JsonConcept} icon={() => <Vectors.NavbarColorsConcept style={[styles.drawerItem]}/>} onPress={() => navigateToScreen(t.JsonConcept)} labelStyle={styles.drawerText}/>
            <View style={styles.separator}/>
            <DrawerItem label={t.JsonBecomeVip} icon={() => <Vectors.GeneralColorsGoldStar />} onPress={() => navigateToScreen(t.JsonBecomeVip)} labelStyle={styles.drawerText}/>
            <DrawerItem label={t.JsonInviteFriends} icon={() => <Vectors.GeneralColorsInviteFriends />} onPress={() => navigateToScreen(t.JsonInviteFriends)} labelStyle={styles.drawerText}/>
            <DrawerItem label={t.JsonCreateEvent} icon={() => <Vectors.GeneralColorsCreateAnActivity />} onPress={() => navigateToScreen(t.JsonCreateEvent)} labelStyle={styles.drawerText}/>
            <DrawerItem label={t.JsonDonation} icon={() => <Vectors.DonationIcon />} onPress={() => Linking.openURL('https://socializus.org')} labelStyle={styles.drawerText}/>
            <View style={styles.separator}/>
            <DrawerItem label={t.JsonSettings} icon={() => <Vectors.GeneralColorsSettings />} onPress={() => navigateToScreen(t.JsonSettings)} labelStyle={styles.drawerText}/>
            <DrawerItem 
    // {t.JsonInProcess}
    label='En développement' icon={() => <Text style={{ fontSize: 24 }}>🚀</Text>} onPress={() => navigateToScreen('InProcess')} labelStyle={styles.drawerText}/>
            <DrawerItem label={t.JsonUpdate} icon={() => <Vectors.GeneralColorsUpdate />} onPress={() => navigateToScreen(t.JsonUpdate)} labelStyle={styles.drawerText}/>
            <DrawerItem label={t.JsonNews} icon={() => <Vectors.SettingsNews style={[styles.drawerItem, { paddingLeft: '-2%' }, { width: 24 }, { height: 24 }]}/>} onPress={() => navigateToScreen(t.JsonNews)} labelStyle={styles.drawerText}/>
            <View style={styles.separator}/>
            
            <View style={[styles.drawerItem, { marginLeft: '-5%', marginBottom: '5%' }]}>
                <LogoutButton style={styles.drawerTextLogout}/>
            </View>
        </DrawerContentScrollView>);
};
export default DrawerMobile;
