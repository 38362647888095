var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { View, Text, Platform } from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AddressAutoPicker from '../../../../components/PickerSection/AdressAutoProfilePicker/Code';
import { generateUsername, verifyExistingUserName, generateUsernameSuggestions } from '../../../../components/ProfileSection/UniqueUserName/Code';
import { useError } from '../../../../contexts/ErrorContext';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code';
import TextInputCustomComponent from '../../../../components/GeneralSection/TextInputCustomComponent/Code';
import Vectors from '../../../../constants/Vectors';
import { useSelector } from 'react-redux';
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const StepThreeProScreen = ({ onNextStep, onPrevStep }) => {
    const { translations, language } = useSelector((state) => state.language);
    const { error, setError } = useError();
    const [selectedAddress, setSelectedAddress] = useState('');
    const [generatedUserName, setGeneratedUserName] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [usernameSuggestions, setUsernameSuggestions] = useState([]);
    useEffect(() => {
        const setupUsername = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const storedUserName = yield AsyncStorage.getItem('userName');
                if (!storedUserName) {
                    const username = yield generateUsername();
                    setGeneratedUserName(username);
                    yield AsyncStorage.setItem('userName', username);
                }
                else {
                    setGeneratedUserName(storedUserName);
                }
                const storedCity = yield AsyncStorage.getItem('city');
                if (storedCity) {
                    setSelectedAddress(storedCity);
                }
            }
            catch (error) {
                setError(error.message);
                console.error('Erreur lors de la récupération ou de la définition du userName :', error);
            }
        });
        setupUsername();
    }, []);
    const handleNextStep = () => __awaiter(void 0, void 0, void 0, function* () {
        if (selectedAddress.trim() === '') {
            setError(translations.JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonCityMissing);
            return;
        }
        if (generatedUserName.trim() === '') {
            setError(translations.JsonProfilePages.JsonEditProfileScreen.JsonErrorMessage.JsonNicknameMissing);
            return;
        }
        if (validationErrors.nickname) {
            setError(translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonUserNameTaken);
            return;
        }
        setValidationErrors({});
        setError('');
        onNextStep ? onNextStep() : () => { };
    });
    const handleAddressSelect = (address) => __awaiter(void 0, void 0, void 0, function* () {
        setError('');
        setSelectedAddress(address);
    });
    const handleNicknameChange = (text) => __awaiter(void 0, void 0, void 0, function* () {
        text = text.replace(/[^\p{L}0-9.-]/gu, '') // Filter out unwanted characters
            .replace(/^[.-]+/, '') // Remove dots and dashes at the beginning
            .replace(/[.-]{2,}/g, match => match[0]); // Replace repeated special characters with a single one
        setError('');
        setGeneratedUserName(text);
        const isTaken = yield verifyExistingUserName(text);
        if (isTaken) {
            setValidationErrors({ nickname: translations.JsonProfilePages.JsonEditProfileScreen.JsonStep1.JsonUserNameTaken });
            setUsernameSuggestions(yield generateUsernameSuggestions(text));
        }
        else {
            setValidationErrors({});
            setUsernameSuggestions([]);
            yield AsyncStorage.setItem('userName', text);
        }
    });
    return (<View style={styles.container}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <View key={language} style={styles.container}>
        <View style={styles.addressContent}>
          <AddressAutoPicker onAddressSelect={handleAddressSelect} selectedAddress={selectedAddress} setSelectedAddress={setSelectedAddress} placeholder={translations.JsonCreateProfilePages.JsonCityPlaceholder}/>
        </View>
        <View style={styles.nicknameContainer}>
          <View style={styles.nicknameContent}>
            <View style={styles.nickNameMessage}>
              <Text style={styles.nickNameMessageTxt}>{translations.JsonCreateProfilePages.JsonNickNameMessage}</Text>
            </View>
            <View style={styles.nickName}>
              <TextInputCustomComponent Icon={Vectors.ProfilesLastName} placeholderText={translations.JsonCreateProfilePages.JsonNickName} value={generatedUserName} handleTextChange={handleNicknameChange} isPlaceHolderTextAlignCenter={false}/>
            </View>
            {validationErrors.nickname && (<View>
                <Text style={styles.errorText}>{validationErrors.nickname}</Text>
                <View style={styles.suggestionsContainer}>
                  {usernameSuggestions.map((suggestion, index) => (<Text key={index} style={styles.suggestionText} onPress={() => handleNicknameChange(suggestion)}>
                      {suggestion}
                    </Text>))}
                </View>
              </View>)}
          </View>
        </View>
        <View style={styles.btnContainer}>
          <ButtonPrevious onPrevStep={onPrevStep ? onPrevStep : () => { }} btnText={translations.JsonActivityPages.JsonActivityManagement.JsonPreviousButton}/>
          <ButtonNext conditionNeed={selectedAddress !== '' && generatedUserName !== '' && !validationErrors.nickname} handleNextStep={handleNextStep} btnText={translations.JsonLoginRegisterPages.JsonPasswordScreen.JsonNextButton}/>
        </View>
      </View>
    </View>);
};
export default StepThreeProScreen;
