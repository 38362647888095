var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from "react";
import { Modal, Pressable, ScrollView, TextInput, TouchableOpacity, View, TouchableWithoutFeedback, Platform } from "react-native";
import { useWebSocket } from "../../../../sockets/WebSocketLocal";
import FetchUserBasics from "../../../../utils/Functions/FetchUserBasics";
import Vectors from "../../../../constants/Vectors";
import { useSelector } from "react-redux";
import Message from "./MessageElement/Code";
import ChatMenuModal from "./ChatMenuModal/Code";
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
export default function ChatModal({ chatId, isVisible, closeModal, participants, activityId }) {
    const { sendChatMessage, requestChatHistory, userChats, privateChatMessages, joinChatRoom } = useWebSocket();
    const { user } = useSelector((state) => state.user);
    let currentChat = userChats.find(chat => chat._id === chatId) || { _id: '', participants: participants, messages: [] };
    const [messageContent, setMessageContent] = useState('');
    const [messagesDisplay, setMessagesDisplay] = useState([]);
    const scrollViewRef = useRef(null);
    const [userBasicsCache, setUserBasicsCache] = useState({});
    const [isNewChat, setIsNewChat] = useState(!currentChat._id);
    const [isChatMenuVisible, setIsChatMenuVisible] = useState(false);
    const activity = useSelector((state) => state.activities.activities.find((activity) => activity._id === activityId));
    function handleSendMessage() {
        if (messageContent.trim()) {
            const otherParticipants = currentChat.participants.filter(participant => participant._id !== user._id).map(participant => participant._id);
            sendChatMessage(currentChat._id, otherParticipants, messageContent, activityId || '');
            const newChatMessage = {
                _id: '',
                sender: user._id,
                content: messageContent,
                timestamp: new Date().toISOString(),
            };
            if (isNewChat) {
                joinChatRoom(chatId || '', otherParticipants, activityId || '');
                currentChat = userChats.find(chat => chat._id === chatId) || { _id: '', participants: participants, messages: [] };
                chatId = currentChat._id;
                requestChatHistory(currentChat._id);
                setIsNewChat(!currentChat._id);
            }
            setMessagesDisplay([...messagesDisplay, newChatMessage]);
            setMessageContent('');
        }
        else {
            alert('Message cannot be empty.');
        }
    }
    function findChat(participants, activityId) {
        if (!activityId) {
            return findChatByParticipants(participants);
        }
    }
    function findChatByParticipants(participants) {
        return userChats.find(chat => { var _a; return (_a = chat.participants) === null || _a === void 0 ? void 0 : _a.every(participant => participants.some(p => p._id === participant._id)); });
    }
    function loadBasicUserData(participantIds) {
        return __awaiter(this, void 0, void 0, function* () {
            let newUserBasicsCache = Object.assign({}, userBasicsCache);
            for (const id of participantIds) {
                if (!newUserBasicsCache[id]) {
                    const userBasics = yield FetchUserBasics(id);
                    newUserBasicsCache = Object.assign(Object.assign({}, newUserBasicsCache), { [id]: {
                            firstName: `${userBasics.firstName}`,
                            lastName: `${userBasics.lastName}`,
                            userName: `${userBasics.userName}`,
                            profileImage: `${userBasics.profileImage}`,
                            status: `${userBasics.status}`,
                            sexe: `${userBasics.sexe}`,
                        } });
                }
            }
            setUserBasicsCache(newUserBasicsCache);
        });
    }
    function idToName(id) {
        var _a;
        return ((_a = userBasicsCache[id]) === null || _a === void 0 ? void 0 : _a.firstName) || 'Unknown User';
    }
    function idToProfilePicture(id) {
        var _a;
        return ((_a = userBasicsCache[id]) === null || _a === void 0 ? void 0 : _a.profileImage) || 'path_to_default_image';
    }
    function chatPicture() {
        var _a;
        if (activity) {
            return activity.activityImage || 'Unknown activity';
        }
        else {
            return ((_a = currentChat.participants) === null || _a === void 0 ? void 0 : _a.map(participant => idToProfilePicture(participant._id))[0]) || 'Unknown chat';
        }
    }
    function chatTitle() {
        var _a;
        if (activity) {
            return activity.title || 'Unknown activity';
        }
        else {
            return ((_a = currentChat.participants) === null || _a === void 0 ? void 0 : _a.map(participant => idToName(participant._id)).join(', ')) || 'Unknown chat';
        }
    }
    useEffect(() => {
        console.log('New message');
        console.log(privateChatMessages);
    }, [privateChatMessages]);
    useEffect(() => {
        if (isVisible && currentChat) {
            const participantIds = currentChat.participants.map(participant => participant._id);
            loadBasicUserData(participantIds).then(() => console.log('User names loaded.'));
        }
    }, [isVisible]);
    useEffect(() => {
        if (isVisible) {
            requestChatHistory(currentChat._id);
        }
    }, [isVisible, chatId]);
    useEffect(() => {
        if (scrollViewRef.current) {
            scrollViewRef.current.scrollToEnd({ animated: true });
        }
    }, [messagesDisplay]);
    useEffect(() => {
        if (isVisible) {
            if (!currentChat._id)
                currentChat = findChat(participants, activityId) || currentChat;
            console.log('Current chat:', currentChat);
            const formatChat = () => {
                if (currentChat === null || currentChat === void 0 ? void 0 : currentChat.messages) {
                    return currentChat.messages.map((message) => {
                        return {
                            _id: message._id,
                            sender: message.sender._id,
                            content: message.content,
                            timestamp: message.timestamp,
                        };
                    });
                }
                else {
                    return [];
                }
            };
            setMessagesDisplay(formatChat());
        }
    }, [userChats, userBasicsCache]);
    return (<Modal visible={isVisible} animationType="slide" transparent={true} onRequestClose={closeModal}>
          <TouchableWithoutFeedback onPress={closeModal}>
            <View style={styles.modalContainer}>
              <TouchableWithoutFeedback>
                <View style={styles.modalView}>
                  <View style={styles.header}>
                    <View style={styles.dragIndicator}/>
                    <View style={styles.headerSettings}>
                      <TouchableOpacity style={{ padding: 15 }} onPress={() => setIsChatMenuVisible(true)}>
                        <View style={{ width: 24, height: 24 }}>
                          <Vectors.ThreeDotsVertiBlack />
                        </View>
                      </TouchableOpacity>
                      {isChatMenuVisible &&
            (<ChatMenuModal onClose={() => setIsChatMenuVisible(false)} activityId={activityId} isVisible={isChatMenuVisible}/>)}
                    </View>
                  </View>
                  <ScrollView style={styles.messagesContainer} ref={scrollViewRef}>
                    {messagesDisplay.map((message, index, messageArray) => (<Message message={message} index={index} messageArray={messageArray} userBasics={userBasicsCache} user={user}></Message>))}
                  </ScrollView>
                  <View style={styles.footer}>
                    <TextInput style={styles.footerInput} placeholder="Tapez votre message ici" value={messageContent} onChangeText={setMessageContent}/>
                    <Pressable style={styles.footerSend} onPress={handleSendMessage}>
                      <View>
                        <Vectors.SendMessage />
                      </View>
                    </Pressable>
                  </View>
                </View>
              </TouchableWithoutFeedback>
            </View>
          </TouchableWithoutFeedback>
        </Modal>);
}
