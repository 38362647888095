import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    top: {
        backgroundColor: '#F0EFF4',
        justifyContent: 'space-between',
        width: '100%',
        height: 'auto',
    },
    header: {
        width: '100%',
        // marginBottom: 40,
    },
    middle: {
        // width: '100%',
        // height: '50%',
        paddingVertical: 20,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 10,
    },
    bottom: {
        backgroundColor: '#F0EFF4',
        width: '100%',
        height: '10%',
        position: 'absolute',
        bottom: 0,
        paddingTop: 40,
    },
    containerButtonBottom: {
        width: "100%",
        height: 50,
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "green",
    },
    titleAttendees: {
        flexDirection: 'row',
        paddingHorizontal: 20,
        width: '100%',
        height: 50,
        justifyContent: 'space-between',
        alignItems: 'center',
        marginVertical: 10,
        backgroundColor: 'yellow',
        // backgroundColor: '#FFFFFF',
    },
    arrowDown: {
        width: 30,
        height: 30,
    },
});
export default styles;
