import React from 'react';
import { View, TouchableOpacity } from 'react-native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useSelector } from 'react-redux';
import DrawerMobile from './Code';
import { Vectors } from '../../../constants/Vectors';
//import of Screens
import ConceptSlidesScreen from '../../../pages/ConceptSlidesScreen/Code';
import MyProfileScreen from '../../../pages/ProfileModule/MyProfileSection/MyProfileParents';
import CreateActivityScreen from '../../../pages/ActivityModule/CreateActivitySection/Code';
import InviteFriendsScreen from '../../../pages/InviteFriendsScreen/Code';
import VipBadges from '../../../pages/AchievementModule/VipBadgesSection/VipBadgeExplanationSelector/Code';
import { SettingsStack } from '../../../pages/SettingsSection/SettingsScreen/Code';
import NewsScreen from '../../../pages/NewsScreen/Code';
import UpdateScreen from '../../../pages/UpdateScreen/Code';
import LogoutButton from '../../../components/ButtonSection/LogoutButton/Code';
import InProcess from '../../../pages/InProcess/Code';
import Drink from '../../../pages/ActivityModule/ActivityDetailsSection/ActivityDetailsScreens/ActivityDetailsFreeShooterScreen/Code';
import VipBadgeGold from '../../../pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesGoldScreen/Code';
import VipBadgeRuby from '../../../pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesRubyScreen/Code';
import VipBadgeEmerald from '../../../pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesEmeraldScreen/Code';
import VipBadgeDiamand from '../../../pages/AchievementModule/VipBadgesSection/VipBadgeAdvantagesDiamondScreen/Code';
//Nav
import BottomNav from '../../NavBarSection/BottomNav/BottomGreenNav/Code';
const Drawer = createDrawerNavigator();
const headerLeftArrow = (navigation) => (<TouchableOpacity onPress={() => navigation.goBack()}>
      <View style={{ marginLeft: 15 }}>
        <Vectors.LeftArrow />
      </View>
    </TouchableOpacity>);
const defaultScreenOptions = {
    headerShown: true,
    headerStyle: { backgroundColor: '#59C09B' },
    headerTintColor: 'white',
    headerTitleStyle: { fontWeight: 'bold', fontSize: 20 },
    headerTitleAlign: "center",
};
export default function DrawerNavigation() {
    const { translations } = useSelector((state) => state.language);
    const headerLeftVipBadge = (navigation) => (<TouchableOpacity onPress={() => navigation.navigate(translations.JsonDrawerNavMenu.JsonBecomeVip)}>
        <View style={{ marginLeft: 15 }}>
          <Vectors.LeftArrow />
        </View>
      </TouchableOpacity>);
    return (<Drawer.Navigator initialRouteName={translations.JsonDrawerNavMenu.JsonConcept} drawerContent={(props) => <DrawerMobile />} screenOptions={{ drawerStyle: { width: '75%' } }}>
          <Drawer.Screen name="Activities" component={BottomNav} options={{ headerShown: false }}/>
          <Drawer.Screen name={translations.JsonDrawerNavMenu.JsonConcept} component={ConceptSlidesScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Drawer.Screen name={translations.JsonDrawerNavMenu.JsonMyProfile} component={MyProfileScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerShown: false }))}/>
          <Drawer.Screen name={translations.JsonDrawerNavMenu.JsonCreateEvent} component={CreateActivityScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Drawer.Screen name={translations.JsonDrawerNavMenu.JsonInviteFriends} component={InviteFriendsScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Drawer.Screen name={translations.JsonDrawerNavMenu.JsonBecomeVip} component={VipBadges} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Drawer.Screen name={translations.JsonDrawerNavMenu.JsonSettings} component={SettingsStack} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerShown: false }))}/>
          <Drawer.Screen 
    // {translations.JsonDrawerNavMenu.JsonInProcess}
    name="InProcess" component={InProcess} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Drawer.Screen name={translations.JsonDrawerNavMenu.JsonNews} component={NewsScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Drawer.Screen name={translations.JsonDrawerNavMenu.JsonUpdate} component={UpdateScreen} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Drawer.Screen name={translations.JsonDrawerNavMenu.JsonLogout} component={LogoutButton} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Drawer.Screen name='Drink' component={Drink} options={({ navigation }) => (Object.assign(Object.assign({}, defaultScreenOptions), { headerLeft: () => headerLeftArrow(navigation) }))}/>
          <Drawer.Screen name="VipBadgeGold" component={VipBadgeGold} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonGoldStar }, defaultScreenOptions), { headerLeft: () => headerLeftVipBadge(navigation) }))}/>
          <Drawer.Screen name="VipBadgeRuby" component={VipBadgeRuby} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonRubyStar }, defaultScreenOptions), { headerLeft: () => headerLeftVipBadge(navigation) }))}/>
            <Drawer.Screen name="VipBadgeEmerald" component={VipBadgeEmerald} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonEmeraldStar }, defaultScreenOptions), { headerLeft: () => headerLeftVipBadge(navigation) }))}/>
            <Drawer.Screen name="VipBadgeDiamond" component={VipBadgeDiamand} options={({ navigation }) => (Object.assign(Object.assign({ title: translations.JsonAchievementsPages.JsonDiamondStar }, defaultScreenOptions), { headerLeft: () => headerLeftVipBadge(navigation) }))}/>
          {/* Ajoutez ici les autres écrans pour les utilisateurs connectés */}
        </Drawer.Navigator>);
}
