// 🇫🇷 Ce fichier gère la page où on rentre notre numéro de téléphone (FrameFigma008)
// 🇬🇧 This file manages the page where we enter our phone number (FrameFigma008).
// ! NON IMPLEMENTER dans le back-end
import React, { useState } from 'react';
import { View, Platform, Text } from 'react-native';
import ButtonNext from '../../../../components/ButtonSection/NextButton/Code';
import PhoneNumberInput from '../../../../components/PickerSection/PhoneWebPicker/Code';
import { useError } from '../../../../contexts/ErrorContext';
import ButtonPrevious from '../../../../components/ButtonSection/PreviousButton/Code'; // Adjust import if needed
import { useSelector } from 'react-redux';
// Import styles based on platform (web or mobile)
const styles = Platform.OS === 'web' ? require('./StylesWeb').default : require('./StylesMobile').default;
const PhoneExistingUserScreen = ({ navigation }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [countryCode, setCountryCode] = useState('FR'); // Assuming 'FR' is the default country code
    const { setError, error } = useError(); // UseError context
    // const { translations } = useLanguage(); // To use later
    const { translations } = useSelector((state) => state.language);
    const handlePhoneNumberChange = (phone) => {
        setPhoneNumber(phone);
        setError(''); // Clear error when phone number changes
    };
    const handleNext = () => {
        if (!phoneNumber.trim()) {
            setError("Numéro de téléphone vide !");
            return;
        }
        //Send to the verification page the phone number & the country code
        navigation.navigate('VerifyPhoneExistingUserScreen', { phoneNumber, countryCode });
    };
    return (<View style={styles.container}>
      {error ? (<View style={styles.headerError}>
          <Text style={styles.headerErrorText}>{error}</Text>
        </View>) : null}
      <View style={styles.verification}>
        <Text style={styles.boldText}>Please enter your phone number to receive a verification code</Text>
        <Text style={styles.underText}>We will send you the 4 digit verification code</Text>
        <View style={styles.container}>
          <View style={styles.inputContainer}>
            <PhoneNumberInput value={phoneNumber} onPhoneNumberChange={handlePhoneNumberChange} onCountryChange={(countryCode) => setCountryCode(countryCode)} country={countryCode.toLowerCase()}/>
          </View>
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <ButtonPrevious onPrevStep={() => navigation.navigate('NewUserPassword')} btnText={'Precedent'}/>
        <ButtonNext handleNextStep={handleNext} btnText={'Suivant'} conditionNeed={phoneNumber.length !== 0} // Disable button if phoneNumber is empty
    />
      </View>
    </View>);
};
export default PhoneExistingUserScreen;
