import { StyleSheet } from "react-native";
const styles = StyleSheet.create({
    container: {
        flex: 1,
        height: '100%',
    },
    top: {
        backgroundColor: 'white',
        width: '100%',
        height: 'auto',
    },
    image: {
        width: '100%',
        aspectRatio: 16 / 9,
        resizeMode: 'contain',
        backgroundColor: 'white'
    },
    heart: {
        alignSelf: 'flex-end',
        position: 'absolute',
        margin: '5%',
        padding: '2%',
        top: '11%'
    },
    middle: {
        backgroundColor: 'white',
        width: '100%',
        height: 'auto',
        alignItems: 'center',
        paddingVertical: '5%'
    },
    bottom: {
        backgroundColor: 'white',
        width: '100%',
        height: 'auto',
        position: 'relative',
        bottom: 0,
        paddingVertical: 20,
    },
    containerButtonBottom: {
        width: "100%",
        height: 50,
        flexDirection: "row",
        justifyContent: "space-around",
        alignItems: "center",
    },
});
export default styles;
