export const handleActivityComment = (socket, updateGroupChatMessages) => {
    // Attach event listeners
    const setupEventListeners = () => {
        if (socket) {
            socket.on('receiveGroupMessage', (comment) => {
                console.log('Received comment from server:', comment);
                if (comment && comment.activity) {
                    updateGroupChatMessages(comment.activity, [comment]); // Envelopper le commentaire dans un tableau
                }
            });
            socket.on('activityCommentsResponse', response => {
                const { activityId, comments } = response;
                console.log('Received comments list from server for activityId:', activityId, 'comments:', comments);
                if (activityId && comments) {
                    updateGroupChatMessages(activityId, comments, true); // true pour remplacer les commentaires existants
                }
            });
            socket.on('confirmGroupMessageSent', () => {
                console.log('Comment sent confirmation received from server');
            });
        }
        else {
            console.warn('Socket is undefined. Event listeners not set up.');
        }
    };
    // Cleanup function
    const cleanupEventListeners = () => {
        if (socket) {
            socket.off('receiveGroupMessage');
            socket.off('activityCommentsResponse');
            socket.off('confirmGroupMessageSent');
        }
    };
    // Initialize event listeners
    setupEventListeners();
    // Exposed functions
    const sendActivityComment = ({ chatId, message, senderId, userColor }) => {
        if (socket) {
            console.log(`Sending message to chat ${chatId}:`, message);
            socket.emit('sendGroupMessage', { chatId, message, senderId, userColor }); // Ajoutez userColor ici
        }
        else {
            console.warn('Socket is undefined. Cannot send message.');
        }
    };
    const requestActivityComments = (activityId) => {
        if (socket) {
            console.log(`Requesting comments for activity: ${activityId}`);
            socket.emit('getActivityComments', { activityId });
        }
        else {
            console.warn('Socket is undefined. Cannot request comments.');
        }
    };
    return { sendActivityComment, requestActivityComments, cleanupEventListeners };
};
